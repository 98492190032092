import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import LosButton from '../../../../../onboarding/components/common/LosButton'
import LosFormInputField from '../../../../../onboarding/components/common/LosFormInputField'
import LosText from '../../../../../onboarding/components/common/LosText'
import { LoanDispersalSchedule } from '../../../../types/Lms'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

type Props = {
  trench: LoanDispersalSchedule
  index: number
  onDisburseClick?: () => void
  hideDisburseButton?: boolean
}

enum TrenchStatus {
  Disbursed = 'Disbursed',
  Pending = 'Pending',
}

function TrenchDetails({
  index,
  trench,
  onDisburseClick,
  hideDisburseButton = false,
}: Props) {
  const getScheduledDate = () => {
    if (trench.scheduleDate) {
      return dayjs(trench.scheduleDate).format('DD/MM/YYYY')
    }
    return '-'
  }
  const getDispersalDate = () => {
    if (trench.dispersalDate) {
      return dayjs(trench.dispersalDate).format('DD/MM/YYYY')
    }
    return '-'
  }

  const getTrenchStatus = () => {
    if (trench.dispersalDate) {
      return TrenchStatus.Disbursed
    }
    return TrenchStatus.Pending
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.75rem',
      }}
    >
      <LosText
        text={`Tranche ${index + 1}`}
        style={{
          color: '#676B76',
          fontSize: '0.625rem',
          fontWeight: 700,
          textTransform: 'uppercase',
          letterSpacing: '0.04375rem',
          lineHeight: '124%',
        }}
      />
      <Grid container rowGap={'1rem'} columnGap={'1rem'}>
        <Grid item xs={5} sm={3} md={2} lg={2}>
          <Field title='Disbursed Amount' description={trench.amount} />
        </Grid>
        <Grid item xs={3} sm={3} md={2} lg={2}>
          <Field title='Schedule Date' description={getScheduledDate()} />
        </Grid>
        <Grid
          item
          xs={5}
          md={2}
          lg={2}
          sm={3}
          style={{
            display:
              getTrenchStatus() == TrenchStatus.Disbursed ? 'block' : 'none',
          }}
        >
          <Field title='UTR Number' description={trench.utrNo} />
        </Grid>
        <Grid
          item
          xs={5}
          md={2}
          lg={2}
          sm={3}
          style={{
            display:
              getTrenchStatus() == TrenchStatus.Disbursed ? 'block' : 'none',
          }}
        >
          <Field title='Disbursal Date' description={getDispersalDate()} />
        </Grid>

        <Grid item xs={6} sm={4} md={2} lg={2}>
          <Field
            title='Status'
            description={getTrenchStatus()}
            descriptionColor={getTrenchStatusColor(getTrenchStatus())}
          />
        </Grid>

        <Grid
          item
          xs={5}
          md={2}
          lg={2}
          sm={3}
          style={{
            display:
              getTrenchStatus() == TrenchStatus.Pending &&
              hideDisburseButton == false
                ? 'block'
                : 'none',
          }}
        >
          <LosButton
            text='Disburse'
            variant='outlined'
            onClick={onDisburseClick}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default TrenchDetails

const Field = (data: {
  title: string
  description?: string | number
  descriptionColor?: string
}) => {
  const displayText = () => {
    if (data.description) {
      return `${data.description}`
    }
    return '-'
  }
  return (
    <LosFormInputField label={data.title}>
      <LosText
        text={displayText()}
        fontSize={'0.75rem'}
        fontWeight={400}
        lineHeight={'120%'}
        color={data.descriptionColor ?? '#1B1D22'}
      />
    </LosFormInputField>
  )
}

const getTrenchStatusColor = (status: TrenchStatus) => {
  if (status == TrenchStatus.Disbursed) {
    return 'rgba(54, 145, 63, 1)'
  } else if (status == TrenchStatus.Pending) {
    return 'rgba(221, 186, 8, 1)'
  }
}
