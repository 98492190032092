import { Grid, Stack } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect } from 'react';
import { useState } from 'react';
import { ColendingLoanOffersRoute } from '../../../containers/ColendingDashboardContainer';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import LosDataGrid from '../../components/LosDataGrid';
import LosStatusPill from '../../components/LosStatusPill';
import SolfinAppBar from '../../components/SolfinAppBar';
import {
  ColenderService,
  GetColenderLoansApiData,
} from '../../LMSHQ/api/ColenderService';
import {
  LOS_COLENDER_ID,
  LOS_PROGRAM_ID,
} from '../../onboarding/api/constants';
import LosButton from '../../onboarding/components/common/LosButton';
import LosSelect, {
  LosSelectOption,
} from '../../onboarding/components/common/LosSelect';
import { ColendingLoan } from '../../onboarding/types/Los';
import { StringUtility } from '../../onboarding/utils/StringUtility';
import { useNestedRouter } from '../provider/NestedRouterProvider';

function ColendingLoanOffers() {
  const { navigateToRoute } = useNestedRouter();
  const [applications, setApplications] = useState<ColendingLoan[]>([]);
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const { setSnackbar } = useSnackbar();
  const [selectedFilter, setSelectedFilter] = useState<string>('PENDING');

  async function fetchApplications() {
    try {
      setLoading(true);
      var params: Partial<GetColenderLoansApiData> = {
        colenderId: LOS_COLENDER_ID,
        status: selectedFilter,
        programId: LOS_PROGRAM_ID,
      };
      if (selectedFilter == 'ALL') delete params.status;
      const response = await ColenderService.getColenderLoans(params);
      setApplications(response.records);
      setRowCount(response.totalItems);
    } catch (error) {
      console.error(error);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const COLUMN_DEF: GridColumns<ColendingLoan> = [
    {
      field: 'id',
      headerName: 'Application Id',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendingLoan;
        return row.applicationId;
      },
      flex: 2,
    },
    {
      field: 'colenderRatio',
      headerName: 'Amount',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as ColendingLoan;
        var displayText = '-';
        if (application.colendingRatio) {
          displayText = `${application.colendingRatio} %`;
        }
        return displayText;
      },
      flex: 1,
    },
    {
      field: 'primaryROI',
      headerName: 'Primary ROI',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as ColendingLoan;
        var displayText = '-';
        if (application.primaryRoi) {
          displayText = `${application.primaryRoi} %`;
        }
        return displayText;
      },
      flex: 1,
    },

    // {
    //   field: 'applicationName',
    //   headerName: 'Applicant Name',
    //   renderCell: (props: GridRenderCellParams) => {
    //     return '-';
    //   },
    //   flex: 1,
    // },
    // {
    //   field: 'mobileNumber',
    //   headerName: 'Mobile Number',
    //   renderCell: (props: GridRenderCellParams) => {
    //     return '-';
    //   },
    //   flex: 1,
    // },
    // {
    //   field: 'tenure',
    //   headerName: 'Tenure',
    //   renderCell: (props: GridRenderCellParams) => {
    //     return '-';
    //   },
    //   flex: 1,
    // },
    {
      field: 'amount',
      headerName: 'Amount',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as ColendingLoan;
        var displayText = '-';
        if (application.approvedLoanAmount && application.colendingRatio) {
          displayText = `₹ ${StringUtility.formatCurrency(
            application.approvedLoanAmount * (application.colendingRatio / 100)
          )}`;
        }
        return displayText;
      },
      flex: 1,
    },

    {
      field: 'status',
      headerName: 'Status',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendingLoan;
        return <LosStatusPill status={row.engagementStatus} />;
      },
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendingLoan;
        return (
          <LosButton
            variant="outlined"
            size="small"
            text="Review"
            onClick={() => {
              const metaData: Record<string, any> = {};
              metaData['APPLICATION'] = row;
              navigateToRoute(
                ColendingLoanOffersRoute.ReviewApplication,
                metaData
              );
            }}
          />
        );
      },
      flex: 1,
    },
  ];

  useEffect(() => {
    fetchApplications();
  }, [pageSize, page, selectedFilter]);

  return (
    <div>
      <SolfinAppBar
        title={'All Offers'}
        renderActions={() => {
          return (
            <Grid container alignItems="end" justifyContent={'end'}>
              <Grid item xs={6}>
                <Stack>
                  <LosSelect
                    options={filterOptions()}
                    key="status"
                    value={selectedFilter}
                    onChange={(e) => {
                      setSelectedFilter(e.target.value as string);
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          );
        }}
      />
      <div style={{ margin: '0.75rem' }}>
        <LosDataGrid
          data={applications}
          columns={COLUMN_DEF}
          idColumn={'id'}
          loading={loading}
          onPageChange={setPage}
          rowCount={rowCount}
        />
      </div>
    </div>
  );
}

export default ColendingLoanOffers;

const filterOptions = () =>
  ['ALL', 'PENDING', 'APPROVED'].map((item) => {
    const option: LosSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
