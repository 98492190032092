import { styled, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode, useRef } from 'react';
import { useState } from 'react';
import PdfPreview from '../../../components/PdfPreview';
import { UploadIcon } from '../../icons/LosIcons';
import LosText from './LosText';

interface LosFileSelectProps {
  acceptedExtension?: string;
  placeholder: string;
  onFileSelect?: (file: File) => void;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  disabled?: boolean;
}

const StyledSelectField = styled(Box)(({ theme }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return {
    border: '1px dashed rgba(208, 212, 218, 1)',
    borderRadius: '0.375rem',
    padding: '0.5rem 1rem 0.5rem 0.75rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    maxHeight: '40px',
    minHeight: '40px',
    textOverflow: 'ellipsis',
    cursour: 'pointer',
    backgroundColor: 'rgba(250, 250, 250, 1)',
    ...(isMobile == false && {
      backgroundColor: 'white',
    }),
  };
});

function LosFileSelect({
  onFileSelect,
  endIcon,
  placeholder,
  acceptedExtension,
  startIcon,
  disabled = false,
}: LosFileSelectProps) {
  const [filename, setFilename] = useState('');
  const inputId = `file-input-${Math.random().toString(36).substring(7)}`;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: any) => {
    if (disabled == true) return;
    const file = event.target.files?.[0];
    if (file) {
      setFilename(file.name);
      onFileSelect?.(file);
    }
  };

  const handleEndIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getEndIcon = () => {
    if (endIcon) return endIcon;
    if (filename.length > 0) return <UploadIcon color="#36913F" />;
    return <UploadIcon />;
  };

  return (
    <StyledSelectField>
      <label
        htmlFor={inputId}
        style={{
          flex: 1,
          fontFamily: 'Aspekta !important',
          textOverflow: 'ellipsis',
          msTextOverflow: 'ellipsis',
          overflow: 'hidden',
          maxHeight: '32px',
          minHeight: '32px',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            maxHeight: '32px',
            minHeight: '32px',
            width: '100%',
            height: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {startIcon && (
            <div
              style={{
                padding: '4px',
                height: '32px',
                width: '32px',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'start',
              }}
            >
              {startIcon}
            </div>
          )}
          <LosText
            text={filename || placeholder}
            fontSize={'0.8125rem'}
            lineHeight={'120%'}
            textOverflow="ellipsis"
            overflow="hidden"
            sx={{
              height: '100%',
              width: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          />
        </div>
      </label>
      <div onClick={handleEndIconClick}>{getEndIcon()}</div>
      <input
        id={inputId}
        ref={fileInputRef}
        type="file"
        disabled={disabled}
        accept={acceptedExtension ?? '.pdf'}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </StyledSelectField>
  );
}

export default LosFileSelect;

type ReadOnlyLosFileSelectProps = {
  fileName?: string;
  onDownload?: () => void;
};
export const ReadOnlyLosFileSelect = ({
  fileName,
  onDownload,
}: ReadOnlyLosFileSelectProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  if (isMobile) {
    return (
      <LosFileSelect placeholder={fileName ?? ''} endIcon={<></>} disabled />
    );
  }

  return (
    <PdfPreview
      onDownload={() => {
        onDownload?.();
      }}
      fileName={fileName ?? ''}
    />
  );
};
