import { Box, Grid, Typography, Skeleton } from '@mui/material'
import {
  IAadharDetailsProps,
  IPanDetailsProps,
} from '../../../../../types/application'
import VegaHeader from '../../../../../../components/common/VegaHeader'
import { COMMON } from '../../../../../../constants/style'
import { dateFormat, timeFormat } from '../../../../../../mcc/variable'
import { GREEN, RED } from '../../../../../components/colors/fontColor'
import { toLowerCase } from '../../../../../../constants/commonFunction'
import { StringUtility } from '../../../../../onboarding/utils/StringUtility'
import { useState, useEffect } from 'react'
import { fetchAadharDetails } from '../../../../../APIs/LMS_Agent/applications'
import { AadharMetaData } from '../../../../../onboarding/types/Los'

interface IProps {
  aadhaarId: string | null | undefined
  header?: string
  subHeader?: string
  aadhaarInfo: Partial<AadharMetaData>
  isLoading?: boolean
}

const AADHAR = {
  id: '',
  createdAt: '',
  updatedAt: '',
  customerId: '',
  applicationId: '',
  name: '',
  uid: '',
  dob: '',
  gender: '',
  address: '',
  photo: '',
  splitAddress: '',
  eaadhaar: true,
  status: '',
}

const KYCDetailsAadharContainer = ({
  aadhaarId,
  header,
  subHeader,
  aadhaarInfo,
  isLoading = false,
}: IProps) => {
  // const [isLoading, setLoadingAadhar] = useState<boolean>(false)
  // const [aadharDetails, setAadharDetails] = useState<IAadharDetailsProps>({
  //   ...AADHAR,
  // })

  // const getAadharDetails = () => {
  //   if (aadhaarId) {
  //     fetchAadharDetails(aadhaarId)
  //       .then(res => {
  //         setAadharDetails(res.data)
  //         setLoadingAadhar(false)
  //       })
  //       .catch(error => {
  //         setLoadingAadhar(false)
  //       })
  //   } else {
  //     setLoadingAadhar(false)
  //   }
  // }

  // useEffect(() => {
  //   setLoadingAadhar(true)
  //   const aadharInfo = setTimeout(() => {
  //     getAadharDetails()
  //   }, 500)
  //   return () => {
  //     clearTimeout(aadharInfo)
  //   }
  // }, [])
  return (
    <>
      {header && (
        <Typography
          className='font-aspekta-500'
          fontSize={14}
          style={{ textDecoration: 'underline' }}
        >
          {header}
        </Typography>
      )}
      <Box py={2}>
        <Box mb={2}>
          {subHeader && (
            <Typography
              className='font-aspekta-700'
              sx={{
                textTransform: 'uppercase',
                fontSize: 10,
                letterSpacing: '0.07em',
                textDecoration: 'underline',
                mb: 1,
                color: 'black',
              }}
            >
              {subHeader}
            </Typography>
          )}
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <VegaHeader text='Name' />
              {isLoading ? (
                <Skeleton />
              ) : (
                <Typography
                  className='font-aspekta-400'
                  color={COMMON.gray}
                  fontSize={12}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  whiteSpace={'nowrap'}
                >
                  {/* {aadharDetails.name ? aadharDetails.name : '--'} */}
                  {aadhaarInfo?.name ? aadhaarInfo.name : '--'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <VegaHeader text='aadhar number' />
              {isLoading ? (
                <Skeleton />
              ) : (
                <Typography
                  className='font-aspekta-400'
                  color={COMMON.gray}
                  fontSize={12}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  whiteSpace={'nowrap'}
                >
                  {/* {aadharDetails.uid ? aadharDetails.uid : '--'} */}
                  {aadhaarInfo?.uid ? aadhaarInfo.uid : '--'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <VegaHeader text='Date of bith/year' />
              {isLoading ? (
                <Skeleton />
              ) : (
                <Typography
                  className='font-aspekta-400'
                  color={COMMON.gray}
                  fontSize={12}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  whiteSpace={'nowrap'}
                >
                  {/* {aadharDetails.dob ? dateFormat(aadharDetails.dob) : '--'} */}
                  {aadhaarInfo?.dob ? dateFormat(aadhaarInfo.dob) : '--'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <VegaHeader text='status' />
              {isLoading ? (
                <Skeleton />
              ) : (
                <Typography
                  className='font-aspekta-400'
                  color={
                    aadhaarInfo?.status === 'APPROVED' ? GREEN.green : RED.red
                  }
                  fontSize={12}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  whiteSpace={'nowrap'}
                >
                  {/* {aadharDetails.status
                    ? toLowerCase(aadharDetails.status)
                    : '--'} */}
                  {aadhaarInfo?.status ? toLowerCase(aadhaarInfo.status) : '--'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <VegaHeader text='gender' />
              {isLoading ? (
                <Skeleton />
              ) : (
                <Typography
                  className='font-aspekta-400'
                  color={COMMON.gray}
                  fontSize={12}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  whiteSpace={'nowrap'}
                >
                  {/* {aadharDetails.gender
                    ? aadharDetails.gender === 'M'
                      ? 'Male'
                      : aadharDetails.gender === 'F'
                      ? 'Female'
                      : 'Other'
                    : '--'} */}
                  {aadhaarInfo?.gender
                    ? aadhaarInfo.gender === 'M'
                      ? 'Male'
                      : aadhaarInfo.gender === 'F'
                      ? 'Female'
                      : 'Other'
                    : '--'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <VegaHeader text='submitted' />
              {isLoading ? (
                <Skeleton />
              ) : (
                <Typography
                  className='font-aspekta-400'
                  color={COMMON.gray}
                  fontSize={12}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  whiteSpace={'nowrap'}
                >
                  {/* {aadharDetails.status ? aadharDetails.status : '--'} */}
                  {aadhaarInfo?.status ? aadhaarInfo.status : '--'}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <VegaHeader text='current Address' />
            {isLoading ? (
              <Skeleton />
            ) : (
              <Typography
                className='font-aspekta-400'
                color={COMMON.gray}
                fontSize={12}
                maxWidth={'200px'}
              >
                {/* {StringUtility.formatAadhaarAddressToReadable(
                  aadharDetails.splitAddress
                )} */}
                {StringUtility.formatAadhaarAddressToReadable(
                  aadhaarInfo?.splitAddress
                )}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <VegaHeader text='permanent Address' />
            {isLoading ? (
              <Skeleton />
            ) : (
              <Typography
                className='font-aspekta-400'
                color={COMMON.gray}
                fontSize={12}
                maxWidth={'200px'}
              >
                {StringUtility.formatAadhaarAddressToReadable(
                  aadhaarInfo?.splitAddress
                )}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <VegaHeader text='Aadhaar Acceptance' />
            {isLoading ? (
              <Skeleton />
            ) : (
              <Typography
                className='font-aspekta-400'
                color={COMMON.gray}
                fontSize={12}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
              >
                {aadhaarInfo?.updatedAt
                  ? `${dateFormat(aadhaarInfo.updatedAt)}, ${timeFormat(
                      aadhaarInfo?.updatedAt
                    )}`
                  : '--'}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default KYCDetailsAadharContainer
