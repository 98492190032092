import React from 'react';
import { Typography, Box, Stack, Tooltip, Skeleton } from '@mui/material';
import LosText from './LosText';
import { InfoOutlined } from '@mui/icons-material';
import { PRIMARY } from '../../constants/style';

interface ITextDatailsWrapperProps {
  name: string;
  value: string | number;
  valueColor?: string;
  fontWeight?: number;
  fontSize?: string;
  isInfo?: boolean;
  loading?: boolean;
  info?: JSX.Element;
}

const LosDetailsWrapper = ({
  name,
  value,
  valueColor = '#36913F',
  isInfo,
  fontWeight = 500,
  fontSize = '0.875rem',
  loading,
  info = <></>,
}: ITextDatailsWrapperProps) => {
  return (
    <>
      <Box>
        <LosText
          text={name}
          sx={{ textTransform: 'uppercase', letterSpacing: '0.5px', mb: 0.3 }}
          color={'#676B76'}
          fontWeight={700}
          fontSize={'0.625rem'}
        />
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          {loading ? (
            <Skeleton width={'100%'} />
          ) : (
            <>
              <LosText
                text={value}
                color={valueColor}
                fontWeight={fontWeight}
                fontSize={fontSize}
              />
              {isInfo && (
                <Tooltip
                  title={info}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: PRIMARY.main,
                        '& .MuiTooltip-arrow': {
                          color: 'common.black',
                        },
                      },
                    },
                  }}
                >
                  <InfoOutlined sx={{ color: valueColor, fontSize: 12 }} />
                </Tooltip>
              )}
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default LosDetailsWrapper;
