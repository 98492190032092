import { Box, Grid, Skeleton, Typography } from '@mui/material'
import VegaHeader from '../../../../../../components/common/VegaHeader'
import { toLowerCase } from '../../../../../../constants/commonFunction'
import { COMMON } from '../../../../../../constants/style'
import { dateFormat, timeFormat } from '../../../../../../mcc/variable'
import { GREEN, RED } from '../../../../../components/colors/fontColor'
import { PanMetadata } from '../../../../../onboarding/types/Los'

interface IProps {
  data: Partial<PanMetadata>
  loading: boolean
}

const KYCDetailsPanContainer = ({ data: data, loading }: IProps) => {
  return (
    <>
      <Typography
        className='font-aspekta-500'
        fontSize={14}
        style={{ textDecoration: 'underline' }}
      >
        PAN Details
      </Typography>
      <Box py={2}>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <VegaHeader text='Name' />
            {loading ? (
              <Skeleton />
            ) : (
              <Typography
                className='font-aspekta-400'
                color={COMMON.darkGray}
                fontSize={12}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
              >
                {data?.name ? data?.name : '--'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <VegaHeader text='PAN number' />
            {loading ? (
              <Skeleton />
            ) : (
              <Typography
                className='font-aspekta-400'
                color={COMMON.darkGray}
                fontSize={12}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
              >
                {data?.number ? data?.number : '--'}
              </Typography>
            )}
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <VegaHeader text='status' />
            {loading ? (
              <Skeleton />
            ) : (
              <Typography
                className='font-aspekta-400'
                color={data?.panStatus === 'VALID' ? GREEN.green : RED.red}
                fontSize={12}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
              >
                {data?.panStatus ? toLowerCase(data?.panStatus) : '--'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <VegaHeader text='Bureau Acceptance' />
            {loading ? (
              <Skeleton />
            ) : (
              <Typography
                className='font-aspekta-400'
                color={COMMON.darkGray}
                fontSize={12}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
              >
                {data?.updatedAt
                  ? `${dateFormat(data?.updatedAt)}, ${timeFormat(
                      data?.updatedAt
                    )}`
                  : '--'}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default KYCDetailsPanContainer
