import { styled, TextField, TextFieldProps } from '@mui/material';

interface VegaTextFieldPropsV2 {}

const StyledTextField = styled(TextField)(({ theme, multiline }) => {
  return {
    '& .MuiInputBase-root': {
      minHeight: '32px',
      maxHeight: '32px',
      borderRadius: '.5rem',
      ...(multiline && {
        maxHeight: 'none',
      }),
    },
  };
});

function VegaTextFieldV2({ ...rest }: VegaTextFieldPropsV2 & TextFieldProps) {
  return <StyledTextField {...rest} />;
}

export default VegaTextFieldV2;
