import { LosUserType } from '../../../types/Los';
import IndividualKycDetailsPreview from './IndividualKycDetailsPreview';
import NonIndividualKycDetailsPreview from './NonIndividualKycDetailsPreview';

type Props = { userType?: string; viewerType: 'Customer' | 'HQ' };

function KycDetailsPreview({ userType, viewerType }: Props) {
  if (userType == 'INDIVIDUAL' || userType == LosUserType.SELF_EMPLOYED) {
    return <IndividualKycDetailsPreview viewerType={viewerType} />;
  } else if (userType == 'NON_INDIVIDUAL') {
    return <NonIndividualKycDetailsPreview viewerType={viewerType} />;
  }

  return <></>;
}

export default KycDetailsPreview;
