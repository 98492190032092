import { Box, Stack, Grid } from '@mui/material';
import { GREEN } from '../../../../../constants/style';
import { useRuleEngine } from '../../../../LMSAgent/Provider/RuleEngingProvider';
import ComplianceList from './components/ComplianceList';
import { EditOutlined, InfoOutlined } from '@mui/icons-material';
import WeightScoreWrapper from '../../WeightScoreWrapper';
import LosFormInputField from '../../../../onboarding/components/common/LosFormInputField';
import LosSelect, {
  LosSelectOption,
} from '../../../../onboarding/components/common/LosSelect';
import _ from 'lodash';
import LosDetailsWrapper from '../../../LosDetailsWrapper';
import LosButton from '../../../../onboarding/components/common/LosButton';

const ComplianceRule = () => {
  const {
    loading,
    underWrittingScrore,
    vetoStatus,
    handleVetoStatusChange,
    isVetoEdit,
    setIsVetoEdit,
    onUpdateVetoStatus,
  } = useRuleEngine();
  return (
    <Stack justifyContent={'space-between'} height={'100%'}>
      <Box>
        <WeightScoreWrapper
          value={
            underWrittingScrore?.underwritingComplianceScore
              ? underWrittingScrore?.underwritingComplianceScore.weightedPoints
              : 0
          }
        />
        <Box
          sx={{
            pb: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={2.4} xl={2.4}>
              {isVetoEdit ? (
                <LosFormInputField label={'Criteria type'}>
                  <LosSelect
                    options={vetoOption()}
                    value={vetoStatus}
                    onChange={(e) =>
                      handleVetoStatusChange(e.target.value as string)
                    }
                  />
                </LosFormInputField>
              ) : (
                <LosDetailsWrapper
                  name={'Criteria type'}
                  value={
                    underWrittingScrore?.underwritingComplianceScore
                      ? underWrittingScrore?.underwritingComplianceScore
                          .veto === true
                        ? 'Yes'
                        : 'No'
                      : '--'
                  }
                  valueColor={'#1B1D22'}
                  loading={loading}
                  fontWeight={400}
                  fontSize="0.75rem"
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            pb: 2,
          }}
        >
          <ComplianceList />
        </Box>
      </Box>
      <Box py={2} borderTop={`1px solid #E1E4EB`} textAlign={'end'}>
        {!isVetoEdit ? (
          <LosButton
            text="Edit"
            variant="outlined"
            startIcon={<EditOutlined />}
            onClick={() => setIsVetoEdit(true)}
          />
        ) : (
          <Stack direction={'row'} gap={1} justifyContent={'end'}>
            <LosButton
              text="Save"
              variant="outlined"
              onClick={onUpdateVetoStatus}
            />
            <LosButton text="Cancel" onClick={() => setIsVetoEdit(false)} />
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default ComplianceRule;

const vetoOption = () =>
  ['YES', 'NO'].map((item) => {
    const option: LosSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
