import {
  BreakfastDiningRounded,
  CancelOutlined,
  CheckCircleOutline,
  Close,
  Info,
  KeyboardArrowRight,
  Tag,
} from '@mui/icons-material'
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Drawer,
  Tooltip,
} from '@mui/material'
import { useEffect, useState } from 'react'
import VegaContainedButton from '../../../../components/common/VegaContainedButton'
import VegaHeader from '../../../../components/common/VegaHeader'
import VegaLabelWrapper from '../../../../components/common/VegaLabelWrapper'
import VegaOutlineButton from '../../../../components/common/VegaOutlineButton'
import VegaTab from '../../../../components/common/VegaTab'
import { toLowerCase } from '../../../../constants/commonFunction'
import { BORDER_GRAY, COMMON, GREY, PRIMARY } from '../../../../constants/style'
import {
  getApplicationsDetails,
  setApplicationsDetails,
  setIsShow,
} from '../../../../store/common/agentApplicationsSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import {
  fetchApplicationById,
  updateApplication,
  updateApplicationStatusForApproveReject,
  updateStatus,
} from '../../../APIs/LMS_Agent/applications'
import LOSModal, { ILOSButtonsProps } from '../../../components/LOSModal'
import LoanDetails from '../../../components/LoanDetails/LoanDetails'
import PaymentDetails from '../../../components/PaymentDetails/PaymentDetails'
import PersonalDetails from '../../../components/PersonalDetails/PersonalDetails'
import ScripsAndCmsDetails from '../../../components/ScripsAndCmsDetails/ScripsAndCmsDetails'
import {
  AgentReasonType,
  ApplicationReviewDetailsEnum,
  ApplicationReviewDetailsType,
  ApplicationReviewDetailsTypeForCompany,
  HQReasonType,
  LOSEntityType,
  ReasonType,
} from '../../../enums/applications'
import { useLmsAgentRouter } from '../../Provider/LmsAgentRouter'
import CollateralAndGurantor from './components/CollateralAndGurantor/CollateralAndGurantor'
import CompanyDetails from './components/CompanyDetails/CompanyDetails'
import ElectricityDetails from './components/ElectricityDetails/ElectricityDetails'
import FinacialDetails from './components/FinacialDetails/FinacialDetails'
import FinacialDetailsForPartnership from './components/FinacialDetails/FinacialDetailsForPartnership'
import KYCDetails from './components/KYCDetails/KYCDetails'
import KYCDetailsForPartnership from './components/KYCDetails/KYCDetailsForPartnership'
import AgentDetailsConfirm from './components/Selfie/AgentDetailsConfirm'
import { useSnackbar } from '../../../../providers/SnackbarProvider'
import { getErrorMessageFromErrorObj } from '../../../../utils/api'
import LendingDetailsDialog from '../../../LMSHQ/components/AllApplication/LendingDetailsDialog'
import {
  ColenderService,
  RegisterLoanForEnagementApiData,
} from '../../../LMSHQ/api/ColenderService'
import {
  ApplicationStatus,
  LendingOfferFormData,
  LosUserType,
} from '../../../onboarding/types/Los'
import { getPreDisbursalDetails } from '../../../../store/common/preDisburseForAgentSlice'
import { StringUtility } from '../../../onboarding/utils/StringUtility'
import { saveSelfieMatch } from '../../../APIs/LMS_Agent/leads'
import { LOS_PROGRAM_ID } from '../../../onboarding/api/constants'
import LosStatusPill from '../../../components/LosStatusPill'
import AgentDetailsReject from './components/Selfie/AgentDetailsReject'
import { RED } from '../../../components/colors/fontColor'
import BureauDetailsTab from '../../../components/Bureau/BureauDetailsTab'
import LosText from '../../../onboarding/components/common/LosText'
import _ from 'lodash'
import { ReadOnlyFormField } from '../../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField'
import RuleEngine from '../../../components/RuleEngine/RuleEngine'
import { RuleEngingProvider } from '../../Provider/RuleEngingProvider'
import { LosService } from '../../../onboarding/api/LosService'

export enum ViewSource {
  AgentApplications,
  HqApplications,
  HqPredisbursement,
}

export interface IReasonType {
  reason: string
  comment: string
}
type Props = {
  viewSource: ViewSource
}

const AGENT_INDIVIDUAL_TABS: ApplicationReviewDetailsEnum[] = [
  ApplicationReviewDetailsEnum.KYC_DETAILS,
  ApplicationReviewDetailsEnum.FINANCIAL_DETAILS,
  ApplicationReviewDetailsEnum.COLLATERAL,
  ApplicationReviewDetailsEnum.ELECTRICITY_DETAILS,
]

const AGENT_NON_INDIVIDUAL_TABS: ApplicationReviewDetailsEnum[] = [
  ApplicationReviewDetailsEnum.KYC_DETAILS,
  ApplicationReviewDetailsEnum.COMPANY_DETAILS,
  ApplicationReviewDetailsEnum.FINANCIAL_DETAILS,
  ApplicationReviewDetailsEnum.COLLATERAL,
  ApplicationReviewDetailsEnum.ELECTRICITY_DETAILS,
]

const HQ_INDIVIDUAL_TABS: ApplicationReviewDetailsEnum[] = [
  ApplicationReviewDetailsEnum.KYC_DETAILS,
  ApplicationReviewDetailsEnum.FINANCIAL_DETAILS,
  ApplicationReviewDetailsEnum.COLLATERAL,
  ApplicationReviewDetailsEnum.ELECTRICITY_DETAILS,
  ApplicationReviewDetailsEnum.BUREAU_DETAILS,
  // ApplicationReviewDetailsEnum.RULE_ENGINE,
]

const HQ_NON_INDIVIDUAL_TABS: ApplicationReviewDetailsEnum[] = [
  ApplicationReviewDetailsEnum.KYC_DETAILS,
  ApplicationReviewDetailsEnum.COMPANY_DETAILS,
  ApplicationReviewDetailsEnum.FINANCIAL_DETAILS,
  ApplicationReviewDetailsEnum.COLLATERAL,
  ApplicationReviewDetailsEnum.ELECTRICITY_DETAILS,
  ApplicationReviewDetailsEnum.BUREAU_DETAILS,
  // ApplicationReviewDetailsEnum.RULE_ENGINE,
]

const HQ_PRE_DISBURSE_INDIVIDUAL_TABS: ApplicationReviewDetailsEnum[] = [
  ApplicationReviewDetailsEnum.KYC_DETAILS,
  ApplicationReviewDetailsEnum.FINANCIAL_DETAILS,
  ApplicationReviewDetailsEnum.COLLATERAL,
  ApplicationReviewDetailsEnum.ELECTRICITY_DETAILS,
  ApplicationReviewDetailsEnum.BUREAU_DETAILS,
]

const HQ_PRE_DISBURSE_NON_INDIVIDUAL_TABS: ApplicationReviewDetailsEnum[] = [
  ApplicationReviewDetailsEnum.KYC_DETAILS,
  ApplicationReviewDetailsEnum.COMPANY_DETAILS,
  ApplicationReviewDetailsEnum.FINANCIAL_DETAILS,
  ApplicationReviewDetailsEnum.COLLATERAL,
  ApplicationReviewDetailsEnum.ELECTRICITY_DETAILS,
  ApplicationReviewDetailsEnum.BUREAU_DETAILS,
]

const ApplicationReview = ({ viewSource }: Props) => {
  const dispatch = useAppDispatch()
  const { setSnackbar } = useSnackbar()
  const { navigateToApplicationHome, applicationId } = useLmsAgentRouter()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isLaptopScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const { applicationDetails, isApplicationForHQ, isShow } = useAppSelector(
    getApplicationsDetails
  )
  const { preDisbursalDetails } = useAppSelector(getPreDisbursalDetails)
  const [selectedDetail, setSelectedDetail] = useState<string>(
    ApplicationReviewDetailsEnum.KYC_DETAILS
  )

  const [show, setShow] = useState<boolean>(false)
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [isReject, setIsReject] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [isDisable, setIsDisable] = useState<boolean>(true)
  const [img, setImg] = useState<string | null>(null)
  const [selectedReason, setSelectedReason] = useState<IReasonType>({
    reason: isApplicationForHQ
      ? HQReasonType.CASH_FLOW_LIMITATION
      : AgentReasonType.ADHAAR_PAN_MISMATCH,
    comment: '',
  })

  const getAppicationReviewComponent = () => {
    const entityType = applicationDetails.losEntityType
    const isIndividual = entityType == LosUserType.INDIVIDUAL
    const isSelfEmployed = entityType == LosUserType.SELF_EMPLOYED

    switch (selectedDetail) {
      case ApplicationReviewDetailsEnum.RULE_ENGINE:
        return (
          <RuleEngingProvider>
            <RuleEngine />
          </RuleEngingProvider>
        )
      case ApplicationReviewDetailsEnum.BUREAU_DETAILS:
        return <BureauDetailsTab />
      case ApplicationReviewDetailsEnum.PERSONAL_DETAILS:
        return <PersonalDetails />
      case ApplicationReviewDetailsEnum.PAYMENT_DETAILS:
        return <PaymentDetails />
      case ApplicationReviewDetailsEnum.LOAN_DETAILS:
        return <LoanDetails />
      case ApplicationReviewDetailsEnum.SCRIPS_AND_CMS_DETAILS:
        return <ScripsAndCmsDetails />
      case ApplicationReviewDetailsEnum.KYC_DETAILS:
        if (isIndividual || isSelfEmployed) {
          return <KYCDetails />
        }
        return <KYCDetailsForPartnership />
      case ApplicationReviewDetailsEnum.COMPANY_DETAILS:
        return <CompanyDetails getAllApplicationById={getAllApplicationById} />
      case ApplicationReviewDetailsEnum.FINANCIAL_DETAILS:
        if (isIndividual) {
          return (
            <FinacialDetails getAllApplicationById={getAllApplicationById} />
          )
        }
        return (
          <FinacialDetailsForPartnership
            getAllApplicationById={getAllApplicationById}
          />
        )

      case ApplicationReviewDetailsEnum.COLLATERAL:
        return (
          <CollateralAndGurantor
            onUpdateApplication={onUpdateApplication}
            getAllApplicationById={getAllApplicationById}
          />
        )

      default:
        return (
          <ElectricityDetails getAllApplicationById={getAllApplicationById} />
        )
    }
  }

  const onUpdateApplication = () => {
    updateApplication(applicationDetails)
      .then(res => {
        setSnackbar('Update Successfully !!')
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      })
  }

  const isCheckHQ = () => {
    if (isApplicationForHQ) {
      dispatch(setIsShow(true))
    } else {
      open()
    }
  }

  const open = () => {
    setShow(!show)
  }

  const onClose = () => {
    setIsApproved(!isApproved)
  }

  function onCancelClickInConfirmDialog() {
    setImg(null)
    onClose()
  }

  async function uploadAgentSelfie(data: { agentId: string; img: string }) {
    try {
      const formData = new FormData()
      const byteString = atob(data.img.split(',')[1])
      const ab = new ArrayBuffer(byteString.length)
      let ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      const blob = new Blob([ab], { type: 'image/png' })
      formData.append('selfie', blob)
      const response = await saveSelfieMatch(data.agentId, formData)
      return response
    } catch (error) {
      throw error
    }
  }

  const buttons: ILOSButtonsProps[] = [
    {
      text: 'Confirm',
      icon: '',
      onSubmit: () => {
        onClose()
      },
      disabled: img === null,
      isOutline: false,
      color: 'purple',
    },
  ]
  const buttonsWrapper: ILOSButtonsProps[] = [
    {
      text: 'Retake',
      icon: '',
      onSubmit: () => {
        setImg(null)
      },
      disabled: isDisable,
      isOutline: false,
      color: 'purple',
    },
    {
      text: 'Confirm',
      icon: '',
      onSubmit: async () => {
        open()
        onClose()
      },
      disabled: isDisable,
      isOutline: false,
      color: 'purple',
    },
  ]
  const rejectButtons: ILOSButtonsProps[] = [
    {
      text: 'Cancel',
      icon: '',
      onSubmit: () => {
        onRejectOpen()
      },
      disabled: false,
      isOutline: true,
      color: 'purple',
    },
    {
      text: 'Reject',
      icon: '',
      onSubmit: async () => {
        await onRejectClick()
        onRejectOpen()
      },
      disabled: false,
      isOutline: false,
      color: 'error',
    },
  ]

  const getAllApplicationById = () => {
    if (applicationId) {
      fetchApplicationById(applicationId)
        .then(res => {
          if (res.status !== 200) {
            throw new Error('failed to load content')
          }
          dispatch(setApplicationsDetails(res.data))
          setLoading(false)
        })
        .catch(error => {
          setSnackbar('failed to load content', 'error')
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }

  async function updateApplicationStatusToApproved(
    applicationId: string,
    agentId: string
  ) {
    try {
      const response = await updateStatus(applicationId, 'APPROVED', agentId)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async function onApproveClick() {
    try {
      const agentId = applicationDetails.agentId
      const selfie = img
      if (!agentId || !selfie || !applicationId) {
        setSnackbar('Something Went Wrong', 'error')
        console.error(
          `Agent ${agentId} || Selfie: ${selfie} || applicationId: ${applicationId} not found`
        )
        return
      }
      setLoading(true)
      const selfieResponse = await uploadAgentSelfie({
        agentId: agentId,
        img: selfie,
      })

      const updatedStatusResponse = await updateApplicationStatusToApproved(
        applicationId,
        agentId
      )
      navigateToApplicationHome()
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  function onRejectClick() {
    if (!applicationId) return
    setLoading(true)
    const reason =
      selectedReason.reason === ReasonType.OTHER_REASON_WITH_COMMENT
        ? selectedReason.comment
        : selectedReason.reason
    updateApplicationStatusForApproveReject(applicationId, 'REJECTED', reason)
      .then(res => {
        navigateToApplicationHome()
      })
      .catch(error => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const onRejectOpen = () => {
    setIsReject(!isReject)
  }

  useEffect(() => {
    setLoading(true)
    const applicationById = setTimeout(() => {
      getAllApplicationById()
    }, 500)
    return () => {
      clearTimeout(applicationById)
    }
  }, [dispatch])

  async function onSendOfferClick(
    applicationId: string,
    requestedLoanAmount: number,
    formData: Partial<LendingOfferFormData>
  ) {
    var approvedLoanAmount
    if (formData.approvedLoanAmount) {
      approvedLoanAmount = Number(formData.approvedLoanAmount)
    }
    var rateOfInterest
    if (formData.rateOfInterest) {
      rateOfInterest = Number(formData.rateOfInterest)
    }
    var colendingRatio = 0
    if (formData.loanType == 'COLENDED' && formData.colenderRatio) {
      const ratio = formData.colenderRatio.split(':')[0]
      if (ratio) {
        colendingRatio = Number(ratio)
      }
    }
    const updatedEngagement: Partial<RegisterLoanForEnagementApiData> = {
      applicationId: applicationId,
      status: 'PENDING',
      requestedLoanAmount: requestedLoanAmount,
      colenderId: formData.colenderId,
      lendingType: formData.loanType,
      colendingRatio: colendingRatio,
      approvedLoanAmount: approvedLoanAmount,
      primaryRoi: rateOfInterest,
      programId: LOS_PROGRAM_ID,
    }
    try {
      ColenderService.registerLoanForEngagement(updatedEngagement)
        .then(res => {
          LosService.updateNextStep(applicationId)
        })
        .catch(err => {
          setSnackbar('Failed to register loan', 'error')
        })

      dispatch(setIsShow(false))
      navigateToApplicationHome()
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      console.error(error)
    }
  }

  const canShowApproveButtons = () => {
    if (viewSource == ViewSource.HqApplications) {
      const isUnderWritingStatusPending =
        preDisbursalDetails.underwritingStatus == 'PENDING'
      const isApplicationStatusApproved =
        applicationDetails.status == ApplicationStatus.APPROVED

      return isUnderWritingStatusPending && isApplicationStatusApproved
    }

    if (viewSource == ViewSource.AgentApplications) {
      const isApplicationStatusPending =
        applicationDetails.status == ApplicationStatus.PENDING

      return isApplicationStatusPending
    }

    return false
  }

  const getApplicationTabs = () => {
    const isEntityNonIndividual = !(
      applicationDetails.losEntityType === LOSEntityType.INDIVIDUAL ||
      applicationDetails.losEntityType === LOSEntityType.SELF_EMPLOYED
    )

    switch (viewSource) {
      case ViewSource.HqApplications:
        if (isEntityNonIndividual) {
          return HQ_NON_INDIVIDUAL_TABS
        }
        return HQ_INDIVIDUAL_TABS
      case ViewSource.HqPredisbursement:
        if (isEntityNonIndividual) {
          return HQ_PRE_DISBURSE_NON_INDIVIDUAL_TABS
        }
        return HQ_PRE_DISBURSE_INDIVIDUAL_TABS
      case ViewSource.AgentApplications:
        if (isEntityNonIndividual) {
          return AGENT_NON_INDIVIDUAL_TABS
        }
        return AGENT_INDIVIDUAL_TABS
    }
  }

  const getUserTypeDisplayText = () => {
    var displayText = ''
    const subType = applicationDetails.basicInfo?.subType

    if (subType) {
      displayText += `${_.startCase(_.toLower(subType))}`
    }

    if (displayText.length <= 0) displayText = '-'
    return displayText
  }

  const getCustomerNameDisplayText = () => {
    var displayText = '-'

    if (applicationDetails.customerName) {
      displayText = `${applicationDetails.customerName}`
    }
    return displayText
  }

  const getLoanTypeDisplayText = () => {
    var displayText = '-'

    if (applicationDetails.basicInfo?.loanType) {
      displayText = _.startCase(
        _.toLower(applicationDetails.basicInfo?.loanType)
      )
    }
    return displayText
  }

  const getApplicationRejectionReasonsDisplayText = () => {
    const reasons = applicationDetails.rejectionReason ?? []

    var displayText = ''

    reasons.forEach((item, index) => {
      displayText += `${index + 1}. ${_.startCase(_.toLower(item))}`
    })

    if (displayText.length <= 0) displayText = '-'
    return displayText
  }

  return (
    <>
      <Grid
        container
        alignItems={'center'}
        borderBottom={`1px solid ${GREY.grey}`}
        sx={{
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          height: '3.75rem',
        }}
      >
        <Grid item display={'flex'} alignItems={'center'} xs={12} sm={6}>
          <Box
            className='font-aspekta-500'
            sx={{ cursor: 'pointer' }}
            color={COMMON.light}
            fontSize={12}
            onClick={() => navigateToApplicationHome()}
          >
            Applications
          </Box>
          <KeyboardArrowRight sx={{ mx: isMobile ? 0.5 : 2, fontSize: 14 }} />
          <Box
            sx={{
              ...(!isSmallScreen && { display: 'flex', alignItems: 'center' }),
            }}
          >
            <LosText fontSize={'0.75rem'} variant='caption'>
              Application id
            </LosText>
            {loading ? (
              <Skeleton width='100px' />
            ) : (
              <Stack marginLeft={'1rem'} direction={'row'} spacing='1rem'>
                <VegaLabelWrapper
                  text={applicationDetails.id}
                  color='purple'
                  icon={<Tag fontSize='small' />}
                />
                <LosStatusPill
                  status={applicationDetails.status}
                  renderEndAdornment={() => {
                    if (
                      applicationDetails.status == ApplicationStatus.REJECTED
                    ) {
                      return (
                        <Tooltip
                          title={getApplicationRejectionReasonsDisplayText()}
                        >
                          <Info />
                        </Tooltip>
                      )
                    }
                  }}
                />
              </Stack>
            )}
          </Box>
        </Grid>
        {!isMobile && (
          <Grid
            item
            display={canShowApproveButtons() ? 'flex' : 'none'}
            justifyContent={'end'}
            xs={12}
            sm={6}
            spacing={2}
          >
            <Stack direction={'row'} spacing={'1rem'}>
              <VegaContainedButton
                text='Approve'
                isSuccess
                py={1}
                startIcon={<CheckCircleOutline />}
                onClick={isCheckHQ}
              />
              <VegaContainedButton
                text='Reject'
                isDanger
                py={1}
                startIcon={<CancelOutlined />}
                onClick={onRejectOpen}
              />
            </Stack>
          </Grid>
        )}
      </Grid>
      <Box
        borderBottom={`1px solid ${GREY.grey}`}
        sx={{
          padding: '1.5rem',
        }}
      >
        <Grid container alignItems={'start'} rowGap='1rem' columnGap={'0.5rem'}>
          <Grid item sm={3} md={2} lg={1.5}>
            <ReadOnlyFormField
              title='Application Id'
              description={applicationDetails.id}
              loading={loading}
            />
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={1.5}>
            <ReadOnlyFormField
              title='Name'
              description={getCustomerNameDisplayText()}
              loading={loading}
            />
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={1.5}>
            <ReadOnlyFormField
              title='User Type'
              description={getUserTypeDisplayText()}
              loading={loading}
            />
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={1.5}>
            <ReadOnlyFormField
              title='Loan Type'
              description={getLoanTypeDisplayText()}
              loading={loading}
            />
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={1.5}>
            <ReadOnlyFormField
              title='Tenure'
              description={`${
                applicationDetails.basicInfo?.tenure ?? '-'
              } Months`}
              loading={loading}
            />
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={1.5}>
            <ReadOnlyFormField
              title='Required Loan Amount'
              description={`${StringUtility.formatCurrency(
                applicationDetails.basicInfo?.amount
              )}`}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <VegaTab
          width={isMobile || isSmallScreen || isLaptopScreen ? '150px' : 'auto'}
          placement='vertical'
          color={PRIMARY.darkBlue}
          tabData={getApplicationTabs()}
          // tabData={
          // applicationDetails.losEntityType !== LOSEntityType.INDIVIDUAL
          //   ? ApplicationReviewDetailsTypeForCompany
          //   : ApplicationReviewDetailsType
          // }
          selected={selectedDetail}
          onSelect={select => setSelectedDetail(select)}
        />
        <Box
          sx={{
            p:
              selectedDetail === ApplicationReviewDetailsEnum.RULE_ENGINE
                ? 0
                : 2,
            border: BORDER_GRAY,
            bgcolor: 'white',
            borderRadius: '10px',
          }}
        >
          {loading ? <Skeleton height={400} /> : getAppicationReviewComponent()}
        </Box>
      </Box>
      {isMobile && (
        <Box sx={{ px: 3, py: 2 }}>
          <Grid
            container
            alignItems={'center'}
            borderTop={`2px solid ${GREY.grey}`}
            spacing={2}
            display={'flex'}
          >
            <Grid item xs={5}>
              <VegaContainedButton
                text='Approve'
                sx={{ width: '100%' }}
                mx={0}
                isSuccess
                py={1}
                startIcon={<CheckCircleOutline />}
                onClick={isCheckHQ}
              />
            </Grid>
            <Grid item xs={5}>
              <VegaContainedButton
                text='Reject'
                sx={{ width: '100%' }}
                mx={0}
                isDanger
                py={1}
                startIcon={<CancelOutlined />}
                onClick={onRejectOpen}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {isMobile ? (
        <Drawer
          anchor={'bottom'}
          open={show}
          onClose={() => {
            open()
            setImg(null)
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
              py: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Stack direction={'row'} alignItems={'center'}>
                <label
                  className='font-aspekta-600'
                  style={{
                    fontSize: 17,
                    color: COMMON.darkGray,
                    lineHeight: '15.6px',
                    marginRight: '10px',
                  }}
                >
                  Confirm Agent Details
                </label>
              </Stack>
            </Box>
            <IconButton
              onClick={() => {
                open()
                setImg(null)
              }}
            >
              <Close sx={{ color: '#3A4A5F' }} />
            </IconButton>
          </Box>
          <AgentDetailsConfirm
            setIsDisable={setIsDisable}
            img={img}
            setImg={setImg}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              px: 2,
              py: 1.5,
              borderTop: `2px solid ${GREY.grey}`,
            }}
          >
            {img && (
              <VegaContainedButton
                text={'Retake'}
                isPurple
                py={1}
                onClick={() => setImg(null)}
              />
            )}
            <VegaContainedButton
              text={'Confirm'}
              isPurple
              py={1}
              disabled={img === null}
              onClick={async () => {
                open()
                onClose()
              }}
            />
          </Box>
        </Drawer>
      ) : (
        <LOSModal
          header='Confirm Agent Details'
          open={show}
          handleClose={() => {
            open()
            setImg(null)
          }}
          maxWidth='md'
          buttonGroup={img ? buttonsWrapper : buttons}
          status={null}
        >
          <AgentDetailsConfirm
            setIsDisable={setIsDisable}
            img={img}
            setImg={setImg}
          />
        </LOSModal>
      )}

      {isMobile ? (
        <Drawer anchor={'bottom'} open={isReject} onClose={onRejectOpen}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
              py: 1,
              // borderBottom: `2px solid ${GREY.grey}`,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Stack direction={'row'} alignItems={'center'}>
                <label
                  className='font-aspekta-600'
                  style={{
                    fontSize: 17,
                    color: COMMON.darkGray,
                    lineHeight: '15.6px',
                    marginRight: '10px',
                  }}
                >
                  Reject Application
                </label>
              </Stack>
            </Box>
            <IconButton onClick={onRejectOpen}>
              <Close sx={{ color: '#3A4A5F' }} />
            </IconButton>
          </Box>
          <AgentDetailsReject
            selectedReason={selectedReason}
            setSelectedReason={setSelectedReason}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              px: 2,
              py: 1.5,
              borderTop: `2px solid ${GREY.grey}`,
            }}
          >
            <VegaOutlineButton
              text={'Cancel'}
              isPurple
              py={1}
              onClick={onRejectOpen}
            />

            <VegaContainedButton
              text={'Reject'}
              isPrimary
              py={1}
              sx={{ bgcolor: RED.red }}
              onClick={async () => {
                await onRejectClick()
                onRejectOpen()
              }}
            />
          </Box>
        </Drawer>
      ) : (
        <LOSModal
          header='Reject Application'
          open={isReject}
          handleClose={() => {
            onRejectOpen()
          }}
          maxWidth='xs'
          buttonGroup={rejectButtons}
          status={null}
        >
          <AgentDetailsReject
            selectedReason={selectedReason}
            setSelectedReason={setSelectedReason}
          />
        </LOSModal>
      )}
      <Dialog open={isApproved} onClose={onClose} maxWidth='sm'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 2,
            py: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <label
              className='font-aspekta-400'
              style={{
                fontSize: 17,
                color: COMMON.darkGray,
                lineHeight: '15.6px',
                marginRight: '10px',
              }}
            >
              Are you sure you want to Approve the Application?
            </label>
          </Box>
          <IconButton onClick={onClose}>
            <Close sx={{ color: '#3A4A5F' }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            bgcolor: 'white',
            p: 2,
          }}
        >
          <Typography
            className='font-aspekta-400'
            fontSize={13}
            color={COMMON.gray}
          >
            By clicking on the approve your application will be submitted. Check
            if you have filled all the information correctly before approving
            the the application.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            px: 2,
            py: 1.5,
          }}
        >
          <VegaOutlineButton
            py={1}
            isDanger
            text={'Cancel'}
            onClick={onCancelClickInConfirmDialog}
          />
          <VegaContainedButton
            py={1}
            isPurple
            text={'Approve'}
            onClick={onApproveClick}
          />
        </Box>
      </Dialog>
      <LendingDetailsDialog
        open={isShow}
        applicationId={applicationDetails.id}
        onClose={function (): void {
          dispatch(setIsShow(false))
        }}
        onSubmit={onSendOfferClick}
      />
    </>
  )
}

export default ApplicationReview
