import { Grid, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSnackbar } from '../../../../providers/SnackbarProvider'
import { getErrorMessageFromErrorObj } from '../../../../utils/api'
import { LoanManagementService } from '../../../LMSHQ/api/LoanManagementService'
import { LosService } from '../../api/LosService'
import LosAppBar from '../../components/LosAppBar'
import { LosFormButtonView } from '../../components/LosFormButton'
import LosFormContainer from '../../components/LosFormContainer'
import LosText from '../../components/common/LosText'
import { useLos } from '../../providers/LosProvider'
import { AgreementFormData } from '../../types/Los'

enum InputType {
  SIGNED_DOCUMENT,
}

function UploadAgreement() {
  const { setSnackbar } = useSnackbar()
  const { navigateToNextStep, customerApplication, saveAgreement } = useLos()
  const [formData, setFormData] = useState<AgreementFormData>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [signedDocument, setSignedDocument] = useState<string>()

  async function PdfPreview() {
    try {
      const applicationId = customerApplication?.id
      if (!applicationId) {
        console.error('Application id not found')
        return
      }
      setLoading(true)
      const response = await LoanManagementService.getAgreementLetter(
        applicationId
      )

      const url = response.agreementUrl

      setSignedDocument(url)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  async function onSubmitClick() {
    setLoading(true)
    if (!customerApplication) {
      setLoading(false)
      return
    }
    await LosService.updateNextStep(customerApplication?.id)
    navigateToNextStep()
    saveAgreement(formData)
      .then(res => {
        customerApplication?.id &&
          LosService.updateNextStep(customerApplication?.id).then(res1 => {
            navigateToNextStep()
          })
      })
      .catch(err => {
        setSnackbar('Error in approving the sanction letter', 'error')
      })
    setLoading(false)
  }

  useEffect(() => {
    PdfPreview()
  }, [])

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            text='Proceed to eSign'
            onClick={onSubmitClick}
            // disabled={isInputValid() == false}
            loading={loading}
          />
        )
      }}
      renderAppBar={() => {
        return (
          <LosAppBar currentStepLabel={'Agreement Signing'} hideProgressBar />
        )
      }}
    >
      <Grid container rowSpacing={'1.5rem'} sx={{ width: '100%' }}>
        <Grid item xs={12}>
          <Stack spacing={'0.5rem'}>
            <LosText
              text='Agreement'
              style={{
                color: '#1B1D22',
                fontSize: '0.875rem',
                fontWeight: 500,
                lineHeight: '140%',
              }}
            />
            <LosText
              text='You need to download the Agreement copy and upload it up signed'
              style={{
                color: '#676B76',
                fontSize: '0.75rem',
                lineHeight: '130%',
                paddingBottom: '10px',
              }}
            />
          </Stack>
        </Grid>
        <div style={{ height: '600px', overflow: 'auto' }}>
          <iframe src={signedDocument} width={'600px'} height={'600px'} />
        </div>
      </Grid>
    </LosFormContainer>
  )
}

export default UploadAgreement
