import axios, { AxiosProgressEvent } from 'axios'
import { PaginatedResponse } from '../../../utils/api'
import { LosJourney } from '../providers/LosProvider'
import {
  AadharMetaData,
  AadharVerificationOtpResponse,
  ApplicationDto,
  ApplicationPanInfo,
  ColendedApplication,
  CollateralTypeData,
  CompanyDetailsResponse,
  CustomerAadharData,
  CustomerApplication,
  CustomerPanData,
  ElectricityBoard,
  EstampData,
  FaceMatchResponse,
  Gurantor,
  LosDocument,
  LosDocumentAcceptType,
  LosDocumentType,
  LosEntityType,
  LosStep,
  LosStepResponse,
  PanMetadata,
  SelfieMetaData,
  User,
  UserBasicInfo,
  UserDataDto,
} from '../types/Los'
import { LocalStorageUtility, StorageKeys } from '../utils/StorageUtility'

const ENDPOINT = {
  BASE: '/los',
  V2: '/v2',
  CREATE_APPLICATION: '/application',
  GET_APPLICATION: '',
  GET_ALL_APPLICATIONS_FOR_AGENT: '/application/list',
  GET_ALL_APPLICATIONS_FOR_HQ: '/application/colended/list',
  UPDATE_APPLICATION: '/application/{applicationId}/update',
  SAVE_APPLICATION_FORM: '/application/basic-info',
  SAVE_PAN_DETAILS: '/pan/member/fetch',
  MARK_STEP_AS_COMPLETED: '/setStepAsCompleted',
  GET_NEXT_STEP: '/flow/nextStep/get',
  GET_APPLICATION_BY_ID: '/application/{applicationId}',
  GET_APPLICATION_BY_CUSTOMER_ID: '/application/customer/{customerId}',
  FETCH_PAN_BY_PAN_NUMBER: '/pan/fetch/{panNumber}',
  FETCH_GST_BY_GST_NUMBER: '/pan/fetch/{gstNumber}',
  FETCH_PAN_BY_PAN_INFO_ID: '/pan/fetch/panId/{panInfoId}',
  FETCH_AADHAAR_BY_ADHAR_INFO_ID: '/aadhaar/fetch/aadhaarId/{aadhaarInfoId}',
  SAVE_CUSTOMER_SELFIE: '/face/member/match',
  SAVE_COMPANY_DETAILS: '/application/company-details',
  GET_COMPANY_DETAILS: '/application/company-details/{applicationId}',
  UPDATE_PAN_STATUS_TO_ACCEPTED: '/pan/accepted',
  UPDATE_AADHAR_STATUS_TO_ACCEPTED: '/aadhaar/accepted',
  SAVE_FINANCIAL_DATA: '/application/financial-details',
  SAVE_COLLATERAL_DETAILS: '/application/collateral-details',
  SAVE_ELECTRICITY_DATA: '/application/documentation-details',
  UPDATE_APPLICATION_STATUS_TO_ACCEPTED: '/application/status',
  UPDATE_SANCTION_LETTER_STATUS: '/application/sanction_letter/download',
  SAVE_AGREEMENT: '/application/agreement',
  SAVE_BANK_DETAILS: '/application/bank-details',
  GET_ELECTRICITY_BOARD_LIST: '/application/electricity-boards/list',
  GENERATE_AADHAR_OTP_FOR_APPLICATION: '/aadhaar/otp',
  VERIFY_AADHAR_OTP_FOR_APPLICATION: '/aadhaar/verification',
  ASSIGN_AGENT_TO_APPLICATION: '/application/assign-agent',
  GET_DOCUMENT: '/application/document/get',
  ACCEPT_DOCUMENT: '/application/document/accept',
  E_SIGN_AGREEMENT: '/eStamp/url',
  GET_PAN_DETAILS: '/los/v1/pan/userId/{userId}',
  GET_ADHAAR_DETAILS: '/los/v1/aadhaar/userId/{userId}',
  UPDATE_NEXT_STEP: '/los/v2/flow/nextStep/update',
  UPDATE_USER_NEXT_STEP: '/los/v2/flow/user/nextStep/update',
  GET_COMPANY_PAN: '/los/v1/pan/fetch/masked/panId/{panId}',
  GET_SELFIE_DETAILS: '/los/v1/face/userId/{userId}',
  GET_USER_DEATILS: '/los/v2/application/userDetails',
  SUBMIT_APPLICATION: '/los/v2/application/submitApplication',
  CUSTOMER_CIBIL: '/underwriting/v1/underwriting/consumer/application',
  COMERCIAL_CIBIL: '/underwriting/v1/underwriting/entity/application',
  UPDATE_USER_CONTACT_DETAILS: '/application/contact/{userId}',
  CREATE_ESTAMP_URL: '/eStamp/url',
  GET_ESTAMP_DATA: '/eStamp',
}

const ENUM_ENDPOINTS = {
  BASE: '/enums',
  GET_CATEGORIES: '/onBoardingApplicationCategory',
  GET_USER_TYPES: '/userType',
  GET_USER_SUB_TYPE: '/userSubType',
  GET_LOAN_TYPES: '/loanType',
  GET_RELATION_TYPE: '/relationType',
  GET_COALLETRAL_TYPES: '/collateral-types',
}

const BASE_URL_V2 = ENDPOINT.BASE + ENDPOINT.V2
const ENUM_BASE_URL = BASE_URL_V2 + ENUM_ENDPOINTS.BASE
const BASE_URL_V1 = ENDPOINT.BASE + '/v1'

export class LosService {
  static async createApplication(
    data: CreateApplicationApiData
  ): Promise<ApplicationDto> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.CREATE_APPLICATION
      const response = await axios.post(endpoint, data)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getApplicationById(
    applicationId: string
  ): Promise<CustomerApplication> {
    try {
      const endpoint =
        BASE_URL_V2 +
        ENDPOINT.GET_APPLICATION_BY_ID.replace('{applicationId}', applicationId)
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getApplicationForCustomer(
    customerId: string
  ): Promise<CustomerApplication> {
    try {
      const endpoint =
        BASE_URL_V2 +
        ENDPOINT.GET_APPLICATION_BY_CUSTOMER_ID.replace(
          '{customerId}',
          customerId
        )
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getPanDetailsByUserId(userId: string): Promise<PanMetadata> {
    try {
      const endpoint = ENDPOINT.GET_PAN_DETAILS.replace('{userId}', userId)
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getCompanyPanDetailsByPanId(
    panId: string
  ): Promise<ApplicationPanInfo> {
    try {
      const endpoint = ENDPOINT.GET_COMPANY_PAN.replace('{panId}', panId)
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getSelfieDetailsByUserId(
    userId: string
  ): Promise<SelfieMetaData> {
    try {
      const endpoint = ENDPOINT.GET_SELFIE_DETAILS.replace('{userId}', userId)
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getAdhaarDetailsByUserId(
    userId: string
  ): Promise<AadharMetaData> {
    try {
      const endpoint = ENDPOINT.GET_ADHAAR_DETAILS.replace('{userId}', userId)
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getNextStep(data: GetNextStepApiData): Promise<LosStepResponse> {
    try {
      const endpoint =
        BASE_URL_V2 +
        ENDPOINT.GET_NEXT_STEP +
        `?applicationId=${data.applicationId}`
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async updateNextStep(
    applicationId: string
  ): Promise<CustomerApplication> {
    try {
      const endpoint =
        ENDPOINT.UPDATE_NEXT_STEP + `?applicationId=${applicationId}`
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async updateUserNextStep(
    userId: string
  ): Promise<CustomerApplication> {
    try {
      const endpoint = ENDPOINT.UPDATE_USER_NEXT_STEP + `?userId=${userId}`
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getUserDetails(
    applicationId: string,
    applicationState?: string
  ): Promise<UserDataDto> {
    try {
      const endpoint = ENDPOINT.GET_USER_DEATILS
      const response = await axios.get(endpoint, {
        params: {
          applicationId: applicationId,
        },
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async submitApplication(
    applicationId: string
  ): Promise<CustomerApplication> {
    try {
      const endpoint = ENDPOINT.SUBMIT_APPLICATION
      const response = await axios.post(endpoint, null, {
        params: {
          applicationId: applicationId,
        },
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async saveApplicationForm(
    data: SaveApplicationFormApiData
  ): Promise<CustomerApplication> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.SAVE_APPLICATION_FORM
      const { applicationId, ...dataWithoutApplicationId } = data
      const response = await axios.post(endpoint, dataWithoutApplicationId, {
        params: { applicationId: applicationId },
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async savePanDetails(
    data: SavePanDetailsApiData
  ): Promise<CustomerApplication> {
    try {
      const endpoint = BASE_URL_V1 + ENDPOINT.SAVE_PAN_DETAILS
      const response = await axios.post(endpoint, data)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async fetchPanDetails(
    data: FetchPanDetailsRequestData
  ): Promise<CustomerPanData> {
    try {
      const endpoint =
        BASE_URL_V1 +
        ENDPOINT.FETCH_PAN_BY_PAN_NUMBER.replace('{panNumber}', data.panNumber)
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  // TODO: @Shailendra yeh konsi api h?? Call toh pan fetch ka jaa rha h endpoint
  static async fetchGSTDetails(
    data: FetchGSTDetailsRequestData
  ): Promise<CustomerPanData> {
    try {
      const endpoint =
        BASE_URL_V1 +
        ENDPOINT.FETCH_GST_BY_GST_NUMBER.replace('{gstNumber}', data.gstNumber)
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async saveCustomerSelfie(data: {
    apiData: SaveCustomerSelfieRequestData
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  }): Promise<FaceMatchResponse> {
    try {
      const endpoint = BASE_URL_V1 + ENDPOINT.SAVE_CUSTOMER_SELFIE
      const formData = new FormData()
      formData.append('applicationId', data.apiData.applicationId)
      formData.append('userId', data.apiData.userId)
      const blob = await base64ToBlob(data.apiData.selfie)
      formData.append('selfie', blob, 'selfie.jpg')
      const response = await axios.patch(endpoint, formData, {
        onUploadProgress: data.onUploadProgress,
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async fetchPanByPanInfoId(data: {
    panInfoId: string
  }): Promise<PanMetadata> {
    try {
      const endpoint =
        BASE_URL_V1 +
        ENDPOINT.FETCH_PAN_BY_PAN_INFO_ID.replace('{panInfoId}', data.panInfoId)
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async fetchSelfieData(infoId: string) {}

  static async updateGstStatusToAccept(data: { applicationId: string }) {
    try {
      return this.acceptDocument({
        applicationId: data.applicationId,
        documentType: LosDocumentAcceptType.GST_DOCUMENT,
      })
    } catch (error) {
      throw error
    }
  }

  static async updatePanStatusToAccept(data: { applicationId: string }) {
    try {
      return this.acceptDocument({
        applicationId: data.applicationId,
        documentType: LosDocumentAcceptType.PAN_CARD,
      })
    } catch (error) {
      throw error
    }
  }

  static async updateCompanyPanStatusToAccept(data: { applicationId: string }) {
    try {
      return this.acceptDocument({
        applicationId: data.applicationId,
        documentType: LosDocumentAcceptType.GST_DOCUMENT,
      })
    } catch (error) {
      throw error
    }
  }

  static async updateAadharStatusToAccept(data: { applicationId: string }) {
    try {
      return this.acceptDocument({
        applicationId: data.applicationId,
        documentType: LosDocumentAcceptType.AADHAAR_CARD,
      })
    } catch (error) {
      throw error
    }
  }

  static async acceptDocument(data: {
    applicationId: string
    documentType: LosDocumentAcceptType
  }) {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.ACCEPT_DOCUMENT
      const customerApplication =
        LocalStorageUtility.getItem<CustomerApplication>(
          StorageKeys.CUSTOMER_APPLICATION
        )
      const currentIndex = customerApplication?.currentUserIndex
        ? customerApplication?.currentUserIndex
        : 0
      const response = await axios.post(
        endpoint,
        {},
        {
          params: {
            applicationId: data.applicationId,
            documentType: data.documentType,
            userId: customerApplication?.users[currentIndex],
          },
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async saveFinancialData(
    data: Partial<SaveFinancialDataRequestData>
  ): Promise<CustomerApplication> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.SAVE_FINANCIAL_DATA
      const formData = new FormData()
      if (data.employerDetail) {
        formData.append('employerDetail', data.employerDetail)
      }
      if (data.file) {
        formData.append('file', data.file)
      }

      const response = await axios.post(endpoint, formData, {
        params: {
          applicationId: data.applicationId,
          documentType: data.documentType,
        },
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async saveCollateralData(
    data: Partial<SaveCollateralDetailsRequestData>
  ): Promise<CustomerApplication> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.SAVE_COLLATERAL_DETAILS
      const { applicationId, ...rest } = data
      const response = await axios.post(
        endpoint,
        { ...rest },
        { params: { applicationId: applicationId } }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async saveElectrictyBillData(
    data: SaveElectricityDataRequestData
  ): Promise<CustomerApplication> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.SAVE_ELECTRICITY_DATA
      const { applicationId } = data
      const formData = new FormData()
      formData.append('electricityBoard', data.electricityBoard)
      formData.append('electricityBillNo', data.electricityBillNo)
      formData.append('code', data.code)
      if (data.file) {
        formData.append('file', data.file)
      }

      const response = await axios.post(endpoint, formData, {
        params: {
          applicationId: applicationId,
          documentType: data.documentType,
        },
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async updateApplicationStatus(
    data: Partial<UpdateApplicationStatusRequestData>
  ): Promise<CustomerApplication> {
    try {
      const endpoint =
        BASE_URL_V2 + ENDPOINT.UPDATE_APPLICATION_STATUS_TO_ACCEPTED
      const response = await axios.put(endpoint, {}, { params: data })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async updateSanctionLetterStatusToAccepted(
    applicationId: string
  ): Promise<CustomerApplication> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.UPDATE_SANCTION_LETTER_STATUS
      const response = await axios.post(
        endpoint,
        {},
        { params: { applicationId: applicationId } }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async saveSignedAgreement(
    data: SaveSignedAgreementRequestData
  ): Promise<CustomerApplication> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.SAVE_AGREEMENT
      const formData = new FormData()
      if (data.agreement) {
        formData.append('file', data.agreement)
      }
      const response = await axios.post(endpoint, formData, {
        params: {
          applicationId: data.applicationId,
          documentType: data.documentType,
        },
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async saveBankDetails(
    data: SaveBankDetailsRequestData
  ): Promise<CustomerApplication> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.SAVE_BANK_DETAILS

      const formData = new FormData()
      if (data.cancelledCheque) {
        formData.append('file', data.cancelledCheque)
      }
      formData.append('accountHolderName', data.accountHolderName)
      formData.append('accountNo', data.accountNo)
      formData.append('ifscCode', data.ifscCode)
      formData.append('branch', data.branch)
      formData.append('bankName', data.bankName)

      const response = await axios.post(endpoint, formData, {
        params: {
          applicationId: data.applicationId,
          documentType: data.documentType,
        },
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async saveCompanyDetails(
    data: Partial<SaveCompanyDetails>
  ): Promise<CustomerApplication> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.SAVE_COMPANY_DETAILS
      const response = await axios.post(endpoint, data, {
        params: {
          applicationId: data.applicationId,
        },
      })
      return response.data
    } catch (error) {
      throw error
    }
  }
  static async getCompanyDetails(
    applicationId: string
  ): Promise<CompanyDetailsResponse> {
    try {
      const endpoint =
        BASE_URL_V2 +
        ENDPOINT.GET_COMPANY_DETAILS.replace('{applicationId}', applicationId)
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getAllApplicationCategories(): Promise<LosJourney[]> {
    try {
      const endpoint = ENUM_BASE_URL + ENUM_ENDPOINTS.GET_CATEGORIES
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getUserTypeOptions(): Promise<string[]> {
    try {
      const endpoint = ENUM_BASE_URL + ENUM_ENDPOINTS.GET_USER_TYPES
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getUserSubTypeOptions(userType: string): Promise<string[]> {
    try {
      const endpoint = ENUM_BASE_URL + ENUM_ENDPOINTS.GET_USER_SUB_TYPE
      const response = await axios.get(endpoint, {
        params: { userType: userType },
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getLoanTypeOptions(): Promise<string[]> {
    try {
      const endpoint = ENUM_BASE_URL + ENUM_ENDPOINTS.GET_LOAN_TYPES
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getCollateralTypeOptions(type: string): Promise<string[]> {
    try {
      const endpoint = ENUM_BASE_URL + ENUM_ENDPOINTS.GET_COALLETRAL_TYPES
      const response = await axios.get(endpoint, {
        params: { userType: type },
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getRelationTypeOptions(): Promise<string[]> {
    try {
      const endpoint = ENUM_BASE_URL + ENUM_ENDPOINTS.GET_RELATION_TYPE
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async updateApplicationEntityType(
    data: UpdateApplicationEntityTypeRequestData
  ): Promise<CustomerApplication> {
    try {
      const endpoint =
        BASE_URL_V2 +
        ENDPOINT.UPDATE_APPLICATION.replace(
          '{applicationId}',
          data.applicationId
        )
      const response = await axios.put(endpoint, {
        losEntityType: data.entityType,
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getElectrictyBoardList(): Promise<ElectricityBoard[]> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.GET_ELECTRICITY_BOARD_LIST
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async generateOtpForApplication(data: {
    applicationId: string
    aadhaarNumber: string
  }): Promise<AadharVerificationOtpResponse> {
    try {
      const endpoint =
        BASE_URL_V1 + ENDPOINT.GENERATE_AADHAR_OTP_FOR_APPLICATION
      const customerApplication =
        LocalStorageUtility.getItem<CustomerApplication>(
          StorageKeys.CUSTOMER_APPLICATION
        )
      const currentIndex = customerApplication?.currentUserIndex
        ? customerApplication?.currentUserIndex
        : 0
      const response = await axios.post(endpoint, {
        aadhaarNumber: data.aadhaarNumber,
        userId: customerApplication?.users[currentIndex],
        applicationId: data.applicationId,
      })
      return response.data.data
    } catch (error) {
      throw error
    }
  }

  static async verifyAadhaarOtp(data: VerifyAadharOtpApiData) {
    try {
      const endpoint = BASE_URL_V1 + ENDPOINT.VERIFY_AADHAR_OTP_FOR_APPLICATION
      const customerApplication =
        LocalStorageUtility.getItem<CustomerApplication>(
          StorageKeys.CUSTOMER_APPLICATION
        )
      const currentIndex = customerApplication?.currentUserIndex
        ? customerApplication?.currentUserIndex
        : 0
      const response = await axios.post(endpoint, {
        requestId: data.requestId,
        otp: data.otp,
        userId: customerApplication?.users[currentIndex],
        applicationId: data.applicationId,
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getAadhaarDetailsForInfoId(
    infoId: string
  ): Promise<CustomerAadharData> {
    try {
      const endpoint =
        BASE_URL_V1 +
        ENDPOINT.FETCH_AADHAAR_BY_ADHAR_INFO_ID.replace(
          '{aadhaarInfoId}',
          infoId
        )
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async assignAgentToApplication(data: {
    applicationId: string
    agentId: string
  }): Promise<CustomerApplication> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.ASSIGN_AGENT_TO_APPLICATION
      const response = await axios.post(
        endpoint,
        {},
        { params: { applicationId: data.applicationId, agentId: data.agentId } }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getAllApplicationsForAgent(
    data: Partial<GetAllApplicationsForAgentApiData>
  ): Promise<PaginatedResponse<CustomerApplication>> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.GET_ALL_APPLICATIONS_FOR_AGENT
      const response = await axios.get(endpoint, { params: data })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getAllApplicationsForHQ(
    data: Partial<GetAllApplicationsForHQApiData>
  ): Promise<PaginatedResponse<ColendedApplication>> {
    try {
      if ((data.status ?? '').length <= 0) {
        delete data.status
      }

      const endpoint = BASE_URL_V2 + ENDPOINT.GET_ALL_APPLICATIONS_FOR_HQ
      const response = await axios.get(endpoint, {
        params: data,
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getDocument(
    data: Partial<GetLosDocumentApiData>
  ): Promise<LosDocument> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.GET_DOCUMENT
      const response = await axios.get(endpoint, { params: data })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async createEstampUrl(applicationId: string): Promise<any> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.CREATE_ESTAMP_URL
      const response = await axios.post(
        endpoint,
        {},
        {
          params: { applicationId: applicationId },
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getEstampData(applicationId: string): Promise<EstampData[]> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.GET_ESTAMP_DATA
      const response = await axios.get(endpoint, {
        params: {
          applicationId,
        },
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async updateContactDetails(
    data: UpdateUserContactDetailsRequest
  ): Promise<User> {
    try {
      const endpoint = BASE_URL_V2 + ENDPOINT.UPDATE_USER_CONTACT_DETAILS
      const request = endpoint.replace('{userId}', data.userId)
      const response = await axios.put(request, data.userBasicInfo)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async eSignAgreement(
    request: Partial<ESignAgreementRequest>
  ): Promise<EsignAgreementResponse> {
    const endpoint = BASE_URL_V2 + ENDPOINT.E_SIGN_AGREEMENT
    const applicationId = request.applicationId
    const data: Partial<ESignAgreementRequest> = HARD_CODED_REQUEST
    delete data.applicationId
    const response = await axios.post(endpoint, data, {
      params: { applicationId: applicationId },
    })
    return response.data
  }

  static async getCustomerCibil({
    applicationId,
    responseType,
  }: {
    applicationId: string
    responseType: string
  }): Promise<LosDocument> {
    const endpoint = ENDPOINT.CUSTOMER_CIBIL + '/' + applicationId
    const response = await axios.get(endpoint, {
      params: {
        responseType,
      },
    })
    return response.data
  }
  static async getComercialCibil({
    applicationId,
    responseType,
  }: {
    applicationId: string
    responseType: string
  }): Promise<LosDocument> {
    const endpoint = ENDPOINT.COMERCIAL_CIBIL + '/' + applicationId
    const response = await axios.get(endpoint, {
      params: {
        responseType,
      },
    })
    return response.data
  }
}

export type GetAllApplicationsForHQApiData = {
  status: string
  page: number
  pageSize: number
  sortBy: string
  sortDir: string
  programId: string
}

export type GetAllApplicationsForAgentApiData = {
  agentId: string
  status: string
  page: number
  pageSize: number
  sortBy: string
  programId: string
  sortDir: string
}

export type VerifyAadharOtpApiData = {
  applicationId: string
  requestId: string
  otp: string
}

export type UpdateApplicationEntityTypeRequestData = {
  applicationId: string
  entityType: string
}

export type SaveCompanyDetails = {
  applicationId: string
  companyName: string
  gstNo: string
  cinNo?: string
  partnershipIdentificationNo?: string
  file?: File | null
  documentType?: LosDocumentType
}

export type SaveBankDetailsRequestData = {
  applicationId: string
  accountHolderName: string
  accountNo: string
  ifscCode: string
  branch: string
  cancelledCheque?: File | null
  documentType: LosDocumentType
  bankName: string
}

export type SaveSignedAgreementRequestData = {
  applicationId: string
  documentType: LosDocumentType
  agreement?: File | null
}

export type UpdateApplicationStatusRequestData = {
  applicationId: string
  agentId: string
  status: string
}

export type SaveCollateralDetailsRequestData = {
  applicationId: string
  loanType: string
  guaranteeDetails: Partial<Gurantor>[]
  collateralDetails: Partial<CollateralTypeData>[]
}

export type SaveFinancialDataRequestData = {
  applicationId: string
  employerDetail: string
  file: File | null
  documentType: string
}

export type SaveElectricityDataRequestData = {
  applicationId: string
  file?: File | null
  documentType?: LosDocumentType
  electricityBoard: string
  electricityBillNo: string
  code: string
}

export type SaveCustomerSelfieRequestData = {
  applicationId: string
  memberId?: string
  selfie: string
  userId: string
}

export type FetchPanDetailsRequestData = {
  panNumber: string
}
export type FetchGSTDetailsRequestData = {
  gstNumber: string
}

export type SaveAadharInfo = {
  applicationId: string
  aadhaarNumber: string
}

export type SavePanDetailsApiData = {
  memberBasicInfo?: {
    name: string
    designation?: string
  }
  userBasicInfo?: {
    name: string
    mobileNumber?: string
    emailId?: string
    designation?: string
  }
  companyName?: string
  panNumber: string
  applicationId: string
  userId?: string
}

export type GetNextStepApiData = {
  applicationId: string
}

export type MarkStepAsCompletedApiData = {
  applicationId: string
  applicationState: LosStep
}

export type SaveApplicationFormApiData = {
  applicationId: string
  userType: string
  subType?: string
  email?: string
  loanType: string
  amount: string
  tenure: string
}

export type CreateApplicationApiData = {
  programId: string
  customerId: string
  leadId: string
  losEntityType?: LosEntityType
}

export type GetLosDocumentApiData = {
  applicationId: string
  memberId: string
  documentType: string
}

export type UpdateUserContactDetailsRequest = {
  userId: string
  userBasicInfo: UserBasicInfo
}

const base64ToBlob = async (base64Url: string): Promise<Blob> => {
  const response = await fetch(base64Url)
  const data = await response.blob()
  return data
}

export type EsignAgreementResponse = {
  customerId: string
  contractId: string
  userReminderTime: number
  redirectTime: string
  redirectUrl: string
  maximumValidityTime: number
  contractCreationTime: number
  callbackUrl: string
  initialContract: string
  isCompleted: string
  contractName: string
  contractExecuterName: string
  contractPageCount: string
  tnc: string
  initialPdfHash: string
  customerMailList: any[] // You may specify the correct type for this array
  finalSignedContract: string
  finalSignedContractHash: string
  finalDscSignedContractUrl: string
  finalDscSignedContractBase64: string
  finalDscSignedContractHash: string
  auditCertificateUrl: string
  auditCertificateBase64: string
  auditCertificateHash: string
  estamp: {
    transactionId: string[]
    estampStatus: string
    transactionResult: {
      eStampedFile: string
      type: string
    }
  }
  signerdetail: {
    signerName: string
    signerEmail: string
    signerDateOfBirth: string
    signatureOptions: string[]
    showId: string
    signerMobile: string
    tnc: string
    signatures: {
      pageNo: number[]
      signaturePosition: string[]
      height: number
      width: number
    }[]
    additionalVerification: any[] // You may specify the correct type for this array
    idImage: string
    signerOtpMobile: string
    signerId: string
    contractSendTime: number
    contractUrl: string
    logoUrl: string
    initialPdfHash: string
    status: string
    signerMatchData: Record<string, any> // You may specify the correct type for this object
    userConsentEmailDetails: Record<string, any> // You may specify the correct type for this object
  }[]
  deletedSignerdetail: any[] // You may specify the correct type for this array
}

export type ESignAgreementRequest = {
  applicationId: string
  contractExecuterName: 'sign'
  multiPages: 'true'
  pageNo: []
  signerdetail: [
    {
      signerName: 'Ishika Aggarwal'
      signerEmail: 'ishika.aggarwal@vegapay.tech'
      signerDateOfBirth: '01/01/2000'
      signatureOptions: ['aadhaaresign']
      showId: 'true'
      signerMobile: '8094845731'
      tnc: 'Consent Statement.'
      signatures: [
        {
          pageNo: [1, 2]
          signaturePosition: ['TOP-LEFT', 'TOP-CENTER']
        }
      ]
    }
  ]
  callbackUrl: 'https://alpha-api.vegapay.tech/los/v2/eStamp/callback'
  fileFormat: 'directURL'
  callbackUrlAuthPresent: 'false'
  callbackUrlAuthType: 'basic-auth'
  signerCallbackUrl: 'https://alpha-api.vegapay.tech/los/v2/eStamp/signer/callback'
  signerCallbackUrlAuthPresent: 'false'
  signerCallbackUrlAuthType: 'basic-auth'
  contractName: 'Agreement'
  useCustomSmtpServer: 'false'
  redirectUrl: 'https://solfin.vegapay.tech/onboarding'
  userReminderTime: '10'
  estamp: {
    type: 'eStamp'
    firstPartyName: 'Vegapay'
    secondPartyName: 'Ishika'
    stampDutyPaidBy: 'Vegapay'
    considerationPrice: '10'
    state: 'Delhi'
    pageNo: [1]
    location: 'bottomLeft'
    stampDetails: [
      {
        articleCode: 'DL1001'
        stateCode: 'DL'
        purposeOfStampDuty: 'loan'
        stampDutyValue: 10
        count: 1
      }
    ]
  }
}

export const HARD_CODED_REQUEST: ESignAgreementRequest = {
  applicationId: '',
  contractExecuterName: 'sign',
  multiPages: 'true',
  pageNo: [],
  signerdetail: [
    {
      signerName: 'Ishika Aggarwal',
      signerEmail: 'ishika.aggarwal@vegapay.tech',
      signerDateOfBirth: '01/01/2000',
      signatureOptions: ['aadhaaresign'],
      showId: 'true',
      signerMobile: '8094845731',
      tnc: 'Consent Statement.',
      signatures: [
        {
          pageNo: [1, 2],
          signaturePosition: ['TOP-LEFT', 'TOP-CENTER'],
        },
      ],
    },
  ],
  callbackUrl: 'https://alpha-api.vegapay.tech/los/v2/eStamp/callback',
  fileFormat: 'directURL',
  callbackUrlAuthPresent: 'false',
  callbackUrlAuthType: 'basic-auth',
  signerCallbackUrl:
    'https://alpha-api.vegapay.tech/los/v2/eStamp/signer/callback',
  signerCallbackUrlAuthPresent: 'false',
  signerCallbackUrlAuthType: 'basic-auth',
  contractName: 'Agreement',
  useCustomSmtpServer: 'false',
  redirectUrl: 'https://solfin.vegapay.tech/onboarding',
  userReminderTime: '10',
  estamp: {
    type: 'eStamp',
    firstPartyName: 'Vegapay',
    secondPartyName: 'Ishika',
    stampDutyPaidBy: 'Vegapay',
    considerationPrice: '10',
    state: 'Delhi',
    pageNo: [1],
    location: 'bottomLeft',
    stampDetails: [
      {
        articleCode: 'DL1001',
        stateCode: 'DL',
        purposeOfStampDuty: 'loan',
        stampDutyValue: 10,
        count: 1,
      },
    ],
  },
}
