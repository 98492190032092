import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '..';
import { ColendedApplication } from '../../solfen/onboarding/types/Los';
import { ICreateDispersalScheduleProps } from '../../solfen/types/pre-disbursement';

const DEFAULT_PRE_APPLICATION = {
  programId: '',
  applicationId: '',
  customerId: '',
  customerName: '',
  phone: '',
  losEntityType: '',
  basicInfo: {
    id: '',
    userType: '',
    subType: '',
    loanType: '',
    amount: 500000,
    tenure: 30,
  },
  agentId: '',
  agentName: '',
  colendingloanId: '',
  applicationStatus: '',
  colenderId: '',
  colenderName: '',
  colendingRatio: 80,
  approvedAmount: 500000,
  primaryRoi: 18,
  loanId: '',
  colendingAmount: 400000,
  colendingRoi: 10,
  clientAmount: 100000,
  clientRoi: 50,
  lendingType: '',
  colendingLoansStatus: '',
  engagementStatus: '',
  underwritingStatus: '',
  isDispersible: false,
  createdAt: '',
};

interface IProps {
  preDisbursalDetails: ColendedApplication;
  trenchs: ICreateDispersalScheduleProps[];
  isError: boolean;
  amountEnter: boolean;
  isAmountError: boolean;
  downPayment: number | null;
}

const initialState: IProps = {
  preDisbursalDetails: DEFAULT_PRE_APPLICATION,
  trenchs: [],
  isError: false,
  isAmountError: false,
  amountEnter: false,
  downPayment: null,
};

const preDisburseForAgentSlice = createSlice({
  name: 'preDisbursal',
  initialState,
  reducers: {
    setPreDisbursalDetails: (state: IProps, action) => {
      const { payload } = action;
      state.preDisbursalDetails = payload;
    },
    setTrenchs: (state: IProps, action) => {
      const { payload } = action;
      state.trenchs = payload;
    },
    setIsError: (state: IProps, action) => {
      const { payload } = action;
      state.isError = payload;
    },
    setDownPayment: (state: IProps, action) => {
      const { payload } = action;
      state.downPayment = payload;
    },
    setAmountEnter: (state: IProps, action) => {
      const { payload } = action;
      state.amountEnter = payload;
    },
    setAmountError: (state: IProps, action) => {
      const { payload } = action;
      state.isAmountError = payload;
    },
  },
});

export const {
  setPreDisbursalDetails,
  setTrenchs,
  setIsError,
  setDownPayment,
  setAmountEnter,
  setAmountError,
} = preDisburseForAgentSlice.actions;
export const getPreDisbursalDetails = (state: AppState) => state.preDisbursal;
export default preDisburseForAgentSlice;
