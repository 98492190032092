import _ from 'lodash'

export enum LosUserType {
  INDIVIDUAL = 'INDIVIDUAL',
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  PVT_LIMITED = 'PVT_LTD',
  PARTNERSHIP_FIRM = 'PARTNERSHIP',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
}

export enum LosStep {
  APPLICATION_FORM = 'APPLICATION_FORM',
  CARD_FORM = 'CARD_FORM',
  USER_KYC = 'USER_KYC',
  USER_KYC_COMPLETE = 'USER_KYC_COMPLETE',
  COMPANY_PAN_VALIDATION = 'COMPANY_PAN_VALIDATION',
  COMPANY_PAN_ACCEPTED = 'COMPANY_PAN_ACCEPTED',
  PAN_VALIDATION = 'PAN_VALIDATION',
  PAN_ACCEPTED = 'PAN_ACCEPTED',
  AADHAAR_VALIDATION = 'AADHAAR_VALIDATION',
  AADHAAR_ACCEPTED = 'AADHAR_ACCEPTED',
  SELFIE_VERIFICATION = 'SELFIE_VERIFICATION',
  SELFIE_LIVENESS = 'SELFIE_LIVENESS',
  SELFIE_MATCH = ' SELFIE_MATCH',
  ADDRESS_VERIFICATION = ' ADDRESS_VERIFICATION',
  COMPANY_DETAIL = 'COMPANY_DETAIL',
  GST_VERIFICATION = 'GST_VERIFICATION  ',
  GST_ACCEPTED = 'GST_ACCEPTED',
  CIN_NO = 'CIN_NO',
  PIN_NO = 'PIN_NO',
  FINANCIALS = 'FINANCIALS',
  COLLATERAL = 'COLLATERAL',
  DOCUMENTATION = 'DOCUMENTATION',
  VIDEO_KYC = 'VIDEO_KYC',
  SUBMITTED = 'SUBMITTED',
  SANCTION_LETTER = 'SANCTION_LETTER',
  AGREEMENT = 'AGGREMENT',
  BANK_DETAILS = 'BANK_DETAILS',
  READY_FOR_DISPERSAL = 'READY_FOR_DISPERSAL',
  DISPERSED = 'DISPERSED',
  UNDERWRITING = 'UNDERWRITING',
  ONBOARDED = 'ONBOARDED',
  APPLICATION_CREATED = 'APPLICATION_CREATED',
  APPLICATION_VALIDATION = 'APPLICATION_VALIDATION',
  APPLICATION_SENT_TO_ISIC = 'APPLICATION_SENT_TO_ISIC',
  APPLICATION_APPROVED = 'APPLICATION_APPROVED',
  APPLICATION_APPROVED_BY_ISIC = 'APPLICATION_APPROVED_BY_ISIC',
  UNSIGNED_AGREEMENT = 'UNSIGNED_AGREEMENT',
  CONTACT_DETAILS = 'CONTACT_DETAILS',
  SIGNED_AGREEMENT='SIGNED_AGREEMENT'
}

export enum LosCategory {
  BASIC_DETAILS = 'BASIC_DETAILS',
  KYC_VERIFICATION = 'KYC_VERIFICATION',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  FINANCIAL_DETAILS = 'FINANCIAL_DETAILS',
  COLLATERAL = 'COLLATERAL',
  ELECTRICITY = 'ELECTRICITY',
}

export interface Promoter {
  name: string
  designation: string
  panNumber: string
  aadharNumber: string
}

export enum VerificationStatus {
  VERIFIED = 'VERIFIED',
  PENDING = 'PENDING',
}

export enum LosEntityType {
  INDIVIDUAL = 'INDIVIDUAL',
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  PARTNERSHIP = 'PARTNERSHIP',
  PVT_LTD = 'PVT_LTD',
}

export enum LoanType {
  SOLAR = 'SOLAR',
  COMMERCIAL = 'COMMERCIAL',
  PERSONAL = 'PERSONAL',
}

export enum RelationType {
  FATHER = 'FATHER',
  MOTHER = 'MOTHER',
  BROTHER = 'BROTHER',
  SISTER = 'SISTER',
}

export enum GuranteeType {
  SECURITIES = 'SECURITIES',
  GOLD = 'GOLD',
  FIXED_DEPOSIT = 'FIXED_DEPOSIT',
}

export interface Lead {
  id: string
  createdAt: string
  updatedAt: string
  identifier: string
  fullName: string
  designation: string
  programId: string
  companyName: string
  cinNumber: string
  email: string
  phone: string
  address: string
  financingRequired: string
  leadType: string
  agentId: string
  agentName: string
  distributionPartnerId: string
  latitude: string
  longitude: string
  status: string
}

export interface CustomerApplication {
  id: string
  programId: string
  customerId: string
  customerName: string
  status: string
  applicationState: string
  programApplicationFlowId: string
  losEntityType: string
  basicInfo: CustomerApplicationBasicInfo
  currentMemberIndex: number
  // members: ApplicationMember[]
  companyPanId: any
  companyDetails: CompanyDetailsData
  collateralDetails: CollateralDetails
  financialDetails: FinancialDetails
  documentationDetails: ElectricityDetails
  agreement: any
  bankDetails: any
  agentId: string
  createdAt: any
  updatedAt: any
  panAccepted: boolean
  phone: string
  users: string[]
  currentUserIndex: number
}

export interface ApplicationDto {
  applicationId: string
}

interface GSTNDetails {
  constitutionOfBusiness: string
  legalNameOfBusiness: string
  tradeNameOfBusiness: string
  centreJurisdiction: string
  stateJurisdiction: string
  registrationDate: string
  taxPayerDate: string
  taxPayerType: string
  gstinStatus: string
  cancellationDate: string
  natureOfBusinessActivities: string[]
  principalPlaceAddress: string
  principalPlaceLatitude: string
  principalPlaceLongitude: string
  principalPlaceBuildingNameFromGST: string
  principalPlaceBuildingNoFromGST: string
  principalPlaceFlatNo: string
  principalPlaceStreet: string
  principalPlaceLocality: string
  principalPlaceCity: string
  principalPlaceDistrict: string
  principalPlaceState: string
  principalPlacePincode: string
  additionalPlaceAddress: string
  additionalPlaceLatitude: string
  additionalPlaceLongitude: string
  additionalPlaceBuildingNameFromGST: string
  additionalPlaceBuildingNoFromGST: string
  additionalPlaceFlatNo: string
  additionalPlaceStreet: string
  additionalPlaceLocality: string
  additionalPlaceCity: string
  additionalPlaceDistrict: string
  additionalPlaceState: string
  additionalPlacePincode: string
  additionalAddressArray: {
    address: string
    flatNo: string
    street: string
    locality: string
    buildingNo: string
    buildingName: string
    district: string
    city: string
    state: string
    pincode: string
    latitude: string
    longitude: string
  }[]
  lastUpdatedDate: string
  principalPlaceSplitAddress: {
    district: string[]
    state: [string[], string[]]
    city: string[]
    pincode: string
    country: string[]
    addressLine: string
  }
  additionalPlaceSplitAddress: {
    district: string[]
    state: [string[], string[]]
    city: string[]
    pincode: string
    country: string[]
    addressLine: string
  }
}

interface GSTNRecord {
  applicationStatus: string
  registrationName: string
  mobNum: string
  regType: string
  emailId: string
  tinNumber: string
  gstinRefId: string
  gstin: string
}

export interface GSTNData {
  gstnDetailed: GSTNDetails
  gstnRecords: GSTNRecord[]
  gstin: string
  gstId: string
}

export type CompanyDetailsData = {
  id: string
  applicationId: string
  companyName: string
  cinNo: string
  partnershipIdentificationNo: string
  // gstNo: string
  gstId: string
  panId: string
  documents: ApplicationDocument[]
  // gstData: GSTNData | undefined;
  gstData: any
}

export type CompanyPreviewData = {
  companyName: string
  gstNo: string
  gstCertification: ApplicationDocument[]
  certificateOfIncorporation: ApplicationDocument[]
  moa: ApplicationDocument[]
  aoa: ApplicationDocument[]
  partnershipFirmDeed: ApplicationDocument[]
}

export type ElectricityDetails = {
  id: string
  applicationId: string
  electricityBoard: string
  electricityBillNo: string
  documents: ApplicationDocument[]
}

export type CollateralDetails = {
  loanType: string
  collateralDetails: CollateralTypeData[]
  guaranteeDetails: Gurantor[]
}

export type ApplicationDocument = {
  id: string
  documentCategory: string
  documentType: string
  documentName: string
}

export type LosDocument = {
  url: string
  name: string
  requestedAt: string
  expiryAt: string
  validityInMinutes: number
  metaData: Record<string, any>
}

export interface EstampData{
  id: string;
  applicationId: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum EstampStatus{
  PENDING = 'PENDING',
  SIGNED = 'SIGNED'
}

export type FinancialDetails = {
  id: string
  applicationId: string
  employerDetail: string
  documents: ApplicationDocument[]
}

export interface ApplicationMember {
  id: string
  basicInfo: ApplicationMemberBasicInfo
  panInfoId: string
  aadhaarInfoId: string
  selfieInfoId: string
  designation: string
  acceptAadhaarTimeStamp: string
  acceptPanTimeStamp: string
  acceptBureauPanTimeStamp: string
}

export interface ApplicationMemberBasicInfo {
  name: string
  designation: string
  mobileNumber: string
  email: string
}

export interface CustomerApplicationBasicInfo {
  userType: string
  subType?: string
  email?: string
  loanType: string
  amount: number
  tenure: number
  id?: null | string
}

export enum UserType {
  INDIVIDUAL = 'INDIVIDUAL',
  NON_INDIVIDUAL = 'NON_INDIVIDUAL',
}
export interface VegaCustomer {
  customerId: string
  clientId: string
  title?: string
  firstName?: string
  middleName?: string
  lastName?: string
  countryCode?: string
  mobileNumber: string
  emailId?: string
  identity?: any[]
  dob?: string
  gender?: string
  currentAddress?: any
  isCurrentAddressPermanent?: string
  permanentAddress?: any
  nationality?: string
  status?: string
  kycStatus?: string
  creationVector?: string
  selfieUrl?: string
  createdAt?: Date
  updatedAt?: Date
  communicationPinCode?: number
  riskScore?: number
  bureauScore?: number
  customerType?: string
}

export interface ElectricityBoard {
  id: string
  state: string
  serviceProvider: string
  code: string
}
export interface Gurantor {
  name: string
  relationShip: string
  email: string
  panCard: string
  guaranteeType: string
  valueOfGuarantee: string
}

export interface CollateralTypeData {
  type: string
  amount: string
}

export enum LosDocumentType {
  PAYSLIP_LAST_MONTH = 'PAYSLIP_LAST_MONTH',
  PAYSLIP_SECOND_LAST_MONTH = 'PAYSLIP_SECOND_LAST_MONTH',
  PAYSLIP_THIRD_LAST_MONTH = 'PAYSLIP_THIRD_LAST_MONTH',
  BANK_STATEMENT = 'BANK_STATEMENT',
  INCOME_TAX_RETURN_LAST_YEAR = 'INCOME_TAX_RETURN_LAST_YEAR',
  INCOME_TAX_RETURN_SECOND_LAST_YEAR = 'INCOME_TAX_RETURN_SECOND_LAST_YEAR',
  AUDITED_FINANCIAL_REPORT_LAST_YEAR = 'AUDITED_FINANCIAL_REPORT_LAST_YEAR',
  AUDITED_FINANCIAL_REPORT_SECOND_LAST_YEAR = 'AUDITED_FINANCIAL_REPORT_SECOND_LAST_YEAR',
  GST_DOCUMENT = 'GST_DOCUMENT',
  CERTIFICATE_OF_INCORPORATION = 'CERTIFICATE_OF_INCORPORATION',
  MOA = 'MOA',
  AOA = 'AOA',
  PARTNERSHIP_FIRM_DEED = 'PARTNERSHIP_FIRM_DEED',
  ELECTRICITY_BILL_LAST_MONTH = 'ELECTRICITY_BILL_LAST_MONTH',
  ELECTRICITY_BILL_SECOND_LAST_MONTH = 'ELECTRICITY_BILL_SECOND_LAST_MONTH',
  ELECTRICITY_BILL_THIRD_LAST_MONTH = 'ELECTRICITY_BILL_THIRD_LAST_MONTH',
  SANCTION_LETTER = 'SANCTION_LETTER',
  CANCELLED_CHEQUE = 'CANCELLED_CHEQUE',
  AADHAAR_CARD = 'AADHAAR_CARD',
  PAN_CARD = 'PAN_CARD',
  SELFIE = 'SELFIE',
  SIGNED_AGREEMENT_LETTER='SIGNED_AGREEMENT_LETTER'
}

export interface FaceMatchResponse {
  id: string
  patronId: string
  result: {
    verified: false
    message: string
    matchPercentage: string
    maskDetections: any[]
  }
}

// Form data

export type PvtLtdCompanyFormData = {
  companyName: string
  gstNo?: string
  cinNumber?: string
  pinNumber?: string
}

export type PartnershipFormData = {
  companyName: string
  gstNo?: string
}

export type SoleProprietorFormData = {
  companyName: string
  gstNo?: string
}

export interface CompanyFormData
  extends PvtLtdCompanyFormData,
    PartnershipFormData,
    SoleProprietorFormData {}

export type BankFormData = {
  accountHolderName: string
  bankName: string
  accountNumber: string
  isfcCode: string
  branch: string
  cancelledCheque: File | null
}

export type AgreementFormData = {
  signedDocument?: File | null
}

export type ElectricityFormData = {
  electricityBoard: string
  electricityBillNo: string
  electricityCode: string
  bills: {
    firstMonth?: File | null
    secondMonth?: File | null
    thirdMonth?: File | null
  }
}

export interface CollateralFormData {
  loanType: string
  collateralDetails: Partial<CollateralTypeFormData>[]
  guaranteeDetails: Partial<GurantorFormData>
}

export type CollateralTypeFormData = {
  type: string
  amount: string
}

export interface GurantorFormData {
  name: string
  email: string
  panCard: string
  relationShip: string
  guaranteeType: string
  valueOfGuarantee: string
}

export type SoleProprietorFinancialFormData = {
  auditedFinancials: {
    firstYear?: File | null
    secondYear?: File | null
  }
  bankStatement?: File | null
  itr: {
    firstYear?: File | null
    secondYear?: File | null
  }
}

export type PvtLtdFinancialFormData = {
  auditedFinancials: {
    firstYear?: File | null
    secondYear?: File | null
  }
  bankStatement?: File | null
  itr: {
    firstYear?: File | null
    secondYear?: File | null
  }
}

export type IndividualFinancialFormData = {
  employeeDetails: string
  paySlips: {
    firstMonth?: File | null
    secondMonth?: File | null
    thirdMonth?: File | null
  }
  bankStatement?: File | null
  itr: {
    firstYear?: File | null
    secondYear?: File | null
  }
}

export type PartnershipFirmFinancialFormData = {
  auditedFinancials: {
    firstYear?: File | null
    secondYear?: File | null
  }
  bankStatement?: File | null
  itr: {
    firstYear?: File | null
    secondYear?: File | null
  }
}

export interface FinancialFormData
  extends IndividualFinancialFormData,
    PartnershipFirmFinancialFormData,
    PvtLtdFinancialFormData,
    SoleProprietorFinancialFormData {}

// API DATA TYPES
export interface LosStepResponse {
  name: string
  category: string
}

export interface CustomerPanData {
  id: string
  applicationId: string
  customerId: string
  name: string
  number: string
  typeOfHolder: string
  status: string
  firstName: string
  middleName: string
  lastName: string
  title: string
  panStatus: string
  panStatusCode: string
  aadhaarSeedingStatus: string
  aadhaarSeedingStatusCode: string
  lastUpdatedOn: string
  createdAt: string
  updatedAt: string
  valid: boolean
  individual: boolean
  panNumber: string
}

export interface CustomerAadharData {
  id: string
  createdAt: string
  updatedAt: string
  customerId: string
  applicationId: string
  name: string
  uid: string
  dob: string
  gender: string
  address: string
  photo: string
  splitAddress: string
  eaadhaar: true
  status: 'APPROVED'
}

export enum UserPanStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum UserAadharStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export interface AadharMetaData {
  id: string
  customerId: string
  applicationId: string
  name: string
  careOf: string
  uid: string
  dob: string
  gender: string
  address: string
  pinCode: string
  photo: string
  splitAddress: string
  eaadhaar: boolean
  status: UserAadharStatus
  createdAt: Date
  updatedAt: Date
}

export interface UserDataDto {
  companyDetailsResponse: CompanyDetailsResponse
  userPanData: UserPanDetailsDto[]
  userAadharData: UserAadharDetailsDto[]
  userSelfieData: UserSelfieDetailsDto[]
}

export interface UserBasicInfo {
  name: string
  mobileNumber: string
  emailId: string
  designation: string
}
export interface UserPanDetailsDto {
  userBasicInfo: UserBasicInfo
  panMetadata: PanMetadata
}

export interface UserAadharDetailsDto {
  userBasicInfo: UserBasicInfo
  aadharMetaData: AadharMetaData
}

export interface UserSelfieDetailsDto {
  userBasicInfo: UserBasicInfo
  selfieMetaData: SelfieMetaData
}

export interface CompanyDetailsResponse {
  applicationId: string
  companyName: string
  gstNo: string
  cinNo: string
  partnershipIdentificationNo: string
  gstStatus: string
  acceptGstTimeStamp: Date
  gstNumber: string
  gstMeta: GSTNDetails
  valid: boolean
  acceptTimestamp: Date
  createdAt: Date
  updatedAt: Date
  panNumber: string
  panName: string
  panMeta: string
}
export interface PanMetadata {
  id: string
  applicationId: string
  customerId: string
  panName: string
  panNumber: string
  typeOfHolder: string
  status: UserPanStatus
  isIndividual: boolean
  valid: boolean
  firstName: string
  middleName: string
  lastName: string
  title: string
  panStatus: string
  panStatusCode: string
  aadhaarSeedingStatus: string
  aadhaarSeedingStatusCode: string
  lastUpdatedOn: string
  createdAt: Date
  updatedAt: Date
  number: string
  name: string
}

export enum UserKycStatus {
  INITIATED='INITIATED',
  IN_PROGRESS='IN_PROGRESS',
  PENDING='PENDING',
  COMPLETE='COMPLETE'
}

export interface User {
  id: string;
  stepId: string;
  noOfRetries: number;
  userBasicInfo: UserBasicInfo;
  userKycStatus: UserKycStatus;
  panId: string;
  aadharId: string;
  selfieId: string;
  isPromoter: boolean;
  kycCompletionTimestamp: Date;
}

export interface ApplicationPanInfo {
  id: string
  entityId: string
  applicationId: string // Stores id to the parent onboardingApplication
  panNumber: string // Stores the entered pan number, this may also be an invalid pan
  panName: string
  panMeta?: string // Stores the id to metadata object for this pan, metadata is a refactoring of existing UserPanInfo object (optional)
  valid: boolean // Flag to indicate validity of pan entered by user
  acceptTimestamp?: Date // Accept timestamp (optional)
  acceptBureauTimestamp?: Date // Accept bureau message timestamp (optional)
  createdAt: Date // Created date
  updatedAt: Date // Last updated date
}

export interface SelfieMetaData {
  id: string
  applicationId: string
  customerId: string
  fileName: string
  // documentType: DocumentType;
  quality: number
  probability: number
  documentName: string
  score: string
  status: DocumentStatus
  metaData: string
  createdAt: Date
  updatedAt: Date
}

export enum DocumentStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export type AadharVerificationOtpResponse = {
  requestId: string
  otpSentStatus: boolean
  if_number: boolean
  status: string
  validAadhaar: boolean
}

export type ColendingLoan = {
  id: string
  createdAt: string
  updatedAt: string
  loanId: string
  colenderId: string
  applicationId: string
  officerId: string
  lendingType: string
  colendingRatio: number
  requestedLoanAmount: number
  approvedLoanAmount: number
  primaryRoi: number
  status: string
  engagementStatus: string
  programId: string
}

export type ColendedApplication = {
  programId: string
  applicationId: string
  customerId: string
  customerName: string
  phone: string
  losEntityType: string
  basicInfo?: CustomerApplicationBasicInfo
  agentId: string
  agentName: string
  loanId: string
  colendingloanId: string
  applicationStatus: string
  colenderId: string
  colenderName: string
  colendingRatio: number
  approvedAmount: number
  colendingAmount: number
  colendingRoi: number
  clientAmount: number
  clientRoi: number
  primaryRoi: number
  colendingLoansStatus: string
  engagementStatus: string
  underwritingStatus: string
  isDispersible: boolean
  lendingType: string
  createdAt: string
}

export enum ColendedApplicationStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  PRE_DISBURSED = 'PRE_DISPERSED',
}

export type LendingOfferFormData = {
  loanType: string
  approvedLoanAmount: string
  rateOfInterest: string
  colenderId: string
  colenderRatio: string
}

export enum ApplicationStatus {
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  PRE_DISPERSED = 'PRE_DISPERSED',
  READY_FOR_DISPERSAL = 'READY_FOR_DISPERSAL',
  DISPERSED = 'DISPERSED',
}

export enum EngagementSatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export type AddAgentFormData = {
  name: string
  gender: string
  mobileNumber: string
  email: string
  address: string
}

export const getApplicationStatusDisplayText = (status: string) => {
  if (status === 'READY_FOR_DISPERSAL') return 'Ready For Disbursal'
  else if (status === 'APPROVED') return 'Agreement Pending'
  else if (status === 'DISPERSED') return 'Disbursed'
  else if (status === 'PRE_DISPERSED') return 'Pre Disbursed'
  return _.startCase(_.toLower(status))
}

export type AddTrancheFormData = {
  scheduleDate: string
  amount: string
  loanId: string
}

export const isUserNonIndividual = (user?: string) => {
  if (!user) return false
  return (
    user === LosUserType.PARTNERSHIP_FIRM ||
    user === LosUserType.PVT_LIMITED ||
    user === LosUserType.SOLE_PROPRIETOR
  )
}

export const isUserIndividual = (user?: string) => {
  if (!user) return false
  return user === LosUserType.INDIVIDUAL
}

export const isUserSelfEmployed = (user?: string) => {
  if (!user) return false
  return user === LosUserType.SELF_EMPLOYED
}

export enum LosDocumentAcceptType {
  PAN_CARD = 'PAN_CARD',
  AADHAAR_CARD = 'AADHAAR_CARD',
  GST_DOCUMENT = 'GST_DOCUMENT',
  COMPANY_PAN = 'COMPANY_PAN',
}
