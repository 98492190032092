/** @format */

import { CopyrightRounded, KeyboardArrowLeft } from '@mui/icons-material';
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { SxProps, Theme, useTheme } from '@mui/system';
import React, { ReactNode, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { BORDER_GRAY_DARK } from '../../constants/style';
import Setting from '../../pages/Settings';
import { useClientAuth } from '../../providers/ClientProvider';
import { NESTED_VIEW, ROUTES, SOLFIN_ROUTES } from '../../routes';
// import { VegaPayLogo } from '../../solfen/onboarding/icons/LosIcons';
import { setHeader } from '../../store/common/stateSlice';
import { useAppDispatch } from '../../store/hook';
import AccountSelector from '../accounts/AccountSelector';
import { VegaListItem } from '../common';
import VegaScrollListItem from '../common/VegaScrollListItem';
import MainHeader from '../header/MainHeader';
import { HomeIcon, SettingIcon,VegaPayLogo } from '../icon/Icons';
// import ProgramSelector from '../program/ProgramSelector';

export enum DashboardType {
  PROGRAM = '/ccms/program',
  CLIENT = '/ccms/client',
  BACK_OFFICE = '/ccms/back_office',
  SIMULATOR = '/ccms/simulator',
  SETTINGS = '/ccms/settings',
  MCC_SIMULATOR = '/mcc/simulator',
  MCC_BRANCH = '/mcc/branch',
  MCC_HQ = '/mcc/hq',
  MCC_CORPORATE = '/mcc/corporate',
  SOLFIN_AGENT = '/solfin/agent',
  SOLFIN_HQ = '/solfin/hq',
  COLENDING = '/solfin/colending',
}

export type SidebarListItemType = {
  id: string;
  component?: ReactNode;
  icon?: string | ReactNode;
  label?: string | ReactNode;
  noRender?: boolean;
  isProgramSelector?: boolean;
  isAccountSelector?: boolean;
  isClientRoutes?: boolean;
  hideForNonAdmin?: boolean;
  hideForAdmin?: boolean;
  isSimulatorRoutes?: boolean;
  isProgramRoutes?: boolean;
  isChecker?: boolean;
  isCrmRoutes?: boolean;
  isSetting?: boolean;
  isWhiteBgColor?: boolean;
  sx?: SxProps<Theme>;
} & (
  | {
      icon: string | ReactNode;
    }
  | {
      label: string | ReactNode;
    }
);

type Props = {
  onToggle?: Function;
  sidebarListItems: SidebarListItemType[];
  sidebarListItem?: any;
  onItemClick?: (item: SidebarListItemType) => void;
  selectedItem?: any;
  setSelectedItem?: any;
};

const SELECTED_VIEW = 'view';
const drawerWidth = 250;
const shrinkedDrawerWidth = 80;

const SidebarLayout = ({
  onToggle,
  sidebarListItems,
  sidebarListItem,
  onItemClick,
  selectedItem,
  setSelectedItem,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAdmin, client, canAccessSubModule } = useClientAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const [listItems, setListItems] = useState<SidebarListItemType[]>([
    ...sidebarListItems,
  ]);
  const { pathname } = useLocation();
  const mccPath = pathname.split('/')[1];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const container =
    window !== undefined ? () => window.document.body : undefined;
  const [open, setOpen] = React.useState(false);
  const currentNestedView = searchParams.get(NESTED_VIEW);

  const [showSelector, setShowSelector] = useState<boolean>(true);
  const currentView = searchParams.get(SELECTED_VIEW);

  React.useEffect(() => {
    let selected;
    const filteredSideBarItems = getFilteredListItems();
    const isSetting = sidebarListItems.some((value) => value.isSetting);
    setListItems(filteredSideBarItems);
    if (currentView) {
      if (currentView == DashboardType.PROGRAM) {
        selected = sidebarListItem;
      } else {
        selected = sidebarListItems.filter(
          (item) => item.id === currentView
        )[0];
      }
    } else {
      if (pathname == DashboardType.PROGRAM) {
        selected = sidebarListItem;
      } else {
        selected = filteredSideBarItems[0];
      }
    }
    if (!isSetting && selected) {
      setSelectedItem(selected);
      setSearchParams(
        createSearchParams({
          [SELECTED_VIEW]: selected.id,
        })
      );
    }
  }, []);

  const getFilteredListItems = () => {
    const filteredList = sidebarListItems.filter((item) => {
      return canAccessSubModule(item.id.replace('/', ''));
    });
    if (filteredList.length === 0) return sidebarListItems;
    else return filteredList;
  };

  const handleDrawerClose = () => {
    const shouldCloseDrawer: boolean = !open;
    setShowSelector(shouldCloseDrawer == false);
    setOpen(shouldCloseDrawer);
    onToggle?.();
  };

  const handleItemClick = (item: SidebarListItemType) => {
    dispatch(setHeader(''));
    if (item.isSetting) {
      navigate('/client');
    } else {
      if (onItemClick) {
        onItemClick(item);
      } else {
        setSelectedItem(item);
        setSearchParams(createSearchParams({ [SELECTED_VIEW]: item.id }));
      }
    }
  };

  if (!selectedItem) return <></>;

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{
          bgcolor: 'primary.main',
          width: { md: open ? shrinkedDrawerWidth : drawerWidth },
          height: '100vh',
          flexShrink: { md: 0 },
          position: 'relative',
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant={isMobile || isSmallScreen ? 'temporary' : 'permanent'}
          open={open}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            height: '100vh',
            '& .MuiDrawer-paper': {
              color: 'text.secondary',
              bgcolor: 'rgba(10, 10, 94, 1)',
              boxSizing: 'border-box',
              borderRight: 'none',
              ...(!open
                ? { width: drawerWidth }
                : isMobile || isSmallScreen
                ? { width: drawerWidth }
                : { width: shrinkedDrawerWidth }),
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Box>
              <List sx={{ borderBottom: BORDER_GRAY_DARK }}>
                <ListItem
                  sx={{
                    display: 'flex',
                    transition: 'all 500ms ease-in-out',
                    ...(!open
                      ? { px: 5, py: 2, justifyContent: 'space-between' }
                      : isMobile || isSmallScreen
                      ? { px: 5, py: 2, justifyContent: 'space-between' }
                      : { px: 1, py: 2 }),
                  }}
                >
                  {!open ? (
                    mccPath === 'mcc' ? (
                      <Box onClick={() => handleItemClick(listItems[0])}>
                        {/* <VegaPayLogo /> */}
                        <img
                        onClick={() => handleItemClick(listItems[0])}
                        src={
                          'https://vegapay-alpha-image-assets.s3.ap-south-1.amazonaws.com/logo/solfinlog.png'
                        }
                        alt=""
                        style={{
                          cursor: 'pointer',
                          maxWidth: '100px',
                          maxHeight: '25px',
                        }}
                      />
                      </Box>
                    ) : (
                      <img
                        onClick={() => handleItemClick(listItems[0])}
                        src={
                          'https://vegapay-alpha-image-assets.s3.ap-south-1.amazonaws.com/logo/solfinlog.png'
                        }
                        alt=""
                        style={{
                          cursor: 'pointer',
                          maxWidth: '100px',
                          maxHeight: '25px',
                        }}
                      />
                    )
                  ) : isMobile || isSmallScreen ? (
                    <img
                      onClick={() => navigate('/client')}
                      width={30}
                      height={30}
                      src={
                        'https://vegapay-alpha-image-assets.s3.ap-south-1.amazonaws.com/logo/solfinlog.png'
                      }
                      alt=""
                      style={{ cursor: 'pointer' }}
                    />
                    // <VegaPayLogo />
                  ) : (
                    <Box sx={{ height: '25px' }}></Box>
                  )}

                  <IconButton
                    onClick={handleDrawerClose}
                    sx={{
                      bgcolor: '#232346',
                      ':hover': { bgcolor: '#232346' },
                      mx: open ? 'auto' : 'inherit',
                    }}
                  >
                    <KeyboardArrowLeft
                      fontSize="small"
                      sx={{
                        color: 'white',
                        transition: '0.75s',
                        ...(!open
                          ? { transform: 'rotate(0deg)' }
                          : { transform: 'rotate(180deg)' }),
                      }}
                    />
                  </IconButton>
                </ListItem>

                {pathname === '/setting' && (
                  <ListItem
                    sx={{
                      bgcolor: '#232346',
                      border: '8px solid #08A6FF',
                      borderRight: 'none',
                      borderTop: 'none',
                      borderBottom: 'none',
                      cursor: 'pointer',
                      ...(open
                        ? { paddingLeft: '9px' }
                        : { paddingLeft: '25px' }),
                    }}
                    onClick={() => navigate('/client')}
                  >
                    <Grid container>
                      {!open ? (
                        <ListItemText>
                          <Typography
                            sx={{
                              color: '#08A6FF',
                              bgcolor: 'inherit',
                              fontWeight: '500',
                            }}
                          >
                            Home{' '}
                            <span style={{ color: 'white' }}>
                              {'>'} Settings
                            </span>
                          </Typography>
                        </ListItemText>
                      ) : isMobile || isSmallScreen ? (
                        <ListItemText
                          sx={{ color: 'text.secondary', bgcolor: 'inherit' }}
                          primary={'Home'}
                        />
                      ) : (
                        <HomeIcon />
                      )}
                    </Grid>
                  </ListItem>
                )}

                {listItems.map((item, index) =>
                  (!isAdmin && item.hideForNonAdmin) ||
                  (isAdmin && item.hideForAdmin) ? (
                    <></>
                  ) : item.isSetting ? (
                    <VegaScrollListItem
                      key={index}
                      isMobile={isMobile}
                      isSamllScreen={isSmallScreen}
                      open={open}
                      id={item.id}
                      icon={item.icon}
                      label={item.label}
                      selected={selectedItem?.id === item.id}
                      // onItemClick={() => handleItemClick(item)}
                    />
                  ) : (
                    <VegaListItem
                      key={index}
                      isMobile={isMobile}
                      isSamllScreen={isSmallScreen}
                      open={open}
                      id={item.id}
                      isChecker={item.isChecker}
                      icon={item.icon}
                      label={item.label}
                      selected={selectedItem?.id === item.id}
                      onItemClick={() => handleItemClick(item)}
                    />
                  )
                )}
              </List>
            </Box>
            <Box
              sx={{
                mb: 3,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {pathname === DashboardType.PROGRAM && (
                <List>
                  <VegaListItem
                    isMobile={isMobile}
                    isSamllScreen={isSmallScreen}
                    open={open}
                    id={ROUTES.PROGRAM}
                    icon={<SettingIcon />}
                    label={'Program Summary'}
                    selected={selectedItem?.id === sidebarListItem.id}
                    onItemClick={() => handleItemClick(sidebarListItem)}
                    key={'setting'}
                  />
                </List>
              )}
              {!open ? (
                <Stack>
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'white',
                      fontWeight: 500,
                    }}
                    pl={5}
                  >
                    Vegapay Version 1.001
                  </Typography>
                </Stack>
              ) : isMobile || isSmallScreen ? (
                <>
                  <Typography
                    variant="caption"
                    sx={{ color: 'white', fontWeight: 500 }}
                    pl={5}
                  >
                    Vegapay Version 1.001
                  </Typography>
                </>
              ) : (
                <List>
                  <VegaListItem
                    isMobile={isMobile}
                    isSamllScreen={isSmallScreen}
                    open={open}
                    id={'Simulator version'}
                    icon={<CopyrightRounded />}
                    label={'Copyright Vegapay Technology Pvt limited'}
                    key={'SIMULATOR'}
                  />
                </List>
              )}
            </Box>
          </Box>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          height: '100vh !important',
          overflow: 'auto',
          position: 'relative',
          bgcolor: 'white',
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },

          scrollbarWidth: 'thin',
          scrollbarColor: 'transparent transparent',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'transparent',
          },
        }}
      >
        <MainHeader
          setOpen={setOpen}
          isSmallScreen={isSmallScreen}
          isMobile={isMobile}
        />
        <Box px={0} pb={0} pt={8}>
          {selectedItem.isSetting ? (
            <Setting />
          ) : selectedItem.noRender ? (
            <></>
          ) : (
            <>
              <Box display={'flex'} gap={2}>
                <AccountSelector disabledOn={selectedItem?.isAccountSelector} />
              </Box>
              {selectedItem.component}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarLayout;
