import { Close } from '@mui/icons-material';
import { Dialog, Stack, IconButton } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import LosButton from '../../../onboarding/components/common/LosButton';
import LosFormInputField from '../../../onboarding/components/common/LosFormInputField';
import LosSelect, {
  LosSelectOption,
} from '../../../onboarding/components/common/LosSelect';
import LosText from '../../../onboarding/components/common/LosText';
import LosTextField from '../../../onboarding/components/common/LosTextField';
import { AddAgentFormData } from '../../../onboarding/types/Los';
import { StringUtility } from '../../../onboarding/utils/StringUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: AddAgentFormData) => void;
  initialFormData?: AddAgentFormData;
  initialViewState?: AddAgentDialogViewState;
};

enum InputType {
  Name = 'name',
  Gender = 'gender',
  MobileNumber = 'mobileNumber',
  Email = 'email',
  Address = 'address',
}

export enum AddAgentDialogViewState {
  Add,
  Update,
}

function AddAgentDialog({
  open,
  onClose,
  onSubmit,
  initialFormData,
  initialViewState,
}: Props) {
  const [error, setErrors] = useState<Partial<AddAgentFormData>>({});
  const [viewState, setViewState] = useState<AddAgentDialogViewState>(
    AddAgentDialogViewState.Add
  );
  const [formData, setFormData] = useState<AddAgentFormData>({
    name: '',
    gender: '',
    mobileNumber: '',
    email: '',
    address: '',
  });

  function handleUpdate(type: InputType, value: string) {
    const updatedErrors = { ...error };
    if (type === InputType.MobileNumber && value.length > 10) {
      return;
    }

    if (type === InputType.Email) {
      const validEmail = StringUtility.validateEmail(value);
      if (validEmail == false) {
        updatedErrors.email = 'Please enter a valid email';
      } else {
        delete updatedErrors.email;
      }
    }

    setFormData((prev) => {
      return { ...prev, [type]: value };
    });
    setErrors(updatedErrors);
  }

  const isInputValid = () => {
    const isNameValid = formData.name?.length > 0;
    const isGenderValid = formData.gender.length > 0;
    const isMobileNumberValid = formData.mobileNumber.length > 0;
    const isAddressValid = formData.address.length > 0;
    return (
      isNameValid && isGenderValid && isMobileNumberValid && isAddressValid
    );
  };

  async function onSubmitClick() {
    onSubmit(formData);
    handleClose();
  }

  function handleClose() {
    setFormData({
      name: '',
      gender: '',
      mobileNumber: '',
      email: '',
      address: '',
    });
    onClose();
  }

  const getTitle = () => {
    if (viewState == AddAgentDialogViewState.Add) {
      return 'Add Agent';
    } else if (viewState == AddAgentDialogViewState.Update) {
      return 'Update Agent';
    }
    return '';
  };

  useEffect(() => {
    if (open == true && initialFormData) {
      setFormData(initialFormData);
    }
  }, [open]);

  useEffect(() => {
    if (initialViewState) {
      setViewState(initialViewState);
    }
  }, [initialViewState]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      sx={{ borderRadius: '1rem' }}
      onClose={handleClose}
      open={open}
    >
      <div
        style={{
          backgroundColor: 'rgba(250, 250, 250, 1)',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems={'center'}
          style={{
            paddingTop: '1.25rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <LosText text={getTitle()} />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <div
          style={{
            padding: '1rem',
            borderRadius: '0.75rem',
          }}
        >
          <Stack spacing={'1rem'}>
            <LosFormInputField label="Agent Name">
              <LosTextField
                value={formData.name}
                onChange={(e) => handleUpdate(InputType.Name, e.target.value)}
              />
            </LosFormInputField>

            <LosFormInputField label="Gender">
              <LosSelect
                value={formData.gender}
                options={genderOptions()}
                onChange={(e) => {
                  handleUpdate(InputType.Gender, e.target.value as string);
                }}
              />
            </LosFormInputField>

            <LosFormInputField label="Mobile Number">
              <LosTextField
                value={formData.mobileNumber}
                type="number"
                onChange={(e) =>
                  handleUpdate(InputType.MobileNumber, e.target.value)
                }
              />
            </LosFormInputField>

            <LosFormInputField label="Email">
              <LosTextField
                value={formData.email}
                onChange={(e) => handleUpdate(InputType.Email, e.target.value)}
                error={!!error.email}
                helperText={error.email}
              />
            </LosFormInputField>
            <LosFormInputField label="Address">
              <LosTextField
                value={formData.address}
                onChange={(e) =>
                  handleUpdate(InputType.Address, e.target.value)
                }
              />
            </LosFormInputField>
          </Stack>
        </div>
      </div>
      <Stack
        direction={'row'}
        justifyContent="end"
        alignItems={'center'}
        style={{ padding: '1rem' }}
      >
        <LosButton
          text={getTitle()}
          onClick={onSubmitClick}
          disabled={isInputValid() == false}
        />
      </Stack>
    </Dialog>
  );
}

export default AddAgentDialog;

enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}
const genderOptions = () =>
  Object.values(GenderType).map((item) => {
    const option: LosSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
