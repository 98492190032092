import React from 'react';
import { Box, Typography, Grid, Skeleton, Stack } from '@mui/material';
import LOSTextWrapper from '../../../components/LOSTextWrapper';
import LosStatusPill from '../../../components/LosStatusPill';
import VegaHeader from '../../../../components/common/VegaHeader';
import LosButton from '../../../onboarding/components/common/LosButton';
import { CustomerApplication } from '../../../onboarding/types/Los';
import { toLowerCase } from '../../../../constants/commonFunction';

interface IProps {
  applications: CustomerApplication[];
  loading: boolean;
  showDetails: (id: string) => void;
  showUpdateModal: (id: string) => void;
}

const PreDisbursalList = ({
  applications,
  loading,
  showDetails,
  showUpdateModal,
}: IProps) => {
  return (
    <>
      {applications.map((application: CustomerApplication) => (
        <Box
          sx={{
            p: 2,
            bgcolor: 'white',
            boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
            borderRadius: '6px',
            mb: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <LOSTextWrapper
                header="customer id"
                loading={loading}
                text={application.customerId}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <LOSTextWrapper
                header="customer name"
                loading={loading}
                text={application.customerName ?? '--'}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <LOSTextWrapper
                header="Requested loan amount"
                loading={loading}
                text={`₹ ${application.basicInfo?.amount ?? '--'}`}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <LOSTextWrapper
                header="tenure"
                loading={loading}
                text={`${application.basicInfo?.tenure ?? '--'} Months`}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <LOSTextWrapper
                header="type"
                loading={loading}
                text={toLowerCase(application.losEntityType)}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <LOSTextWrapper
                header="mobile no."
                loading={loading}
                text={application?.phone ?? '--'}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              {loading ? (
                <Skeleton />
              ) : (
                <>
                  <VegaHeader text={'status'} />
                  <LosStatusPill status={application.status} />
                </>
              )}
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={6} sm={6}>
              <Stack direction={'row'} spacing="0.5rem">
                <LosButton
                  size="small"
                  text="View"
                  variant="outlined"
                  onClick={() => {
                    showDetails(application.id);
                  }}
                />
                <LosButton
                  size="small"
                  text="Update"
                  variant="outlined"
                  onClick={() => {
                    showUpdateModal(application.id);
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
};

export default PreDisbursalList;
