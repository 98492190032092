import { Edit } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import LosButton from '../../../../../onboarding/components/common/LosButton';
import { useColenders } from '../../../../providers/ColendersProvider';
import ColenderBankDetailsForm from '../Forms/ColenderBankDetailsForm';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
enum ViewState {
  ReadOnly,
  Edit,
}

type FormData = {
  colenderName: string;
  dateOfRegistration: string;
  pan: string;
  cin: string;
  tin: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
};

function ColenderBankDetailsTab() {
  const { selectedColender } = useColenders();
  const [viewState, setViewState] = useState<ViewState>(ViewState.ReadOnly);
  const [formData, setFormData] = useState<Partial<FormData>>({});

  function updateViewState(viewState: ViewState) {
    setViewState(viewState);
  }

  function handleUpdate(type: string, value?: string) {
    setFormData((prev) => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  useEffect(() => {
    updateViewState(ViewState.ReadOnly);

    var registrationDateDisplayText = '-';

    if (selectedColender?.registrationDate) {
      const date = new Date(selectedColender.registrationDate);
      registrationDateDisplayText = dayjs(date).format('DD-MM-YYYY');
    }

    setFormData({
      colenderName: selectedColender?.name,
      pan: selectedColender?.panNo,
      cin: selectedColender?.cin,
      tin: selectedColender?.tin,
      address: selectedColender?.address,
      city: selectedColender?.city,
      state: selectedColender?.state,
      pincode: selectedColender?.pincode,
      dateOfRegistration: registrationDateDisplayText,
    });
  }, [selectedColender]);

  return (
    <div
      style={{
        padding: '12px',
      }}
    >
      <ColenderBankDetailsForm
        formData={formData}
        handleUpdate={handleUpdate}
        isEditable={viewState == ViewState.Edit}
      />
      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      />
      <Stack
        direction={'row'}
        justifyContent="end"
        alignItems={'center'}
        spacing={'1rem'}
        display={viewState == ViewState.ReadOnly ? 'flex' : 'none'}
      >
        <LosButton
          startIcon={<Edit />}
          text="Edit"
          size="small"
          variant="outlined"
          onClick={() => {
            updateViewState(ViewState.Edit);
          }}
        />
      </Stack>
      <Stack
        direction={'row'}
        justifyContent="end"
        alignItems={'center'}
        spacing={'1rem'}
        display={viewState == ViewState.Edit ? 'flex' : 'none'}
      >
        <LosButton
          size="small"
          text="Save"
          variant="outlined"
          onClick={() => {}}
        />
        <LosButton
          text="Cancel"
          size="small"
          variant="outlined"
          onClick={() => {
            updateViewState(ViewState.ReadOnly);
          }}
        />
      </Stack>
    </div>
  );
}

export default ColenderBankDetailsTab;
