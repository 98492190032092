import { Close } from '@mui/icons-material';
import { Dialog, Stack, IconButton, Grid } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { ColenderService } from '../../LMSHQ/api/ColenderService';
import { ReadOnlyFormField } from '../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField';
import { Colender } from '../../LMSHQ/types/Lms';
import { LOS_PROGRAM_ID } from '../../onboarding/api/constants';
import { LosService } from '../../onboarding/api/LosService';
import LosButton from '../../onboarding/components/common/LosButton';
import LosText from '../../onboarding/components/common/LosText';
import { ColendingLoan, CustomerApplication } from '../../onboarding/types/Los';
import { StringUtility } from '../../onboarding/utils/StringUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  applicationId?: string;
};

function ConfirmColendingOffer({
  open,
  onClose,
  onConfirm,
  applicationId,
}: Props) {
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [customerApplication, setCustomerApplication] =
    useState<CustomerApplication>();

  const [loadingLoan, setLoadingLoan] = useState<boolean>(false);
  const [colendedLoan, setColendedLoan] = useState<ColendingLoan>();

  const [colender, setColender] = useState<Colender>();
  const [loadingColender, setLoadingColender] = useState<boolean>(false);

  async function fetchApplication(applicationId: string) {
    try {
      setLoading(true);
      const response = await LosService.getApplicationById(applicationId);
      setCustomerApplication(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function fetchApplicationColendedLoan(applicationId: string) {
    try {
      setLoadingLoan(true);
      const response = await ColenderService.getColendedLoansForApplicationId(
        applicationId
      );
      if (response.length > 0) {
        const firstLoan = response[0];
        setColendedLoan(firstLoan);
        fetchColender({
          programId: LOS_PROGRAM_ID,
          colenderId: firstLoan.colenderId,
        });
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoadingLoan(false);
    }
  }

  async function fetchColender(data: {
    programId: string;
    colenderId: string;
  }) {
    try {
      setLoadingColender(true);
      const response = await ColenderService.getColenders(data.programId);

      const currentColender = response.find((item) => {
        if (item.id == data.colenderId && item.programId == data.programId)
          return true;
        return false;
      });

      setColender(currentColender);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColender(false);
    }
  }

  const getColendedAmount = () => {
    if (colendedLoan?.approvedLoanAmount && colendedLoan.colendingRatio) {
      const approvedAmount = Number(colendedLoan.approvedLoanAmount);
      const ratio = Number(colendedLoan.colendingRatio);

      return StringUtility.formatCurrency(approvedAmount * (ratio / 100));
    }
    return '-';
  };

  const getEntityTypeDisplayText = () => {
    if (customerApplication?.basicInfo.subType) {
      return _.startCase(_.toLower(customerApplication.basicInfo.subType));
    }
    return '-';
  };

  useEffect(() => {
    if (open == true && applicationId != null) {
      fetchApplication(applicationId);
      fetchApplicationColendedLoan(applicationId);
    }
  }, [open, applicationId]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      sx={{ borderRadius: '20px' }}
      onClose={onClose}
      open={open}
    >
      <div
        style={{
          backgroundColor: 'rgba(250, 250, 250, 1)',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems={'center'}
          style={{
            paddingTop: '1.25rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <LosText text="Confirm Offer" />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <div
          style={{
            border: '1px solid #E1E4EB',
            padding: '1rem',
            margin: '1rem',
            borderRadius: '0.75rem',
            background: 'white',
          }}
        >
          <Stack spacing={'1rem'}>
            <LosText text="Loan Details" />
            <div>
              <Grid container spacing={'1rem'}>
                <Grid item xs={4}>
                  <ReadOnlyFormField
                    title="Customer Name"
                    description={customerApplication?.customerName}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReadOnlyFormField
                    title="Entity"
                    description={getEntityTypeDisplayText()}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReadOnlyFormField
                    title="Loan Amount"
                    description={`${getColendedAmount()}`}
                    loading={loadingLoan}
                  />
                </Grid>

                <Grid item xs={4}>
                  <ReadOnlyFormField
                    title="Colender ROI"
                    description={`${colender?.interest ?? '-'}%`}
                  />
                </Grid>

                <Grid item xs={4}>
                  <ReadOnlyFormField
                    title="Tenure"
                    description={`${
                      customerApplication?.basicInfo.tenure ?? '-'
                    } Months`}
                    loading={loading}
                  />
                </Grid>
              </Grid>
            </div>
          </Stack>
        </div>
      </div>
      <Stack
        direction={'row'}
        justifyContent="end"
        alignItems={'center'}
        style={{ padding: '1rem' }}
      >
        <LosButton text="Confirm" onClick={onConfirm} />
      </Stack>
    </Dialog>
  );
}

export default ConfirmColendingOffer;
