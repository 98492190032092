import { createContext, useContext, useState } from 'react';

type AppConfigProviderContextType = {
  privateKey: string;
  updatePrivateKey: (key: string) => void;
};

const AppConfigProviderContext =
  createContext<AppConfigProviderContextType | null>(null);

export const useAppConfig = () =>
  useContext(AppConfigProviderContext) as AppConfigProviderContextType;

export const AppConfigProvider = ({ children }: any) => {
  const [privateKey, setPrivateKey] = useState<string>('');

  function _setPrivateKey(key: string) {
    setPrivateKey(key);
  }

  return (
    <AppConfigProviderContext.Provider
      value={{ privateKey: privateKey, updatePrivateKey: _setPrivateKey }}
    >
      {children}
    </AppConfigProviderContext.Provider>
  );
};
