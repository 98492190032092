import { useLos } from '../../providers/LosProvider';
import { LosUserType } from '../../types/Los';
import PageNotFound from '../PageNotFound';
import IndividualFinancialDetails from './IndividualFinancialDetails';
import PartnershipFirmFinancialDetails from './PartnershipFirmFinancialDetails';
import PvtLtdFinancialDetails from './PvtLtdFinancialDetails';
import SelfEmployedFinancialDetails from './SelfEmployedFinancialDetails';
import SoleProprietorFinancialDetails from './SoleProprietorFinancialDetails';

function FinancialDetails() {
  const { currentUserType } = useLos();

  if (currentUserType == LosUserType.INDIVIDUAL) {
    return <IndividualFinancialDetails />;
  } else if (currentUserType == LosUserType.PARTNERSHIP_FIRM) {
    return <PartnershipFirmFinancialDetails />;
  } else if (currentUserType == LosUserType.PVT_LIMITED) {
    return <PvtLtdFinancialDetails />;
  } else if (currentUserType == LosUserType.SOLE_PROPRIETOR) {
    return <SoleProprietorFinancialDetails />;
  } else if (currentUserType == LosUserType.SELF_EMPLOYED) {
    return <SelfEmployedFinancialDetails />;
  }

  return <PageNotFound />;
}

export default FinancialDetails;
