import './../../../../components/common/v2/Styles/__styles.css';
import { Stack, styled, Typography } from '@mui/material';
import React from 'react';
import VegaHeader from '../../../../components/common/VegaHeader';

const StyledInputHeading = styled(Typography)(({ theme }) => ({
  fontSize: '.625rem',
  fontFamily: 'Aspekta !important',
  color: '#676B76',
  fontWeight: 700,
  lineHeight: '124%',
  letterSpacing: '0.04375rem',
  textTransform: 'uppercase',
}));

interface LosFormInputFieldProps {
  label: string;
  children: React.ReactNode;
  endAdornment?: React.ReactNode;
}

function LosFormInputField({
  label,
  children,
  endAdornment,
}: LosFormInputFieldProps) {
  return (
    <Stack spacing={'0.5rem'}>
      <Stack
        direction={'row'}
        spacing={1}
        justifyContent="space-between"
        alignItems={'center'}
        maxHeight="0.75rem"
      >
        <StyledInputHeading>{label}</StyledInputHeading> {endAdornment}
      </Stack>
      {children}
    </Stack>
  );
}

export default LosFormInputField;
