import { AxiosProgressEvent } from 'axios'
import { log } from 'console'
import _ from 'lodash'
import { createContext, useContext, useEffect, useState } from 'react'
import { useSnackbar } from '../../../providers/SnackbarProvider'
import {
  getErrorMessageFromErrorObj,
  PaginatedResponse,
} from '../../../utils/api'
import {
  GetLoanLedgerApiData,
  LoanManagementService,
} from '../../LMSHQ/api/LoanManagementService'
import {
  Loan,
  LoanDispersalSchedule,
  LoanEmiSchedule,
  LoanLedger,
} from '../../LMSHQ/types/Lms'
import { LosService } from '../api/LosService'
import { useLosEnumApi } from '../hooks/useLosEnumApi'
import { useLosStepApi } from '../hooks/useLosStepApi'
import {
  AadharVerificationOtpResponse,
  AgreementFormData,
  BankFormData,
  CollateralFormData,
  CompanyFormData,
  CustomerAadharData,
  CustomerApplication,
  CustomerApplicationBasicInfo,
  CustomerPanData,
  ElectricityBoard,
  ElectricityFormData,
  FinancialFormData,
  Gurantor,
  LosCategory,
  LosDocumentType,
  LosEntityType,
  LosStep,
  PanMetadata,
  UserType,
} from '../types/Los'
import { LocalStorageUtility, StorageKeys } from '../utils/StorageUtility'

const MAX_STEP_NUMBER = 10000

type CurrentStepMetaData = {
  index: number
  total: number
  categoryName: string
  step?: LosStep
  description?: string
}

type LosProviderContextType = {
  loading: boolean
  loadingApplication: boolean
  journeySteps: string[]
  currentUserType?: string
  customerApplication: CustomerApplication | null
  userTypes: string[]
  individualSubTypes: string[]
  nonIndividualSubTypes: string[]
  collateralTypes: string[]
  loanTypes: string[]
  relationTypes: string[]
  electrictyBoards: ElectricityBoard[]
  onUserTypeChange: (userType: string) => void
  navigateToNextStep: () => void
  currentStep: LosStep
  currentStepMetaData: CurrentStepMetaData
  saveApplicationFormData: (data: CustomerApplicationBasicInfo) => Promise<void>
  saveIndividualPanData: (data: {
    name: string
    panNumber: string
    userId?: string
    emailId?: string
    mobileNumber?: string
  }) => Promise<void>
  saveCompanyPanData: (data: {
    name: string
    panNumber: string
  }) => Promise<void>
  savePromoter: (data: {
    name: string
    panNumber: string
    designation: string
    userId: string
    emailId: string
    mobileNumber: string
  }) => Promise<void>
  saveIndividualSelfie: (data: {
    selfie: string
    onUploadProgress: (event: AxiosProgressEvent) => void
  }) => Promise<boolean>
  saveFiancialData: (data: Partial<FinancialFormData>) => Promise<void>
  saveCollateralData: (data: Partial<CollateralFormData>) => Promise<void>
  saveElectricityData: (data: Partial<ElectricityFormData>) => Promise<void>
  saveAgreement: (data: Partial<AgreementFormData>) => Promise<void>
  saveBankDetails: (data: Partial<BankFormData>) => Promise<void>
  saveCompanyData: (data: Partial<CompanyFormData>) => Promise<void>
  saveCinData: (data: Partial<CompanyFormData>) => Promise<void>
  savePinData: (data: Partial<CompanyFormData>) => Promise<void>
  fetchApplicationState: () => Promise<void>
  fetchPanDetailsForPanNumber: (panNumber: string) => Promise<CustomerPanData>
  fetchGSTDetailsForGSTNumber: (gstNumber: string) => Promise<CustomerPanData>
  fetchPanDetailsForPanInfoId: (panInfoId: string) => Promise<PanMetadata>
  fetchAadharDetailsForAadharInfoId: (
    aadhaarInfoId: string
  ) => Promise<CustomerAadharData>
  updatePanStatusToAccepted: () => Promise<void>
  updateCompanyPanStatusToAccepect: () => Promise<void>
  updateAadharStatusToAccepted: () => Promise<void>
  updateGstStatusToAccepted: () => Promise<void>
  updateApplicationEntityType: (entityType: string) => Promise<void>
  approveSanctionLetter: () => Promise<void>
  markPromotersDetailsAsCompleted: () => void
  generateAadharOtp: (data: {
    aadhaarNumber: string
  }) => Promise<AadharVerificationOtpResponse>
  verifyAadharOtp: (data: {
    requestId: string
    otp: string
  }) => Promise<boolean>
  fetchLoanForApplication: (applicationId: string) => Promise<Loan>
  fetchLoanEmiSchedule: (loanId: string) => Promise<LoanEmiSchedule[]>
  fetchLoanDispersalSchedule: (
    loanId: string
  ) => Promise<LoanDispersalSchedule[]>
  fetchLoanLedger(data: {
    loanId: string
    filters: Partial<GetLoanLedgerApiData>
  }): Promise<PaginatedResponse<LoanLedger>>
  fetchApplication(applicationId: string): Promise<CustomerApplication>
  fetchCollateralTypes: () => void
  saveElectricityDataWithDocument(data: {
    applicationId: string
    file: File
    documentType: LosDocumentType
    electricityBoard: string
    electricityBillNo: string
    code: string
  }): Promise<CustomerApplication>
}

const LosProviderContext = createContext<LosProviderContextType | null>(null)

export const useLos = () =>
  useContext(LosProviderContext) as LosProviderContextType

export const LosProvider = ({ children }: any) => {
  const { setSnackbar } = useSnackbar()
  const { getNextStep: fetchNextStep } = useLosStepApi()
  const {
    getUserSubTypes,
    getLoanTypes,
    getRelationTypes,
    getUserTypes,
    getCollateralTypes,
  } = useLosEnumApi()
  const [customerApplication, setCustomerApplication] =
    useState<CustomerApplication | null>(null)

  const [currentStepIndex, setCurrentStepIndex] =
    useState<number>(MAX_STEP_NUMBER)
  const [losJourneySteps, setLosJourneySteps] = useState<string[]>([])
  const [currentLosStep, setCurrentLosStep] = useState<LosStep>(
    LosStep.APPLICATION_FORM
  )
  const [currentCategory, setCurrentCategory] = useState<string>('')
  const [arePromoterDetailsCompleted, setArePromoterDetailsCompleted] =
    useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingCustomerApplication, setLoadingCustomerApplication] =
    useState<boolean>(false)
  const [journeyStepMetaData, setJourneyStepMetaData] = useState<LosJourney[]>(
    []
  )
  const [userTypes, setUserTypes] = useState<string[]>([])
  const [nonIndividualSubTypes, setNonIndividualSubTypes] = useState<string[]>(
    []
  )
  const [individualSubTypes, setIndividualSubTypes] = useState<string[]>([])
  const [loanTypes, setLoanTypes] = useState<string[]>([])
  const [relationTypes, setRelationType] = useState<string[]>([])
  const [electrictyBoards, setElectricityBoards] = useState<ElectricityBoard[]>(
    []
  )
  const [collateralType, setCollateralType] = useState<string[]>([])

  const getNextStepFromBackend = async () => {
    const applicationId = LocalStorageUtility.getItem<string>(
      StorageKeys.APPLICATION_ID
    )
    if (!applicationId) {
      console.error('Application id not found')
      return
    }
    let step = await fetchNextStep({
      applicationId: applicationId,
    })

    console.log('Get next Step')

    if (step.name === LosStep.USER_KYC_COMPLETE) {
      console.log('KYC COMPLETE ')
      await LosService.updateNextStep(applicationId)
      step = await fetchNextStep({
        applicationId: applicationId,
      })
    }
    setCurrentCategory(step.category)
    const nextStep = getEnumValueFromString(LosStep, step.name)

    return nextStep
  }

  async function _navigateToNextStep() {
    const applicationId = LocalStorageUtility.getItem<string>(
      StorageKeys.APPLICATION_ID
    )
    if (!applicationId) {
      console.error('Application id not found')
      return
    }
    setLoading(true)
    const nextStep = await getNextStepFromBackend()
    if (nextStep) {
      setCurrentLosStep(nextStep)
    }
  }

  function _onUserTypeChange(userType: string) {
    // setSelectedUserType(userType);
    // setLosJourneySteps(getLosJourneyForUser(userType));
  }

  const getCurrentStep = () => {
    return currentLosStep
  }

  async function _saveApplicationFormData(data: CustomerApplicationBasicInfo) {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )
      if (!applicationId) return
      const response = await LosService.saveApplicationForm({
        applicationId: applicationId,
        userType: data.userType,
        subType: data.subType,
        loanType: data.loanType,
        email: data.email,
        amount: data.amount.toString(),
        tenure: data.tenure.toString(),
      })
      setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }

  async function _saveIndividualPanData(data: {
    name: string
    panNumber: string
    userId?: string
    emailId?: string
    mobileNumber?: string
  }) {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )
      if (!applicationId) return
      const response = await LosService.savePanDetails({
        memberBasicInfo: {
          name: data.name.trim(),
        },
        userBasicInfo: {
          name: data.name.trim(),
          emailId: data.emailId,
          mobileNumber: data.mobileNumber,
        },
        panNumber: data.panNumber,
        applicationId: applicationId,
        userId: data.userId,
      })
      setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }

  async function _saveCompanyPanData(data: {
    name: string
    panNumber: string
  }) {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )
      if (!applicationId) return
      const response = await LosService.savePanDetails({
        companyName: data.name.trim(),
        panNumber: data.panNumber,
        applicationId: applicationId,
      })
      setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }

  async function _savePromoter(data: {
    name: string
    panNumber: string
    designation: string
    userId: string
    emailId: string
    mobileNumber: string
  }) {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )
      if (!applicationId) return
      const response = await LosService.savePanDetails({
        userBasicInfo: {
          name: data.name.trim(),
          designation: data.designation.trim(),
          emailId: data.emailId,
          mobileNumber: data.mobileNumber,
        },
        panNumber: data.panNumber,
        applicationId: applicationId,
        userId: data.userId,
      })
      setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }

  async function _fetchPanDetails(panNumber: string): Promise<CustomerPanData> {
    try {
      const response = await LosService.fetchPanDetails({
        panNumber: panNumber,
      })
      return response
    } catch (error) {
      throw error
    }
  }
  async function _fetchGSTDetails(gstNumber: string): Promise<CustomerPanData> {
    try {
      const response = await LosService.fetchGSTDetails({
        gstNumber: gstNumber,
      })
      return response
    } catch (error) {
      throw error
    }
  }

  async function _fetchPanDetailsForPanInfoId(
    panInfoId: string
  ): Promise<PanMetadata> {
    try {
      const response = await LosService.fetchPanByPanInfoId({
        panInfoId: panInfoId,
      })
      return response
    } catch (error) {
      throw error
    }
  }

  async function _updatePanStatusToAccepted(): Promise<void> {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )
      if (!applicationId) return
      const response = await LosService.updatePanStatusToAccept({
        applicationId: applicationId,
      })
      return response
    } catch (error) {
      throw error
    }
  }
  async function _updateCompanyPanStatusToAccepect(): Promise<void> {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )
      if (!applicationId) return
      const response = await LosService.updateCompanyPanStatusToAccept({
        applicationId: applicationId,
      })
      return response
    } catch (error) {
      throw error
    }
  }

  async function _updateAadharStatusToAccepted(): Promise<void> {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )
      if (!applicationId) return
      const response = await LosService.updateAadharStatusToAccept({
        applicationId: applicationId,
      })

      return response
    } catch (error) {
      throw error
    }
  }

  async function _updateGSTStatusToAccepted(): Promise<void> {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )
      if (!applicationId) return
      const response = await LosService.updateGstStatusToAccept({
        applicationId: applicationId,
      })
      return response
    } catch (error) {
      throw error
    }
  }

  // const getLatestMember = () => {
  //   const members = customerApplication?.members ?? []
  //   if (members.length > 0) {
  //     const lastMember = members[members.length - 1]
  //     return lastMember
  //   }
  // }

  async function _saveIndividualSelfie(data: {
    selfie: string
    onUploadProgress: (event: AxiosProgressEvent) => void
  }): Promise<boolean> {
    try {
      // const lastMember = getLatestMember()
      // // if (!lastMember) throw new Error('Member not found')
      const customerApplication =
        LocalStorageUtility.getItem<CustomerApplication>(
          StorageKeys.CUSTOMER_APPLICATION
        )
      const currentIndex = customerApplication?.currentUserIndex
        ? customerApplication?.currentUserIndex
        : 0

      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )
      if (!applicationId) {
        throw new Error('Application id ' + applicationId + ' are required')
      }

      const response = await LosService.saveCustomerSelfie({
        apiData: {
          applicationId: applicationId,
          userId: customerApplication
            ? customerApplication?.users[currentIndex]
            : '',
          // memberId: memberId,
          selfie: data.selfie,
        },
        onUploadProgress: data.onUploadProgress,
      })

      await _fetchCustomerApplication(applicationId)
      return response.result.verified
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      return false
    }
  }

  async function _saveCollateralData(
    data: Partial<CollateralFormData>
  ): Promise<void> {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )
      if (!applicationId) return
      var guranteeDetails: Partial<Gurantor>[] = []
      if (objectHasValues(data.guaranteeDetails)) {
        guranteeDetails = [
          {
            name: data.guaranteeDetails?.name,
            relationShip: data.guaranteeDetails?.relationShip,
            email: data.guaranteeDetails?.email,
            panCard: data.guaranteeDetails?.panCard,
            guaranteeType: data.guaranteeDetails?.guaranteeType,
            valueOfGuarantee: data.guaranteeDetails?.valueOfGuarantee,
          },
        ]
      }
      const response = await LosService.saveCollateralData({
        applicationId: applicationId,
        loanType: data.loanType,
        guaranteeDetails: guranteeDetails,
        collateralDetails: data.collateralDetails,
      })
      setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }

  async function _saveFinancialData(data: Partial<FinancialFormData>) {
    try {
      const {
        employeeDetails,
        paySlips,
        bankStatement,
        itr,
        auditedFinancials,
      } = data

      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )

      if (!applicationId) {
        throw new Error('Application ID is missing.')
      }

      const documentMappings = [
        {
          documentType: LosDocumentType.PAYSLIP_LAST_MONTH,
          file: paySlips?.firstMonth,
        },
        {
          documentType: LosDocumentType.PAYSLIP_SECOND_LAST_MONTH,
          file: paySlips?.secondMonth,
        },
        {
          documentType: LosDocumentType.PAYSLIP_THIRD_LAST_MONTH,
          file: paySlips?.thirdMonth,
        },
        { documentType: LosDocumentType.BANK_STATEMENT, file: bankStatement },
        {
          documentType: LosDocumentType.INCOME_TAX_RETURN_LAST_YEAR,
          file: itr?.firstYear,
        },
        {
          documentType: LosDocumentType.INCOME_TAX_RETURN_SECOND_LAST_YEAR,
          file: itr?.secondYear,
        },
        {
          documentType: LosDocumentType.AUDITED_FINANCIAL_REPORT_LAST_YEAR,
          file: auditedFinancials?.firstYear,
        },
        {
          documentType:
            LosDocumentType.AUDITED_FINANCIAL_REPORT_SECOND_LAST_YEAR,
          file: auditedFinancials?.secondYear,
        },
      ]

      var response
      for (const { documentType, file } of documentMappings) {
        if (file) {
          response = await _saveFinancialDataWithDocument({
            applicationId,
            employeeDetails,
            file,
            documentType,
          })
        }
      }
      if (response) {
        await LosService.updateNextStep(applicationId)
        setCustomerApplication(response)
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      console.error('Error saving financial data:', error)
      throw error
    }
  }

  async function _saveFinancialDataWithDocument(data: {
    applicationId: string
    employeeDetails?: string
    file: File
    documentType: LosDocumentType
  }) {
    try {
      const response = await LosService.saveFinancialData({
        applicationId: data.applicationId,
        employerDetail: data.employeeDetails,
        file: data.file,
        documentType: data.documentType,
      })
      return response
    } catch (error) {
      throw error
    }
  }

  async function _saveElectricityData(data: Partial<ElectricityFormData>) {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )
      const board = data.electricityBoard
      const billNumber = data.electricityBillNo
      const code = data.electricityCode
      if (!applicationId) {
        throw new Error('Application ID is missing.')
      }
      if (!board || !billNumber || !code) {
        throw new Error(
          `Board ${board} and Bill Number ${billNumber} and code ${code} are required `
        )
      }

      const documentMappings = [
        {
          documentType: LosDocumentType.ELECTRICITY_BILL_LAST_MONTH,
          file: data.bills?.firstMonth,
        },
        {
          documentType: LosDocumentType.ELECTRICITY_BILL_SECOND_LAST_MONTH,
          file: data.bills?.secondMonth,
        },
        {
          documentType: LosDocumentType.ELECTRICITY_BILL_THIRD_LAST_MONTH,
          file: data.bills?.thirdMonth,
        },
      ]

      var response
      for (const { documentType, file } of documentMappings) {
        if (file) {
          response = await _saveElectricityDataWithDocument({
            applicationId: applicationId,
            file: file,
            documentType: documentType,
            electricityBillNo: billNumber,
            electricityBoard: board,
            code: code,
          })
        }
      }
      if (response) {
        setCustomerApplication(response)
        LosService.submitApplication(applicationId)
          .then(res => {
            LosService.updateNextStep(applicationId)
              .then(res1 => {
                _navigateToNextStep()
              })
              .catch(err => {
                setSnackbar('Error in getting next step ', 'error')
              })
          })
          .catch(err => {
            setSnackbar('Error in submitting application', 'error')
          })
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }

  async function _saveElectricityDataWithDocument(data: {
    applicationId: string
    file: File
    documentType: LosDocumentType
    electricityBoard: string
    electricityBillNo: string
    code: string
  }) {
    try {
      const response = await LosService.saveElectrictyBillData({
        applicationId: data.applicationId,
        file: data.file,
        documentType: data.documentType,
        electricityBillNo: data.electricityBillNo,
        electricityBoard: data.electricityBoard,
        code: data.code,
      })
      return response
    } catch (error) {
      throw error
    }
  }

  async function _approveSanctionLetter() {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )

      if (!applicationId) {
        throw new Error('Application ID is missing.')
      }
      const response = await LosService.updateSanctionLetterStatusToAccepted(
        applicationId
      )
      setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }

  async function _saveSignedAgreementLetter(data: Partial<AgreementFormData>) {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )

      if (!applicationId) {
        throw new Error('Application ID is missing.')
      }
      const response = await LosService.saveSignedAgreement({
        applicationId: applicationId,
        agreement: data.signedDocument,
        documentType: LosDocumentType.SANCTION_LETTER,
      })
      setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }

  async function _saveBankDetails(data: Partial<BankFormData>) {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )
      if (!applicationId) throw new Error('Application ID is missing.')

      const name = data.accountHolderName
      const accNumber = data.accountNumber
      const code = data.isfcCode
      const branch = data.branch
      const bankName = data.bankName
      if (!name || !accNumber || !code || !branch || !bankName)
        throw new Error(
          `Name ${name} Account Number ${accNumber} IFCS Code ${code} branch ${branch} and Bank Name ${bankName} are required`
        )

      const response = await LosService.saveBankDetails({
        applicationId: applicationId,
        accountHolderName: name,
        accountNo: accNumber,
        ifscCode: code,
        branch: branch,
        cancelledCheque: data.cancelledCheque,
        documentType: LosDocumentType.CANCELLED_CHEQUE,
        bankName: bankName,
      })
      setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }

  async function _saveCompanyDetails(data: Partial<CompanyFormData>) {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )

      if (!applicationId) throw new Error('Application ID is missing.')

      const companyName = data.companyName
      const gstNumber = data.gstNo

      if (!companyName || !gstNumber) {
        throw new Error(
          `Company Name ${companyName} and GST Number ${gstNumber} are required`
        )
      }

      var response = await LosService.saveCompanyDetails({
        applicationId: applicationId,
        companyName: companyName,
        gstNo: gstNumber,
      })

      if (response) setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }
  async function _saveCinDetails(data: Partial<CompanyFormData>) {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )

      if (!applicationId) throw new Error('Application ID is missing.')

      const companyName = data.companyName
      const gstNumber = data.gstNo
      const cinNumber = data.cinNumber

      //TODO:

      // if (!companyName || !gstNumber || !cinNumber)
      //   throw new Error(
      //     `Company Name ${companyName} and GST Number ${gstNumber} are required and CIN Number ${cinNumber} are required`
      //   )
      var response = await LosService.saveCompanyDetails({
        applicationId: applicationId,
        companyName: companyName,
        gstNo: gstNumber,
        cinNo: cinNumber,
      })

      if (response) setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }
  async function _savePinDetails(data: Partial<CompanyFormData>) {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )

      if (!applicationId) throw new Error('Application ID is missing.')

      const companyName = data.companyName
      const gstNumber = data.gstNo
      const pinNumber = data.pinNumber

      if (!companyName || !gstNumber || !pinNumber)
        throw new Error(
          `Company Name ${companyName} and GST Number ${gstNumber} are required and PIN Number ${pinNumber} are required`
        )

      var response = await LosService.saveCompanyDetails({
        applicationId: applicationId,
        companyName: companyName,
        gstNo: gstNumber,
        partnershipIdentificationNo: pinNumber,
      })

      if (response) setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }

  async function _fetchApplicationState() {
    // const applicationId =
    //   LocalStorageUtility.getItem<string>(StorageKeys.APPLICATION_ID) ?? ''
    setLoading(true)
    const nextStep = await getNextStepFromBackend()

    console.log('Get next')

    // if (nextStep === LosStep.USER_KYC_COMPLETE) {
    //   console.log('KYC COMPLETE ')
    //   await LosService.updateNextStep(applicationId)
    //   nextStep = await getNextStepFromBackend()
    // }
    if (nextStep) {
      setCurrentLosStep(nextStep)
    }
    setLoading(false)
  }

  async function getJourney() {
    try {
      setLoading(true)
      const response = await LosService.getAllApplicationCategories()
      setJourneyStepMetaData(response)
      const names = response.map(item => item.name)
      setLosJourneySteps(names)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  function _markPromotersDetailsAsCompleted() {
    setArePromoterDetailsCompleted(true)
  }

  const getCurrentCategoryStepIndex = () => {
    if (!currentCategory) return MAX_STEP_NUMBER
    const index = losJourneySteps.indexOf(currentCategory)
    return index ?? MAX_STEP_NUMBER
  }

  async function fetchAllEnumValues() {
    try {
      const loanTypes = await getLoanTypes()
      setLoanTypes(loanTypes)
      const userTypes = await getUserTypes()
      setUserTypes(userTypes)
      const individualSubTypes = await getUserSubTypes(UserType.INDIVIDUAL)
      setIndividualSubTypes(individualSubTypes)
      const nonIndividualSubTypes = await getUserSubTypes(
        UserType.NON_INDIVIDUAL
      )
      setNonIndividualSubTypes(nonIndividualSubTypes)
      const relationTypes = await getRelationTypes()
      setRelationType(relationTypes)
    } catch (error) {
      throw error
    }
  }

  async function fetchCollateralTypes() {
    try {
      const userType = customerApplication?.basicInfo?.userType
      if (userType) {
        const response = await getCollateralTypes(userType)
        setCollateralType(response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function _updateApplicationEntityType(
    entityType: string
  ): Promise<void> {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )

      if (!applicationId) throw new Error('Application ID is missing.')

      const response = await LosService.updateApplicationEntityType({
        applicationId: applicationId,
        entityType: entityType,
      })
      setCustomerApplication(response)
    } catch (error) {
      console.error(error)
    }
  }

  async function _fetchAadharDetailsForAadhaarInfoId(
    infoId: string
  ): Promise<CustomerAadharData> {
    try {
      const response = await LosService.getAadhaarDetailsForInfoId(infoId)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async function getElectricityBoards() {
    try {
      const response = await LosService.getElectrictyBoardList()
      response.sort((a, b) => {
        const serviceProviderA = a.serviceProvider.toUpperCase()
        const serviceProviderB = b.serviceProvider.toUpperCase()

        if (serviceProviderA < serviceProviderB) {
          return -1
        }
        if (serviceProviderA > serviceProviderB) {
          return 1
        }
        return 0
      })
      setElectricityBoards(response)
    } catch (error) {
      console.error(error)
    }
  }

  const getCurrentStepMetaData = () => {
    var metaData: CurrentStepMetaData = {
      index: currentStepIndex,
      categoryName: _.startCase(_.toLower(currentCategory)),
      total: losJourneySteps.length,
      step: currentLosStep,
      description: 'Let us know about your loan requirement',
    }

    if (
      currentLosStep == LosStep.COMPANY_DETAIL &&
      arePromoterDetailsCompleted == false
    ) {
      metaData = {
        index: currentStepIndex - 1,
        categoryName: _.startCase(_.toLower(LosCategory.KYC_VERIFICATION)),
        total: losJourneySteps.length,
        step: currentLosStep,
      }
    }

    const filtered =
      journeyStepMetaData.filter(item => item.name == currentCategory) ?? []

    if (filtered.length > 0) {
      metaData.description = filtered[0].description
    }
    return metaData
  }

  async function _generateAadharOtp(data: {
    aadhaarNumber: string
  }): Promise<AadharVerificationOtpResponse> {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )

      if (!applicationId) throw new Error('Application ID is missing.')

      const response = await LosService.generateOtpForApplication({
        applicationId: applicationId,
        aadhaarNumber: data.aadhaarNumber,
      })
      return response
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }

  async function _verifyAadharOtp(data: { requestId: string; otp: string }) {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )

      if (!applicationId) throw new Error('Application ID is missing.')

      const response = await LosService.verifyAadhaarOtp({
        applicationId: applicationId,
        requestId: data.requestId,
        otp: data.otp,
      })
      setCustomerApplication(response)
      const message = response.message
      if (message != null && message == 'Verification Failed.') {
        return false
      }
      return true
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }

  async function _fetchLoanForApplication(
    applicationId: string
  ): Promise<Loan> {
    try {
      const response = await LoanManagementService.getLoanByApplicationId(
        applicationId
      )
      return response
    } catch (error) {
      throw error
    }
  }

  async function _fetchLoanEmiSchedule(
    loanId: string
  ): Promise<LoanEmiSchedule[]> {
    try {
      const response = await LoanManagementService.getEmiSchedule(loanId)
      return response
    } catch (error) {
      throw error
    }
  }

  async function _fetchLoanDispersalSchedule(
    loanId: string
  ): Promise<LoanDispersalSchedule[]> {
    try {
      const response = await LoanManagementService.getDispersalSchedule(loanId)
      return response
    } catch (error) {
      throw error
    }
  }

  async function _fetchLoanLedger(data: {
    loanId: string
    filters: Partial<GetLoanLedgerApiData>
  }): Promise<PaginatedResponse<LoanLedger>> {
    try {
      const response = await LoanManagementService.getLedger(data)
      return response
    } catch (error) {
      throw error
    }
  }

  async function _fetchCustomerApplication(
    applicationId: string
  ): Promise<CustomerApplication> {
    try {
      setLoadingCustomerApplication(true)
      const response = await LosService.getApplicationById(applicationId)
      LocalStorageUtility.setItem(StorageKeys.CUSTOMER_APPLICATION, response)
      setCustomerApplication(response)
      return response
    } catch (error) {
      throw error
    } finally {
      setLoadingCustomerApplication(false)
    }
  }

  useEffect(() => {
    const index = getCurrentCategoryStepIndex()
    setCurrentStepIndex(index)
  }, [currentCategory])

  useEffect(() => {
    if (customerApplication) {
      LocalStorageUtility.setItem<CustomerApplication>(
        StorageKeys.CUSTOMER_APPLICATION,
        customerApplication
      )
    }
    // fetchCollateralTypes();
  }, [customerApplication])

  useEffect(() => {
    getJourney()
    fetchAllEnumValues()
    getElectricityBoards()
  }, [])

  return (
    <LosProviderContext.Provider
      value={{
        loading: loading,
        loadingApplication: loadingCustomerApplication,
        loanTypes: loanTypes,
        userTypes: userTypes,
        individualSubTypes: individualSubTypes,
        nonIndividualSubTypes: nonIndividualSubTypes,
        collateralTypes: collateralType,
        relationTypes: relationTypes,
        onUserTypeChange: _onUserTypeChange,
        navigateToNextStep: _navigateToNextStep,
        electrictyBoards: electrictyBoards,
        currentStep: getCurrentStep(),
        journeySteps: losJourneySteps,
        currentUserType: customerApplication?.losEntityType,
        customerApplication: customerApplication,
        currentStepMetaData: getCurrentStepMetaData(),

        // save apis
        saveIndividualSelfie: _saveIndividualSelfie,
        saveApplicationFormData: _saveApplicationFormData,
        saveIndividualPanData: _saveIndividualPanData,
        saveFiancialData: _saveFinancialData,
        saveCollateralData: _saveCollateralData,
        saveElectricityData: _saveElectricityData,
        saveAgreement: _saveSignedAgreementLetter,
        saveBankDetails: _saveBankDetails,
        saveCompanyPanData: _saveCompanyPanData,
        savePromoter: _savePromoter,
        saveCompanyData: _saveCompanyDetails,
        saveCinData: _saveCinDetails,
        savePinData: _savePinDetails,

        // fetch apis
        fetchApplicationState: _fetchApplicationState,
        fetchPanDetailsForPanNumber: _fetchPanDetails,
        fetchGSTDetailsForGSTNumber: _fetchGSTDetails,
        fetchPanDetailsForPanInfoId: _fetchPanDetailsForPanInfoId,
        updatePanStatusToAccepted: _updatePanStatusToAccepted,
        updateCompanyPanStatusToAccepect: _updateCompanyPanStatusToAccepect,
        updateAadharStatusToAccepted: _updateAadharStatusToAccepted,
        updateApplicationEntityType: _updateApplicationEntityType,
        updateGstStatusToAccepted: _updateGSTStatusToAccepted,
        fetchAadharDetailsForAadharInfoId: _fetchAadharDetailsForAadhaarInfoId,
        fetchLoanForApplication: _fetchLoanForApplication,
        fetchLoanEmiSchedule: _fetchLoanEmiSchedule,
        fetchLoanDispersalSchedule: _fetchLoanDispersalSchedule,
        fetchLoanLedger: _fetchLoanLedger,
        fetchApplication: _fetchCustomerApplication,
        fetchCollateralTypes: fetchCollateralTypes,

        approveSanctionLetter: _approveSanctionLetter,

        markPromotersDetailsAsCompleted: _markPromotersDetailsAsCompleted,

        generateAadharOtp: _generateAadharOtp,
        verifyAadharOtp: _verifyAadharOtp,
        saveElectricityDataWithDocument: _saveElectricityDataWithDocument,
      }}
    >
      {children}
    </LosProviderContext.Provider>
  )
}

function getEnumValueFromString<T extends string>(
  enumObject: Record<string, T>,
  value: string
): T | undefined {
  return enumObject[value]
}

export type LosJourney = {
  name: string
  description: string
}

function objectHasValues(obj?: Partial<any>): boolean {
  if (!obj) return false
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined) {
      // Check if the value is a string and its length is greater than zero
      if (typeof obj[key] === 'string' && obj[key].length > 0) {
        return true
      }
    }
  }
  return false
}
