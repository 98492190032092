import { ColendingLoanOffersRoute } from '../../../containers/ColendingDashboardContainer';
import { ColendingLoan } from '../../onboarding/types/Los';
import { useNestedRouter } from '../provider/NestedRouterProvider';
import ColendingApplicationReview from './ColendingApplicationReview';
import ColendingLoanOffers from './ColendingLoanOffers';

function ColendingLoanOffersContainer() {
  const { currentRoute, currentRouteMetaData, navigateToRoute } =
    useNestedRouter();

  if (currentRoute == ColendingLoanOffersRoute.LoanOffers) {
    return <ColendingLoanOffers />;
  } else if (currentRoute == ColendingLoanOffersRoute.ReviewApplication) {
    var application;
    if (currentRouteMetaData?.APPLICATION) {
      application = currentRouteMetaData.APPLICATION as ColendingLoan;
    }

    return <ColendingApplicationReview colendingLoan={application} />;
  }

  navigateToRoute(ColendingLoanOffersRoute.LoanOffers);

  return <div>-</div>;
}

export default ColendingLoanOffersContainer;
