import { LocalOffer, NotificationsActive } from '@mui/icons-material';
import { useState } from 'react';
import SidebarLayout, {
  SidebarListItemType,
} from '../components/layouts/SidebarLayout';
import { SOLFIN_ROUTES } from '../routes';
import ColendingActiveLoans from '../solfen/Colending/pages/ColendingActiveLoans';
import ColendingLoanOffersContainer from '../solfen/Colending/pages/ColendingLoanOffersContainer';
import { ColendingLoansProvider } from '../solfen/Colending/provider/ColendingLoansProvider';
import { NestedRouterProvider } from '../solfen/Colending/provider/NestedRouterProvider';

export enum ColendingLoanOffersRoute {
  LoanOffers = 'LoanOffers',
  ReviewApplication = 'ReviewApplication',
}

function ColendingDashboardContainer() {
  const NAV_OPTIONS: SidebarListItemType[] = [
    {
      id: SOLFIN_ROUTES.COLENDING_OFFERS,
      icon: <LocalOffer />,
      label: 'Offers',
      component: (
        <NestedRouterProvider
          routes={[
            ColendingLoanOffersRoute.LoanOffers,
            ColendingLoanOffersRoute.ReviewApplication,
          ]}
          initialRoute={ColendingLoanOffersRoute.LoanOffers}
        >
          <ColendingLoanOffersContainer />
        </NestedRouterProvider>
      ),
    },
    {
      id: SOLFIN_ROUTES.COLENDING_ACTIVE_LOANS,
      icon: <NotificationsActive />,
      label: 'Active Loans',
      component: (
        <ColendingLoansProvider>
          <ColendingActiveLoans />
        </ColendingLoansProvider>
      ),
    },
  ];
  const [selectedItem, setSelectedItem] = useState<SidebarListItemType | null>(
    NAV_OPTIONS[0]
  );
  return (
    <div>
      <SidebarLayout
        sidebarListItems={NAV_OPTIONS}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    </div>
  );
}

export default ColendingDashboardContainer;
