import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { LoanManagementService } from '../../LMSHQ/api/LoanManagementService';
import { LoanLedger } from '../../LMSHQ/types/Lms';
import LosDataGrid from '../LosDataGrid';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  loanId?: string;
};

function LoanLedgerDataGrid({ loanId }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [ledger, setLedger] = useState<LoanLedger[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  async function fetchDetails(loanId: string) {
    try {
      setLoading(true);
      const response = await LoanManagementService.getLedger({
        loanId: loanId,
        filters: {
          page: page,
          pageSize: pageSize,
        },
      });
      setLedger(response.records);
      setPage(response.pageNumber);
      setRowCount(response.totalItems);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (loanId) {
      fetchDetails(loanId);
    }
  }, [loanId]);

  return (
    <LosDataGrid
      data={ledger}
      columns={COLUMN_DEF}
      idColumn={'id'}
      page={page}
      rowCount={rowCount}
      onPageChange={setPage}
      loading={loading}
    />
  );
}

export default LoanLedgerDataGrid;

const COLUMN_DEF: GridColumns<LoanLedger> = [
  {
    field: 'paymentDate',
    headerName: 'Payment Date',
    renderCell: (props: GridRenderCellParams) => {
      const row = props.row as LoanLedger;
      var displayText = '-';
      if (row.created_at) {
        displayText = dayjs(row.created_at).format('DD/MM/YYYY');
      }
      return displayText;
    },
    flex: 1,
  },
  {
    field: 'loan_ledger_category',
    headerName: 'Transaction Category',
    renderCell: (props: GridRenderCellParams) => {
      const ledger = props.row as LoanLedger;
      if (ledger.loan_ledger_category) {
        return _.startCase(_.toLower(ledger.loan_ledger_category));
      }
      return '-';
    },
    flex: 1,
  },
  {
    field: 'debit',
    headerName: 'Debit',
    renderCell: (props: GridRenderCellParams) => {
      return getDebitValue(props.row as LoanLedger);
    },
    flex: 1,
  },
  {
    field: 'credit',
    headerName: 'Credit',
    renderCell: (props: GridRenderCellParams) => {
      return getCreditValue(props.row as LoanLedger);
    },
    flex: 1,
  },
];

const getDebitValue = (ledger: LoanLedger) => {
  if (ledger.type == 'DEBIT') {
    return `${ledger.amount}`;
  }
  return '-';
};
const getCreditValue = (ledger: LoanLedger) => {
  if (ledger.type == 'CREDIT') {
    return `${ledger.amount}`;
  }
  return '-';
};
