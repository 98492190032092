import { Card, Grid, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { LosService } from '../api/LosService'
import { useLos } from '../providers/LosProvider'
import { CompanyDetailsResponse } from '../types/Los'
import LosText from './common/LosText'

type Props = {
  fullWidth?: boolean
}
export const GSTDetailsCard = ({ fullWidth = false }: Props) => {
  const { customerApplication } = useLos()
  const [parseGstData, setParseGstData] =
    useState<CompanyDetailsResponse | null>(null)

  const fetchGstDetails = async () => {
    if (customerApplication?.id) {
      const companyDetails = await LosService.getCompanyDetails(
        customerApplication.id
      )
      setParseGstData(companyDetails)
    }
  }

  useEffect(() => {
    fetchGstDetails()
  }, [])

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '0.62rem',
        width: '100% !important',
        borderRadius: '0.875rem',
        paddingTop: '0.5rem',
        paddingBottom: '0.75rem',
        background: 'white',
        boxShadow: '0px 0px 25px 0px rgba(0, 0, 0, 0.15)',
        ...(fullWidth == false && { width: '20rem' }),
      }}
    >
      <Stack
        direction={'row'}
        alignItems='center'
        justifyContent={'space-between'}
        style={{
          height: '4.2rem',
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
          borderBottom: '1px solid rgba(43, 123, 255, 0.5)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem',
          }}
        >
          <div
            style={{
              height: '48px',
              width: '3.1238rem',
            }}
          >
            <img
              src={require('../assets/GST_logo.png')}
              height={'100%'}
              width={'100%'}
            ></img>
          </div>
          <div style={{}}>
            <LosText
              text='GOOD AND SERVICES TAX'
              variant='body1'
              fontSize={'0.8125rem'}
              fontWeight={'bold'}
              lineHeight={'130%'}
            />
          </div>
        </div>
      </Stack>
      <Stack
        direction={'row'}
        style={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <PanLabel
          label='Legal Name'
          description={parseGstData?.companyName ?? '-'}
        />
      </Stack>

      <Grid
        container
        direction={'row'}
        justifyContent='start'
        style={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <Grid item xs={6}>
          <PanLabel label='GST No' description={parseGstData?.gstNo ?? '-'} />
        </Grid>
        <Grid item xs={6}>
          <PanLabel
            label='GST Status'
            description={parseGstData?.gstMeta.gstinStatus ?? '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <PanLabel
            label='Company Type'
            description={parseGstData?.gstMeta?.constitutionOfBusiness ?? '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <PanLabel
            label='Trade of Business'
            description={parseGstData?.gstMeta?.tradeNameOfBusiness ?? '-'}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

console.log('🚀 -----------------------------------🚀')
console.log('🚀 ~ GSTDetailsCard:', GSTDetailsCard)
console.log('🚀 -----------------------------------🚀')

const PanLabel = (data: { label: string; description: string }) => {
  return (
    <Stack spacing={'0px'}>
      <LosText text={data.label} variant='caption' />
      <LosText text={data.description} fontSize={'10px'} />
    </Stack>
  )
}
