import { Grid, Stack } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import VegaCheckBoxV2 from '../../../../../components/common/v2/VegaCheckBoxV2'
import LosFormInputField from '../../../components/common/LosFormInputField'
import LosSelect, {
  LosSelectOption,
} from '../../../components/common/LosSelect'
import LosText from '../../../components/common/LosText'
import LosTextField from '../../../components/common/LosTextField'
import LosAppBar from '../../../components/LosAppBar'
import { LosFormButtonView } from '../../../components/LosFormButton'
import LosFormContainer from '../../../components/LosFormContainer'
import { PanDetailsCard } from '../../../components/PanDetailsCard'
import VerifyPanDetailsDialog from '../../../components/VerifyPanDetailsDialog'
import {
  ApplicationPanInfo,
  CustomerApplication,
  CustomerPanData,
  LosStep,
  UserPanStatus,
  UserType,
} from '../../../types/Los'
import { PanEntityType, StringUtility } from '../../../utils/StringUtility'
import { useLos } from '../../../providers/LosProvider'
import VerificationSuccessDialog from '../../../components/VerificationSuccessDialog'
import { useLosAuth } from '../../../providers/LosAuthProvider'
import { LosService } from '../../../api/LosService'
import { LocalStorageUtility, StorageKeys } from '../../../utils/StorageUtility'

type FormData = {
  name: string
  designation: string
  panNumber: string
  aadharNumber: string
  panVerified: boolean
  aadharVerified: boolean
  emailId: string
  mobileNumber: string
}

enum InputType {
  Name,
  Designation,
  Pan,
  AADHAR_NUMBER,
  AADHAR_VERFICATION,
  PAN_VERIFICATION,
  EMAIL_ID,
  MOBILE_NUMBER,
}

function AddPromoter() {
  const {
    savePromoter,
    navigateToNextStep,
    currentStep,
    currentStepMetaData,
    customerApplication,
    fetchPanDetailsForPanInfoId,
    fetchApplication,
    updatePanStatusToAccepted,
  } = useLos()
  const { isAuthenticated } = useLosAuth()

  const [formData, setFormData] = useState<FormData>({
    name: '',
    designation: '',
    panNumber: '',
    aadharNumber: '',
    panVerified: false,
    aadharVerified: false,
    emailId: '',
    mobileNumber: '',
  })
  const [errors, setErrors] = useState<Partial<FormData>>({})
  const [tncAccepted, setTncAccepted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [openPanVerificationDialog, setOpenPanVerificationDialog] =
    useState<boolean>(false)
  const [companyPanDetails, setCompanyPanDetails] =
    useState<ApplicationPanInfo>()

  const [openPanVerifiedDialog, setOpenPanVerifiedDialog] =
    useState<boolean>(false)

  const isInputValid = () => {
    const isNameValid = formData.name?.length > 0
    const isDesignationValid = formData.designation?.length > 0
    const isPanValid = formData.panNumber?.length > 0

    return (
      isNameValid &&
      isDesignationValid &&
      isPanValid &&
      isEmailInputValid() &&
      isMobileNumberVlaid()
    )
  }

  const isPanNumberInputValid = () => {
    if (!!errors.panNumber) return false

    return formData.panNumber?.length > 0
  }

  const isEmailInputValid = () => {
    return StringUtility.validateEmail(formData.emailId)
  }

  const isMobileNumberVlaid = () => {
    return StringUtility.validatePhoneNumber(formData.mobileNumber)
  }

  function handleStatusUpdate(type: InputType, status: boolean) {
    switch (type) {
      case InputType.PAN_VERIFICATION:
        setFormData(prev => {
          return {
            ...prev,
            panVerified: status,
          }
        })
        break
      case InputType.AADHAR_VERFICATION:
        setFormData(prev => {
          return {
            ...prev,
            aadharVerified: status,
          }
        })
        break
    }
  }

  function handleUpdate(type: InputType, value: string) {
    const updatedErrors: Partial<FormData> = { ...errors }
    switch (type) {
      case InputType.Name:
        if (value.length <= 0) {
          updatedErrors.name = 'Name is required'
        } else {
          delete updatedErrors.name
        }
        setFormData(prev => {
          return {
            ...prev,
            name: value,
          }
        })
        break
      case InputType.Designation:
        if (value.length <= 0) {
          updatedErrors.designation = 'Designation is required'
        } else {
          delete updatedErrors.designation
        }
        setFormData(prev => {
          return {
            ...prev,
            designation: value,
          }
        })
        break
      case InputType.MOBILE_NUMBER:
        if (!StringUtility.validatePhoneNumber(value)) {
          updatedErrors.mobileNumber = 'Enter a Valid Mobile Number'
        } else {
          delete updatedErrors.mobileNumber
        }
        setFormData(prev => {
          return {
            ...prev,
            mobileNumber: value,
          }
        })
        break
      case InputType.EMAIL_ID:
        if (!StringUtility.validateEmail(value)) {
          updatedErrors.emailId = 'Enter a Valid Email'
        } else {
          delete updatedErrors.emailId
        }
        setFormData(prev => {
          return {
            ...prev,
            emailId: value,
          }
        })
        break
      case InputType.Pan:
        if (value.length <= 0) {
          updatedErrors.panNumber = `Valid ${_.startCase(
            _.toLower(UserType.INDIVIDUAL)
          )} Pan Number is required`
        } else {
          const isValidPan = StringUtility.validatePANNumber(
            PanEntityType.Person,
            value
          )
          if (isValidPan == false) {
            updatedErrors.panNumber = `Valid ${_.startCase(
              _.toLower(UserType.INDIVIDUAL)
            )} Pan Number is required`
          } else {
            delete updatedErrors.panNumber
          }
        }
        setFormData(prev => {
          return {
            ...prev,
            panNumber: value,
          }
        })
        break
      case InputType.AADHAR_NUMBER:
        if (value.length <= 0) {
          updatedErrors.aadharNumber = 'Aadhaar number is required'
        } else {
          const isAadhaarValid = StringUtility.validateAadharNumber(value)
          if (isAadhaarValid == false) {
            updatedErrors.aadharNumber = 'Valid Aadhaar number is required'
          } else {
            delete updatedErrors.aadharNumber
          }
        }
        setFormData(prev => {
          return {
            ...prev,
            aadharNumber: value,
          }
        })
        break
    }
    setErrors(updatedErrors)
  }
  async function onVerifyPanClick() {
    try {
      const customerApplication =
        LocalStorageUtility.getItem<CustomerApplication>(
          StorageKeys.CUSTOMER_APPLICATION
        )
      const currentIndex = customerApplication?.currentUserIndex ?? 0
      const userId = customerApplication?.users[currentIndex] ?? ''

      setLoading(true)
      await savePromoter({
        name: formData.name,
        designation: formData.designation,
        panNumber: formData.panNumber,
        userId: userId,
        mobileNumber: formData.mobileNumber,
        emailId: formData.emailId,
      })
      navigateToNextStep()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  async function onConfirmPanClick() {
    try {
      setLoading(true)
      const response = await updatePanStatusToAccepted()
      handleStatusUpdate(InputType.PAN_VERIFICATION, true)
      setOpenPanVerifiedDialog(true)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  function onPanVerificationSuccessClick() {
    navigateToNextStep()
  }

  async function fetchCompanyPanDetails() {
    try {
      const customerApplication =
        LocalStorageUtility.getItem<CustomerApplication>(
          StorageKeys.CUSTOMER_APPLICATION
        )
      const companyPanId = customerApplication?.companyDetails.panId

      console.log(companyPanId)

      if (companyPanId) {
        // const response = await fetchPanDetailsForPanInfoId(companyPanId)
        const response = await LosService.getCompanyPanDetailsByPanId(
          companyPanId
        )
        setCompanyPanDetails(response)
      }
      setLoading(false)
    } catch (error) {}
  }

  function onTncClick() {}

  function runAfterDelay(callback: () => void, delay: number) {
    setTimeout(callback, delay)
  }

  async function handlePanAcceptedState() {
    const curentIndex = customerApplication?.currentUserIndex ?? 0
    const userId = customerApplication?.users[curentIndex] ?? ''
    // if (userId.length > 0) {
    // const lastMember = member[member.length - 1]
    // const response = await fetchPanDetailsForPanInfoId(lastMember.panInfoId)
    const response = await LosService.getPanDetailsByUserId(userId)
    setFormData(prev => {
      return {
        ...prev,
        name: response.panName,
        panNumber: response.panNumber,
        // designation: lastMember.basicInfo.designation ?? '',
      }
    })
    setOpenPanVerificationDialog(true)
    // }
    setLoading(false)
  }

  const getPromoterNumber = () => {
    const curentIndex = customerApplication?.currentUserIndex ?? 0
    // const members = customerApplication?.members ?? []
    // const length = members.length

    // if (length == 1) {
    //   const firstMember = members[0]
    //   const firstMemberHasPanInfoId = (firstMember.panInfoId ?? '').length > 0
    //   return firstMemberHasPanInfoId ? 2 : 1
    // }

    // return length + 1
    return curentIndex + 1
  }

  const fetchApplicationOnLoad = () => {
    const customerApplicationId = LocalStorageUtility.getItem<string>(
      StorageKeys.APPLICATION_ID
    )
    customerApplicationId && fetchApplication(customerApplicationId)
  }
  useEffect(() => {
    if (currentStep === LosStep.PAN_ACCEPTED && isAuthenticated) {
      setLoading(true)
      runAfterDelay(handlePanAcceptedState, 500)
    }
  }, [currentStep, customerApplication, isAuthenticated])

  useEffect(() => {
    console.log('fetchCompanyPanDetails')
    fetchCompanyPanDetails()
    fetchApplicationOnLoad()
  }, [])

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <SubmitPanDetailsButtonView
            onClick={onVerifyPanClick}
            onTncChange={setTncAccepted}
            isInputValid={isInputValid()}
            isTncChecked={tncAccepted}
            onTncClick={onTncClick}
          />
        )
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        )
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <LosFormInputField label='Company Pan'>
            <PanDetailsCard
              name={companyPanDetails?.panName ?? '-'}
              panNumber={companyPanDetails?.panNumber ?? '-'}
              fullWidth
              status={
                companyPanDetails?.valid === true
                  ? UserPanStatus.APPROVED
                  : UserPanStatus.REJECTED
              }
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label={`Promoter ${getPromoterNumber()} Details`}>
            <Grid container rowSpacing={'1rem'}>
              <Grid item xs={12}>
                <LosFormInputField label='Name'>
                  <LosTextField
                    value={formData.name}
                    onChange={e => handleUpdate(InputType.Name, e.target.value)}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </LosFormInputField>
              </Grid>
              <Grid item xs={12}>
                <LosFormInputField label='Designation'>
                  <LosSelect
                    value={formData.designation}
                    onChange={e =>
                      handleUpdate(
                        InputType.Designation,
                        e.target.value as string
                      )
                    }
                    options={designationOptions()}
                  />
                </LosFormInputField>
              </Grid>
              <Grid item xs={12}>
                <LosFormInputField label='Pan No'>
                  <LosTextField
                    value={formData.panNumber}
                    onChange={e =>
                      handleUpdate(InputType.Pan, e.target.value.toUpperCase())
                    }
                    error={!!errors.panNumber}
                    helperText={errors.panNumber}
                    isValid={isPanNumberInputValid()}
                  />
                </LosFormInputField>
              </Grid>
              <Grid item xs={12}>
                <LosFormInputField label='Email ID'>
                  <LosTextField
                    value={formData.emailId}
                    onChange={e =>
                      handleUpdate(InputType.EMAIL_ID, e.target.value)
                    }
                    error={!!errors.emailId}
                    helperText={errors.emailId}
                    isValid={isEmailInputValid()}
                  />
                </LosFormInputField>
              </Grid>
              <Grid item xs={12}>
                <LosFormInputField label='Mobile Number'>
                  <LosTextField
                    value={formData.mobileNumber}
                    onChange={e =>
                      handleUpdate(InputType.MOBILE_NUMBER, e.target.value)
                    }
                    error={!!errors.mobileNumber}
                    helperText={errors.mobileNumber}
                    isValid={isMobileNumberVlaid()}
                  />
                </LosFormInputField>
              </Grid>
            </Grid>
          </LosFormInputField>
        </Grid>
      </Grid>
      <VerifyPanDetailsDialog
        open={openPanVerificationDialog}
        onClose={function (): void {
          setOpenPanVerificationDialog(false)
        }}
        onSubmit={function (): void {
          onConfirmPanClick()
        }}
        onTncClick={onTncClick}
        panNumber={formData.panNumber}
      />

      <VerificationSuccessDialog
        open={openPanVerifiedDialog}
        onClose={function (): void {
          setOpenPanVerifiedDialog(false)
        }}
        onSubmit={onPanVerificationSuccessClick}
        title={'Verify PAN'}
        description={'Your Pan card has been verified'}
        buttonText={'Proceed'}
      />
    </LosFormContainer>
  )
}

export default AddPromoter

const SubmitPanDetailsButtonView = (data: {
  onClick: () => void
  onTncChange: (checked: boolean) => void
  isInputValid: boolean
  isTncChecked: boolean
  onTncClick: () => void
}) => {
  return (
    <LosFormButtonView
      onClick={data.onClick}
      disabled={data.isInputValid == false}
      text='Verify PAN'
      renderHelperView={() => {
        return (
          <Stack>
            <Stack direction={'row'} spacing='1' alignItems={'start'}>
              <VegaCheckBoxV2
                sx={{
                  '&.MuiButtonBase-root': {
                    paddingTop: '0px !important',
                  },
                  color: 'blue',
                }}
                checked={data.isTncChecked}
                onChange={(e, checked) => data.onTncChange(checked)}
              />
              <LosText style={{ fontSize: '0.75rem', lineHeight: '130%' }}>
                I give{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Dhanvikas Fiscal Services Private Limited
                </span>{' '}
                the consent to save and verify my KYC details and agree to the{' '}
                <span
                  onClick={() => {
                    window.open(
                      'https://solfin.co.in/Termsandconditions',
                      '__blank'
                    )
                  }}
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  Terms and Conditions
                </span>{' '}
                and{' '}
                <span
                  onClick={() => {
                    window.open('https://solfin.co.in/Privacypolicy', '__blank')
                  }}
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  Privacy Policy
                </span>{' '}
              </LosText>
            </Stack>
          </Stack>
        )
      }}
    />
  )
}

enum DesignationType {
  MANAGING_DIRECTOR = 'MANAGING_DIRECTOR',
  WHOLE_TIME_DIRECTOR = 'WHOLE_TIME_DIRECTOR',
  OWNER = 'OWNER',
}

const designationOptions = () =>
  Object.values(DesignationType).map(designation => {
    const option: LosSelectOption = {
      value: designation,
      label: _.startCase(_.toLower(designation)),
    }
    return option
  })
