import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { BORDER_GRAY } from '../../../../../../constants/style'
import { getApplicationsDetails } from '../../../../../../store/common/agentApplicationsSlice'
import { useAppSelector } from '../../../../../../store/hook'
import {
  fetchAadharDetails,
  fetchPANDetails,
} from '../../../../../APIs/LMS_Agent/applications'
import {
  IAadharDetailsProps,
  IPanDetailsProps,
} from '../../../../../types/application'
import KYCDetailsAadharContainer from './KYCDetailsAadharContainer'
import KYCDetailsPanContainer from './KYCDetailsPanContainer'
import SelfieDetailsContainer from './SelfieDetailsContainer'
import {
  LocalStorageUtility,
  StorageKeys,
} from '../../../../../onboarding/utils/StorageUtility'
import { LosService } from '../../../../../onboarding/api/LosService'
import { UserDataDto } from '../../../../../onboarding/types/Los'

export interface IDetailsProps {
  img?: string
  key: string
  value: string
}

const PAN = {
  id: '',
  applicationId: '',
  customerId: '',
  name: '',
  number: '',
  typeOfHolder: '',
  status: null,
  firstName: '',
  middleName: '',
  lastName: '',
  title: '',
  panStatus: '',
  panStatusCode: '',
  aadhaarSeedingStatus: '',
  aadhaarSeedingStatusCode: '',
  lastUpdatedOn: '',
  createdAt: '',
  updatedAt: '',
  valid: true,
  individual: true,
}

interface IProps {}

const KYCDetails = ({}: IProps) => {
  const { applicationDetails } = useAppSelector(getApplicationsDetails)

  const [loadingPan, setLoadingPan] = useState<boolean>(false)
  const [panDetails, setPanDetails] = useState<IPanDetailsProps>({ ...PAN })
  const [userDetails, setUserDetails] = useState<UserDataDto>()

  const getUserDetailsApi = async () => {
    try {
      setLoadingPan(true)
      const response = await LosService.getUserDetails(applicationDetails.id)
      setUserDetails(response)
    } catch (error) {
    } finally {
      setLoadingPan(false)
    }
  }

  // useEffect(() => {
  //   setLoadingPan(true)
  //   const panInfo = setTimeout(() => {
  //     getPanDetails()
  //     getUserDetailsApi()
  //   }, 500)
  //   return () => {
  //     clearTimeout(panInfo)
  //   }
  // }, [])

  useEffect(() => {
    getUserDetailsApi()
  }, [])

  return (
    <>
      <Box sx={{ borderBottom: BORDER_GRAY }}>
        <KYCDetailsPanContainer
          data={userDetails ? userDetails?.userPanData?.[0].panMetadata : {}}
          loading={loadingPan}
        />
      </Box>
      <Box mt={1} sx={{ borderBottom: BORDER_GRAY }}>
        <KYCDetailsAadharContainer
          header='Aadhaar Details'
          aadhaarId={
            userDetails?.userAadharData?.[0]?.aadharMetaData?.uid ?? ''
          }
          aadhaarInfo={
            userDetails ? userDetails?.userAadharData?.[0].aadharMetaData : {}
          }
          isLoading={loadingPan}
        />
      </Box>
      <Box mt={1}>
        <SelfieDetailsContainer
          header='Selfie'
          applicationId={userDetails?.userSelfieData?.[0]?.selfieMetaData?.id}
          selfieData={
            userDetails ? userDetails?.userSelfieData?.[0].selfieMetaData : {}
          }
          isLoading={loadingPan}
        />
      </Box>
    </>
  )
}

export default KYCDetails
