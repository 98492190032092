import axios from 'axios';
import { BASE_URL } from '../../../api/endpoints';
import { PaginatedResponse } from '../../../utils/api';
import { AddTrancheFormData } from '../../onboarding/types/Los';
import {
  CustomLoanDto,
  Loan,
  LoanDispersalSchedule,
  LoanEmiSchedule,
  LoanLedger,
} from '../types/Lms';
import { PaginatedApiData } from './LeadService';

const SolfinLmsEndpoint = {
  BASE: '/installment/v1/installment',
  GET_ALL_LOANS: '/list/program/{programId}',
  GET_ALL_LOANS_CUSTOM: '/custom/list/program/{programId}',
  GET_DISPERSAL_SCHEDULE_FOR_LOAN: '/loan/{loanId}/dispersalSchedule',
  GET_LEDGER_FOR_LOAN: '/{loanId}/ledger',
  GET_EMI_SCHEDULE_FOR_LOAN: '/{loanId}/schedule',
  DISBURSE_TRENCH: '/disperse/dispersalSchedule/{dispersalScheduleId}',
  GET_DETAILED_LOAN: '/detail/loan/{loanId}',
  GET_LOAN_BY_APPLICATION_ID: '/application/{applicationId}',
  CREATE_REPAYMENT: '/repayment/loan',
  GET_SANCTION_LETTER: '/sanctionLetter',
  GET_AGREEMENT_LETTER: '/agreementLetter',
  UPDATE_FIRST_EMI_DATE: '/loan/{loanId}/firstEmiDate',
  CREATE_DISBURSAL_SCHEDULES: '/loan/{loanId}/dispersalSchedule',
};

const LMS_BASE_URL = BASE_URL + SolfinLmsEndpoint.BASE;

export class LoanManagementService {
  static async getAllLoans(
    programId: string,
    filters: Partial<GetLoansApiData>
  ): Promise<PaginatedResponse<CustomLoanDto>> {
    try {
      const endpoint =
        LMS_BASE_URL +
        SolfinLmsEndpoint.GET_ALL_LOANS_CUSTOM.replace(
          '{programId}',
          programId!
        );
      const response = await axios.get(endpoint, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getDetailedLoan(loanId: string): Promise<Loan> {
    try {
      const endpoint =
        LMS_BASE_URL +
        SolfinLmsEndpoint.GET_DETAILED_LOAN.replace('{loanId}', loanId);
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getDispersalSchedule(
    loanId: string
  ): Promise<LoanDispersalSchedule[]> {
    try {
      const endpoint =
        LMS_BASE_URL +
        SolfinLmsEndpoint.GET_DISPERSAL_SCHEDULE_FOR_LOAN.replace(
          '{loanId}',
          loanId
        );
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async getLedger(data: {
    loanId: string;
    filters: Partial<GetLoanLedgerApiData>;
  }): Promise<PaginatedResponse<LoanLedger>> {
    try {
      const endpoint =
        LMS_BASE_URL +
        SolfinLmsEndpoint.GET_LEDGER_FOR_LOAN.replace('{loanId}', data.loanId);
      const response = await axios.get(endpoint, { params: data.filters });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async getEmiSchedule(loanId: string): Promise<LoanEmiSchedule[]> {
    try {
      const endpoint =
        LMS_BASE_URL +
        SolfinLmsEndpoint.GET_EMI_SCHEDULE_FOR_LOAN.replace('{loanId}', loanId);
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async disburseLoanSchedule(data: {
    scheduleId: string;
    utrNumber: string;
    date: string;
  }): Promise<LoanDispersalSchedule> {
    try {
      const endpoint =
        LMS_BASE_URL +
        SolfinLmsEndpoint.DISBURSE_TRENCH.replace(
          '{dispersalScheduleId}',
          data.scheduleId
        );
      const response = await axios.put(
        endpoint,
        {},
        {
          params: {
            date: data.date,
            utr: data.utrNumber,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getLoanByApplicationId(applicationId: string): Promise<Loan> {
    try {
      const endpoint =
        LMS_BASE_URL +
        SolfinLmsEndpoint.GET_LOAN_BY_APPLICATION_ID.replace(
          '{applicationId}',
          applicationId
        );
   
      const response = await axios.get(endpoint);

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async createRepayment(data: Partial<CreateRepaymentApiData>) {
    try {
      const endpoint = LMS_BASE_URL + SolfinLmsEndpoint.CREATE_REPAYMENT;
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getSanctionLetter(applicationId: string) {
    try {
      const endpoint = LMS_BASE_URL + SolfinLmsEndpoint.GET_SANCTION_LETTER;
      const response = await axios.get(endpoint, {
        params: { applicationId: applicationId },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getAgreementLetter(applicationId: string) {
    try {
      const endpoint = LMS_BASE_URL + SolfinLmsEndpoint.GET_AGREEMENT_LETTER;
      const response = await axios.get(endpoint, {
        params: { applicationId: applicationId },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateLoanFirstEmiDate(data: {
    loanId: string;
    firstEmiDate: string;
  }) {
    try {
      const endpoint =
        LMS_BASE_URL +
        SolfinLmsEndpoint.UPDATE_FIRST_EMI_DATE.replace(
          '{loanId}',
          data.loanId
        );
      const response = await axios.put(endpoint, null, {
        params: { firstEmiDate: data.firstEmiDate },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async createDisbursalSchedules(
    data: Partial<CreateDisbursalSchedulesApiData>
  ) {
    try {
      const loanId = data.loanId;
      if (!loanId) throw new Error('Loan id not specified');
      const endpoint =
        LMS_BASE_URL +
        SolfinLmsEndpoint.CREATE_DISBURSAL_SCHEDULES.replace(
          '{loanId}',
          loanId
        );
      const response = await axios.post(endpoint, data.schedules);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export type CreateDisbursalSchedulesApiData = {
  loanId: string;
  schedules: Partial<AddTrancheFormData>[];
};

export type CreateRepaymentApiData = {
  loanId: string;
  currency: string;
  type: string;
  amount: number;
  dateTime: string;
  utr: string;
};

export interface GetLoanLedgerApiData extends PaginatedApiData {}

export interface GetLoansApiData extends PaginatedApiData {
  status: string;
}
