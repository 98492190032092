import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { GREEN, GREY, PRIMARY, RED } from '../../constants/style';
import { Box } from '@mui/system';
import _ from 'lodash';
import { CircularProgress, SxProps, Theme } from '@mui/material';

interface IButtonProps {
  bgColor?: string;
  className?: string;
  sx?: SxProps<Theme>;
  mx?: string | number;
  py?: string | number;
  px?: string | number;
  text: any;
  isPrimary?: boolean;
  isDanger?: boolean;
  isPurple?: boolean;
  isSuccess?: boolean;
  loading?: boolean;
  startIcon?: JSX.Element | string;
  endIcon?: JSX.Element;
  fullWidth?: boolean;
  onClick?: (e: any) => void;
  disabled?: boolean;
}

const VegaOutlineButton = ({
  bgColor,
  className,
  text,
  mx,
  py,
  px,
  startIcon,
  endIcon,
  isDanger,
  isPrimary,
  isPurple,
  isSuccess,
  loading,
  fullWidth,
  onClick,
  disabled,
  sx,
}: IButtonProps) => {
  return (
    <>
      <LoadingButton
        variant="outlined"
        loading={loading}
        loadingPosition="start"
        // loadingIndicator={<CircularProgress size={15}/>}
        startIcon={startIcon ?? ''}
        disabled={disabled}
        sx={{
          width: fullWidth ? '100%' : 'auto',
          textTransform: 'capitalize',

          mx: mx ?? 1,
          py: py ?? 0.5,
          px: px ?? 2,
          borderRadius: '5px',
          ...(isPrimary
            ? {
                bgcolor: 'transparent',
                borderColor: PRIMARY.light,
                color: PRIMARY.light,
                ':hover': {
                  bgcolor: 'transparent',
                  borderColor: PRIMARY.light,
                  color: PRIMARY.light,
                },
                '&.Mui-disabled': {
                  color: PRIMARY.light,
                  bgcolor: PRIMARY.lighter,
                },
              }
            : isDanger
            ? {
                bgcolor: 'transparent',
                borderColor: RED.dark,
                color: RED.dark,
                ':hover': {
                  bgcolor: 'transparent',
                  borderColor: RED.dark,
                  color: RED.dark,
                },
                '&.Mui-disabled': {
                  color: RED.dark,
                  bgcolor: RED.light,
                },
              }
            : isSuccess
            ? {
                bgcolor: 'transparent',
                borderColor: GREEN.dark,
                color: GREEN.dark,
                ':hover': {
                  bgcolor: 'transparent',
                  borderColor: GREEN.dark,
                  color: GREEN.dark,
                },
                '&.Mui-disabled': {
                  color: GREEN.dark,
                  bgcolor: GREEN.light,
                },
              }
            : isPurple
            ? {
                bgcolor: 'transparent',
                borderColor: PRIMARY.darkBlue,
                color: PRIMARY.darkBlue,
                ':hover': {
                  bgcolor: 'transparent',
                  borderColor: PRIMARY.darkBlue,
                  color: PRIMARY.darkBlue,
                },
                '&.Mui-disabled': {
                  color: PRIMARY.darkBlue,
                  bgcolor: PRIMARY.lighter,
                },
              }
            : {
                bgcolor: 'transparent',
                borderColor: GREY.grey,
                color: 'black',
                ':hover': {
                  bgcolor: 'transparent',
                  borderColor: GREY.grey,
                  color: 'black',
                },
                '&.Mui-disabled': {
                  color: 'black',
                  bgcolor: '#8080807d',
                },
              }),
          ...sx,
        }}
        onClick={onClick}
      >
        <Box
          component={'span'}
          className={className}
          sx={{
            ...(loading && { ml: 2 }),
            lineHeight: '19.6px',
            letterSpacing: '0.5px',
          }}
        >
          {_.startCase(_.toLower(text))}
        </Box>
      </LoadingButton>
    </>
  );
};

export default VegaOutlineButton;
