import { useEffect, useState } from 'react'
import { Box, Typography, Grid, Skeleton } from '@mui/material'
import { GREEN, RED } from '../../../../../components/colors/fontColor'
import { BLUE } from '../../../../../../constants/style'
import { Person } from '@mui/icons-material'
import { LosService } from '../../../../../onboarding/api/LosService'
import {
  DocumentStatus,
  LosDocument,
  LosDocumentType,
  SelfieMetaData,
} from '../../../../../onboarding/types/Los'
import { ReadOnlyFormField } from '../../../../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField'
import LosFormInputField from '../../../../../onboarding/components/common/LosFormInputField'
import LosText from '../../../../../onboarding/components/common/LosText'

interface IProps {
  applicationId?: string
  memberId?: string
  header?: string
  subHeader?: string
  selfieData: Partial<SelfieMetaData>
  isLoading?: boolean
}

const SelfieDetailsContainer = ({
  applicationId,
  header,
  subHeader,
  memberId,
  isLoading = false,
  selfieData,
}: IProps) => {
  // const [loading, setLoading] = useState<boolean>(false)
  // const [selfieDetails, setSelfieDetails] = useState<Partial<LosDocument>>({})

  // const getSelfieDetails = async () => {
  //   try {
  //     if (!applicationId) return
  //     setLoading(true)
  //     const response = await LosService.getDocument({
  //       documentType: LosDocumentType.SELFIE,
  //       applicationId: applicationId,
  //       memberId: memberId,
  //     })
  //     setSelfieDetails(response)
  //   } catch (error) {
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const getSelfieMatchPercentage = () => {
    const percentage = selfieData?.score
    if (percentage) {
      return `${+percentage * 100} %`
    }
    return '-'
  }

  const getSelfieStatus = () => {
    if (isVerified()) {
      return 'Verified'
    }
    return 'Not Verified'
  }

  const isVerified = () => {
    const status = selfieData?.status
    return status === DocumentStatus.APPROVED
  }

  // useEffect(() => {
  //   getSelfieDetails()
  // }, [applicationId, memberId])

  return (
    <>
      {header && (
        <Typography
          className='font-aspekta-500'
          fontSize={14}
          style={{ textDecoration: 'underline' }}
        >
          {header}
        </Typography>
      )}
      <Box mt={2}>
        {subHeader && (
          <Typography
            className='font-aspekta-700'
            sx={{
              textTransform: 'uppercase',
              fontSize: 10,
              letterSpacing: '0.07em',
              textDecoration: 'underline',
              mb: 1,
              color: 'black',
            }}
          >
            {subHeader}
          </Typography>
        )}
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <Box
              sx={{
                width: '80px',
                height: '80px',
                borderRadius: '6px',
              }}
            >
              {isLoading ? (
                <Skeleton />
              ) : selfieData?.fileName ? (
                <img
                  width={'100%'}
                  height={'100%'}
                  style={{
                    borderRadius: '6px',
                  }}
                  src={selfieData?.fileName ?? ''}
                  alt=''
                />
              ) : (
                <Box
                  sx={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '6px',
                  }}
                >
                  <Person sx={{ fontSize: 80, color: BLUE.lighter }} />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <LosFormInputField label={'Status'}>
              {isLoading == true && <Skeleton />}
              {isLoading == false && (
                <LosText
                  text={getSelfieStatus()}
                  fontSize={'0.75rem'}
                  fontWeight={400}
                  lineHeight={'130%'}
                  color={isVerified() ? GREEN.green : RED.red}
                />
              )}
            </LosFormInputField>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField
              title='Face Match'
              loading={isLoading}
              description={getSelfieMatchPercentage().toString()}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default SelfieDetailsContainer
