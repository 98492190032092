import { Grid, Divider, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { ReadOnlyFormField } from '../../../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField'
import LosText from '../../../components/common/LosText'
import { useApplicationPreview } from '../../../providers/ApplicationPreviewProvider'
import { NonIndividualKycPreviewData } from '../types'
import PromoterKycDetailsPreview from './PromoterKycDetailsPreview'
import {
  CompanyDetailsResponse,
  DocumentStatus,
  UserAadharStatus,
  UserDataDto,
} from '../../../types/Los'
import { UserDataProcessor } from '../../../../LMSAgent/components/ApplicationReview/components/KYCDetails/KYCDetailsForPartnership'
import { LosService } from '../../../api/LosService'
import { getApplicationsDetails } from '../../../../../store/common/agentApplicationsSlice'
import { useAppSelector } from '../../../../../store/hook'
import { LocalStorageUtility, StorageKeys } from '../../../utils/StorageUtility'

type Props = {
  viewerType: 'Customer' | 'HQ'
}
function NonIndividualKycDetailsPreview({ viewerType }: Props) {
  // const { applicationDetails } = useAppSelector(getApplicationsDetails)
  // const applicationId =
  //   LocalStorageUtility.getItem<string>(StorageKeys.APPLICATION_ID) ?? ''
  const { fetchNonIndividualKycDetails, applicationId } =
    useApplicationPreview()
  const [loading, setLoading] = useState<boolean>(false)
  // const [kycDetails, setKycDetails] = useState<
  //   Partial<NonIndividualKycPreviewData>
  // >({})
  const [loadingUserDetails, setLoadingUserDetails] = useState<boolean>(false)
  const [userDetails, setUserDetails] = useState<UserDataDto[]>([])
  const [companyDeatils, setCompanyDeatils] = useState<
    Partial<CompanyDetailsResponse>
  >({})

  // async function fetchDetails() {
  //   try {
  //     if (!applicationId) return
  //     setLoading(true)
  //     const response = await fetchNonIndividualKycDetails(applicationId)
  //     setKycDetails(response)
  //   } catch (error) {
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  // useEffect(() => {
  //   fetchDetails()
  // }, [])

  const getUserDetailsApi = async () => {
    try {
      setLoadingUserDetails(true)
      const response = await LosService.getUserDetails(applicationId ?? '')
      setCompanyDeatils(response.companyDetailsResponse)
      const users = UserDataProcessor.getUsers(response)
      setUserDetails(users)
    } catch (error) {
    } finally {
      setLoadingUserDetails(false)
    }
  }

  useEffect(() => {
    getUserDetailsApi()
  }, [])

  const getStatus = () => {
    if (isVerified()) {
      return 'Verified'
    }
    return 'Not Verified'
  }

  const isVerified = () => {
    const status = companyDeatils?.valid
    return status === true
  }

  const isCustomerViewingComponent = () => viewerType === 'Customer'

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    )
  }
  return (
    <div>
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <LosText text='Company Pan Details' />
        </Grid>
        <Grid item xs={6} sm={3}>
          <ReadOnlyFormField
            title='Name'
            // description={kycDetails.companyPanDetails?.name}
            description={companyDeatils?.companyName ?? '-'}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <ReadOnlyFormField
            title='Pan Number'
            // description={kycDetails.companyPanDetails?.panNumber}
            description={companyDeatils?.panNumber ?? '-'}
          />
        </Grid>

        {isCustomerViewingComponent() == false && (
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField
              title='Status'
              // description={kycDetails.companyPanDetails?.status}
              description={getStatus()}
            />
          </Grid>
        )}
      </Grid>

      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      />
      {userDetails?.map((item, index) => {
        return (
          <div>
            <PromoterKycDetailsPreview
              key={index}
              kycDetails={item}
              index={index}
              viewerType={viewerType}
            />
            {index % 2 == 0 && (
              <Divider
                style={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                }}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default NonIndividualKycDetailsPreview
