import './Styles/__styles.css';
import { Typography, TypographyProps, styled } from '@mui/material';

export interface StyledTypographyProps extends TypographyProps {
  bold?: boolean;
}

const StyledTypography = styled(Typography)<StyledTypographyProps>(
  ({ theme, bold }) => {
    return {
      fontFamily: 'Aspekta !important',
      ...(bold && {
        color: '#3A4A5F',
        fontWeight: 700,
      }),
    };
  }
);

export interface VegaTextV2Props extends StyledTypographyProps {
  text?: string;
}

function VegaTextV2({ text, children, ...rest }: VegaTextV2Props) {
  return <StyledTypography {...rest}>{children || text}</StyledTypography>;
}

export default VegaTextV2;
