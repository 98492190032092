/** @format */

import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchUserData, getClient, updateClientLogo } from '../api/client'
import { getUserByEmail } from '../api/roleManagement'
import { Loading } from '../components/common'
import { ACCESS_MAP, isDev } from '../constants/url'
import { ROUTES, SCOPE_MODULE_ROUTE_MAP } from '../routes'
import {
  ILoggedInUser,
  IVegaClientScope,
  IVegaClientScopeProgramRoles,
  UpdateClientLogoRequestData,
  VegaClientInterface,
  getNameForClientLogoType,
} from '../types/client'
import { VegaUser } from '../types/user'
import { DEFAULT_SCOPE, getParsedScope } from '../utils/clientScope'
import { useSnackbar } from './SnackbarProvider'

export type ClientContextType = {
  clientId: string | null
  userId: string | null
  isAuth: boolean
  mccUser: boolean
  forexAdmin: boolean
  login: (event: any, email: string, password: string) => void
  logout: () => void
  preferedUserName?: string
  client: VegaClientInterface | null
  scope: IVegaClientScope
  getCurrentModule: () => string
  canAccessModule: (module: string) => boolean
  canAccessSubModule: (subModule: string) => boolean
  getAllowedProgramRolesForSubmodule: (
    subModule: string
  ) => IVegaClientScopeProgramRoles | null
  isAdmin: boolean
  mccPlatformId: string
  userDetails: any
  completeNewPassword: (newPassword: string) => void
  loggedInUserDetails: ILoggedInUser | null
  updateClientLogo: (logos: UpdateClientLogoRequestData) => void
  user?: VegaUser | null
  agentId?: string
}

type Props = {
  children: React.ReactNode
}

const ClientContext = React.createContext<ClientContextType | null>(null)
export const useClientAuth = () =>
  React.useContext(ClientContext) as ClientContextType

const ClientAuthProvider = ({ children }: Props) => {
  const existingId = sessionStorage.getItem('clientId')
  const existingUserId = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  const { setSnackbar } = useSnackbar()
  const [isAuth, setIsAuth] = useState(!!existingId)
  const [isAdmin, setIsAdmin] = useState(
    sessionStorage.getItem('isAdmin') === 'true'
  )

  const [clientId, setClientId] = useState<string | null>(existingId || '')
  const [userId, setUserId] = useState<string | null>(existingUserId || '')
  const [client, setClient] = useState<VegaClientInterface | null>(null)
  const [scope, setScope] = useState<IVegaClientScope>({ ...DEFAULT_SCOPE })
  const [mccUser, setMccUser] = useState<boolean>(false)
  const [forexAdmin, setForexAdmin] = useState<boolean>(false)
  const [mccPlatformId, setMccPlatformId] = useState<string>('')
  const [loadingClientDetails, setLoadingClientDetails] = useState(false)
  const [loginChallange, setLoginChallange] = useState<any>(null)
  const [userDetails, setUserDetails] = useState<any>({})
  const [loadingCurrentUser, setLoadingCurrentUser] = useState(false)
  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<ILoggedInUser | null>(null)
  const [agentId, setAgentId] = useState<string>()
  const [user, setUser] = useState<VegaUser | null>(null)
  const [preferredUserName, setPreferredUserName] = useState<string>('')
  const tokenRefreshJob = React.useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    setUserParams()
    return () => {
      stopTokenRefreshJob()
    }
  }, [])

  useEffect(() => {
    if (!client || !loggedInUserDetails) return
    const updatedLoggedInDetails: ILoggedInUser = {
      email: loggedInUserDetails.email,
      mobileNumber: client.clientMob,
      loginTime: loggedInUserDetails.loginTime,
      idToken: loggedInUserDetails.idToken,
      accessToken: loggedInUserDetails.idToken,
      refreshToken: loggedInUserDetails.idToken,
      name: loggedInUserDetails.name,
      user: loggedInUserDetails?.user,
    }
    setLoggedInUserDetails(updatedLoggedInDetails)
  }, [client])

  const login = (event: any, email: string, password: string) => {
    if (event != null) {
      event.preventDefault()
    }

    Auth.signIn(email, password)
      .then(user => {
        if (window.location.pathname != '/onboarding') {
          setSnackbar(`Login successfull`)
        }

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setLoginChallange({ forceChange: true, user: user })
          navigate(ROUTES.SET_NEW_PASSWORD)
        } else {
          setUserParams()
        }
      })
      .catch(error => {
        setSnackbar(`${error.message}`, 'error')
        console.error('Cannot login', error)
      })
  }
  const startTokenRefreshJob = () => {
    if (!tokenRefreshJob.current) {
      console.log(
        'Starting background JOB to refresh access token every 6 hours'
      )
      const tokenInterval = setInterval(refreshAccessToken, 1000 * 60 * 60 * 6)
      tokenRefreshJob.current = tokenInterval
    }
  }

  const stopTokenRefreshJob = () => {
    clearInterval(tokenRefreshJob.current as NodeJS.Timeout)
    tokenRefreshJob.current = null
  }

  const refreshAccessToken = () => {
    Auth.currentSession()
      .then((data: any) => {
        console.log('Current Session : ', data)
      })
      .catch((error: any) => {
        console.error('Failed to fetch current session', error)
        logout()
      })
  }

  const completeNewPassword = async (newPassword: string) => {
    if (loginChallange?.forceChange) {
      try {
        let user = loginChallange.user
        try {
          const { requiredAttributes } = user.challengeParam
          user = await Auth.completeNewPassword(
            loginChallange.user as CognitoUser,
            newPassword,
            requiredAttributes
          )
          console.log(user)
          setUserParams()
          if (agentId) {
            navigate(ROUTES.AGENT_LOGIN)
          } else {
            navigate(ROUTES.HQ_LOGIN)
          }
        } catch (e) {
          console.log(e)
        }
      } catch (e) {
        console.log('Failed to update', e)
      }
    }
  }

  const setUserParams = async () => {
    setLoadingCurrentUser(true)
    await Auth.currentAuthenticatedUser()
      .then(async user => {
        if (user) {
          console.log('Logged in user', user)
          const clientId =
            user.signInUserSession.idToken.payload['custom:clientId']
          const agentId =
            user.signInUserSession.idToken.payload['custom:agentId']
          const mccUser =
            user.signInUserSession.idToken.payload['custom:mccUser']
          const forexAdmin =
            user.signInUserSession.idToken.payload['custom:forexAdmin']
          const userId =
            user.signInUserSession.idToken.payload['cognito:username']
          const isAdmin = true
          const scopeStr =
            user.signInUserSession.idToken.payload?.['custom:scope'] // MCC login
          const loggedInUserEmail =
            user.signInUserSession.idToken.payload?.['email']
          const loggedInTime = new Date(
            user.signInUserSession.idToken.payload['auth_time'] * 1000
          )
          const idToken = user.signInUserSession.idToken.jwtToken
          const accessToken = user.signInUserSession.accessToken.jwtToken
          const refreshToken = user.signInUserSession.refreshToken.jwtToken
          const userName =
            user.signInUserSession.idToken.payload['preferred_username']
          setPreferredUserName(userName)
          const exp = new Date(
            user.signInUserSession.idToken.payload['exp'] * 1000
          )
          const scopeObj = getParsedScope(scopeStr)
          const isMccUser = mccUser === 'true' ? true : false
          const isForexAdmin = forexAdmin === 'true' ? true : false

          setAgentId(agentId)
          setMccUser(isMccUser)
          setForexAdmin(isForexAdmin)
          setClientId(clientId)
          fetchClient(clientId, isMccUser)
          console.log('first', mccPlatformId, scopeObj.scope, mccUser)
          if (!mccPlatformId && scopeObj.scope && mccUser) {
            const roles = scopeObj.scope[Object.keys(scopeObj.scope)?.[0]]
            let branchIdFromRole = roles[Object.keys(roles)?.[0]]
            const bId = Object.keys(branchIdFromRole)?.[0]
            console.log('🚀 ~ file: ClientProvider.tsx:209 ~ .then ~ bId:', bId)
            setMccPlatformId(bId ?? '')
          }
          setIsAuth(true)
          setIsAdmin(isAdmin)
          setScope(scopeObj)
          setLoginChallange(null)
          sessionStorage.setItem('clientId', clientId)
          sessionStorage.setItem('userId', userId)
          sessionStorage.setItem('isAdmin', `${isAdmin}`)
          sessionStorage.setItem('scope', `${scopeObj}`)
          sessionStorage.setItem('token', `${accessToken}`)
          await fetchUserDetailsAndSetLoggedInUser({
            email: loggedInUserEmail,
            idToken: idToken,
            accessToken: accessToken,
            refreshToken: refreshToken,
            loggedInTime: loggedInTime,
          })
          await getUserData(loggedInUserEmail)
          startTokenRefreshJob()
        }
      })
      .catch(err => {
        console.error('failed to fetch current user', err)
      })
      .finally(() => {
        setLoadingCurrentUser(false)
      })
  }

  const getUserData = async (email: string) => {
    fetchUserData(email)
      .then(response => {
        console.log('response?.data', response?.data)
        setUserDetails(response?.data)
        if (response?.data) {
          setMccUser(response?.data?.mccUser === true)
        }
        setUserId(response?.data?.userId ?? '')
      })
      .catch(err => {
        console.error('failed to fetch current user data', err)
      })
  }

  function fetchClient(clientId: string, mccUser: boolean) {
    if (!!clientId && !loadingClientDetails) {
      setLoadingClientDetails(true)

      getClient(clientId)
        .then(res => setClient(res.data))
        .catch(err => {
          // setSnackbar('Failed to fetch client', 'error');
        })
        .finally(() => setLoadingClientDetails(false))
    }
  }

  const fetchUserDetailsAndSetLoggedInUser = async (data: {
    email: string
    idToken: string
    accessToken: string
    refreshToken: string
    loggedInTime: Date
  }) => {
    try {
      const response = await getUserByEmail({ email: data.email })

      setLoggedInUserDetails({
        email: data.email,
        loginTime: data.loggedInTime,
        idToken: data.idToken,
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
        name: response.name,
        user: response,
      })
      const isAdmin = response.isAdmin
      setIsAdmin(isAdmin)
      setUser(response)
    } catch (error) {
      console.error('Failed to get user details by email=> ', error)
      setLoggedInUserDetails({
        email: data.email,
        loginTime: data.loggedInTime,
        idToken: data.idToken,
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      })
      setIsAdmin(false)
      setUser(null)
    }
  }

  const logout = () => {
    Auth.signOut()
    stopTokenRefreshJob()
    setClientId(null)
    setClient(null)
    setIsAuth(false)
    sessionStorage.clear()
    if (agentId) {
      navigate(ROUTES.AGENT_LOGIN)
    } else {
      navigate(ROUTES.HQ_LOGIN)
    }
  }

  const getNormalisedModuleString = (module: string | undefined) => {
    if (module) {
      return module.replace('/', '').toUpperCase()
    }
    return ''
  }

  const getCurrentModule = () => {
    const currentHost = window.location.hostname
    let module = isDev
      ? 'hq'
      : getNormalisedModuleString(ACCESS_MAP[currentHost])
    console.log('current modeule: ', module)
    if (
      (currentHost === 'client.vegapay.tech' ||
        currentHost === 'www.client.vegapay.tech') &&
      mccUser
    ) {
      module = 'hq'
    }
    console.log('current modeule: ', module)
    if (SCOPE_MODULE_ROUTE_MAP[module]) {
      console.log(
        'current modeule: ',
        getNormalisedModuleString(SCOPE_MODULE_ROUTE_MAP[module])
      )
      return getNormalisedModuleString(SCOPE_MODULE_ROUTE_MAP[module])
    }

    return getNormalisedModuleString(module)
  }

  const canAccessModule = (module: string) => {
    const normalisedModuleStr = getNormalisedModuleString(module)

    if (
      !!scope?.scope ||
      scope.scope?.[normalisedModuleStr] ||
      scope.scope['ALL']
    ) {
      if (forexAdmin) {
        return true
      }
      if (mccUser) {
        return scope.scope?.[normalisedModuleStr] ? true : false
      }
      return true
    }
    return false
  }

  const mccSubModuleMap: Record<string, any> = {
    FOREX_HQ: {
      'CREATE-PROGRAM': 'FOREX_HQ_PROGRAM',
      'CREATE-BRANCH': 'FOREX_HQ_BRANCH',
      'CREATE-ROLE': 'FOREX_HQ_ROLE',
      'TEAM-MANAGEMENT-CHECKER': 'FOREX_HQ_TEAM_MANAGEMENT_CHECKER',
      'TEAM-MANAGEMENT-MAKER': 'FOREX_HQ_TEAM_MANAGEMENT_MAKER',
      POLICIES: 'FOREX_HQ_POLICIES',
      'PROGRAM-ALLOCATION': 'FOREX_HQ_PROGRAM_ALLOCATION',
      'INSTITUTIONAL-RESOURCES': 'FOREX_HQ_INSTITUTIONAL_RESOURCES',
      'CARD-CHECKER': 'FOREX_HQ_CARD_SALE_CHECKER',
      'APPLICATION-MANAGEMENT': 'FOREX_HQ_APPLICATION_MANAGEMENT',
      'CARD-TRANSACTION': 'FOREX_HQ_CARD_TRANSACTION',
      'INVENTORY-MANAGEMENT': 'FOREX_HQ_INVENTORY_MANAGEMENT',
    },
    FOREX_BRANCH: {
      'TEAM-MANAGEMENT': 'FOREX_BRANCH_TEAM_MANAGEMENT',
      'APPLICATION-MANAGEMENT': 'FOREX_BRANCH_APPLICATION_MANAGEMENT',
      'USER-MANAGEMENT': 'FOREX_BRANCH_USER_MANAGEMENT',
      'CARD-MAKER': 'FOREX_BRANCH_CARD_SALE_MAKER',
      'CARD-TRANSACTION': 'FOREX_BRANCH_CARD_TRANSACTION',
      'INVENTORY-MANAGEMENT': 'FOREX_BRANCH_INVENTORY_MANAGEMENT',
      'RELEASE-TRANSACTION': 'FOREX_BRANCH_RELEASE_TRANSACTION',
      SERVICES: 'FOREX_BRANCH_SERVICES',
      'CORPORATE-MANAGEMENT': 'FOREX_BRANCH_CORPORATE_MANAGEMENT',
    },
    FOREX_CORPORATE: {
      'CARD-ANALYTICS': 'FOREX_CORPORATE_CARD_ANALYTICS',
      'CARD-TRANSACTION': 'FOREX_CORPORATE_CARD_TRANSACTION',
      INVOICES: 'FOREX_CORPORATE_INVOICES',
      'APPLICATION-MANAGEMENT': 'FOREX_CORPORATE_APPLICATION_MANAGEMENT',
      'CARD-SALE': 'FOREX_CORPORATE_CARD_SALE',
    },
    FOREX_SIMULATOR: {
      TRANSACTIONS: 'FOREX_SIMULATOR_TRANSACTIONS',
    },
  }

  const canAccessSubModule = (subModule: string) => {
    const module = getCurrentModule()
    if (!canAccessModule(module) && !isDev) {
      return false
    }
    console.log('module: ', module)
    console.log('scope.scope: ', scope.scope)
    const normalisedSubModule = getNormalisedModuleString(subModule)
    const moduleObj = scope.scope[module] || scope.scope['ALL']
    console.log('moduleObj:', moduleObj)
    if (!moduleObj) {
      return true
    }
    console.log(
      'moduleObj[mccSubModuleMap[module][normalisedSubModule]]: ',
      moduleObj?.[mccSubModuleMap?.[module]?.[normalisedSubModule]]
    )
    if (
      moduleObj[normalisedSubModule] ||
      moduleObj['ALL'] ||
      moduleObj?.[mccSubModuleMap?.[module]?.[normalisedSubModule]]
    ) {
      return true
    }
    return false
  }

  const getAllowedProgramRolesForSubmodule = (subModule: string) => {
    const module = getCurrentModule()
    if (!canAccessModule(module) || !canAccessSubModule(subModule)) {
      return null
    }
    try {
      const normalisedSubModule = getNormalisedModuleString(subModule)
      console.log(
        'Scope str in program check: ',
        scope,
        module,
        normalisedSubModule
      )
      const allowedPrograms = scope.scope[module]
        ? scope.scope[module][normalisedSubModule]
          ? scope.scope[module][normalisedSubModule]
          : scope.scope[module]['ALL']
        : scope.scope['ALL'][normalisedSubModule]
        ? scope.scope['ALL'][normalisedSubModule]
        : scope.scope['ALL']['ALL']
      return allowedPrograms
    } catch {
      return { ALL: ['EDIT'] }
    }
  }

  function _updateClientLogo(logoData: UpdateClientLogoRequestData) {
    return new Promise((resolve, reject) => {
      updateClientLogo(logoData)
        .then(response => {
          const updatedClient = response.data as VegaClientInterface
          setClient(updatedClient)
          setSnackbar('Updated ' + getNameForClientLogoType(logoData.logoType))
          resolve(updatedClient)
        })
        .catch(error => {
          setSnackbar('Failed to update Logo ' + error, 'error')
          reject(error)
        })
    })
  }

  return (
    <ClientContext.Provider
      value={{
        userDetails,
        clientId,
        userId,
        mccUser,
        forexAdmin,
        isAuth,
        login,
        logout,
        client,
        mccPlatformId,
        isAdmin,
        scope,
        getCurrentModule,
        canAccessModule,
        canAccessSubModule,
        completeNewPassword,
        getAllowedProgramRolesForSubmodule,
        loggedInUserDetails,
        preferedUserName: preferredUserName,
        updateClientLogo: _updateClientLogo,
        user: user,
        agentId: agentId,
      }}
    >
      {loadingCurrentUser || loadingClientDetails ? <Loading /> : children}
    </ClientContext.Provider>
  )
}

export default ClientAuthProvider
