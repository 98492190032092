import { useEffect } from 'react'
import {
  CompanyPanVerificationState,
  useCompanyPanVerification,
} from '../../providers/CompanyPanProvider'
import { useLos } from '../../providers/LosProvider'
import { LosEntityType, LosStep } from '../../types/Los'
import CompanyIdentificationNumberInput from '../CompanyDetails/CompanyIdentificationNumberInput'
import CompanyDetails from '../CompanyDetails/CompanyDetails'
import {
  SelfieProvider,
  SelfieViewState,
} from '../SelfieVerification/SelfieProvider'
import SelfieVerification from '../SelfieVerification/SelfieVerification'
import AddPromoter from './Company/AddPromoter'
import CompanyPanDetails from './Company/CompanyPanDetails'
import PromoterAdded from './Company/PromoterAdded'
import PartnershipIdentificationNumberInput from '../CompanyDetails/PartnershipIdentificationNumberInput'
import { LOSEntityType } from '../../../enums/applications'
import { LosService } from '../../api/LosService'
import IndividualStepContainer from '../IndividualStepContainer'
import SoleProprietorKyc from './Company/SoleProprietorKyc'
import IndividualPanDetails from './IndividualPanDetails'
import IndividualAadharDetails from '../AadharDetails/IndividualAadharDetails'

function CompanyPanContainer() {
  const { currentStep, customerApplication } = useLos()
  const { currentState, updateState } = useCompanyPanVerification()

  useEffect(() => {
    if (
      currentStep == LosStep.COMPANY_PAN_VALIDATION ||
      currentStep == LosStep.COMPANY_PAN_ACCEPTED
    ) {
      updateState(CompanyPanVerificationState.ValidateCompanyPan)
    } else if (
      currentStep == LosStep.AADHAAR_VALIDATION ||
      currentStep == LosStep.AADHAAR_ACCEPTED
    ) {
      updateState(CompanyPanVerificationState.PromoterAdded)
    } else if (currentStep == LosStep.SELFIE_VERIFICATION) {
      if (
        customerApplication?.losEntityType === LOSEntityType.SOLE_PROPRIETOR
      ) {
        updateState(CompanyPanVerificationState.PromoterSelfie)
      } else {
        updateState(CompanyPanVerificationState.PromoterAdded)
      }
    } else if (currentStep == LosStep.COMPANY_DETAIL) {
      if (
        customerApplication?.losEntityType === LOSEntityType.SOLE_PROPRIETOR
      ) {
        updateState(CompanyPanVerificationState.CompanyDetails)
      } else {
        updateState(CompanyPanVerificationState.PromoterAdded)
      }
      // updateState(CompanyPanVerificationState.PromoterAdded)
    } else if (
      currentStep == LosStep.PAN_VALIDATION ||
      currentStep == LosStep.PAN_ACCEPTED
    ) {
      updateState(CompanyPanVerificationState.AddPromoter)
    } else if (
      currentStep == LosStep.GST_ACCEPTED ||
      currentStep == LosStep.GST_VERIFICATION
    ) {
      updateState(CompanyPanVerificationState.CompanyDetails)
    } else if (currentStep == LosStep.PIN_NO) {
      updateState(CompanyPanVerificationState.PinInput)
    } else if (currentStep == LosStep.CIN_NO) {
      updateState(CompanyPanVerificationState.CinInput)
    }
  }, [currentStep])

  if (currentState == CompanyPanVerificationState.ValidateCompanyPan) {
    return <CompanyPanDetails />
  } else if (currentState == CompanyPanVerificationState.AddPromoter) {
    if (customerApplication?.losEntityType === LOSEntityType.SOLE_PROPRIETOR) {
      return <IndividualPanDetails />
    } else {
      return <AddPromoter />
    }
    // return <AddPromoter />
  } else if (currentState == CompanyPanVerificationState.PromoterAdded) {
    if (customerApplication?.losEntityType === LOSEntityType.SOLE_PROPRIETOR) {
      return <IndividualAadharDetails />
    } else {
      return <PromoterAdded />
    }
    // return <PromoterAdded />
  } else if (currentState == CompanyPanVerificationState.PromoterSelfie) {
    return (
      <SelfieProvider>
        <SelfieVerification initialState={SelfieViewState.CaptureSelfie} />
      </SelfieProvider>
    )
  } else if (currentState == CompanyPanVerificationState.CompanyDetails) {
    //  if (customerApplication?.losEntityType === LOSEntityType.SOLE_PROPRIETOR) {
    //    return <PartnershipIdentificationNumberInput />
    //  } else {
    //    return <CompanyDetails />
    //  }
    return <CompanyDetails />
  } else if (currentState == CompanyPanVerificationState.PinInput) {
    return <PartnershipIdentificationNumberInput />
  } else if (currentState == CompanyPanVerificationState.CinInput) {
    if (customerApplication?.losEntityType === LOSEntityType.PARTNERSHIP) {
      return <PartnershipIdentificationNumberInput />
    } else {
      return <CompanyIdentificationNumberInput />
    }
  }

  return <div>{currentStep}</div>
}

export default CompanyPanContainer
