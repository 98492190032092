import { Box, Grid, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSnackbar } from '../../../../providers/SnackbarProvider'
import { getErrorMessageFromErrorObj } from '../../../../utils/api'
import { LosService } from '../../api/LosService'
import LosAppBar from '../../components/LosAppBar'
import { LosFormButtonView } from '../../components/LosFormButton'
import LosFormContainer from '../../components/LosFormContainer'
import LosText from '../../components/common/LosText'
import { useLos } from '../../providers/LosProvider'
import { AgreementFormData, LosDocumentType } from '../../types/Los'

function DownloadSignedAgreement() {
  const { setSnackbar } = useSnackbar()
  const { navigateToNextStep, customerApplication, saveAgreement } = useLos()
  const [errors, setErrors] = useState<Partial<AgreementFormData>>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [signedDocument, setSignedDocument] = useState<string>()

  async function onDownloadClick() {
    try {
      const applicationId = customerApplication?.id
      if (!applicationId) {
        console.error('Application id not found')
        return
      }
      setLoading(true)
      const response = await LosService.getDocument({
        applicationId: applicationId,
        documentType: LosDocumentType.SIGNED_AGREEMENT_LETTER,
      })

      const url = response.url

      window.open(url, '__blank')
      await LosService.updateNextStep(customerApplication?.id)
      navigateToNextStep()
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  async function PdfPreview() {
    try {
      const applicationId = customerApplication?.id
      if (!applicationId) {
        console.error('Application id not found')
        return
      }
      setLoading(true)
      const response = await LosService.getDocument({
        applicationId: applicationId,
        documentType: LosDocumentType.SIGNED_AGREEMENT_LETTER,
      })

      const url = response.url

      setSignedDocument(url)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    PdfPreview()
  }, [])

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            text='Download agreement'
            onClick={onDownloadClick}
            loading={loading}
          />
        )
      }}
      renderAppBar={() => {
        return (
          <LosAppBar currentStepLabel={'Agreement Signing'} hideProgressBar />
        )
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <Stack spacing={'0.5rem'}>
            <LosText
              text='Signed agreement'
              style={{
                fontFamily: 'Aspekta',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '17px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: ' #161616',
              }}
            />
            <div style={{ height: '600px', overflow: 'auto' }}>
              <iframe src={signedDocument} width={'600px'} height={'600px'} />
            </div>
            {/* <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'end',
                cursor: 'pointer',
              }}
              onClick={onDownloadClick}
            >
              <LosText
                text='Download agreement'
                style={{
                  color: '#1047DC',
                  fontSize: '0.625rem',
                  fontWeight: 700,
                  lineHeight: '124%',
                  letterSpacing: '0.04375rem',
                  textTransform: 'uppercase',
                }}
              />
            </Box> */}
          </Stack>
        </Grid>
      </Grid>
    </LosFormContainer>
  )
}

export default DownloadSignedAgreement
