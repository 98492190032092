import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { useState } from 'react'
import LosFileSelect from '../../components/common/LosFileSelect'
import LosFormInputField from '../../components/common/LosFormInputField'
import LosTextField from '../../components/common/LosTextField'
import LosAppBar from '../../components/LosAppBar'
import { LosFormButtonView } from '../../components/LosFormButton'
import LosFormContainer from '../../components/LosFormContainer'
import { useLos } from '../../providers/LosProvider'
import { CustomerApplication, SoleProprietorFormData } from '../../types/Los'
import { StringUtility } from '../../utils/StringUtility'
import { LosService } from '../../api/LosService'
import { LocalStorageUtility, StorageKeys } from '../../utils/StorageUtility'
import VerificationSuccessDialog from '../../components/VerificationSuccessDialog'
import VerifyGSTDetailsDialog from '../../components/VerifyGSTDetailsDialog'

enum InputType {
  COMPANY_NAME,
  GST_NUMBER,
}

function SelfEmployedCompanyDetails() {
  const {
    navigateToNextStep,
    currentStepMetaData,
    saveCompanyData,
    customerApplication,
    fetchPanDetailsForPanInfoId,
    updateGstStatusToAccepted,
    currentStep,
  } = useLos()
  const [formData, setFormData] = useState<SoleProprietorFormData>({
    companyName: '',
    gstNo: '',
  })
  const [openVerifyGSTDialog, setOpenVerifyGSTDialog] = useState<boolean>(false)
  const [openGstVerifiedDialog, setOpenGstVerifiedDialog] =
    useState<boolean>(false)
  const [errors, setErrors] = useState<Partial<SoleProprietorFormData>>({})
  const [loading, setLoading] = useState<boolean>(false)

  function handleUpdate(type: InputType, value: string) {
    const updatedErrors: Partial<SoleProprietorFormData> = {}
    switch (type) {
      case InputType.COMPANY_NAME:
        if (value.length <= 0) {
          updatedErrors.companyName = 'Company Name is required'
        }
        setFormData(prev => {
          return {
            ...prev,
            companyName: value,
          }
        })
        break
      case InputType.GST_NUMBER:
        if (value.length <= 0) {
          updatedErrors.gstNo = 'GST Number is required'
        } else {
          const isValid = StringUtility.validateGSTNumber(value)
          if (isValid == false) {
            updatedErrors.gstNo = 'Valid GST Number is required'
          }
        }
        setFormData(prev => {
          return {
            ...prev,
            gstNo: value,
          }
        })
        break
    }
    setErrors(updatedErrors)
  }

  function handleGstDocumentChange(file?: File | null) {
    setFormData(prev => {
      return {
        ...prev,
        gstDocument: file,
      }
    })
  }

  const isGSTNumberInputValid = () => {
    if (!!errors.gstNo) return false
    return (formData.gstNo ?? '').length > 0
  }

  const isInputValid = () => {
    let isPanValid = isGSTNumberInputValid()
    let isNameValid = (formData.companyName ?? '').length > 0
    return isPanValid && isNameValid
  }

  // async function fetchCompanyPanDetails() {
  //   try {
  //     setLoading(true);
  //     const panInfoId = customerApplication?.companyPanId;
  //     if (!panInfoId) return;
  //     const response = await fetchPanDetailsForPanInfoId(panInfoId);
  //     setFormData((prev) => {
  //       return {
  //         ...prev,
  //         companyName: response.name,
  //       };
  //     });
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  async function onGstVerificationConfirm() {
    try {
      setLoading(true)
      await updateGstStatusToAccepted()
      setOpenGstVerifiedDialog(true)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  async function onGstVerificationSuccessClick() {
    if (!customerApplication) return
    navigateToNextStep()
  }

  async function fetchCompanyPanDetails() {
    try {
      setLoading(true)
      const customerApplication =
        LocalStorageUtility.getItem<CustomerApplication>(
          StorageKeys.CUSTOMER_APPLICATION
        )
      const companyPanId = customerApplication?.companyDetails.panId
      // const panInfoId = customerApplication?.companyPanId;
      if (!companyPanId) return
      // const response = await fetchPanDetailsForPanInfoId(panInfoId);
      const response = await LosService.getCompanyPanDetailsByPanId(
        companyPanId
      )
      setFormData(prev => {
        return {
          ...prev,
          companyName: response.panName,
        }
      })
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  async function onSubmit() {
    try {
      setLoading(true)
      await saveCompanyData(formData)
      navigateToNextStep()
      setOpenVerifyGSTDialog(true)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchCompanyPanDetails()
  }, [customerApplication])

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            text='Proceed to Next Step'
            onClick={onSubmit}
            disabled={isInputValid() == false}
            loading={loading}
          />
        )
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        )
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <LosFormInputField label='Company Name'>
            <LosTextField
              value={formData.companyName}
              onChange={e => {
                handleUpdate(InputType.COMPANY_NAME, e.target.value)
              }}
              error={!!errors.companyName}
              helperText={errors.companyName}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label='GST NO.'>
            <LosTextField
              value={formData.gstNo}
              onChange={e => handleUpdate(InputType.GST_NUMBER, e.target.value)}
              error={!!errors.gstNo}
              helperText={errors.gstNo}
              isValid={isGSTNumberInputValid()}
            />
          </LosFormInputField>
        </Grid>
      </Grid>
      <VerifyGSTDetailsDialog
        gstNumber={''}
        open={openVerifyGSTDialog}
        onClose={() => setOpenVerifyGSTDialog(false)}
        onSubmit={onGstVerificationConfirm}
      />
      <VerificationSuccessDialog
        open={openGstVerifiedDialog}
        onClose={function (): void {
          setOpenGstVerifiedDialog(false)
        }}
        onSubmit={onGstVerificationSuccessClick}
        title={'Verify GST'}
        description={'Your Gst has been verified'}
        buttonText={'Proceed'}
      />
    </LosFormContainer>
  )
}

export default SelfEmployedCompanyDetails
