import { Grid, Divider, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import TrenchDetails from '../../../../LMSHQ/components/ActiveLoans/Tabs/LoanDetails/TrenchDetails';
import {
  LoanDetails,
  LoanDispersalSchedule,
} from '../../../../LMSHQ/types/Lms';
import LosFormInputField from '../../../../onboarding/components/common/LosFormInputField';
import LosText from '../../../../onboarding/components/common/LosText';
import { useColendingLoans } from '../../../provider/ColendingLoansProvider';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type DisburseNowDialogData = {
  open: boolean;
  trench: LoanDispersalSchedule | null;
  index: number;
};

function ColendingLoanDetailsTab() {
  const { fetchLoanDetails, selectedLoan, disburseTrench } =
    useColendingLoans();
  const [loading, setLoading] = useState<boolean>(false);
  const [loanDetails, setLoanDetails] = useState<Partial<LoanDetails>>({});
  const [disburseNowDialogData, setDisburseNowDialogData] =
    useState<DisburseNowDialogData>({
      open: false,
      trench: null,
      index: 0,
    });

  async function fetchDetails() {
    try {
      setLoanDetails({});
      setLoading(true);
      const response = await fetchLoanDetails();
      setLoanDetails(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function onDisburseTrench(
    trench: LoanDispersalSchedule,
    utrNumber: string,
    date: string
  ) {
    try {
      setLoading(true);
      const response = await disburseTrench({
        trench: trench,
        date: date,
        utrNumber: utrNumber,
      });
      updateTrenchInCache(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function updateTrenchInCache(updatedTrench: LoanDispersalSchedule) {
    const updatedTrenchList =
      loanDetails.trenches?.map((item) => {
        if (item.id == updatedTrench.id) return updatedTrench;
        return item;
      }) ?? [];

    setLoanDetails((prev) => {
      return {
        ...prev,
        trenches: updatedTrenchList,
      };
    });
  }

  const loanEmiDate = () => {
    const date = getLoan()?.loan_start_date;
    if (date) {
      return dayjs(date).format('DD/MM/YYYY');
    }
    return '-';
  };

  const getLoan = () => loanDetails.loan;

  const hasTrenches = () => {
    const trenches = loanDetails.trenches ?? [];
    return trenches.length > 0;
  };

  const hasChildLoans = () => {
    const childLoans = loanDetails.childLoans ?? [];
    return childLoans.length > 0;
  };

  useEffect(() => {
    fetchDetails();
  }, [selectedLoan]);

  return (
    <div
      style={{
        padding: '1rem',
      }}
    >
      <div
        style={{
          display: loading ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
      <div
        style={{
          display: loading == false ? 'flex' : 'none',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Grid container spacing={'1rem'} columns={10}>
          <Grid item xs={2}>
            <Field
              title="Total Loan Amount"
              description={getLoan()?.loan_amount}
            />
          </Grid>
          <Grid item xs={2}>
            <Field
              title="Rate Of Interest"
              description={getLoan()?.interest_rate + ' %'}
            />
          </Grid>
          <Grid item xs={2}>
            <Field
              title="Tenure"
              description={
                getLoan()?.tenure + ` ${getLoan()?.tenure_unit ?? '-'}`
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Field title="EMI Date" description={loanEmiDate()} />
          </Grid>
          <Grid item xs={2}>
            <Field
              title="Entity"
              description={loanDetails.customer?.customerType}
            />
          </Grid>
        </Grid>
      </div>
      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          display: hasTrenches() ? 'block' : 'none',
        }}
      />
      <div
        style={{
          display: hasTrenches() ? 'flex' : 'none',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <LosText text="Tranche Details" />
        {loanDetails.trenches?.map((item, index) => {
          return (
            <TrenchDetails
              trench={item}
              index={index}
              key={index}
              hideDisburseButton
            />
          );
        })}
      </div>
    </div>
  );
}

export default ColendingLoanDetailsTab;

const Field = (data: { title: string; description?: string | number }) => {
  const displayText = () => {
    if (data.description) {
      return `${data.description}`;
    }
    return '-';
  };
  return (
    <LosFormInputField label={data.title}>
      <LosText
        text={displayText()}
        fontSize={'0.75rem'}
        fontWeight={400}
        lineHeight={'120%'}
        color={'#1B1D22'}
      />
    </LosFormInputField>
  );
};
