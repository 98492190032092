import KYCDetails from '../../LMSAgent/components/ApplicationReview/components/KYCDetails/KYCDetails'
import { useLos } from '../providers/LosProvider'
import { LosStep } from '../types/Los'
import AadharDetails from './AadharDetails/AadharDetails'
import AgreementSigning from './AggrementSigning/AgreementSigning'
import AgreementSigningCompletedPage from './AggrementSigning/AgreementSigningCompletedPage'
import DownloadSignedAgreement from './AggrementSigning/DownloadSignedAgreement'
import ApplicationApproved from './ApplicationApproved/ApplicationApproved'
import ApplicationReadyForDisbursal from './ApplicationReadyForDisbursal/ApplicationReadyForDisbursal'
import ApplicationSubmitted from './ApplicationSubmitted/ApplicationSubmitted'
import BankDetails from './BankDetails/BankDetails'
import BasicDetails from './BasicDetails/BasicDetails'
import CollateralDetails from './CollateralDetails/CollateralDetails'
import CompanyDetails from './CompanyDetails/CompanyDetails'
import ElectricityDetails from './ElectricityDetails'
import FinancialDetails from './FinancialDetails/FinancialDetails'
import LoanDispursed from './LoanDispursed/LoanDispursed'
import PanDetails from './PanDetails/PanDetails'
import {
  SelfieProvider,
  SelfieViewState,
} from './SelfieVerification/SelfieProvider'
import SelfieVerification from './SelfieVerification/SelfieVerification'
import UploadAgreement from './UploadAgreement/UploadAgreement'

function IndividualStepContainer() {
  const { currentStep } = useLos()
  if (currentStep == LosStep.SUBMITTED) {
    return <ApplicationSubmitted />
  } else if (currentStep == LosStep.SANCTION_LETTER) {
    return <ApplicationApproved />
  } else if (currentStep == LosStep.USER_KYC) {
    return <KYCDetails />
  } else if (currentStep == LosStep.UNSIGNED_AGREEMENT) {
    return <UploadAgreement />
  } else if (currentStep == LosStep.CONTACT_DETAILS) {
    return <AgreementSigning />
  } else if (currentStep == LosStep.AGREEMENT) {
    return <AgreementSigningCompletedPage />
  } else if (currentStep == LosStep.SIGNED_AGREEMENT) {
    return <DownloadSignedAgreement />
  } else if (currentStep == LosStep.BANK_DETAILS) {
    return <BankDetails />
  } else if (
    currentStep == LosStep.AADHAAR_VALIDATION ||
    currentStep == LosStep.AADHAAR_ACCEPTED
  ) {
    return <AadharDetails />
  } else if (currentStep == LosStep.APPLICATION_FORM) {
    return <BasicDetails />
  } else if (currentStep == LosStep.FINANCIALS) {
    return <FinancialDetails />
  } else if (
    currentStep == LosStep.PAN_VALIDATION ||
    currentStep == LosStep.PAN_ACCEPTED
  ) {
    return <PanDetails />
  } else if (currentStep == LosStep.COLLATERAL) {
    return <CollateralDetails />
  } else if (currentStep == LosStep.DOCUMENTATION) {
    return <ElectricityDetails />
  } else if (currentStep == LosStep.SELFIE_VERIFICATION) {
    return (
      <SelfieProvider>
        <SelfieVerification initialState={SelfieViewState.CaptureSelfie} />
      </SelfieProvider>
    )
  } else if (
    currentStep == LosStep.ONBOARDED ||
    currentStep == LosStep.DISPERSED
  ) {
    return <LoanDispursed />
  } else if (currentStep == LosStep.READY_FOR_DISPERSAL) {
    return <ApplicationReadyForDisbursal />
  } else if (
    currentStep == LosStep.COMPANY_DETAIL ||
    currentStep == LosStep.GST_VERIFICATION ||
    currentStep == LosStep.GST_ACCEPTED
  ) {
    return <CompanyDetails />
  }

  return <div>{currentStep} Something Went Wrong</div>
}

export default IndividualStepContainer
