import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import VegaTextV2 from '../../../components/common/v2/VegaTextV2';
import { CheckMarkOutlinedWhite } from '../icons/LosIcons';

type Props = {
  index: number;
  selected: boolean;
  completed: boolean;
  heading: string;
  description: string;
};

function OnboardingStepListItem({
  index,
  selected,
  heading,
  description,
  completed,
}: Props) {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  useEffect(() => {
    setIsCompleted(completed);
    setIsSelected(selected);
  }, [selected, completed]);

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '20.5rem',
        minHeight: '4rem',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '0.75rem',
        transition: 'all 0.2s ease-in-out',
      }}
    >
      <StepNumberLabel
        isSelected={isSelected}
        index={index}
        isCompleted={isCompleted}
      />
      <Stack
        style={{
          // border: isSelected ? '1px solid #88F231' : 'none',
          borderRadius: '0.375rem',
          padding: '0.75rem',
          width: '100%',
          minHeight: '4rem',
          justifyContent: 'center',
          // backgroundColor: isSelected
          //   ? 'rgba(16, 71, 220, 0.05)'
          //   : 'transparent',
          transition: 'all 0.2s ease-in-out',
        }}
      >
        <VegaTextV2
          text={heading}
          fontSize={'0.8125rem'}
          lineHeight="120%"
          fontWeight={500}
          color={'white'}
        />
        {isSelected && (
          <VegaTextV2
            text={description}
            fontSize={'0.75rem'}
            lineHeight="130%"
            color={'white'}
          />
        )}
      </Stack>
    </div>
  );
}

export default OnboardingStepListItem;

type StepNumberLabelProps = {
  isSelected: boolean;
  isCompleted: boolean;
  index: number;
};
const StepNumberLabel = ({
  index,
  isSelected,
  isCompleted,
}: StepNumberLabelProps) => {
  return (
    <div
      style={{
        borderRadius: '2rem',
        background: isSelected || isCompleted ? '#88F231' : 'transparent',
        display: 'flex',
        width: '2rem',
        height: '2rem',
        padding: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        border: isSelected ? 'none' : '1px solid #88F231',
        transition: 'all 0.2s ease-in-out',
      }}
    >
      <div
        style={{
          display: isCompleted == true ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <CheckMarkOutlinedWhite />
      </div>

      <VegaTextV2
        display={isCompleted ? 'none' : 'block'}
        text={`${index + 1}`}
        color={isSelected ? '#161616' : '#88F231'}
        fontWeight={700}
      />
    </div>
  );
};
