import { Grid, Stack } from '@mui/material'
import { useState } from 'react'
import LosFileSelect from '../../components/common/LosFileSelect'
import LosFormInputField from '../../components/common/LosFormInputField'
import LosTextField from '../../components/common/LosTextField'
import LosAppBar from '../../components/LosAppBar'
import { LosFormButtonView } from '../../components/LosFormButton'
import LosFormContainer from '../../components/LosFormContainer'
import { useLos } from '../../providers/LosProvider'
import { IndividualFinancialFormData } from '../../types/Los'

enum InputType {
  EMPLOYEE_DETAILS,
  PAY_SLIP_FIRST_MONTH,
  PAY_SLIP_SECOND_MONTH,
  PAY_SLIP_THIRD_MONTH,
  BANK_STATEMENT,
  ITR_FIRST_YEAR,
  ITR_SECOND_YEAR,
}

function IndividualFinancialDetails() {
  const { navigateToNextStep, saveFiancialData, currentStepMetaData } = useLos()
  const [formData, setFormData] = useState<IndividualFinancialFormData>({
    employeeDetails: '',
    paySlips: {},
    bankStatement: null,
    itr: {},
  })
  const [errors, setErrors] = useState<Partial<IndividualFinancialFormData>>({})
  const [loading, setLoading] = useState<boolean>(false)

  function handleFileUpdate(type: InputType, value: File) {
    const updatedErrors: Partial<IndividualFinancialFormData> = { ...errors }

    switch (type) {
      case InputType.BANK_STATEMENT:
        setFormData(prev => {
          return {
            ...prev,
            bankStatement: value,
          }
        })
        break
      case InputType.PAY_SLIP_FIRST_MONTH:
        setFormData(prev => {
          return {
            ...prev,
            paySlips: {
              ...prev.paySlips,
              firstMonth: value,
            },
          }
        })
        break
      case InputType.PAY_SLIP_SECOND_MONTH:
        setFormData(prev => {
          return {
            ...prev,
            paySlips: {
              ...prev.paySlips,
              secondMonth: value,
            },
          }
        })
        break
      case InputType.PAY_SLIP_THIRD_MONTH:
        setFormData(prev => {
          return {
            ...prev,
            paySlips: {
              ...prev.paySlips,
              thirdMonth: value,
            },
          }
        })
        break
      case InputType.ITR_FIRST_YEAR:
        setFormData(prev => {
          return {
            ...prev,
            itr: {
              ...prev.itr,
              firstYear: value,
            },
          }
        })
        break
      case InputType.ITR_SECOND_YEAR:
        setFormData(prev => {
          return {
            ...prev,
            itr: {
              ...prev.itr,
              secondYear: value,
            },
          }
        })
        break
    }
    setErrors(updatedErrors)
  }

  function handleEmployeeDetailsUpdate(value: string) {
    const updatedErrors: Partial<IndividualFinancialFormData> = { ...errors }

    if (value.length <= 0) {
      updatedErrors.employeeDetails = 'Employee details are required'
    } else {
      delete updatedErrors.employeeDetails
    }
    setFormData(prev => {
      return {
        ...prev,
        employeeDetails: value,
      }
    })
    setErrors(updatedErrors)
  }

  const isInputValid = () => {
    let isEmployeeDetailsValid = (formData.employeeDetails ?? '').length > 0
    const paySlips = formData.paySlips
    let arePaySlipsValid =
      paySlips.firstMonth != null &&
      paySlips.secondMonth != null &&
      paySlips.thirdMonth != null
    const itr = formData.itr
    let isItrValid = itr.firstYear != null && itr.secondYear != null
    let isBankStatementValid = formData.bankStatement != null

    return (
      isEmployeeDetailsValid &&
      arePaySlipsValid &&
      isItrValid &&
      isBankStatementValid
    )
  }

  async function onSubmitClick() {
    try {
      setLoading(true)
      await saveFiancialData(formData)
      navigateToNextStep()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            text='Proceed to Next Step'
            disabled={isInputValid() == false}
            onClick={onSubmitClick}
            loading={loading}
          />
        )
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        )
      }}
    >
      <Grid container rowSpacing={'2rem'}>
        <Grid item xs={12}>
          <LosFormInputField label='Employer Details (Registered Company name)'>
            <LosTextField
              value={formData.employeeDetails}
              onChange={e => handleEmployeeDetailsUpdate(e.target.value)}
              error={!!errors.employeeDetails}
              helperText={errors.employeeDetails}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label='PaySlips (Last 3 months)'>
            <Stack spacing={'1rem'}>
              <LosFileSelect
                placeholder={'Upload PDF'}
                onFileSelect={function (file: File): void {
                  handleFileUpdate(InputType.PAY_SLIP_FIRST_MONTH, file)
                }}
              />
              <LosFileSelect
                placeholder={'Upload PDF'}
                onFileSelect={function (file: File): void {
                  handleFileUpdate(InputType.PAY_SLIP_SECOND_MONTH, file)
                }}
              />
              <LosFileSelect
                placeholder={'Upload PDF'}
                onFileSelect={function (file: File): void {
                  handleFileUpdate(InputType.PAY_SLIP_THIRD_MONTH, file)
                }}
              />
            </Stack>
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label='Recent Bank Statement (Last 6 months)'>
            <LosFileSelect
              placeholder={'Upload Bank Statement'}
              onFileSelect={function (file: File): void {
                handleFileUpdate(InputType.BANK_STATEMENT, file)
              }}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label='Income Tax Return (Last 2 years)'>
            <Stack spacing={'1rem'}>
              <LosFileSelect
                placeholder={'Upload ITR'}
                onFileSelect={function (file: File): void {
                  handleFileUpdate(InputType.ITR_FIRST_YEAR, file)
                }}
              />
              <LosFileSelect
                placeholder={'Upload ITR'}
                onFileSelect={function (file: File): void {
                  handleFileUpdate(InputType.ITR_SECOND_YEAR, file)
                }}
              />
            </Stack>
          </LosFormInputField>
        </Grid>
      </Grid>
    </LosFormContainer>
  )
}

export default IndividualFinancialDetails
