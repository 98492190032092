export interface VegaCustomerCard {
  id: string;
  accountId?: string;
  vendorId: string;
  vendorCardNumber: string;
  cardType: string;
  cardNetwork: string;
  cardStatus: string;
  lastFourDigits: string;
  pinStatus: string;
  replacedCardId?: string;
  nameOnCard: string;
  countryCode: string;
  mobileNumber: string;
  createdAt: string;
  updatedAt: string;
  primary: boolean;
}

export interface CardBin {
  id: number;
  clientId: string;
  binName: string;
  binValue: string;
  binType: BinType;
  cardFormFactorId?: string;
  startKitNumber?: number;
  endKitNumber?: number;
  lastAllocatedKitNumber?: number;
  totalKits?: number;
  allocatedKits?: number;
  unallocatedKits?: number;
  onHoldKits?: number;
  availableKits?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum BinType {
  SHARED = 'SHARED',
  DEDICATED = 'DEDICATED',
}
export interface BinSettings {
  programId: string;
  currency: Currency;
  binId: number;
  cardAllocated?: number;
}

export enum Currency {
  INR = 'INR',
}

export interface CardSettings {
  programId: string;
  cardDigit: number;
  expiryMonth: number;
  cvvType: string;
  cvvLength: number;
  physicalCardActivationProcess: string;
  virtualCardActivationProcess: string;
}

export enum CvvType {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC',
}

export enum CardCategory {
  PRIMARY_CARD = 'PRIMARY_CARD',
  SUPPLEMENT_CARD = 'SUPPLEMENT_CARD',
  ADD_ON_CARD = 'ADD_ON_CARD',
}
export interface AddOnCardConfiguration {
  programId: string;
  addOnCardSchemeName: string;
  supplementaryCardSchemeName: string;
  firstName: boolean;
  lastName: boolean;
  relationShip: boolean;
  address: boolean;
  phoneNumber: boolean;
  email: boolean;
  gender: boolean;
  panNo: boolean;
  aadhaarNo: boolean;
  addOnCardEcommerceStatus: boolean;
  addOnCardPosStatus: boolean;
  addOnCardCashWithdrawalStatus: boolean;
  addOnCardCount: number;
  supplementaryCardCount: number;
}

export interface BinAllocation {
  id: string;
  binId: number;
  binEntityType: any;
  entityId: string;
  kitStart: number;
  kitEnd: number;
  totalKits: number;
  allocatedKits: number;
  unallocatedKits: number;
  onHoldKits: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface PhysicalCardOrder {
  id: string;
  accountId: string;
  cardId: string;
  binId: number;
  clientId: string;
  trackingId: string;
  nameOnCard: string;
  nameOnLetter: string;
  nameOnEnvelop: string;
  customerAddress: Address;
  vendorAddress: Address;
  deliveryPartner: string;
  rtoAddress: Address;
  otpDelivery: boolean;
  physicalCardOrderStatus: PhysicalCardOrderStatus;
  eta: string;
  createdAt: string;
  updatedAt: string;
}

export interface Address {
  line1: string;
  line2: string;
  city: string;
  state: string;
  pincode: string;
  country: string;
}

export enum CardActivationOptions {
  GREEN_PIN = 'GREEN_PIN',
  PIN_THROUGH_SMS = 'PIN_THROUGH_SMS',
  OTP_ON_MOBILE = 'OTP_ON_MOBILE',
  OTP_ON_EMAIL = 'OTP_ON_EMAIL',
  OTP_ON_MOBILE_AND_EMAIL = 'OTP_ON_MOBILE_AND_EMAIL',
}

export enum PhysicalCardOrderStatus {
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  PLACED = 'PLACED',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  PRINTING_STARTED = 'PRINTING_STARTED',
  PACKED = 'PACKED',
  DISPATCHED = 'DISPATCHED',
  IN_TRANSIT = 'IN_TRANSIT',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  DELIVERED = 'DELIVERED',
  RTO = 'RTO',
}
