import axios from 'axios';
import { LMS } from '../endpoints';
import {
  IApplicationsForSolfinProps,
  IColleteralDetailsProps,
} from '../../types/application';

const APPLICATIONS_BASE_URL = LMS.BASE;

export const fetchAllApplications = (page: number, pageSize: number) => {
  return axios.get(
    `${APPLICATIONS_BASE_URL}/v2/applications?page=${page}&pageSize=${pageSize}`
  );
};
export const updateApplicationStatusForApproveReject = (
  applicationId: string,
  status: string,
  reason: string
) => {
  return axios.put(
    `${APPLICATIONS_BASE_URL}/v2/application/status?applicationId=${applicationId}&status=${status}&reason=${reason}`
  );
};

export const fetchAllApplicationsForAgent = (
  agentId: string,
  status?: string
) => {
  var endpoint = `${APPLICATIONS_BASE_URL}/v2/applications/agentId`;

  var data = {
    agentId: agentId,
    status: status,
  };

  if (!status) {
    delete data.status;
  }
  return axios.get(endpoint, { params: data });
};

export const fetchApplicationById = (id: string) => {
  return axios.get(`${APPLICATIONS_BASE_URL}/v2/application/${id}`);
};

export const updateApplication = (application: IApplicationsForSolfinProps) => {
  return axios.put(
    `${APPLICATIONS_BASE_URL}/v2/application/${application.id}/update`,
    application
  );
};
export const fetchPANDetails = (panId: string) => {
  return axios.get(`${APPLICATIONS_BASE_URL}/v1/pan/fetch/panId/${panId}`);
};
export const fetchAadharDetails = (aadhaarInfoId: string) => {
  return axios.get(
    `${APPLICATIONS_BASE_URL}/v1/aadhaar/fetch/aadhaarId/${aadhaarInfoId}`
  );
};

export const fetchDocumentURL = (
  applicationId: keyof IApplicationsForSolfinProps,
  documentType: string | null
) => {
  return axios.get(
    `${APPLICATIONS_BASE_URL}/v2/application/document/get?applicationId=${applicationId}&documentType=${documentType}`
  );
};
export const addFinancialDetailsForAgent = (
  applicationId: keyof IApplicationsForSolfinProps,
  documentType: string | null,
  formData: FormData
) => {
  const memberIdIn = `${APPLICATIONS_BASE_URL}/v2/application/financial-details?applicationId=${applicationId}&documentType=${documentType}`;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.post(memberIdIn, formData, config);
};
export const addCollateralDetailsForAgent = (
  applicationId: keyof IApplicationsForSolfinProps,
  data: IColleteralDetailsProps
) => {
  const collateralURL = `${APPLICATIONS_BASE_URL}/v2/application/collateral-details?applicationId=${applicationId}`;

  return axios.post(collateralURL, data);
};
// export const addDocumentationForAgent = (
//   applicationId: keyof IApplicationsForSolfinProps,
//   documentType: string | null,
//   formData: FormData
// ) => {
//   const memberIdIn = `${APPLICATIONS_BASE_URL}/v2/application/documentation-details?applicationId=${applicationId}&documentType=${documentType}`;
//   const config = {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   };

//   return axios.post(memberIdIn, formData, config);
// };
export const addCompanyDetailsForAgent = (
  applicationId: keyof IApplicationsForSolfinProps,
  documentType: string | null,
  formData: FormData
) => {
  const comapnyURL = `${APPLICATIONS_BASE_URL}/v2/application/company-details?applicationId=${applicationId}&documentType=${documentType}`;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.post(comapnyURL, formData, config);
};
export const addAgreementDetailsForAgent = (
  applicationId: keyof IApplicationsForSolfinProps,
  documentType: string | null,
  formData: FormData
) => {
  const comapnyURL = `${APPLICATIONS_BASE_URL}/v2/application/agreement?applicationId=${applicationId}&documentType=${documentType}`;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.post(comapnyURL, formData, config);
};

// TODO: iska url change krna @Shailendra
export const updateDocumentForAgent = (
  applicationId: keyof IApplicationsForSolfinProps,
  documentType: string | null,
  formData: FormData,
  memberId?: string | null
) => {
  const memberIdIn = `${APPLICATIONS_BASE_URL}/v2/document/update?applicationId=${applicationId}&memberId=${memberId}&documentType=${documentType}`;
  const withoutMemberId = `${APPLICATIONS_BASE_URL}/v2/document/update?applicationId=${applicationId}&documentType=${documentType}`;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  if (memberId) {
    return axios.post(memberIdIn, formData, config);
  } else {
    return axios.post(withoutMemberId, formData, config);
  }
};

export const updateApplicationStatus = (
  application: IApplicationsForSolfinProps
) => {
  return axios.post(
    `${APPLICATIONS_BASE_URL}/v2/application/status?applicationId=${application.id}&agentId=${application.agentId}`
  );
};
export const updateStatus = (
  applicationId: keyof IApplicationsForSolfinProps,
  status: string,
  agentId: string
) => {
  return axios.put(
    `${APPLICATIONS_BASE_URL}/v2/application/status?applicationId=${applicationId}&status=${status}&agentId=${agentId}`,
    status
  );
};
