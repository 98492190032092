export enum ApplicationReviewDetailsEnum {
  KYC_DETAILS = 'KYC_DETAILS',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  FINANCIAL_DETAILS = 'FINANCIAL_DETAILS',
  COLLATERAL = 'COLLATERAL',
  ELECTRICITY_DETAILS = 'ELECTRICITY_DETAILS',
  AGREEMENT_BANK_DETAILS = 'AGREEMENT_BANK_DETAILS',
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  PAYMENT_DETAILS = 'PAYMENT_DETAILS',
  SCRIPS_AND_CMS_DETAILS = 'SCRIPS_AND_CAMS_DETAILS',
  LOAN_DETAILS = 'LOAN_DETAILS',
  BUREAU_DETAILS = 'CIBIL_REPORTS',
  RULE_ENGINE = 'RULE_ENGINE',
}
export const ApplicationReviewDetailsType = [
  'KYC_DETAILS',
  'FINANCIAL_DETAILS',
  'COLLATERAL',
  'ELECTRICITY_DETAILS',
  ApplicationReviewDetailsEnum.BUREAU_DETAILS,
  // ApplicationReviewDetailsEnum.RULE_ENGINE,
];
export const ApplicationReviewDetailsTypeForPreDisbursal = [
  'KYC_DETAILS',
  'FINANCIAL_DETAILS',
  'COLLATERAL',
  'ELECTRICITY_DETAILS',
  'AGREEMENT_&_BANK_DETAILS',
  ApplicationReviewDetailsEnum.BUREAU_DETAILS,
  // ApplicationReviewDetailsEnum.RULE_ENGINE,
];

export const ApplicationReviewDetailsTypeForCompany = [
  'KYC_DETAILS',
  'COMPANY_DETAILS',
  'FINANCIAL_DETAILS',
  'COLLATERAL',
  'ELECTRICITY_DETAILS',
  ApplicationReviewDetailsEnum.BUREAU_DETAILS,
  // ApplicationReviewDetailsEnum.RULE_ENGINE,
];

export const ActiveLoansDetails = [
  'APPLICATION_DETAILS',
  'LOAN_DETAILS',
  'EMI_SCHEDULE',
];

export enum activeLoanEnum {
  APPLICATION_DETAILS = 'APPLICATION_DETAILS',
  LOAN_DETAILS = 'LOAN_DETAILS',
  EMI_SCHEDULE = 'EMI_SCHEDULE',
}
export enum CoLenderEnum {
  BANK_DETAILS = 'BANK_DETAILS',
  ELIGIBILITY_SCHEME = 'ELIGIBILITY_SCHEME',
  INTEREST_SCHEME = 'INTEREST_SCHEME',
}

export const RELATION_TYPE = ['FATHER', 'MOTHER', 'BROTHER', 'SISTER'];
export const LOAN_TYPE = ['SOLAR', 'COMMERCIAL', 'PERSONAL'];
export enum LOSEntityType {
  INDIVIDUAL = 'INDIVIDUAL',
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  PVT_LTD = 'PVT_LTD',
  PARTNERSHIP = 'PARTNERSHIP',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
}
export enum documentTypeEnum {
  PAYSLIP_LAST_MONTH = 'PAYSLIP_LAST_MONTH',
  PAYSLIP_SECOND_LAST_MONTH = 'PAYSLIP_SECOND_LAST_MONTH',
  PAYSLIP_THIRD_LAST_MONTH = 'PAYSLIP_THIRD_LAST_MONTH',
  BANK_STATEMENT = 'BANK_STATEMENT',
  INCOME_TAX_RETURN_LAST_YEAR = 'INCOME_TAX_RETURN_LAST_YEAR',
  INCOME_TAX_RETURN_SECOND_LAST_YEAR = 'INCOME_TAX_RETURN_SECOND_LAST_YEAR',
  AUDITED_FINANCIAL_REPORT_LAST_YEAR = 'AUDITED_FINANCIAL_REPORT_LAST_YEAR',
  AUDITED_FINANCIAL_REPORT_SECOND_LAST_YEAR = 'AUDITED_FINANCIAL_REPORT_SECOND_LAST_YEAR',
  GST_DOCUMENT = 'GST_DOCUMENT',
  CERTIFICATE_OF_INCORPORATION = 'CERTIFICATE_OF_INCORPORATION',
  MOA = 'MOA',
  AOA = 'AOA',
  PARTNERSHIP_FIRM_DEED = 'PARTNERSHIP_FIRM_DEED',
  ELECTRICITY_BILL_LAST_MONTH = 'ELECTRICITY_BILL_LAST_MONTH',
  ELECTRICITY_BILL_SECOND_LAST_MONTH = 'ELECTRICITY_BILL_SECOND_LAST_MONTH',
  ELECTRICITY_BILL_THIRD_LAST_MONTH = 'ELECTRICITY_BILL_THIRD_LAST_MONTH',
  SANCTION_LETTER = 'SANCTION_LETTER',
  CANCELLED_CHEQUE = 'CANCELLED_CHEQUE',
  AADHAAR_CARD = 'AADHAAR_CARD',
  PAN_CARD = 'PAN_CARD',
  SELFIE = 'SELFIE',
}

export enum HQReasonType {
  LOW_CREDIT_SCORE = 'LOW_CREDIT_SCORE',
  NO_ENOUGH_COLLATERAL = 'NO_ENOUGH_COLLATERAL',
  INSUFFICIENT_INCOME = 'INSUFFICIENT_INCOME',
  CASH_FLOW_LIMITATION = 'CASH_FLOW_LIMITATION',
  DEBT_TO_INCOME_RATIO_HIGH = 'DEBT_TO_INCOME_RATIO_HIGH',
  HIGH_CREDIT_UTILISATION = 'HIGH_CREDIT_UTILISATION',
  SHORT_BUSINESS_HISTORY = 'SHORT_BUSINESS_HISTORY',
  MANAGEMENT_RECORD_NOT_CLEAN = 'MANAGEMENT_RECORD_NOT_CLEAN',
  RISKY_INDUSTRY = 'RISKY_INDUSTRY',
}
export enum AgentReasonType {
  ADHAAR_PAN_MISMATCH = 'ADHAAR_PAN_MISMATCH',
  GST_DOCUMENT_MISMATCH_WITH_GST_NUMBER = 'GST_DOCUMENT_MISMATCH_WITH_GST_NUMBER',
  FINANCIAL_REPORT_MISMATCH = 'FINANCIAL_REPORT_MISMATCH',
  BANK_STATEMENT_6_MONTHS_UNAVAILABLE = 'BANK_STATEMENT_6_MONTHS_UNAVAILABLE',
  ITR_MISMATCH = 'ITR_MISMATCH',
  ELECTRICITY_BILL_MISMATCH = 'ELECTRICITY_BILL_MISMATCH',
  COMPANY_DOCUMENT_MISMATCH = 'COMPANY_DOCUMENT_MISMATCH',
}
export enum ReasonType {
  KYC_MISMATHCH = 'KYC_MISMATHCH',
  FINANCIAL_DETAILS_INCOMPLETE = 'FINANCIAL_DETAILS_INCOMPLETE',
  OTHER_REASON_WITH_COMMENT = 'OTHER_REASON_WITH_COMMENT',
  REFER_TO_DESIGN = 'REFER_TO_DESIGN',
}

export const agentReasonTypes = [
  'ADHAAR_PAN_MISMATCH',
  'GST_DOCUMENT_MISMATCH_WITH_GST_NUMBER',
  'FINANCIAL_REPORT_MISMATCH',
  'BANK_STATEMENT_6_MONTHS_UNAVAILABLE',
  'ITR_MISMATCH',
  'ELECTRICITY_BILL_MISMATCH',
  'COMPANY_DOCUMENT_MISMATCH',
];
export const hqReasonTypes = [
  'LOW_CREDIT_SCORE',
  'NO_ENOUGH_COLLATERAL',
  'INSUFFICIENT_INCOME',
  'CASH_FLOW_LIMITATION',
  'DEBT_TO_INCOME_RATIO_HIGH',
  'HIGH_CREDIT_UTILISATION',
  'SHORT_BUSINESS_HISTORY',
  'MANAGEMENT_RECORD_NOT_CLEAN',
  'RISKY_INDUSTRY',
];
export const financialDocumentTypeArrForIndividual = [
  'PAYSLIP_LAST_MONTH',
  'PAYSLIP_SECOND_LAST_MONTH',
  'PAYSLIP_THIRD_LAST_MONTH',
  'BANK_STATEMENT',
  'INCOME_TAX_RETURN_LAST_YEAR',
  'INCOME_TAX_RETURN_SECOND_LAST_YEAR',
];
export const financialDocumentTypeArrForPartner = [
  'AUDITED_FINANCIAL_REPORT_LAST_YEAR',
  'AUDITED_FINANCIAL_REPORT_SECOND_LAST_YEAR',
  'BANK_STATEMENT',
  'INCOME_TAX_RETURN_LAST_YEAR',
  'INCOME_TAX_RETURN_SECOND_LAST_YEAR',
];
export const documentationDetailsArr = [
  'ELECTRICITY_BILL_LAST_MONTH',
  'ELECTRICITY_BILL_SECOND_LAST_MONTH',
  'ELECTRICITY_BILL_THIRD_LAST_MONTH',
];
export const companyDocumentDetailsArr = [
  'GST_DOCUMENT',
  'CERTIFICATE_OF_INCORPORATION',
  'AOA',
];
