import { Grid, Stack } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { setIsApplicationForHQ } from '../../../../store/common/agentApplicationsSlice';
import { setPreDisbursalDetails } from '../../../../store/common/preDisburseForAgentSlice';
import { useAppDispatch } from '../../../../store/hook';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import LosDataGrid from '../../../components/LosDataGrid';
import LosStatusPill from '../../../components/LosStatusPill';
import SolfinAppBar from '../../../components/SolfinAppBar';
import { useLmsAgentRouter } from '../../../LMSAgent/Provider/LmsAgentRouter';
import { LOS_PROGRAM_ID } from '../../../onboarding/api/constants';
import {
  GetAllApplicationsForHQApiData,
  LosService,
} from '../../../onboarding/api/LosService';
import LosButton from '../../../onboarding/components/common/LosButton';
import LosSelect, {
  LosSelectOption,
} from '../../../onboarding/components/common/LosSelect';
import {
  ColendedApplication,
  ApplicationStatus,
  getApplicationStatusDisplayText,
} from '../../../onboarding/types/Los';
import { Agent } from '../../types/Lms';
import MapAgentDialog from '../Leads/Components/MapAgentDialog';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type MapAgentDialogProps = {
  show: boolean;
  application: ColendedApplication | null;
};
const ListOfApplications = () => {
  const { navigateToApplicationReview } = useLmsAgentRouter();
  const { setSnackbar } = useSnackbar();
  const [applications, setApplications] = useState<ColendedApplication[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>('ALL');
  const [pageSize, setPageSize] = useState<number>(10);
  const dispatch = useAppDispatch();

  const [showMapAgentDialog, setShowMapAgentDialog] =
    useState<MapAgentDialogProps>({
      show: false,
      application: null,
    });

  const COLUMN_DEF: GridColumns<ColendedApplication> = [
    {
      field: 'id',
      headerName: 'App. Id',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication;
        return row.applicationId;
      },
      flex: 1,
    },
    {
      field: 'customerName',
      headerName: 'Applicant Name',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication;
        var displayText = '-';
        if (row.customerName) {
          displayText = row.customerName;
        }
        return displayText;
      },
      flex: 1.5,
    },
    {
      field: 'losEntityType',
      headerName: 'Entity',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication;
        return _.startCase(_.toLower(row.losEntityType));
      },
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Mobile Number',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as ColendedApplication;
        var displayText = '-';
        if (application.phone) {
          displayText = application.phone;
        }
        return displayText;
      },
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as ColendedApplication;
        var displayText = '-';
        if (application.basicInfo?.amount) {
          displayText = `₹ ${application.basicInfo?.amount}`;
        }
        return displayText;
      },
      flex: 0.75,
    },
    {
      field: 'tenure',
      headerName: 'Tenure',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication;
        var displayText = '-';
        if (row.basicInfo?.tenure) {
          displayText = `${row.basicInfo?.tenure} Months`;
        }
        return displayText;
      },
      flex: 0.75,
    },

    {
      field: 'status',
      headerName: 'Status',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication;
        return <LosStatusPill status={row.applicationStatus} />;
      },
      flex: 1,
    },

    {
      field: 'createdAt',
      headerName: 'Created At',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication;
        if (row.createdAt) {
          return dayjs(row.createdAt).format('DD/MM/YYYY');
        }
        return '-';
      },
      flex: 1,
    },

    {
      field: 'agent Name',
      headerName: 'Agent Name',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication;
        if (row.agentName) {
          return row.agentName;
        }
        return (
          <LosButton
            text="Map Agent"
            size="small"
            onClick={() => {
              setShowMapAgentDialog({
                show: true,
                application: row,
              });
            }}
          />
        );
      },
      flex: 1,
    },
    {
      field: 'underwritingStatus',
      headerName: 'Underwriting',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication;

        const applicationStatus = row.applicationStatus;
        const isApplicationPending =
          applicationStatus == ApplicationStatus.PENDING;
        const isApplicationInProgress =
          applicationStatus == ApplicationStatus.IN_PROGRESS;
        const isApplicationRejected =
          applicationStatus == ApplicationStatus.REJECTED;

        const canShowStatusPill =
          isApplicationPending == false &&
          isApplicationInProgress == false &&
          isApplicationRejected == false;

        if (canShowStatusPill) {
          return <LosStatusPill status={row.underwritingStatus} />;
        }

        return '-';
      },
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication;
        var displayText = '';
        if (
          row.underwritingStatus == 'PENDING' &&
          row.applicationStatus == ApplicationStatus.APPROVED
        ) {
          displayText = 'Audit';
        } else {
          displayText = 'View';
        }
        return (
          <LosButton
            variant="outlined"
            size="small"
            text={displayText}
            onClick={() => {
              dispatch(setPreDisbursalDetails(row));
              viewAudit(row.applicationId);
            }}
          />
        );
      },
      flex: 1,
    },
  ];

  async function fetchApplications() {
    try {
      setLoading(true);
      var params: Partial<GetAllApplicationsForHQApiData> = {
        page: page,
        pageSize: pageSize,
        sortBy: 'createdAt',
        sortDir: 'desc',
        programId: LOS_PROGRAM_ID,
      };

      if (selectedFilter != 'ALL') {
        params.status = selectedFilter;
      }
      const response = await LosService.getAllApplicationsForHQ(params);
      setApplications(response.records);
      setPage(response.pageNumber);
      setRowCount(response.totalItems);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchApplications();
  }, [page, pageSize, selectedFilter]);

  const viewAudit = (applicationId: string) => {
    dispatch(setIsApplicationForHQ(true));
    navigateToApplicationReview(applicationId);
  };
  async function mapAgentToApplication(
    application: ColendedApplication,
    agent: Agent
  ) {
    try {
      const response = await LosService.assignAgentToApplication({
        applicationId: application.applicationId,
        agentId: agent.id,
      });
      setSnackbar('Agent Assigned');
      fetchApplications();
    } catch (error) {
      console.error(error);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }
  return (
    <>
      <SolfinAppBar
        title={'Loan Applications'}
        renderActions={() => {
          return (
            <Grid container alignItems="end" justifyContent={'end'}>
              <Grid item xs={4}>
                <Stack>
                  <LosSelect
                    label="Application Status"
                    options={filterOptions()}
                    key="status"
                    value={selectedFilter}
                    onChange={(e) => {
                      setSelectedFilter(e.target.value as string);
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          );
        }}
      />
      <div
        style={{
          height: 'auto',
          margin: '1rem',
        }}
      >
        <LosDataGrid
          data={applications}
          columns={COLUMN_DEF}
          idColumn={'applicationId'}
          page={page}
          pageSize={pageSize}
          loading={loading}
          rowCount={rowCount}
          onPageChange={(page) => setPage(page)}
        />
      </div>

      <MapAgentDialog
        open={showMapAgentDialog.show}
        onClose={function (): void {
          setShowMapAgentDialog({
            show: false,
            application: null,
          });
        }}
        onSubmit={function (agent: Agent): void {
          const application = showMapAgentDialog.application;
          if (!application) return;
          mapAgentToApplication(application, agent);
        }}
      />
    </>
  );
};
export default ListOfApplications;

const filterOptions = () => {
  const options = Object.values(ApplicationStatus).map((item) => {
    const option: LosSelectOption = {
      value: item,
      label: getApplicationStatusDisplayText(item),
    };
    return option;
  });
  const All = { value: 'ALL', label: 'All' };
  return [All, ...options];
};
