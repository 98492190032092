import { Box, Stack } from '@mui/material';
import { GREEN } from '../../../../../constants/style';
import { useRuleEngine } from '../../../../LMSAgent/Provider/RuleEngingProvider';
import FinancialList from './components/FinancialList';
import WeightScoreWrapper from '../../WeightScoreWrapper';

const FinancialRule = () => {
  const { loading, underWrittingScrore } = useRuleEngine();
  return (
    <>
      <WeightScoreWrapper
        value={
          underWrittingScrore?.underwritingFinancialScore
            ? underWrittingScrore?.underwritingFinancialScore.weightedPoints
            : 0
        }
      />

      <Box
        sx={{
          pb: 2,
        }}
      >
        <FinancialList />
      </Box>
    </>
  );
};

export default FinancialRule;
