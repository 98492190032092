import { Card, Grid, Stack } from '@mui/material';
import LosText from './common/LosText';

type Props = {
  name?: string;
  panNumber?: string;
  status?: string;
};

function PanDetailsVerifiedStatusCard({ name, panNumber, status }: Props) {
  return (
    <Card
      elevation={0}
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '0.62rem',
        borderRadius: '0.875rem',
        maxHeight: '167px',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        background: 'white',
      }}
    >
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent={'start'}
        spacing={'0.5rem'}
        style={{
          height: '4.5rem',
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <div
          style={{
            height: '2.104014rem',
            width: '3.1238rem',
          }}
        >
          <img
            src={require('../assets/income_tax_logo.png')}
            height={'100%'}
            width={'100%'}
          ></img>
        </div>
        <LosText text="KYC Details" />
      </Stack>
      <Grid
        container
        direction={'row'}
        justifyContent="start"
        style={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <Grid item xs={6}>
          <PanLabel label="Name" description={name} />
        </Grid>
      </Grid>

      <Grid
        container
        direction={'row'}
        justifyContent="start"
        style={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <Grid item xs={6}>
          <PanLabel label="Pan Number" description={panNumber} />
        </Grid>
        <Grid item xs={6}>
          <PanLabel label="Status" description={status} />
        </Grid>
      </Grid>
    </Card>
  );
}

export default PanDetailsVerifiedStatusCard;

const PanLabel = (data: { label: string; description?: string }) => {
  return (
    <Stack spacing={'0px'}>
      <LosText text={data.label} variant="caption" />
      <LosText text={data.description ?? '-'} fontSize={'10px'} />
    </Stack>
  );
};
