import {
  IApplicationsForSolfinProps,
  IPreDisburseApplicationProps,
} from '../../types/application';
import ApplicationsForAgent from '../components/Applications/ApplicationsForAgent';
import ApplicationReview, {
  ViewSource,
} from '../components/ApplicationReview/ApplicationReview';
import {
  LmsAgentApplicationRoute,
  useLmsAgentRouter,
} from '../Provider/LmsAgentRouter';

export interface IApplicationsProps {
  loading: boolean;
  page: number;
  totalPages: number;
  applicationData: IApplicationsForSolfinProps[];
}
export interface IPre_DisburseApplicationsProps {
  loading: boolean;
  page: number;
  totalPages: number;
  applicationData: IPreDisburseApplicationProps[];
}

const ApplicationsDashboard = () => {
  const { selectedRoute, navigateToApplicationHome } = useLmsAgentRouter();

  if (selectedRoute == LmsAgentApplicationRoute.Applications) {
    return <ApplicationsForAgent />;
  } else if (selectedRoute == LmsAgentApplicationRoute.ApplicationReview) {
    return <ApplicationReview viewSource={ViewSource.AgentApplications} />;
  }

  navigateToApplicationHome();

  return <></>;
};

export default ApplicationsDashboard;
