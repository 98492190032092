export const AllApplicationIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.60012 4.80001H10.4001M5.60012 7.20001H10.4001M5.60012 9.60001H8.00011M4.39993 1.60001H11.6001C12.4837 1.60001 13.2001 2.31637 13.2001 3.20004L13.1999 12.8C13.1999 13.6837 12.4835 14.4 11.5999 14.4L4.39986 14.4C3.5162 14.4 2.79986 13.6836 2.79987 12.8L2.79993 3.2C2.79993 2.31635 3.51628 1.60001 4.39993 1.60001Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const LeadIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.86653 14.4H3.66652C2.78286 14.4 2.06652 13.6836 2.06653 12.8L2.06659 3.2C2.0666 2.31635 2.78294 1.60001 3.66659 1.60001H10.8668C11.7504 1.60001 12.4668 2.31635 12.4668 3.20001V7.60001M9.26678 12.1333L10.7334 13.6L13.9334 10.3999M4.86678 4.80001H9.66678M4.86678 7.20001H9.66678M4.86678 9.60001H7.26678"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const OffersIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.19999 5.2H10.8M5.19999 8.4H10.8M3.83999 2H12.16C12.7344 2 13.2 2.53726 13.2 3.2V14L11.4667 12.8L9.73332 14L7.99999 12.8L6.26665 14L4.53332 12.8L2.79999 14V3.2C2.79999 2.53726 3.26561 2 3.83999 2Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const PreDisbursementIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.37153 14.3716H3.17153C2.28787 14.3716 1.57153 13.6552 1.57153 12.7715L1.5716 3.17159C1.5716 2.28793 2.28794 1.57159 3.1716 1.57159H10.3718C11.2554 1.57159 11.9718 2.28794 11.9718 3.17159V6.37159M4.37178 4.77159H9.17178M4.37178 7.17159H9.17178M4.37178 9.57159H6.77178M8.77166 12.1656L12.1658 8.7715L14.4285 11.0342L11.0344 14.4284H8.77166V12.1656Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ManageColendersIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66664 9.99997V8.22087M6.2222 9.99997V8.22087M9.77775 9.99997V8.22087M13.3333 9.99997V8.22087M1.59998 12.2666H14.4V14.4H1.59998V12.2666ZM1.59998 5.86664V4.44442L7.73696 1.59998L14.4 4.44442V5.86664H1.59998Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ActiveLoansIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.19997 14.4H3.19997C2.31631 14.4 1.59997 13.6836 1.59998 12.8L1.60004 3.20003C1.60004 2.31638 2.31639 1.60004 3.20004 1.60004H10.4002C11.2839 1.60004 12.0002 2.31638 12.0002 3.20004V6.40004M11.6 11.566V11.5239M4.40022 4.80004H9.20022M4.40022 7.20004H9.20022M4.40022 9.60004H6.80022M14.4 11.6C14.4 11.6 13.7359 13.5598 11.6 13.5256C9.46408 13.4913 8.79997 11.6 8.79997 11.6C8.79997 11.6 9.43715 9.60602 11.6 9.60602C13.7628 9.60602 14.4 11.6 14.4 11.6Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
