import React from 'react';
import VegaHeader from '../../../../../components/common/VegaHeader';
import { Typography } from '@mui/material';
import { toLowerCase } from '../../../../../constants/commonFunction';
import { VegaTextField } from '../../../../../components/common';
import { COMMON } from '../../../../../constants/style';

interface IProps {
  title: string;
  value: string;
  name: string;
  type?: string;
  isEdit: boolean;
  displayText?: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const AgentTextField = ({
  title,
  value,
  isEdit,
  name,
  onChange,
  type,
  displayText,
}: IProps) => {
  return (
    <>
      <VegaHeader text={title} />
      {isEdit ? (
        <VegaTextField
          isFixedHeight
          value={value ?? ''}
          onChange={onChange}
          key={name}
          type={type ?? 'text'}
          placeholder={`${toLowerCase(title)}`}
        />
      ) : (
        <Typography
          className="font-aspekta-400"
          color={COMMON.darkGray}
          fontSize={12}
        >
          {displayText ?? value}
        </Typography>
      )}
    </>
  );
};

export default AgentTextField;
