import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { COMMON } from '../../../../../../constants/style';
import { getApplicationsDetails } from '../../../../../../store/common/agentApplicationsSlice';
import { useAppSelector } from '../../../../../../store/hook';
import LOSSelectWrapper from '../../../../../components/LOSSelectWrapper';
import LOSTextFieldWrapper from '../../../../../components/LOSTextFieldWrapper';
import {
  ReasonType,
  agentReasonTypes,
  hqReasonTypes,
} from '../../../../../enums/applications';
import { IReasonType } from '../../ApplicationReview';

interface IProps {
  selectedReason: IReasonType;
  setSelectedReason: React.Dispatch<React.SetStateAction<IReasonType>>;
}

function AgentDetailsReject({ selectedReason, setSelectedReason }: IProps) {
  const { isApplicationForHQ } = useAppSelector(getApplicationsDetails);
  const handleChange = (name: keyof IReasonType, value: string) => {
    setSelectedReason({ ...selectedReason, [name]: value });
  };

  return (
    <Box
      sx={{
        bgcolor: '#FAFAFA',
        p: 2,
      }}
    >
      <Box>
        <Typography
          className="font-aspekta-400"
          color={COMMON.light}
          fontSize={12}
        >
          Select any one of the following reasons for rejecting the application
        </Typography>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <LOSSelectWrapper
              title="rejection reason"
              name="reason"
              isEdit={true}
              options={isApplicationForHQ ? hqReasonTypes : agentReasonTypes}
              value={selectedReason.reason}
              onChange={(value) => handleChange('reason', value)}
            />
          </Grid>
          {selectedReason.reason === ReasonType.OTHER_REASON_WITH_COMMENT && (
            <Grid item xs={12}>
              <LOSTextFieldWrapper
                title=""
                name="comment"
                isEdit={true}
                type="text"
                value={selectedReason.comment}
                onChange={(e) => handleChange('comment', e.target.value)}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default AgentDetailsReject;
