import React, { useEffect, useState } from 'react'
import { BORDER_GRAY, GREY, PRIMARY } from '../../../../constants/style'
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { StringUtility } from '../../../onboarding/utils/StringUtility'
import BureauDetailsTab from '../../../components/Bureau/BureauDetailsTab'
import { Close } from '@mui/icons-material'
import _ from 'lodash'
import { useSnackbar } from '../../../../providers/SnackbarProvider'
import { getErrorMessageFromErrorObj } from '../../../../utils/api'
import { LosService } from '../../../onboarding/api/LosService'
import LosText from '../../../onboarding/components/common/LosText'
import { CustomerApplication, UserType } from '../../../onboarding/types/Los'
import LosTabBar from '../../../onboarding/components/common/LosTabBar'
import LosTabBarItem from '../../../onboarding/components/common/LosTabBarItem'
import CollateralAndGuarantorDetailsPreview from '../../../onboarding/pages/ApplicationPreview/CollateralAndGuarantorDetailsPreview'
import CompanyDetailsPreview from '../../../onboarding/pages/ApplicationPreview/CompanyDetailsPreview'
import ElectricityDetailsPreview from '../../../onboarding/pages/ApplicationPreview/ElectricityDetailsPreview'
import FinancialDetailsPreview from '../../../onboarding/pages/ApplicationPreview/Financial/FinancialDetailsPreview'
import KycDetailsPreview from '../../../onboarding/pages/ApplicationPreview/Kyc/KycDetailsPreview'
import { ReadOnlyFormField } from '../Co-Lenders/components/Forms/ReadOnlyFormField'
import { Loan } from '../../types/Lms'
import { LoanManagementService } from '../../api/LoanManagementService'

type Props = {
  open: boolean
  onClose: () => void
  applicationId: string | null
}

const ReviewPredisubrsedApplicationForHq = ({
  open,
  onClose,
  applicationId,
}: Props) => {
  const { setSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)
  const [customerApplication, setCustomerApplication] =
    useState<CustomerApplication>()
  const [loadingLoan, setLoadingLoan] = useState<boolean>(false)
  const [loan, setLoan] = useState<Loan>()

  const [selectedTab, setSelectedTab] = useState<number>(0)

  const canShowCompanyTab = () => {
    const userType = customerApplication?.basicInfo.userType
    if (!userType) return false
    if (userType == UserType.INDIVIDUAL) return false
    return true
  }

  async function fetchApplication(applicationId: string) {
    try {
      setLoading(true)
      const response = await LosService.getApplicationById(applicationId)
      setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  async function fetchApplicationLoan(applicationId: string) {
    try {
      setLoadingLoan(true)
      const response = await LoanManagementService.getLoanByApplicationId(
        applicationId
      )
      setLoan(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoadingLoan(false)
    }
  }

  const userTypeDisplayText = () => {
    if (customerApplication?.basicInfo?.subType)
      return _.startCase(_.toLower(customerApplication?.basicInfo.subType))
    return '-'
  }

  const requiredLoanAmountDisplayText = () => {
    if (customerApplication?.basicInfo?.amount)
      return StringUtility.formatCurrency(customerApplication?.basicInfo.amount)
    return '-'
  }

  const approvedLoanAmountDisplayText = () => {
    if (loan?.loan_amount) return StringUtility.formatCurrency(loan.loan_amount)
    return '-'
  }

  const loanRoiDisplayText = () => {
    if (loan?.interest_rate) return `${loan.interest_rate}%`
    return '-'
  }

  useEffect(() => {
    if (open == true && applicationId != null) {
      fetchApplication(applicationId)
      fetchApplicationLoan(applicationId)
    }
  }, [open, applicationId])

  return (
    <Dialog fullWidth={true} maxWidth={'md'} onClose={onClose} open={open}>
      <div
        style={{
          backgroundColor: 'rgba(250, 250, 250, 1)',
          width: '100%',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent='space-between'
          alignItems={'center'}
          style={{
            paddingTop: '1.25rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <LosText text='Review Application' />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <div
          style={{
            padding: '1rem',
            width: '100%',
          }}
        >
          <Grid
            container
            rowGap={2}
            alignItems={'start'}
            style={{
              border: '1px solid #E1E4EB',
              padding: '1rem',
              borderRadius: '0.75rem',
              background: 'white',
              width: '100%',
            }}
          >
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Application Id'
                description={customerApplication?.id}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Name'
                description={customerApplication?.customerName}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='User Type'
                description={userTypeDisplayText()}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Loan Type'
                description={customerApplication?.basicInfo?.loanType ?? '-'}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Tenure'
                description={`${
                  customerApplication?.basicInfo?.tenure ?? '-'
                } Months`}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Required Loan Amount'
                description={requiredLoanAmountDisplayText()}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Approved Loan Amount'
                description={approvedLoanAmountDisplayText()}
                loading={loadingLoan}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Rate Of Interest'
                description={loanRoiDisplayText()}
                loading={loadingLoan}
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            margin: '1rem',
          }}
        >
          <LosTabBar
            value={selectedTab}
            onChange={(e, selected) => {
              setSelectedTab(selected)
            }}
          >
            <LosTabBarItem label='Kyc Details' />
            <LosTabBarItem label='Financial' />
            <LosTabBarItem label='Collateral & Guarntor' />
            <LosTabBarItem label='Electricity Details' />

            {canShowCompanyTab() && <LosTabBarItem label='Company Details' />}
          </LosTabBar>
        </div>
        <div
          style={{
            border: '1px solid #E1E4EB',
            padding: '1rem',
            margin: '1rem',
            borderRadius: '0.75rem',
            background: 'white',
            height: '40vh',
            overflowY: 'scroll',
          }}
        >
          {selectedTab == 0 && (
            <KycDetailsPreview
              userType={customerApplication?.basicInfo.userType}
              viewerType={'HQ'}
            />
          )}
          {selectedTab == 1 && <FinancialDetailsPreview />}
          {selectedTab == 2 && <CollateralAndGuarantorDetailsPreview />}
          {selectedTab == 3 && <ElectricityDetailsPreview />}
          {canShowCompanyTab() && selectedTab == 4 && <CompanyDetailsPreview />}
        </div>
      </div>
      <Stack
        direction={'row'}
        justifyContent='end'
        alignItems={'center'}
        style={{ padding: '1rem' }}
      ></Stack>
    </Dialog>
  )
}

export default ReviewPredisubrsedApplicationForHq
