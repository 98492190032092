import { createContext, useContext, useEffect, useState } from 'react';
import { LOS_PROGRAM_ID } from '../../onboarding/api/constants';
import { ColenderService, CreateColenderApiData } from '../api/ColenderService';
import { PolicyService } from '../api/PolicyService';
import {
  ColenderBankDetailsFormData,
  ColenderDetailsFormData,
  ColenderEligiblityFormData,
  ColenderIncomeSchemeFormData,
  ColenderInterestSchemeFormData,
} from '../components/Co-Lenders/components/Forms/formData';
import {
  Colender,
  ColenderFeeDetails,
  ColenderFeePolicy,
  ColendingPolicy,
  DEFAULT_COLENDER_CONFIGURATION,
  DEFAULT_COLENDER_FEE_DETAILS,
} from '../types/Lms';

type ColendersContextType = {
  colenders: Colender[];
  loading: boolean;
  selectedColender?: Colender;
  onColenderSelected: (colender: Colender) => void;

  createColender: (formData: Partial<ColenderDetailsFormData>) => Promise<void>;

  fetchColenderEligiblityScheme: (
    colenderId: string
  ) => Promise<Partial<ColenderEligiblityFormData>>;
  fetchColenderInterestScheme: (
    colenderId: string
  ) => Promise<Partial<ColenderInterestSchemeFormData>>;
  fetchColenderIncomeScheme: (
    colenderId: string
  ) => Promise<Partial<ColenderIncomeSchemeFormData>>;

  saveColenderEligiblityScheme: (data: {
    colenderId: string;
    formData: Partial<ColenderEligiblityFormData>;
  }) => Promise<void>;
  saveColenderIncomeScheme: (data: {
    colenderId: string;
    formData: Partial<ColenderIncomeSchemeFormData>;
  }) => Promise<void>;
  saveColenderInterestScheme: (data: {
    colenderId: string;
    formData: Partial<ColenderInterestSchemeFormData>;
  }) => Promise<void>;
};
const ColendersContext = createContext<ColendersContextType | null>(null);

export const useColenders = () =>
  useContext(ColendersContext) as ColendersContextType;

export const ColenderProvider = ({ children }: any) => {
  const [selectedColender, setSelectedColender] = useState<Colender>();
  const [colenders, setColenders] = useState<Colender[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [colendingPolicy, setColendingPolicy] = useState<ColendingPolicy>();
  const [colendingFeePolicy, setColendingFeePolicy] =
    useState<ColenderFeePolicy>();

  function _onColenderSelected(colender: Colender) {
    setSelectedColender(colender);
  }

  async function _fetchColendingPolicy(): Promise<ColendingPolicy> {
    try {
      const response = await PolicyService.getColendingPolicy({
        programId: LOS_PROGRAM_ID,
      });
      setColendingPolicy(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function _fetchColendingFeePolicy(): Promise<ColenderFeePolicy> {
    try {
      const response = await PolicyService.getColendingFeePolicy({
        programId: LOS_PROGRAM_ID,
      });
      setColendingFeePolicy(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function _fetchColenderEligiblityScheme(
    colenderId: string
  ): Promise<Partial<ColenderEligiblityFormData>> {
    try {
      const colendingPolicy = await _fetchColendingPolicy();
      const colendersEligiblityScheme =
        colendingPolicy.colender_configuration_list.filter((item) => {
          return item.colender_id === colenderId;
        });
      if (colendersEligiblityScheme.length == 1) {
        const firstScheme = colendersEligiblityScheme[0];
        const formData: Partial<ColenderEligiblityFormData> = {
          minLoanAmount: `${firstScheme.min_loan_amount}`,
          maxLoanAmount: `${firstScheme.max_loan_amount}`,
          minRateOfInterest: `${firstScheme.interest_rate}`,
          ageCriteria: `${firstScheme.filter.min_age}`,
          bureauCriteria: `${firstScheme.filter.min_cibil}`,
        };
        return formData;
      }
      throw new Error('Colenders Eligiblity Scheme not found');
    } catch (error) {
      throw error;
    }
  }

  async function _fetchColenderInterestScheme(
    colenderId: string
  ): Promise<Partial<ColenderInterestSchemeFormData>> {
    try {
      const colendingPolicy = await _fetchColendingPolicy();
      const colendersEligiblityScheme =
        colendingPolicy.colender_configuration_list.filter((item) => {
          return item.colender_id === colenderId;
        });
      if (colendersEligiblityScheme.length == 1) {
        const firstScheme = colendersEligiblityScheme[0];
        const formData: ColenderInterestSchemeFormData = {
          interestValue: `${firstScheme.interest_rate}`,
          interestType: firstScheme.interest_type,
          accrualMethod: firstScheme.day_count_method,
          presicion: `${firstScheme.precision_by_interest}`,
          repaymentFrequency: firstScheme.cycle_frequency,
        };
        return formData;
      }
      throw new Error('Colenders Interest Scheme not found');
    } catch (error) {
      throw error;
    }
  }

  async function _fetchColenderIncomeScheme(
    colenderId: string
  ): Promise<Partial<ColenderIncomeSchemeFormData>> {
    try {
      const feePolicy = await _fetchColendingFeePolicy();
      const fees = feePolicy.colender_fee_details_list ?? [];
      const lateFee = fees.filter((item) => item.fee_type == 'LATE_FEE') ?? [];
      const penalFee =
        fees.filter((item) => item.fee_type == 'PENAL_CHARGE') ?? [];

      const formData: Partial<ColenderIncomeSchemeFormData> = {};

      const primaryColender = getPrimaryColender(colendingPolicy);
      const primaryColenderId = primaryColender?.colender_id;
      if (!primaryColenderId) {
        throw new Error('Primary colender not found');
      }
      if (lateFee.length > 0) {
        const feeComponent =
          lateFee[0].colender_contributions.filter(
            (item) => item.colenderId == colenderId
          ) ?? [];
        if (feeComponent.length > 0) {
          formData.lateFee = feeComponent[0].percentage.toFixed(2);
        }
      }

      if (penalFee.length > 0) {
        const penalFeeComponent =
          penalFee[0].colender_contributions.filter(
            (item) => item.colenderId == colenderId
          ) ?? [];
        if (penalFeeComponent.length > 0) {
          formData.penalInterest = penalFeeComponent[0].percentage.toFixed(2);
        }
      }

      return formData;
    } catch (error) {
      throw error;
    }
  }

  const getColenderConfigurationForId = (
    colenderId: string,
    policy: ColendingPolicy
  ) => {
    const configs = policy.colender_configuration_list ?? [];
    const filtered = configs.filter((item) => item.colender_id === colenderId);
    if (filtered.length > 0) {
      return filtered[0];
    }
    return DEFAULT_COLENDER_CONFIGURATION;
  };

  const getPrimaryColender = (policy?: ColendingPolicy) => {
    const filteredColenders =
      policy?.colender_configuration_list.filter(
        (item) => item.is_primary_lender_configuration == true
      ) ?? [];
    if (filteredColenders?.length <= 0) {
      console.error('Primary Colender does not exist');
      return;
    }
    return filteredColenders[0];
  };

  const getFeeDetailsFromPolicy = (data: {
    feeType: string;
    policy: ColenderFeePolicy;
  }) => {
    const existingFeeList = data.policy.colender_fee_details_list ?? [];

    const filteredPolicy =
      existingFeeList.filter((item) => item.fee_type == data.feeType) ?? [];
    if (filteredPolicy.length > 0) {
      return filteredPolicy[0];
    }

    const defaultFeeDetail = { ...DEFAULT_COLENDER_FEE_DETAILS };
    defaultFeeDetail.fee_type = data.feeType;
    return defaultFeeDetail;
  };

  async function _saveColenderIncomeScheme(data: {
    colenderId: string;
    formData: Partial<ColenderIncomeSchemeFormData>;
  }): Promise<void> {
    try {
      const lateFeeValue = data.formData.lateFee ?? '';
      const penalFeeValue = data.formData.penalInterest ?? '';

      if (lateFeeValue.length <= 0 || penalFeeValue.length <= 0) {
        console.error(
          `Late fee and Penal Interest are required ` + data.formData
        );
        return;
      }

      const lateFeeNumberValue = Number(lateFeeValue);
      const penalFeeNumberValue = Number(penalFeeValue);
      const feePolicy = await _fetchColendingFeePolicy();
      const updatedPolicy = { ...feePolicy };
      const primaryColender = getPrimaryColender(colendingPolicy);
      if (!primaryColender) {
        throw new Error('Primary colender not found');
      }
      const primaryColenderId = primaryColender.colender_id;
      const lateFee = getFeeDetailsFromPolicy({
        feeType: 'LATE_FEE',
        policy: updatedPolicy,
      });

      const updatedFeeDetails = modifyFeeDetails({
        fee: lateFee,
        primaryColenderId: primaryColenderId,
        currentColenderId: data.colenderId,
        currentColenderPercentage: lateFeeNumberValue,
      });

      const penalFee = getFeeDetailsFromPolicy({
        feeType: 'PENAL_CHARGE',
        policy: updatedPolicy,
      });

      const updatedPenalDetails = modifyFeeDetails({
        fee: penalFee,
        primaryColenderId: primaryColenderId,
        currentColenderId: data.colenderId,
        currentColenderPercentage: penalFeeNumberValue,
      });

      const existingFeeList = updatedPolicy.colender_fee_details_list ?? [];
      const updatedFeeList = existingFeeList.filter((item) => {
        if (item.fee_type == 'LATE_FEE' || item.fee_type == 'PENAL_CHARGE')
          return false;
        return true;
      });
      updatedFeeList.push(updatedFeeDetails);
      updatedFeeList.push(updatedPenalDetails);

      updatedPolicy.colender_fee_details_list = updatedFeeList;

      const response = await PolicyService.saveColendingFeePolicy({
        programId: LOS_PROGRAM_ID,
        policy: updatedPolicy,
      });
    } catch (error) {
      throw error;
    }
  }

  async function _saveColenderEligiblityScheme(data: {
    colenderId: string;
    formData: Partial<ColenderEligiblityFormData>;
  }): Promise<void> {
    try {
      const colendingPolicy = await _fetchColendingPolicy();
      const updatedPolicy = { ...colendingPolicy };
      const mappedConfiguration = getColenderConfigurationForId(
        data.colenderId,
        colendingPolicy
      );
      const minLoanAmount = Number(data.formData.minLoanAmount);
      const maxLoanAmount = Number(data.formData.maxLoanAmount);
      const minRateOfInterest = Number(data.formData.minRateOfInterest);
      const minAge = Number(data.formData.ageCriteria);
      const minCibil = Number(data.formData.bureauCriteria);
      mappedConfiguration.colender_id = data.colenderId;
      mappedConfiguration.program_id = LOS_PROGRAM_ID;
      mappedConfiguration.min_loan_amount = minLoanAmount;
      mappedConfiguration.max_loan_amount = maxLoanAmount;
      mappedConfiguration.minimum_interest_rate = minRateOfInterest;
      mappedConfiguration.filter.min_age = minAge;
      mappedConfiguration.filter.min_cibil = minCibil;

      const existingConfigurations =
        updatedPolicy.colender_configuration_list ?? [];
      const updatedConfiguration = existingConfigurations.filter(
        (item) => item.colender_id != data.colenderId
      );
      updatedConfiguration.push(mappedConfiguration);

      updatedPolicy.colender_configuration_list = updatedConfiguration;

      const response = await PolicyService.saveColendingPolicy({
        programId: LOS_PROGRAM_ID,
        policy: updatedPolicy,
      });
      setColendingPolicy(response);
    } catch (error) {
      throw error;
    }
  }

  async function _saveColenderInterestScheme(data: {
    colenderId: string;
    formData: Partial<ColenderInterestSchemeFormData>;
  }) {
    try {
      const colendingPolicy = await _fetchColendingPolicy();
      const updatedPolicy = { ...colendingPolicy };
      const mappedConfiguration = getColenderConfigurationForId(
        data.colenderId,
        colendingPolicy
      );

      const intrestValue = Number(data.formData.interestValue);
      const precision = Number(data.formData.presicion);
      const interestType = data.formData.interestType;
      const repaymentFrequency = data.formData.repaymentFrequency;
      const accrual = data.formData.accrualMethod;

      if (!interestType || !repaymentFrequency || !accrual) {
        console.log('====================================');
        console.log({
          interestType: interestType,
          repaymentFrequency: repaymentFrequency,
          accrual: accrual,
        });
        console.log('====================================');
        return;
      }
      mappedConfiguration.colender_id = data.colenderId;
      mappedConfiguration.program_id = LOS_PROGRAM_ID;
      mappedConfiguration.interest_type = interestType;
      mappedConfiguration.interest_rate = intrestValue;
      mappedConfiguration.precision_by_interest = precision;
      mappedConfiguration.cycle_frequency = repaymentFrequency;
      mappedConfiguration.day_count_method = accrual;

      const existingConfigurations =
        updatedPolicy.colender_configuration_list ?? [];
      const updatedConfiguration = existingConfigurations.filter(
        (item) => item.colender_id != data.colenderId
      );
      mappedConfiguration.is_primary_lender_configuration = false;
      updatedConfiguration.push(mappedConfiguration);

      updatedPolicy.colender_configuration_list = updatedConfiguration;

      const response = await PolicyService.saveColendingPolicy({
        programId: LOS_PROGRAM_ID,
        policy: updatedPolicy,
      });
      setColendingPolicy(response);
    } catch (error) {
      throw error;
    }
  }

  async function _fetchColenders() {
    try {
      setLoading(true);
      const response = await ColenderService.getColenders(LOS_PROGRAM_ID);
      setColenders(response);
      if (response.length > 0) setSelectedColender(response[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function _createColender(
    data: Partial<ColenderBankDetailsFormData>
  ): Promise<Colender> {
    try {
      const params: Partial<CreateColenderApiData> = {
        programId: LOS_PROGRAM_ID,
        name: data.colenderName,
        panNo: data.pan,
        cin: data.cin,
        tin: data.tin,
        address: data.address,
        city: data.city,
        state: data.state,
        pincode: data.pincode,
      };
      if (
        data.dateOfRegistration != null &&
        data.dateOfRegistration.length > 0
      ) {
        params.registrationDate = new Date(data.dateOfRegistration);
      }
      const response = await ColenderService.createColender(params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function _saveColenderDetails(
    formData: Partial<ColenderDetailsFormData>
  ): Promise<void> {
    try {
      const bankDetails = formData.bankDetails;
      const eligiblityDetails = formData.eligiblityDetails;
      const incomeSchemeDetails = formData.incomeSchemeDetails;
      const intresetSchemeDetails = formData.interestSchemeDetails;
      if (
        !bankDetails ||
        !eligiblityDetails ||
        !incomeSchemeDetails ||
        !intresetSchemeDetails
      )
        return;
      const createdColender = await _createColender(bankDetails);
      const eligiblityScheme = await _saveColenderEligiblityScheme({
        colenderId: createdColender.id,
        formData: eligiblityDetails,
      });
      const incomeScheme = await _saveColenderIncomeScheme({
        colenderId: createdColender.id,
        formData: incomeSchemeDetails,
      });
      const intresetScheme = await _saveColenderInterestScheme({
        colenderId: createdColender.id,
        formData: intresetSchemeDetails,
      });
      _fetchColenders();
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    _fetchColenders();
    _fetchColendingPolicy();
  }, []);

  return (
    <ColendersContext.Provider
      value={{
        colenders: colenders,
        selectedColender: selectedColender,
        loading: loading,
        onColenderSelected: _onColenderSelected,
        fetchColenderEligiblityScheme: _fetchColenderEligiblityScheme,
        fetchColenderInterestScheme: _fetchColenderInterestScheme,
        fetchColenderIncomeScheme: _fetchColenderIncomeScheme,
        saveColenderEligiblityScheme: _saveColenderEligiblityScheme,
        saveColenderIncomeScheme: _saveColenderIncomeScheme,
        saveColenderInterestScheme: _saveColenderInterestScheme,
        createColender: _saveColenderDetails,
      }}
    >
      {children}
    </ColendersContext.Provider>
  );
};

const modifyFeeDetails = (data: {
  fee: ColenderFeeDetails;
  currentColenderId: string;
  primaryColenderId: string;
  currentColenderPercentage: number;
}) => {
  const updatedFeeDetails = { ...data.fee };

  updatedFeeDetails.colender_contributions = [
    {
      colenderId: data.currentColenderId,
      percentage: data.currentColenderPercentage,
    },
    {
      colenderId: data.primaryColenderId,
      percentage: 100 - data.currentColenderPercentage,
    },
  ];

  return updatedFeeDetails;
};
