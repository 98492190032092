import LoanLedgerDataGrid from '../../../../components/LoanLedger/LoanLedgerDataGrid';
import { useActiveLoans } from '../../../providers/ActiveLoansProvider';

function LoanRepaymentScheduleTab() {
  const { selectedLoan } = useActiveLoans();

  return (
    <div
      style={{
        paddingTop: '1rem',
        paddingBottom: '1rem',
      }}
    >
      <LoanLedgerDataGrid loanId={selectedLoan?.id} />
    </div>
  );
}

export default LoanRepaymentScheduleTab;
