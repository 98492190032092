import { Grid } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import LosFormInputField from '../../components/common/LosFormInputField'
import LosSelect, { LosSelectOption } from '../../components/common/LosSelect'
import LosSlider from '../../components/common/LosSlider'
import LosText from '../../components/common/LosText'
import LosTextField from '../../components/common/LosTextField'
import LosAppBar from '../../components/LosAppBar'
import { LosFormButtonView } from '../../components/LosFormButton'
import LosFormContainer from '../../components/LosFormContainer'
import { useLos } from '../../providers/LosProvider'
import {
  CustomerApplicationBasicInfo,
  LoanType,
  UserType,
} from '../../types/Los'
import { StringUtility } from '../../utils/StringUtility'
import { LosService } from '../../api/LosService'

enum InputType {
  USER_TYPE,
  USER_SUB_TYPE,
  LOAN_TYPE,
  TENURE,
  LOAN_AMOUNT,
  EMAIL,
}

function BasicDetails() {
  const {
    navigateToNextStep,
    saveApplicationFormData,
    currentStepMetaData,
    individualSubTypes,
    nonIndividualSubTypes,
    userTypes,
    loanTypes,
    customerApplication,
  } = useLos()
  const [formData, setFormData] = useState<CustomerApplicationBasicInfo>({
    userType: '',
    loanType: 'SOLAR',
    tenure: 0,
    amount: 0,
    subType: '',
    email: '',
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setErrors] = useState<Partial<CustomerApplicationBasicInfo>>({})

  function handleUpdate(type: InputType, value: string) {
    const updatedErrors = { ...error }
    switch (type) {
      case InputType.USER_TYPE:
        setFormData(prev => {
          return {
            ...prev,
            userType: value,
            subType: '',
          }
        })
        break
      case InputType.LOAN_TYPE:
        setFormData(prev => {
          return {
            ...prev,
            loanType: value,
          }
        })
        break
      case InputType.USER_SUB_TYPE:
        setFormData(prev => {
          return {
            ...prev,
            subType: value,
          }
        })
        break
      case InputType.EMAIL:
        const validEmail = StringUtility.validateEmail(value)
        if (validEmail == false) {
          updatedErrors.email = 'Please enter a valid email'
        } else {
          delete updatedErrors.email
        }
        setFormData(prev => {
          return {
            ...prev,
            email: value,
          }
        })
        break
      case InputType.LOAN_AMOUNT:
        const validValue = value.replaceAll(',', '')
        let numberValue = Number(validValue)
        setFormData(prev => {
          return {
            ...prev,
            amount: numberValue,
          }
        })
        break
    }
    setErrors(updatedErrors)
  }

  function handleTenureUpdate(value: number) {
    setFormData(prev => {
      return {
        ...prev,
        tenure: value,
      }
    })
  }

  const isInputValid = () => {
    let isUserTypeValid = (formData.userType ?? '').length > 0
    var isCompanyTypeValid = true
    if (formData.userType == UserType.NON_INDIVIDUAL) {
      isCompanyTypeValid = (formData.subType ?? '').length > 0
    }
    let isLoanTypeValid = (formData.loanType ?? '').length > 0
    let isLoanAmountValid = formData.amount > 0
    let isTenureValid = formData.tenure > 0
    let isEmailValid =
      (formData.email ?? '').length > 0 &&
      StringUtility.validateEmail(formData.email ?? '')
    return (
      isUserTypeValid &&
      isLoanAmountValid &&
      isCompanyTypeValid &&
      isLoanTypeValid &&
      isTenureValid &&
      isEmailValid
    )
  }

  async function onSubmit() {
    console.log('formData', formData)
    try {
      setLoading(true)
      await saveApplicationFormData(formData)
      // if (formData.subType === 'SOLE_PROPRIETOR' && customerApplication) {
      //   // await LosService.updateNextStep(customerApplication?.id)
      //   // const response = await LosService.updateNextStep(
      //   //   customerApplication?.id
      //   // )
      //   // await LosService.updateUserNextStep(response.users[0])
      //   // await LosService.updateUserNextStep(response.users[0])
      // }
      navigateToNextStep()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const getUserSubTypeOptions = () => {
    if (formData.userType == UserType.INDIVIDUAL) {
      return companyTypeOptions(individualSubTypes)
    } else if (formData.userType == UserType.NON_INDIVIDUAL) {
      return companyTypeOptions(nonIndividualSubTypes)
    }
    return []
  }

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            text='Proceed to KYC'
            onClick={() => onSubmit()}
            disabled={isInputValid() == false}
            loading={loading}
          />
        )
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        )
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <LosFormInputField label='email'>
            <LosTextField
              value={formData.email}
              onChange={e => {
                const value = e.target.value as string
                handleUpdate(InputType.EMAIL, value)
              }}
              error={!!error.email}
              helperText={error.email}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label='User Type'>
            <LosSelect
              options={userTypeOptions(userTypes)}
              value={formData.userType}
              onChange={e => {
                handleUpdate(InputType.USER_TYPE, e.target.value as string)
              }}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label='User Sub-Type'>
            <LosSelect
              options={getUserSubTypeOptions()}
              value={formData.subType}
              onChange={e => {
                handleUpdate(InputType.USER_SUB_TYPE, e.target.value as string)
              }}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label='Loan Type'>
            <LosSelect
              options={loanTypeOptions(['SOLAR'])}
              value={formData.loanType}
              onChange={e => {
                handleUpdate(InputType.LOAN_TYPE, e.target.value as string)
              }}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label='Required Loan Amount'>
            <LosTextField
              value={
                formData.amount > 0
                  ? StringUtility.formatCurrency(formData.amount)
                  : ''
              }
              onChange={e => {
                const value = e.target.value as string
                const validValue = value.replaceAll(',', '')
                let numberValue = Number(validValue)
                if (isNaN(numberValue)) {
                  return
                }
                handleUpdate(InputType.LOAN_AMOUNT, value)
              }}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField
            label='Tenure'
            endAdornment={
              <LosText
                text={`${formData.tenure} Months`}
                style={{
                  color: '#161616',
                  fontSize: '0.625rem',
                  fontWeight: 700,
                  lineHeight: '124%',
                  letterSpacing: '0.04375rem',
                  textTransform: 'uppercase',
                }}
              />
            }
          >
            <div
              style={{
                marginLeft: '0.75rem',
                marginRight: '2rem',
              }}
            >
              <LosSlider
                min={0}
                max={120}
                marks
                step={2}
                value={formData.tenure}
                onChange={(e, value) => {
                  handleTenureUpdate(value as number)
                }}
              />
            </div>
          </LosFormInputField>
        </Grid>
      </Grid>
    </LosFormContainer>
  )
}

export default BasicDetails

const userTypeOptions = (userTypes: string[]) =>
  userTypes.map(item => {
    var displayText = ''
    if (item == 'NON_INDIVIDUAL') {
      displayText = 'Non-Individual'
    } else {
      displayText = _.startCase(_.toLower(item))
    }
    const option: LosSelectOption = {
      value: item,
      label: displayText,
    }
    return option
  })

const companyTypeOptions = (userSubTypes: string[]) =>
  userSubTypes.map(item => {
    const option: LosSelectOption = {
      value: item,
      label: userSubTypeLabel(item),
    }
    return option
  })

const userSubTypeLabel = (type: string) => {
  if (type == 'HUF') return '​​​Hindu Undivided Family'
  else if (type == 'RWA') return 'Resident Welfare Association'
  else if (type == 'PVT_LTD') return 'Private Limited'
  else if (type == 'LLP') return 'Limited Liability Partnership'
  else if (type == 'PARTNERSHIP') return 'Partnership Firm'
  return _.startCase(_.toLower(type))
}

const loanTypeOptions = (loanTypes: string[]) => {
  return loanTypes.map(item => {
    var displayText = ''
    if (item == LoanType.SOLAR) {
      displayText = 'Solar Financing'
    } else if (item == LoanType.COMMERCIAL) {
      displayText = 'MSME Loan'
    } else if (item == LoanType.PERSONAL) {
      displayText = 'Personal Loan'
    } else {
      displayText = _.startCase(_.toLower(item))
    }
    const option: LosSelectOption = {
      value: item,
      label: displayText,
    }
    return option
  })
}
