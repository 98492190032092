import { Autocomplete, AutocompleteProps, styled } from '@mui/material';
import './../../../../components/common/v2/Styles/__styles.css';
import LosTextField from './LosTextField';

export type LosAutoCompleteOption = {
  value: string;
  label: string;
};

interface LosAutoCompleteProps
  extends Omit<
    AutocompleteProps<any, any, any, any>,
    'renderInput' | 'options'
  > {
  isValid?: boolean;
  options: LosAutoCompleteOption[];
}

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
    fontFamily: 'Aspekta !important',
    fontWeight: 450,
    fontSize: '13px',
    lineHeight: '120%',
    color: 'rgba(58, 74, 95, 1)',
  },
}));

function LosAutoCompleteField({
  isValid,
  options,
  ...rest
}: LosAutoCompleteProps) {
  return (
    <StyledAutocomplete
      disablePortal
      id="auto-complete"
      {...rest}
      options={options}
      renderInput={(params) => <LosTextField isValid={isValid} {...params} />}
    />
  );
}

export default LosAutoCompleteField;
