import { useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  renderSubmitButton?: () => ReactNode;
  renderAppBar?: () => ReactNode;
};

function LosFormContainer({
  children,
  renderSubmitButton,
  renderAppBar,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 100,
          width: '100%',
          backgroundColor: 'white',
          ...(isMobile && {
            backgroundColor: 'white',
          }),
        }}
      >
        {renderAppBar?.()}
      </div>
      <div
        style={{
          flex: 1,
          overflowY: 'auto',
          display: 'flex',
          justifyContent: 'center',

          width: '100%',
        }}
      >
        <div
          className="no-scrollbar"
          style={{
            width: '100%',
            overflow: 'auto',
            maxWidth: '25rem',
            ...(isMobile && {
              padding: '24px',
            }),
          }}
        >
          {children}
        </div>
      </div>
      <div
        style={{
          position: 'sticky',
          bottom: 0,
          zIndex: 100,
          width: '100%',
          scrollbarColor: 'transparent transparent',
          backgroundColor: 'white',
          ...(isMobile && {
            backgroundColor: 'white',
          }),
        }}
      >
        {renderSubmitButton?.()}
      </div>
    </div>
  );
}

export default LosFormContainer;
