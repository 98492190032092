import { Grid, IconButton, Stack } from '@mui/material';
import { useState } from 'react';
import { CloseDialogIcon } from '../../icons/LosIcons';
import LosButton from '../common/LosButton';
import LosDialog from '../common/LosDialog';
import LosFormInputField from '../common/LosFormInputField';
import LosText from '../common/LosText';
import LosTextField from '../common/LosTextField';
import LosOnboardingButton from '../common/LosOnboardingButton';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (aadharNumber: string) => void;
};
function AadharNumberInputDialog({ open, onClose, onSubmit }: Props) {
  const [aadharNumber, setAadharNumber] = useState<string>('');

  const isInputValid = () => {
    return aadharNumber.length == 12;
  };

  function onSubmitClick() {
    if (isInputValid()) {
      onSubmit(aadharNumber);
      onCloseClick();
    }
  }

  function onCloseClick() {
    setAadharNumber('');
    onClose();
  }

  return (
    <LosDialog open={open} onClose={onCloseClick}>
      <div
        style={{
          maxWidth: '22.5rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
        }}
      >
        <Stack
          direction={'row'}
          alignItems="start"
          justifyContent={'space-between'}
        >
          <Stack>
            <LosText text="Verify Aadhaar" />
            <LosText
              text="We will send you a verification OTP on your Aadhaar registered mobile number."
              variant="caption"
            />
          </Stack>
          <IconButton onClick={onCloseClick}>
            <CloseDialogIcon />
          </IconButton>
        </Stack>

        <Grid container>
          <Grid item xs={12}>
            <LosFormInputField label={'Aadhaar No'}>
              <LosTextField
                fullWidth
                value={aadharNumber}
                onChange={(e) => {
                  if (e.target.value.length > 12) return;
                  setAadharNumber(e.target.value);
                }}
              />
            </LosFormInputField>
          </Grid>
        </Grid>

        <LosOnboardingButton
          fullWidth
          text="Verify Aadhaar"
          disabled={isInputValid() == false}
          onClick={onSubmitClick}
        />
      </div>
    </LosDialog>
  );
}

export default AadharNumberInputDialog;
