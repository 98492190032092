import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { ReadOnlyFormField } from '../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField';
import LosText from '../../onboarding/components/common/LosText';

function LoanDetails() {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField
              title="Scheme master"
              description="GLG472SD3SZCX"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField
              title="Approved loan amount"
              description="₹ 1,50,000"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField
              title="Approved Tenure"
              description="12 Months"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField
              title="Approved Rate of Interest"
              description="12.3%"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Processing Fees" description="1.3%" />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Close date" description="01/03/2026" />
          </Grid>
        </Grid>
      </div>
      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '1rem',
        }}
      >
        <Typography className="font-aspekta-500" fontSize={14}>
          Branch Details
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="branch" description="Pune" />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField
              title="Branch Name"
              description="State Bank Of India"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField
              title="Existing Customer"
              description="No (New) "
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Customer type" description="Individual" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default LoanDetails;
