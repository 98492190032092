import { Grid } from '@mui/material';
import { useState } from 'react';
import LosFileSelect from '../../components/common/LosFileSelect';
import LosFormInputField from '../../components/common/LosFormInputField';
import LosTextField from '../../components/common/LosTextField';
import LosAppBar from '../../components/LosAppBar';
import { LosFormButtonView } from '../../components/LosFormButton';
import LosFormContainer from '../../components/LosFormContainer';
import { useLos } from '../../providers/LosProvider';
import { BankFormData } from '../../types/Los';

enum InputType {
  ACCOUNT_HOLDER_NAME,
  ACCOUNT_NUMBER,
  ISFC_CODE,
  BRANCH,
  CANCELLED_CHEQUE,
  BANK_NAME,
}

function IndividualBankDetails() {
  const { navigateToNextStep, saveBankDetails } = useLos();
  const [formData, setFormData] = useState<BankFormData>({
    accountHolderName: '',
    accountNumber: '',
    isfcCode: '',
    branch: '',
    cancelledCheque: null,
    bankName: '',
  });
  const [errors, setErrors] = useState<Partial<BankFormData>>({});
  const [loading, setLoading] = useState<boolean>(false);

  function handleFileUpdate(value: File | null) {
    setFormData((prev) => {
      return {
        ...prev,
        cancelledCheque: value,
      };
    });
  }

  function handleUpdate(type: InputType, value: string) {
    const updatedErrors: Partial<BankFormData> = { ...errors };
    switch (type) {
      case InputType.ACCOUNT_HOLDER_NAME:
        if (value.length <= 0) {
          updatedErrors.accountHolderName = 'Account Holder Name is required';
        } else {
          delete updatedErrors.accountHolderName;
        }
        setFormData((prev) => {
          return {
            ...prev,
            accountHolderName: value,
          };
        });
        break;
      case InputType.ACCOUNT_NUMBER:
        if (value.length <= 0) {
          updatedErrors.accountNumber = 'Account Number is required';
        } else {
          delete updatedErrors.accountNumber;
        }
        setFormData((prev) => {
          return {
            ...prev,
            accountNumber: value,
          };
        });
        break;
      case InputType.ISFC_CODE:
        if (value.length <= 0) {
          updatedErrors.isfcCode = 'ISFC Code is required';
        } else {
          delete updatedErrors.isfcCode;
        }
        setFormData((prev) => {
          return {
            ...prev,
            isfcCode: value,
          };
        });
        break;
      case InputType.BRANCH:
        if (value.length <= 0) {
          updatedErrors.branch = 'Branch Name is required';
        } else {
          delete updatedErrors.branch;
        }
        setFormData((prev) => {
          return {
            ...prev,
            branch: value,
          };
        });
        break;
      case InputType.BANK_NAME:
        if (value.length <= 0) {
          updatedErrors.bankName = 'Bank Name is required';
        } else {
          delete updatedErrors.bankName;
        }
        setFormData((prev) => {
          return {
            ...prev,
            bankName: value,
          };
        });
    }
    setErrors(updatedErrors);
  }

  const isInputValid = () => {
    const isNameValid = formData.accountNumber.length > 0;
    const isNumberValid = formData.accountNumber.length > 0;
    const isIsfcCodeValid = formData.isfcCode.length > 0;
    const isBranchValid = formData.branch.length > 0;
    const isCancelledChequeValid = formData.cancelledCheque != null;
    return (
      isNameValid &&
      isNumberValid &&
      isIsfcCodeValid &&
      isBranchValid &&
      isBranchValid &&
      isCancelledChequeValid
    );
  };

  async function onSubmit() {
    try {
      setLoading(true);
      await saveBankDetails(formData);
      navigateToNextStep();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            text="Submit"
            onClick={onSubmit}
            disabled={isInputValid() == false}
            loading={loading}
          />
        );
      }}
      renderAppBar={() => {
        return <LosAppBar currentStepLabel={'Bank Details'} hideProgressBar />;
      }}
    >
      <Grid container rowSpacing={'2rem'}>
        <Grid item xs={12}>
          <LosFormInputField label="Account Holder Name">
            <LosTextField
              value={formData.accountHolderName}
              onChange={(e) =>
                handleUpdate(InputType.ACCOUNT_HOLDER_NAME, e.target.value)
              }
              error={!!errors.accountHolderName}
              helperText={errors.accountHolderName}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label="Bank Name">
            <LosTextField
              value={formData.bankName}
              onChange={(e) =>
                handleUpdate(InputType.BANK_NAME, e.target.value)
              }
              error={!!errors.bankName}
              helperText={errors.bankName}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label="Account NO.">
            <LosTextField
              type={'number'}
              value={formData.accountNumber}
              onChange={(e) =>
                handleUpdate(InputType.ACCOUNT_NUMBER, e.target.value)
              }
              error={!!errors.accountNumber}
              helperText={errors.accountNumber}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label="ISFC CODE">
            <LosTextField
              value={formData.isfcCode}
              onChange={(e) =>
                handleUpdate(InputType.ISFC_CODE, e.target.value)
              }
              error={!!errors.isfcCode}
              helperText={errors.isfcCode}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label="Branch">
            <LosTextField
              value={formData.branch}
              onChange={(e) => handleUpdate(InputType.BRANCH, e.target.value)}
              error={!!errors.branch}
              helperText={errors.branch}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label="Upload Cancelled Cheque">
            <LosFileSelect
              placeholder={'Upload Cancelled cheque'}
              onFileSelect={function (file: File): void {
                handleFileUpdate(file);
              }}
            />
          </LosFormInputField>
        </Grid>
      </Grid>
    </LosFormContainer>
  );
}

export default IndividualBankDetails;
