import { Tab, TabProps } from '@mui/material';
import "./../../../../components/common/v2/Styles/__styles.css"
interface LosTabBarItemProps extends TabProps {}

function LosTabBarItem({ ...rest }: LosTabBarItemProps) {
  const { color = 'rgba(16, 71, 220, 1)' } = rest;
  return (
    <Tab
      sx={{
        '&.Mui-selected': {
          color: { color },
        },
        color: '#3A4A5F',
        fontSize: '.8125rem',
        fontFamily: 'Aspekta !important',
        fontWeight: 700,
        textTransform: 'none',
      }}
      {...rest}
    />
  );
}

export default LosTabBarItem;
