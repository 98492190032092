import * as CryptoJS from 'crypto-js';

export class EncryptionUtils {
  static decrypt(encryptedData: string, key: string): string {
    const AES_PADDING = CryptoJS.pad.Pkcs7;

    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      CryptoJS.enc.Base64.parse(key),
      {
        mode: CryptoJS.mode.ECB,
        padding: AES_PADDING,
      }
    );
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
