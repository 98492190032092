import { InputAdornment } from '@mui/material';
import { ReactNode } from 'react';
import { LessOrEqual } from '../../../../../../components/icon/Icons';
import LosFormInputField from '../../../../../onboarding/components/common/LosFormInputField';
import LosSelect, {
  LosSelectOption,
} from '../../../../../onboarding/components/common/LosSelect';
import LosTextField from '../../../../../onboarding/components/common/LosTextField';

export const EditFormFiled = (data: {
  title: string;
  description?: string;
  onChange: (value?: string) => void;
  options?: LosSelectOption[];
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
}) => {
  const isSelectField = (data.options ?? []).length > 0;
  return (
    <LosFormInputField label={data.title}>
      {isSelectField && (
        <LosSelect
          options={data.options}
          value={data.description}
          onChange={(e) => {
            data.onChange(e.target.value as string);
          }}
        />
      )}
      {isSelectField == false && (
        <LosTextField
          value={data.description}
          onChange={(e) => data.onChange(e.target.value)}
          InputProps={{
            ...(data.startAdornment && {
              startAdornment: (
                <InputAdornment position="start">
                  {data.startAdornment}
                </InputAdornment>
              ),
            }),
            ...(data.endAdornment && {
              endAdornment: (
                <InputAdornment position="end">
                  {data.endAdornment}
                </InputAdornment>
              ),
            }),
          }}
        />
      )}
    </LosFormInputField>
  );
};
