import React from 'react';
import { Typography, Skeleton } from '@mui/material';
import { COMMON } from '../../constants/style';
import VegaHeader from '../../components/common/VegaHeader';

interface IProps {
  header: string;
  text: string | number;
  loading: boolean;
}

const LOSTextWrapper = ({ text, loading, header }: IProps) => {
  return (
    <>
      <VegaHeader text={header} />
      {loading ? (
        <Skeleton />
      ) : (
        <Typography
          className="font-aspekta-400"
          color={COMMON.darkGray}
          fontSize={12}
        >
          {text ? text : '--'}
        </Typography>
      )}
    </>
  );
};

export default LOSTextWrapper;
