import { Grid, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { getApplicationsDetails } from '../../../../store/common/agentApplicationsSlice'
import { useAppSelector } from '../../../../store/hook'
import { LosService } from '../../api/LosService'
import LosAppBar from '../../components/LosAppBar'
import LosFormContainer from '../../components/LosFormContainer'
import LosText from '../../components/common/LosText'
import { EstampData, EstampStatus, UserDataDto } from '../../types/Los'
import UserDetailViewOnlyCard from './UserDetailViewOnlyCard'
import { useLos } from '../../providers/LosProvider'

function AgreementSigningCompletedPage() {
  const [userDetails, setUserDetails] = useState<UserDataDto[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [estampData, setEstampData] = useState<EstampData[]>([])
  const { customerApplication, navigateToNextStep } = useLos()

  const getUserDetailsApi = async () => {
    try {
      setLoading(true)
      if (!customerApplication) {
        setLoading(false)
        return
      }
      const response = await LosService.getUserDetails(customerApplication.id)
      const users = getUsers(response)
      setUserDetails(users)
      return response
    } catch (error) {
      return error
    } finally {
      setLoading(false)
    }
  }

  function isEsignPending(userId: string): EstampStatus {
    if (estampData.length === 0) {
      return EstampStatus.PENDING
    } else {
      const foundUser = estampData.find(data => data.userId === userId)
      return foundUser ? EstampStatus.SIGNED : EstampStatus.PENDING
    }
  }

  async function isAllEstampSigned(
    estampData: EstampData[],
    userDetails: UserDataDto
  ) {
    if (estampData.length === 0) {
      return
    } else {
      const users = getUsers(userDetails)
      const allSigned = estampData?.length === users?.length

      if (allSigned && customerApplication) {
        await LosService.updateNextStep(customerApplication.id)
        navigateToNextStep()
      }
      return
    }
  }

  async function getEstampData() {
    try {
      if (!customerApplication) {
        return
      }
      const response = await LosService.getEstampData(customerApplication.id)
      setEstampData(response)
      return response
    } catch (error) {}
  }

  async function fetchData() {
    const userDetails = (await getUserDetailsApi()) as UserDataDto
    const response = await getEstampData()
    // const users = getUsers(userDetails)

    if (response && userDetails) {
      await isAllEstampSigned(response, userDetails)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <LosFormContainer
      renderAppBar={() => {
        return (
          <LosAppBar currentStepLabel={'Agreement Signing'} hideProgressBar />
        )
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <Stack spacing={'0.5rem'}>
            <LosText
              text='Track the eSign process status'
              style={{
                fontSize: '0.9rem',
                lineHeight: '100%',
                fontWeight: 600,
              }}
            />
            <LosText
              text='Link will expire in 14 days'
              style={{
                fontFamily: 'Aspekta',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '17px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#FFC21B',
              }}
            />
          </Stack>
          <Grid item xs={12} sx={{ paddingTop: 2 }}>
            <Grid container rowSpacing={'1rem'}>
              {userDetails?.map((user, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <UserDetailViewOnlyCard
                      user={user.userAadharData[0].userBasicInfo}
                      index={index}
                      isEsignPending={isEsignPending(
                        user.userAadharData[0].aadharMetaData.customerId
                      )}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LosFormContainer>
  )
}

export default AgreementSigningCompletedPage
function getUsers(userData: UserDataDto): UserDataDto[] {
  const users: UserDataDto[] = []

  for (let i = 0; i < userData.userPanData.length; i++) {
    const user: UserDataDto = {
      companyDetailsResponse: userData.companyDetailsResponse,
      userPanData: [userData.userPanData[i]],
      userAadharData: [userData.userAadharData[i]],
      userSelfieData: [userData.userSelfieData[i]],
    }
    users.push(user)
  }

  return users
}
