import axios from 'axios';
import { BASE_URL } from '../../../api/endpoints';
import { PaginatedResponse } from '../../../utils/api';
import { Lead } from '../../onboarding/types/Los';
import { Agent } from '../types/Lms';

const Endpoint = {
  BASE: '/lead',
  GET_AGENT_BY_ID: '/agent/{agentId}',
  GET_AGENTS_LIST: '/agent/list',
  MAP_AGENT_TO_LEAD: '/assign-agent',
  CREATE_AGENT: '/agent',
  UPDATE_AGENT: '/agent/{agentId}',
  GET_LEADS: '/list',
};

const LEAD_BASE_URL = BASE_URL + Endpoint.BASE;

export class LeadService {
  static async getAgent(agentId: string): Promise<Agent> {
    try {
      const endpoint =
        LEAD_BASE_URL + Endpoint.GET_AGENT_BY_ID.replace('{agentId}', agentId);
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async getAllAgent(
    data: Partial<GetAllAgentsApiData>
  ): Promise<PaginatedResponse<Agent>> {
    try {
      const endpoint = LEAD_BASE_URL + Endpoint.GET_AGENTS_LIST;
      const response = await axios.get(endpoint, {
        params: data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async mapAgentToLead(data: { leadId: string; agentId: string }) {
    try {
      const endpoint = LEAD_BASE_URL + Endpoint.MAP_AGENT_TO_LEAD;
      const response = await axios.post(
        endpoint,
        {},
        { params: { leadId: data.leadId, agentId: data.agentId } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async createAgent(data: Partial<Agent>): Promise<Agent> {
    try {
      const endpoint = LEAD_BASE_URL + Endpoint.CREATE_AGENT;
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateAgent(
    agentId: string,
    data: Partial<Agent>
  ): Promise<Agent> {
    try {
      const endpoint =
        LEAD_BASE_URL + Endpoint.UPDATE_AGENT.replace('{agentId}', agentId);
      const response = await axios.put(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getAllLeads(
    data: Partial<GetAllLeadsApiData>
  ): Promise<PaginatedResponse<Lead>> {
    try {
      const endpoint = LEAD_BASE_URL + Endpoint.GET_LEADS;
      const response = await axios.get(endpoint, {
        params: data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export interface PaginatedApiData {
  page: number;
  pageSize: number;
}

export interface GetAllAgentsApiData extends PaginatedApiData {
  programId: string;
}

export interface GetAllLeadsApiData extends PaginatedApiData {
  programId: string;
  sortBy: string;
  sortDir: string;
  agentId: string;
}
