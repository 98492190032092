/** @format */

import { Box, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { VEGA_BOX_SHADOW } from '../../constants/style';

type Props = {
  children: ReactNode;
  fullHeight?: boolean;
  noPad?: boolean;
  sx?: SxProps<Theme>;
};

const VegaCard = ({ children, fullHeight, noPad, sx }: Props) => {
  return (
    <Box
      sx={{
        boxShadow: VEGA_BOX_SHADOW,
        borderRadius: '20px',
        transform: 'all 0.5s easy-in-out',
        py: 3,
        px: 5,
        bgcolor: 'background.paper',
        ...(noPad && { p: 0 }),
        ...(fullHeight && { height: '100%' }),
        ...(sx || {}),
      }}
    >
      {children}
    </Box>
  );
};

export default VegaCard;
