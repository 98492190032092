import { Box, Grid } from '@mui/material';
import { BORDER_GRAY } from '../../../constants/style';
import LosText from '../../onboarding/components/common/LosText';
import ColendingActiveLoansList from '../components/ActiveLoans/ColendingActiveLoansList';
import ColendingLoanDetailsContainer from '../components/ActiveLoans/ColendingLoanDetailsContainer';

function ColendingActiveLoans() {
  return (
    <>
      <Grid
        container
        alignItems={'center'}
        sx={{
          bgcolor: 'white',
          p: 2,
        }}
      >
        <Grid item xs={12} sm={6}>
          <LosText fontWeight={500} fontSize={14}>
            Active Loans
          </LosText>
        </Grid>
      </Grid>
      <Box m={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                border: BORDER_GRAY,
                bgcolor: 'white',
                borderRadius: '10px',
              }}
            >
              <ColendingActiveLoansList />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Box
              sx={{
                border: BORDER_GRAY,
                p: 1,
                bgcolor: 'white',
                borderRadius: '10px',
              }}
            >
              <ColendingLoanDetailsContainer />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ColendingActiveLoans;
