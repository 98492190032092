import { ReactNode, useState } from 'react';
import LosAccordian from '../../components/common/LosAccordian';
import LosText from '../../components/common/LosText';

type Props = {
  renderPreview: () => ReactNode;
  title: string;
};
function PreviewAccordian({ renderPreview, title }: Props) {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <LosAccordian
      collapsedView={<LosText text={title} />}
      expandedView={renderPreview()}
      disableGutters
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
    ></LosAccordian>
  );
}

export default PreviewAccordian;
