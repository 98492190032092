import LosButton from './onboarding/components/common/LosButton';
import LosText from './onboarding/components/common/LosText';

type Props = {
  description: string;
  buttonText: string;
  onClick: () => void;
  showLogoutButton?: boolean;
  onLogoutClick?: () => void;
};
function AccessNotAllowed({
  description,
  buttonText,
  onClick,
  onLogoutClick,
  showLogoutButton = false,
}: Props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        gap: '1rem',
      }}
    >
      <LosText text={description} />
      <LosButton text={buttonText} onClick={onClick} />
      {showLogoutButton == true && (
        <LosButton text={'Logout'} onClick={onLogoutClick} variant="outlined" />
      )}
    </div>
  );
}

export default AccessNotAllowed;
