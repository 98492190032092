/** @format */

import {
  ArrowForwardIos,
  KeyboardArrowDown,
  Menu,
  Search,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COMMON, GREY } from '../../constants/style';
import { useAppBar } from '../../providers/AppBarProvider';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSearchHeader } from '../../providers/SearchHeader';
import { ROUTES } from '../../routes';
import { VegaTextField } from '../common';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import {
  getState,
  setHeader,
  setIsNavigate,
} from '../../store/common/stateSlice';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: any;
  isSmallScreen: any;
};

const MainHeader = (props: Props) => {
  const { setAppBarHeight } = useAppBar();
  const dispatch = useAppDispatch();
  const { header } = useAppSelector(getState);
  const { setOpen, isSmallScreen, isMobile } = props;
  const navigation = useNavigate();
  const { client, logout, loggedInUserDetails, userDetails, preferedUserName } =
    useClientAuth();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const {
    searchValue,
    isHeaderSearch,
    onSearchValueChange,
    headerPlaceHolder,
  } = useSearchHeader();

  const headerRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const resetNavigator = () => {
    navigation(ROUTES.RESET_PASSWORD);
  };
  useEffect(() => {
    if (headerRef.current) {
      const updatedHeight = headerRef.current.offsetHeight;
      setAppBarHeight(updatedHeight);
    }
  }, []);

  const userPreferredName = () => {
    const clientName = client?.clientName;
    const userDetailsName = userDetails.name;
    const currentPath = window.location.pathname;
    if (preferedUserName) {
      return preferedUserName;
    }

    if (currentPath == '/hq') {
      return 'Admin';
    }

    if (currentPath == '/colender') {
      return 'Colender';
    }

    return clientName || userDetailsName || 'User';
  };

  console.log('Logged in user in header', loggedInUserDetails);

  return (
    <Box
      ref={headerRef}
      sx={{
        position: 'fixed',
        top: 0,
        width: '-webkit-fill-available',
        bgcolor: 'background.paper',
        py: 2,
        px: 5,
        zIndex: 100,
        borderBottom: '1px solid #EBEBEB',
        display: 'flex',
        ...(isSmallScreen || isMobile
          ? { justifyContent: 'space-between' }
          : header
          ? { justifyContent: 'space-between' }
          : { justifyContent: 'flex-end' }),
      }}
    >
      {(isSmallScreen || isMobile) && (
        <IconButton
          sx={{ padding: 0 }}
          onClick={() => setOpen((preVal: boolean) => !preVal)}
        >
          <Menu />
        </IconButton>
      )}

      {header && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            className="font-aspekta-500"
            color={COMMON.gray}
            sx={{ cursor: 'pointer', mr: 1 }}
            onClick={() => {
              dispatch(setHeader(''));
            }}
          >
            {header?.split('_')[0]}
          </Typography>
          <ArrowForwardIos fontSize="small" />
          <Typography
            className="font-aspekta-500"
            color={COMMON.gray}
            sx={{ ml: 1 }}
          >
            {header.split('_')[1]}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            columnGap: 2,
          }}
        >
          {isHeaderSearch && (
            <VegaTextField
              key={'searchHeader'}
              value={searchValue}
              onChange={(e) => onSearchValueChange(e.target.value)}
              placeholder={headerPlaceHolder}
              type={'text'}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              textFieldWidth={300}
            />
          )}

          <Box>
            <Typography
              variant="body2"
              sx={{ mr: 1, display: 'flex', flexDirection: 'column' }}
            >
              {userPreferredName()}
              <Typography
                variant="caption"
                sx={{ color: GREY.grey }}
                style={{ lineHeight: 1 }}
              >
                Last Login : {loggedInUserDetails?.loginTime?.toLocaleString()}
              </Typography>
            </Typography>
          </Box>

          <KeyboardArrowDown color="primary" onClick={handleClick} />
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{ marginTop: 22 }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            zIndex: 9999,
          }}
        >
          <Typography
            sx={{ fontSize: 14, paddingBottom: 1 }}
            borderBottom="0.4px solid #D1D1D1"
          >
            {loggedInUserDetails?.email}
          </Typography>
          <Stack spacing={1}>
            <Button
              sx={{
                marginTop: 2,
                minWidth: 220,
                backgroundColor: '#4785FF',
                color: 'white',
                fontWeight: 'bold',
              }}
              onClick={logout}
            >
              logout
            </Button>
            <Button variant="outlined" onClick={resetNavigator}>
              Reset Password
            </Button>
          </Stack>
        </Box>
      </Popover>
    </Box>
  );
};

export default MainHeader;
