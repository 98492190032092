import { Grid, Divider, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { GREEN, RED } from '../../../../../constants/style'
import { ReadOnlyFormField } from '../../../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField'
import LosText from '../../../components/common/LosText'
import { useApplicationPreview } from '../../../providers/ApplicationPreviewProvider'
import { StringUtility } from '../../../utils/StringUtility'
import { IndividualKycDetailsPreviewData } from '../types'
import { LosService } from '../../../api/LosService'
import {
  DocumentStatus,
  UserAadharStatus,
  UserDataDto,
  UserPanStatus,
} from '../../../types/Los'
import { getApplicationsDetails } from '../../../../../store/common/agentApplicationsSlice'
import { useAppSelector } from '../../../../../store/hook'
import { LocalStorageUtility, StorageKeys } from '../../../utils/StorageUtility'

type Props = {
  viewerType: 'Customer' | 'HQ'
}
function IndividualKycDetailsPreview({ viewerType }: Props) {
  const { applicationDetails } = useAppSelector(getApplicationsDetails)
  const applicationId =
    LocalStorageUtility.getItem<string>(StorageKeys.APPLICATION_ID) ?? ''
  const [loading, setLoading] = useState<boolean>(false)
  const [userDetails, setUserDetails] = useState<UserDataDto>()

  // async function fetchDetails() {
  //   try {
  //     if (!applicationId) return;
  //     setLoading(true);
  //     // const response = await fetchKycDetails(applicationId);

  //     setKycDetails(response);
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }
  // }
  const getUserDetailsApi = async () => {
    try {
      setLoading(true)
      const response = await LosService.getUserDetails(applicationId)
      setUserDetails(response)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getUserDetailsApi()
    // fetchDetails();
  }, [])

  const isCustomerViewingComponent = () => viewerType === 'Customer'

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <div>
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <LosText text='Pan Details' />
        </Grid>
        <Grid item xs={6} sm={3}>
          <ReadOnlyFormField
            title='Name'
            description={
              userDetails?.userPanData?.[0]?.panMetadata?.name ?? '-'
            }
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <ReadOnlyFormField
            title='Pan Number'
            description={
              userDetails?.userPanData?.[0]?.panMetadata?.number ?? '-'
            }
          />
        </Grid>

        {isCustomerViewingComponent() == false && (
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField
              title='Status'
              description={
                userDetails?.userPanData?.[0]?.panMetadata?.status ?? '-'
              }
              descriptionColor={
                userDetails?.userPanData?.[0]?.panMetadata?.status ==
                UserPanStatus.APPROVED
                  ? GREEN.dark
                  : RED.red
              }
            />
          </Grid>
        )}

        {/* {isCustomerViewingComponent() == false && (
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField
              title='Bureau Acceptance'
              description={kycDetails.panDetails?.bureauAcceptance}
            />
          </Grid>
        )} */}
      </Grid>

      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      />
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <LosText text='Aadhaar Details' />
        </Grid>
        <Grid item xs={6} sm={3}>
          <ReadOnlyFormField
            title='Name'
            description={
              userDetails?.userAadharData?.[0]?.aadharMetaData?.name ?? '-'
            }
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <ReadOnlyFormField
            title='Aadhaar Number'
            description={
              userDetails?.userAadharData?.[0]?.aadharMetaData?.uid ?? '-'
            }
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <ReadOnlyFormField
            title='Date of Birth/Year'
            description={
              userDetails?.userAadharData?.[0]?.aadharMetaData?.dob ?? '-'
            }
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <ReadOnlyFormField
            title='Status'
            description={
              userDetails?.userAadharData?.[0]?.aadharMetaData?.status ?? '-'
            }
            descriptionColor={
              userDetails?.userAadharData?.[0]?.aadharMetaData?.status ==
              UserAadharStatus.APPROVED
                ? GREEN.dark
                : RED.red
            }
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <ReadOnlyFormField
            title='Gender'
            description={
              userDetails?.userAadharData?.[0]?.aadharMetaData?.gender
            }
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <ReadOnlyFormField
            title='Current Address'
            description={StringUtility.formatAadhaarAddressToReadable(
              userDetails?.userAadharData?.[0]?.aadharMetaData?.splitAddress
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <ReadOnlyFormField
            title='Permanent Address'
            description={StringUtility.formatAadhaarAddressToReadable(
              userDetails?.userAadharData?.[0]?.aadharMetaData?.splitAddress
            )}
          />
        </Grid>
        {/* {isCustomerViewingComponent() == false && (
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField
              title='Aadhaar Acceptance'
              description={kycDetails.aadhaarDetails?.aadhaarAcceptance}
            />
          </Grid>
        )} */}
      </Grid>

      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      />
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <LosText text='Selfie Details' />
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              height: '5rem',
              width: '5rem',
              borderRadius: '0.75rem',
            }}
          >
            <img
              src={userDetails?.userSelfieData?.[0]?.selfieMetaData?.fileName}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                borderRadius: '0.75rem',
              }}
            ></img>
          </div>
        </Grid>
        {isCustomerViewingComponent() == false && (
          <Grid item xs={4}>
            <ReadOnlyFormField
              title='Face Match'
              description={
                userDetails?.userSelfieData?.[0]?.selfieMetaData?.score
              }
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <ReadOnlyFormField
            title='Status'
            description={
              userDetails?.userSelfieData?.[0]?.selfieMetaData?.status
            }
            descriptionColor={
              userDetails?.userSelfieData?.[0]?.selfieMetaData?.status ===
              DocumentStatus.APPROVED
                ? GREEN.dark
                : RED.red
            }
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default IndividualKycDetailsPreview
