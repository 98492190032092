import { useEffect } from 'react';
import { useState } from 'react';
import { PaginatedResponse } from '../../utils/api';

export const usePagination = <T,>(props: {
  initialData: T[];
  api: (page: number, pageSize: number) => Promise<PaginatedResponse<T>>;
}) => {
  const [data, setData] = useState<T[]>(props.initialData);
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await props.api(page, pageSize);
      setData(response.records);
      // setPageSize(response.numberOfItems);
      setPage(response.pageNumber);
      setRowCount(response.totalItems);
      setTotalPage(response.totalPages);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const changePage = (newPage: number) => {
    setPage(newPage);
  };

  const changePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const privious = (page: number) => {
    setPage(page - 1);
  };
  const next = (page: number) => {
    setPage(page + 1);
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize]);

  return {
    data,
    page,
    loading,
    pageSize,
    totalPage,
    privious,
    next,
    changePage,
    changePageSize,
    rowCount,
  };
};
