import { Slider, SliderProps, styled, useMediaQuery } from '@mui/material';

interface LosProgressBarProps extends SliderProps {}

const StyledSlider = styled(Slider)<LosProgressBarProps>(({ theme }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return {
    fontFamily: 'Aspekta !important',
    color: '#88F231',
    '& .MuiSlider-thumb': {
      display: 'none',
    },
    '& .MuiSlider-track': {
      color: '#88F231 !important',
      height: '0.5rem',
    },
  };
});

function LosProgressBar({ ...rest }: LosProgressBarProps) {
  return <StyledSlider {...rest} disabled />;
}

export default LosProgressBar;
