import React from 'react';
import WeightScoreWrapper from '../../WeightScoreWrapper';
import { useRuleEngine } from '../../../../LMSAgent/Provider/RuleEngingProvider';
import { Box, Stack } from '@mui/material';
import LosText from '../../../LosText';
import ManagementCriteria from './components/ManagementCriteria';
import LosButton from '../../../../onboarding/components/common/LosButton';
import { EditIcon } from '../../../../../components/icon/Icons';
import { EditOutlined } from '@mui/icons-material';
import { COMMON } from '../../../../../constants/style';

const ManagementRule = () => {
  const {
    loading,
    underWrittingScrore,
    setIsEdit,
    isEdit,
    onUpdateManagementScore,
  } = useRuleEngine();
  return (
    <Stack justifyContent={'space-between'} height={'100%'}>
      <Box>
        <WeightScoreWrapper
          value={
            underWrittingScrore?.underwritingManagementScore
              ? underWrittingScrore?.underwritingManagementScore.weightedPoints
              : 0
          }
        />
        <Box
          sx={{
            py: 1.8,
          }}
        >
          <ManagementCriteria />
        </Box>
      </Box>
      <Box py={2} borderTop={`1px solid #E1E4EB`} textAlign={'end'}>
        {!isEdit ? (
          <LosButton
            text="Edit"
            variant="outlined"
            startIcon={<EditOutlined />}
            onClick={() => setIsEdit(true)}
          />
        ) : (
          <Stack direction={'row'} gap={1} justifyContent={'end'}>
            <LosButton
              text="Save"
              variant="outlined"
              onClick={onUpdateManagementScore}
            />
            <LosButton text="Cancel" onClick={() => setIsEdit(false)} />
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default ManagementRule;
