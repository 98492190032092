import axios from 'axios';
import { BASE_URL } from '../../../api/endpoints';

const OTP_VERIFICATION_ENDPOINT = {
  GENERATE_OTP: '/notification/v1/notification/generateOtp',
  VERIFY_OTP: '/notification/v1/notification/verifyOtp',
};

const OTP_BASE_URL = BASE_URL;

export class OtpService {
  static async generateOTP(data: GenerateOTPRequestData) {
    try {
      const endpoint = OTP_BASE_URL + OTP_VERIFICATION_ENDPOINT.GENERATE_OTP;
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async verifyOTP(data: VerifyOTPRequestData): Promise<string> {
    try {
      const endpoint = OTP_BASE_URL + OTP_VERIFICATION_ENDPOINT.VERIFY_OTP;
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export type VerifyOTPResponseData = {
  result: string;
  timestamp: string;
};

export type GenerateOTPRequestData = {
  programId: string;
  mobileNo: string;
};

export type VerifyOTPRequestData = {
  programId: string;
  mobileNo: string;
  otp: string;
};
