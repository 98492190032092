import { Grid } from '@mui/material';
import _ from 'lodash';
import { LosSelectOption } from '../../../../../onboarding/components/common/LosSelect';
import { EditFormFiled } from './EditFormFiled';
import {
  ColenderIncomeSchemeFormData,
  ColenderIncomeSchemeInputType,
} from './formData';
import { ReadOnlyFormField } from './ReadOnlyFormField';

type Props = {
  isEditable: boolean;
  formData: Partial<ColenderIncomeSchemeFormData>;
  handleUpdate: (type: ColenderIncomeSchemeInputType, value?: string) => void;
};

function ColenderIncomeSchemeForm({
  isEditable,
  formData,
  handleUpdate,
}: Props) {
  if (isEditable) {
    return <EditView formData={formData} onUpdate={handleUpdate} />;
  }
  return <ReadOnlyView formData={formData} />;
}

export default ColenderIncomeSchemeForm;

type EditViewProps = {
  formData: Partial<ColenderIncomeSchemeFormData>;
  onUpdate: (type: ColenderIncomeSchemeInputType, value?: string) => void;
};

const EditView = ({ formData, onUpdate }: EditViewProps) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Grid container spacing={'1rem'}>
          <Grid item xs={3}>
            <EditFormFiled
              title="Late Fee"
              description={formData.lateFee}
              onChange={(e) => {
                onUpdate(ColenderIncomeSchemeInputType.LateFee, e);
              }}
              options={lateFeeOptions()}
            />
          </Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="Penal Interest"
              description={formData.penalInterest}
              onChange={(e) => {
                onUpdate(ColenderIncomeSchemeInputType.PenalInterest, e);
              }}
              options={penalInterestOptions()}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

type ReadOnlyViewProps = {
  formData: Partial<ColenderIncomeSchemeFormData>;
};

const ReadOnlyView = ({ formData }: ReadOnlyViewProps) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Grid container spacing={'1rem'}>
          <Grid item xs={3}>
            <ReadOnlyFormField
              title="Late Fee"
              description={`${formData.lateFee ?? '-'}%`}
            />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField
              title="Penal Interest"
              description={`${formData.penalInterest ?? '-'}%`}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const percentages = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
const lateFeeOptions = () =>
  percentages.map((item) => {
    const option: LosSelectOption = {
      value: `${item}`,
      label: `${item}%`,
    };
    return option;
  });

const penalInterestOptions = () =>
  percentages.map((item) => {
    const option: LosSelectOption = {
      value: `${item}`,
      label: `${item}%`,
    };
    return option;
  });
