import { Box, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useClientAuth } from '../../../../providers/ClientProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import LosDataGrid from '../../../components/LosDataGrid';
import LosStatusPill from '../../../components/LosStatusPill';
import SolfinAppBar from '../../../components/SolfinAppBar';
import {
  GetAllApplicationsForAgentApiData,
  LosService,
} from '../../../onboarding/api/LosService';
import { LOS_PROGRAM_ID } from '../../../onboarding/api/constants';
import LosButton from '../../../onboarding/components/common/LosButton';
import LosSelect, {
  LosSelectOption,
} from '../../../onboarding/components/common/LosSelect';
import {
  ApplicationStatus,
  CustomerApplication,
  getApplicationStatusDisplayText,
} from '../../../onboarding/types/Los';
import { useLmsAgentRouter } from '../../Provider/LmsAgentRouter';
import ApplicationsList from '../MobileView/ApplicationsList';
import MobileViewPagination from '../MobileViewPagination';

const ApplicationsForAgent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { agentId } = useClientAuth();
  const { navigateToApplicationReview } = useLmsAgentRouter();
  const [applications, setApplications] = useState<CustomerApplication[]>([]);
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const { setSnackbar } = useSnackbar();
  const [selectedFilter, setSelectedFilter] = useState<string>('ALL');

  const COLUMN_DEF: GridColumns<CustomerApplication> = [
    {
      field: 'customerId',
      headerName: 'Customer Id',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as CustomerApplication;
        return row.customerId;
      },
      flex: 2,
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as CustomerApplication;
        var displayText = '-';
        if (row.customerName) {
          displayText = row.customerName;
        }
        return displayText;
      },
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'Loan Amount',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as CustomerApplication;
        var displayText = '-';
        if (application?.basicInfo?.amount) {
          displayText = `₹ ${application.basicInfo.amount}`;
        }
        return displayText;
      },
      flex: 1,
    },
    {
      field: 'tenure',
      headerName: 'Tenure',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as CustomerApplication;
        var displayText = '-';
        if (application?.basicInfo?.tenure) {
          displayText = `${application.basicInfo.tenure} Months`;
        }
        return displayText;
      },
      flex: 1,
    },
    {
      field: 'mobileNumber',
      headerName: 'Mobile Number',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as CustomerApplication;
        var displayText = '-';
        if (row.phone) {
          displayText = row.phone;
        }
        return displayText;
      },
      flex: 1,
    },
    {
      field: 'losEntityType',
      headerName: 'Entity',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as CustomerApplication;
        return _.startCase(_.toLower(row.losEntityType));
      },
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as CustomerApplication;
        return <LosStatusPill status={row.status} />;
      },
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as CustomerApplication;
        var buttonDisplayText = 'View';
        if (
          row.status == ApplicationStatus.IN_PROGRESS ||
          row.status == ApplicationStatus.PENDING
        ) {
          buttonDisplayText = 'Audit';
        }
        return (
          <LosButton
            variant="outlined"
            size="small"
            text={buttonDisplayText}
            onClick={() => {
              navigateToApplicationReview(row.id);
            }}
          />
        );
      },
      flex: 1,
    },
  ];

  async function fetchApplications() {
    try {
      if (!agentId) {
        console.error('Agent id not found');
        return;
      }
      setLoading(true);
      const params: Partial<GetAllApplicationsForAgentApiData> = {
        agentId: agentId,
        page: page,
        pageSize: 10,
        sortBy: 'createdAt',
        sortDir: 'desc',
        programId: LOS_PROGRAM_ID,
      };
      if (selectedFilter != 'ALL') {
        params.status = selectedFilter;
      }
      const response = await LosService.getAllApplicationsForAgent(params);
      setApplications(response.records);
      setPage(response.pageNumber);
      setRowCount(response.totalItems);
      setTotalPage(response.totalPages);
      // setPageSize(response.numberOfItems);
    } catch (error) {
      console.error(error);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const privious = (page: number) => {
    setPage(page - 1);
  };
  const next = (page: number) => {
    setPage(page + 1);
  };

  useEffect(() => {
    fetchApplications();
  }, [page, agentId, selectedFilter]);

  return (
    <>
      <SolfinAppBar
        title={'All Applications'}
        renderActions={() => {
          return (
            <Grid container alignItems="end" justifyContent={'end'}>
              <Grid item xs={isMobile ? 12 : 6}>
                <Stack>
                  <LosSelect
                    options={filterOptions()}
                    key="status"
                    label="Application Status"
                    value={selectedFilter}
                    onChange={(e) => {
                      setSelectedFilter(e.target.value as string);
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          );
        }}
      />
      <Box m={2}>
        {isMobile ? (
          applications.length ? (
            <>
              <ApplicationsList
                applications={applications}
                navigateToApplicationReview={navigateToApplicationReview}
                loading={loading}
              />
              <MobileViewPagination
                page={page}
                totalPage={totalPage}
                privious={privious}
                next={next}
              />
            </>
          ) : (
            ''
          )
        ) : (
          <LosDataGrid
            data={applications}
            columns={COLUMN_DEF}
            page={page}
            pageSize={pageSize}
            loading={loading}
            idColumn={'id'}
          />
        )}
      </Box>
    </>
  );
};

export default ApplicationsForAgent;

const filterOptions = () => {
  const options = Object.values(ApplicationStatus).map((item) => {
    const option: LosSelectOption = {
      value: item,
      label: getApplicationStatusDisplayText(item),
    };
    return option;
  });
  const All = { value: 'ALL', label: 'All' };
  return [All, ...options];
};
