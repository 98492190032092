/** @format */

import { Grid } from '@mui/material';
import React, { SetStateAction } from 'react';

import { Add } from '@mui/icons-material';
import { IUsersProps, VegaTeamType } from '../../../types/userAccess';
import { VegaCard, VegaTextField } from '../../common';
import VegaContainedButton from '../../common/VegaContainedButton';
import VegaText from '../../common/VegaText';
import { UploadBulkIcon } from '../../icon/Icons';
import VegaSelect from '../../common/VegaSelect';
interface IAddUserProps {
  onChange: (name: string, value: any) => void;
  onSubmit: () => void;
  showBulkUpload: boolean;
  setIsBulkUpload?: React.Dispatch<SetStateAction<boolean>>;
  state: IUsersProps;
}

const AddUser = ({
  state,
  onChange,
  setIsBulkUpload,
  onSubmit,
  showBulkUpload,
}: IAddUserProps) => {
  return (
    <VegaCard>
      <VegaText mb={2} fw={600} text="Add a team member" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <VegaTextField
            focused
            value={state.name}
            onChange={(e) => onChange('name', e.target.value)}
            key="name"
            label="Name"
            type={'text'}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <VegaTextField
            focused
            value={state.mobileNumber}
            onChange={(e) => {
              const value = e.target.value.trim();
              if (state.mobileNumber.length <= 10) {
                onChange('mobileNumber', value);
              }
            }}
            key="mobileNumber"
            label="Mobile"
            type={'number'}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <VegaTextField
            focused
            value={state.email}
            onChange={(e) => onChange('email', e.target.value.trim())}
            key="email"
            label="Email"
            type={'email'}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <VegaTextField
            focused
            rows={1}
            multiline
            value={state.address}
            onChange={(e) => onChange('address', e.target.value.trim())}
            key="address"
            label="Enter Your Address"
            placeholder="Enter Your Address (Optional)"
            type={'text'}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <VegaSelect
            focused
            label="Select Team"
            placeholder="Select Team"
            options={Object.values(VegaTeamType)}
            selected={state.team}
            onChange={(e) => onChange('team', e)}
          />
        </Grid>
      </Grid>
      <VegaText mb={2} mt={4} fw={600} text="Government ID Proof" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <VegaSelect
            focused
            label="ID"
            placeholder="ID"
            options={['PAN', 'AADHAR', 'DRIVING_LICENCES']}
            selected={state.idType}
            onChange={(e) => onChange('idType', e)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <VegaTextField
            focused
            value={state.idNumber}
            onChange={(e) => onChange('idNumber', e.target.value)}
            key="idNumber"
            label="ID Number"
            placeholder="ID Number ( mandatory  )"
            type={'text'}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'end'} py={2}>
        {showBulkUpload && (
          <Grid item>
            <VegaContainedButton
              text="Upload Bulk"
              isSuccess
              startIcon={<UploadBulkIcon />}
              onClick={() => {
                if (setIsBulkUpload) {
                  setIsBulkUpload((preVal: boolean) => !preVal);
                }
              }}
            />
          </Grid>
        )}

        <Grid item>
          <VegaContainedButton
            text="Add User"
            isPrimary
            startIcon={<Add />}
            // mx="0"
            onClick={onSubmit}
          />
        </Grid>
      </Grid>
    </VegaCard>
  );
};

export default AddUser;
