import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '..';
import {
  IDispersalScheduleProps,
  ILoanDetailsProps,
} from '../../solfen/types/pre-disbursement';

const default_load = {
  id: '',
  account_id: '',
  program_id: '',
  installment_policy_id: null,
  loan_amount: 100000,
  loan_type: null,
  loan_dispersal_type: null,
  lan: null,
  parent_loan_id: null,
  lender_id: null,
  loan_percentage: null,
  currency: '',
  interest_rate: 18.3,
  interest_type: '',
  interest_amount: 10187.39,
  repayment_amount: 110187.39,
  installment_amount: null,
  installment_count: 12,
  loan_start_date: '',
  over_due_amount: 0,
  interest_accure: null,
  due_principal: 40000,
  application_id: '',
  lending_type: null,
  child_loans: [],
  paid_amount: null,
  surplus: 0,
  status: '',
  tentative_loan_closing_date: null,
  loan_closed_date: null,
  tenure: 12,
  tenure_unit: '',
  first_installment_payment: '',
  installment_frequency: '',
  amortization_type: '',
  gap_interest: null,
  re_amortisation_required: null,
  file_id: null,
  created_at: '',
  updated_at: '',
};

interface IProps {
  loanDetails: ILoanDetailsProps;
  dispersalSchedule: IDispersalScheduleProps[];
}

const initialState: IProps = {
  loanDetails: default_load,
  dispersalSchedule: [],
};

const preDisbursmentSlice = createSlice({
  name: 'disbursement',
  initialState,
  reducers: {
    setLoadnDetails: (state: IProps, action) => {
      const { payload } = action;
      state.loanDetails = payload;
    },
    setDispersalSchedule: (state: IProps, action) => {
      const { payload } = action;
      state.dispersalSchedule = payload;
    },
  },
});

export const { setLoadnDetails, setDispersalSchedule } =
  preDisbursmentSlice.actions;
export const getDisbursementDetials = (state: AppState) => state.disbursement;
export default preDisbursmentSlice;
