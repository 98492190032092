import { Grid, Divider, CircularProgress } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import LosFormInputField from '../../../../../onboarding/components/common/LosFormInputField'
import LosText from '../../../../../onboarding/components/common/LosText'
import { StringUtility } from '../../../../../onboarding/utils/StringUtility'
import { useActiveLoans } from '../../../../providers/ActiveLoansProvider'
import { LoanDetails, LoanDispersalSchedule } from '../../../../types/Lms'
import DisburseTrenchDialog from '../../DisburseTrenchDialog'
import ColenderForLoanDetails from './ColenderForLoanDetails'
import TrenchDetails from './TrenchDetails'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
type DisburseNowDialogData = {
  open: boolean
  trench: LoanDispersalSchedule | null
  index: number
}

function LoanDetailsTab() {
  const { fetchLoanDetails, selectedLoan, disburseTrench } = useActiveLoans()
  const [loading, setLoading] = useState<boolean>(false)
  const [loanDetails, setLoanDetails] = useState<Partial<LoanDetails>>({})
  const [disburseNowDialogData, setDisburseNowDialogData] =
    useState<DisburseNowDialogData>({
      open: false,
      trench: null,
      index: 0,
    })

  async function fetchDetails() {
    try {
      setLoanDetails({})
      setLoading(true)
      const response = await fetchLoanDetails()
      setLoanDetails(response)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  async function onDisburseTrench(
    trench: LoanDispersalSchedule,
    utrNumber: string,
    date: string
  ) {
    try {
      setLoading(true)
      const response = await disburseTrench({
        trench: trench,
        date: date,
        utrNumber: utrNumber,
      })
      updateTrenchInCache(response)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  function updateTrenchInCache(updatedTrench: LoanDispersalSchedule) {
    const updatedTrenchList =
      loanDetails.trenches?.map(item => {
        if (item.id == updatedTrench.id) return updatedTrench
        return item
      }) ?? []

    setLoanDetails(prev => {
      return {
        ...prev,
        trenches: updatedTrenchList,
      }
    })
  }

  const loanEmiDate = () => {
    const date = getLoan()?.loan_start_date
    if (date) {
      return dayjs(date).format('DD/MM/YYYY')
    }
    return '-'
  }

  const getLoan = () => {
    return loanDetails.loan
  }

  const hasTrenches = () => {
    const trenches = loanDetails.trenches ?? []
    return trenches.length > 0
  }

  const hasChildLoans = () => {
    const childLoans = loanDetails.childLoans ?? []
    return childLoans.length > 0
  }

  const getEntityDisplayText = () => {
    const type = loanDetails.application?.losEntityType
    var displayText = '-'
    if (type) {
      displayText = _.startCase(_.toLower(type))
    }

    return displayText
  }

  const hasPendingTranchesBeforeIndex = (index: number) => {
    const tranches = loanDetails.trenches ?? []
    if (tranches.length < index) return false
    const previousTranches = tranches.slice(0, index)
    const filteredTranches = previousTranches.filter(
      item => item.dispersalDate == null
    )
    if (filteredTranches.length > 0) return true
    return false
  }

  useEffect(() => {
    fetchDetails()
  }, [selectedLoan])

  return (
    <div
      style={{
        padding: '1rem',
      }}
    >
      <div
        style={{
          display: loading ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
      <div
        style={{
          display: loading == false ? 'flex' : 'none',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Grid container spacing={'1rem'} columns={12}>
          <Grid item xs={6} sm={4} md={2}>
            <Field
              title='Loan Amount'
              description={`₹ ${StringUtility.formatCurrency(
                getLoan()?.loan_amount
              )}`}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Field
              title='ROI'
              description={`${getLoan()?.interest_rate ?? '-'}` + ' %'}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Field
              title='Loan Tenure'
              description={
                `${getLoan()?.tenure ?? '-'}` +
                ` ${getLoan()?.tenure_unit ?? '-'}`
              }
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Field title='Loan Start Date' description={loanEmiDate()} />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Field title='Entity' description={getEntityDisplayText()} />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Field
              title='Surplus Amount'
              description={`₹ ${StringUtility.formatCurrency(
                getLoan()?.surplus
              )}`}
            />
          </Grid>
        </Grid>
      </div>
      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          display: hasTrenches() ? 'block' : 'none',
        }}
      />
      <div
        style={{
          display: hasTrenches() ? 'flex' : 'none',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <LosText text='Disbursal Plan Details' />
        {loanDetails.trenches?.map((item, index) => {
          return (
            <TrenchDetails
              trench={item}
              index={index}
              key={index}
              hideDisburseButton={hasPendingTranchesBeforeIndex(index)}
              onDisburseClick={function (): void {
                setDisburseNowDialogData({
                  open: true,
                  trench: item,
                  index: index,
                })
              }}
            />
          )
        })}
      </div>
      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          display: hasChildLoans() ? 'block' : 'none',
        }}
      />
      <div
        style={{
          display: hasChildLoans() ? 'flex' : 'none',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <LosText text='Colender Details' />
        {loanDetails.childLoans?.map((item, index) => {
          return <ColenderForLoanDetails index={index} loan={item} />
        })}
      </div>

      <DisburseTrenchDialog
        open={disburseNowDialogData.open}
        trench={disburseNowDialogData.trench}
        index={disburseNowDialogData.index + 1}
        onClose={function (): void {
          setDisburseNowDialogData({
            open: false,
            trench: null,
            index: 0,
          })
        }}
        onSubmit={onDisburseTrench}
      />
    </div>
  )
}

export default LoanDetailsTab

const Field = (data: { title: string; description?: string | number }) => {
  const displayText = () => {
    if (data.description) {
      return `${data.description}`
    }
    return '-'
  }
  return (
    <LosFormInputField label={data.title}>
      <LosText
        text={displayText()}
        fontSize={'0.75rem'}
        fontWeight={400}
        lineHeight={'120%'}
        color={'#1B1D22'}
      />
    </LosFormInputField>
  )
}
