import { Grid, Stack } from '@mui/material'
import { useState, useEffect } from 'react'
import LosProgressBar from '../../../components/common/LosProgressBar'

import LosText from '../../../components/common/LosText'
import LosAppBar from '../../../components/LosAppBar'
import LosFormContainer from '../../../components/LosFormContainer'
import { useLos } from '../../../providers/LosProvider'
import { SelfieViewState, useSelfie } from '../SelfieProvider'

const TIMER_SECONDS = 4
function SelfieUploadingView() {
  const { onViewStateChange, capturedSelfie } = useSelfie()
  const { saveIndividualSelfie, currentStepMetaData } = useLos()
  const [progressValue, setProgressValue] = useState(0)

  async function uploadIndividualSelfie() {
    const isVerified = await saveIndividualSelfie({
      selfie: capturedSelfie,
      onUploadProgress: progress => {},
    })

    if (isVerified == true) {
      onViewStateChange(SelfieViewState.Approved)
    } else {
      onViewStateChange(SelfieViewState.Rejected)
    }
  }

  function runAfterDelay(callback: () => void, delay: number) {
    setTimeout(callback, delay)
  }

  useEffect(() => {
    runAfterDelay(uploadIndividualSelfie, 3)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const increment = (80 / TIMER_SECONDS) * 0.1
      setProgressValue(prevValue => prevValue + increment)
    }, 80)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <LosFormContainer
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        )
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <Stack
            direction={'row'}
            alignItems='center'
            justifyContent={'center'}
          >
            <div
              style={{
                width: '12.5rem',
                height: '12.5rem',
                borderRadius: '12.5rem',
              }}
            >
              <img
                src={capturedSelfie}
                height='100%'
                width={'100%'}
                style={{
                  objectFit: 'cover',
                  overflow: 'hidden',
                  width: '12.5rem',
                  height: '12.5rem',
                  borderRadius: '12.5rem',
                }}
              ></img>
            </div>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack alignItems={'center'} justifyContent='center'>
            <LosText text='Please wait, We’re uploading your selfie' />
            <LosText text='This can take upto 3-5 seconds' variant='caption' />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          alignItems='center'
          justifyContent={'center'}
          display='flex'
          flexDirection={'row'}
        >
          <div
            style={{
              width: '80%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <LosProgressBar max={100} value={progressValue} min={0} />
          </div>
        </Grid>
      </Grid>
    </LosFormContainer>
  )
}

export default SelfieUploadingView
