import React from 'react';
import { Grid } from '@mui/material';
import LosDetailsWrapper from '../../../../LosDetailsWrapper';
import {
  ICollateralScoresProps,
  IComplianceFactorsProps,
  ICreditWorthinessScoreObjProps,
  ICreditWorthinessScoreProps,
  IFinanceScoresProps,
  IUnderwritingCollateralScoreObjProps,
  IUnderwritingComplianceScoreObjProps,
  IUnderwritingFinancialScoreObjProps,
  IUnderwritingManagementScoreObjProps,
  useRuleEngine,
} from '../../../../../LMSAgent/Provider/RuleEngingProvider';
import { GREEN, RED } from '../../../../../../constants/style';
import { AnyScoreType } from '../../../RuleEngineType';

interface IWeightScroreProps {
  name: string;
  value: number;
  color: string;
}

const getColorValue = (score: AnyScoreType | null) => {
  if (!score)
    return {
      value: 0,
      color: RED.red,
    };
  const weight = score.points;
  // const decimalWeight = weight * 100;

  return {
    value: weight,
    color: weight >= 3 ? GREEN.dark : RED.red,
  };
};

const WeightScroreOverview = () => {
  const { loading, underWrittingScrore } = useRuleEngine();
  const weightScrore: IWeightScroreProps[] = [
    {
      name: 'Collateral',
      value: underWrittingScrore?.underwritingCollateralScore
        ? getColorValue(underWrittingScrore?.underwritingCollateralScore)?.value
        : 0,
      color: underWrittingScrore?.underwritingCollateralScore
        ? getColorValue(underWrittingScrore?.underwritingCollateralScore)?.color
        : RED.red,
    },
    {
      name: 'Credit worthiness',
      value: underWrittingScrore?.creditWorthinessScore
        ? getColorValue(underWrittingScrore?.creditWorthinessScore)?.value
        : 0,
      color: underWrittingScrore?.creditWorthinessScore
        ? getColorValue(underWrittingScrore?.creditWorthinessScore)?.color
        : RED.red,
    },
    {
      name: 'Financial',
      value: underWrittingScrore?.underwritingFinancialScore
        ? getColorValue(underWrittingScrore?.underwritingFinancialScore)?.value
        : 0,
      color: underWrittingScrore?.underwritingFinancialScore
        ? getColorValue(underWrittingScrore?.underwritingFinancialScore)?.color
        : RED.red,
    },
    {
      name: 'Management',
      value: underWrittingScrore?.underwritingManagementScore
        ? getColorValue(underWrittingScrore?.underwritingManagementScore)?.value
        : 0,
      color: underWrittingScrore?.underwritingManagementScore
        ? getColorValue(underWrittingScrore?.underwritingManagementScore)?.color
        : RED.red,
    },
    {
      name: 'Compliance',
      value: underWrittingScrore?.underwritingComplianceScore
        ? getColorValue(underWrittingScrore?.underwritingComplianceScore)?.value
        : 0,
      color: underWrittingScrore?.underwritingComplianceScore
        ? getColorValue(underWrittingScrore?.underwritingComplianceScore)?.color
        : RED.red,
    },
  ];
  return (
    <>
      <Grid container spacing={2}>
        {weightScrore.map((score: IWeightScroreProps, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={3} lg={2.4} xl={2.4}>
            <LosDetailsWrapper
              name={score.name}
              value={score.value}
              loading={loading}
              valueColor={score.color}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default WeightScroreOverview;
