import { Box, Grid, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useState } from 'react'
import LosButton from '../../components/common/LosButton'
import LosFormInputField from '../../components/common/LosFormInputField'
import LosText from '../../components/common/LosText'
import LosTextField from '../../components/common/LosTextField'
import LosFormContainer from '../../components/LosFormContainer'
import { useCountdown } from '../../hooks/useCountdown'
import { useLosAuth } from '../../providers/LosAuthProvider'
import LosOnboardingButton from '../../components/common/LosOnboardingButton'
import { VegaPayLogo } from '../../icons/LosIcons'
import { useSnackbar } from '../../../../providers/SnackbarProvider'

const COUNT_DOWN_TIMER_SECONDS = 59
function MobileNumberOtpVerification() {
  const {
    verifyOtp,
    onOtpChange,
    mobileNumber,
    loading,
    resendOtp,
    lead,
    navigateToMobileNumber,
  } = useLosAuth()
  const [otp, setOtp] = useState<string>()
  const { minutes, seconds, startTimer } = useCountdown()
  const [enableResendButton, setEnableResendButton] = useState<boolean>(false)
  const { setSnackbar } = useSnackbar()
  function handleOtpChange(value: string) {
    if (value.length <= 6) {
      setOtp(value)
      onOtpChange(value)
    }
  }

  const isInputValid = () => {
    let isOtpValid = otp != null && validateOTP(otp)
    return isOtpValid
  }

  function onVerifyOtpClick() {
    const leadId = lead?.id
    if (!leadId) {
      console.error('Lead Id not found')
      setSnackbar('Lead id not found ', 'error')
      return
    }
    verifyOtp(leadId)
  }

  function onResendOTPClick() {
    if (enableResendButton) {
      resendOtp()
    }
  }

  function onChangeNumberClick() {
    navigateToMobileNumber()
  }

  useEffect(() => {
    const canEnableResendButton = minutes == 0 && seconds == 0
    if (canEnableResendButton != enableResendButton) {
      setEnableResendButton(canEnableResendButton)
    }
  }, [minutes, seconds])

  useEffect(() => {
    startTimer(COUNT_DOWN_TIMER_SECONDS)
  }, [])

  return (
    <LosFormContainer>
      <div
        style={{
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <img src='https://solfin.co.in/images/solfin-logo.svg'></img>
        </div>
        {/* <VegaPayLogo /> */}
        <LosText
          text='Sign up to get started'
          style={{
            fontWeight: 600,
            lineHeight: '120%',
            color: '#1B1D22',
            fontSize: '1.0625rem',
          }}
        />
        <Grid container rowSpacing={'1.5rem'}>
          <Grid item xs={12}>
            <LosFormInputField label='Mobile Number'>
              <LosTextField type={'number'} value={mobileNumber} />
              <Stack
                direction={'row'}
                justifyContent='end'
                alignItems={'center'}
              >
                <Box
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'start',
                    gap: '0.5rem',
                  }}
                  onClick={onChangeNumberClick}
                >
                  <LosText
                    text='Edit'
                    style={{
                      color: '#1047DC',
                      fontSize: '0.8125rem',
                      fontWeight: 450,
                      lineHeight: '120%',
                    }}
                  />
                </Box>
              </Stack>
            </LosFormInputField>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={'0.5rem'}>
              <LosText
                text='Verification'
                style={{
                  fontWeight: 600,
                  lineHeight: '120%',
                  color: '#1B1D22',
                  fontSize: '1.0625rem',
                }}
              />
              <LosText
                text={`We have sent your verification OTP on your registered mobile number ${mobileNumber}`}
                style={{
                  lineHeight: '120%',
                  color: '#1B1D22',
                  fontSize: '0.75rem',
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <LosFormInputField label='OTP'>
              <LosTextField
                type={'number'}
                value={otp}
                onChange={e => {
                  handleOtpChange(e.target.value)
                }}
                isValid={isInputValid()}
              />
              <Stack
                direction={'row'}
                justifyContent='end'
                alignItems={'center'}
              >
                <Box
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'start',
                    gap: '0.5rem',
                  }}
                  onClick={onResendOTPClick}
                >
                  <LosText
                    display={enableResendButton == false ? 'block' : 'none'}
                    text={`${seconds}s`}
                    variant='caption'
                  />
                  <LosText
                    text='Resend OTP'
                    style={{
                      color: enableResendButton ? '#1047DC' : '#676B76',
                      fontSize: '0.8125rem',
                      fontWeight: 450,
                      lineHeight: '120%',
                    }}
                  />
                </Box>
              </Stack>
            </LosFormInputField>
          </Grid>

          <Grid item xs={12}>
            <LosOnboardingButton
              fullWidth
              text='Verify Mobile Number'
              disabled={isInputValid() == false}
              onClick={onVerifyOtpClick}
              loading={loading}
            />
          </Grid>
        </Grid>
      </div>
    </LosFormContainer>
  )
}

export default MobileNumberOtpVerification

const validateOTP = (value: string) => {
  const digitsOnly = value.replace(/\D/g, '')

  return /^\d{6}$/.test(digitsOnly)
}
