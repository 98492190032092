import './Styles/__styles.css';
import {
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  styled,
} from '@mui/material';
import { ReactNode } from 'react';
import { ChevronDown } from '../../icon/Icons';
import VegaTextV2 from './VegaTextV2';

export interface VegaSelectOptionV2 {
  id: string;
  value: string;
  label: string;
  icon?: JSX.Element;
}

interface VegaSelectPropsV2 {
  placeholder?: string;
  hideDropDownIcon?: boolean;
  dropDownIcon?: ReactNode;
  options?: VegaSelectOptionV2[];
  renderOptions?: () => ReactNode;
  renderSelectedValue?: (selected: string) => ReactNode;
  hidePlaceholder?: boolean;
}

const StyledPlaceholder = styled(VegaTextV2)(({ theme }) => {
  return {
    fontSize: '0.8125rem',
    fontFamily: 'Aspekta !important',
    fontWeight: 450,
    lineHeight: '120%',
  };
});

function VegaSelectV2({
  options,
  placeholder,
  renderOptions,
  renderSelectedValue,
  hideDropDownIcon,
  hidePlaceholder = false,
  ...rest
}: VegaSelectPropsV2 & SelectProps) {
  const getLabelForSelectedValue = (selected: string) => {
    const selectedOption =
      options?.filter((item) => item.value == selected) ?? [];
    if (selectedOption.length > 0) {
      return selectedOption[0].label;
    }
    return selected;
  };

  return (
    <FormControl>
      <Select
        style={{ borderRadius: '.5rem', maxHeight: '32px' }}
        {...rest}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={hideDropDownIcon ? () => null : undefined}
        // TODO: Remove default icon and add custom icon, removed this because on clicking icon select was not opening
        // IconComponent={() =>
        //   hideDropDownIcon ? null : (
        //     <div
        //       style={{
        //         padding: 5,
        //         display: 'flex',
        //         justifyContent: 'center',
        //         alignItems: 'center',
        //       }}
        //     >
        //       <ChevronDown />
        //     </div>
        //   )
        // }
        renderValue={(selected: any) => {
          if (
            hidePlaceholder == false &&
            (!selected || selected?.length <= 0)
          ) {
            return (
              <StyledPlaceholder text={placeholder ?? ''} variant="caption" />
            );
          }
          if (renderSelectedValue) {
            return renderSelectedValue(selected);
          }
          return getLabelForSelectedValue(selected);
        }}
      >
        {renderOptions && renderOptions()}
        {options?.map((option) => {
          return (
            <MenuItem
              key={option.id}
              value={option.value}
              style={{ fontFamily: 'Aspekta !important' }}
            >
              <Stack direction={'row'} gap={2} alignItems="center">
                {option.icon && option.icon}
                <VegaTextV2 style={{ fontSize: '14px' }}>
                  {option.label}
                </VegaTextV2>
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default VegaSelectV2;
