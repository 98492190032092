import { useState } from 'react'
import SidebarLayout, {
  SidebarListItemType,
} from '../components/layouts/SidebarLayout'
import { SOLFIN_ROUTES } from '../routes'
import { Box } from '@mui/material'
import ApplicationsDashboard from '../solfen/LMSAgent/pages/ApplicationsDashboard'
import PreDisbursal from '../solfen/LMSAgent/pages/PreDisbursal'
import LeadsForAgent from '../solfen/LMSAgent/pages/LeadsForAgent'
import { LmsAgentRouterProvider } from '../solfen/LMSAgent/Provider/LmsAgentRouter'
import {
  AllApplicationIcon,
  LeadIcon,
  PreDisbursementIcon,
} from '../solfen/Icons/DashboardIcons'

const Solfin_LMS_Agent_Container = () => {
  const NAV_OPTIONS: SidebarListItemType[] = [
    {
      id: SOLFIN_ROUTES.LEADS_FOR_AGENT,
      icon: <LeadIcon />,
      label: 'Leads',
      component: <LeadsForAgent />,
    },
    {
      id: SOLFIN_ROUTES.APPLICATION,
      icon: <AllApplicationIcon />,
      label: 'Loan Applications',
      component: (
        <LmsAgentRouterProvider>
          <ApplicationsDashboard />
        </LmsAgentRouterProvider>
      ),
    },
    {
      id: SOLFIN_ROUTES.PRE_DISBURSAL,
      icon: <PreDisbursementIcon />,
      label: 'Pre-disbursement',
      component: <PreDisbursal />,
    },
  ]
  const [selectedItem, setSelectedItem] = useState<SidebarListItemType | null>(
    NAV_OPTIONS[0]
  )

  return (
    <Box sx={{ height: '100%' }}>
      <SidebarLayout
        sidebarListItems={
          //   isAdmin ? NAV_OPTIONS : [...NON_ADMIN, ...NAV_OPTIONS]
          NAV_OPTIONS
        }
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    </Box>
  )
}

export default Solfin_LMS_Agent_Container
