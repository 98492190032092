import { Box, Typography, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { BACKGROUND, BORDER_GRAY } from '../../../../../../constants/style';
import SelfieCamara from './components/SelfieCamara';
import { useAppSelector } from '../../../../../../store/hook';
import { getApplicationsDetails } from '../../../../../../store/common/agentApplicationsSlice';
import { useSnackbar } from '../../../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../../../utils/api';
import { getAgentDetailsById } from '../../../../../APIs/LMS_Agent/leads';
import { IAgentInfoProps } from '../../../../../types/application';
import { ReadOnlyFormField } from '../../../../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField';

const default_agent = {
  id: '',
  createdAt: '',
  updatedAt: '',
  name: '',
  email: '',
  phone: '',
  gender: '',
  selfie: '',
  status: '',
  clientId: null,
  address: {
    id: '',
    createdAt: '',
    updatedAt: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    pincode: '',
    country: '',
  },
  latitude: '',
  longitude: '',
};

interface IProps {
  setIsDisable: React.Dispatch<React.SetStateAction<boolean>>;
  setImg: React.Dispatch<React.SetStateAction<string | null>>;
  img: string | null;
}

export type GeoLocation = {
  coords: {
    latitude: number;
    longitude: number;
  };
};

function AgentDetailsConfirm({ setIsDisable, setImg, img }: IProps) {
  const { applicationDetails } = useAppSelector(getApplicationsDetails);
  const [agentInfo, setAgentInfo] = useState<IAgentInfoProps>({
    ...default_agent,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<Partial<GeoLocation>>({});
  const { setSnackbar } = useSnackbar();

  function getCurrentLocation(): Promise<GeoLocation> {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by your browser.'));
      } else {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      }
    });
  }

  async function fetchLocation() {
    const location = await getCurrentLocation();
    setLocation(location);
  }

  const getLatitudeDisplayText = () => {
    if (location.coords?.latitude) {
      return location.coords.latitude.toFixed(2);
    }
    return '-';
  };

  const getLongitudeDisplayText = () => {
    if (location.coords?.longitude) {
      return location.coords.longitude.toFixed(2);
    }
    return '-';
  };

  useEffect(() => {
    if (applicationDetails.agentId) {
      getAgentDetailsById(applicationDetails.agentId)
        .then((res) => {
          setAgentInfo(res.data);
        })
        .catch((error) => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        });
    }
  }, []);

  return (
    <Box
      sx={{
        bgcolor: BACKGROUND.GERY,
        p: 2,
      }}
    >
      <Box
        textAlign={'center'}
        sx={{
          border: BORDER_GRAY,
          bgcolor: 'white',

          p: 2,
          borderRadius: '10px',
        }}
      >
        <SelfieCamara
          img={img}
          setImg={setImg}
          loading={loading}
          setIsDisable={setIsDisable}
          onAllowAccessClick={() => {
            fetchLocation();
          }}
        />
      </Box>
      <Box
        sx={{
          border: BORDER_GRAY,
          borderRadius: '10px',
          p: 2,
          mt: 2,
          bgcolor: 'white',
        }}
      >
        <Typography className="font-aspekta-500" fontSize={14}>
          Agent Details
        </Typography>
        <Grid container mt={2}>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <ReadOnlyFormField
              title="Name"
              description={agentInfo.name ? agentInfo.name : '--'}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <ReadOnlyFormField
              title="location"
              description={
                agentInfo.address.city ? agentInfo.address.city : '--'
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <ReadOnlyFormField
              title="latitude"
              description={getLatitudeDisplayText()}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <ReadOnlyFormField
              title="longitude"
              description={getLongitudeDisplayText()}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default AgentDetailsConfirm;
