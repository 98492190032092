import React from 'react';
import { Box, Grid } from '@mui/material';
import LosDetailsWrapper from '../../../LosDetailsWrapper';
import LosText from '../../../LosText';
import WeightScroreOverview from './components/WeightScroreOverview';
import UnderwrittingBasicCriteria from './components/UnderwrittingBasicCriteria';
import { useRuleEngine } from '../../../../LMSAgent/Provider/RuleEngingProvider';
import OverviewCriteria from './components/OverviewCriteria';
import VetoCriteria from './components/VetoCriteria';
import { GREEN, RED } from '../../../../../constants/style';

const FinalCriteriaRule = () => {
  const { loading, underWrittingScrore } = useRuleEngine();
  return (
    <>
      <Box
        sx={{
          py: 1.8,
          borderBottom: '1px solid #E1E4EB',
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={6} md={3} lg={2.4} xl={2.4}>
            <LosDetailsWrapper
              name="total weight scrore"
              value={underWrittingScrore?.points ?? 0}
              loading={loading}
              valueColor={
                underWrittingScrore
                  ? underWrittingScrore?.points >= 3
                    ? GREEN.dark
                    : RED.red
                  : RED.red
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          py: 1.8,
          borderBottom: '1px solid #E1E4EB',
        }}
      >
        <LosText
          text="Individual Score Overview"
          fontWeight={500}
          fontSize={'0.875rem'}
          mb={2}
        />
        <WeightScroreOverview />
      </Box>
      <Box
        sx={{
          py: 1.8,
          borderBottom: '1px solid #E1E4EB',
        }}
      >
        <LosText
          text="Underwriting Basic Criteria"
          fontWeight={500}
          fontSize={'0.875rem'}
          mb={2}
        />
        <UnderwrittingBasicCriteria />
      </Box>
      <Box
        sx={{
          py: 1.8,
          borderBottom: '1px solid #E1E4EB',
        }}
      >
        <LosText
          text="Overwrite Criteria"
          fontWeight={500}
          fontSize={'0.875rem'}
          mb={2}
        />
        <OverviewCriteria />
      </Box>
      <Box
        sx={{
          py: 1.8,
          borderBottom: '1px solid #E1E4EB',
        }}
      >
        <LosText
          text="Veto Criteria"
          fontWeight={500}
          fontSize={'0.875rem'}
          mb={2}
        />
        <VetoCriteria />
      </Box>
    </>
  );
};

export default FinalCriteriaRule;
