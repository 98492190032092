import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import dayjs from 'dayjs';
import _ from 'lodash';
import { DATE_FORMATE } from '../../../constants/url';
import { IRolesProps } from '../../../types/userAccess';
import { VegaCard, VegaTable } from '../../common';
import VegaLabel from '../../common/VegaLabel';

interface IProps {
  data: any[];
  onViewMoreClick: (roles: IRolesProps[]) => void;
}
const ListOfTeam = ({ data, onViewMoreClick }: IProps) => {
  console.log('ListOfTeam', data);
  const columnDef = [
    {
      field: 'name',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Name
        </Typography>
      ),

      flex: 1,
    },
    {
      field: 'email',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Email
        </Typography>
      ),

      flex: 1,
    },
    {
      field: 'mobileNumber',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Mobile Number
        </Typography>
      ),

      flex: 0.7,
    },
    {
      field: 'team',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Team
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const team = props.row.team;
        return <Typography>{_.startCase(_.toLower(team))}</Typography>;
      },
    },
    {
      field: 'createdAt',
      headerName: (
        <Typography variant="body2" alignItems={'center'}>
          Invite Date
        </Typography>
      ),
      renderCell: (props: any) => {
        return (
          <div className="">
            {props.row.createdAt &&
              dayjs(props.row.createdAt).format(DATE_FORMATE)}
          </div>
        );
      },
      flex: 0.5,
    },
    {
      field: 'role',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Role
        </Typography>
      ),
      renderCell: (props: any) => {
        const roles = props.row.userRoles;
        var firstRole = '-';
        var hasMoreThan2Roles = false;
        if (roles) {
          if (roles.length > 1) {
            hasMoreThan2Roles = roles.length > 2;
            firstRole = roles[0].roleName;
          }
        }
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <VegaLabel
              text={_.startCase(_.toLower(firstRole))}
              color="primary"
            />
            {hasMoreThan2Roles && (
              <Typography fontSize={12}>+{roles.length - 1}</Typography>
            )}
          </Box>
        );
      },
      flex: 1,
    },
    {
      field: 'roleMoreButton',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2"></Typography>
      ),
      renderCell: (props: any) => {
        const roles = props.row.userRoles;
        var hasMoreThan2Roles = false;
        if (roles) {
          if (roles.length > 2) {
            hasMoreThan2Roles = true;
          }
        }

        return (
          <Button
            color="info"
            variant="text"
            disabled={!hasMoreThan2Roles}
            onClick={() => onViewMoreClick(roles)}
          >
            View More
          </Button>
        );
      },
      flex: 1,
    },
  ];
  return (
    <VegaCard fullHeight noPad>
      <VegaTable data={data} columnDef={columnDef} idColumn="userId" />
    </VegaCard>
  );
};

export default ListOfTeam;
