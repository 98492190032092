/** @format */

import { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Login from '../../pages/Login'
import { useClientAuth } from '../../providers/ClientProvider'
import { DASHBOARD_ROUTES, ROUTES, ROUTE_CODE } from '../../routes'
import LosOnboarding from '../../containers/LosOnboarding'
import { LosProvider } from '../../solfen/onboarding/providers/LosProvider'
import { LosAuthProvider } from '../../solfen/onboarding/providers/LosAuthProvider'
import { CompanyPanProvider } from '../../solfen/onboarding/providers/CompanyPanProvider'
import Solfin_LMS_Agent_Container from '../../containers/Solfin_LMS_Agent_Container'
import Solfin_LMS_HQ_Container from '../../containers/Solfin_LMS_HQ_Container'
import AgentLogin from '../../solfen/LMSAgent/pages/AgentLogin'
import ColendingDashboardContainer from '../../containers/ColendingDashboardContainer'
import HqLogin from '../../solfen/LMSHQ/pages/HqLogin'
import { PrivateHqRoute } from './PrivateHqRoute'
import { PrivateAgentRoute } from './PrivateAgentRoute'
import { PrivateOnboardingRoutes } from './PrivateOnboardingRoutes'
import ForgotPassord from '../../pages/ForgotPassord'
import ResetPassword from '../../pages/ResetPassword'
import SetNewPassword from '../../pages/SetNewPassword'
import { useAppConfig } from '../../solfen/config/AppConfigProvider'
import FirestoreConfigFetcher from '../../solfen/config/FirestoreConfigFetcher'

type Props = {}

const RoutingComponent = (props: Props) => {
  const { isAuth } = useClientAuth()
  const navigate = useNavigate()
  const currentPath = window.location.pathname
  const { updatePrivateKey } = useAppConfig()

  useEffect(() => {
    fetchAppConfigAndNavigate()
  }, [])

  async function fetchAppConfigAndNavigate() {
    const appConfig = await new FirestoreConfigFetcher().fetchAppConfig()
    updatePrivateKey(appConfig.privateKey)
  }

  useEffect(() => {
    if (isAuth == true) {
      if (currentPath == ROUTES.HQ_LOGIN) {
        navigate('/' + DASHBOARD_ROUTES.SOLFIN_HQ, {
          replace: true,
        })
      } else if (currentPath == ROUTES.AGENT_LOGIN) {
        navigate('/' + DASHBOARD_ROUTES.SOLFIN_AGENT, {
          replace: true,
        })
      } else if (currentPath == '/') {
        navigate('/' + DASHBOARD_ROUTES.SOLFIN_HQ, { replace: true })
      } else {
        navigate(currentPath, { replace: true })
      }
    } else if (isAuth == false && currentPath == '/') {
      // if not authenticated and no route provided then redirect to hq login page
      navigate('/' + DASHBOARD_ROUTES.SOLFIN_HQ, { replace: true })
    }
  }, [isAuth, currentPath])

  return (
    <Routes>
      <Route path={ROUTES.AGENT_LOGIN} element={<AgentLogin />} />
      <Route path={ROUTES.HQ_LOGIN} element={<HqLogin />} />
      <Route path={ROUTES.SET_NEW_PASSWORD} element={<SetNewPassword />} />
      <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassord />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />

      <Route
        path={DASHBOARD_ROUTES.SOLFIN_ONBOARDING}
        element={
          <PrivateOnboardingRoutes>
            <LosAuthProvider>
              <LosProvider>
                <CompanyPanProvider>
                  <LosOnboarding />
                </CompanyPanProvider>
              </LosProvider>
            </LosAuthProvider>
          </PrivateOnboardingRoutes>
        }
      />
      <Route
        path={DASHBOARD_ROUTES.SOLFIN_AGENT}
        element={
          <PrivateAgentRoute>
            <Solfin_LMS_Agent_Container />
          </PrivateAgentRoute>
        }
      />
      <Route
        path={DASHBOARD_ROUTES.SOLFIN_HQ}
        element={
          <PrivateHqRoute>
            <Solfin_LMS_HQ_Container />
          </PrivateHqRoute>
        }
      />
      <Route
        path={DASHBOARD_ROUTES.SOLFIN_COLENDING}
        element={<ColendingDashboardContainer />}
      />
    </Routes>
  )
}

export default RoutingComponent
