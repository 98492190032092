import React from 'react';
import { Box, Typography, Grid, Skeleton } from '@mui/material';
import LOSTextWrapper from '../../../components/LOSTextWrapper';
import LosStatusPill from '../../../components/LosStatusPill';
import VegaHeader from '../../../../components/common/VegaHeader';
import LosButton from '../../../onboarding/components/common/LosButton';
import { CustomerApplication, Lead } from '../../../onboarding/types/Los';
import { toLowerCase } from '../../../../constants/commonFunction';
import { dateFormat } from '../../../../mcc/variable';

interface IProps {
  leads: Lead[];
  loading: boolean;
}

const LeadsList = ({ leads, loading }: IProps) => {
  return (
    <>
      {leads.map((lead: Lead) => (
        <Box
          sx={{
            p: 2,
            bgcolor: 'white',
            boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
            borderRadius: '6px',
            mb: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <LOSTextWrapper
                header="Lead id"
                loading={loading}
                text={lead.id ?? '--'}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <LOSTextWrapper
                header="customer name"
                loading={loading}
                text={lead.fullName ?? '--'}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <LOSTextWrapper
                header="mobile no."
                loading={loading}
                text={lead?.phone ?? '--'}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <LOSTextWrapper
                header="Created At"
                loading={loading}
                text={lead?.createdAt ? dateFormat(lead.createdAt) : '--'}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <LOSTextWrapper
                header="agent name"
                loading={loading}
                text={lead?.agentName ?? '--'}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <LOSTextWrapper
                header="Pre Eligiblity"
                loading={loading}
                text={'--'}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              {loading ? (
                <Skeleton />
              ) : (
                <>
                  <VegaHeader text={'status'} />
                  <LosStatusPill status={lead.status} />
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
};

export default LeadsList;
