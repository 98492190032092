import { Grid, Stack } from '@mui/material';
import { useState } from 'react';
import LosText from '../../../components/common/LosText';
import LosAppBar from '../../../components/LosAppBar';
import { LosFormButtonView } from '../../../components/LosFormButton';
import LosFormContainer from '../../../components/LosFormContainer';
import SelfieCamera from '../../../components/SelfieCamera';
import {
  SelfiePlaceholder,
  EyeGlasses,
  UserProfileIcon,
  BackgroundImageIcon,
} from '../../../icons/LosIcons';
import { useLos } from '../../../providers/LosProvider';
import { SelfieViewState, useSelfie } from '../SelfieProvider';
import { SelfiePrecaution } from './SelfiePrecaution';

enum ViewState {
  Initial,
  Capturing,
}

function SelfieRejectedView() {
  const { currentStepMetaData } = useLos();
  const { onViewStateChange, onSelfieCaptureChange } = useSelfie();
  const [viewState, setViewState] = useState<ViewState>(ViewState.Initial);

  function updateViewState(viewState: ViewState) {
    setViewState(viewState);
  }

  function onSelfieCaptured(selfie: string) {
    onSelfieCaptureChange(selfie);
    onViewStateChange(SelfieViewState.Uploading);
  }

  if (viewState == ViewState.Capturing) {
    return (
      <SelfieCamera
        isActive={viewState == ViewState.Capturing}
        onPictureClicked={onSelfieCaptured}
        desiredResolution={{
          width: 1280,
          height: 720,
        }}
      ></SelfieCamera>
    );
  }

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            onClick={() => {
              updateViewState(ViewState.Capturing);
            }}
            text="Take Selfie"
          />
        );
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        );
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent={'center'}
          >
            <SelfiePlaceholder />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            alignItems="center"
            justifyContent={'center'}
            display="flex"
            flexDirection={'row'}
          >
            <LosText text="Your photo did not match with KYC documents " />
          </Stack>
        </Grid>
        <Grid item xs={12} alignItems="center" justifyContent={'stretch'}>
          <Grid container columns={3}>
            <Grid item xs={1}>
              <SelfiePrecaution text={'No \nEye Glass'} icon={<EyeGlasses />} />
            </Grid>
            <Grid item xs={1}>
              <SelfiePrecaution
                text={'Avoid \nblurred photo'}
                icon={<UserProfileIcon />}
              />
            </Grid>
            <Grid item xs={1}>
              <SelfiePrecaution
                text={'No Object \nin Background'}
                icon={<BackgroundImageIcon />}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LosFormContainer>
  );
}

export default SelfieRejectedView;
