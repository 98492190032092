import { CancelOutlined } from '@mui/icons-material';
import { Box, Grid, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCountdown } from '../../hooks/useCountdown';
import { CloseDialogIcon } from '../../icons/LosIcons';

import { StringUtility } from '../../utils/StringUtility';
import LosButton from '../common/LosButton';
import LosDialog from '../common/LosDialog';
import LosText from '../common/LosText';
import LosTextField from '../common/LosTextField';
import LosOnboardingButton from '../common/LosOnboardingButton';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (otp: string) => void;
  onResendOtp: () => void;
};

const COUNT_DOWN_TIMER_SECONDS = 40;
function AadharOtpInputDialog({ open, onClose, onSubmit, onResendOtp }: Props) {
  const [otp, setOtp] = useState<string>('');
  const { minutes, seconds, startTimer } = useCountdown();
  const [enableResendButton, setEnableResendButton] = useState<boolean>(false);

  const isInputValid = () => {
    return otp.length == 6;
  };

  function onSubmitClick() {
    if (isInputValid()) {
      onSubmit(otp);
      onCloseClick();
    }
  }

  function onCloseClick() {
    setOtp('');
    onClose();
  }

  function onOtpChange(value: string) {
    if (value.length > 6) return;
    setOtp(value);
  }

  useEffect(() => {
    const canEnableResendButton = minutes == 0 && seconds == 0;
    if (canEnableResendButton != enableResendButton) {
      setEnableResendButton(canEnableResendButton);
    }
  }, [minutes, seconds]);

  useEffect(() => {
    if (open) {
      startTimer(COUNT_DOWN_TIMER_SECONDS);
    }
  }, [open]);

  function onResendClick() {
    onResendOtp();
    startTimer(COUNT_DOWN_TIMER_SECONDS);
  }

  return (
    <LosDialog open={open} onClose={onCloseClick}>
      <div
        style={{
          maxWidth: '22.5rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1.5rem',
        }}
      >
        <Stack
          direction={'row'}
          alignItems="start"
          justifyContent={'space-between'}
        >
          <Stack>
            <LosText text="Verify your Aadhaar" />
            <LosText
              text="We have sent you a verification OTP on your aadhaar registered mobile number"
              variant="caption"
            />
          </Stack>

          <IconButton onClick={onCloseClick}>
            <CloseDialogIcon />
          </IconButton>
        </Stack>
        <Grid container>
          <Grid item xs={12}>
            <LosTextField
              fullWidth
              value={otp}
              onChange={(e) => {
                onOtpChange(e.target.value);
              }}
              isValid={StringUtility.validateOTP(otp)}
            />
            <Stack
              direction={'row'}
              justifyContent="end"
              alignItems={'center'}
              marginTop={'0.5rem'}
              marginBottom="2rem"
            >
              <Box
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'start',
                  gap: '0.5rem',
                }}
                onClick={onResendClick}
              >
                <LosText
                  display={enableResendButton == false ? 'block' : 'none'}
                  text={`${minutes}:${seconds}s`}
                  variant="caption"
                />
                <LosText
                  text="Resend OTP"
                  style={{
                    color: enableResendButton ? '#1047DC' : '#676B76',
                    fontSize: '0.8125rem',
                    fontWeight: 450,
                    lineHeight: '120%',
                  }}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <LosOnboardingButton
          fullWidth
          text="Verify Aadhaar"
          disabled={isInputValid() == false}
          onClick={onSubmitClick}
        />
      </div>
    </LosDialog>
  );
}

export default AadharOtpInputDialog;
