import { CircularProgress } from '@mui/material';
import { LosAuthState, useLosAuth } from '../../providers/LosAuthProvider';
import MobileNumberInput from './MobileNumberInput';
import MobileNumberOtpVerification from './MobileNumberOtpVerification';

function MobileValidationContainer() {
  const { currentState, loading } = useLosAuth();

  if (loading) {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (currentState == LosAuthState.MobileInput) {
    return <MobileNumberInput />;
  } else if (currentState == LosAuthState.OtpVerification) {
    return <MobileNumberOtpVerification />;
  }
  return <div>-</div>;
}

export default MobileValidationContainer;
