import { createContext, useContext, useState } from 'react';

export enum SelfieViewState {
  Initial,
  CaptureSelfie,
  Uploading,
  Rejected,
  Approved,
}

type SelfieContextType = {
  capturedSelfie: any;
  onSelfieCaptureChange: (value: any) => void;
  viewState: SelfieViewState;
  onViewStateChange: (viewState: SelfieViewState) => void;
};

const SelfieContext = createContext<SelfieContextType | null>(null);

export const useSelfie = () => useContext(SelfieContext) as SelfieContextType;

export const SelfieProvider = ({ children }: any) => {
  const [viewState, setViewState] = useState<SelfieViewState>(
    SelfieViewState.Initial
  );
  const [capturedSelfie, setCapturedSelfie] = useState<any>();

  function _updateViewState(updatedState: SelfieViewState) {
    setViewState(updatedState);
  }

  function _updateCapturedSelfie(value: any) {
    setCapturedSelfie(value);
  }

  return (
    <SelfieContext.Provider
      value={{
        viewState: viewState,
        onViewStateChange: _updateViewState,
        capturedSelfie: capturedSelfie,
        onSelfieCaptureChange: _updateCapturedSelfie,
      }}
    >
      {children}
    </SelfieContext.Provider>
  );
};
