import { CircularProgress, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import { ReadOnlyFormField } from '../../../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField';
import { LosService } from '../../../api/LosService';
import { ReadOnlyLosFileSelect } from '../../../components/common/LosFileSelect';
import LosFormInputField from '../../../components/common/LosFormInputField';
import LosText from '../../../components/common/LosText';
import { useApplicationPreview } from '../../../providers/ApplicationPreviewProvider';
import { FinancialDetailsPreviewData } from '../types';

function FinancialDetailsPreview() {
  const { setSnackbar } = useSnackbar();
  const { fetchFiancialDetails, applicationId } = useApplicationPreview();
  const [loading, setLoading] = useState<boolean>(false);
  const [financialDetails, setFinancialDetails] = useState<
    Partial<FinancialDetailsPreviewData>
  >({});

  async function fetchDetails() {
    try {
      if (!applicationId) return;
      setLoading(true);
      const response = await fetchFiancialDetails(applicationId);
      setFinancialDetails(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const hasAuditedFinancials = () =>
    (financialDetails.auditedFinancials ?? []).length > 0;
  const hasItr = () => (financialDetails.itr ?? []).length > 0;
  const hasBankStatement = () =>
    (financialDetails.bankStatement ?? []).length > 0;
  const hasPayslips = () => (financialDetails.paySlips ?? []).length > 0;

  const getDisplayType = (hasValue: boolean) => {
    if (hasValue) return 'block';
    return 'none';
  };

  async function onDownload(documentType: string) {
    try {
      if (!applicationId) return;
      const response = await LosService.getDocument({
        documentType: documentType,
        applicationId: applicationId,
      });
      window.open(response.url, '__blank');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  useEffect(() => {
    fetchDetails();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        {financialDetails.companyName && (
          <Grid item xs={12}>
            <LosText text="Employer Details" />
          </Grid>
        )}
        {financialDetails.companyName && (
          <Grid item xs={12}>
            <ReadOnlyFormField
              title="Company Name"
              description={financialDetails.companyName}
            />
          </Grid>
        )}

        <Grid item xs={12} display={getDisplayType(hasPayslips())}>
          <LosFormInputField label="PaySlips (Last 3 months)">
            <Grid container rowSpacing={'1rem'} columnGap={'1rem'}>
              {financialDetails.paySlips?.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} sm={3}>
                    <ReadOnlyLosFileSelect
                      fileName={item.documentName}
                      onDownload={() => {
                        onDownload(item.documentType);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </LosFormInputField>
        </Grid>

        <Grid item xs={12} display={getDisplayType(hasBankStatement())}>
          <LosFormInputField label="Recent Bank Statement (Last 6 months)">
            <Grid container rowSpacing={'1rem'} columnGap={'1rem'}>
              {financialDetails.bankStatement?.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} sm={3}>
                    <ReadOnlyLosFileSelect
                      fileName={item.documentName}
                      onDownload={() => {
                        onDownload(item.documentType);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </LosFormInputField>
        </Grid>

        <Grid item xs={12} display={getDisplayType(hasItr())}>
          <LosFormInputField label="Income Tax Return (Last 2 years)">
            <Grid container rowSpacing={'1rem'} columnGap={'1rem'}>
              {financialDetails.itr?.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} sm={3}>
                    <ReadOnlyLosFileSelect
                      fileName={item.documentName}
                      onDownload={() => {
                        onDownload(item.documentType);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </LosFormInputField>
        </Grid>

        <Grid item xs={12} display={getDisplayType(hasAuditedFinancials())}>
          <LosFormInputField label="Audited Financial Report (Last 2 years)">
            <Grid container rowSpacing={'1rem'} columnGap={'1rem'}>
              {financialDetails.auditedFinancials?.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} sm={3}>
                    <ReadOnlyLosFileSelect
                      fileName={item.documentName}
                      onDownload={() => {
                        onDownload(item.documentType);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </LosFormInputField>
        </Grid>
      </Grid>
    </div>
  );
}

export default FinancialDetailsPreview;
