export enum StorageKeys {
  CUSTOMER_ID = 'CUSTOMER_ID',
  APPLICATION_ID = 'APPLICATION_ID',
  PROGRAM_ID = 'PROGRAM_ID',
  CUSTOMER_APPLICATION = 'CUSTOMER_APPLICATION',
  hasFetchedStateBefore = 'hasFetchedStateBefore',
}

export class LocalStorageUtility {
  static setItem<T>(key: StorageKeys, value: T) {
    try {
      const jsonValue = JSON.stringify(value)
      console.log('jsonValue in setItem ', key, jsonValue)
      window.localStorage.setItem(key, jsonValue)
    } catch (e) {
      console.error(`Error setting item in AsyncStorage - ${key} :  ${e}`)
    }
  }

  static getItem<T>(key: StorageKeys): T | null {
    try {
      const jsonValue = window.localStorage.getItem(key)
      var value = jsonValue != null ? JSON.parse(jsonValue) : null
      return value
    } catch (e) {
      console.error(`Error getting item from AsyncStorage: ${e}`)
      return null
    }
  }

  static removeItem(key: StorageKeys) {
    try {
      window.localStorage.removeItem(key)
    } catch (e) {
      console.error(`Error removing item from AsyncStorage: ${e}`)
    }
  }

  static clear() {
    try {
      const keys = Object.keys(StorageKeys)
      keys.forEach(item => window.localStorage.removeItem(item))
    } catch (error) {
      console.error(`Error clearing AsyncStorage: ${error}`)
    }
  }
}
