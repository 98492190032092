import { Cancel, CancelOutlined, Close } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Modal,
  Typography,
  Dialog,
  DialogProps,
  Stack,
} from '@mui/material';
import React from 'react';
import { AntSwitch, toLowerCase } from '../../constants/commonFunction';
import { BLUE, COMMON, GREY, PRIMARY } from '../../constants/style';
import VegaOutlineButton from '../../components/common/VegaOutlineButton';
import VegaContainedButton from '../../components/common/VegaContainedButton';
import VegaLabelWrapper from '../../components/common/VegaLabelWrapper';
import LOSLabel from './LOSLabel';
import { RED } from './colors/fontColor';

export interface ILOSButtonsProps {
  text: string;
  onSubmit: () => void;
  icon: JSX.Element | string;
  isOutline: boolean;
  disabled?: boolean;
  color: string;
}

interface IModalProps {
  open: boolean;
  children: JSX.Element;
  header: string;
  status: string | null;
  handleClose: () => void;
  buttonGroup: ILOSButtonsProps[];
  maxWidth: DialogProps['maxWidth'];
}

const style = {};

const LOSModal = ({
  open,
  children,
  header,
  handleClose,
  buttonGroup,
  maxWidth,
  status,
}: IModalProps) => {
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        sx={{ borderRadius: '20px' }}
        onClose={handleClose}
        open={open}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 2,
            py: 1,
            // borderBottom: `2px solid ${GREY.grey}`,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Stack direction={'row'} alignItems={'center'}>
              <label
                className="font-aspekta-600"
                style={{
                  fontSize: 17,
                  color: COMMON.darkGray,
                  lineHeight: '15.6px',
                  marginRight: '10px',
                }}
              >
                {header}
              </label>
              {status && (
                <LOSLabel
                  text={toLowerCase(status)}
                  color={
                    status === 'PENDING' || status === 'IN_PROGRESS'
                      ? 'pending'
                      : status === 'APPROVED'
                      ? 'approved'
                      : 'rejected'
                  }
                />
              )}
            </Stack>
          </Box>
          <IconButton onClick={handleClose}>
            <Close sx={{ color: '#3A4A5F' }} />
          </IconButton>
        </Box>
        <Box>{children}</Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            px: 2,
            py: 1.5,
            borderTop: `2px solid ${GREY.grey}`,
          }}
        >
          {buttonGroup.map((button: ILOSButtonsProps) => {
            if (button.isOutline) {
              return (
                <VegaOutlineButton
                  py={1}
                  text={button.text}
                  isPrimary
                  disabled={button.disabled}
                  onClick={button.onSubmit}
                />
              );
            } else {
              if (button.color === 'success') {
                return (
                  <VegaContainedButton
                    text={button.text}
                    startIcon={button.icon ?? ''}
                    isSuccess
                    py={1}
                    disabled={button.disabled}
                    onClick={button.onSubmit}
                  />
                );
              } else if (button.color === 'primary') {
                return (
                  <VegaContainedButton
                    text={button.text}
                    startIcon={button.icon ?? ''}
                    isPrimary
                    py={1}
                    disabled={button.disabled}
                    onClick={button.onSubmit}
                  />
                );
              } else if (button.color === 'purple') {
                return (
                  <VegaContainedButton
                    text={button.text}
                    startIcon={button.icon ?? ''}
                    isPurple
                    py={1}
                    disabled={button.disabled}
                    onClick={button.onSubmit}
                  />
                );
              } else if (button.color === 'reject') {
                return (
                  <VegaContainedButton
                    text={button.text}
                    startIcon={button.icon ?? ''}
                    isDanger
                    py={1}
                    disabled={button.disabled}
                    onClick={button.onSubmit}
                  />
                );
              } else if (button.color === 'error') {
                return (
                  <VegaContainedButton
                    text={button.text}
                    startIcon={button.icon ?? ''}
                    sx={{
                      bgcolor: RED.red,
                    }}
                    py={1}
                    disabled={button.disabled}
                    onClick={button.onSubmit}
                  />
                );
              }
            }
          })}
        </Box>
      </Dialog>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-modal-title"
        aria-describedby="responsive-modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            maxWidth: { width },
            minWidth: 450,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '10px',
          }}
        >
          
        </Box>
      </Modal> */}
    </>
  );
};

export default LOSModal;
