import { CircularProgress, Divider, Grid } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useSnackbar } from '../../../../../providers/SnackbarProvider'
import LosFormInputField from '../../../../onboarding/components/common/LosFormInputField'
import LosText from '../../../../onboarding/components/common/LosText'
import { LoanManagementService } from '../../../api/LoanManagementService'
import { useActiveLoans } from '../../../providers/ActiveLoansProvider'
import { LoanApplicationDetails } from '../../../types/Lms'
import PdfPreview from '../../../../components/PdfPreview'
import { LosService } from '../../../../onboarding/api/LosService'
import { LosDocumentType } from '../../../../onboarding/types/Los'

function ApplicationDetailsTab() {
  const { fetchApplicationDetails, selectedLoan } = useActiveLoans()

  const { setSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)
  const [applicationDetails, setApplicationDetails] = useState<
    Partial<LoanApplicationDetails>
  >({})

  async function fetchDetails() {
    try {
      setApplicationDetails({})
      setLoading(true)
      const response = await fetchApplicationDetails()
      setApplicationDetails(response)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  async function downloadLoanAgreement() {
    try {
      const applicationId = applicationDetails.application?.id
      if (!applicationId) return
      const response = await LosService.getDocument({
        applicationId: applicationId,
        documentType: LosDocumentType.SANCTION_LETTER,
      })
      const url = response.url
      window.open(url, '__blank')
    } catch (error) {
      setSnackbar('Failed to download loan agreement', 'error')
    }
  }

  async function downloadLoanSanctionLetter() {
    try {
      const applicationId = applicationDetails.application?.id
      if (!applicationId) return
      const response = await LoanManagementService.getSanctionLetter(
        applicationId
      )
      const url = response.url
      window.open(url, '__blank')
    } catch (error) {
      setSnackbar('Failed to download loan agreement', 'error')
    }
  }

  const getApplication = () => applicationDetails?.application

  const getAgent = () => applicationDetails?.agent

  const getEntityDisplayText = () => {
    const type = getApplication()?.losEntityType
    var displayText = '-'
    if (type) {
      displayText = _.startCase(_.toLower(type))
    }

    return displayText
  }

  useEffect(() => {
    fetchDetails()
  }, [selectedLoan])

  return (
    <div
      style={{
        padding: '1rem',
      }}
    >
      <div
        style={{
          display: loading ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
      <div
        style={{
          display: loading == false ? 'flex' : 'none',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <LosText
          text='Applicant Details'
          color={'#1B1D22'}
          fontSize={'0.875rem'}
          fontWeight={500}
          lineHeight={'140%'}
        />
        <Grid container spacing={'1rem'}>
          <Grid item xs={3}>
            <Field
              title='Customer Id'
              description={getApplication()?.customerId}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              title='Applicant Name'
              description={getApplication()?.customerName}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              title='Mobile Number'
              description={getApplication()?.phone}
            />
          </Grid>
          <Grid item xs={3}>
            <Field title='Entity' description={getEntityDisplayText()} />
          </Grid>
        </Grid>
      </div>
      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          display: loading == false ? 'block' : 'none',
        }}
      />
      <div
        style={{
          display: loading == false ? 'flex' : 'none',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <LosText text='Agent Details' />
        <Grid container spacing={'1rem'}>
          <Grid item xs={3}>
            <Field title='Agent Id' description={getAgent()?.id} />
          </Grid>
          <Grid item xs={3}>
            <Field title='Agent Name' description={getAgent()?.name} />
          </Grid>
        </Grid>
      </div>

      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          display: loading == false ? 'block' : 'none',
        }}
      />
      <div
        style={{
          display: loading == false ? 'flex' : 'none',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <LosText text='Loan Documents' />
        <Grid container spacing={'1rem'}>
          {/* <Grid item xs={3}>
            <LosFormInputField label="Loan Application">
              <PdfPreview
                onDownload={function (): void {}}
                fileName={'Loan Application'}
              />
            </LosFormInputField>
          </Grid> */}
          <Grid item xs={3}>
            <LosFormInputField label='Sanction Letter'>
              <PdfPreview
                onDownload={downloadLoanSanctionLetter}
                fileName={'Sanction Letter'}
              />
            </LosFormInputField>
          </Grid>
          <Grid item xs={3}>
            <LosFormInputField label='Signed Agreement'>
              <PdfPreview
                onDownload={downloadLoanAgreement}
                fileName={'Signed Agreement'}
              />
            </LosFormInputField>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default ApplicationDetailsTab

const Field = (data: { title: string; description?: string }) => {
  return (
    <LosFormInputField label={data.title}>
      <LosText
        text={data.description ?? '-'}
        fontSize={'0.75rem'}
        fontWeight={400}
        lineHeight={'130%'}
        color={'#1B1D22'}
      />
    </LosFormInputField>
  )
}
