import { Grid } from "@mui/material";
import { useState } from "react";
import LosButton from "../../components/common/LosButton";
import LosFormInputField from "../../components/common/LosFormInputField";
import LosText from "../../components/common/LosText";
import LosTextField from "../../components/common/LosTextField";
import LosFormContainer from "../../components/LosFormContainer";
import { useLosAuth } from "../../providers/LosAuthProvider";
import LosOnboardingButton from "../../components/common/LosOnboardingButton";
import { VegaPayLogo } from "../../icons/LosIcons";

function MobileNumberInput() {
  const { onMobileNumberChange, generateOtp, loading } = useLosAuth();
  const [mobileNumber, setMobileNumber] = useState<string>();

  function handleMobileNumberChange(value: string) {
    if (value.length <= 10) {
      setMobileNumber(value);
      onMobileNumberChange(value);
    }
  }

  const isInputValid = () => {
    let isMobileNumberValid =
      mobileNumber != null && validateMobileNumber(mobileNumber);
    return isMobileNumberValid;
  };

  function onSendOtpClick() {
    generateOtp();
  }

  return (
    <LosFormContainer>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {/* <SolfenLogo elevation={0} background={'rgba(231, 237, 252, 1)'} /> */}
        <div>
          <img src="https://solfin.co.in/images/solfin-logo.svg"></img>
        </div>
        {/* <VegaPayLogo/> */}
        <LosText
          text="Sign up to get started"
          style={{
            fontWeight: 600,
            lineHeight: "120%",
            color: "#1B1D22",
            fontSize: "1.0625rem",
          }}
        />
        <Grid container rowSpacing={"1.5rem"}>
          <Grid item xs={12}>
            <LosFormInputField label="Mobile Number">
              <LosTextField
                type={"number"}
                value={mobileNumber}
                onChange={(e) => {
                  handleMobileNumberChange(e.target.value);
                }}
                isValid={isInputValid()}
              />
            </LosFormInputField>
          </Grid>
        </Grid>
        <Grid container rowSpacing={"1.5rem"}>
          <Grid item xs={12}>
            <LosOnboardingButton
              fullWidth
              text="Send OTP"
              disabled={isInputValid() == false}
              onClick={onSendOtpClick}
              loading={loading}
            />
          </Grid>
        </Grid>
      </div>
    </LosFormContainer>
  );
}

export default MobileNumberInput;

const validateMobileNumber = (value: string) => {
  const digitsOnly = value.replace(/\D/g, "");

  return /^\d{10}$/.test(digitsOnly);
};
