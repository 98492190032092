import { Card, Grid, Stack } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useLos } from '../providers/LosProvider'
import {
  AadharMetaData,
  ApplicationMember,
  CustomerAadharData,
  CustomerPanData,
  DocumentStatus,
  PanMetadata,
  UserAadharStatus,
  UserPanStatus,
  VerificationStatus,
} from '../types/Los'
import LosText from './common/LosText'
import { LosService } from '../api/LosService'

type Props = {
  // member: ApplicationMember;
  userId: string
}

function PromoterDetailsCard({ userId }: Props) {
  const [isPanVerified, setIsPanVerified] = useState<boolean>(false)
  const [isAadhaarVerified, setIsAadhaarVerified] = useState<boolean>(false)
  const [isSelfieVerified, setIsSelfieVerified] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [promoterPanDetails, setPromoterPanDetails] = useState<PanMetadata>()
  const [promoterAadharDetails, setPromoterAadharDetails] =
    useState<AadharMetaData>()

  async function fetchPromoterPanDetails() {
    setLoading(true)
    // const response = await fetchPanDetailsForPanInfoId(member.panInfoId)
    const response = await LosService.getPanDetailsByUserId(userId)
    setIsPanVerified(!!response.valid)
    setPromoterPanDetails(response)
    setLoading(false)
  }

  async function fetchPromoterAadharDetails() {
    setLoading(true)
    // const response = await fetchAadharDetailsForAadharInfoId(
    //   member.aadhaarInfoId
    // )
    const response = await LosService.getAdhaarDetailsByUserId(userId)

    setIsAadhaarVerified(response.uid != null)
    setPromoterAadharDetails(response)
    setLoading(false)
  }

  async function fetchSelfieDeatils() {
    try {
      setLoading(true)
      const response = await LosService.getSelfieDetailsByUserId(userId)
      setIsSelfieVerified(response.status === DocumentStatus.APPROVED)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const getPanStatusDisplayText = () => {
    if (isPanVerified) {
      return _.startCase(_.toLower(VerificationStatus.VERIFIED))
    }
    return _.startCase(_.toLower(VerificationStatus.PENDING))
  }

  const getAadharStatusDisplayText = () => {
    if (isAadhaarVerified) {
      return _.startCase(_.toLower(VerificationStatus.VERIFIED))
    }
    return _.startCase(_.toLower(VerificationStatus.PENDING))
  }

  const getSelfieStatusDisplayText = () => {
    if (isSelfieVerified) {
      return _.startCase(_.toLower(VerificationStatus.VERIFIED))
    }
    return _.startCase(_.toLower(VerificationStatus.PENDING))
  }

  useEffect(() => {
    fetchPromoterPanDetails()
    fetchPromoterAadharDetails()
    fetchSelfieDeatils()
    // const aadhaarAcceptedDate = promoterAadharDetails?.createdAt ?? ''
    // const panAcceptedDate = promoterPanDetails?.createdAt ?? ''
    // const bureauAcceptedDate = member.acceptBureauPanTimeStamp ?? ''
    // const aadhaarAcceptedDate = member.acceptAadhaarTimeStamp ?? ''
    // const panAcceptedDate = member.acceptPanTimeStamp ?? ''
    // const bureauAcceptedDate = member.acceptBureauPanTimeStamp ?? ''

    setIsAadhaarVerified(
      promoterAadharDetails?.status === UserAadharStatus.APPROVED
    )
    setIsPanVerified(promoterPanDetails?.status === UserPanStatus.APPROVED)
  }, [])

  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '0.62rem',
        borderRadius: '7.317px',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        background: 'white',
      }}
    >
      <Stack
        direction={'row'}
        alignItems='center'
        justifyContent={'space-between'}
        spacing={'0.5rem'}
        style={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <LosText text='Promoter Details' />
      </Stack>
      <Grid
        container
        direction={'row'}
        justifyContent='start'
        style={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <Grid item xs={6}>
          <Label label='Name' description={promoterAadharDetails?.name} />
        </Grid>

        {/* <Grid item xs={6}>
          <Label
            label='Designation'
            description={_.startCase(_.toLower(member.basicInfo.designation))}
          />
        </Grid> */}
      </Grid>

      <Grid
        container
        direction={'row'}
        justifyContent='start'
        style={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <Grid item xs={6}>
          <Label label='Pan Card' description={promoterPanDetails?.number} />
        </Grid>
        <Grid item xs={6}>
          <Label
            label='Status'
            description={getPanStatusDisplayText()}
            color={statusColor(isPanVerified)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction={'row'}
        justifyContent='start'
        style={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <Grid item xs={6}>
          <Label label='AADHAR Card' description={promoterAadharDetails?.uid} />
        </Grid>
        <Grid item xs={6}>
          <Label
            label='Status'
            description={getAadharStatusDisplayText()}
            color={statusColor(isAadhaarVerified)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction={'row'}
        justifyContent='start'
        style={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <Grid item xs={6}>
          <Label
            label='Photo Verification'
            description={getSelfieStatusDisplayText()}
            color={statusColor(isSelfieVerified)}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default PromoterDetailsCard

const Label = (data: {
  label: string
  description?: string
  color?: string
}) => {
  return (
    <Stack spacing={'0px'}>
      <LosText text={data.label} variant='caption' />
      <LosText
        text={data.description ?? '-'}
        fontSize={'10px'}
        {...(data.color && {
          color: data.color,
        })}
      />
    </Stack>
  )
}

const statusColor = (verified: boolean) => {
  if (verified) {
    return '#36913F'
  } else {
    return '#FFC21B'
  }
}
