import { CustomerApplication, VegaCustomer } from '../../onboarding/types/Los';

export type CustomLoanDto = {
  id: string;
  accountId: string;
  programId: string;
  installmentPolicyId: string;
  loanAmount: number;
  loanType: string;
  loanDispersalType: string;
  customerName: string;
  mobile: string;
  lan: string;
  parentLoanId: string;
  lenderId: string;
  loanPercentage: number;
  currency: string;
  interestRate: number;
  interestType: string;
  interestAmount: number;
  repaymentAmount: number;
  installmentAmount: number;
  installmentCount: number;
  loanStartDate: string;
  overDueAmount: number;
  interestAccure: number;
  duePrincipal: number;
  applicationId: string;
  lendingType: string;
  childLoans: Loan[];
  firstEmiDate: string;
  paidAmount: number;
  surplus: number;
  status: string;
  tentativeLoanClosingDate: string;
  loanClosedDate: string;
  tenure: number;
  tenureUnit: string;
  firstInstallmentPayment: string;
  installmentFrequency: string;
  amortizationType: string;
  gapInterest: number;
  reAmortisationRequired: boolean;
  fileId: string;
  createdAt: string;
  updatedAt: string;
};

export type Loan = {
  id: string;
  account_id: string;
  program_id: string;
  installment_policy_id: string;
  loan_amount: number;
  loan_type: string;
  loan_dispersal_type: string;
  lan: string;
  parent_loan_id: string;
  lender_id: string;
  loan_percentage: string;
  currency: string;
  interest_rate: number;
  interest_type: string;
  interest_amount: number;
  repayment_amount: number;
  installment_amount: string;
  installment_count: number;
  loan_start_date: string;
  over_due_amount: number;
  interest_accure: number;
  due_principal: number;
  application_id: string;
  paid_amount: number;
  surplus: number;
  status: string;
  tentative_loan_closing_date: string;
  loan_closed_date: string;
  tenure: number;
  tenure_unit: string;
  first_installment_payment: string;
  first_emi_date: string;
  installment_frequency: string;
  amortization_type: string;
  gap_interest: number;
  re_amortisation_required: boolean;
  file_id: string;
  created_at: string;
  updated_at: string;
  child_loans: Loan[];
};

export type LoanLedger = {
  id: string;
  account_id: string;
  loan_id: string;
  loan_ledger_category: string;
  amount: number;
  principle: number;
  interest: number;
  interest_type: null;
  other_charges: number;
  balance: null;
  due_date: string;
  type: string;
  meta_data: null;
  currency: string;
  description: string;
  date: string;
  created_at: string;
  updated_at: string;
};

export type LoanEmiSchedule = {
  id: string;
  loan_id: string;
  program_id: string;
  account_id: string;
  installment_amount: number;
  principle: number;
  interest: number;
  gap_interest: null;
  paid_amount: number;
  fee: null;
  paid_principal: null;
  paid_interest: null;
  paid_fee: null;
  currency: string;
  paid_date: string;
  due_date: string;
  status: string;
  billing_status: string;
  created_at: string;
  updated_at: string;
};

export enum LoanEmiScheduleStatus {
  PAID = 'PAID', // green
  UNPAID = 'UNPAID', // red
  PARTIAL_PAID = 'PARTIAL_PAID', // yellow
  BILLED = 'BILLED',
  UNBILLED = 'UNBILLED',
  REAMORTIZED = 'REAMORTIZED',
  MOVED_TO_LEDGER = 'MOVED_TO_LEDGER', // orange
}

export type LoanDispersalSchedule = {
  id: string;
  createdAt: string;
  updatedAt: string;
  loanId: string;
  amount: number;
  scheduleDate: string;
  dispersalDate: string;
  utrNo: string;
};

export type Agent = {
  id: string;
  programId: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  email: string;
  phone: string;
  gender: string;
  selfie: string;
  status: string;
  clientId: string;
  address: Partial<Address>;
  latitude: string;
  longitude: string;
};

export type LoanApplicationDetails = {
  customer: VegaCustomer;
  agent: Agent;
  application: CustomerApplication;
};

export type LoanDetails = {
  customer: VegaCustomer;
  loan: Loan;
  childLoans: any[];
  trenches: LoanDispersalSchedule[];
  application: CustomerApplication;
};

export type Address = {
  id: string;
  createdAt: string;
  updatedAt: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  pincode: string;
  country: string;
};

export type Colender = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  bankName: string;
  accountHolderName: string;
  accountNo: string;
  ifscCode: string;
  branch: string;
  interest: number;
  status: string;
  isPrimaryLender: boolean;
  tin: string;
  cin: string;
  accountNumber: string;
  registrationDate: string;
  panNo: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  programId: string;
};

export enum LoanType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  COLENDED = 'COLENDED",',
}

export enum AccrualType {
  ACTUAL_BY_ACTUAL = 'ACTUAL_BY_ACTUAL',
  ACTUAL_BY_365 = 'ACTUAL_BY_365',
  ACTUAL_BY_360 = 'ACTUAL_BY_360',
}

export enum LoanDispersalType {
  SINGLE_DISPERSAL = 'SINGLE_DISPERSAL',
  MULTIPLE_DISPERSAL = 'MULTIPLE_DISPERSAL',
}

export enum InterestType {
  FLAT = 'FLAT',
  REDUCING_BALANCE = 'REDUCING_BALANCE',
  WEIGHTED_AVERAGE = 'WEIGHTED_AVERAGE',
}
export enum LoanStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  CLOSED = 'CLOSED',
}
export enum TenureUnit {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}
export enum CycleFrequency {
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
}
export enum AmortizationType {
  ADVANCE = 'ADVANCE',
  ARREAR = 'ARREAR',
}

export interface ColendingPolicy {
  policy_id: string;
  policy_name: string;
  policy_type: string;
  policy_description: string;
  is_override: boolean;
  start_date: string;
  end_date: string;
  priority: string;
  status: string;
  cohort_id: string;
  updated_at: string;
  colender_configuration_list: ColenderConfiguration[];
}

export interface ColenderConfiguration {
  program_id: string;
  colender_id: string;
  min_loan_amount: number;
  max_loan_amount: number;
  interest_rate: number;
  minimum_interest_rate: number;
  interest_type: string;
  start_date: string;
  end_date: string;
  loan_processing_fee: {
    value_type: string;
    number: number;
  };
  late_fee: {
    value_type: string;
    number: number;
  };
  loan_amount_percentage: number;
  is_primary_lender_configuration: boolean;
  status: string;
  grace_period: number;
  filter: {
    min_age: number;
    min_cibil: number;
  };
  cycle_frequency: string;
  precision_by_interest: number;
  day_count_method: string;
}

export interface ColenderFeeDetails {
  fee_type: string;
  value: {
    value_type: string;
    number: number;
  };
  colender_contributions: ColenderContribution[];
}

export interface ColenderContribution {
  colenderId: string;
  percentage: number;
}

export interface ColenderFeePolicy {
  policy_id: string;
  policy_name: string;
  policy_type: string;
  policy_description: string;
  is_override: boolean;
  start_date: string;
  end_date: string;
  priority: string;
  status: string;
  cohort_id: string;
  updated_at: string;
  colender_fee_details_list: ColenderFeeDetails[];
  default_colender_contributions: ColenderContribution[];
}

export const DEFAULT_COLENDER_CONFIGURATION: ColenderConfiguration = {
  program_id: '',
  colender_id: '',
  min_loan_amount: 10000.0,
  max_loan_amount: 1000000.0,
  interest_rate: 10.0,
  minimum_interest_rate: 5.0,
  interest_type: 'WEIGHTED_AVERAGE',
  start_date: '2023-05-20T11:18:20.46421Z',
  end_date: '2026-05-20T11:18:20.46421Z',
  loan_processing_fee: {
    value_type: 'AMOUNT',
    number: 1000.0,
  },
  late_fee: {
    value_type: 'AMOUNT',
    number: 1000.0,
  },
  loan_amount_percentage: 20.0,
  is_primary_lender_configuration: true,
  status: 'ACTIVE',
  grace_period: 4,
  filter: {
    min_age: 24,
    min_cibil: 600,
  },
  cycle_frequency: 'MONTHLY',
  precision_by_interest: 2,
  day_count_method: 'ACTUAL_BY_ACTUAL',
};

export const DEFAULT_COLENDER_FEE_DETAILS = {
  fee_type: '',
  value: {
    value_type: 'AMOUNT',
    number: 1000.0,
  },
  colender_contributions: [
    {
      colenderId: '',
      percentage: 0,
    },
    {
      colenderId: '',
      percentage: 0,
    },
  ],
};
