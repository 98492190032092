import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import VegaContainedButton from '../../../../../../../components/common/VegaContainedButton';
import { User_Icon } from '../../../../../../../components/icon/Icons';
import { BLUE, COMMON } from '../../../../../../../constants/style';

interface IProps {
  img: string | null;
  setImg: React.Dispatch<React.SetStateAction<string | null>>;
  setIsDisable: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  onAllowAccessClick: () => void;
}

const SelfieCamara = ({
  img,
  setImg,
  setIsDisable,
  onAllowAccessClick,
}: IProps) => {
  const [isAllow, setIsAllow] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const webcamRef = useRef<any>(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImg(imageSrc);
    setIsDisable(false);
  }, [webcamRef]);

  const openSelfie = () => {
    onAllowAccessClick();
    setIsAllow(true);
  };

  return (
    <>
      {isAllow ? (
        img === null ? (
          <Box>
            <Box
              sx={{
                textAlign: 'center',
                height: '325px',
              }}
            >
              <Webcam
                style={{
                  borderRadius: '10px',
                }}
                audio={false}
                mirrored={true}
                height={'auto'}
                width={isMobile ? 320 : 400}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
              />
            </Box>
            <VegaContainedButton
              isPurple
              text="Capture photo"
              onClick={capture}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={img}
              width={isMobile ? 325 : ''}
              alt="screenshot"
              style={{ borderRadius: '10px' }}
            />
          </Box>
        )
      ) : (
        <Box>
          <Box>
            <User_Icon />
            <Typography
              my={1}
              fontSize={12}
              color={COMMON.gray}
              className="font-aspekta-400"
            >
              Please take a clear photo & allow location access to help us
              confirm your identity
            </Typography>
            <Typography
              onClick={openSelfie}
              my={1}
              fontSize={14}
              color={BLUE.lighter}
              sx={{
                cursor: 'pointer',
              }}
              className="font-aspekta-500"
            >
              Allow Access
            </Typography>
          </Box>
        </Box>
      )}
      {}
    </>
  );
};

export default SelfieCamara;
