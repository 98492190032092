import dayjs from "dayjs";
import { createContext, ReactNode, useContext, useState } from "react";
import { LosService } from "../api/LosService";
import {
  ElectricityDetailsPreviewData,
  FinancialDetailsPreviewData,
  IndividualKycDetailsPreviewData,
  KycSelfieDetails,
  NonIndividualKycPreviewData,
  PromoterKycDetails,
} from "../pages/ApplicationPreview/types";
import {
  AadharMetaData,
  ApplicationDocument,
  CollateralDetails,
  CompanyPreviewData,
  CustomerAadharData,
  CustomerApplication,
  CustomerPanData,
  LosDocumentType,
  PanMetadata,
  UserPanStatus,
} from "../types/Los";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
type ApplicationPreviewContextType = {
  applicationId?: string;
  fetchIndividualKycDetails: (
    applicationId: string
  ) => Promise<Partial<IndividualKycDetailsPreviewData>>;
  fetchNonIndividualKycDetails: (
    applicationId: string
  ) => Promise<Partial<NonIndividualKycPreviewData>>;
  fetchFiancialDetails: (
    applicationId: string
  ) => Promise<Partial<FinancialDetailsPreviewData>>;
  fetchCollateralDetails: (
    applicationId: string
  ) => Promise<Partial<CollateralDetails>>;
  fetchElectricityDetails: (
    applicationId: string
  ) => Promise<Partial<ElectricityDetailsPreviewData>>;
  fetchCompanyDetails: (
    applicationId: string
  ) => Promise<Partial<CompanyPreviewData>>;
};

type Props = {
  children: ReactNode;
  applicationId?: string;
};

const ApplicationPreviewContext =
  createContext<ApplicationPreviewContextType | null>(null);

export const useApplicationPreview = () =>
  useContext(ApplicationPreviewContext) as ApplicationPreviewContextType;

export const ApplicationPreviewProvider = ({
  children,
  applicationId,
}: Props) => {
  const [customerApplication, setCustomerApplication] =
    useState<CustomerApplication>();

  async function _fetchApplication(applicationId: string) {
    try {
      if (customerApplication && applicationId == customerApplication.id) {
        return customerApplication;
      }
      const response = await LosService.getApplicationById(applicationId);
      setCustomerApplication(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchAadhaarInfo(
    infoId: string
  ): Promise<CustomerAadharData> {
    try {
      const response = await LosService.getAadhaarDetailsForInfoId(infoId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchPanInfo(infoId: string): Promise<PanMetadata> {
    try {
      const response = await LosService.fetchPanByPanInfoId({
        panInfoId: infoId,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchSelfie(data: {
    memberId: string;
    applicationId: string;
  }) {
    try {
      const response = await LosService.getDocument({
        memberId: data.memberId,
        documentType: LosDocumentType.SELFIE,
        applicationId: data.applicationId,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchKycDetails(
    applicationId: string
  ): Promise<Partial<IndividualKycDetailsPreviewData>> {
    try {
      const application = await _fetchApplication(applicationId);
      const user = application.users[application.currentUserIndex] ?? [];
      //TODO: commented selfie details api

      // if (members.length <= 0) {
      //   throw new Error('No Members found')
      // }
      // const firstMember = members[members.length - 1]
      // const aadhaarDetails = await _fetchAadhaarInfo(firstMember.aadhaarInfoId)
      // const aadhaarDetails = await LosService.getAdhaarDetailsByUserId(user)
      // const panDetails = await _fetchPanInfo(firstMember.panInfoId)
      // const panDetails = await LosService.getPanDetailsByUserId(user)
      // const selfieDetails = await _fetchSelfie({
      //   // memberId: firstMember.id,
      //   memberId: user,
      //   applicationId: application.id,
      // })

      // const selfieStatus =
      //   (selfieDetails.metaData?.['verified'] ?? false) == true
      //     ? 'Verified'
      //     : 'Not verified'
      // const selfieMatchPercentage: string =
      //   selfieDetails.metaData?.['matchPercentage'] ?? '-'
      const response = await LosService.getUserDetails(applicationId);
      const panDetails = response?.userPanData[0]?.panMetadata;
      const aadhaarDetails = response?.userAadharData[0]?.aadharMetaData;
      const selfieDetails = response?.userSelfieData[0]?.selfieMetaData;

      const kycDetails: IndividualKycDetailsPreviewData = {
        panDetails: {
          name: panDetails.panName,
          panNumber: panDetails.panNumber,
          status: panDetails.panStatus,
          bureauAcceptance: dayjs(panDetails.updatedAt).format(
            "DD-MM-YYYY h:mm A"
          ),
        },
        aadhaarDetails: {
          name: aadhaarDetails.name,
          aadhaarNumber: aadhaarDetails.uid,
          dob: aadhaarDetails.dob,
          status: aadhaarDetails.status,
          gender: aadhaarDetails.gender,
          currentAddress: aadhaarDetails.splitAddress,
          permanentAddress: aadhaarDetails.splitAddress,
          city: "",
          pincode: "",
          aadhaarAcceptance: dayjs(aadhaarDetails.updatedAt).format(
            "DD-MM-YYYY h:mm A"
          ),
        },
        selfieDetails: {
          url: selfieDetails?.fileName,
          status: selfieDetails?.status,
          faceMatch: selfieDetails?.score,
        },
        // selfieDetails: {
        //   url: '',
        //   status: '',
        //   faceMatch: '',
        // },
      };

      return kycDetails;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchNonIndividualKycDetails(
    applicationId: string
  ): Promise<Partial<NonIndividualKycPreviewData>> {
    try {
      const application = await _fetchApplication(applicationId);

      // const companyPanDetails = await _fetchPanInfo(
      //   application.companyDetails.panId
      // )
      const conpanyPanDetails = await LosService.getCompanyPanDetailsByPanId(
        application.companyDetails.panId
      );
      const kycDetails: Partial<NonIndividualKycPreviewData> = {
        companyPanDetails: {
          name: conpanyPanDetails.panName,
          panNumber: conpanyPanDetails.panNumber,
          status:
            conpanyPanDetails.valid === true
              ? UserPanStatus.APPROVED
              : UserPanStatus.REJECTED,
          bureauAcceptance: dayjs(conpanyPanDetails.updatedAt).format(
            "DD-MM-YYYY h:mm A"
          ),
        },
        promoterDetails: [],
      };

      const members = application.users ?? [];
      var promoterDetailsArray: Partial<PromoterKycDetails>[] = [];
      for await (const member of members) {
        // const aadhaarDetails = await _fetchAadhaarInfo(member.aadhaarInfoId)
        // const panDetails = await _fetchPanInfo(member.panInfoId)
        const aadhaarDetails = await LosService.getAdhaarDetailsByUserId(
          member
        );
        const panDetails = await LosService.getPanDetailsByUserId(member);
        const selfieDetails = await LosService.getSelfieDetailsByUserId(member);
        //TODO:
        // const selfieDetails = await _fetchSelfie({
        //   memberId: member.id,
        //   applicationId: application.id,
        // })
        // const selfieStatus =
        //   (selfieDetails.metaData?.['verified'] ?? false) == true
        //     ? 'Verified'
        //     : 'Not verified'
        // const selfieMatchPercentage: string =
        //   selfieDetails.metaData?.['matchPercentage'] ?? '-'
        // const name = member.basicInfo.name
        // const designation = member.basicInfo.designation
        const mappedPromoter = mapPromoterDetails({
          name: aadhaarDetails.name,
          // designation: designation,
          aadhaarDetails: aadhaarDetails,
          panDetails: panDetails,
          selfieDetails: {
            url: selfieDetails.fileName,
            faceMatch: selfieDetails.score,
            status: selfieDetails.status,
          },
        });
        promoterDetailsArray.push(mappedPromoter);
      }

      kycDetails.promoterDetails = promoterDetailsArray;

      return kycDetails;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchFiancialDetails(
    applicationId: string
  ): Promise<Partial<FinancialDetailsPreviewData>> {
    try {
      const application = await _fetchApplication(applicationId);
      const paySlips: ApplicationDocument[] = [
        getFirstApplicationDocument({
          ofType: LosDocumentType.PAYSLIP_LAST_MONTH,
          from: application.financialDetails.documents,
        }),
        getFirstApplicationDocument({
          ofType: LosDocumentType.PAYSLIP_SECOND_LAST_MONTH,
          from: application.financialDetails.documents,
        }),
        getFirstApplicationDocument({
          ofType: LosDocumentType.PAYSLIP_LAST_MONTH,
          from: application.financialDetails.documents,
        }),
      ].filter(isNotNull);

      const itr: ApplicationDocument[] = [
        getFirstApplicationDocument({
          ofType: LosDocumentType.INCOME_TAX_RETURN_LAST_YEAR,
          from: application.financialDetails.documents,
        }),
        getFirstApplicationDocument({
          ofType: LosDocumentType.INCOME_TAX_RETURN_SECOND_LAST_YEAR,
          from: application.financialDetails.documents,
        }),
      ].filter(isNotNull);

      const auditedFinancialReport: ApplicationDocument[] = [
        getFirstApplicationDocument({
          ofType: LosDocumentType.AUDITED_FINANCIAL_REPORT_LAST_YEAR,
          from: application.financialDetails.documents,
        }),
        getFirstApplicationDocument({
          ofType: LosDocumentType.AUDITED_FINANCIAL_REPORT_SECOND_LAST_YEAR,
          from: application.financialDetails.documents,
        }),
      ].filter(isNotNull);

      const bankStatement: ApplicationDocument[] = [
        getFirstApplicationDocument({
          ofType: LosDocumentType.BANK_STATEMENT,
          from: application.financialDetails.documents,
        }),
      ].filter(isNotNull);

      const financialDetails: FinancialDetailsPreviewData = {
        companyName: application.financialDetails.employerDetail,
        paySlips: paySlips,
        bankStatement: bankStatement,
        itr: itr,
        auditedFinancials: auditedFinancialReport,
      };

      return financialDetails;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchCompanyDetails(
    applicationId: string
  ): Promise<Partial<CompanyPreviewData>> {
    try {
      const application = await _fetchApplication(applicationId);

      const gstCertification = [
        getFirstApplicationDocument({
          ofType: LosDocumentType.GST_DOCUMENT,
          from: application.companyDetails.documents,
        }),
      ].filter(isNotNull);

      const certificateOfIncorporation = [
        getFirstApplicationDocument({
          ofType: LosDocumentType.CERTIFICATE_OF_INCORPORATION,
          from: application.companyDetails.documents,
        }),
      ].filter(isNotNull);

      const aoa = [
        getFirstApplicationDocument({
          ofType: LosDocumentType.AOA,
          from: application.companyDetails.documents,
        }),
      ].filter(isNotNull);

      const moa = [
        getFirstApplicationDocument({
          ofType: LosDocumentType.MOA,
          from: application.companyDetails.documents,
        }),
      ].filter(isNotNull);

      const partnershipFirmDeed = [
        getFirstApplicationDocument({
          ofType: LosDocumentType.PARTNERSHIP_FIRM_DEED,
          from: application.companyDetails.documents,
        }),
      ].filter(isNotNull);

      const details: CompanyPreviewData = {
        companyName: application.companyDetails.companyName,
        gstNo: application.companyDetails.gstId,
        gstCertification: gstCertification,
        certificateOfIncorporation: certificateOfIncorporation,
        moa: moa,
        aoa: aoa,
        partnershipFirmDeed: partnershipFirmDeed,
      };
      return details;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchCollateralDetails(
    applicationId: string
  ): Promise<Partial<CollateralDetails>> {
    try {
      const application = await _fetchApplication(applicationId);
      return application.collateralDetails;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchElectricityDetails(
    applicationId: string
  ): Promise<Partial<ElectricityDetailsPreviewData>> {
    try {
      const application = await _fetchApplication(applicationId);
      const electrictyDetails: ElectricityDetailsPreviewData = {
        board: application.documentationDetails.electricityBoard,
        accountNumber: application.documentationDetails.electricityBillNo,
        bill: {
          lastMonth: getFirstApplicationDocument({
            ofType: LosDocumentType.ELECTRICITY_BILL_LAST_MONTH,
            from: application.documentationDetails.documents,
          }),
          secondLastMonth: getFirstApplicationDocument({
            ofType: LosDocumentType.ELECTRICITY_BILL_SECOND_LAST_MONTH,
            from: application.documentationDetails.documents,
          }),
          thirdLastMonth: getFirstApplicationDocument({
            ofType: LosDocumentType.ELECTRICITY_BILL_THIRD_LAST_MONTH,
            from: application.documentationDetails.documents,
          }),
        },
      };

      return electrictyDetails;
    } catch (error) {
      throw error;
    }
  }

  return (
    <ApplicationPreviewContext.Provider
      value={{
        fetchIndividualKycDetails: _fetchKycDetails,
        fetchNonIndividualKycDetails: _fetchNonIndividualKycDetails,
        fetchFiancialDetails: _fetchFiancialDetails,
        fetchCollateralDetails: _fetchCollateralDetails,
        fetchElectricityDetails: _fetchElectricityDetails,
        fetchCompanyDetails: _fetchCompanyDetails,
        applicationId: applicationId,
      }}
    >
      {children}
    </ApplicationPreviewContext.Provider>
  );
};

const getFirstApplicationDocument = (data: {
  ofType: LosDocumentType;
  from?: ApplicationDocument[];
}) => {
  const filteredItems =
    data.from?.filter((item) => item.documentType == data.ofType) ?? [];
  if (filteredItems.length > 0) return filteredItems[0];
  return null;
};

const mapPromoterDetails = (data: {
  name: string;
  designation?: string;
  aadhaarDetails: AadharMetaData;
  panDetails: PanMetadata;
  selfieDetails?: KycSelfieDetails;
}) => {
  const promoterDetails: Partial<PromoterKycDetails> = {
    name: data.name,
    designation: data.designation,
    panDetails: {
      name: data.panDetails.panName,
      panNumber: data.panDetails.panNumber,
      status: data.panDetails.panStatus,
      bureauAcceptance: dayjs(data.panDetails.updatedAt).format(
        "DD-MM-YYYY h:mm A"
      ),
    },
    aadhaarDetails: {
      name: data.aadhaarDetails.name,
      aadhaarNumber: data.aadhaarDetails.uid,
      dob: data.aadhaarDetails.dob,
      status: data.aadhaarDetails.status,
      gender: data.aadhaarDetails.gender,
      currentAddress: data.aadhaarDetails.splitAddress,
      permanentAddress: data.aadhaarDetails.splitAddress,
      city: "",
      pincode: "",
      aadhaarAcceptance: dayjs(data.aadhaarDetails.updatedAt).format(
        "DD-MM-YYYY h:mm A"
      ),
    },
    selfieDetails: data.selfieDetails,
  };

  return promoterDetails;
};
function isNotNull<T>(value: T | null): value is T {
  return value !== null;
}
