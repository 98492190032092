import { useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import LosButton from './common/LosButton';
import LosOnboardingButton from './common/LosOnboardingButton';

type Props = {
  renderHelperView?: () => ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  text?: string;
  loading?: boolean;
};
export const LosFormButtonView = ({
  onClick,
  renderHelperView,
  disabled = false,
  text = 'Submit',
  loading = false,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div
      style={{
        paddingTop: '0.88rem',
        paddingBottom: '1.25rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        ...(isMobile && {
          borderTop: '1px solid #E1E4EB',
        }),
      }}
    >
      {renderHelperView && (
        <div
          style={{
            marginBottom: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '25rem',
            width: '85%',
          }}
        >
          {renderHelperView()}
        </div>
      )}
      <LosOnboardingButton
        text={text}
        sx={{
          maxWidth: '25rem',
          width: '85%',
        }}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
      />
    </div>
  );
};
