import { Box, Card, Grid, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSnackbar } from '../../../../providers/SnackbarProvider'
import { LosService } from '../../api/LosService'
import LosFormInputField from '../../components/common/LosFormInputField'
import LosText from '../../components/common/LosText'
import LosTextField from '../../components/common/LosTextField'
import { UserBasicInfo } from '../../types/Los'
import { StringUtility } from '../../utils/StringUtility'
import { getErrorMessageFromErrorObj } from '../../../../utils/api'

type Props = {
  user?: UserBasicInfo
  index?: number
  userId: string
}

function UserDetailsEditCard({ user, index, userId }: Props) {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [userBasicInfo, setUserBasicInfo] = useState<UserBasicInfo>({
    designation: '',
    emailId: '',
    mobileNumber: '',
    name: '',
  })
  const { setSnackbar } = useSnackbar()

  function updateEmail(email: string) {
    setUserBasicInfo(prev => {
      return { ...prev, emailId: email }
    })
  }
  function updateMobileNumber(mobileNumber: string) {
    setUserBasicInfo(prev => {
      return { ...prev, mobileNumber }
    })
  }

  async function onSaveClick() {
    try {
      if (!isEdit) {
        setIsEdit(!isEdit)
        return
      }
      if (!StringUtility.validateEmail(userBasicInfo.emailId)) {
        setSnackbar('Please Enter A Valid Email', 'error')
        return
      }
      if (!StringUtility.validatePhoneNumber(userBasicInfo.mobileNumber)) {
        setSnackbar('Please Enter A Valid Mobile Number', 'error')
        return
      }
      const request = {
        userBasicInfo: userBasicInfo,
        userId: userId,
      }
      const response = await LosService.updateContactDetails(request)
      setUserBasicInfo(prev => {
        return { ...prev, ...response.userBasicInfo }
      })
      setIsEdit(!isEdit)
      setSnackbar('Changes Saved')
    } catch (error) {
      setSnackbar('Failed To Save Changes', 'error')
    }
  }

  useEffect(() => {
    setUserBasicInfo(prev => {
      return { ...prev, ...user }
    })
  }, [])

  return (
    <>
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '0.62rem',
          borderRadius: '7.317px',
          paddingTop: '0.75rem',
          paddingBottom: '0.75rem',
          background: 'white',
        }}
      >
        <Stack
          direction={'row'}
          alignItems='center'
          justifyContent={'space-between'}
          spacing={'0.5rem'}
          style={{
            paddingLeft: '1.25rem',
            paddingRight: '1.25rem',
          }}
        >
          {/* <LosText
            text={`Promoter ${index}`}
            style={{ fontSize: '0.8rem', lineHeight: '100%', fontWeight: 600 }}
          /> */}
          <LosText
            text={`Customer Contact Details`}
            style={{ fontSize: '0.8rem', lineHeight: '100%', fontWeight: 600 }}
          />
          <Box
            style={{
              cursor: 'pointer',
              paddingTop: 0.5,
            }}
            onClick={onSaveClick}
          >
            <LosText
              text={isEdit ? 'Save' : 'Edit'}
              style={{
                color: '#1047DC',
                fontSize: '0.625rem',
                fontWeight: 700,
                lineHeight: '100%',
                letterSpacing: '0.04375rem',
                textTransform: 'uppercase',
              }}
            />
          </Box>
        </Stack>
        <Grid
          container
          direction={'row'}
          justifyContent='start'
          style={{
            paddingLeft: '1.25rem',
            paddingRight: '1.25rem',
          }}
        >
          <Grid item xs={6}>
            <Label label='Name' description={userBasicInfo?.name ?? ''} />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          justifyContent='start'
          style={{
            paddingLeft: '1.25rem',
            paddingRight: '1.25rem',
          }}
        >
          <Grid item xs={6}>
            {isEdit ? (
              <LosFormInputField label={'Mobile Number'}>
                <LosTextField
                  value={userBasicInfo?.mobileNumber ?? '-'}
                  onChange={e => updateMobileNumber(e.target.value)}
                  style={{ width: '90%' }}
                />
              </LosFormInputField>
            ) : (
              <Label
                label='Mobile Number'
                description={userBasicInfo?.mobileNumber ?? ''}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {isEdit ? (
              <LosFormInputField label={'Email'}>
                <LosTextField
                  value={userBasicInfo?.emailId ?? ''}
                  onChange={e => updateEmail(e.target.value)}
                  style={{ width: '90%' }}
                />
              </LosFormInputField>
            ) : (
              <Label label='Email' description={userBasicInfo?.emailId ?? ''} />
            )}
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </Card>
    </>
  )
}

export default UserDetailsEditCard
const Label = (data: {
  label: string
  description?: string
  color?: string
}) => {
  return (
    <Stack rowGap={1}>
      <LosText
        text={data.label}
        style={{
          fontSize: '.625rem',
          fontFamily: 'Aspekta !important',
          color: '#676B76',
          fontWeight: 700,
          lineHeight: '124%',
          letterSpacing: '0.04375rem',
          textTransform: 'uppercase',
        }}
      />
      <LosText
        text={data.description ?? '-'}
        fontSize={'10px'}
        {...(data.color && {
          color: data.color,
        })}
        style={{
          fontFamily: 'Aspekta',
          fontSize: '13px',
          fontWeight: 400,
          lineHeight: '12px',
          letterSpacing: '0em',
          textAlign: 'left',
        }}
      />
    </Stack>
  )
}
