import { createContext, useContext, useEffect, useState } from 'react'
import { useClientAuth } from '../../../providers/ClientProvider'
import { useSnackbar } from '../../../providers/SnackbarProvider'
import { getErrorMessageFromErrorObj } from '../../../utils/api'
import { LOS_CLIENT_ID, LOS_PROGRAM_ID } from '../api/constants'
import { LeadService } from '../api/LeadService'
import { useCustomerApi } from '../hooks/useCustomerApi'
import { useCustomerApplicationApi } from '../hooks/useCustomerApplicationApi'
import { useMobileOtpVerification } from '../hooks/useMobileOtpVerification'
import { CustomerApplication, Lead, LosEntityType } from '../types/Los'
import { LocalStorageUtility, StorageKeys } from '../utils/StorageUtility'
import { FastForward } from '@mui/icons-material'

export enum LosAuthState {
  MobileInput,
  OtpVerification,
  Authenticated,
}
type LosAuthContextType = {
  isAuthenticated: boolean
  currentState: LosAuthState
  mobileNumber: string
  otp: string
  lead?: Lead
  loading: boolean
  updateAuthState: (value: LosAuthState) => void
  onOtpChange: (value: string) => void
  resendOtp: () => Promise<void>
  onMobileNumberChange: (value: string) => void
  generateOtp: () => void
  verifyOtp: (leadId: string) => void
  navigateToMobileNumber: () => void
}

const LosAuthContext = createContext<LosAuthContextType | null>(null)

export const useLosAuth = () => useContext(LosAuthContext) as LosAuthContextType

export const LosAuthProvider = ({ children }: any) => {
  const { createCustomerIfDoesNotExists } = useCustomerApi()
  const { getCustomerApplicationAndCreateIfNotFound, getApplicationById } =
    useCustomerApplicationApi()
  const { generateOtp, verifyOtp } = useMobileOtpVerification()
  const [mobileNumber, setMobileNumber] = useState<string>('')
  const [otp, setOtp] = useState<string>('')
  const [currentState, setCurrentState] = useState<LosAuthState>(
    LosAuthState.MobileInput
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [lead, setLead] = useState<Lead>()
  const { setSnackbar } = useSnackbar()

  function _onOtpChange(value: string) {
    setOtp(value)
  }

  function _onMobileNumberChange(value: string) {
    setMobileNumber(value)
  }

  function _updateLosAuthState(value: LosAuthState) {
    setCurrentState(value)
  }

  async function _generateOtp() {
    try {
      if (mobileNumber.length <= 0) {
        return
      }
      setLoading(true)
      await generateOtp({
        mobileNumber: mobileNumber,
        programId: LOS_PROGRAM_ID,
      })
      const lead = await _createLead(mobileNumber)
      setLead(lead)
      setCurrentState(LosAuthState.OtpVerification)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  async function _createLead(mobileNumber: string): Promise<Lead> {
    try {
      const response = await LeadService.createLead({
        phone: mobileNumber,
        programId: LOS_PROGRAM_ID,
      })
      return response
    } catch (error) {
      console.error('Failed to create lead', error)
      throw error
    }
  }

  async function _verifyOtp(leadId: string) {
    try {
      if (mobileNumber.length <= 0 || otp.length <= 0) {
        return false
      }
      setLoading(true)
      const status = await verifyOtp({
        mobileNumber: mobileNumber,
        programId: LOS_PROGRAM_ID,
        otp: otp,
      })

      if (status === false) {
        return false
        // setSnackbar('Invalid OTP', 'error')
      }
      const customer = await createCustomerIfDoesNotExists({
        mobileNumber: mobileNumber,
        clientId: LOS_CLIENT_ID,
      })

      LocalStorageUtility.setItem(StorageKeys.CUSTOMER_ID, customer.customerId)

      await createApplicationIfDoesNotExists(
        customer.customerId,
        LOS_PROGRAM_ID,
        leadId
      )
      setLoading(false)
      setCurrentState(LosAuthState.Authenticated)
      return true
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      return false
    } finally {
      setLoading(false)
    }
  }

  async function createApplicationIfDoesNotExists(
    customerId: string,
    programId: string,
    leadId: string
  ) {
    setLoading(true)

    const response = await getCustomerApplicationAndCreateIfNotFound({
      customerId: customerId,
      programId: programId,
      leadId: leadId,
    })
    LocalStorageUtility.setItem<CustomerApplication>(
      StorageKeys.CUSTOMER_APPLICATION,
      response
    )
    LocalStorageUtility.setItem<string>(StorageKeys.APPLICATION_ID, response.id)
    setLoading(false)
  }

  function runAfterDelay(callback: () => void, delay: number) {
    setTimeout(callback, delay)
  }

  function clearLocalStorage() {
    LocalStorageUtility.removeItem(StorageKeys.APPLICATION_ID)
    LocalStorageUtility.removeItem(StorageKeys.CUSTOMER_ID)
    LocalStorageUtility.removeItem(StorageKeys.CUSTOMER_APPLICATION)
  }

  async function checkIfLoggedIn() {
    try {
      const applicationId = LocalStorageUtility.getItem<string>(
        StorageKeys.APPLICATION_ID
      )
      if (applicationId != null && applicationId.length > 0) {
        const application = await getApplicationById(applicationId)
        LocalStorageUtility.setItem<CustomerApplication>(
          StorageKeys.CUSTOMER_APPLICATION,
          application
        )
        setCurrentState(LosAuthState.Authenticated)
        return
      }
      throw new Error("Can't find application for id " + applicationId)
    } catch (error) {
      console.error(error)
      console.log('Clearing local storage')
      //clear local storage
      clearLocalStorage()
    } finally {
      setLoading(false)
    }
  }

  async function _onResendOtpClick(): Promise<void> {
    if (mobileNumber.length <= 0) {
      throw new Error('Mobile number is not valid ' + mobileNumber)
    }
    setLoading(true)
    await generateOtp({
      mobileNumber: mobileNumber,
      programId: LOS_PROGRAM_ID,
    })
    setLoading(false)
  }

  async function _navigateToMobileNumber() {
    setCurrentState(LosAuthState.MobileInput)
  }

  useEffect(() => {
    setLoading(true)
    runAfterDelay(checkIfLoggedIn, 1000)
  }, [])

  return (
    <LosAuthContext.Provider
      value={{
        loading: loading,
        isAuthenticated: currentState == LosAuthState.Authenticated,
        mobileNumber: mobileNumber,
        otp: otp,
        lead: lead,
        currentState: currentState,
        onOtpChange: _onOtpChange,
        onMobileNumberChange: _onMobileNumberChange,
        updateAuthState: _updateLosAuthState,
        generateOtp: _generateOtp,
        verifyOtp: _verifyOtp,
        resendOtp: _onResendOtpClick,
        navigateToMobileNumber: _navigateToMobileNumber,
      }}
    >
      {children}
    </LosAuthContext.Provider>
  )
}
