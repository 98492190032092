import React from 'react';
import { Grid } from '@mui/material';
import LosDetailsWrapper from '../../../../LosDetailsWrapper';
import { GREEN, RED } from '../../../../../../constants/style';
import { toLowerCase } from '../../../../../../constants/commonFunction';
import { useRuleEngine } from '../../../../../LMSAgent/Provider/RuleEngingProvider';
import LosFormInputField from '../../../../../onboarding/components/common/LosFormInputField';
import LosTextField from '../../../../../onboarding/components/common/LosTextField';
import LosSelect, {
  LosSelectOption,
} from '../../../../../onboarding/components/common/LosSelect';
import _ from 'lodash';

interface IManagementCriteriaProps {
  name: string;
  type: string;
  value: string | number;
  color: string;
}

const ManagementCriteria = () => {
  const { loading, underWrittingScrore, isEdit, handleCommentChange, comment } =
    useRuleEngine();

  const basicCriteria: IManagementCriteriaProps[] = [
    {
      name: 'Criteria type',
      value: underWrittingScrore?.underwritingManagementScore
        ? toLowerCase(underWrittingScrore?.underwritingManagementScore.comment)
        : '--',
      color: '#1B1D22',
      type: 'text',
    },
    {
      name: 'Score',
      value: underWrittingScrore?.underwritingManagementScore
        ? underWrittingScrore?.underwritingManagementScore.points
        : '--',

      color: '#1B1D22',
      type: 'number',
    },
  ];
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={2.4} xl={2.4}>
          {isEdit ? (
            <LosFormInputField label={'Criteria type'}>
              <LosSelect
                options={criteriaType()}
                value={comment}
                onChange={(e) => handleCommentChange(e.target.value as string)}
              />
            </LosFormInputField>
          ) : (
            <LosDetailsWrapper
              name={'Criteria type'}
              value={
                underWrittingScrore?.underwritingManagementScore
                  ? toLowerCase(
                      underWrittingScrore?.underwritingManagementScore.comment
                    )
                  : '--'
              }
              valueColor={'#1B1D22'}
              loading={loading}
              fontWeight={400}
              fontSize="0.75rem"
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.4} xl={2.4}>
          {isEdit ? (
            <LosFormInputField label={'Score'}>
              <LosTextField
                disabled
                value={
                  underWrittingScrore?.underwritingManagementScore
                    ? underWrittingScrore?.underwritingManagementScore.points
                    : '--'
                }
              />
            </LosFormInputField>
          ) : (
            <LosDetailsWrapper
              name={'Score'}
              value={
                underWrittingScrore?.underwritingManagementScore
                  ? underWrittingScrore?.underwritingManagementScore.points
                  : '--'
              }
              valueColor={'#1B1D22'}
              loading={loading}
              fontWeight={400}
              fontSize="0.75rem"
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ManagementCriteria;

const criteriaType = () =>
  ['HIGH', 'ABOVE_MEDIUM', 'MEDIUM', 'ABOVE_LOW', 'LOW', 'VETO'].map((item) => {
    const option: LosSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
