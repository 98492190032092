import { Grid, Stack } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import VegaCheckBoxV2 from '../../../../components/common/v2/VegaCheckBoxV2'
import LosFormInputField from '../../components/common/LosFormInputField'
import LosText from '../../components/common/LosText'
import LosTextField from '../../components/common/LosTextField'
import LosAppBar from '../../components/LosAppBar'
import { LosFormButtonView } from '../../components/LosFormButton'
import LosFormContainer from '../../components/LosFormContainer'
import VerifyPanDetailsDialog from '../../components/VerifyPanDetailsDialog'
import { useLosAuth } from '../../providers/LosAuthProvider'
import { useLos } from '../../providers/LosProvider'
import { CustomerApplication, LosStep } from '../../types/Los'
import { PanEntityType, StringUtility } from '../../utils/StringUtility'
import { LocalStorageUtility, StorageKeys } from '../../utils/StorageUtility'
import { LosService } from '../../api/LosService'

type FormData = {
  name: string
  panNumber: string
  mobileNumber: string
  emailId: string
}

enum InputType {
  NAME = 'name',
  PAN_NUMBER = 'panNumber',
  EMAIL_ID = 'emailId',
  MOBILE_NUMBER = 'mobileNumber',
}

function IndividualPanDetails() {
  const {
    navigateToNextStep,
    saveIndividualPanData,
    fetchPanDetailsForPanInfoId,
    customerApplication,
    currentStep,
    updatePanStatusToAccepted,
    currentStepMetaData,
  } = useLos()
  const { isAuthenticated } = useLosAuth()
  const [formData, setFormData] = useState<FormData>({
    name: '',
    panNumber: '',
    emailId: '',
    mobileNumber: '',
  })
  const [errors, setErrors] = useState<Partial<FormData>>({})
  const [tncAccepted, setTncAccepted] = useState<boolean>(false)
  const [openPanVerificationDialog, setOpenPanVerificationDialog] =
    useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  function handleUpdate(type: InputType, value: string) {
    const updatedErrors: Partial<FormData> = { ...errors }
    switch (type) {
      case InputType.NAME:
        if (value.length <= 0) {
          updatedErrors.name = 'Name is required'
        } else {
          delete updatedErrors.name
        }
        setFormData(prev => {
          return {
            ...prev,
            name: value,
          }
        })
        break
      case InputType.EMAIL_ID:
        if (!StringUtility.validateEmail(value)) {
          updatedErrors.emailId = 'Enter a valid Email'
        } else {
          delete updatedErrors.emailId
        }
        setFormData(prev => {
          return {
            ...prev,
            emailId: value,
          }
        })
        break
      case InputType.MOBILE_NUMBER:
        if (!StringUtility.validatePhoneNumber(value)) {
          updatedErrors.mobileNumber = 'Enter a valid Phone Number'
        } else {
          delete updatedErrors.mobileNumber
        }
        setFormData(prev => {
          return {
            ...prev,
            mobileNumber: value,
          }
        })
        break
      case InputType.PAN_NUMBER:
        if (value.length <= 0) {
          updatedErrors.panNumber = `Valid ${_.startCase(
            _.toLower(customerApplication?.losEntityType)
          )} Pan Number is required`
        } else {
          const isValidPan = StringUtility.validatePANNumber(
            PanEntityType.Person,
            value
          )
          if (isValidPan == false) {
            updatedErrors.panNumber = `Valid ${_.startCase(
              _.toLower(customerApplication?.losEntityType)
            )} Pan Number is required`
          } else {
            delete updatedErrors.panNumber
          }
        }
        setFormData(prev => {
          return {
            ...prev,
            panNumber: value,
          }
        })
        break
    }
    setErrors(updatedErrors)
  }

  const isEmailInputValid = () => {
    return StringUtility.validateEmail(formData.emailId)
  }

  const isMobileNumberValid = () => {
    return StringUtility.validatePhoneNumber(formData.mobileNumber)
  }

  const isPanNumberInputValid = () => {
    if (!!errors.panNumber) return false
    return formData?.panNumber?.length > 0
  }

  const isInputValid = () => {
    let isPanValid = isPanNumberInputValid()
    let isNameValid = (errors.name ?? '').length <= 0
    var isTncAccepted = tncAccepted == true
    return isPanValid && isNameValid && isTncAccepted
  }

  async function onVerifyPanClick() {
    try {
      setLoading(true)
      const customerApplication =
        LocalStorageUtility.getItem<CustomerApplication>(
          StorageKeys.CUSTOMER_APPLICATION
        )

      if (customerApplication?.users) {
        await saveIndividualPanData({
          name: formData.name,
          panNumber: formData.panNumber,
          userId: customerApplication.users[0],
          emailId: formData.emailId,
          mobileNumber: formData.mobileNumber,
        })
      }

      navigateToNextStep()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  async function onConfirmPanClick() {
    try {
      setLoading(true)
      const response = await updatePanStatusToAccepted()
      navigateToNextStep()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  function onTncClick() {}

  function runAfterDelay(callback: () => void, delay: number) {
    setTimeout(callback, delay)
  }

  async function handlePanAcceptedState() {
    const index = customerApplication?.currentUserIndex
      ? customerApplication?.currentUserIndex
      : 0
    const userId = customerApplication?.users[index]

    // if (member.length > 0) {
    // const firstMember = member
    // const response = await fetchPanDetailsForPanInfoId(
    //   '6565f389b5c8f241b157a74a'
    // )
    if (!userId) {
      console.error('UserId not found for member ')
      return
    }
    const response = await LosService.getPanDetailsByUserId(userId)
    setFormData({
      name: response.name,
      panNumber: response.number,
      mobileNumber: '',
      emailId: '',
    })
    setOpenPanVerificationDialog(true)
    // }
    setLoading(false)
  }

  useEffect(() => {
    if (currentStep == LosStep.PAN_ACCEPTED && isAuthenticated) {
      setLoading(true)
      runAfterDelay(handlePanAcceptedState, 500)
    }
  }, [currentStep, customerApplication, isAuthenticated])

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <SubmitPanDetailsButtonView
            onClick={onVerifyPanClick}
            onTncChange={setTncAccepted}
            isInputValid={isInputValid()}
            isTncChecked={tncAccepted}
            onTncClick={onTncClick}
            loading={loading}
          />
        )
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        )
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <LosFormInputField label='Name (as on your pancard)'>
            <LosTextField
              value={formData.name}
              onChange={e => handleUpdate(InputType.NAME, e.target.value)}
              error={!!errors.name}
              helperText={errors.name}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label='Pan Card Number'>
            <LosTextField
              value={formData.panNumber}
              onChange={e =>
                handleUpdate(InputType.PAN_NUMBER, e.target.value.toUpperCase())
              }
              error={!!errors.panNumber}
              helperText={errors.panNumber}
              isValid={isPanNumberInputValid()}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label='Email Id'>
            <LosTextField
              value={formData.emailId}
              onChange={e => handleUpdate(InputType.EMAIL_ID, e.target.value)}
              error={!!errors.emailId}
              helperText={errors.emailId}
              isValid={isEmailInputValid()}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label='Mobile Number'>
            <LosTextField
              value={formData.mobileNumber}
              onChange={e =>
                handleUpdate(InputType.MOBILE_NUMBER, e.target.value)
              }
              error={!!errors.mobileNumber}
              helperText={errors.mobileNumber}
              isValid={isMobileNumberValid()}
            />
          </LosFormInputField>
        </Grid>
      </Grid>
      <VerifyPanDetailsDialog
        open={openPanVerificationDialog}
        onClose={function (): void {
          // setOpenPanVerificationDialog(false);
        }}
        onSubmit={function (): void {
          onConfirmPanClick()
        }}
        onTncClick={onTncClick}
        panNumber={formData.panNumber}
      />
    </LosFormContainer>
  )
}

export default IndividualPanDetails

const SubmitPanDetailsButtonView = (data: {
  onClick: () => void
  onTncChange: (checked: boolean) => void
  isInputValid: boolean
  isTncChecked: boolean
  onTncClick: () => void
  loading?: boolean
}) => {
  return (
    <LosFormButtonView
      onClick={data.onClick}
      disabled={data.isInputValid == false}
      text='Verify PAN'
      loading={data.loading}
      renderHelperView={() => {
        return (
          <Stack>
            <Stack direction={'row'} spacing='1' alignItems={'start'}>
              <VegaCheckBoxV2
                sx={{
                  '&.MuiButtonBase-root': {
                    paddingTop: '0px !important',
                  },
                  color: 'blue',
                }}
                checked={data.isTncChecked}
                onChange={(e, checked) => data.onTncChange(checked)}
              />
              <LosText style={{ fontSize: '0.75rem', lineHeight: '130%' }}>
                I give{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Dhanvikas Fiscal Services Private Limited
                </span>{' '}
                the consent to save and verify my KYC details and agree to the{' '}
                <span
                  onClick={() => {
                    window.open(
                      'https://solfin.co.in/Termsandconditions',
                      '__blank'
                    )
                  }}
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  Terms and Conditions
                </span>{' '}
                and{' '}
                <span
                  onClick={() => {
                    window.open('https://solfin.co.in/Privacypolicy', '__blank')
                  }}
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  Privacy Policy
                </span>{' '}
              </LosText>
            </Stack>
          </Stack>
        )
      }}
    />
  )
}
