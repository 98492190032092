import { Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { LoanManagementService } from '../../../LMSHQ/api/LoanManagementService';
import LosButton from '../../components/common/LosButton';
import LosText from '../../components/common/LosText';
import KeyFactsStatementDialog from '../../components/KeyFactsStatement/KeyFactsStatementDialog';
import { LosFormButtonView } from '../../components/LosFormButton';
import LosFormContainer from '../../components/LosFormContainer';

import { GiftIcon } from '../../icons/LosIcons';
import { useLos } from '../../providers/LosProvider';
import { CustomerApplication } from '../../types/Los';
import { LocalStorageUtility, StorageKeys } from '../../utils/StorageUtility';
import { LosService } from '../../api/LosService';

function ApplicationApproved() {
  const { setSnackbar } = useSnackbar();
  const { customerApplication, navigateToNextStep, approveSanctionLetter } =
    useLos();
  const [loading, setLoading] = useState<boolean>(false);
  const [openInfoDialog, setOpenInfoDialog] = useState<boolean>(false);
  async function onDownloadClick() {
    try {
      const applicationId = LocalStorageUtility.getItem<CustomerApplication>(
        StorageKeys.CUSTOMER_APPLICATION
      )?.id;

      if (!applicationId) {
        console.error('Application id not found');
        return;
      }
      setLoading(true);
      const response = await LoanManagementService.getSanctionLetter(
        applicationId
      );
      const url = response.url;
      window.open(url, '__blank');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function onProceedClick() {
    approveSanctionLetter()
      .then((res) => {
        customerApplication?.id &&
          LosService.updateNextStep(customerApplication?.id)
            .then((res) => {
              navigateToNextStep();
            })
            .catch((err) => {
              setSnackbar('Error in updating next step', 'error');
            });
      })
      .catch((err) => {
        setSnackbar('Error in approving the sanction letter', 'error');
      });
  }

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            loading={loading}
            text="Proceed"
            onClick={onProceedClick}
          />
        );
      }}
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          rowGap: '0.5rem',
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <CongratulationsContainer />
          </Grid>
        </Grid>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem',
          }}
        >
          <LosButton
            variant="outlined"
            text="Download Sanction Letter"
            onClick={onDownloadClick}
            loading={loading}
          />
          <LosButton
            variant="outlined"
            text="View Key Fact Statement"
            onClick={() => setOpenInfoDialog(true)}
            // loading={loading}
          />
        </div>
        <KeyFactsStatementDialog
          open={openInfoDialog}
          onClose={function (): void {
            setOpenInfoDialog(false);
          }}
        />
      </div>
    </LosFormContainer>
  );
}

export default ApplicationApproved;

const CongratulationsContainer = () => {
  return (
    <div
      style={{
        borderRadius: '0.375rem',
        background: '#E7EDFC',
        paddingLeft: '0.75rem',
        paddingRight: '0.75rem',
        paddingTop: '1.25rem',
        paddingBottom: '1.25rem',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '0.5rem',
        }}
      >
        <GiftIcon />
        <LosText
          text="Congratulations!"
          style={{
            color: '#1047DC',
            textAlign: 'center',
            fontSize: '1.0625rem',
            lineHeight: '120%',
            fontWeight: 600,
          }}
        />
        <LosText
          text="Your loan has been Sanctioned."
          style={{
            color: '#1047DC',
            textAlign: 'center',
            fontSize: '0.75rem',
            lineHeight: '130%',
          }}
        />
      </div>
    </div>
  );
};
