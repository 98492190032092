import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  useTheme,
} from '@mui/material'
import { IFinacialDocumentProps } from '../../../../types/application'
import { ReadOnlyFormField } from '../../../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField'
import _ from 'lodash'
import { StringUtility } from '../../../../onboarding/utils/StringUtility'
import LosFormInputField from '../../../../onboarding/components/common/LosFormInputField'
import PdfPreview from '../../../../components/PdfPreview'
import {
  CustomerApplication,
  LosDocumentType,
} from '../../../../onboarding/types/Los'
import { Close } from '@mui/icons-material'
import LosText from '../../../../onboarding/components/common/LosText'
import LosButton from '../../../../onboarding/components/common/LosButton'
import { useEffect, useState } from 'react'
import { LosService } from '../../../../onboarding/api/LosService'
import { useSnackbar } from '../../../../../providers/SnackbarProvider'
import { getErrorMessageFromErrorObj } from '../../../../../utils/api'

type Props = {
  open: boolean
  onClose: () => void
  applicationId: string | null
}

const ReviewPredisbursedApplicationForAgent = ({
  open,
  onClose,
  applicationId,
}: Props) => {
  const theme = useTheme()
  const { setSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)
  const [customerApplication, setCustomerApplication] =
    useState<CustomerApplication>()

  async function fetchApplication(applicationId: string) {
    try {
      setLoading(true)
      const response = await LosService.getApplicationById(applicationId)
      setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  const userTypeDisplayText = () => {
    if (customerApplication?.basicInfo?.subType)
      return _.startCase(_.toLower(customerApplication?.basicInfo.subType))
    return '-'
  }

  const requiredLoanAmountDisplayText = () => {
    if (customerApplication?.basicInfo?.amount)
      return StringUtility.formatCurrency(customerApplication?.basicInfo.amount)
    return '-'
  }

  const getCancelledChequeFileName = () => {
    const file = getFirstApplicationDocument({
      ofType: LosDocumentType.CANCELLED_CHEQUE,
      from: customerApplication?.bankDetails?.documents ?? [],
    })

    return file?.documentName ?? undefined
  }

  const getSanctionLetterFileName = () => {
    const file = getFirstApplicationDocument({
      ofType: LosDocumentType.SANCTION_LETTER,
      from: customerApplication?.agreement?.documents ?? [],
    })

    return file?.documentName ?? undefined
  }

  async function downloadSanctionLetter() {
    try {
      if (!applicationId) return
      const response = await LosService.getDocument({
        applicationId: applicationId,
        documentType: LosDocumentType.SANCTION_LETTER,
      })
      window.open(response.url, '__blank')
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    }
  }

  async function downloadCancelledCheque() {
    try {
      if (!applicationId) return
      const response = await LosService.getDocument({
        applicationId: applicationId,
        documentType: LosDocumentType.CANCELLED_CHEQUE,
      })

      window.open(response.url, '__blank')
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    }
  }

  useEffect(() => {
    if (open == true && applicationId != null) {
      fetchApplication(applicationId)
    }
  }, [open, applicationId])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      sx={{ borderRadius: '20px' }}
      onClose={onClose}
      open={open}
    >
      <div
        style={{
          backgroundColor: 'rgba(250, 250, 250, 1)',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent='space-between'
          alignItems={'center'}
          style={{
            paddingTop: '1.25rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <LosText text='Application' />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>

        <div
          style={{
            border: '1px solid #E1E4EB',
            padding: '1rem',
            margin: '1rem',
            borderRadius: '0.75rem',
            background: 'white',
          }}
        >
          <Grid item container xs={12} spacing={2} alignItems={'start'}>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Application Id'
                description={customerApplication?.id}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Name'
                description={customerApplication?.customerName}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='User Type'
                description={userTypeDisplayText()}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Loan Type'
                description={customerApplication?.basicInfo?.loanType ?? '-'}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Tenure'
                description={`${
                  customerApplication?.basicInfo?.tenure ?? '-'
                } Months`}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Required Loan Amount'
                description={requiredLoanAmountDisplayText()}
                loading={loading}
              />
            </Grid>
          </Grid>

          <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />
          <div>
            <Stack spacing={'1rem'}>
              <LosText fontWeight={500} fontSize={14}>
                Agreement
              </LosText>
              <Grid container>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <LosFormInputField label='Sanction letter'>
                    <PdfPreview
                      onDownload={downloadSanctionLetter}
                      fileName={getSanctionLetterFileName()}
                      canUpload={false}
                    />
                  </LosFormInputField>
                </Grid>
              </Grid>
            </Stack>
            {/* <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} /> */}
            {/* <Box py={1}>
              <Stack spacing={'1rem'}>
                <LosText fontWeight={500} fontSize={14}>
                  Bank Details
                </LosText>
                <Grid container>
                  <Grid item xs={12} sm={4} md={2}>
                    <ReadOnlyFormField
                      title="Account holder name"
                      description={
                        customerApplication?.bankDetails?.accountHolderName ??
                        '-'
                      }
                      loading={loading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <ReadOnlyFormField
                      title="Account no"
                      description={
                        customerApplication?.bankDetails?.accountNo ?? '-'
                      }
                      loading={loading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <ReadOnlyFormField
                      title="ifsc code"
                      description={
                        customerApplication?.bankDetails?.ifscCode ?? '-'
                      }
                      loading={loading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <ReadOnlyFormField
                      title="Branch"
                      description={
                        customerApplication?.bankDetails?.branch ?? '-'
                      }
                      loading={loading}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <LosFormInputField label="Cancelled Cheque">
                      <PdfPreview
                        onDownload={downloadCancelledCheque}
                        fileName={getCancelledChequeFileName()}
                        canUpload={false}
                      />
                    </LosFormInputField>
                  </Grid>
                </Grid>
              </Stack>
            </Box> */}
          </div>
        </div>
        <Stack
          direction={'row'}
          justifyContent='end'
          alignItems={'center'}
          style={{ padding: '1rem' }}
        >
          <LosButton text='Got it' size='small' onClick={onClose} />
        </Stack>
      </div>
    </Dialog>
  )
}

export default ReviewPredisbursedApplicationForAgent

const getFirstApplicationDocument = (data: {
  ofType: LosDocumentType
  from: IFinacialDocumentProps[]
}) => {
  const filteredItems =
    data.from.filter(item => item.documentType == data.ofType) ?? []
  if (filteredItems.length > 0) return filteredItems[0]
  return null
}
