import { Box } from '@mui/material';
import ListOfPreDisbursal from '../components/PreDisbursal/ListOfPreDisbursal';
import { ColendedApplication } from '../../onboarding/types/Los';
import SolfinAppBar from '../../components/SolfinAppBar';

export interface IPreDisburseApplicationsProps {
  loading: boolean;
  page: number;
  totalPages: number;
  applicationData: ColendedApplication[];
}

const PreDisbursal = () => {
  return (
    <>
      <SolfinAppBar title="Pre-Disbursed Applications" />
      <Box my={2}>
        <ListOfPreDisbursal />
      </Box>
    </>
  );
};

export default PreDisbursal;
