import { Typography } from '@mui/material';
import React from 'react';
import { GREY } from '../../constants/style';

interface IProps {
  text: string | number;
  mb?: number;
}

const VegaHeader = ({ text, mb = 0.5 }: IProps) => {
  return (
    <Typography
      className="font-aspekta-700"
      sx={{
        textTransform: 'uppercase',
        fontSize: 10,
        letterSpacing: '0.07em',

        mb: mb,
        color: '#676B76',
      }}
    >
      {text}
    </Typography>
  );
};

export default VegaHeader;
