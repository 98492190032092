import { GetNextStepApiData, LosService } from '../api/LosService';
import { LosStepResponse } from '../types/Los';

export const useLosStepApi = () => {
  async function getNextStep(
    data: GetNextStepApiData
  ): Promise<LosStepResponse> {
    try {
      const response = await LosService.getNextStep(data);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  return {
    getNextStep,
  };
};
