import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '..';
import {
  IApplicationsForSolfinProps,
  IPreDisburseApplicationProps,
} from '../../solfen/types/application';

export const DEFAUL_APPLICATION = {
  id: '',
  programId: '',
  customerId: '',
  status: '',
  applicationState: '',
  customerName: '',
  programApplicationFlowId: '',
  losEntityType: '',
  basicInfo: {
    userType: '',
    subType: null,
    name: '',
    mobileNumber: '',
    loanType: '',
    amount: 1000000.0,
    tenure: 3,
  },
  currentMemberIndex: 1,
  members: [
    {
      id: '',
      basicInfo: null,
      panInfoId: '',
      aadhaarInfoId: '',
      selfieInfoId: '',
    },
  ],
  companyPanId: null,
  companyDetails: null,
  collateralDetails: {
    loanType: 'SOLAR',
    collateralDetails: [
      {
        type: 'FIXED_DEPOSIT',
        amount: '120000',
        id: '',
      },
    ],
    guaranteeDetails: [
      {
        name: '',
        relationShip: '',
        email: '',
        panCard: '',
        guaranteeType: '',
        valueOfGuarantee: '',
      },
    ],
  },
  financialDetails: {
    id: '',
    applicationId: '',
    employerDetail: '',
    documents: [
      {
        id: '',
        documentCategory: '',
        documentType: '',
        documentName: '',
      },
      {
        id: '',
        documentCategory: '',
        documentType: '',
        documentName: '',
      },
      {
        id: '',
        documentCategory: '',
        documentType: '',
        documentName: '',
      },
      {
        id: '',
        documentCategory: '',
        documentType: '',
        documentName: '',
      },
      {
        id: '',
        documentCategory: '',
        documentType: '',
        documentName: '',
      },
      {
        id: '',
        documentCategory: '',
        documentType: '',
        documentName: '',
      },
      {
        id: '',
        documentCategory: '',
        documentType: '',
        documentName: '',
      },
      {
        id: '',
        documentCategory: '',
        documentType: '',
        documentName: '',
      },
    ],
  },
  documentationDetails: {
    id: '',
    applicationId: '',
    electricityBoard: '',
    electricityBillNo: '',
    code: '',
    documents: [
      {
        id: '',
        documentCategory: '',
        documentType: '',
        documentName: '',
      },
      {
        id: '',
        documentCategory: '',
        documentType: '',
        documentName: '',
      },
      {
        id: '',
        documentCategory: '',
        documentType: '',
        documentName: '',
      },
    ],
  },
  agreement: {
    id: '',
    applicationId: '',
    documents: [
      {
        id: '',
        documentCategory: '',
        documentType: '',
        documentName: '',
      },
    ],
  },
  bankDetails: {
    id: '',
    applicationId: '',
    accountHolderName: '',
    accountNo: '',
    ifscCode: '',
    branch: '',
    documents: [
      {
        id: '',
        documentCategory: '',
        documentType: '',
        documentName: '',
      },
    ],
  },
  agentId: null,
  createdAt: null,
  updatedAt: null,
  panAccepted: true,
  sanctionLetterAccepted: true,
  rejectionReason: [],
} as IApplicationsForSolfinProps;

const DEFAULT_PRE_APPLICATION = {
  programId: '10000f0a2-d79b-41bf-a5e8-0da658f90188',
  applicationId: '8a412f96-9eb9-4683-8533-8017ebabec19',
  customerId: '2X6O7b8e-2cd3-4b8e-a64a-c1ac8eae576a',
  customerName: null,
  phone: null,
  losEntityType: 'INDIVIDUAL',
  basicInfo: null,
  agentId: null,
  agentName: null,
  loanId: '',
  applicationStatus: 'REJECTED',
  colenderId: 'aac7ab46-8fd9-4bf5-a2fb-6d8005ce7a21',
  colenderName: 'Colender',
  colendingRatio: 70,
  colendingAmount: 0,
  colendingLoansStatus: 'PENDING',
  engagementStatus: null,
  loanAmount: null,
};

interface IProps {
  applicationDetails: IApplicationsForSolfinProps;
  preDisburseapplicationDetails: IPreDisburseApplicationProps;
  isShow: boolean;
  isApplicationForHQ: boolean;
}

const initialState: IProps = {
  applicationDetails: DEFAUL_APPLICATION,
  preDisburseapplicationDetails: DEFAULT_PRE_APPLICATION,
  isShow: false,
  isApplicationForHQ: false,
};

const agentApplicationsSlice = createSlice({
  name: 'agentApplication',
  initialState,
  reducers: {
    setApplicationsDetails: (state: IProps, action) => {
      const { payload } = action;
      state.applicationDetails = payload;
    },
    setPreDisburseApplicationsDetails: (state: IProps, action) => {
      const { payload } = action;
      state.preDisburseapplicationDetails = payload;
    },
    setIsShow: (state: IProps, action) => {
      const { payload } = action;
      state.isShow = payload;
    },
    setIsApplicationForHQ: (state: IProps, action) => {
      const { payload } = action;
      state.isApplicationForHQ = payload;
    },
  },
});

export const {
  setApplicationsDetails,
  setPreDisburseApplicationsDetails,
  setIsShow,
  setIsApplicationForHQ,
} = agentApplicationsSlice.actions;
export const getApplicationsDetails = (state: AppState) =>
  state.agentApplication;
export default agentApplicationsSlice;
