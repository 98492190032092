import { Box, Grid, Typography } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import VegaHeader from '../../../../../../components/common/VegaHeader'
import { BORDER_GRAY, COMMON } from '../../../../../../constants/style'
import { getApplicationsDetails } from '../../../../../../store/common/agentApplicationsSlice'
import { useAppSelector } from '../../../../../../store/hook'
import { LosService } from '../../../../../onboarding/api/LosService'
import {
  CompanyDetailsResponse,
  UserBasicInfo,
  UserDataDto,
} from '../../../../../onboarding/types/Los'
import KYCDetailsContainer from '../../../../components/KYCDetailsContainer'
import KYCCompanyDetailsContainer from '../../KYCCompanyDetailsContainer'
import KYCDetailsAadharContainer from './KYCDetailsAadharContainer'
import SelfieDetailsContainer from './SelfieDetailsContainer'
import {
  LocalStorageUtility,
  StorageKeys,
} from '../../../../../onboarding/utils/StorageUtility'

interface IProps {}

const KYCDetailsForPartnership = ({}: IProps) => {
  const { applicationDetails } = useAppSelector(getApplicationsDetails)
  // const applicationId =
  //   LocalStorageUtility.getItem<string>(StorageKeys.APPLICATION_ID) ?? ''
  const [userDetails, setUserDetails] = useState<UserDataDto[]>([])
  const [loadingUserDetails, setLoadingUserDetails] = useState<boolean>(false)
  const [companyDeatils, setCompanyDeatils] = useState<
    Partial<CompanyDetailsResponse>
  >({})

  const getDesignationDisplayText = (details: UserBasicInfo) => {
    if (details?.designation) {
      return _.startCase(_.toLower(details.designation))
    }
    return '-'
  }
  const getUserDetailsApi = async () => {
    try {
      setLoadingUserDetails(true)
      const response = await LosService.getUserDetails(applicationDetails.id)
      setCompanyDeatils(response.companyDetailsResponse)
      const users = UserDataProcessor.getUsers(response)
      setUserDetails(users)
    } catch (error) {
    } finally {
      setLoadingUserDetails(false)
    }
  }

  useEffect(() => {
    getUserDetailsApi()
  }, [])

  return (
    <div>
      <Box sx={{ borderBottom: BORDER_GRAY }}>
        <KYCCompanyDetailsContainer
          header='Company PAN details'
          panId={companyDeatils?.panNumber ?? '-'}
          isLoading={loadingUserDetails}
          panInfo={companyDeatils}
        />
      </Box>
      {userDetails.map((memberDetail: UserDataDto, index: number) => (
        <Box mt={1} sx={{ borderBottom: BORDER_GRAY }}>
          <Typography className='font-aspekta-500' fontSize={14}>
            {`Promoter ${index + 1} details`}
          </Typography>
          <Box py={2}>
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item xs={6} sm={6} md={2}>
                <VegaHeader text={'name'} />
                <Typography
                  className='font-aspekta-600'
                  color={COMMON.gray}
                  fontSize={11}
                >
                  {memberDetail.userAadharData[0]?.aadharMetaData.name ?? '--'}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <VegaHeader text={'designation'} />
                <Typography
                  className='font-aspekta-600'
                  color={COMMON.gray}
                  fontSize={11}
                >
                  {getDesignationDisplayText(
                    memberDetail.userAadharData[0].userBasicInfo
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <KYCDetailsContainer
              subHeader='PAN details'
              panId={memberDetail?.userPanData?.[0]?.panMetadata?.panNumber}
              isLoading={loadingUserDetails}
              panInfo={memberDetail?.userPanData?.[0]?.panMetadata}
            />
          </Box>
          <Box>
            <KYCDetailsAadharContainer
              subHeader='Aadhaar Details'
              aadhaarId={memberDetail?.userAadharData?.[0].aadharMetaData?.uid}
              aadhaarInfo={memberDetail?.userAadharData?.[0]?.aadharMetaData}
              isLoading={loadingUserDetails}
            />
          </Box>
          <Box mb={1}>
            <SelfieDetailsContainer
              subHeader='selfie'
              applicationId={applicationDetails.id}
              memberId={memberDetail?.userSelfieData?.[0]?.selfieMetaData.id}
              selfieData={memberDetail?.userSelfieData?.[0]?.selfieMetaData}
            />
          </Box>
        </Box>
      ))}
    </div>
  )
}

export default KYCDetailsForPartnership
export class UserDataProcessor {
  static getUsers(userData: UserDataDto): UserDataDto[] {
    const users: UserDataDto[] = []

    for (let i = 0; i < userData.userPanData.length; i++) {
      const user: UserDataDto = {
        companyDetailsResponse: userData.companyDetailsResponse,
        userPanData: [userData.userPanData[i]],
        userAadharData: [userData.userAadharData[i]],
        userSelfieData: [userData.userSelfieData[i]],
      }
      users.push(user)
    }

    return users
  }
}
