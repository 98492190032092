import { Grid } from '@mui/material';
import LosFormInputField from '../../../../../onboarding/components/common/LosFormInputField';
import LosText from '../../../../../onboarding/components/common/LosText';
import { StringUtility } from '../../../../../onboarding/utils/StringUtility';
import { Colender, Loan } from '../../../../types/Lms';

type Props = {
  loan: Partial<Loan>;
  index: number;
};

function ColenderForLoanDetails({ index, loan }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.75rem',
      }}
    >
      <LosText
        text={`Colender ${index + 1}`}
        style={{
          color: '#676B76',
          fontSize: '0.625rem',
          fontWeight: 700,
          textTransform: 'uppercase',
          letterSpacing: '0.04375rem',
          lineHeight: '124%',
        }}
      />
      <Grid container spacing={'1rem'} columns={10}>
        {/* <Grid item xs={2}>
          <Field title="Name" description={'No Data In API'} />
        </Grid> */}
        <Grid item xs={2}>
          <Field
            title="Amount"
            description={`₹ ${StringUtility.formatCurrency(loan.loan_amount)}`}
          />
        </Grid>
        <Grid item xs={2}>
          <Field
            title="Rate Of Interest"
            description={loan.interest_rate}
            suffix={'%'}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default ColenderForLoanDetails;

const Field = (data: {
  title: string;
  description?: string | number;
  suffix?: string;
}) => {
  const displayText = () => {
    if (data.description) {
      return `${data.description}${data.suffix ?? ''}`;
    }
    return '-';
  };
  return (
    <LosFormInputField label={data.title}>
      <LosText
        text={displayText()}
        fontSize={'0.75rem'}
        fontWeight={400}
        lineHeight={'120%'}
        color={'#1B1D22'}
      />
    </LosFormInputField>
  );
};
