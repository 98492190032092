import './../../../../components/common/v2/Styles/__styles.css';
import { Typography, TypographyProps, styled } from '@mui/material';

export interface StyledTypographyProps extends TypographyProps {
  bold?: boolean;
}

const StyledTypography = styled(Typography)<StyledTypographyProps>(
  ({ theme, bold }) => {
    return {
      fontFamily: 'Aspekta !important',

      ...(bold && {
        fontWeight: 700,
        color: '#3A4A5F',
      }),
    };
  }
);

export interface LosTextProps extends StyledTypographyProps {
  text?: string;
}

function LosText({ text, children, ...rest }: LosTextProps) {
  return <StyledTypography {...rest}>{children || text}</StyledTypography>;
}

export default LosText;
