import { Edit, EditOutlined } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import VegaOutlineButton from '../../../../../../components/common/VegaOutlineButton';
import { BORDER_GRAY, PURPLE } from '../../../../../../constants/style';
import {
  getApplicationsDetails,
  setApplicationsDetails,
} from '../../../../../../store/common/agentApplicationsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hook';
import {
  ICompanyDetailsProps,
  IFinacialDocumentProps,
} from '../../../../../types/application';
import AgentTextField from '../AgentTextField';
import VegaTextButton from '../../../../../../components/common/VegaTextButton';
import { IFilesProps } from '../ElectricityDetails/ElectricityDetails';
import { useSnackbar } from '../../../../../../providers/SnackbarProvider';
import { addFinancialDetailsForAgent } from '../../../../../APIs/LMS_Agent/applications';
import { getErrorMessageFromErrorObj } from '../../../../../../utils/api';
import {
  documentTypeEnum,
  financialDocumentTypeArrForIndividual,
} from '../../../../../enums/applications';
import VegaHeader from '../../../../../../components/common/VegaHeader';
import Media from '../ElectricityDetails/Media';

import { LosService } from '../../../../../onboarding/api/LosService';
import { LosDocumentType } from '../../../../../onboarding/types/Los';

interface IProps {
  getAllApplicationById: () => void;
}

const DEFAULT_DATA = [
  {
    documentType: documentTypeEnum.PAYSLIP_LAST_MONTH,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.PAYSLIP_SECOND_LAST_MONTH,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.PAYSLIP_THIRD_LAST_MONTH,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.BANK_STATEMENT,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.INCOME_TAX_RETURN_LAST_YEAR,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.INCOME_TAX_RETURN_SECOND_LAST_YEAR,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
];

const FinacialDetails = ({ getAllApplicationById }: IProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { applicationDetails, isApplicationForHQ } = useAppSelector(
    getApplicationsDetails
  );
  const dispatch = useAppDispatch();
  const [isClear, setIsClear] = useState<boolean>(false);
  const { setSnackbar } = useSnackbar();
  const [filesData, setFilesData] = useState<IFilesProps[]>([]);

  const companyName = applicationDetails.companyDetails?.companyName;

  const handleFinacialDetails = (
    name: keyof ICompanyDetailsProps,
    value: string | number
  ) => {
    const newCompanyObj = {
      ...applicationDetails.companyDetails,
      [name]: value,
    };
    const modifyApplication = {
      ...applicationDetails,
      companyDetails: newCompanyObj,
    };
    dispatch(setApplicationsDetails(modifyApplication));
  };

  const addDocs = async (name: string, formData: FormData) => {
    try {
      const response = await addFinancialDetailsForAgent(
        applicationDetails.id,
        name,
        formData
      );
      dispatch(setApplicationsDetails(response.data));
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };
  // const updateDocs = async (name: string, formData: FormData) => {
  //   try {
  //     const response = await updateDocumentForAgent(
  //       applicationDetails.id,
  //       name,
  //       formData
  //     );
  //   } catch (error) {
  //     setSnackbar(getErrorMessageFromErrorObj(error), 'error');
  //   }
  // };
  const updateDocs = async (name: string, file: File) => {
    try {
      const response = await LosService.saveFinancialData({
        applicationId: applicationDetails.id,
        file: file,
        documentType: name,
      });
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };
  // const handleImage = (name: string, value: any, index: number) => {
  //   const fileObj = { ...filesData[index] };
  //   const newObj = {
  //     ...fileObj,
  //     documentType: name,
  //     file: value,
  //     documentName: value.name,
  //     id: fileObj.id === '' ? new Date().getTime().toString() : fileObj.id,
  //   } as IFilesProps;
  //   const copy = [...filesData];
  //   copy.splice(index, 1, newObj);
  //   setFilesData(copy);
  // };

  const handleImage = (
    name: string,
    value: any,
    index: number,
    documentType: LosDocumentType
  ) => {
    const updatedData = filesData.reduce((pre, curr) => {
      // file.documentType === documentType ? file.file = value : file
      return [
        ...pre,
        {
          ...curr,
          file:
            curr.documentType === documentType
              ? (curr.file = value)
              : curr.file,
          id: curr.id === '' ? new Date().getTime().toString() : curr.id,
        },
      ];
    }, [] as IFilesProps[]);

    // const fileObj = { ...filesData[index] };
    // const newObj = {
    //   ...fileObj,
    //   documentType: name,
    //   file: value,
    //   documentName: value.name,
    //   id: fileObj.id === '' ? new Date().getTime().toString() : fileObj.id,
    // } as IFilesProps;
    // const copy = [...filesData];
    // copy.splice(index, 1, newObj);
    setFilesData(updatedData);
  };
  const isFiles = filesData.some((file: IFilesProps) => file.id === '');
  console.log('filesData', filesData);
  const uploadFiles = async () => {
    console.log('uploadFiles', filesData);
    for (let index = 0; index < filesData.length; index++) {
      const element = filesData[index];
      const formData = new FormData();
      formData.append('file', element.file);
      if (applicationDetails.financialDetails !== null) {
        const exists = applicationDetails.financialDetails?.documents?.some(
          (document) => document.documentType === element.documentType
        );
        if (exists) {
          element.file &&
            (await updateDocs(element.documentType, element.file));
        } else {
          formData.append('employerDetail', companyName ?? '');
          element.file && (await addDocs(element.documentType, formData));
        }
      } else {
        formData.append('employerDetail', companyName ?? '');
        element.file && (await addDocs(element.documentType, formData));
      }
    }
  };
  const onUploadFiles = async () => {
    await uploadFiles();
    // onUpdateApplication();
  };

  const getFileData = async (documents: IFinacialDocumentProps[]) => {
    let fileArr: IFilesProps[] = [];
    let newFileArr: string[] = [
      documentTypeEnum.PAYSLIP_LAST_MONTH,
      documentTypeEnum.PAYSLIP_SECOND_LAST_MONTH,
      documentTypeEnum.PAYSLIP_THIRD_LAST_MONTH,
      documentTypeEnum.BANK_STATEMENT,
      documentTypeEnum.INCOME_TAX_RETURN_LAST_YEAR,
      documentTypeEnum.INCOME_TAX_RETURN_SECOND_LAST_YEAR,
    ];
    await documents.map(
      async (document: IFinacialDocumentProps, index: number) => {
        const newObj = {
          ...document,
          file: null,
        } as IFilesProps;
        fileArr.push(newObj);
        newFileArr = [
          ...newFileArr.filter(
            (newFile: string) => newFile !== document.documentType
          ),
        ];
      }
    );
    newFileArr.forEach((eleme) => {
      fileArr.push({
        documentType: eleme,
        file: null,
        documentName: '',
        id: '',
        documentCategory: '',
      });
    });
    setFilesData([...fileArr]);
  };

  useEffect(() => {
    if (applicationDetails.financialDetails !== null) {
      if (applicationDetails.financialDetails?.documents?.length) {
        const documents: IFinacialDocumentProps[] =
          applicationDetails.financialDetails.documents;

        getFileData(documents);
      }
    } else {
      setFilesData([...DEFAULT_DATA]);
    }
  }, [isClear]);

  const getPaySlipComponent = (file: IFilesProps, index: number) => {
    if (
      file.documentType === documentTypeEnum.PAYSLIP_LAST_MONTH ||
      file.documentType === documentTypeEnum.PAYSLIP_SECOND_LAST_MONTH ||
      file.documentType === documentTypeEnum.PAYSLIP_THIRD_LAST_MONTH
    ) {
      return (
        <>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Media
              file={file}
              handleImage={handleImage}
              isEdit={isEdit}
              index={index}
            />
          </Grid>
        </>
      );
    }
  };
  const getITRComponent = (file: IFilesProps, index: number) => {
    if (
      file.documentType === documentTypeEnum.INCOME_TAX_RETURN_LAST_YEAR ||
      file.documentType === documentTypeEnum.INCOME_TAX_RETURN_SECOND_LAST_YEAR
    ) {
      return (
        <>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Media
              file={file}
              handleImage={handleImage}
              isEdit={isEdit}
              index={index}
            />
          </Grid>
        </>
      );
    }
  };

  return (
    <>
      <Box sx={{ borderBottom: BORDER_GRAY }}>
        <Typography className="font-aspekta-500" pb={2} fontSize={14}>
          Employer details
        </Typography>

        <Grid container spacing={2} pb={2}>
          <Grid item xs={12} sm={4} md={2}>
            <AgentTextField
              title="company Name"
              value={applicationDetails.financialDetails?.employerDetail ?? ''}
              isEdit={isEdit}
              name="companyName"
              onChange={(e) =>
                handleFinacialDetails('companyName', e.target.value)
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ borderBottom: BORDER_GRAY, mt: 2 }}>
        <Typography className="font-aspekta-500" fontSize={14}>
          Income details
        </Typography>

        <Box py={2}>
          <VegaHeader text="payslips (last 3 months)" />
          <Grid container spacing={2}>
            {filesData
              .sort((a, b) => {
                const indexA = financialDocumentTypeArrForIndividual.indexOf(
                  a.documentType
                );
                const indexB = financialDocumentTypeArrForIndividual.indexOf(
                  b.documentType
                );
                return indexA - indexB;
              })
              .map((file: IFilesProps, index: number) =>
                getPaySlipComponent(file, index)
              )}
          </Grid>
        </Box>
        <Box py={2}>
          <VegaHeader text="Bank statement (last 6 months) " />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Media
                file={filesData[3]}
                handleImage={handleImage}
                isEdit={isEdit}
                index={3}
              />
            </Grid>
          </Grid>
        </Box>
        <Box py={2}>
          <VegaHeader text="iTR (last 2 YEARS)" />
          <Grid container spacing={2}>
            {filesData
              .sort((a, b) => {
                const indexA = financialDocumentTypeArrForIndividual.indexOf(
                  a.documentType
                );
                const indexB = financialDocumentTypeArrForIndividual.indexOf(
                  b.documentType
                );
                return indexA - indexB;
              })
              .map((file: IFilesProps, index: number) =>
                getITRComponent(file, index)
              )}
          </Grid>
        </Box>
      </Box>
      <Box textAlign={'end'} sx={{ mt: 2 }}>
        {isEdit ? (
          <>
            <VegaOutlineButton
              text={'Save'}
              isPurple
              // disabled={isFiles}
              onClick={async () => {
                // await onUpdateApplication();
                await onUploadFiles();
                setIsEdit(!isEdit);
              }}
            />
            <VegaTextButton
              text={'Cancel'}
              color={PURPLE.dark}
              onClick={() => {
                setIsEdit(!isEdit);
                setIsClear(!isClear);
                getAllApplicationById();
              }}
            />
          </>
        ) : (
          !isApplicationForHQ &&
          (applicationDetails.status === 'IN_PROGRESS' ||
            applicationDetails.status === 'PENDING') && (
            <VegaOutlineButton
              text={'Edit'}
              startIcon={<EditOutlined />}
              isPurple
              onClick={() => setIsEdit(!isEdit)}
            />
          )
        )}
      </Box>
    </>
  );
};

export default FinacialDetails;
