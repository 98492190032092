import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import LosText from '../onboarding/components/common/LosText';

type Props = {
  title?: string;
  renderTitle?: () => ReactNode;
  renderActions?: () => ReactNode;
};
function SolfinAppBar({ title, renderTitle, renderActions }: Props) {
  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent="space-between"
      borderBottom={`1px solid #E1E4EB;`}
      sx={{
        bgcolor: 'white',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        height: '3.75rem',
      }}
    >
      <Grid item xs={renderActions?.() ? 6 : 12}>
        {renderTitle != null && renderTitle()}
        {renderTitle == null && (
          <LosText fontWeight={500} fontSize={14}>
            {title}
          </LosText>
        )}
      </Grid>

      {renderActions?.() && (
        <Grid item xs={6}>
          {renderActions?.()}
        </Grid>
      )}
    </Grid>
  );
}

export default SolfinAppBar;
