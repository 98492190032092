import { Box } from '@mui/material';
import SolfinAppBar from '../../components/SolfinAppBar';
import ListOfPreDisbursement from '../components/Pre_Disbursemenmt/ListOfPreDisbursement';

const Pre_Disbursement = () => {
  return (
    <>
      <SolfinAppBar title={' Pre Disbursed Applications'} />
      <Box m={2}>
        <ListOfPreDisbursement />
      </Box>
    </>
  );
};

export default Pre_Disbursement;
