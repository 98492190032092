import { Stack, useMediaQuery, useTheme } from '@mui/material';
import LosProgressBar from './common/LosProgressBar';
import LosText from './common/LosText';

type Props = {
  totalSteps?: number;
  currentStep?: number;
  currentStepLabel: string;
  hideProgressBar?: boolean;
};

function LosAppBar({
  totalSteps,
  currentStep,
  currentStepLabel,
  hideProgressBar = false,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div
      style={{
        width: '100%',
        paddingTop: '1rem',
        marginBottom: isMobile ? '0rem' : '3.5rem',
        borderBottom: '1px solid #E1E4EB',
      }}
    >
      <Stack direction={'row'} width="100%">
        <div style={{ width: '100%', marginLeft: '1rem' }}>
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent={'space-between'}
            width="100%"
          >
            <Stack direction={'row'} spacing={1}>
              <LosText
                display={!!currentStep && !!totalSteps ? 'block' : 'none'}
                text={`${currentStep}/${totalSteps}`}
                style={{
                  fontSize: '1.0625rem',
                  lineHeight: '120%',
                  fontWeight: 600,
                }}
              />
              <LosText
                text={currentStepLabel}
                style={{
                  fontSize: '1.0625rem',
                  lineHeight: '120%',
                  fontWeight: 600,
                  ...(hideProgressBar && {
                    marginBottom: '1.12rem',
                  }),
                }}
              />
            </Stack>
          </Stack>
          {isMobile && hideProgressBar == false && (
            <LosProgressBar max={totalSteps} value={currentStep} min={0} />
          )}
        </div>
      </Stack>
      {isMobile == false && hideProgressBar == false && (
        <div
          style={{
            paddingLeft: '4px',
            paddingRight: '4px',
          }}
        >
          <LosProgressBar max={totalSteps} value={currentStep} min={0} />
        </div>
      )}
    </div>
  );
}

export default LosAppBar;
