import { Grid, InputAdornment, Stack } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { LosTextButton } from '../../../components/LosTextButton'
import LosFormInputField from '../../components/common/LosFormInputField'
import LosSelect, { LosSelectOption } from '../../components/common/LosSelect'
import LosText from '../../components/common/LosText'
import LosTextField from '../../components/common/LosTextField'
import { AddOutlinedIcon } from '../../icons/LosIcons'
import { useLos } from '../../providers/LosProvider'
import { CollateralTypeFormData, GuranteeType } from '../../types/Los'
import { StringUtility } from '../../utils/StringUtility'

enum ViewState {
  Collapsed,
  Expanded,
}

enum InputType {
  Type = 'Type',
  Value = 'Value',
}

type Props = {
  formData: Partial<CollateralTypeFormData>[]
  onAddCollateralClick: () => void
  onRemoveCollateral: (index: number) => void
  handleUpdate: (data: {
    type: InputType
    value: string
    index: number
  }) => void
}

function AddCollateralDetails({
  formData,
  onAddCollateralClick,
  handleUpdate,
  onRemoveCollateral,
}: Props) {
  const { collateralTypes, fetchCollateralTypes } = useLos()
  const [viewState, setViewState] = useState<ViewState>(ViewState.Collapsed)

  useEffect(() => {
    if (viewState == ViewState.Collapsed && formData.length > 0) {
      setViewState(ViewState.Expanded)
    } else if (viewState == ViewState.Expanded && formData.length == 0) {
      setViewState(ViewState.Collapsed)
    }
  }, [formData])

  useEffect(() => {
    fetchCollateralTypes()
  }, [])

  return (
    <div style={{ width: '100%' }}>
      <Stack
        display={viewState == ViewState.Collapsed ? 'flex' : 'none'}
        direction={'row'}
        alignItems='center'
        justifyContent={'space-between'}
      >
        <LosText text='Collateral details' fontSize={'0.875rem'} />
      </Stack>
      {viewState == ViewState.Collapsed && (
        <LosText
          text='Add a collateral to improve your odds of getting your loan approved'
          variant='caption'
        />
      )}
      <div
        style={{
          display: viewState == ViewState.Expanded ? 'flex' : 'none',
          gap: '1rem',
          flexDirection: 'column',
        }}
      >
        {formData.map((item, index) => {
          return (
            <CollateralTypeInputForm
              key={index}
              formData={item}
              options={collateralType(collateralTypes)}
              onTypeChange={function (value: string): void {
                handleUpdate({
                  type: InputType.Type,
                  value: value,
                  index: index,
                })
              }}
              onValueChange={function (value: string): void {
                handleUpdate({
                  type: InputType.Value,
                  value: value,
                  index: index,
                })
              }}
              onCancelClick={function (): void {
                onRemoveCollateral(index)
              }}
              showCancelButton={index == formData.length - 1}
            />
          )
        })}
      </div>
      {formData.length < collateralType(collateralTypes).length && (
        <div style={{ marginTop: '1rem' }}>
          <LosTextButton
            startIcon={<AddOutlinedIcon />}
            text={'Add Collateral'}
            onClick={() => {
              onAddCollateralClick()
            }}
          />
        </div>
      )}
    </div>
  )
}

export default AddCollateralDetails

const CollateralTypeInputForm = (data: {
  options: LosSelectOption[]
  onTypeChange: (value: string) => void
  onValueChange: (value: string) => void
  onCancelClick: () => void
  formData: Partial<CollateralTypeFormData>
  showCancelButton: boolean
}) => {
  const [type, setType] = useState<string>('')
  const [amount, setAmount] = useState<number>(0)

  function onTypeChange(value: string) {
    setType(value)
    data.onTypeChange(value)
  }

  function onValueChange(value: number) {
    setAmount(value)
    data.onValueChange(`${value}`)
  }

  useEffect(() => {
    setType(data.formData.type ?? '')

    const amount = data.formData.amount
    if (amount != null) {
      const numberValue = Number(amount)
      setAmount(numberValue ?? 0)
    }
  }, [data.formData])

  return (
    <LosFormInputField
      label='Collateral Type'
      endAdornment={
        data.showCancelButton ? (
          <LosTextButton
            text={'Cancel'}
            color='rgba(186, 28, 28, 1)'
            onClick={() => {
              data.onCancelClick()
            }}
          />
        ) : (
          ''
        )
      }
    >
      <Grid container>
        <Grid item xs={6}>
          <Stack marginRight={'0.5rem'}>
            <LosSelect
              options={data.options}
              value={type}
              onChange={e => onTypeChange(e.target.value as string)}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <LosTextField
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <LosText text='₹' />
                </InputAdornment>
              ),
            }}
            value={amount > 0 ? StringUtility.formatCurrency(amount) : ''}
            onChange={e => {
              const value = e.target.value as string
              const validValue = value.replaceAll(',', '')
              let numberValue = Number(validValue)
              if (isNaN(numberValue)) {
                return
              }
              onValueChange(numberValue)
            }}
          />
        </Grid>
      </Grid>
    </LosFormInputField>
  )
}

const collateralType = (types: string[]) =>
  types.map(item => {
    const option: LosSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    }
    return option
  })
