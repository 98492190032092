import { Grid } from '@mui/material';
import { toLowerCase } from '../../../../../../constants/commonFunction';
import { GREEN, RED } from '../../../../../../constants/style';
import {
  ICreditWorthinessScoreProps,
  IFinanceScoresProps,
  useRuleEngine,
} from '../../../../../LMSAgent/Provider/RuleEngingProvider';
import LosDetailsWrapper from '../../../../LosDetailsWrapper';

interface IBasicCriteriaProps {
  name: string;
  value: string;
  color: string;
}

const getColorValue = (
  score: string | number | undefined,
  lessNumber: number
) => {
  if (!score)
    return {
      value: 'FAILED',
      color: RED.red,
    };

  const parseScore = typeof score === 'string' ? JSON.parse(score) : score;
  return {
    value: parseScore >= lessNumber ? 'PASSED' : 'FAILED',
    color: parseScore >= lessNumber ? GREEN.dark : RED.red,
  };
};

const UnderwrittingBasicCriteria = () => {
  const { underWrittingScrore } = useRuleEngine();
  const checkPointsGreaterThan3 = (point: number | undefined | null) => {
    if (!point) return false;
    if (point >= 3) {
      return true;
    }
    return false;
  };
  const isCreditWorthiness = underWrittingScrore?.creditWorthinessScore
    ? checkPointsGreaterThan3(
        underWrittingScrore?.creditWorthinessScore?.points
      )
    : false;
  const isUnderwritingCollateral =
    underWrittingScrore?.underwritingCollateralScore
      ? checkPointsGreaterThan3(
          underWrittingScrore?.underwritingCollateralScore?.points
        )
      : false;
  const isUnderwritingCompliance =
    underWrittingScrore?.underwritingComplianceScore
      ? checkPointsGreaterThan3(
          underWrittingScrore?.underwritingComplianceScore?.points
        )
      : false;
  const isUnderwritingFinancial =
    underWrittingScrore?.underwritingFinancialScore
      ? checkPointsGreaterThan3(
          underWrittingScrore?.underwritingFinancialScore?.points
        )
      : false;
  const isUnderwritingManagement =
    underWrittingScrore?.underwritingManagementScore
      ? checkPointsGreaterThan3(
          underWrittingScrore?.underwritingManagementScore?.points
        )
      : false;

  const isAllPassed =
    isCreditWorthiness &&
    isUnderwritingCollateral &&
    isUnderwritingCompliance &&
    isUnderwritingFinancial &&
    isUnderwritingManagement;

  const creditScore =
    underWrittingScrore?.creditWorthinessScore &&
    underWrittingScrore?.creditWorthinessScore.creditWorthinessScore.find(
      (creditScore: ICreditWorthinessScoreProps) =>
        creditScore.creditWorthinessFactors === 'CREDIT_SCORE'
    );
  const debitToIncomeScore =
    underWrittingScrore?.underwritingFinancialScore.financeScores.find(
      (financilScore: IFinanceScoresProps) =>
        financilScore.financialFactors === 'DEBIT_TO_INCOME_RATIO'
    );
  const netWorthScore =
    underWrittingScrore?.underwritingFinancialScore.financeScores.find(
      (financilScore: IFinanceScoresProps) =>
        financilScore.financialFactors === 'NET_WORTH'
    );

  const basicCriteria: IBasicCriteriaProps[] = [
    {
      name: 'Overall Weighted Score',
      value: underWrittingScrore?.creditWorthinessScore
        ? getColorValue(underWrittingScrore?.creditWorthinessScore?.points, 3)
            .value
        : 'FAILED',
      color: underWrittingScrore?.creditWorthinessScore
        ? getColorValue(underWrittingScrore?.creditWorthinessScore?.points, 3)
            .color
        : RED.red,
    },
    {
      name: 'Individual Section Criteria',
      value: isAllPassed ? 'PASSED' : 'FAILED',
      color: isAllPassed ? GREEN.dark : RED.red,
    },
    {
      name: 'Credit Score/CIBIL',
      value: getColorValue(creditScore ? creditScore?.value : 0, 650).value,
      color: getColorValue(creditScore ? creditScore?.value : 0, 650).color,
    },
    {
      name: 'Debt to Income',
      value: getColorValue(debitToIncomeScore?.value, 25).value,
      color: getColorValue(debitToIncomeScore?.value, 25).color,
    },
    {
      name: 'Net Worth',
      value: getColorValue(netWorthScore?.value, 1).value,
      color: getColorValue(netWorthScore?.value, 1).color,
    },
  ];
  return (
    <div>
      <Grid container spacing={2}>
        {basicCriteria.map((criteria: IBasicCriteriaProps, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={3} lg={2.4} xl={2.4}>
            {' '}
            <LosDetailsWrapper
              name={criteria.name}
              value={toLowerCase(criteria.value)}
              isInfo
              valueColor={criteria.color}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default UnderwrittingBasicCriteria;
