/** @format */
export type PolicyComponentType = {
  initialValue: any;
  onSubmit: (policy: AnyPolicyType) => void;
  onReject: (policy: AnyPolicyType) => void;
  onApprove: (policy: AnyPolicyType) => void;
  isLoading: boolean;
  pddList?: any;
  onPastCommentsClick: (entityId?: string) => void;
};

export enum PolicyStatus {
  DRAFT = 'DRAFT',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  REJECTED = 'REJECTED',
}

export enum PolicyType {
  BILLING = 'BILLING',
  MAD = 'MAD',
  ACCOUNT = 'ACCOUNT',
  TAX = 'TAX',
  CHARGES = 'CHARGES',
  INTEREST = 'INTEREST',
  REPAYMENT_WATERFALL = 'REPAYMENT_WATERFALL',
  TRANSACTION_TO_INSTALLMENT = 'TRANSACTION_TO_INSTALLMENT',
  TRANSACTION = 'TRANSACTION',
}

export interface PolicyInterface {
  policy_id?: string;
  policy_name: string;
  policy_type: string;
  policy_description: string;
  is_override: boolean;
  start_date: string;
  end_date: string;
  priority?: any;
  status: PolicyStatus;
  updated_at?: string | null;
  cohort_id?: string | null;
}

export type AnyPolicyType =
  | BillingPolicyInterface
  | MadPolicyInterface
  | AccountPolicyInterface
  | TaxPolicyInterface
  | ChargePolicyInterface
  | IntersetPolicyInterface
  | RepaymentPolicyInterface
  | TxnToEmiPoicyInterface
  | TransactionPolicy
  | PasswordPolicy;

export interface BillingPolicyInterface extends PolicyInterface {
  cycle_end_date: number | string | null;
  billing_cycle_type: string;
  days_between_end_date_and_bill_date: number | string | null;
  due_date: number | string | null;
  minimum_tad: number | string | null;
}

export type BILLING_POLICY_KEYS = keyof BillingPolicyInterface;

export interface MadComponent {
  transaction_category: string;
  value_type: string;
  value: number;
}
export interface MadPolicyInterface extends PolicyInterface {
  mad_components: MadComponent[];
  tad_components: MadComponent[];
  minimum_mad: number;
  minimum_tad: number;
  round_up?: boolean;
  round_up_to?: number;
  interest_status?: string | null;
}
export type MAD_POLICY_KEYS = keyof MadPolicyInterface;

export interface AccountPolicyValueType {
  value_type: string;
  value: number;
}
export interface AccountPolicyInterface extends PolicyInterface {
  overlimit: AccountPolicyValueType;
  grace_period: number;
  payment_deviation: AccountPolicyValueType;
  cash_limit: AccountPolicyValueType;
  days_to_mark_account_charge_off: number;
  days_to_mark_account_write_off: number;
}
export type ACCOUNT_POLICY_KEYS = keyof AccountPolicyInterface;

export interface Tax {
  name: string;
  description: string;
  value_type: string;
  value: number;
  tax_sub_type_components_list?: TaxSubTypeComponent[] | null;
}
export interface TaxComponent {
  country: string;
  taxes: Tax[];
}
export interface TaxSubTypeComponent {
  tax_sub_type: string;
  value_type: string;
  value: number;
}
export interface TaxPolicyInterface extends PolicyInterface {
  tax_components: TaxComponent[];
}
export type TAX_POLICY_KEYS = keyof TaxPolicyInterface;

export interface ChargeCriteriaInterface {
  mcc?: any;
  transaction_codes: string[] | null;
}
export interface SlabChargesInterface {
  value_type: string;
  value: number;
  start_amount: number;
  end_amount: number;
}
export interface ChargeInterface {
  charge_type: string;
  value_type?: string;
  value?: number;
  max?: number;
  min?: number;
  charges?: SlabChargesInterface[];
  default_slab?: SlabChargesInterface;
  amount_type: string;
}
export interface ChargeRuleInterface {
  charge_code: string;
  charge_criteria: ChargeCriteriaInterface;
  charge: ChargeInterface;
  taxation_type: string;
  deduction_type?: string;
  frequency?: string;
}
export type ChargeRuleInterfaceKeyTypes = keyof ChargeRuleInterface;
export type ChargeRuleInterfaceValueTypes =
  ChargeRuleInterface[ChargeRuleInterfaceKeyTypes];
export interface ChargePolicyInterface extends PolicyInterface {
  charge_rules: ChargeRuleInterface[];
}

export type PostingCategoryType =
  | 'RETAIL_PRINCIPAL'
  | 'RETAIL_INTEREST'
  | 'RETAIL_FEE'
  | 'CASH_PRINCIPAL'
  | 'CASH_INTEREST'
  | 'CASH_FEE'
  | 'EMI_PRINCIPAL'
  | 'EMI_INTEREST'
  | 'EMI_FEE';
export type InterestStatusType = 'NORMAL' | 'REVOLVING' | 'DELINQUENT';
export interface InterestRateSlabInterface {
  from_amount: number;
  to_amount: number;
  rate: number;
}
export interface InterestRuleInterface {
  interest_rate_value_type: string;
  default_rate: number;
  amount_slab_interest_rates?: InterestRateSlabInterface[];
}

export interface IntersetPolicyInterface extends PolicyInterface {
  rounding_method: string;
  day_count_method: string;
  liquidation_policy?: string;
  interest_liquidation?: string;
  default_interest_rate_rule: any;
  interest_status_to_interest_rate_rules: any;
}
export interface RepaymentWaterfall {
  amount_type: string;
  priority_order: number;
}
export interface RepaymentPolicyInterface extends PolicyInterface {
  auto_repayment: boolean;
  auto_debit_number_of_retries: number;
  partial_payoff_allowed: boolean;
  partial_pay_off_minimum_number_of_installments: number;
  repayment_waterfall: RepaymentWaterfall[];
}

export interface InterestChargeConfig {
  interest_rate: number;
  duration_in_months: number;
  discount?: number;
  effective_interest?: number;
}
export interface InstallmentPolicy {
  amortizationType: string;
  interestType: string;
  interestChargeConfig: InterestChargeConfig[];
  mcc: string[] | number[];
  maxProcessingFee?: number;
  processingFeeInPercentage?: number;
}
export interface TxnToEmiPoicyInterface extends PolicyInterface {
  minimum_amount: number;
  transaction_category: string;
  installment_policy: InstallmentPolicy[];
}
export interface PasswordPolicy extends PolicyInterface {
  selected_fields: PasswordPolicyValueType[];
}
export enum PasswordPolicyValueType {
  MOBILE_NUMBER = 'MOBILE_NUMBER',
  PAN_NUMBER = 'PAN_NUMBER',
  DOB = 'DOB',
}

export interface TransactionPolicy extends PolicyInterface {
  transaction_limits: TransactionLimit[];
}

export interface TransactionLimit {
  transaction_code: TransactionCode;
  per_transaction_limit: number;
  daily_limit: number;
  monthly_limit: number;
  yearly_limit: number;
}

export enum TransactionCode {
  E_COM = 'CARD_ECOM',
  POS = 'CARD_POS',
  CARD_CASH_WITHDRAW = 'CARD_CASH_WITHDRAW',
  CARD_NFC = 'CARD_NFC',
}

export interface PolicySelections {
  BILLING: BillingPolicyInterface | null;
  MAD: MadPolicyInterface | null;
  ACCOUNT: AccountPolicyInterface | null;
  TAX: TaxPolicyInterface | null;
  CHARGES: ChargePolicyInterface | null;
  INTEREST: IntersetPolicyInterface | null;
  REPAYMENT_WATERFALL: RepaymentPolicyInterface | null;
  TRANSACTION_TO_INSTALLMENT: TxnToEmiPoicyInterface | null;
  TRANSACTION: TransactionPolicy | null;
}

export interface IdentityFRMPolicy extends PolicyInterface {
  identity_frm_criteria: IdentityFRMCriteria;
}

export interface IdentityFRMCriteria {
  [key: string]: FRMCriteria | undefined;
}

export interface FRMCriteria {
  failsafe: boolean;
  acceptance_criteria?: FRMCriteriaConfig | null;
  manual_inspection_criteria?: FRMCriteriaConfig | null;
  reject_criteria?: FRMCriteriaConfig | null;
}

export interface FRMCriteriaConfig {
  min?: number | null;
  max?: number | null;
  exact?: number | null;
  min_inclusive?: boolean;
  max_inclusive?: boolean;
}
