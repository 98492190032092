import { useState } from 'react'
import ReviewPredisbursedApplicationForAgent from './components/ReviewPredisbursedApplicationForAgent'
import { CustomerApplication } from '../../../onboarding/types/Los'
import { PaginatedResponse } from '../../../../utils/api'
import LosDataGrid from '../../../components/LosDataGrid'
import { usePagination } from '../../../hooks/usePagination'
import { LosService } from '../../../onboarding/api/LosService'
import { useClientAuth } from '../../../../providers/ClientProvider'
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid'
import _ from 'lodash'
import LosButton from '../../../onboarding/components/common/LosButton'
import LosStatusPill from '../../../components/LosStatusPill'
import { Stack, useTheme, useMediaQuery, Box } from '@mui/material'
import { LOS_PROGRAM_ID } from '../../../onboarding/api/constants'
import PreDisbursalList from '../MobileView/PreDisbursalList'
import MobileViewPagination from '../MobileViewPagination'
import AddLoanTranchesDialog from './components/AddLoanTrenchDialog'

type OpenDialogProps = {
  show: boolean
  applicationId: string | null
}

const ListOfPreDisbursal = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { agentId } = useClientAuth()
  const [openViewDialog, setOpenViewDialog] = useState<OpenDialogProps>({
    show: false,
    applicationId: null,
  })
  const [openUpdateDialog, setOpenUpdateDialog] = useState<OpenDialogProps>({
    show: false,
    applicationId: null,
  })

  const {
    loading,
    data,
    pageSize,
    page,
    totalPage,
    privious,
    next,
    changePage,
    rowCount,
  } = usePagination<CustomerApplication>({
    initialData: [],
    api: fetchApplications,
  })

  const COLUMN_DEF: GridColumns<CustomerApplication> = [
    {
      field: 'customerId',
      headerName: 'Customer Id',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as CustomerApplication
        return row.customerId
      },
      flex: 2,
      minWidth: 200,
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as CustomerApplication
        var displayText = '-'
        if (row.customerName) {
          displayText = row.customerName
        }
        return displayText
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'losEntityType',
      headerName: 'Entity',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as CustomerApplication
        return _.startCase(_.toLower(row.losEntityType))
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'mobileNumber',
      headerName: 'Mobile Number',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as CustomerApplication
        var displayText = '-'
        if (row.phone) {
          displayText = row.phone
        }
        return displayText
      },
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'amount',
      headerName: 'Requested Loan Amount',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as CustomerApplication
        var displayText = '-'
        if (application.basicInfo.amount) {
          displayText = `₹ ${application.basicInfo.amount}`
        }
        return displayText
      },
      minWidth: 200,
      flex: 1.5,
    },
    {
      field: 'tenure',
      headerName: 'Tenure',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as CustomerApplication
        var displayText = '-'
        if (application.basicInfo.tenure) {
          displayText = `${application.basicInfo.tenure} Months`
        }
        return displayText
      },
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'status',
      headerName: 'Status',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as CustomerApplication
        return <LosStatusPill status={row.status} />
      },
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as CustomerApplication
        return (
          <Stack direction={'row'} spacing='0.5rem'>
            <LosButton
              size='small'
              text='View'
              variant='outlined'
              onClick={() => {
                setOpenViewDialog({
                  applicationId: row.id,
                  show: true,
                })
              }}
            />
            <LosButton
              size='small'
              text='Update'
              variant='outlined'
              onClick={() => {
                showUpdateModal(row.id)
              }}
            />
          </Stack>
        )
      },
      flex: 1,
      minWidth: 200,
    },
  ]

  async function fetchApplications(
    page: number,
    pageSize: number
  ): Promise<PaginatedResponse<CustomerApplication>> {
    try {
      if (!agentId) {
        throw new Error('AgentId is required')
      }
      const response = await LosService.getAllApplicationsForAgent({
        agentId: agentId,
        pageSize: pageSize,
        page: page,
        status: 'PRE_DISPERSED,READY_FOR_DISPERSAL',
        sortBy: 'createdAt',
        sortDir: 'desc',
        programId: LOS_PROGRAM_ID,
      })
      return response
    } catch (error) {
      throw error
    }
  }

  const showDetails = (applicationId: string) => {
    setOpenViewDialog({
      applicationId: applicationId,
      show: true,
    })
  }

  const showUpdateModal = async (applicationId: string) => {
    setOpenUpdateDialog({
      applicationId: applicationId,
      show: true,
    })
  }

  return (
    <>
      {isMobile ? (
        data.length ? (
          <Box mx={1}>
            <PreDisbursalList
              applications={data}
              loading={loading}
              showUpdateModal={showUpdateModal}
              showDetails={showDetails}
            />
            <MobileViewPagination
              page={page}
              totalPage={totalPage}
              privious={privious}
              next={next}
            />
          </Box>
        ) : (
          ''
        )
      ) : (
        <div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
          <LosDataGrid
            data={data}
            columns={COLUMN_DEF}
            rowCount={rowCount}
            page={page}
            pageSize={pageSize}
            loading={loading}
            idColumn={'id'}
            onPageChange={changePage}
          />
        </div>
      )}

      <ReviewPredisbursedApplicationForAgent
        open={openViewDialog.show}
        applicationId={openViewDialog.applicationId}
        onClose={function (): void {
          setOpenViewDialog({
            show: false,
            applicationId: null,
          })
        }}
      />

      <AddLoanTranchesDialog
        open={openUpdateDialog.show}
        applicationId={openUpdateDialog.applicationId}
        onClose={function (): void {
          setOpenUpdateDialog({
            show: false,
            applicationId: null,
          })
        }}
      />
    </>
  )
}

export default ListOfPreDisbursal
