import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { BORDER_GRAY } from '../../../../../../constants/style';
import { useSnackbar } from '../../../../../../providers/SnackbarProvider';
import {
  getApplicationsDetails,
  setApplicationsDetails,
} from '../../../../../../store/common/agentApplicationsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hook';
import { getErrorMessageFromErrorObj } from '../../../../../../utils/api';
import {
  LOSEntityType,
  documentTypeEnum,
} from '../../../../../enums/applications';
import { LosService } from '../../../../../onboarding/api/LosService';
import {
  LosDocumentType,
  UserDataDto,
} from '../../../../../onboarding/types/Los';
import {
  ICompanyDetailsProps,
  IFinacialDocumentProps,
} from '../../../../../types/application';
import AgentTextField from '../AgentTextField';
import { IFilesProps } from '../ElectricityDetails/ElectricityDetails';

interface IProps {
  getAllApplicationById: () => void;
}

const INDIVIDUAL_DATA = [
  {
    documentType: documentTypeEnum.GST_DOCUMENT,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.CERTIFICATE_OF_INCORPORATION,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.AOA,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
];
const SELF_EMPLOYED_DATA = [
  {
    documentType: documentTypeEnum.GST_DOCUMENT,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
];
const PARTNERSHIP_DATA = [
  {
    documentType: documentTypeEnum.GST_DOCUMENT,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.PARTNERSHIP_FIRM_DEED,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
];
const PVT_LTD_DATA = [
  {
    documentType: documentTypeEnum.MOA,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.CERTIFICATE_OF_INCORPORATION,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.AOA,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
];

const CompanyDetails = ({ getAllApplicationById }: IProps) => {
  const { applicationDetails, isApplicationForHQ } = useAppSelector(
    getApplicationsDetails
  );

  const dispatch = useAppDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isClear, setIsClear] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserDataDto>();
  const companyName = userDetails?.companyDetailsResponse?.companyName ?? '';
  const gstNo = userDetails?.companyDetailsResponse?.gstNo ?? '';

  // const gstNo = applicationDetails.companyDetails?.gstNo
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUserDetailsApi = async () => {
    try {
      setIsLoading(true);
      const response = await LosService.getUserDetails(applicationDetails.id);
      setUserDetails(response);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleCompanyDetails = (
    name: keyof ICompanyDetailsProps,
    value: string | number
  ) => {
    const newCompanyObj = {
      ...userDetails?.companyDetailsResponse,
      [name]: value,
    };
    const modifyApplication = {
      ...userDetails,
      companyDetails: newCompanyObj,
    };
    dispatch(setApplicationsDetails(modifyApplication));
  };
  const [filesData, setFilesData] = useState<IFilesProps[]>([]);

  const { setSnackbar } = useSnackbar();

  const addCompanyDocs = async (name: string, file: File) => {
    try {
      const response = await LosService.saveCompanyDetails({
        applicationId: applicationDetails.id,
        file: file,
        documentType: name as LosDocumentType,
      });
      dispatch(setApplicationsDetails(response));
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };

  const handleImage = (name: string, value: any, index: number) => {
    const fileObj = { ...filesData[index] };
    const newObj = {
      ...fileObj,
      documentType: name,
      file: value,
      documentName: value.name,
      id: fileObj.id === '' ? new Date().getTime().toString() : fileObj.id,
    } as IFilesProps;
    const copy = [...filesData];
    copy.splice(index, 1, newObj);
    setFilesData(copy);
  };

  const getFileData = async (documents: IFinacialDocumentProps[]) => {
    let fileArr: IFilesProps[] = [];
    let newFileArr: string[] = [];
    if (applicationDetails.losEntityType === LOSEntityType.INDIVIDUAL) {
      newFileArr = [
        documentTypeEnum.GST_DOCUMENT,
        documentTypeEnum.CERTIFICATE_OF_INCORPORATION,
        documentTypeEnum.AOA,
      ];
    } else if (
      applicationDetails.losEntityType === LOSEntityType.SOLE_PROPRIETOR ||
      applicationDetails.losEntityType === LOSEntityType.SELF_EMPLOYED
    ) {
      newFileArr = [documentTypeEnum.GST_DOCUMENT];
    } else if (applicationDetails.losEntityType === LOSEntityType.PARTNERSHIP) {
      newFileArr = [
        documentTypeEnum.GST_DOCUMENT,
        documentTypeEnum.PARTNERSHIP_FIRM_DEED,
      ];
    } else if (applicationDetails.losEntityType === LOSEntityType.PVT_LTD) {
      newFileArr = [
        documentTypeEnum.MOA,
        documentTypeEnum.CERTIFICATE_OF_INCORPORATION,
        documentTypeEnum.AOA,
      ];
    }
    await documents.map(
      async (document: IFinacialDocumentProps, index: number) => {
        const newObj = {
          ...document,
          file: null,
        } as IFilesProps;
        fileArr.push(newObj);
        newFileArr = [
          ...newFileArr.filter(
            (newFile: string) => newFile !== document.documentType
          ),
        ];
      }
    );
    newFileArr.forEach((eleme) => {
      fileArr.push({
        documentType: eleme,
        file: null,
        documentName: '',
        id: '',
        documentCategory: '',
      });
    });
    setFilesData([...fileArr]);
  };

  const isFiles = filesData.some((file: IFilesProps) => file.id === '');

  const uploadFiles = async () => {
    console.log('uploadFiles', filesData);
    for (let index = 0; index < filesData.length; index++) {
      const element = filesData[index];
      element.file &&
        (await addCompanyDocs(element.documentType, element.file));
    }
  };

  const onUploadFiles = async () => {
    await uploadFiles();
  };

  useEffect(() => {
    if (applicationDetails?.companyDetails !== null) {
      if (applicationDetails?.companyDetails?.documents?.length) {
        const documents: IFinacialDocumentProps[] =
          applicationDetails.companyDetails.documents;

        getFileData(documents);
      }
    } else {
      if (applicationDetails.losEntityType === LOSEntityType.INDIVIDUAL) {
        setFilesData([...INDIVIDUAL_DATA]);
      } else if (
        applicationDetails.losEntityType === LOSEntityType.SOLE_PROPRIETOR ||
        applicationDetails.losEntityType === LOSEntityType.SELF_EMPLOYED
      ) {
        setFilesData([...SELF_EMPLOYED_DATA]);
      } else if (
        applicationDetails.losEntityType === LOSEntityType.PARTNERSHIP
      ) {
        setFilesData([...PARTNERSHIP_DATA]);
      } else {
        setFilesData([...PVT_LTD_DATA]);
      }
    }
  }, [isClear]);

  const getHeader = (header: string) => {
    if (header === documentTypeEnum.AOA) {
      return 'ARTICLES_OF_ASSOCIATION';
    } else if (header === documentTypeEnum.MOA) {
      return 'MEMORANDUM_OF_ASSOCIATION';
    } else return header;
  };

  useEffect(() => {
    getUserDetailsApi();
  }, []);

  return (
    <>
      <Box sx={{ borderBottom: BORDER_GRAY }}>
        <Typography className="font-aspekta-500" pb={2} fontSize={14}>
          GST details
        </Typography>

        <Grid container spacing={2} pb={2}>
          <Grid item xs={12} sm={4} md={2}>
            <AgentTextField
              title="company Name"
              value={userDetails?.companyDetailsResponse?.companyName ?? ''}
              isEdit={isEdit}
              name="companyName"
              onChange={(e) =>
                handleCompanyDetails('companyName', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <AgentTextField
              title="gst no."
              value={userDetails?.companyDetailsResponse?.gstNo ?? '--'}
              isEdit={isEdit}
              name="gstNo"
              onChange={(e) => handleCompanyDetails('gstNo', e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
      {/* <Box sx={{ borderBottom: BORDER_GRAY }}>
        <Box py={2}>
          <Typography className="font-aspekta-500" pb={2} fontSize={14}>
            Documents
          </Typography>
          <Grid container spacing={2}>
            {filesData
              .sort((a, b) => {
                if (a.documentType < b.documentType) return -1;
                if (a.documentType > b.documentType) return 1;
                return 0;
              })
              .map((file: IFilesProps, index: number) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <VegaHeader
                    text={toLowerCase(getHeader(file.documentType))}
                  />
                  <Media
                    file={file}
                    handleImage={handleImage}
                    isEdit={isEdit}
                    index={index}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box> */}
      {/* <Box textAlign={'end'} sx={{ mt: 2 }}>
        {isEdit ? (
          <>
            <VegaOutlineButton
              text={'Save'}
              isPurple
              disabled={isFiles}
              onClick={async () => {
                // await onUpdateApplication();
                await onUploadFiles();
                setIsEdit(!isEdit);
              }}
            />
            <VegaTextButton
              text={'Cancel'}
              color={PURPLE.dark}
              onClick={() => {
                setIsEdit(!isEdit);
                setIsClear(!isClear);
                getAllApplicationById();
              }}
            />
          </>
        ) : (
          !isApplicationForHQ &&
          (applicationDetails.status === 'IN_PROGRESS' ||
            applicationDetails.status === 'PENDING') && (
            <VegaOutlineButton
              text={'Edit'}
              startIcon={<EditOutlined />}
              isPurple
              onClick={() => setIsEdit(!isEdit)}
            />
          )
        )}
      </Box> */}
    </>
  );
};

export default CompanyDetails;
