import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import LOSDateField from '../../../../../../components/LOSDateField';
import LosButton from '../../../../../../onboarding/components/common/LosButton';
import LosFormInputField from '../../../../../../onboarding/components/common/LosFormInputField';
import LosTextField from '../../../../../../onboarding/components/common/LosTextField';
import { StringUtility } from '../../../../../../onboarding/utils/StringUtility';
import { LoanManagementService } from '../../../../../api/LoanManagementService';
import { useSnackbar } from '../../../../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../../../../utils/api';
import { LoanDispersalSchedule } from '../../../../../types/Lms';

export interface IDisburseProps {
  dispersalScheduleId: string;
  date: any;
  utr: string | number;
  emiFirstDate: string;
}

type Props = {
  onClose: () => void;
  disbersalSchedule: LoanDispersalSchedule[];
};

const DisburseDetails = ({ onClose, disbersalSchedule }: Props) => {
  const { setSnackbar } = useSnackbar();

  const [disburse, setDisburse] = useState<IDisburseProps>({
    dispersalScheduleId: '',
    date: '',
    utr: '',
    emiFirstDate: '',
  });
  const [firstSchedule, setFirstSchedule] = useState<LoanDispersalSchedule>();

  const isInputValid = () => {
    const isScheduleIdValid = disburse.dispersalScheduleId.length > 0;
    const isUtrValid = disburse.utr.toString().length > 0;
    const isDateValid = disburse.date.toString().length > 0;
    const isEmiFirstDateValid = disburse.emiFirstDate.length > 0;
    return (
      isScheduleIdValid && isUtrValid && isDateValid && isEmiFirstDateValid
    );
  };

  const getMinFirstEmiDate = () => {
    const disbursalDate = disburse.date as string;
    if (disbursalDate.length > 0) {
      const dateObject = new Date(disbursalDate);
      dateObject.setDate(dateObject.getDate() + 30);
      return dateObject.toISOString();
    }
    return '';
  };

  const getMaxFirstEmiDate = () => {
    const disbursalDate = disburse.date as string;
    if (disbursalDate.length > 0) {
      const dateObject = new Date(disbursalDate);
      dateObject.setDate(dateObject.getDate() + 90);
      return dateObject.toISOString();
    }
    return '';
  };

  const getFirstScheduleAmountDisplayText = () => {
    if (firstSchedule?.amount) {
      return StringUtility.formatCurrency(firstSchedule.amount);
    }
    return '-';
  };

  const handleChange = (name: keyof IDisburseProps, value: any) => {
    setDisburse({ ...disburse, [name]: value });
  };

  async function disburseTranche() {
    try {
      const schedule = disbersalSchedule;

      if (schedule.length > 0) {
        const loanId = schedule[0].loanId;

        if (loanId) {
          const emiDateResponse =
            await LoanManagementService.updateLoanFirstEmiDate({
              firstEmiDate: disburse.emiFirstDate,
              loanId: loanId,
            });
          setSnackbar('First EMI Date updated');
        }
      }

      const response = await LoanManagementService.disburseLoanSchedule({
        scheduleId: disburse.dispersalScheduleId,
        date: disburse.date,
        utrNumber: `${disburse.utr}`,
      });

      setSnackbar('Tranche Disbursed!!');
    } catch (error) {
      console.error(error);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      onClose();
    }
  }

  useEffect(() => {
    if (disbersalSchedule.length > 0) {
      setFirstSchedule(disbersalSchedule[0]);
      setDisburse((prev) => {
        return {
          ...prev,
          dispersalScheduleId: disbersalSchedule[0].id,
        };
      });
    }
  }, [disbersalSchedule]);

  return (
    <>
      <Typography className="font-aspekta-500" fontSize={14}>
        Disbursal Details
      </Typography>
      <Box py={1}>
        <Grid container spacing={1} alignItems={'end'}>
          <Grid item xs={12} sm={2.5}>
            <LosFormInputField label="Tranches">
              <LosTextField
                disabled
                value={getFirstScheduleAmountDisplayText()}
              />
            </LosFormInputField>
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <LosFormInputField label="Utr Number">
              <LosTextField
                value={disburse.utr}
                onChange={(e) => handleChange('utr', e.target.value)}
              ></LosTextField>
            </LosFormInputField>
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <LosFormInputField label="Disbursal Date">
              <LOSDateField
                height="40px"
                value={disburse.date ?? ''}
                maxDate={new Date().toISOString()}
                onChange={(e) => {
                  if (!e) return;
                  const dateString = e.$d;
                  if (!dateString || dateString == 'Invalid Date') return;
                  if (dateString.length <= 0) return;
                  const dateObject = new Date(dateString);
                  handleChange('date', dateObject.toISOString());
                }}
              />
            </LosFormInputField>
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <LosFormInputField label="First EMI Date">
              <LOSDateField
                height="40px"
                value={disburse.emiFirstDate}
                minDate={getMinFirstEmiDate()}
                maxDate={getMaxFirstEmiDate()}
                onChange={(e) => {
                  if (!e) return;
                  const dateString = e.$d;
                  if (dateString.length <= 0) return;
                  const dateObject = new Date(dateString);
                  handleChange('emiFirstDate', dateObject.toISOString());
                }}
                _shouldDisableDate={(date) => {
                  const dayOfMonth = date.getDate();
                  return (
                    dayOfMonth === 29 || dayOfMonth === 30 || dayOfMonth === 31
                  );
                }}
              />
            </LosFormInputField>
          </Grid>
          <Grid item xs={12} sm={1}>
            <LosButton
              text={'Disburse'}
              variant="outlined"
              onClick={() => disburseTranche()}
              disabled={isInputValid() == false}
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DisburseDetails;
