import { Divider, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import { useState, useEffect } from 'react';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { ReadOnlyFormField } from '../../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField';
import { LosService } from '../../api/LosService';
import { ReadOnlyLosFileSelect } from '../../components/common/LosFileSelect';
import LosFormInputField from '../../components/common/LosFormInputField';
import { useApplicationPreview } from '../../providers/ApplicationPreviewProvider';
import { ElectricityDetailsPreviewData } from './types';

function ElectricityDetailsPreview() {
  const { setSnackbar } = useSnackbar();
  const { fetchElectricityDetails, applicationId } = useApplicationPreview();
  const [loading, setLoading] = useState<boolean>(false);
  const [electrictyDetails, setElectrictyDetails] = useState<
    Partial<ElectricityDetailsPreviewData>
  >({});

  async function fetchDetails() {
    try {
      if (!applicationId) return;
      setLoading(true);
      const response = await fetchElectricityDetails(applicationId);
      setElectrictyDetails(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function onDownload(documentType?: string) {
    try {
      if (!applicationId || !documentType) return;
      const response = await LosService.getDocument({
        documentType: documentType,
        applicationId: applicationId,
      });
      window.open(response.url, '__blank');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <Stack
      style={{
        width: '100%',
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12} sm={4}>
          <ReadOnlyFormField
            title="Electricity Board"
            description={electrictyDetails.board}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ReadOnlyFormField
            title="Electricity Account No"
            description={electrictyDetails.accountNumber}
          />
        </Grid>
      </Grid>
      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      />
      <Grid container>
        <Grid item xs={12}>
          <LosFormInputField label="Electricity bill (last 3 months)">
            <Grid container rowSpacing={'1.25rem'} columnGap="1rem">
              <Grid item xs={12} sm={3}>
                <ReadOnlyLosFileSelect
                  fileName={electrictyDetails.bill?.lastMonth?.documentName}
                  onDownload={() => {
                    onDownload(electrictyDetails.bill?.lastMonth?.documentType);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ReadOnlyLosFileSelect
                  fileName={
                    electrictyDetails.bill?.secondLastMonth?.documentName
                  }
                  onDownload={() => {
                    onDownload(
                      electrictyDetails.bill?.secondLastMonth?.documentType
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ReadOnlyLosFileSelect
                  fileName={
                    electrictyDetails.bill?.thirdLastMonth?.documentName
                  }
                  onDownload={() => {
                    onDownload(
                      electrictyDetails.bill?.secondLastMonth?.documentType
                    );
                  }}
                />
              </Grid>
            </Grid>
          </LosFormInputField>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default ElectricityDetailsPreview;
