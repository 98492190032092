import { Box } from '@mui/material';
import { useState } from 'react';
import SidebarLayout, {
  SidebarListItemType,
} from '../components/layouts/SidebarLayout';
import { SOLFIN_ROUTES } from '../routes';
import {
  ActiveLoansIcon,
  AllApplicationIcon,
  LeadIcon,
  ManageColendersIcon,
  OffersIcon,
  PreDisbursementIcon,
} from '../solfen/Icons/DashboardIcons';
import { LmsAgentRouterProvider } from '../solfen/LMSAgent/Provider/LmsAgentRouter';
import ActiveLoans from '../solfen/LMSHQ/pages/ActiveLoans';
import Agents from '../solfen/LMSHQ/pages/Agents';
import ApplicationDashboardForHQ from '../solfen/LMSHQ/pages/ApplicationDashboardForHQ';
import Leads from '../solfen/LMSHQ/pages/Leads';
import ManageColenders from '../solfen/LMSHQ/pages/ManageColenders';
import Offers from '../solfen/LMSHQ/pages/Offers';
import PreDisbusermentDashboardHQ from '../solfen/LMSHQ/pages/PreDisbusermentDashboardHQ';

import { ActiveLoansProvider } from '../solfen/LMSHQ/providers/ActiveLoansProvider';
import { ColenderProvider } from '../solfen/LMSHQ/providers/ColendersProvider';

const Solfin_LMS_HQ_Container = () => {
  const NAV_OPTIONS: SidebarListItemType[] = [
    {
      id: SOLFIN_ROUTES.LEADS,
      icon: <LeadIcon />,
      label: 'Leads',
      component: <Leads />,
    },
    {
      id: SOLFIN_ROUTES.ALL_APPLICATION,
      icon: <AllApplicationIcon />,
      label: 'Loan Applications',
      component: (
        <LmsAgentRouterProvider>
          <ApplicationDashboardForHQ />
        </LmsAgentRouterProvider>
      ),
    },
    // {
    //   id: SOLFIN_ROUTES.OFFERS,
    //   icon: <OffersIcon />,
    //   label: 'Offers',
    //   component: <Offers />,
    // },
    {
      id: SOLFIN_ROUTES.PRE_DISBURSEMENT,
      icon: <PreDisbursementIcon />,
      label: 'Pre-disbursement',
      component: (
        <LmsAgentRouterProvider>
          <PreDisbusermentDashboardHQ />
        </LmsAgentRouterProvider>
      ),
    },
    {
      id: SOLFIN_ROUTES.ACTIVE_LOANS,
      icon: <ActiveLoansIcon />,
      label: 'Active Loans',
      component: (
        <ActiveLoansProvider>
          <ActiveLoans />
        </ActiveLoansProvider>
      ),
    },
    {
      id: SOLFIN_ROUTES.MANAGE_CO_LENDERS,
      icon: <ManageColendersIcon />,
      label: 'Manage Co-Lenders',
      component: (
        <ColenderProvider>
          <ManageColenders />
        </ColenderProvider>
      ),
    },
    {
      id: SOLFIN_ROUTES.AGENT,
      icon: <ManageColendersIcon />,
      label: 'Agents',
      component: <Agents />,
    },
  ];
  const [selectedItem, setSelectedItem] = useState<SidebarListItemType | null>(
    NAV_OPTIONS[0]
  );

  return (
    <Box sx={{ height: '100%' }}>
      <SidebarLayout
        sidebarListItems={
          //   isAdmin ? NAV_OPTIONS : [...NON_ADMIN, ...NAV_OPTIONS]
          NAV_OPTIONS
        }
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    </Box>
  );
};

export default Solfin_LMS_HQ_Container;
