import { Close } from '@mui/icons-material';
import {
  Dialog,
  Stack,
  IconButton,
  Grid,
  CircularProgress,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../../utils/api';
import { LOS_PROGRAM_ID } from '../../../../onboarding/api/constants';

import LosButton from '../../../../onboarding/components/common/LosButton';
import LosSelect, {
  LosSelectOption,
} from '../../../../onboarding/components/common/LosSelect';
import LosText from '../../../../onboarding/components/common/LosText';
import { LeadService } from '../../../api/LeadService';
import { Agent } from '../../../types/Lms';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (agent: Agent) => void;
};
function MapAgentDialog({ open, onClose, onSubmit }: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [agents, setAgents] = useState<Agent[]>([]);
  const { setSnackbar } = useSnackbar();
  const [selectedAgent, setSelectedAgent] = useState<Agent>();

  function onAgentSelect(agentId: string) {
    const filtered = agents.filter((agent) => agent.id === agentId) ?? [];
    if (filtered.length > 0) {
      setSelectedAgent(filtered[0]);
    }
  }

  async function fetchAgents() {
    try {
      setLoading(true);
      const response = await LeadService.getAllAgent({
        programId: LOS_PROGRAM_ID,
      });
      setAgents(response.records);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function onSubmitClick() {
    if (!selectedAgent) return;
    onSubmit(selectedAgent);
    onClose();
  }

  const isInputValid = () => {
    return selectedAgent != null;
  };

  useEffect(() => {
    if (open == true) {
      fetchAgents();
    }
  }, [open]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      sx={{ borderRadius: '20px' }}
      onClose={onClose}
      open={open}
    >
      <div
        style={{
          backgroundColor: 'rgba(250, 250, 250, 1)',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems={'center'}
          style={{
            paddingTop: '1.25rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <LosText text="Map Agent" />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        {loading && (
          <Stack
            style={{
              margin: '1rem',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Stack>
        )}

        {loading == false && (
          <Stack
            style={{
              margin: '1rem',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LosSelect
              fullWidth
              style={{
                width: '250px',
              }}
              placeholder="Select Agent"
              options={getAgentOptions(agents)}
              onChange={(e) => {
                onAgentSelect(e.target.value as string);
              }}
            />
          </Stack>
        )}
      </div>
      <Stack
        direction={'row'}
        justifyContent="end"
        alignItems={'center'}
        style={{ padding: '1rem' }}
      >
        <LosButton
          text="Map"
          onClick={onSubmitClick}
          disabled={isInputValid() == false}
          size="small"
        />
      </Stack>
    </Dialog>
  );
}

export default MapAgentDialog;

const getAgentOptions = (agents: Agent[]) => {
  return agents.map((item) => {
    const option: LosSelectOption = {
      value: item.id,
      label: `${_.startCase(_.toLower(item.name))}`,
    };
    return option;
  });
};
