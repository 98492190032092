import { Grid } from '@mui/material';
import _ from 'lodash';
import { LosSelectOption } from '../../../../../onboarding/components/common/LosSelect';
import {
  AccrualType,
  CycleFrequency,
  InterestType,
} from '../../../../types/Lms';
import { EditFormFiled } from './EditFormFiled';
import {
  ColenderInterestSchemeFormData,
  ColenderInresetSchemeInputType,
} from './formData';
import { ReadOnlyFormField } from './ReadOnlyFormField';

type Props = {
  isEditable: boolean;
  formData: Partial<ColenderInterestSchemeFormData>;
  handleUpdate: (type: ColenderInresetSchemeInputType, value?: string) => void;
};

function ColenderInterestSchemeForm({
  isEditable,
  formData,
  handleUpdate,
}: Props) {
  if (isEditable) {
    return <EditView formData={formData} onUpdate={handleUpdate} />;
  }
  return <ReadOnlyView formData={formData} />;
}

export default ColenderInterestSchemeForm;

type EditViewProps = {
  formData: Partial<ColenderInterestSchemeFormData>;
  onUpdate: (type: ColenderInresetSchemeInputType, value?: string) => void;
};

const EditView = ({ formData, onUpdate }: EditViewProps) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Grid container spacing={'1rem'}>
          <Grid item xs={3}>
            <EditFormFiled
              title="Interest Value"
              description={formData.interestValue}
              onChange={(e) => {
                onUpdate(ColenderInresetSchemeInputType.InterestValue, e);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="Interest type"
              description={formData.interestType}
              onChange={(e) => {
                onUpdate(ColenderInresetSchemeInputType.InterestType, e);
              }}
              options={interestTypeOptions()}
            />
          </Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="Interest accrual method"
              description={formData.accrualMethod}
              onChange={(e) => {
                onUpdate(ColenderInresetSchemeInputType.AccrualMethod, e);
              }}
              options={accrualOptions()}
            />
          </Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="Precision rate of interest"
              description={formData.presicion}
              onChange={(e) => {
                onUpdate(ColenderInresetSchemeInputType.Prescion, e);
              }}
              options={prescionOptions()}
            />
          </Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="Repayment Frequency"
              description={formData.repaymentFrequency}
              onChange={(e) => {
                onUpdate(ColenderInresetSchemeInputType.RepaymentFrequency, e);
              }}
              options={repaymentTypeOptions()}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

type ReadOnlyViewProps = {
  formData: Partial<ColenderInterestSchemeFormData>;
};

const ReadOnlyView = ({ formData }: ReadOnlyViewProps) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Grid container spacing={'1rem'}>
          <Grid item xs={3}>
            <ReadOnlyFormField
              title="Interest Value"
              description={`${formData.interestValue ?? '-'}%`}
            />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField
              title="Interest type"
              description={_.startCase(_.toLower(formData.interestType))}
            />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField
              title="Interest accrual method"
              description={_.startCase(_.toLower(formData.accrualMethod))}
            />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField
              title="Precision rate of interest"
              description={_.startCase(_.toLower(formData.presicion))}
            />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField
              title="Repayment Frequency"
              description={_.startCase(_.toLower(formData.repaymentFrequency))}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const interestTypeOptions = () =>
  Object.values(InterestType).map((item) => {
    const option: LosSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const repaymentTypeOptions = () =>
  Object.values(CycleFrequency).map((item) => {
    const option: LosSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const accrualOptions = () =>
  Object.values(AccrualType).map((item) => {
    const option: LosSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const prescionOptions = () =>
  [1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
    const option: LosSelectOption = {
      value: `${item}`,
      label: `Round up to ${item} decimals`,
    };
    return option;
  });
