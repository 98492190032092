import axios from 'axios';
import { BASE_URL } from '../../../api/endpoints';
import { PaginatedResponse } from '../../../utils/api';
import {
  ColendedApplication,
  ColendedApplicationStatus,
  ColendingLoan,
} from '../../onboarding/types/Los';
import { Colender } from '../types/Lms';
import { PaginatedApiData } from './LeadService';

const Endpoint = {
  BASE: '/colending',
  CREATE: '/onboarding',
  GET_ALL_APPLICATIONS: '/engagement/colendedApplications',
  UPDATE_COLENDING_APPLICATION_STATUS:
    '/engagement/update/status/colendingLoan/{colendingLoanId}',
  REGISTER_LOAN_FOR_ENGAGEMENT: '/engagement/register',
  GET_COLENDER_LOANS: '/engagement/register/colender/{colenderId}',
  UPDATE_COLENDING_LOAN_OFFER_STATUS:
    '/engagement/update/interest/colendingLoan/{colendingLoanId}',
  GET_COLENDED_LOAN_BY_APPLICATION_ID:
    '/engagement/colendingLoans/application/{applicationId}',
  MOVE_OFFER_TO_ONUS: '/engagement/moveToSelf',
};

const COLENDING_BASE_URL = BASE_URL + Endpoint.BASE;

export class ColenderService {
  static async getColenders(programId: string): Promise<Colender[]> {
    try {
      const endpoint = COLENDING_BASE_URL;
      const response = await axios.get(endpoint, {
        params: { programId: programId },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async createColender(
    data: Partial<CreateColenderApiData>
  ): Promise<Colender> {
    try {
      const endpoint = COLENDING_BASE_URL + Endpoint.CREATE;
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getAllColendedApplications(
    data: Partial<GetAllColendedApplicationsApiData>
  ): Promise<PaginatedResponse<ColendedApplication>> {
    try {
      const endpoint = COLENDING_BASE_URL + Endpoint.GET_ALL_APPLICATIONS;
      const response = await axios.get(endpoint, { params: data });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async approveColendingApplication(colendingLoanId: string) {
    try {
      const endpoint =
        COLENDING_BASE_URL +
        Endpoint.UPDATE_COLENDING_APPLICATION_STATUS.replace(
          '{colendingLoanId}',
          colendingLoanId
        );
      const response = await axios.put(
        endpoint,
        {},
        { params: { status: 'APPROVED' } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async moveOfferToOnus(data: Partial<MoveOfferToOnusApiData>) {
    try {
      const endpoint = COLENDING_BASE_URL + Endpoint.MOVE_OFFER_TO_ONUS;
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async rejectColendingApplication(colendingLoanId: string) {
    try {
      const endpoint =
        COLENDING_BASE_URL +
        Endpoint.UPDATE_COLENDING_APPLICATION_STATUS.replace(
          '{colendingLoanId}',
          colendingLoanId
        );
      const response = await axios.put(
        endpoint,
        {},
        { params: { status: 'REJECTED' } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async registerLoanForEngagement(
    data: Partial<RegisterLoanForEnagementApiData>
  ) {
    try {
      const endpoint =
        COLENDING_BASE_URL + Endpoint.REGISTER_LOAN_FOR_ENGAGEMENT;
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getColenderLoans(
    data: Partial<GetColenderLoansApiData>
  ): Promise<PaginatedResponse<ColendingLoan>> {
    try {
      if (!data.colenderId) {
        throw new Error('Colender Id is required');
      }
      const endpoint =
        COLENDING_BASE_URL +
        Endpoint.GET_COLENDER_LOANS.replace('{colenderId}', data.colenderId);
      delete data.colenderId;
      const response = await axios.get(endpoint, { params: data });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateColendingLoanOfferStatus(data: {
    colendingLoanId: string;
    status: string;
  }) {
    try {
      const endpoint =
        COLENDING_BASE_URL +
        Endpoint.UPDATE_COLENDING_LOAN_OFFER_STATUS.replace(
          '{colendingLoanId}',
          data.colendingLoanId
        );
      const response = await axios.put(endpoint, null, {
        params: { status: data.status },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getColendedLoansForApplicationId(
    applicationId: string
  ): Promise<ColendingLoan[]> {
    try {
      const endpoint =
        COLENDING_BASE_URL +
        Endpoint.GET_COLENDED_LOAN_BY_APPLICATION_ID.replace(
          '{applicationId}',
          applicationId
        );
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export interface GetColenderLoansApiData extends PaginatedApiData {
  colenderId: string;
  status: string;
  programId: string;
}

export type GetAllColendedApplicationsApiData = {
  status: string;
  page: number;
  pageSize: number;
  sortBy: string;
  sortDir: string;
  programId: string;
};

export type RegisterLoanForEnagementApiData = {
  applicationId: string;
  status: string;
  officerId: string;
  requestedLoanAmount: number;
  colenderId: string;
  lendingType: string;
  colendingRatio: number;
  approvedLoanAmount: number;
  primaryRoi: number;
  programId: string;
};

export type CreateColenderApiData = {
  programId: string;
  name: string;
  status: string;
  bankName: string;
  accountHolderName: string;
  accountNumber: string;
  ifscCode: string;
  branch: string;
  interest: number;
  registrationDate: Date;
  panNo: string;
  cin: string;
  tin: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  isPrimaryLender: boolean;
};

export type MoveOfferToOnusApiData = {
  applicationId: string;
  status: string;
  requestedLoanAmount: number;
  lendingType: string;
  approvedLoanAmount: number;
  primaryRoi: number;
  programId: string;
};
