export type ColenderDetailsFormData = {
  bankDetails: Partial<ColenderBankDetailsFormData>;
  eligiblityDetails: Partial<ColenderEligiblityFormData>;
  interestSchemeDetails: Partial<ColenderInterestSchemeFormData>;
  incomeSchemeDetails: Partial<ColenderIncomeSchemeFormData>;
};

export type ColenderBankDetailsFormData = {
  colenderName: string;
  dateOfRegistration: string;
  pan: string;
  cin: string;
  tin: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
};

export enum ColenderBankDetailsInputType {
  ColenderName = 'colenderName',
  DateOfRegistration = 'dateOfRegistration',
  PAN = 'pan',
  CIN = 'cin',
  TIN = 'tin',
  Address = 'address',
  City = 'city',
  State = 'state',
  Pincode = 'pincode',
}

export enum ColenderIncomeSchemeInputType {
  LateFee = 'lateFee',
  PenalInterest = 'penalInterest',
}

export type ColenderIncomeSchemeFormData = {
  lateFee: string;
  penalInterest: string;
};

export type ColenderInterestSchemeFormData = {
  interestValue: string;
  interestType: string;
  accrualMethod: string;
  presicion: string;
  repaymentFrequency: string;
};

export enum ColenderInresetSchemeInputType {
  InterestValue = 'interestValue',
  InterestType = 'interestType',
  AccrualMethod = 'accrualMethod',
  Prescion = 'presicion',
  RepaymentFrequency = 'repaymentFrequency',
}

export type ColenderEligiblityFormData = {
  minLoanAmount: string;
  maxLoanAmount: string;
  minRateOfInterest: string;
  ageCriteria: string;
  bureauCriteria: string;
};

export enum ColenderEligiblitySchemeInputType {
  MinLoanAmount = 'minLoanAmount',
  MaxLoanAmount = 'maxLoanAmount',
  minRateOfInterest = 'minRateOfInterest',
  AgeCriteria = 'ageCriteria',
  BureauCriteria = 'bureauCriteria',
}
