import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import {
  DataGrid,
  GridCallbackDetails,
  GridColumns,
  GridFeatureMode,
  gridPageCountSelector,
  gridPageSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import LosText from '../onboarding/components/common/LosText';
import './../../components/common/v2/Styles/__styles.css';

type Props = {
  data: readonly any[];
  columns: GridColumns<any>;
  page?: number;
  pageSize?: number;
  rowCount?: number;
  onPageChange?: (page: number, details: GridCallbackDetails) => void;
  onPageSizeChange?: (pageSize: number, details: GridCallbackDetails) => void;
  loading?: boolean;
  idColumn: string;
  paginationMode?: GridFeatureMode;
};

function LosDataGrid({
  data,
  columns,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  loading,
  rowCount,
  paginationMode,
  idColumn,
}: Props) {
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '4px',
          flex: 1,
        }}
      >
        <DataGrid
          autoHeight
          autoPageSize
          pageSize={pageSize}
          sx={{
            overflowX: 'scroll',
            border: '1px solid #E1E4EB',
            borderRadius: '0.75rem',
            '& ::-webkit-scrollbar': {
              width: '2px !important',
              height: '2px !important',
            },
            '& ::-webkit-scrollbar-track': {
              backgroundColor: 'transparent !important',
            },
            '& .MuiDataGrid-cell--withRenderer': {
              color: '#181F28',
              lineHeight: '130%',
              fontSize: '0.75rem',
              fontFamily: 'var(--font-Aspekta-400) !important',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderTop: 'none !important',
              borderBottom: '1px solid #E1E4EB',
              backgroundColor: '#FAFAFA',
              maxHeight: '2.75rem !important',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontFamily: 'var(--font-Aspekta-700) !important',
              fontSize: '0.625rem',
              textTransform: 'uppercase',
              letterSpacing: '0.04375rem',
              lineHeight: '124%',
              color: '#676B76',
            },
            '& .MuiDataGrid-cellContent': {
              fontFamily: 'var(--font-Aspekta-400) !important',
              fontSize: '0.75rem',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-iconSeparator': {
              display: 'none !important',
            },
            '& .MuiDataGrid-sortIcon': {
              display: 'none !important',
            },
            '& .MuiDataGrid-footerContainer': {
              border: 'none !important',
              backgroundColor: '#FAFAFA',
              margin: '0px !important',
              padding: '0px !important',
            },
          }}
          components={{
            Pagination: CustomPagination,
          }}
          showCellRightBorder={false}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          rowHeight={56}
          classes={{ withBorder: 'no-border' }}
          rows={data}
          columns={columns}
          disableSelectionOnClick
          rowSpacingType="margin"
          onRowClick={(params) => {}}
          page={page}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          loading={loading}
          rowCount={rowCount}
          paginationMode={paginationMode}
          getRowId={(row: any) => {
            return row[idColumn];
          }}
        />
      </Box>
    </div>
  );
}

export default LosDataGrid;

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);

  const rowsPerPage = apiRef.current?.state.pagination.pageSize || 0;
  const startIndex = page * rowsPerPage + 1;
  const endIndex = Math.min((page + 1) * rowsPerPage, rowCount);

  const canGoBack = () => {
    if (page <= 0) return false;
    return true;
  };
  const canGoForward = () => {
    if (page + 1 >= pageCount) return false;
    return true;
  };

  const getPageCountDisplayText = () => {
    if (pageCount == 0) return 1;
    return pageCount;
  };

  return (
    <Stack direction={'row'} justifyContent="space-between" width={'100%'}>
      <Box
        sx={{
          px: 2,
          py: 1,
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <LosText
          text={`Showing data ${startIndex} to ${endIndex} of ${rowCount} entries`}
          variant="caption"
        />
      </Box>

      <Stack
        direction={'row'}
        alignItems="center"
        spacing={'0.2rem'}
        marginRight="1rem"
      >
        <IconButton
          disabled={canGoBack() == false}
          onClick={() => {
            apiRef.current.setPage(page - 1);
          }}
        >
          <ArrowBackIos sx={{ fontSize: 12 }} />
        </IconButton>

        <LosText
          style={{
            color: 'rgba(103, 107, 118, 1)',
            fontSize: '0.625rem',
            fontWeight: 600,
            lineHeight: '120%',
          }}
        >
          Page {page + 1} of {getPageCountDisplayText()}
        </LosText>
        <IconButton
          disabled={canGoForward() == false}
          onClick={() => {
            apiRef.current.setPage(page + 1);
          }}
        >
          <ArrowForwardIos sx={{ fontSize: 12 }} />
        </IconButton>
      </Stack>
    </Stack>
  );
}
