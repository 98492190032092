import { isDev } from '../../../constants/url';

type UserCred = {
  email: string;
  password: string;
};

const getProgramId = () => {
  return isDev
    ? process.env.REACT_APP_DEV_PROGRAM_ID
    : process.env.REACT_APP_PROD_PROGRAM_ID;
};

const getCliendId = () => {
  return isDev
    ? process.env.REACT_APP_DEV_CLIENT_ID
    : process.env.REACT_APP_PROD_CLIENT_ID;
};

const getColenderId = () => {
  return isDev
    ? process.env.REACT_APP_DEV_COLENDER_ID
    : process.env.REACT_APP_PROD_COLENDER_ID;
};

const getCreds = () => {
  const creds: UserCred = isDev
    ? {
        email: process.env.REACT_APP_ALPHA_EMAIL ?? '',
        password: process.env.REACT_APP_ALPHA_PASS ?? '',
      }
    : {
        email: process.env.REACT_APP_PROD_EMAIL ?? '',
        password: process.env.REACT_APP_PROD_PASS ?? '',
      };

  return creds;
};

export const LOS_PROGRAM_ID: string = getProgramId() ?? '';
export const LOS_CLIENT_ID: string = getCliendId() ?? '';

export const LOS_COLENDER_ID: string = getColenderId() ?? '';

export const ONBOARDING_CREDS = getCreds();
