import { getFirestore } from 'firebase/firestore';

import { collection, getDocs } from 'firebase/firestore';
import { Firestore } from 'firebase/firestore';
import { isDev } from '../../constants/url';
import { firebaseApp } from './firebaseConfig';

const FirestoreKey = {
  ALHA: {
    PRIVATE_KEY: 'alpha_private_key',
  },
  BETA: {
    PRIVATE_KEY: 'beta_private_key',
  },
};

type VegaAppConfig = {
  privateKey: string;
};

class FirestoreConfigFetcher {
  private firestore: Firestore;
  private collectionPath: string;

  constructor() {
    this.firestore = getFirestore(firebaseApp);
    this.collectionPath = 'app_config';
  }

  async fetchAppConfig(): Promise<VegaAppConfig> {
    const querySnapshot = await getDocs(
      collection(this.firestore, this.collectionPath)
    );

    if (querySnapshot.empty) {
      throw new Error('Failed to get Query Snapshot');
    }

    const losDemoCollection = querySnapshot.docs[0];
    const appConfig: VegaAppConfig = {
      privateKey: '',
    };
    if (isDev) {
      appConfig.privateKey = losDemoCollection.get(
        FirestoreKey.ALHA.PRIVATE_KEY
      );
    } else {
      appConfig.privateKey = losDemoCollection.get(
        FirestoreKey.BETA.PRIVATE_KEY
      );
    }

    return appConfig;
  }
}

export default FirestoreConfigFetcher;
