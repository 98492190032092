import { Box } from '@mui/material';
import { useState } from 'react';
import VegaTab from '../../../../components/common/VegaTab';
import { BLUE } from '../../../../constants/style';
import ColendingApplicationDetailsTab from './Tabs/ColendingApplicationDetailsTab';
import ColendingLoanDetailsTab from './Tabs/ColendingLoanDetails';
import ColendingLoanEmiScheduleTab from './Tabs/ColendingLoanEmiScheduleTab';

enum TabType {
  Application = 'Application Details',
  Loan = 'Loan Details',
  Emi = 'Emi Schedule',
}

function ColendingLoanDetailsContainer() {
  const [selectedTab, setSelectedTab] = useState<string>(TabType.Application);

  return (
    <>
      <Box px={1} sx={{ overflow: 'auto' }}>
        <VegaTab
          placement="vertical"
          color={BLUE.lighter}
          tabData={[TabType.Application, TabType.Loan, TabType.Emi]}
          selected={selectedTab}
          onSelect={(select) => setSelectedTab(select)}
        />
      </Box>
      {selectedTab == TabType.Application && <ColendingApplicationDetailsTab />}
      {selectedTab == TabType.Loan && <ColendingLoanDetailsTab />}
      {selectedTab == TabType.Emi && <ColendingLoanEmiScheduleTab />}
    </>
  );
}

export default ColendingLoanDetailsContainer;
