import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyC7NyQcrvv0BMFiewlO_aC0OigT-0vwQh8',
  authDomain: 'vegapay-281b1.firebaseapp.com',
  projectId: 'vegapay-281b1',
  storageBucket: 'vegapay-281b1.appspot.com',
  messagingSenderId: '184898473143',
  appId: '1:184898473143:web:506bf0eb0873c4a9425132',
  measurementId: 'G-BWMJMW6VQ5',
};

export const firebaseApp = initializeApp(firebaseConfig);
