import React, { useState } from 'react';
import {
  Box,
  Typography,
  CardMedia,
  useTheme,
  useMediaQuery,
  Skeleton,
} from '@mui/material';
import { BLUE, GREY, PRIMARY } from '../../../../../../constants/style';
import { IFilesProps } from './ElectricityDetails';
import {
  DownloadFIleIcon,
  UploadFileIcon,
} from '../../../../../../components/icon/Icons';
import { useAppSelector } from '../../../../../../store/hook';
import { getApplicationsDetails } from '../../../../../../store/common/agentApplicationsSlice';
import download from 'downloadjs';
import { fetchDocumentURL } from '../../../../../APIs/LMS_Agent/applications';
import { LosDocumentType } from '../../../../../onboarding/types/Los';

interface IProps {
  file: IFilesProps;
  isEdit: boolean;
  hideDownload?: boolean;
  handleImage: (
    name: string,
    value: File,
    index: number,
    documentType: LosDocumentType
  ) => void;
  index: number;
}

const Media = ({
  file,
  isEdit,
  handleImage,
  index,
  hideDownload = false,
}: IProps) => {
  const { isApplicationForHQ, applicationDetails } = useAppSelector(
    getApplicationsDetails
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState<boolean>(false);

  const downloadURL = async (documentDetails: IFilesProps) => {
    console.log('documentDetails', documentDetails);
    setLoading(true);
    fetchDocumentURL(applicationDetails.id, documentDetails.documentType)
      .then((res) => {
        const url = res.data.url;
        if (url) {
          download(url);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoading(false);
      });
  };

  const imageContainer = (name: string, index: number) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className="">
          <UploadFileIcon />
        </div>
        <label
          htmlFor={name}
          className="font-aspekta-500"
          style={{
            borderRadius: '5px',
            color: BLUE.lighter,

            fontSize: 12,
            margin: '0px 2px',
            padding: '5px 5px',
            cursor: 'pointer',
          }}
        >
          Upload File
        </label>
        <input
          id={name}
          type="file"
          accept="application/pdf"
          style={{ display: 'none' }}
          onChange={(e) =>
            e.target.files?.[0] &&
            handleImage(
              name,
              e.target.files?.[0],
              index,
              file.documentType as LosDocumentType
            )
          }
        />
      </Box>
    );
  };

  const noImageContainer = () => {
    return (
      <label
        className="font-aspekta-500"
        style={{
          borderRadius: '5px',
          color: BLUE.lighter,

          fontSize: 12,
          margin: '0px 2px',
          padding: '5px 5px',
          cursor: 'pointer',
        }}
      >
        No Document
      </label>
    );
  };
  return loading ? (
    <Skeleton
      variant="rectangular"
      width={!isMobile ? 210 : ''}
      height={!isMobile ? 118 : ''}
    />
  ) : isMobile ? (
    <Box
      sx={{
        border: `1px dashed ${GREY.dark}`,
        borderRadius: '10px',
        py: 1,
        px: 1.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {!isEdit && file?.id === '' ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!isApplicationForHQ &&
          (applicationDetails.status === 'IN_PROGRESS' ||
            applicationDetails.status === 'PENDING')
            ? imageContainer(file?.documentType, index)
            : noImageContainer()}
        </Box>
      ) : file?.id === '' ? (
        imageContainer(file?.documentType, index)
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '16px', height: '16px', mr: 2 }}>
              <img
                src={require('../../../../../assets/pdf_thumbnail.png')}
                alt=""
                width={'100%'}
                height={'100%'}
                style={{ borderRadius: '4px' }}
              />
            </Box>
            <Typography
              className="font-aspekta-450"
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              whiteSpace={'nowrap'}
              fontSize={13}
              width={'100px'}
              textAlign="center"
            >
              {file?.documentName != null ? file?.documentName : '--'}
            </Typography>
          </Box>
          <Box>
            {isEdit && (
              <>
                <label
                  htmlFor={file?.documentType}
                  style={{
                    color: BLUE.lighter,
                    fontSize: 12,
                    margin: '0px 2px',
                    cursor: 'pointer',
                  }}
                >
                  Reupload
                </label>
                <input
                  id={file?.documentType}
                  type="file"
                  accept="application/pdf"
                  style={{ display: 'none' }}
                  onChange={(e) =>
                    e.target.files?.[0] &&
                    handleImage(
                      file?.documentType,
                      e.target.files?.[0],
                      index,
                      file.documentType as LosDocumentType
                    )
                  }
                />
              </>
            )}
            {file?.id && !isEdit && (
              <label
                onClick={() => downloadURL(file)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <DownloadFIleIcon />
              </label>
            )}
          </Box>
        </>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        border: `1px dashed ${GREY.dark}`,
        borderRadius: '10px',
        p: 2,
        position: 'relative',
      }}
    >
      {file?.id === '' ? (
        <Box
          className="media"
          sx={{
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          {' '}
          {isEdit ? (
            imageContainer(file?.documentType, index)
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {noImageContainer()}
            </Box>
          )}
        </Box>
      ) : (
        <>
          <Box className="media" sx={{ position: 'relative' }}>
            <CardMedia
              style={{
                borderRadius: '5px',
                width: '100%',
                height: '80px',
                objectFit: 'contain',
              }}
              component="img"
              image={require('../../../../../assets/pdf_thumbnail.png')}
            />

            <Box className="overlay">
              {isEdit && (
                <>
                  <label
                    htmlFor={file?.documentType}
                    style={{
                      borderRadius: '5px',
                      backgroundColor: PRIMARY.blue,
                      fontSize: 12,
                      margin: '0px 2px',
                      padding: '5px 5px',
                      cursor: 'pointer',
                    }}
                  >
                    Upload
                  </label>
                  <input
                    id={file?.documentType}
                    type="file"
                    accept="application/pdf"
                    style={{ display: 'none' }}
                    onChange={(e) =>
                      e.target.files?.[0] &&
                      handleImage(
                        file?.documentType,
                        e.target.files?.[0],
                        index,
                        file.documentType as LosDocumentType
                      )
                    }
                  />
                </>
              )}
              {!isEdit && file?.id !== '' && !hideDownload && (
                <label
                  onClick={() => downloadURL(file)}
                  style={{
                    borderRadius: '5px',
                    backgroundColor: PRIMARY.blue,
                    fontSize: 12,
                    margin: '0px 2px',
                    padding: '5px 5px',
                    cursor: 'pointer',
                  }}
                >
                  Download
                </label>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              className="font-aspekta-450"
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              whiteSpace={'nowrap'}
              fontSize={13}
              width={'100px'}
              textAlign="center"
            >
              {file?.documentName ? file?.documentName : '--'}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Media;
