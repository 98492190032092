import { Address } from '../../LMSHQ/types/Lms'

export enum PanEntityType {
  Company = 'Company',
  Person = 'Person',
  HUF = 'HUF',
  Firm = 'Firm',
  AOP = 'AOP',
  BOI = 'BOI',
  Government = 'Government',
  LocalAuthority = 'Local Authority',
  ArtificialJuridicalPerson = 'Artificial Juridical Person',
}

type AadharAddress = {
  country: string
  dist: string
  state: string
  po: string
  loc: string
  vtc: string
  subdist: string
  street: string
  house: string
  landmark: string
}

export class StringUtility {
  static formatAadhaarAddressToReadable(value?: string) {
    if (!value) return '-'
    const dataObject: AadharAddress = JSON.parse(value)
    var displayText = ''

    displayText += dataObject.house
    displayText += ' ' + dataObject.street
    displayText += ' ' + dataObject.subdist
    displayText += ' ' + dataObject.dist
    displayText += ' ' + dataObject.state
    displayText += ' ' + dataObject.country

    if (displayText.length <= 0) return '-'
    return displayText.toUpperCase()
  }

  static formatAddressToReadable(value?: Partial<Address>) {
    if (!value) return '-'

    var displayText = ''

    if (value.line1) displayText += value.line1
    if (value.line2) displayText += ' ' + value.line2
    if (value.city) displayText += ' ' + value.city
    if (value.state) displayText += ' ' + value.state
    if (value.pincode) displayText += ' ' + value.pincode
    if (value.country) displayText += ' ' + value.country

    if (displayText.length <= 0) return '-'
    return displayText.toUpperCase()
  }

  static formatCurrency(value?: number | string) {
    if (value == 0) return '0'
    if (!value) return '-'
    const numberValue = typeof value === 'string' ? Number(value) : value
    if (isNaN(numberValue)) {
      return 'Invalid Number'
    }
    const absoluteValue = Math.abs(numberValue)
    const formattedValue = absoluteValue.toLocaleString('en-IN')
    return numberValue < 0 ? `-${formattedValue}` : formattedValue
  }

  static validatePANNumber(
    entityType: PanEntityType,
    panNumber: string
  ): boolean {
    const panRegexMap: { [key: string]: RegExp } = {
      Company: /^[A-Z]{3}C[A-Z]\d{4}[A-Z]$/,
      Person: /^[A-Z]{3}P[A-Z]\d{4}[A-Z]$/,
      HUF: /^[A-Z]{3}H[A-Z]\d{4}[A-Z]$/,
      Firm: /^[A-Z]{3}F[A-Z]\d{4}[A-Z]$/,
      AOP: /^[A-Z]{3}A[A-Z]\d{4}[A-Z]$/,
      Trust: /^[A-Z]{3}T[A-Z]\d{4}[A-Z]$/,
      BOI: /^[A-Z]{3}B[A-Z]\d{4}[A-Z]$/,
      'Local Authority': /^[A-Z]{3}L[A-Z]\d{4}[A-Z]$/,
      'Artificial Juridical Person': /^[A-Z]{3}J[A-Z]\d{4}[A-Z]$/,
      Government: /^[A-Z]{3}G[A-Z]\d{4}[A-Z]$/,
    }

    const regexPattern = panRegexMap[entityType]
    if (!regexPattern) {
      console.error('Invalid entity type')
      return false
    }

    return regexPattern.test(panNumber)
  }

  static validateOTP(value: string): boolean {
    const digitsOnly = value.replace(/\D/g, '')
    return /^\d{6}$/.test(digitsOnly)
  }

  static validateAadharNumber(aadhaarNumber: string): boolean {
    const aadhaarNumberRegex = /^\d{12}$/
    return aadhaarNumberRegex.test(aadhaarNumber)
  }

  static validateEmail(email: string) {
    const emailRegex = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,63}){1,3})$/
    return emailRegex.test(email)
  }

  static validateGSTNumber(value: string) {
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    return gstRegex.test(value)
  }

  static validatePhoneNumber(number: string) {
    const phoneRegex = /^\d{10}$/
    return phoneRegex.test(number)
  }

  /**
   * Validates a Company Identification Number (CIN).
   *
   * @param {string} value - The CIN to validate.
   * @returns {boolean} - `true` if the CIN is valid, `false` otherwise.
   *
   * @description
   * The CIN (Company Identification Number) is a 21-character alphanumeric code that represents and provides information about a company. The CIN is broken down into six sections:
   *
   * - Section-1: The first character, which must be an uppercase letter (A-Z).
   * - Section-2: The next five characters, which must be digits (0-9).
   * - Section-3: The next two characters, which must be uppercase letters (A-Z).
   * - Section-4: The next four characters, which must be digits (0-9).
   * - Section-5: The next three characters, which must be uppercase letters (A-Z).
   * - Section-6: The last six characters, which must be digits (0-9).
   *
   * This function uses a regular expression to check if the provided value matches the valid CIN format. It returns `true` if the CIN is valid and `false` otherwise.
   *
   * @example
   * const cin1 = "L01631KA2010PTC096843";
   * const cin2 = "InvalidCIN123456789012345678";
   *
   * console.log(validateCINumber(cin1)); // true
   * console.log(validateCINumber(cin2)); // false
   */
  static validateCINumber(value: string) {
    const cinRegex = /^[A-Z]\d{5}[A-Z]{2}\d{4}[A-Z]{3}\d{6}$/
    return cinRegex.test(value)
  }
}
