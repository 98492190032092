import { CustomerService } from '../api/CustomerService';
import { VegaCustomer } from '../types/Los';

export const useCustomerApi = () => {
  async function getCustomer(id: string): Promise<VegaCustomer> {
    try {
      const response = await CustomerService.getCustomer(id);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function createCustomer(data: {
    clientId: string;
    mobileNumber: string;
  }) {
    try {
      const response = await CustomerService.createCustomerInSteps({
        clientId: data.clientId,
        mobileNumber: data.mobileNumber,
        customerType: 'INDIVIDUAL',
      });
      const customerId = response.customerId;
      if (customerId) {
        if (customerId.length > 0) {
          return await getCustomer(customerId);
        }
      }
      throw new Error(`Customer Id ${customerId}  is invalid`);
    } catch (error) {
      throw error;
    }
  }

  async function createCustomerIfDoesNotExists(data: {
    mobileNumber: string;
    clientId: string;
  }): Promise<VegaCustomer> {
    try {
      const vectorCheckResponse = await CustomerService.vectorCheckDedupe({
        phone: data.mobileNumber,
        clientId: data.clientId,
      });
      const customerExists = vectorCheckResponse.exists;
      const existingCustomer = vectorCheckResponse.existingCustomer;
      if (customerExists && existingCustomer) {
        return existingCustomer;
      } else {
        const customerResponse = await createCustomer({
          mobileNumber: data.mobileNumber,
          clientId: data.clientId,
        });
        return customerResponse;
      }
    } catch (error) {
      throw error;
    }
  }

  return {
    createCustomer,
    createCustomerIfDoesNotExists,
  };
};
