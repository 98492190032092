import axios from 'axios'
import { BASE_URL } from '../../../api/endpoints'
import { ColenderFeePolicy, ColendingPolicy } from '../types/Lms'

const Endpoint = {
  BASE: '/program/v1/program/policy',
  GET_POLICY: '/{programId}/policy/{policyType}',
  SAVE_POLICY: '/{programId}/policy',
  GET_EFFECTIVE: '/effective',
}
const POLICY_BASE_URL = BASE_URL + Endpoint.BASE
export class PolicyService {
  static async getColendingPolicy(data: {
    programId: string
  }): Promise<ColendingPolicy> {
    try {
      const endpoint = POLICY_BASE_URL + Endpoint.GET_EFFECTIVE
      const response = await axios.get(endpoint, {
        params: {
          entityId: data.programId,
          entityType: 'PROGRAM',
          policyType: 'COLENDING',
        },
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async getColendingFeePolicy(data: {
    programId: string
  }): Promise<ColenderFeePolicy> {
    try {
      const endpoint =
        POLICY_BASE_URL +
        Endpoint.GET_POLICY.replace('{programId}', data.programId).replace(
          '{policyType}',
          'COLENDER_FEE'
        )
      const response = await axios.get(endpoint)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async saveColendingPolicy(data: {
    programId: string
    policy: ColendingPolicy
  }): Promise<ColendingPolicy> {
    try {
      const endpoint =
        POLICY_BASE_URL +
        Endpoint.SAVE_POLICY.replace('{programId}', data.programId)
      const response = await axios.post(endpoint, data.policy)
      return response.data
    } catch (error) {
      throw error
    }
  }

  static async saveColendingFeePolicy(data: {
    programId: string
    policy: ColenderFeePolicy
  }): Promise<ColendingPolicy> {
    try {
      const endpoint =
        POLICY_BASE_URL +
        Endpoint.SAVE_POLICY.replace('{programId}', data.programId)
      const response = await axios.post(endpoint, data.policy)
      return response.data
    } catch (error) {
      throw error
    }
  }
}
