import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '..';

export interface IProps {
  header: string;
  iterationValue: string;
  isNavigate: boolean;
  error: any;
}
const initialState: IProps = {
  header: '',
  iterationValue: 'MONTHLY',
  isNavigate: false,
  error: {},
};

const stateSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeader: (state: IProps, action) => {
      const { payload } = action;
      state.header = payload;
    },
    setIsNavigate: (state: IProps, action) => {
      const { payload } = action;
      state.isNavigate = payload;
    },

    setError: (state: IProps, action) => {
      const { payload } = action;
      state.error = payload;
    },
    setIterationValue: (state: IProps, action) => {
      const { payload } = action;
      state.iterationValue = payload;
    },
  },
});

export const { setHeader, setIsNavigate, setError, setIterationValue } =
  stateSlice.actions;
export const getState = (state: AppState) => state.header;
export default stateSlice;
