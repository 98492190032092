import { Box, Typography, Pagination, IconButton } from '@mui/material';
import React, { ReactNode } from 'react';
import { BORDER_GRAY, COMMON, GREY } from '../../constants/style';
import VegaLoader from '../../components/common/VegaLoader';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

interface IProps {
  theadComponent: () => void;
  rowComponent: () => void;
  privious: (page: number) => void;
  next: (page: number) => void;
  loading: boolean;
  page: number;
  count: number;
  onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  data: any[];
}

const LOSTable = ({
  theadComponent,
  rowComponent,
  loading,
  data,
  page,
  count,
  onPageChange,
  next,
  privious,
}: IProps) => {
  return (
    <Box sx={{ mx: 2, border: `1px solid #e1e4eb`, borderRadius: '20px' }}>
      <Box
        component={'div'}
        sx={{
          overflowX: 'auto',

          borderRadius: '20px 20px 0 0',
        }}
      >
        <table
          className="LOStable"
          style={{
            borderCollapse: 'collapse',
            width: '100%',
          }}
        >
          <thead style={{ background: GREY.light, borderBottom: BORDER_GRAY }}>
            <tr
              style={{
                borderBottom: `1px solid lightgray, `,
              }}
            >
              <>{theadComponent()}</>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={12} style={{ background: 'white' }}>
                  <VegaLoader height="500px" />
                </td>
              </tr>
            ) : data.length ? (
              <>{rowComponent()}</>
            ) : (
              <tr>
                <td colSpan={12}>
                  <Box
                    sx={{
                      height: '500px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'white',
                    }}
                  >
                    <Typography
                      className="font-aspekta-450"
                      color={COMMON.darkGray}
                    >
                      No Result
                    </Typography>
                  </Box>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
      <Box
        sx={{
          px: 2,
          py: 1,
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          bgcolor: GREY.light,
          borderRadius: '0px 0px 20px 20px',
        }}
      >
        <IconButton disabled={page === 1} onClick={() => privious(page)}>
          <ArrowBackIos sx={{ fontSize: 12 }} />
        </IconButton>
        <Typography
          className="font-aspekta-600"
          color={COMMON.light}
          fontSize={10}
        >
          Page {page} of {count}
        </Typography>
        <IconButton disabled={page === count} onClick={() => next(page)}>
          <ArrowForwardIos sx={{ fontSize: 12 }} />
        </IconButton>
        {/* <Pagination
          color="primary"
          page={page}
          count={count}
          onChange={onPageChange}
        /> */}
      </Box>
    </Box>
  );
};

export default LOSTable;
