import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import { GREY } from '../../../constants/style';
import { IAgentLeadsListProps } from '../../types/lead';
import { Lead } from '../../onboarding/types/Los';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import LosStatusPill from '../../components/LosStatusPill';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { LOS_PROGRAM_ID } from '../../onboarding/api/constants';
import { LeadService } from '../../LMSHQ/api/LeadService';
import LosDataGrid from '../../components/LosDataGrid';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import SolfinAppBar from '../../components/SolfinAppBar';
import LeadsList from '../components/MobileView/LeadsList';
import MobileViewPagination from '../components/MobileViewPagination';
import { useClientAuth } from '../../../providers/ClientProvider';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
export interface IPaginationStateProps {
  loading: boolean;
  page: number;
  totalPages: number;
  applicationData: IAgentLeadsListProps[];
}

const LeadsForAgent = () => {
  const theme = useTheme();
  const { agentId } = useClientAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [leads, setLeads] = useState<Lead[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);

  const { setSnackbar } = useSnackbar();

  const COLUMN_DEF: GridColumns<Lead> = [
    {
      field: 'id',
      headerName: 'Lead Id',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Lead;
        return row.id;
      },
      flex: 1,
    },
    {
      field: 'fullName',
      headerName: 'Customer Name',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Lead;
        var displayText = '-';
        if (row.fullName) {
          displayText = row.fullName;
        }
        return displayText;
      },
      flex: 1.5,
    },
    {
      field: 'phone',
      headerName: 'Mobile Number',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as Lead;
        var displayText = '-';
        if (application.phone) {
          displayText = application.phone;
        }
        return displayText;
      },
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as Lead;
        var displayText = '-';
        if (application.createdAt) {
          displayText = dayjs(application.createdAt).format('DD/MM/YYYY');
        }
        return displayText;
      },
      flex: 0.75,
    },
    {
      field: 'preEligibility',
      headerName: 'Pre Eligiblity',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Lead;
        return '-';
      },
      flex: 1,
    },

    {
      field: 'status',
      headerName: 'Status',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Lead;
        return <LosStatusPill status={row.status} />;
      },
      flex: 1,
    },
  ];

  async function getAllLeads() {
    try {
      if (!agentId) return;
      setLoading(true);
      const response = await LeadService.getAllLeads({
        programId: LOS_PROGRAM_ID,
        page: page,
        pageSize: pageSize,
        sortBy: 'createdAt',
        sortDir: 'desc',
        agentId: agentId,
      });
      setLeads(response.records);
      setPage(response.pageNumber);
      setRowCount(response.totalItems);
      setTotalPage(response.totalPages);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const privious = (page: number) => {
    setPage(page - 1);
  };
  const next = (page: number) => {
    setPage(page + 1);
  };

  useEffect(() => {
    getAllLeads();
  }, [page, pageSize]);

  return (
    <>
      <SolfinAppBar title={'All Leads'} />
      <Box m={2}>
        {isMobile ? (
          leads.length ? (
            <>
              <LeadsList leads={leads} loading={loading} />
              <MobileViewPagination
                page={page}
                totalPage={totalPage}
                privious={privious}
                next={next}
              />
            </>
          ) : (
            ''
          )
        ) : (
          <LosDataGrid
            data={leads}
            columns={COLUMN_DEF}
            idColumn={'id'}
            page={page}
            pageSize={pageSize}
            onPageChange={setPage}
            loading={loading}
            rowCount={rowCount}
          />
        )}
      </Box>
    </>
  );
};

export default LeadsForAgent;
