import { Edit } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import LosButton from '../../../../../onboarding/components/common/LosButton';
import { useColenders } from '../../../../providers/ColendersProvider';
import ColenderIncomeSchemeForm from '../Forms/ColenderIncomeSchemeForm';
import { ColenderIncomeSchemeFormData } from '../Forms/formData';

enum ViewState {
  ReadOnly,
  Edit,
}

function ColenderIncomeSchemeTab() {
  const {
    fetchColenderIncomeScheme,
    saveColenderIncomeScheme,
    selectedColender,
  } = useColenders();
  const [viewState, setViewState] = useState<ViewState>(ViewState.ReadOnly);
  const [formData, setFormData] = useState<
    Partial<ColenderIncomeSchemeFormData>
  >({});

  function updateViewState(viewState: ViewState) {
    setViewState(viewState);
  }

  function handleUpdate(type: string, value?: string) {
    setFormData((prev) => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  async function fetchDetails() {
    setFormData({});
    const colenderId = selectedColender?.id;
    if (!colenderId) {
      return;
    }
    const response = await fetchColenderIncomeScheme(colenderId);
    setFormData(response);
  }

  async function onSubmit() {
    const colenderId = selectedColender?.id;
    if (!colenderId) {
      return;
    }
    await saveColenderIncomeScheme({
      colenderId: colenderId,
      formData: formData,
    });
    updateViewState(ViewState.ReadOnly);
  }

  useEffect(() => {
    fetchDetails();
    updateViewState(ViewState.ReadOnly);
  }, [selectedColender]);

  return (
    <div
      style={{
        padding: '12px',
      }}
    >
      <ColenderIncomeSchemeForm
        formData={formData}
        handleUpdate={handleUpdate}
        isEditable={viewState == ViewState.Edit}
      />
      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      />
      <Stack
        direction={'row'}
        justifyContent="end"
        alignItems={'center'}
        spacing={'1rem'}
        display={viewState == ViewState.ReadOnly ? 'flex' : 'none'}
      >
        <LosButton
          startIcon={<Edit />}
          text="Edit"
          variant="outlined"
          size="small"
          onClick={() => {
            updateViewState(ViewState.Edit);
          }}
        />
      </Stack>
      <Stack
        direction={'row'}
        justifyContent="end"
        alignItems={'center'}
        spacing={'1rem'}
        display={viewState == ViewState.Edit ? 'flex' : 'none'}
      >
        <LosButton
          text="Save"
          size="small"
          variant="outlined"
          onClick={onSubmit}
        />
        <LosButton
          text="Cancel"
          variant="outlined"
          size="small"
          onClick={() => {
            updateViewState(ViewState.ReadOnly);
          }}
        />
      </Stack>
    </div>
  );
}

export default ColenderIncomeSchemeTab;
