import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material'
import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import _ from 'lodash'
import { useState, useEffect } from 'react'
import VegaOutlineButton from '../../../../../../components/common/VegaOutlineButton'
import VegaTextButton from '../../../../../../components/common/VegaTextButton'
import { BORDER_GRAY, PURPLE } from '../../../../../../constants/style'
import {
  getApplicationsDetails,
  setApplicationsDetails,
} from '../../../../../../store/common/agentApplicationsSlice'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hook'
import { LOAN_TYPE } from '../../../../../enums/applications'
import { ReadOnlyFormField } from '../../../../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField'
import LosFormInputField from '../../../../../onboarding/components/common/LosFormInputField'
import LosSelect, {
  LosSelectOption,
} from '../../../../../onboarding/components/common/LosSelect'
import LosTextField from '../../../../../onboarding/components/common/LosTextField'
import { GuranteeType, RelationType } from '../../../../../onboarding/types/Los'
import {
  IBesicInfoProps,
  ICollateralDetailArrProps,
  IColleteralDetailsProps,
  IGuaranteeDetailsProps,
} from '../../../../../types/application'
import AgentSelect from '../AgentSelect'
import { useLosEnumApi } from '../../../../../onboarding/hooks/useLosEnumApi'
import { AddOutlinedIcon } from '../../../../../onboarding/icons/LosIcons'
import { toLowerCase } from '../../../../../../constants/commonFunction'
import { addCollateralDetailsForAgent } from '../../../../../APIs/LMS_Agent/applications'
import { useSnackbar } from '../../../../../../providers/SnackbarProvider'
import { getErrorMessageFromErrorObj } from '../../../../../../utils/api'
import { StringUtility } from '../../../../../onboarding/utils/StringUtility'

interface IProps {
  getAllApplicationById: () => void
  onUpdateApplication: () => void
}

const CollateralAndGurantor = ({
  getAllApplicationById,
  onUpdateApplication,
}: IProps) => {
  const { getCollateralTypes } = useLosEnumApi()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { setSnackbar } = useSnackbar()
  const [collateralType, setCollateralType] = useState<LosSelectOption[]>([])
  const { applicationDetails, isApplicationForHQ } = useAppSelector(
    getApplicationsDetails
  )
  const dispatch = useAppDispatch()

  if (applicationDetails.collateralDetails === null) {
    const newObj = {
      loanType: 'SOLAR',
      collateralDetails: [],
      guaranteeDetails: [
        {
          name: '',
          relationShip: '',
          email: '',
          panCard: '',
          guaranteeType: '',
          valueOfGuarantee: '',
        },
      ],
    }

    const newApplication = {
      ...applicationDetails,
      collateralDetails: newObj,
    }
    dispatch(setApplicationsDetails(newApplication))
  }

  const handleCollateralDetails = (
    name: keyof IColleteralDetailsProps,
    value: string | number | IGuaranteeDetailsProps[]
  ) => {
    const newCompanyObj = {
      ...applicationDetails.collateralDetails,
      [name]: value,
    }

    const modifyApplication = {
      ...applicationDetails,
      collateralDetails: newCompanyObj,
    }
    dispatch(setApplicationsDetails(modifyApplication))
  }
  const handleBesicInfoDetails = (
    name: keyof IBesicInfoProps,
    value: string | number
  ) => {
    const newCompanyObj = {
      ...applicationDetails.basicInfo,
      [name]: value,
    }
    const modifyApplication = {
      ...applicationDetails,
      basicInfo: newCompanyObj,
    }
    dispatch(setApplicationsDetails(modifyApplication))
  }

  const handleCahngeCollateralDetails = (
    name: keyof ICollateralDetailArrProps,
    value: string | number,
    index: number
  ) => {
    const guranteeDetailsArr =
      applicationDetails.collateralDetails?.collateralDetails
    if (guranteeDetailsArr) {
      const edited = {
        ...applicationDetails.collateralDetails?.collateralDetails[index],
        [name]: value,
      }

      const copy: any = [...guranteeDetailsArr]
      copy.splice(index, 1, edited)
      handleCollateralDetails('collateralDetails', copy)
    }
  }

  const getLoanAmount = () => {
    const amount = applicationDetails?.basicInfo?.amount ?? 0
    return amount
  }

  const handleGuaranteeDetails = (
    name: keyof IGuaranteeDetailsProps,
    value: string | number,
    index: number
  ) => {
    if (name == 'valueOfGuarantee' && typeof value === 'string') {
      const numberValue = Number(value)
      if (numberValue != null && numberValue > getLoanAmount()) {
        return
      }
    }

    const guranteeDetailsArr =
      applicationDetails.collateralDetails?.guaranteeDetails
    if (guranteeDetailsArr) {
      const edited = {
        ...applicationDetails.collateralDetails?.guaranteeDetails[index],
        [name]: value,
      }

      const copy: any = [...guranteeDetailsArr]
      copy.splice(index, 1, edited)
      handleCollateralDetails('guaranteeDetails', copy)
    }
  }

  const getGurantorDetails = () => {
    const details = applicationDetails.collateralDetails?.guaranteeDetails ?? []
    if (details?.length > 0) return details[0]
  }

  const getGuranteeTypeDisplayText = () => {
    const gurantor = getGurantorDetails()
    if (isEdit) {
      return gurantor?.guaranteeType
    }

    if (gurantor?.guaranteeType) {
      return _.startCase(_.toLower(gurantor.guaranteeType))
    }
  }

  const onSaveCollateralAndGuarantee = async () => {
    try {
      await addCollateralDetailsForAgent(
        applicationDetails.id as string,
        applicationDetails?.collateralDetails as IColleteralDetailsProps
      )
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    }
  }

  const addCollateralDetails = () => {
    const guranteeDetailsArr: any =
      applicationDetails.collateralDetails?.collateralDetails
    const newObj = {
      type: '',
      amount: '',
      id: '',
    }

    const newArr = [...guranteeDetailsArr, newObj]
    handleCollateralDetails('collateralDetails', newArr)
  }
  const delectCollateral = (
    collateral: ICollateralDetailArrProps,
    index: number
  ) => {
    const collateralArr = applicationDetails.collateralDetails
      ?.collateralDetails as ICollateralDetailArrProps[]
    const copy: any = [...collateralArr]
    copy.splice(index, 1)
    handleCollateralDetails('collateralDetails', copy)
  }

  const checkField = () => {
    const guranteeDetailsArray =
      applicationDetails.collateralDetails?.guaranteeDetails ?? []

    if (guranteeDetailsArray.length <= 0) {
      return false
    }
    const guaranteeDetails = guranteeDetailsArray[0]
    const name = guaranteeDetails.name
    const relationShip = guaranteeDetails.relationShip
    const email = guaranteeDetails.email
    const panCard = guaranteeDetails.panCard
    const guaranteeType = guaranteeDetails.guaranteeType
    const valueOfGuarantee = guaranteeDetails.valueOfGuarantee
    if (
      name &&
      relationShip &&
      email &&
      panCard &&
      guaranteeType &&
      valueOfGuarantee
    ) {
      return false
    }

    return true
  }

  async function fetchCollateralTypes() {
    try {
      const userType = applicationDetails?.basicInfo?.userType
      if (userType) {
        const response = await getCollateralTypes(userType)

        const optionArr = response.map((value: string) => {
          const option: LosSelectOption = {
            value: value,
            label: _.startCase(_.toLower(value)),
          }
          return option
        })
        setCollateralType(optionArr)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getCollateralAmount = (collateral: ICollateralDetailArrProps) => {
    if (isEdit) {
      return collateral.amount
    }
    return StringUtility.formatCurrency(collateral.amount ?? undefined)
  }

  useEffect(() => {
    fetchCollateralTypes()
  }, [])

  return (
    <>
      <Box sx={{ borderBottom: BORDER_GRAY }}>
        <Grid container spacing={2} pb={2}>
          <Grid item xs={12} sm={4} md={2}>
            <AgentSelect
              options={LOAN_TYPE}
              title='Loan Type'
              value={
                applicationDetails.basicInfo?.loanType
                  ? applicationDetails.basicInfo?.loanType
                  : '--'
              }
              isEdit={isEdit}
              name={`loanType`}
              onChange={value => handleBesicInfoDetails('loanType', value)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ borderBottom: BORDER_GRAY, mt: 2 }}>
        <Typography className='font-aspekta-500' fontSize={14}>
          Collateral details
        </Typography>
        {applicationDetails.collateralDetails?.collateralDetails.length
          ? applicationDetails.collateralDetails?.collateralDetails.map(
              (collateral: ICollateralDetailArrProps, index: number) => {
                return (
                  <Box py={1}>
                    <Grid container spacing={2} alignItems={'center'}>
                      <Grid item xs={6} sm={4} md={4}>
                        <Field
                          canEdit={isEdit}
                          title={'Type'}
                          options={collateralType}
                          value={toLowerCase(collateral.type ?? '') ?? ''}
                          onChange={function (value: string): void {
                            handleCahngeCollateralDetails('type', value, index)
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={4} md={4}>
                        <Field
                          canEdit={isEdit}
                          title={'Amount'}
                          inputType='number'
                          value={getCollateralAmount(collateral) ?? ''}
                          onChange={function (value: string): void {
                            handleCahngeCollateralDetails(
                              'amount',
                              value,
                              index
                            )
                          }}
                        />
                      </Grid>
                      {isEdit && (
                        <Grid item xs={6} sm={4} md={4} mt={2}>
                          <IconButton
                            onClick={() => delectCollateral(collateral, index)}
                          >
                            <DeleteOutlineOutlined color='error' />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )
              }
            )
          : !isEdit && (
              <Box textAlign={'center'} height={'50px'}>
                <Typography fontSize={16} className='font-aspekta-500'>
                  No Collateral Details
                </Typography>
              </Box>
            )}
        {isEdit && (
          <Button
            variant='text'
            disabled={
              applicationDetails.collateralDetails?.collateralDetails.length ===
              4
            }
            startIcon={<AddOutlinedIcon />}
            sx={{
              color: 'rgba(16, 71, 220, 1)',
              paddingLeft: '4px',
              textTransform: 'none',
            }}
            onClick={addCollateralDetails}
          >
            Add new Collateral
          </Button>
        )}
      </Box>

      <Box sx={{ borderBottom: BORDER_GRAY, mt: 2 }}>
        <Typography className='font-aspekta-500' fontSize={14}>
          Guarantor details
        </Typography>
        <Box py={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4}>
              <Field
                canEdit={isEdit}
                title={'Name'}
                value={getGurantorDetails()?.name}
                onChange={function (value: string): void {
                  handleGuaranteeDetails('name', value, 0)
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Field
                canEdit={isEdit}
                title={'Relationship'}
                options={relationshipType()}
                value={getGurantorDetails()?.relationShip ?? undefined}
                onChange={function (value: string): void {
                  handleGuaranteeDetails('relationShip', value, 0)
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Field
                canEdit={isEdit}
                title={'Email'}
                value={getGurantorDetails()?.email}
                onChange={function (value: string): void {
                  handleGuaranteeDetails('email', value, 0)
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Field
                canEdit={isEdit}
                title={'Pan card'}
                value={getGurantorDetails()?.panCard}
                onChange={function (value: string): void {
                  handleGuaranteeDetails('panCard', value, 0)
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Field
                canEdit={isEdit}
                options={guarnteeType()}
                title={'Type of Guarantee'}
                value={getGuranteeTypeDisplayText() ?? undefined}
                onChange={function (value: string): void {
                  handleGuaranteeDetails('guaranteeType', value, 0)
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Field
                canEdit={isEdit}
                title={`Value of Guarantee Max (${getLoanAmount()})`}
                value={getGurantorDetails()?.valueOfGuarantee ?? undefined}
                onChange={function (value: string): void {
                  handleGuaranteeDetails('valueOfGuarantee', value, 0)
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box textAlign={'end'} sx={{ mt: 2 }}>
        {isEdit ? (
          <>
            <VegaOutlineButton
              text={'Save'}
              isPurple
              disabled={checkField()}
              onClick={async () => {
                await onSaveCollateralAndGuarantee()
                setIsEdit(!isEdit)
              }}
            />
            <VegaTextButton
              text={'Cancel'}
              color={PURPLE.dark}
              onClick={() => {
                setIsEdit(!isEdit)
                getAllApplicationById()
              }}
            />
          </>
        ) : (
          !isApplicationForHQ &&
          (applicationDetails.status === 'IN_PROGRESS' ||
            applicationDetails.status === 'PENDING') && (
            <VegaOutlineButton
              text={'Edit'}
              startIcon={<EditOutlined />}
              isPurple
              onClick={() => setIsEdit(!isEdit)}
            />
          )
        )}
      </Box>
    </>
  )
}

export default CollateralAndGurantor

type FieldProps = {
  canEdit: boolean
  title: string
  value?: string
  onChange: (value: string) => void
  options?: LosSelectOption[]
  inputType?: React.InputHTMLAttributes<unknown>['type']
}

const Field = ({
  canEdit,
  title,
  value,
  onChange,
  options,
  inputType,
}: FieldProps) => {
  const hasOptions = () => {
    return options != null
  }

  if (canEdit) {
    return (
      <LosFormInputField label={title}>
        {hasOptions() && (
          <LosSelect
            value={value}
            onChange={e => onChange(e.target.value as string)}
            options={options}
          />
        )}
        {hasOptions() == false && (
          <LosTextField
            type={inputType}
            value={value}
            onChange={e => onChange(e.target.value)}
          />
        )}
      </LosFormInputField>
    )
  }
  return <ReadOnlyFormField title={title} description={value} />
}

const relationshipType = () =>
  Object.values(RelationType).map(item => {
    const option: LosSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    }
    return option
  })
const collateralType = () =>
  Object.values(RelationType).map(item => {
    const option: LosSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    }
    return option
  })

const guarnteeType = () =>
  Object.values(GuranteeType).map(item => {
    const option: LosSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    }
    return option
  })
