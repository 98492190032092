import { styled, Tab, Tabs, TabsProps } from '@mui/material';
import { ReactNode } from 'react';
import './../../../../components/common/v2/Styles/__styles.css';
interface LosTabBarProps extends TabsProps {
  children: ReactNode;
}

function LosTabBar({ children, ...rest }: LosTabBarProps) {
  const { color = 'rgba(16, 71, 220, 1)' } = rest;
  return (
    <Tabs
      TabIndicatorProps={{
        style: {
          backgroundColor: color,
        },
      }}
      {...rest}
    >
      {children}
    </Tabs>
  );
}

export default LosTabBar;
