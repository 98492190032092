import { InfoOutlined } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { toLowerCase } from '../../../../../../constants/commonFunction';
import {
  IComplianceFactorsProps,
  useRuleEngine,
} from '../../../../../LMSAgent/Provider/RuleEngingProvider';
import LosDataGrid from '../../../../LosDataGrid';
import LosText from '../../../../LosText';

dayjs.extend(utc);
dayjs.extend(timezone);

const ComplianceList = () => {
  const { loading, underWrittingScrore } = useRuleEngine();
  const indexData = underWrittingScrore?.underwritingComplianceScore
    ? underWrittingScrore?.underwritingComplianceScore.complianceScore.map(
        (score: IComplianceFactorsProps, id: number) => ({ ...score, id })
      )
    : [];

  const COLUMN_DEF: GridColumns<IComplianceFactorsProps> = [
    {
      field: 'creditWorthinessFactors',
      headerName: 'Criteria type',
      flex: 1,
      renderCell: (props: GridRenderCellParams) => {
        const compliance = props.row as IComplianceFactorsProps;
        return (
          <LosText
            text={toLowerCase(compliance.complianceFactors ?? '-')}
            fontSize={'0.75rem'}
          />
        );
      },
    },
    {
      field: 'weight',
      headerName: 'Weight',
      flex: 1,
      renderCell: (props: GridRenderCellParams) => {
        const compliance = props.row as IComplianceFactorsProps;
        const weight = compliance.weight;
        return <LosText text={`${weight} %`} fontSize={'0.75rem'} />;
      },
    },
    {
      field: 'value',
      headerName: 'value',
      flex: 1,
      renderCell: (props: GridRenderCellParams) => {
        const compliance = props.row as IComplianceFactorsProps;
        const value = compliance.value;
        return <LosText text={`${value} `} fontSize={'0.75rem'} />;
      },
    },
    {
      field: 'Score ',
      headerName: 'Score ',
      flex: 1,
      renderCell: (props: GridRenderCellParams) => {
        const compliance = props.row as IComplianceFactorsProps;
        const score = compliance.points;
        return (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <LosText text={`${score} `} fontSize={'0.75rem'} />
            <Tooltip
              title={
                <Stack>
                  <LosText
                    text={'Score Offered  (Value * Weight)'}
                    color={'white'}
                    fontWeight={600}
                    fontSize={'0.5rem'}
                    letterSpacing={'0.3px'}
                  />
                </Stack>
              }
            >
              <InfoOutlined
                sx={{
                  fontSize: '0.75rem',
                  color: '#676B76',
                }}
              />
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <LosDataGrid
        data={indexData}
        columns={COLUMN_DEF}
        idColumn={'id'}
        loading={loading}
      />
    </>
  );
};
export default ComplianceList;
