import { Box, Typography, Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { GREY } from '../../constants/style';
import { NON_ROUTES } from '../../routes';
import {
  UpdateClientLogoRequestData,
  VegaClientInterface,
  VegaClientLogoType,
} from '../../types/client';
import { VegaCard } from '../common';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaText from '../common/VegaText';
import UploadLogoContainer from './Components/UploadLogoContainer';

interface IProps {
  onSaveLogosClick: (updatedLogos: UpdateClientLogoRequestData[]) => void;
  client: VegaClientInterface | null;
}

interface LogoData {
  logo: File | null;
  logoUrl: string | null;
}

function getFileUrl(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

const emptyLogoData = {
  logo: null,
  logoUrl: null,
};

const Media = ({ client, onSaveLogosClick }: IProps) => {
  const [companyLogo, setCompanyLogo] = useState<LogoData>(emptyLogoData);
  const [mobileAppLogo, setMobileAppLogo] = useState<LogoData>(emptyLogoData);
  const [statementLogo, setStatementLogo] = useState<LogoData>(emptyLogoData);
  const [crmLogo, setCrmLogo] = useState<LogoData>(emptyLogoData);

  const mobileRef = useRef<any>(null);
  const statementRef = useRef<any>(null);
  const companyRef = useRef<any>(null);
  const crmRef = useRef<any>(null);
  const onUploadMobileLogo = () => {
    mobileRef.current.click();
  };
  const onUploadStatementLogo = () => {
    statementRef.current.click();
  };
  const onUploadCompanyLogo = () => {
    companyRef.current.click();
  };
  const onUploadCRMLogo = () => {
    crmRef.current.click();
  };

  async function updateSelectedLogo(data: {
    logoType: VegaClientLogoType;
    file: File | null;
  }) {
    var url = null;
    if (data.file) {
      url = await getFileUrl(data.file);
    }

    switch (data.logoType) {
      case VegaClientLogoType.COMPANY:
        setCompanyLogo({
          logo: data.file,
          logoUrl: url,
        });
        break;
      case VegaClientLogoType.CRM:
        setCrmLogo({
          logo: data.file,
          logoUrl: url,
        });
        break;
      case VegaClientLogoType.MOBILE_APP:
        setMobileAppLogo({
          logo: data.file,
          logoUrl: url,
        });
        break;
      case VegaClientLogoType.STATEMENT_APP:
        setStatementLogo({
          logo: data.file,
          logoUrl: url,
        });
        break;
    }
  }

  function onSaveClick() {
    const cliendId = client?.clientId;
    if (!cliendId) return;

    const logos = [
      { logo: companyLogo.logo, logoType: VegaClientLogoType.COMPANY },
      { logo: crmLogo.logo, logoType: VegaClientLogoType.CRM },
      { logo: mobileAppLogo.logo, logoType: VegaClientLogoType.MOBILE_APP },
      { logo: statementLogo.logo, logoType: VegaClientLogoType.STATEMENT_APP },
    ];

    const updatedLogos: UpdateClientLogoRequestData[] = logos
      .filter((logo) => logo.logo != null)
      .map((logo) => ({
        logoType: logo.logoType,
        logo: logo.logo as File,
        clientId: cliendId,
      }));

    onSaveLogosClick(updatedLogos);
  }

  useEffect(() => {
    setCompanyLogo({
      logo: null,
      logoUrl: client?.logo ?? null,
    });
    setMobileAppLogo({
      logo: null,
      logoUrl: client?.mobileAppLogo ?? null,
    });
    setStatementLogo({
      logo: null,
      logoUrl: client?.statementAppLogo ?? null,
    });
    setCrmLogo({
      logo: null,
      logoUrl: client?.crmLogo ?? null,
    });
  }, [client]);

  return (
    <Box id={NON_ROUTES.MEDIA}>
      <VegaCard>
        <Box sx={{ mb: 2 }}>
          <VegaText fw={500} text="Media" />
          <Typography
            variant="body2"
            sx={{ fontWeight: '500', color: GREY.lighter }}
          >
            All resources which are used in the Vegapay ecosystem
          </Typography>
        </Box>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <UploadLogoContainer
              handleChange={(e: any) =>
                updateSelectedLogo({
                  logoType: VegaClientLogoType.MOBILE_APP,
                  file: e.target.files[0],
                })
              }
              headerText="Mobile App Logo"
              customRef={mobileRef}
              fileUrl={mobileAppLogo.logoUrl}
              name="mobile"
              text="The logo uploaded here will be visible on the Mobile App"
              onUpload={onUploadMobileLogo}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UploadLogoContainer
              handleChange={(e: any) => {
                updateSelectedLogo({
                  logoType: VegaClientLogoType.STATEMENT_APP,
                  file: e.target.files[0],
                });
              }}
              headerText="Statement App Logo"
              customRef={statementRef}
              fileUrl={statementLogo.logoUrl}
              name="statement"
              text="The logo uploaded here will be visible on the Statement"
              onUpload={onUploadStatementLogo}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UploadLogoContainer
              handleChange={(e: any) =>
                updateSelectedLogo({
                  logoType: VegaClientLogoType.COMPANY,
                  file: e.target.files[0],
                })
              }
              headerText="Upload Company Logo"
              customRef={companyRef}
              fileUrl={companyLogo.logoUrl}
              name="company"
              text="The logo uploaded here will be visible on the Dashboard"
              onUpload={onUploadCompanyLogo}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UploadLogoContainer
              handleChange={(e: any) =>
                updateSelectedLogo({
                  logoType: VegaClientLogoType.CRM,
                  file: e.target.files[0],
                })
              }
              headerText="CRM Logo"
              customRef={crmRef}
              fileUrl={crmLogo.logoUrl}
              name="crm"
              text="The logo uploaded here will be visible on the CRM Dashboard"
              onUpload={onUploadCRMLogo}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: 2 }}>
          <VegaContainedButton text="Save" isPrimary onClick={onSaveClick} />
        </Box>
      </VegaCard>
    </Box>
  );
};

export default Media;
