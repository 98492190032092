import React from 'react';
import { Grid } from '@mui/material';
import LosDetailsWrapper from '../../../../LosDetailsWrapper';
import { GREEN, RED } from '../../../../../../constants/style';
import { toLowerCase } from '../../../../../../constants/commonFunction';
import { useRuleEngine } from '../../../../../LMSAgent/Provider/RuleEngingProvider';

interface IScoreOverviewProps {
  name: string;
  value: number | string;
  color: string;
}

const getColorValue = (score: number) => {
  if (!score)
    return {
      value: 'FAILED',
      color: RED.red,
    };
  const decimalWeight = score * 100;

  return {
    value: 'PASSED',
    color: decimalWeight >= 30 ? GREEN.dark : RED.red,
  };
};

const ScoreOverview = () => {
  const { loading, underWrittingScrore } = useRuleEngine();
  const scoreOverview: IScoreOverviewProps[] = [
    {
      name: 'Total Score Offered',
      value: underWrittingScrore?.underwritingCollateralScore
        ? underWrittingScrore?.underwritingCollateralScore.totalPoints
        : 0,
      color: '#1B1D22',
    },
    {
      name: 'Score Offered upon loan amount',
      value: underWrittingScrore?.underwritingCollateralScore
        ? `${(underWrittingScrore?.underwritingCollateralScore.totalPointPercentage).toFixed(
            1
          )} %`
        : `0 %`,
      color: '#1B1D22',
    },
    {
      name: 'Score',
      value: underWrittingScrore?.underwritingCollateralScore
        ? underWrittingScrore?.underwritingCollateralScore.weightedPoints
        : 0,
      color: '#1B1D22',
    },
  ];
  return (
    <div>
      <Grid container spacing={2}>
        {scoreOverview.map((score: IScoreOverviewProps, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={4}>
            {' '}
            <LosDetailsWrapper
              name={score.name}
              value={score.value}
              loading={loading}
              valueColor={score.color}
              fontWeight={400}
              fontSize="0.75rem"
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ScoreOverview;
