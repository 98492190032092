import { Stack } from '@mui/material';
import { useState } from 'react';
import VegaCheckBoxV2 from '../../../components/common/v2/VegaCheckBoxV2';
import { useLos } from '../providers/LosProvider';
import { CustomerPanData } from '../types/Los';
import { GSTDetailsCard } from './GSTDetailsCard';
import LosDialog from './common/LosDialog';
import LosOnboardingButton from './common/LosOnboardingButton';
import LosText from './common/LosText';

type Props = {
  open: boolean;
  gstNumber: string;
  onClose: () => void;
  onSubmit: () => void;
};
function VerifyGSTDetailsDialog({ open, onClose, onSubmit, gstNumber }: Props) {
  const [tncAccepted, setTncAccepted] = useState<boolean>(false);
  const isInputValid = () => {
    return tncAccepted == true;
  };

  function onSubmitClick() {
    if (isInputValid()) {
      onSubmit();
      onClose();
    }
  }

  return (
    <LosDialog open={open} onClose={() => {}}>
      <div
        style={{
          maxWidth: '22.5rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
        }}
      >
        <Stack
          direction={'row'}
          alignItems="center"
          justifyContent={'space-between'}
        >
          <LosText text="Verify GST" />
        </Stack>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <GSTDetailsCard />
        </div>
        <Stack>
          <Stack direction={'row'} spacing="1" alignItems={'start'}>
            <VegaCheckBoxV2
              sx={{
                '&.MuiButtonBase-root': {
                  paddingTop: '0px !important',
                },
                color: 'blue',
              }}
              checked={tncAccepted}
              onChange={(e, checked) => setTncAccepted(checked)}
            />
            <LosText style={{ fontSize: '0.75rem', lineHeight: '130%' }}>
              By accepting and agreeing to this contest form and the
              <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                Terms & Conditions
              </span>{' '}
              I, allow Solfin to save my KYC details
            </LosText>
          </Stack>
        </Stack>
        <LosOnboardingButton
          fullWidth
          text="Yes, Confirm My GST"
          disabled={isInputValid() == false}
          onClick={onSubmitClick}
        />
      </div>
    </LosDialog>
  );
}

export default VerifyGSTDetailsDialog;
