import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import LosDataGrid from '../../../../LosDataGrid';
import LosText from '../../../../LosText';
import { Stack, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import {
  ICollateralScoresProps,
  ICreditWorthinessScoreProps,
  useRuleEngine,
} from '../../../../../LMSAgent/Provider/RuleEngingProvider';
import { toLowerCase } from '../../../../../../constants/commonFunction';

dayjs.extend(utc);
dayjs.extend(timezone);

const CreditWothinessList = () => {
  const { loading, underWrittingScrore } = useRuleEngine();
  const indexData = underWrittingScrore?.creditWorthinessScore
    ? underWrittingScrore?.creditWorthinessScore.creditWorthinessScore.map(
        (score: ICreditWorthinessScoreProps, id: number) => ({ ...score, id })
      )
    : [];

  const COLUMN_DEF: GridColumns<ICreditWorthinessScoreProps> = [
    {
      field: 'creditWorthinessFactors',
      headerName: 'Criteria type',
      flex: 1,
      renderCell: (props: GridRenderCellParams) => {
        const creditWorthiness = props.row as ICreditWorthinessScoreProps;
        return (
          <LosText
            text={toLowerCase(creditWorthiness.creditWorthinessFactors ?? '-')}
            fontSize={'0.75rem'}
          />
        );
      },
    },
    {
      field: 'weight',
      headerName: 'Weight',
      flex: 1,
      renderCell: (props: GridRenderCellParams) => {
        const creditWorthiness = props.row as ICreditWorthinessScoreProps;
        const weight = creditWorthiness.weight;
        return <LosText text={`${weight} %`} fontSize={'0.75rem'} />;
      },
    },
    {
      field: 'value',
      headerName: 'value',
      flex: 1,
      renderCell: (props: GridRenderCellParams) => {
        const creditWorthiness = props.row as ICreditWorthinessScoreProps;
        const value = creditWorthiness.value;
        return <LosText text={`${value} `} fontSize={'0.75rem'} />;
      },
    },
    {
      field: 'Score ',
      headerName: 'Score ',
      flex: 1,
      renderCell: (props: GridRenderCellParams) => {
        const creditWorthiness = props.row as ICreditWorthinessScoreProps;

        // const value = JSON.parse(creditWorthiness.value ?? '0');
        const score = creditWorthiness.points;
        // const convertedValue = (value * weight) / 100;
        // const row = convertedValue.toFixed(2);
        return (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <LosText text={`${score} `} fontSize={'0.75rem'} />
            <Tooltip
              title={
                <Stack>
                  <LosText
                    text={'Score Offered  (Value * Weight)'}
                    color={'white'}
                    fontWeight={600}
                    fontSize={'0.5rem'}
                    letterSpacing={'0.3px'}
                  />
                  {/* <LosText
                    text={`${value} * ${weight} %`}
                    color={'white'}
                    fontWeight={600}
                    fontSize={'0.5rem'}
                    letterSpacing={'0.3px'}
                  /> */}
                </Stack>
              }
            >
              <InfoOutlined
                sx={{
                  fontSize: '0.75rem',
                  color: '#676B76',
                }}
              />
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <LosDataGrid
        data={indexData}
        columns={COLUMN_DEF}
        idColumn={'id'}
        loading={loading}
      />
    </>
  );
};
export default CreditWothinessList;
