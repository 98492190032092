import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import LOSDateField from '../../../../../components/LOSDateField';
import LosFormInputField from '../../../../../onboarding/components/common/LosFormInputField';
import { EditFormFiled } from './EditFormFiled';
import {
  ColenderBankDetailsFormData,
  ColenderBankDetailsInputType,
} from './formData';
import { ReadOnlyFormField } from './ReadOnlyFormField';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  formData: Partial<ColenderBankDetailsFormData>;
  handleUpdate: (type: ColenderBankDetailsInputType, value?: string) => void;
  isEditable: boolean;
};

function ColenderBankDetailsForm({
  formData,
  handleUpdate,
  isEditable,
}: Props) {
  if (isEditable) {
    return <EditView formData={formData} onUpdate={handleUpdate} />;
  }
  return <ReadOnlyView formData={formData} />;
}

export default ColenderBankDetailsForm;

type EditViewProps = {
  formData: Partial<ColenderBankDetailsFormData>;
  onUpdate: (type: ColenderBankDetailsInputType, value?: string) => void;
};
const EditView = ({ formData, onUpdate }: EditViewProps) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Grid container spacing={'1rem'}>
          <Grid item xs={3}>
            <EditFormFiled
              title="Colender Name"
              description={formData.colenderName}
              onChange={(e) => {
                onUpdate(ColenderBankDetailsInputType.ColenderName, e);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <LosFormInputField label="Date Of Registeration">
              <LOSDateField
                value={formData.dateOfRegistration}
                onChange={(e) => {
                  if (!e) return;
                  const dateString = e.$d;
                  if (!dateString || dateString == 'Invalid Date') return;
                  onUpdate(
                    ColenderBankDetailsInputType.DateOfRegistration,
                    dateString
                  );
                }}
              />
            </LosFormInputField>
          </Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="PAN Number"
              description={formData.pan}
              onChange={(e) => {
                onUpdate(ColenderBankDetailsInputType.PAN, e);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="CIN Number"
              description={formData.cin}
              onChange={(e) => {
                onUpdate(ColenderBankDetailsInputType.CIN, e);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="TIN Number"
              description={formData.tin}
              onChange={(e) => {
                onUpdate(ColenderBankDetailsInputType.TIN, e);
              }}
            />
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '1rem',
        }}
      >
        <Grid container spacing={'1rem'}>
          <Grid item xs={3}>
            <EditFormFiled
              title="Address"
              description={formData.address}
              onChange={(e) => {
                onUpdate(ColenderBankDetailsInputType.Address, e);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="City"
              description={formData.city}
              onChange={(e) => {
                onUpdate(ColenderBankDetailsInputType.City, e);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="State"
              description={formData.state}
              onChange={(e) => {
                onUpdate(ColenderBankDetailsInputType.State, e);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="Pincode"
              description={formData.pincode}
              onChange={(e) => {
                onUpdate(ColenderBankDetailsInputType.Pincode, e);
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

type ReadOnlyViewProps = {
  formData: Partial<ColenderBankDetailsFormData>;
};

const ReadOnlyView = ({ formData }: ReadOnlyViewProps) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Grid container spacing={'1rem'}>
          <Grid item xs={3}>
            <ReadOnlyFormField
              title="Colender Name"
              description={formData.colenderName}
            />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField
              title="Date Of Registeration"
              description={formData.dateOfRegistration}
            />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField title="PAN Number" description={formData.pan} />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField title="CIN Number" description={formData.cin} />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField title="TIN Number" description={formData.tin} />
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '1rem',
        }}
      >
        <Grid container spacing={'1rem'}>
          <Grid item xs={3}>
            <ReadOnlyFormField title="Address" description={formData.address} />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField title="City" description={formData.city} />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField title="State" description={formData.state} />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField title="Pincode" description={formData.pincode} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
