import React from 'react';
import { Box } from '@mui/material';
import {
  BLUE,
  GREEN,
  ORANGE,
  PURPLE,
  RED,
  VIOLET,
  YELLOW,
} from '../../constants/style';

interface ILabelProps {
  text: string | number;
  color?: 'success' | 'danger' | 'warning' | 'primary' | 'purple';
  icon?: React.ReactNode;
  fontSize?: 8 | 10 | 12 | 14 | 16 | 18;
  width?: string;
}

const VegaLabelWrapper = ({
  color,
  text,
  icon,
  fontSize = 12,
  width = 'auto',
}: ILabelProps) => {
  return (
    <Box
      className="font-aspekta-600"
      sx={{
        px: 1,
        py: 0.2,

        borderRadius: '15px',

        display: 'flex',

        alignItems: 'center',
        ...(color === 'success'
          ? { bgcolor: GREEN.light, color: GREEN.dark }
          : color === 'danger'
          ? { bgcolor: RED.light, color: RED.dark }
          : color === 'warning'
          ? { bgcolor: YELLOW.indicative, color: YELLOW.dark }
          : color === 'purple'
          ? { bgcolor: PURPLE.violet, color: VIOLET.dark }
          : { bgcolor: BLUE.light, color: BLUE.lighter }),
      }}
    >
      {icon} {icon && ' '}
      <Box
        className="font-aspekta-600"
        sx={{
          fontSize: fontSize,
          width: width,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {text}
      </Box>
    </Box>
  );
};

export default VegaLabelWrapper;
