import axios from 'axios';
import { BASE_URL } from '../../../api/endpoints';
import { Lead } from '../types/Los';

const Endpoint = {
  BASE: '/lead',
  CREATE_LEAD: '/',
};

const LEAD_BASE_URL = BASE_URL + Endpoint.BASE;
export class LeadService {
  static async createLead(
    data: Partial<CreateLeadApiRequestData>
  ): Promise<Lead> {
    try {
      const endpoint = LEAD_BASE_URL + Endpoint.CREATE_LEAD;
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export type CreateLeadApiRequestData = {
  phone: string;
  programId: string;
};
