import { ReactNode } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useClientAuth } from '../../providers/ClientProvider';
import { DASHBOARD_ROUTES, ROUTES } from '../../routes';
import AccessNotAllowed from '../../solfen/AccessNotAllowed';

type Props = {
  children: ReactNode;
};

export function PrivateHqRoute({ children }: Props) {
  const { isAuth, agentId, logout } = useClientAuth();
  const navigate = useNavigate();

  if (agentId) {
    return (
      <AccessNotAllowed
        description={'Agent is not authorized to access this view.'}
        buttonText={'Go back to agent dashboard'}
        showLogoutButton
        onClick={function () {
          navigate('/' + DASHBOARD_ROUTES.SOLFIN_AGENT);
        }}
        onLogoutClick={() => {
          logout();
        }}
      />
    );
  }

  if (isAuth == true) return <>{children}</>;

  return <Navigate to={ROUTES.HQ_LOGIN} />;
}
