import { IconButton, Stack } from '@mui/material';
import { CloseDialogIcon, VerifiedLargeIcon } from '../icons/LosIcons';
import LosButton from './common/LosButton';
import LosDialog from './common/LosDialog';
import LosText from './common/LosText';
import LosOnboardingButton from './common/LosOnboardingButton';

type Props = {
  open: boolean;
  title: string;
  description: string;
  buttonText: string;
  onClose: () => void;
  onSubmit: () => void;
};
function VerificationSuccessDialog({
  open,
  onClose,
  onSubmit,
  title,
  description,
  buttonText,
}: Props) {
  function onSubmitClick() {
    onSubmit();
    onClose();
  }

  return (
    <LosDialog open={open} onClose={() => {}}>
      <div
        style={{
          maxWidth: '22.5rem',
          minWidth: '20rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
        }}
      >
        <Stack
          direction={'row'}
          alignItems="center"
          justifyContent={'space-between'}
        >
          <LosText text={title} />
          {/* <IconButton onClick={onClose}>
            <CloseDialogIcon />
          </IconButton> */}
        </Stack>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <VerifiedLargeIcon />
          <LosText
            text={description}
            textAlign="center"
            style={{
              fontWeight: 600,
              lineHeight: '120%',
              fontSize: '1.0625rem',
            }}
          />
        </div>

        <LosOnboardingButton
          fullWidth
          text={buttonText}
          onClick={onSubmitClick}
        />
      </div>
    </LosDialog>
  );
}

export default VerificationSuccessDialog;
