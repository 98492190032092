/** @format */

import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { GREY } from '../../constants/style';
import { useClientAuth } from '../../providers/ClientProvider';
import { NON_ROUTES } from '../../routes';
import { VegaCard, VegaTextField } from '../common';
import VegaContainedButton from '../common/VegaContainedButton';
import VegaOutlineButton from '../common/VegaOutlineButton';
import { EditIcon } from '../icon/Icons';

const UserProfile = () => {
  const { loggedInUserDetails } = useClientAuth();
  return (
    <Box id={NON_ROUTES.USER_PROFILE}>
      <VegaCard>
        <Box sx={{ display: 'flex', mb: 2 }}>
          <Typography sx={{ fontSize: '18px', color: GREY.grey_light }}>LoggedIn Customer Profile</Typography> &nbsp;
          <Typography sx={{ fontSize: '20px', color: '#A4A4A4' }}>
            ( Last LogIn : {dayjs(loggedInUserDetails?.loginTime).format('DD MMM YYYY hh:mm A')} )
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={4} sx={{ mb: 2 }}>
            <VegaTextField
              value={loggedInUserDetails?.name ?? ''}
              onChange={(e) => console.log('name', e.target.value)}
              key="name"
              label="Name"
              placeholder="Name"
              type={'text'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} sx={{ mb: 2 }}>
            <VegaTextField
              value={loggedInUserDetails?.email || ''}
              onChange={(e) => console.log('email', e.target.value)}
              key="email"
              label="Email"
              placeholder="Email"
              type={'email'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} sx={{ mb: 2 }}>
            <VegaTextField
              value={loggedInUserDetails?.user?.mobileNumber || ''}
              onChange={(e) => console.log('mobileNumber', e.target.value)}
              key="mobileNumber"
              label="Mobile Number"
              placeholder="Mobile Number"
              type={'number'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={8} sx={{ mb: 2 }}>
            <VegaTextField
              value={loggedInUserDetails?.user?.address || ''}
              onChange={(e) => console.log('address', e.target.value)}
              key="address"
              label="Address"
              placeholder="Address"
              type={'text'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} sx={{ mb: 2 }}>
            <VegaTextField
              value={loggedInUserDetails?.user?.team || ''}
              onChange={(e) => console.log('position', e.target.value)}
              key="position"
              label="Position"
              placeholder="Position"
              type={'text'}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <VegaOutlineButton text="View Log-in history" isSuccess />
          <VegaContainedButton text="Edit Info" startIcon={<EditIcon />} isPrimary />
        </Box>
      </VegaCard>
    </Box>
  );
};

export default UserProfile;
