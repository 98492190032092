import React from 'react';
import { Stack } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { GREEN, RED } from '../../../constants/style';

interface IProps {
  value: number;
}

const WeightScoreWrapper = ({ value }: IProps) => {
  const getColorValue = (value: number) => {
    const convertedValue = value * 100;
    if (convertedValue === 0) {
      return {
        bgcolor: RED.light,
        color: RED.red,
      };
    } else if (convertedValue <= 30) {
      return {
        bgcolor: RED.light,
        color: RED.red,
      };
    } else {
      return {
        bgcolor: GREEN.light,
        color: GREEN.dark,
      };
    }
  };

  return (
    <>
      <Stack
        direction={'row'}
        className="font-aspekta-600"
        fontSize={'0.625rem'}
        minWidth={'123px'}
        alignItems={'center'}
        maxWidth={'123px'}
        gap={1}
        my={1.5}
        ml={0.3}
        sx={{
          px: 1,
          py: 0.5,
          bgcolor: getColorValue(value).bgcolor,
          color: getColorValue(value).color,
          borderRadius: '12px',
        }}
      >
        Weight Score: {value}
        <InfoOutlined
          sx={{
            fontSize: '0.75rem',
          }}
        />
      </Stack>
    </>
  );
};

export default WeightScoreWrapper;
