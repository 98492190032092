import { createContext, useContext, useEffect, useState } from 'react';
import {
  getUnderWrittingScoreById,
  updateManagementScore,
  updateVetoStatus,
} from '../../APIs/LMS_Agent/ruleEngine';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useAppSelector } from '../../../store/hook';
import { getApplicationsDetails } from '../../../store/common/agentApplicationsSlice';

export enum RuleTypeEnum {
  FINAL_CRITERIA = 'FINAL_CRITERIA',
  COLLATERAL = 'COLLATERAL',
  CREDIT_WORTHINESS = 'CREDIT_WORTHINESS',
  FINANCIAL = 'FINANCIAL',
  MANAGMENT = 'MANAGMENT',
  COMPLIANCE = 'COMPLIANCE',
}

export interface ICreditWorthinessScoreProps {
  creditWorthinessFactors: string;
  weight: number;
  value: string;
  points: string;
}
export interface IFinanceScoresProps {
  financialFactors: string | null;
  weight: number;
  value: string;
  points: string;
}
export interface IComplianceFactorsProps {
  complianceFactors: string | null;
  weight: number;
  value: string;
  points: string;
}
export interface ICollateralScoresProps {
  collateralFactors: string | null;
  weight: number;
  value: string;
  points: string;
}

export interface ICreditWorthinessScoreObjProps {
  creditWorthinessScore: ICreditWorthinessScoreProps[];
  points: number;
  weightedPoints: number;
}
export interface IUnderwritingFinancialScoreObjProps {
  financeScores: IFinanceScoresProps[];
  points: number;
  weightedPoints: number;
}
export interface IUnderwritingManagementScoreObjProps {
  comment: string;
  points: number;
  weightedPoints: number;
}
export interface IUnderwritingComplianceScoreObjProps {
  complianceScore: IComplianceFactorsProps[];
  points: number;
  weightedPoints: number;
  veto: boolean;
}
export interface IUnderwritingCollateralScoreObjProps {
  collateralScores: ICollateralScoresProps[];
  totalPoints: number;
  totalPointPercentage: number;
  points: number;
  weightedPoints: number;
}

export interface IUnderWrittingScroreProps {
  applicationId: string;
  points: number;
  overRideFD: number | null;
  underwritingBasicCriteria: null;
  underwritingVetaCriteria: null;
  underwritingCollateralScore: IUnderwritingCollateralScoreObjProps | null;
  creditWorthinessScore: ICreditWorthinessScoreObjProps;
  underwritingFinancialScore: IUnderwritingFinancialScoreObjProps;
  underwritingManagementScore: IUnderwritingManagementScoreObjProps;
  underwritingComplianceScore: IUnderwritingComplianceScoreObjProps;
}

type RuleEngineContextType = {
  selectedRuleEngineType: string;
  comment: string;
  vetoStatus: string;
  loading: boolean;
  isEdit: boolean;
  isVetoEdit: boolean;
  underWrittingScrore: IUnderWrittingScroreProps | null;
  setSelectedRuleEngineType: React.Dispatch<React.SetStateAction<string>>;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  setVetoStaus: React.Dispatch<React.SetStateAction<string>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setIsVetoEdit: React.Dispatch<React.SetStateAction<boolean>>;
  handleCommentChange: (value: string) => void;
  handleVetoStatusChange: (value: string) => void;
  onUpdateManagementScore: () => void;
  onUpdateVetoStatus: () => void;
};

const RuleEngineContext = createContext<RuleEngineContextType | null>(null);

export const useRuleEngine = () =>
  useContext(RuleEngineContext) as RuleEngineContextType;

export const RuleEngingProvider = ({ children }: any) => {
  const { applicationDetails } = useAppSelector(getApplicationsDetails);

  const { setSnackbar } = useSnackbar();
  const [selectedRuleEngineType, setSelectedRuleEngineType] = useState<string>(
    RuleTypeEnum.FINAL_CRITERIA
  );
  const [underWrittingScrore, setUnderWrittingScrore] =
    useState<IUnderWrittingScroreProps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isVetoEdit, setIsVetoEdit] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [vetoStatus, setVetoStaus] = useState<string>('');

  const applicationId = 'ce9c8eb1-870e-460b-9b89-5ae6ca805da8';

  const handleCommentChange = (value: string) => {
    setComment(value);
  };
  const handleVetoStatusChange = (value: string) => {
    setVetoStaus(value);
  };

  const onUpdateManagementScore = async () => {
    try {
      const response = await updateManagementScore(applicationId, comment);
      fetchUderWrittingScrore();
      setSnackbar('Comment Updated !');
      setIsEdit(false);
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to update comment'),
        'error'
      );
    }
  };

  const onUpdateVetoStatus = async () => {
    const status = vetoStatus === 'YES' ? true : false;
    try {
      const response = await updateVetoStatus(applicationId, status);
      fetchUderWrittingScrore();
      setSnackbar('veto status Updated !');
      setIsVetoEdit(false);
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to update veto status'),
        'error'
      );
    }
  };
  const fetchUderWrittingScrore = async () => {
    setLoading(true);
    try {
      const response = await getUnderWrittingScoreById(applicationId);
      setUnderWrittingScrore(response.data);
      if (response.data.underwritingManagementScore) {
        setComment(response.data.underwritingManagementScore.comment);
      }
      if (response.data.underwritingComplianceScore) {
        const status =
          response.data.underwritingComplianceScore.veto === true
            ? 'YES'
            : 'NO';
        setVetoStaus(status);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUderWrittingScrore();
  }, []);

  return (
    <RuleEngineContext.Provider
      value={{
        isVetoEdit,
        setIsVetoEdit,
        vetoStatus,
        setVetoStaus,
        handleVetoStatusChange,
        onUpdateVetoStatus,
        selectedRuleEngineType,
        loading,
        isEdit,
        setIsEdit,
        comment,
        setComment,
        handleCommentChange,
        onUpdateManagementScore,
        underWrittingScrore,
        setSelectedRuleEngineType,
      }}
    >
      {children}
    </RuleEngineContext.Provider>
  );
};
