import { Close } from '@mui/icons-material'
import { Box, Dialog, Grid, IconButton, Stack } from '@mui/material'
import { useState, useEffect } from 'react'
import { BORDER_GRAY, GREY } from '../../../../../../constants/style'
import { dateFormat } from '../../../../../../mcc/variable'
import { useSnackbar } from '../../../../../../providers/SnackbarProvider'
import { getErrorMessageFromErrorObj } from '../../../../../../utils/api'
import { LosService } from '../../../../../onboarding/api/LosService'
import LosFormInputField from '../../../../../onboarding/components/common/LosFormInputField'
import LosText from '../../../../../onboarding/components/common/LosText'
import { CustomerApplication } from '../../../../../onboarding/types/Los'
import { StringUtility } from '../../../../../onboarding/utils/StringUtility'
import { LoanManagementService } from '../../../../api/LoanManagementService'
import { Loan, LoanDispersalSchedule } from '../../../../types/Lms'
import { ReadOnlyFormField } from '../../../Co-Lenders/components/Forms/ReadOnlyFormField'
import DisburseDetails from './components/DisburseDetails'
interface IProps {
  open: boolean
  onClose: () => void
  applicationId: string | null
}

const DisburseFirstTrancheDialog = ({
  open,
  onClose,
  applicationId,
}: IProps) => {
  const { setSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingTranches, setLoadingTranches] = useState<boolean>(false)
  const [loan, setLoan] = useState<Loan>()
  const [customerApplication, setCustomerApplication] =
    useState<CustomerApplication>()
  const [tranches, setTranches] = useState<LoanDispersalSchedule[]>([])

  async function fetchApplication(applicationId: string) {
    try {
      setLoading(true)
      const response = await LosService.getApplicationById(applicationId)
      setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  async function fetchLoanTranches(loanId: string) {
    try {
      setLoadingTranches(true)
      const response = await LoanManagementService.getDispersalSchedule(loanId)
      setTranches(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoadingTranches(false)
    }
  }

  async function fetchLoan(applicationId: string) {
    try {
      setLoading(true)
      const response = await LoanManagementService.getLoanByApplicationId(
        applicationId
      )
      setLoan(response)
      fetchLoanTranches(response.id)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open == true && applicationId != null) {
      fetchApplication(applicationId)
      fetchLoan(applicationId)
    }
  }, [open, applicationId])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      sx={{ borderRadius: '20px' }}
      onClose={onClose}
      open={open}
    >
      <Stack
        direction={'row'}
        justifyContent='space-between'
        alignItems={'center'}
        style={{
          paddingTop: '1.25rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
        }}
      >
        <LosText text='Disburse Now' />
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <Box sx={{ bgcolor: GREY.light, p: 2 }}>
        <Box
          sx={{
            bgcolor: 'white',
            border: BORDER_GRAY,
            borderRadius: '10px',
            py: 2,
            mb: 2,
            px: 3,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <ReadOnlyFormField
                title='Loan Amount'
                description={`${StringUtility.formatCurrency(
                  customerApplication?.basicInfo.amount
                )}`}
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ReadOnlyFormField
                title='Approved Loan Amount'
                description={`${StringUtility.formatCurrency(
                  loan?.loan_amount
                )}`}
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ReadOnlyFormField
                title='Rate Of Interest'
                description={`${loan?.interest_rate ?? '-'}%`}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            bgcolor: 'white',
            border: BORDER_GRAY,
            borderRadius: '10px',
            py: 2,
            mb: 2,
            px: 3,
          }}
        >
          <Stack>
            <LosText fontWeight={500} fontSize={14}>
              Requested Disbursal Plan Details
            </LosText>
            {tranches?.map((schedule: LoanDispersalSchedule, index: number) => (
              <Box py={1} key={index}>
                <LosFormInputField label={`Tranche ${index + 1}`}>
                  <Grid container>
                    <Grid item xs={12} sm={3}>
                      <ReadOnlyFormField
                        title='Date'
                        description={dateFormat(schedule.scheduleDate)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <ReadOnlyFormField
                        title='Amount'
                        description={`${StringUtility.formatCurrency(
                          schedule.amount
                        )}`}
                      />
                    </Grid>
                    {schedule.utrNo && (
                      <Grid item xs={12} sm={3}>
                        <ReadOnlyFormField
                          title='UTR Number'
                          description={`${schedule.utrNo}`}
                        />
                      </Grid>
                    )}
                    {schedule.dispersalDate && (
                      <Grid item xs={12} sm={3}>
                        <ReadOnlyFormField
                          title='Disbursal Date'
                          description={dateFormat(schedule.dispersalDate)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </LosFormInputField>
              </Box>
            ))}
          </Stack>
        </Box>
        <Box
          sx={{
            bgcolor: 'white',
            border: BORDER_GRAY,
            borderRadius: '10px',
            py: 2,
            mb: 2,
            px: 3,
          }}
        >
          <DisburseDetails onClose={onClose} disbersalSchedule={tranches} />
        </Box>
      </Box>
    </Dialog>
  )
}

export default DisburseFirstTrancheDialog
