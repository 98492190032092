import { useLos } from '../providers/LosProvider'
import { LosStep } from '../types/Los'
import AgreementSigning from './AggrementSigning/AgreementSigning'
import AgreementSigningCompletedPage from './AggrementSigning/AgreementSigningCompletedPage'
import DownloadSignedAgreement from './AggrementSigning/DownloadSignedAgreement'
import ApplicationApproved from './ApplicationApproved/ApplicationApproved'
import ApplicationReadyForDisbursal from './ApplicationReadyForDisbursal/ApplicationReadyForDisbursal'
import ApplicationSubmitted from './ApplicationSubmitted/ApplicationSubmitted'
import BankDetails from './BankDetails/BankDetails'
import BasicDetails from './BasicDetails/BasicDetails'
import CollateralDetails from './CollateralDetails/CollateralDetails'
import ElectricityDetails from './ElectricityDetails'
import FinancialDetails from './FinancialDetails/FinancialDetails'
import LoanDispursed from './LoanDispursed/LoanDispursed'
import CompanyPanContainer from './PanDetails/CompanyPanContainer'
import UploadAgreement from './UploadAgreement/UploadAgreement'

function NonIndividualStepContainer() {
  const { currentStep } = useLos()

  const isUserInKyc = () => {
    const isPanState =
      currentStep == LosStep.PAN_ACCEPTED ||
      currentStep == LosStep.PAN_VALIDATION
    const isAadharState =
      currentStep == LosStep.AADHAAR_VALIDATION ||
      currentStep == LosStep.AADHAAR_ACCEPTED
    const isSelfieState = currentStep == LosStep.SELFIE_VERIFICATION
    const isCompanyDetails =
      currentStep == LosStep.COMPANY_DETAIL ||
      currentStep == LosStep.COMPANY_PAN_VALIDATION ||
      currentStep == LosStep.COMPANY_PAN_ACCEPTED
    const isGstDetails =
      currentStep == LosStep.GST_ACCEPTED ||
      currentStep == LosStep.GST_VERIFICATION

    const isCINStep = currentStep == LosStep.CIN_NO
    const isPINStep = currentStep == LosStep.PIN_NO

    return (
      isAadharState ||
      isSelfieState ||
      isPanState ||
      isCompanyDetails ||
      isGstDetails ||
      isCINStep ||
      isPINStep
    )
  }

  if (currentStep == LosStep.SUBMITTED) {
    return <ApplicationSubmitted />
  } else if (currentStep == LosStep.SANCTION_LETTER) {
    return <ApplicationApproved />
  } else if (currentStep == LosStep.UNSIGNED_AGREEMENT) {
    return <UploadAgreement />
  } else if (currentStep == LosStep.CONTACT_DETAILS) {
    return <AgreementSigning />
  } else if (currentStep == LosStep.AGREEMENT) {
    return <AgreementSigningCompletedPage />
  } else if (currentStep == LosStep.SIGNED_AGREEMENT) {
    return <DownloadSignedAgreement />
  } else if (currentStep == LosStep.BANK_DETAILS) {
    return <BankDetails />
  } else if (currentStep == LosStep.APPLICATION_FORM) {
    return <BasicDetails />
  } else if (currentStep == LosStep.FINANCIALS) {
    return <FinancialDetails />
  } else if (currentStep == LosStep.COLLATERAL) {
    return <CollateralDetails />
  } else if (currentStep == LosStep.DOCUMENTATION) {
    return <ElectricityDetails />
  } else if (isUserInKyc()) {
    return <CompanyPanContainer />
  } else if (
    currentStep == LosStep.ONBOARDED ||
    currentStep == LosStep.DISPERSED
  ) {
    return <LoanDispursed />
  } else if (currentStep == LosStep.READY_FOR_DISPERSAL) {
    return <ApplicationReadyForDisbursal />
  }

  return <div>Something went wrong</div>
}

export default NonIndividualStepContainer
