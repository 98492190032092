import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'

import { useLosAuth } from '../providers/LosAuthProvider'
import { useLos } from '../providers/LosProvider'
import {
  isUserIndividual,
  isUserNonIndividual,
  isUserSelfEmployed,
} from '../types/Los'
import { LocalStorageUtility, StorageKeys } from '../utils/StorageUtility'
import IndividualStepContainer from './IndividualStepContainer'
import NonIndividualStepContainer from './NonIndividualStepContainer'
import PageNotFound from './PageNotFound'

function LosStepContainer() {
  const { fetchApplicationState, currentUserType, fetchApplication } = useLos()
  const { isAuthenticated } = useLosAuth()
  const [loadingState, setLoadingState] = useState<boolean>(false)
  const [userType, setUserType] = useState<string | undefined>(
    currentUserType ?? 'INDIVIDUAL'
  )

  async function _fetchState() {
    const applicationId = LocalStorageUtility.getItem<string>(
      StorageKeys.APPLICATION_ID
    )
    console.log(' i am getting execiuted now')
    if (isAuthenticated == false || !applicationId) {
      console.log('got aplication id')
      return
    }
    try {
      setLoadingState(true)
      await fetchApplication(applicationId)
      await fetchApplicationState()
    } catch (error) {
    } finally {
      setLoadingState(false)
    }
  }

  useEffect(() => {
    _fetchState()
  }, [isAuthenticated])

  // useEffect(() => {
  //   console.log('====================================')
  //   console.log('called1')
  //   console.log('====================================')

  //   const hasFetchedStateBefore = LocalStorageUtility.getItem<boolean>(
  //     StorageKeys.hasFetchedStateBefore
  //   )
  //   console.log('====================================')
  //   console.log('hasFetchedStateBefore', hasFetchedStateBefore)
  //   console.log('====================================')
  //   if (!hasFetchedStateBefore && isAuthenticated) {
  //     _fetchState()
  //     LocalStorageUtility.setItem<boolean>(
  //       StorageKeys.hasFetchedStateBefore,
  //       true
  //     )
  //   }
  // }, [isAuthenticated])

  useEffect(() => {
    setUserType(currentUserType)
  }, [currentUserType])

  if (loadingState == true) {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  if (isUserIndividual(userType) || isUserSelfEmployed(userType)) {
    return <IndividualStepContainer />
  } else if (isUserNonIndividual(userType)) {
    return <NonIndividualStepContainer />
  }

  return <PageNotFound />
}

export default LosStepContainer
