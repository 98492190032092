import { Close } from '@mui/icons-material';
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { PRIMARY } from '../../constants/style';
import VegaContainedButton from './VegaContainedButton';
import VegaOutlineButton from './VegaOutlineButton';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: (e: any) => void;
}

interface DialogProps {
  handleClose: (e: any) => void;
  onSubmit?: () => void;
  open: boolean;
  title: string;
  subtitle?: string;
  btnText?: string;
  children?: React.ReactNode;
  submitButtonDisabled?: boolean;
  fullWidth?: boolean;
  maxWidth?: Breakpoint;
  cancelText?: string;
}

function DialogTitleBox(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const VegaDialog = ({
  handleClose,
  open,
  children,
  title,
  subtitle,
  btnText,
  onSubmit,
  submitButtonDisabled = false,
  fullWidth = false,
  maxWidth = 'md',
  cancelText = 'Cancel',
}: DialogProps) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitleBox id="customized-dialog-title" onClose={handleClose}>
        <Stack>
          <Typography>{title}</Typography>
          <Typography variant="caption">{subtitle}</Typography>
        </Stack>
      </DialogTitleBox>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <VegaOutlineButton
          text={cancelText}
          onClick={handleClose}
          isDanger
          disabled={submitButtonDisabled}
        />
        <VegaContainedButton
          text={btnText ?? 'Save'}
          onClick={onSubmit}
          isPrimary
          disabled={submitButtonDisabled}
        />
      </DialogActions>
    </Dialog>
  );
};

export default VegaDialog;
