import {
  KeyboardArrowRight,
  Tag,
  CheckCircleOutline,
} from '@mui/icons-material';
import { Grid, Box, Skeleton, Stack } from '@mui/material';
import _ from 'lodash';
import { useState, useEffect, ReactNode } from 'react';
import VegaLabelWrapper from '../../../components/common/VegaLabelWrapper';
import VegaTab from '../../../components/common/VegaTab';
import { GREY, COMMON, PRIMARY, BORDER_GRAY } from '../../../constants/style';
import { ColendingLoanOffersRoute } from '../../../containers/ColendingDashboardContainer';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { setApplicationsDetails } from '../../../store/common/agentApplicationsSlice';
import { useAppDispatch } from '../../../store/hook';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import BureauDetailsTab from '../../components/Bureau/BureauDetailsTab';
import LoanDetails from '../../components/LoanDetails/LoanDetails';
import PaymentDetails from '../../components/PaymentDetails/PaymentDetails';
import PersonalDetails from '../../components/PersonalDetails/PersonalDetails';
import ScripsAndCmsDetails from '../../components/ScripsAndCmsDetails/ScripsAndCmsDetails';
import {
  ApplicationReviewDetailsEnum,
  LOSEntityType,
  ApplicationReviewDetailsTypeForCompany,
  ApplicationReviewDetailsType,
} from '../../enums/applications';
import CollateralAndGurantor from '../../LMSAgent/components/ApplicationReview/components/CollateralAndGurantor/CollateralAndGurantor';
import CompanyDetails from '../../LMSAgent/components/ApplicationReview/components/CompanyDetails/CompanyDetails';
import ElectricityDetails from '../../LMSAgent/components/ApplicationReview/components/ElectricityDetails/ElectricityDetails';
import FinacialDetails from '../../LMSAgent/components/ApplicationReview/components/FinacialDetails/FinacialDetails';
import FinacialDetailsForPartnership from '../../LMSAgent/components/ApplicationReview/components/FinacialDetails/FinacialDetailsForPartnership';
import KYCDetails from '../../LMSAgent/components/ApplicationReview/components/KYCDetails/KYCDetails';
import KYCDetailsForPartnership from '../../LMSAgent/components/ApplicationReview/components/KYCDetails/KYCDetailsForPartnership';
import { ColenderService } from '../../LMSHQ/api/ColenderService';
import { ReadOnlyFormField } from '../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField';

import { LosService } from '../../onboarding/api/LosService';
import LosButton from '../../onboarding/components/common/LosButton';
import LosText from '../../onboarding/components/common/LosText';
import {
  ColendingLoan,
  CustomerApplication,
  EngagementSatus,
  LosUserType,
} from '../../onboarding/types/Los';
import { StringUtility } from '../../onboarding/utils/StringUtility';
import ConfirmColendingOffer from '../components/ConfirmColendingOffer';
import { useNestedRouter } from '../provider/NestedRouterProvider';

type Props = {
  colendingLoan?: ColendingLoan;
};

type OpenConfirmationDialogData = {
  show: boolean;
};

const INDIVIDUAL_TABS: ApplicationReviewDetailsEnum[] = [
  ApplicationReviewDetailsEnum.KYC_DETAILS,
  ApplicationReviewDetailsEnum.FINANCIAL_DETAILS,
  ApplicationReviewDetailsEnum.COLLATERAL,
  ApplicationReviewDetailsEnum.ELECTRICITY_DETAILS,
];

const NON_INDIVIDUAL_TABS: ApplicationReviewDetailsEnum[] = [
  ApplicationReviewDetailsEnum.KYC_DETAILS,
  ApplicationReviewDetailsEnum.COMPANY_DETAILS,
  ApplicationReviewDetailsEnum.FINANCIAL_DETAILS,
  ApplicationReviewDetailsEnum.COLLATERAL,
  ApplicationReviewDetailsEnum.ELECTRICITY_DETAILS,
];

const ColendingApplicationReview = ({ colendingLoan }: Props) => {
  const { setSnackbar } = useSnackbar();
  const { navigateToRoute } = useNestedRouter();
  const dispatch = useAppDispatch();
  const [application, setApplication] = useState<CustomerApplication>();
  const [selectedDetail, setSelectedDetail] = useState<string>(
    ApplicationReviewDetailsEnum.KYC_DETAILS
  );
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<OpenConfirmationDialogData>({
      show: false,
    });
  const [loading, setLoading] = useState<boolean>(false);

  const getAppicationReviewComponent = () => {
    const entityType = application?.losEntityType;
    const isIndividual = entityType == LosUserType.INDIVIDUAL;
    const isSelfEmployed = entityType == LosUserType.SELF_EMPLOYED;

    switch (selectedDetail) {
      case ApplicationReviewDetailsEnum.PERSONAL_DETAILS:
        return <PersonalDetails />;
      case ApplicationReviewDetailsEnum.PAYMENT_DETAILS:
        return <PaymentDetails />;
      case ApplicationReviewDetailsEnum.LOAN_DETAILS:
        return <LoanDetails />;
      case ApplicationReviewDetailsEnum.SCRIPS_AND_CMS_DETAILS:
        return <ScripsAndCmsDetails />;
      case ApplicationReviewDetailsEnum.BUREAU_DETAILS:
        return <BureauDetailsTab />;
      case ApplicationReviewDetailsEnum.KYC_DETAILS:
        if (isIndividual || isSelfEmployed) {
          return <KYCDetails />;
        }

        return <KYCDetailsForPartnership />;

      case ApplicationReviewDetailsEnum.COMPANY_DETAILS:
        return <CompanyDetails getAllApplicationById={getApplication} />;
      case ApplicationReviewDetailsEnum.FINANCIAL_DETAILS:
        if (isIndividual) {
          return <FinacialDetails getAllApplicationById={getApplication} />;
        }
        return (
          <FinacialDetailsForPartnership
            getAllApplicationById={getApplication}
          />
        );
      case ApplicationReviewDetailsEnum.COLLATERAL:
        return (
          <CollateralAndGurantor
            onUpdateApplication={() => {}}
            getAllApplicationById={getApplication}
          />
        );

      default:
        return <ElectricityDetails getAllApplicationById={getApplication} />;
    }
  };

  const canShowActionButtons = () => {
    const isPending =
      colendingLoan?.engagementStatus == EngagementSatus.PENDING;
    return isPending;
  };

  async function getApplication() {
    const applicationId = colendingLoan?.applicationId;
    if (!applicationId) return;
    try {
      setLoading(true);
      const response = await LosService.getApplicationById(applicationId);
      dispatch(setApplicationsDetails(response));
      setApplication(response);
    } catch (error) {
      console.error(error);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function approveColendingOffer() {
    try {
      const id = colendingLoan?.id;
      if (!id) {
        console.error('Colending Loan Offer id is undefined');
        return;
      }
      const response = await ColenderService.updateColendingLoanOfferStatus({
        colendingLoanId: id,
        status: 'ACCEPTED',
      });
      navigateToRoute(ColendingLoanOffersRoute.LoanOffers);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  async function rejectColendingOffer() {
    try {
      const id = colendingLoan?.id;
      if (!id) {
        console.error('Colending Loan Offer id is undefined');
        return;
      }
      const response = await ColenderService.updateColendingLoanOfferStatus({
        colendingLoanId: id,
        status: 'REJECTED',
      });
      navigateToRoute(ColendingLoanOffersRoute.LoanOffers);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  const getRequiredLoanAmount = () => {
    const approvedLoanAmount = colendingLoan?.approvedLoanAmount;
    const colendingRatio = colendingLoan?.colendingRatio;
    if (!colendingRatio || !approvedLoanAmount) return '-';
    return StringUtility.formatCurrency(
      approvedLoanAmount * (colendingRatio / 100)
    );
  };

  const getColendingRatioDisplayText = () => {
    const ratio = colendingLoan?.colendingRatio;
    var displayText = '-';
    if (ratio) {
      displayText = ratio + '%';
    }
    return displayText;
  };

  const getUserSubType = () => {
    if (application?.basicInfo?.subType) {
      return _.startCase(_.toLower(application?.basicInfo?.subType));
    }
    return '-';
  };

  useEffect(() => {
    getApplication();
  }, [colendingLoan]);

  return (
    <>
      <Grid
        container
        alignItems={'center'}
        borderBottom={`2px solid ${GREY.grey}`}
        sx={{
          p: 2,
        }}
      >
        <Grid item display={'flex'} alignItems={'center'} xs={12} sm={6}>
          <Box
            className="font-aspekta-500"
            sx={{ cursor: 'pointer' }}
            color={COMMON.light}
            fontSize={12}
            onClick={() => {
              navigateToRoute(ColendingLoanOffersRoute.LoanOffers);
            }}
          >
            Applications
          </Box>
          <KeyboardArrowRight sx={{ mx: 2, fontSize: 14 }} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <LosText
              className="font-aspekta-500"
              display={'flex'}
              fontSize={12}
              color={COMMON.light}
            >
              Application id
            </LosText>
            {loading ? (
              <Skeleton width="100px" />
            ) : (
              <VegaLabelWrapper
                width="100px"
                text={application?.id ?? ''}
                color="purple"
                icon={<Tag fontSize="small" />}
              />
            )}
          </Box>
        </Grid>
        <Grid
          item
          display={canShowActionButtons() ? 'flex' : 'none'}
          justifyContent={'end'}
          xs={12}
          sm={6}
          spacing={2}
        >
          <Stack direction={'row'} spacing={'1rem'}>
            <LosButton
              text="Approve"
              startIcon={<CheckCircleOutline />}
              onClick={() => {
                setOpenConfirmationDialog({
                  show: true,
                });
              }}
            />
            <LosButton
              text="Reject"
              variant="outlined"
              color="error"
              onClick={rejectColendingOffer}
            />
          </Stack>
        </Grid>
      </Grid>
      <Box
        borderBottom={`2px solid ${GREY.grey}`}
        sx={{ p: 2, bgcolor: 'white' }}
      >
        <Grid container alignItems={'start'}>
          <Grid item width={'180px'} px={2}>
            <ReadOnlyFormField
              title="Application Id"
              description={application?.id}
              loading={loading}
            />
          </Grid>
          <Grid item width={'150px'} px={2}>
            <ReadOnlyFormField
              title="Name"
              description={application?.customerName}
              loading={loading}
            />
          </Grid>
          <Grid item width={'150px'} px={2}>
            <ReadOnlyFormField
              title="User Type"
              description={getUserSubType()}
              loading={loading}
            />
          </Grid>
          <Grid item width={'150px'} px={2}>
            <ReadOnlyFormField
              title="Loan Type"
              description={application?.basicInfo?.loanType}
              loading={loading}
            />
          </Grid>
          <Grid item width={'150px'} px={2}>
            <ReadOnlyFormField
              title="Tenure"
              description={`${application?.basicInfo?.tenure ?? '-'} Months`}
              loading={loading}
            />
          </Grid>
          <Grid item width={'180px'} px={2}>
            <ReadOnlyFormField
              title="Required Loan Amount"
              description={getRequiredLoanAmount()}
              loading={loading}
            />
          </Grid>
          <Grid item width={'180px'} px={2}>
            <ReadOnlyFormField
              title="Ratio"
              description={getColendingRatioDisplayText()}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <VegaTab
          width={'auto'}
          placement="vertical"
          color={PRIMARY.darkBlue}
          tabData={
            application?.losEntityType !== LOSEntityType.INDIVIDUAL
              ? NON_INDIVIDUAL_TABS
              : INDIVIDUAL_TABS
          }
          selected={selectedDetail}
          onSelect={(select) => setSelectedDetail(select)}
        />
        <Box
          sx={{
            p: 2,
            border: BORDER_GRAY,
            bgcolor: 'white',
            borderRadius: '10px',
          }}
        >
          {loading ? <Skeleton height={400} /> : getAppicationReviewComponent()}
        </Box>
      </Box>

      <ConfirmColendingOffer
        open={openConfirmationDialog.show}
        onClose={function (): void {
          setOpenConfirmationDialog({
            show: false,
          });
        }}
        onConfirm={approveColendingOffer}
        applicationId={application?.id}
      />
    </>
  );
};

export default ColendingApplicationReview;
