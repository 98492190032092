import {
  IApplicationsForSolfinProps,
  IPreDisburseApplicationProps,
} from '../../types/application';

import {
  LmsAgentApplicationRoute,
  useLmsAgentRouter,
} from '../../LMSAgent/Provider/LmsAgentRouter';
import ApplicationReview, {
  ViewSource,
} from '../../LMSAgent/components/ApplicationReview/ApplicationReview';

import ListOfApplicationForHQ from '../components/AllApplication/ListOfApplicationForHQ';

export interface IApplicationsProps {
  loading: boolean;
  page: number;
  totalPages: number;
  applicationData: IApplicationsForSolfinProps[];
}
export interface IPre_DisburseApplicationsProps {
  loading: boolean;
  page: number;
  totalPages: number;
  applicationData: IPreDisburseApplicationProps[];
}

const ApplicationDashboardForHQ = () => {
  const { selectedRoute, navigateToApplicationHome } = useLmsAgentRouter();

  if (selectedRoute == LmsAgentApplicationRoute.Applications) {
    return <ListOfApplicationForHQ />;
  }
  if (selectedRoute == LmsAgentApplicationRoute.ApplicationReview) {
    return <ApplicationReview viewSource={ViewSource.HqApplications} />;
  }

  navigateToApplicationHome();

  return <></>;
};

export default ApplicationDashboardForHQ;
