import { EditOutlined } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import VegaHeader from '../../../../../../components/common/VegaHeader';
import VegaOutlineButton from '../../../../../../components/common/VegaOutlineButton';
import { BORDER_GRAY, PURPLE } from '../../../../../../constants/style';
import {
  getApplicationsDetails,
  setApplicationsDetails,
} from '../../../../../../store/common/agentApplicationsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hook';

import { documentTypeEnum } from '../../../../../enums/applications';
import {
  IDocumentationDetailsProps,
  IFinacialDocumentProps,
} from '../../../../../types/application';
import VegaTextButton from '../../../../../../components/common/VegaTextButton';
import { useSnackbar } from '../../../../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../../../../utils/api';
import Media from './Media';
import _ from 'lodash';
import LosAutoCompleteField, {
  LosAutoCompleteOption,
} from '../../../../../onboarding/components/common/LosAutoCompleteField';
import {
  ElectricityBoard,
  LosDocumentType,
} from '../../../../../onboarding/types/Los';
import { LosService } from '../../../../../onboarding/api/LosService';
import LosFormInputField from '../../../../../onboarding/components/common/LosFormInputField';
import { ReadOnlyFormField } from '../../../../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField';
import LosTextField from '../../../../../onboarding/components/common/LosTextField';

interface IProps {
  getAllApplicationById: () => void;
}

export interface IFilesProps {
  documentType: string;
  documentName: string;
  documentCategory: string;
  id: string;
  file: any;
}

const default_Electricity = [
  {
    documentType: documentTypeEnum.ELECTRICITY_BILL_LAST_MONTH,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.ELECTRICITY_BILL_SECOND_LAST_MONTH,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.ELECTRICITY_BILL_THIRD_LAST_MONTH,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
];

const ElectricityDetails = ({ getAllApplicationById }: IProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { applicationDetails, isApplicationForHQ } = useAppSelector(
    getApplicationsDetails
  );
  const [electrictyBoards, setElectricityBoards] = useState<ElectricityBoard[]>(
    []
  );
  const [loadingBoards, setLoadingBoards] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [isClear, setIsClear] = useState<boolean>(false);
  const { setSnackbar } = useSnackbar();
  const [filesData, setFilesData] = useState<IFilesProps[]>([]);

  function handleElectricityBoardSelection(value: LosAutoCompleteOption) {
    const newCompanyObj = {
      ...applicationDetails.documentationDetails,
      electricityBoard: value?.label ?? '',
      code: value?.value ?? '',
    };
    const modifyApplication = {
      ...applicationDetails,
      documentationDetails: newCompanyObj,
    };
    dispatch(setApplicationsDetails(modifyApplication));
  }

  const handleDocumetationDetails = (
    name: keyof IDocumentationDetailsProps,
    value: string | number | FileList
  ) => {
    const newCompanyObj = {
      ...applicationDetails.documentationDetails,
      [name]: value,
    };
    const modifyApplication = {
      ...applicationDetails,
      documentationDetails: newCompanyObj,
    };
    dispatch(setApplicationsDetails(modifyApplication));
  };

  async function getElectricityBoards() {
    try {
      setLoadingBoards(true);
      const response = await LosService.getElectrictyBoardList();
      response.sort((a, b) => {
        const serviceProviderA = a.serviceProvider.toUpperCase();
        const serviceProviderB = b.serviceProvider.toUpperCase();

        if (serviceProviderA < serviceProviderB) {
          return -1;
        }
        if (serviceProviderA > serviceProviderB) {
          return 1;
        }
        return 0;
      });
      setElectricityBoards(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBoards(false);
    }
  }

  // const addDocs = async (name: string, formData: FormData) => {
  //   try {
  //     const response = await addDocumentationForAgent(
  //       applicationDetails.id,
  //       name,
  //       formData
  //     );
  //     dispatch(setApplicationsDetails(response.data));
  //   } catch (error) {
  //     setSnackbar(getErrorMessageFromErrorObj(error), 'error');
  //   }
  // };

  // const updateDocs = async (name: string, formData: FormData) => {
  //   try {
  //     const response = await updateDocumentForAgent(
  //       applicationDetails.id,
  //       name,
  //       formData
  //     );
  //     dispatch(setApplicationsDetails(response.data));
  //   } catch (error) {
  //     setSnackbar(getErrorMessageFromErrorObj(error), 'error');
  //   }
  // };
  const updateDocs = async (name: string, file: File) => {
    try {
      const response = await LosService.saveFinancialData({
        applicationId: applicationDetails.id,
        file: file,
        documentType: name,
      });
      dispatch(setApplicationsDetails(response));
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  };
  async function saveElectricityDataWithDocument(data: {
    applicationId: string;
    file?: File;
    documentType?: LosDocumentType;
    electricityBoard: string;
    electricityBillNo: string;
    code: string;
  }) {
    try {
      const response = await LosService.saveElectrictyBillData({
        applicationId: data.applicationId,
        file: data.file,
        documentType: data.documentType,
        electricityBillNo: data.electricityBillNo,
        electricityBoard: data.electricityBoard,
        code: data.code,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  const handleImage = (
    name: string,
    value: File,
    index: number,
    documentType: LosDocumentType
  ) => {
    const updatedData = filesData.reduce((pre, curr) => {
      // file.documentType === documentType ? file.file = value : file
      return [
        ...pre,
        {
          ...curr,
          file: curr.documentType === documentType ? value : curr.file,
          documentName:
            curr.documentType === documentType ? value.name : curr.documentName,
          id: curr.id === '' ? new Date().getTime().toString() : curr.id,
        },
      ];
    }, [] as IFilesProps[]);

    // const fileObj = { ...filesData[index] };
    // const newObj = {
    //   ...fileObj,
    //   documentType: name,
    //   file: value,
    //   documentName: value.name,
    //   id: fileObj.id === '' ? new Date().getTime().toString() : fileObj.id,
    // } as IFilesProps;
    // const copy = [...filesData];
    // copy.splice(index, 1, newObj);
    setFilesData(updatedData);
  };
  const isFiles = filesData.some((file: IFilesProps) => file.id === '');

  // const uploadFiles = async () => {
  //   for (let index = 0; index < filesData.length; index++) {
  //     const element = filesData[index];
  //     const formData = new FormData();
  //     formData.append('file', element.file);
  //     if (applicationDetails.documentationDetails !== null) {
  //       const exists = applicationDetails.documentationDetails?.documents?.some(
  //         (document) => document.documentType === element.documentType
  //       );
  //       if (exists) {
  //         if (element.file) {
  //           const response = await saveElectricityDataWithDocument({
  //             applicationId: applicationDetails.id,
  //             file: element.file,
  //             documentType: element.documentType as LosDocumentType,
  //             electricityBillNo:
  //               applicationDetails.documentationDetails.electricityBillNo || '',
  //             electricityBoard:
  //               applicationDetails.documentationDetails.electricityBoard || '',
  //             code: applicationDetails.documentationDetails.code ?? '',
  //           });

  //           dispatch(setApplicationsDetails(response));
  //         }
  //       } else {
  //         formData.append(
  //           'electricityBillNo',
  //           applicationDetails.documentationDetails.electricityBillNo ?? ''
  //         );
  //         formData.append(
  //           'electricityBoard',
  //           applicationDetails.documentationDetails.electricityBoard ?? ''
  //         );
  //         formData.append(
  //           'code',
  //           applicationDetails.documentationDetails.code ?? ''
  //         );

  //         element.file && (await addDocs(element.documentType, formData));
  //       }
  //     }
  //   }
  //   setSnackbar('Electricity bill uploaded successfully', 'success');
  // };

  const uploadFiles = async () => {
    for (let index = 0; index < filesData.length; index++) {
      const element = filesData[index];
      // (await updateDocs(element.documentType, element.file));
      if (element.file) {
        const response = await saveElectricityDataWithDocument({
          applicationId: applicationDetails.id,
          file: element.file,
          documentType: element.documentType as LosDocumentType,
          electricityBillNo:
            applicationDetails?.documentationDetails?.electricityBillNo || '',
          electricityBoard:
            applicationDetails.documentationDetails?.electricityBoard || '',
          code: applicationDetails.documentationDetails?.code ?? '',
        });

        dispatch(setApplicationsDetails(response));
      }
    }
    setSnackbar('Electricity bill uploaded successfully', 'success');
  };
  const onUploadFiles = async () => {
    await uploadFiles();
  };

  const getFileData = async (documents: IFinacialDocumentProps[]) => {
    let fileArr: IFilesProps[] = [];
    let newFileArr: string[] = [
      documentTypeEnum.ELECTRICITY_BILL_LAST_MONTH,
      documentTypeEnum.ELECTRICITY_BILL_SECOND_LAST_MONTH,
      documentTypeEnum.ELECTRICITY_BILL_THIRD_LAST_MONTH,
    ];
    await documents.map(
      async (document: IFinacialDocumentProps, index: number) => {
        const newObj = {
          ...document,
          file: null,
        } as IFilesProps;
        fileArr.push(newObj);
        newFileArr = [
          ...newFileArr.filter(
            (newFile: string) => newFile !== document.documentType
          ),
        ];
      }
    );
    newFileArr.forEach((eleme) => {
      fileArr.push({
        documentType: eleme,
        file: null,
        documentName: '',
        id: '',
        documentCategory: '',
      });
    });
    setFilesData([...fileArr]);
  };

  const saveElectricityData = async () => {
    await onUploadFiles();
  };

  useEffect(() => {
    if (applicationDetails?.documentationDetails !== null) {
      if (applicationDetails?.documentationDetails?.documents?.length) {
        const documents: IFinacialDocumentProps[] =
          applicationDetails.documentationDetails.documents;

        getFileData(documents);
      }
    } else {
      setFilesData([...default_Electricity]);
    }
  }, [isClear]);

  useEffect(() => {
    getElectricityBoards();
  }, []);

  return (
    <>
      <Box sx={{}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4} xl={3}>
            {/* {isEdit === true && 
            (
              <LosFormInputField label={'Electricity Board'}>
                <LosAutoCompleteField
                  options={electricityBoardOptions(electrictyBoards)}
                  onChange={(_, newValue: LosAutoCompleteOption) => {
                    handleElectricityBoardSelection(newValue);
                  }}
                  value={
                    applicationDetails.documentationDetails?.electricityBoard ??
                    ''
                  }
                  loading={loadingBoards}
                />
              </LosFormInputField>
            )} */}

            {/* {isEdit === false && (
              <ReadOnlyFormField
                title="Electricity Board"
                description={
                  applicationDetails.documentationDetails?.electricityBoard ??
                  undefined
                }
              />
            )} */}
            <ReadOnlyFormField
              title="Electricity Board"
              description={
                applicationDetails.documentationDetails?.electricityBoard ??
                undefined
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} xl={3}>
            {/* {isEdit == true && (
              <LosFormInputField label={'Electricity Account Number'}>
                <LosTextField
                  value={
                    applicationDetails.documentationDetails?.electricityBillNo
                  }
                  onChange={(e) =>
                    handleDocumetationDetails(
                      'electricityBillNo',
                      e.target.value
                    )
                  }
                />
              </LosFormInputField>
            )} */}

            {/* {isEdit == false && (
              <ReadOnlyFormField
                title="Electricity Account Number"
                description={
                  applicationDetails.documentationDetails?.electricityBillNo ??
                  undefined
                }
              />
            )} */}
            <ReadOnlyFormField
              title="Electricity Account Number"
              description={
                applicationDetails.documentationDetails?.electricityBillNo ??
                undefined
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box py={2} sx={{ borderBottom: BORDER_GRAY }}>
        <VegaHeader text="Electricity bill" />
        <Grid container spacing={2}>
          {filesData
            // .sort((a, b) => {
            //   if (a.documentType < b.documentType) return -1;
            //   if (a.documentType > b.documentType) return 1;
            //   return 0;
            // })
            .map((file: IFilesProps, index: number) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <Media
                  file={file}
                  handleImage={handleImage}
                  isEdit={isEdit}
                  index={index}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
      <Box textAlign={'end'} sx={{ mt: 2 }}>
        {isEdit ? (
          <>
            <VegaOutlineButton
              text={'Save'}
              isPurple
              disabled={isFiles}
              onClick={async () => {
                await saveElectricityData();
                setIsEdit(!isEdit);
              }}
            />
            <VegaTextButton
              text={'Cancel'}
              color={PURPLE.dark}
              onClick={() => {
                setIsEdit(!isEdit);
                setIsClear(!isClear);
                setFilesData([...default_Electricity]);
                getAllApplicationById();
              }}
            />
          </>
        ) : (
          !isApplicationForHQ &&
          (applicationDetails.status === 'IN_PROGRESS' ||
            applicationDetails.status === 'PENDING') && (
            <VegaOutlineButton
              text={'Edit'}
              startIcon={<EditOutlined />}
              isPurple
              onClick={() => setIsEdit(!isEdit)}
            />
          )
        )}
      </Box>
    </>
  );
};

export default ElectricityDetails;

const electricityBoardOptions = (boards: ElectricityBoard[]) =>
  boards.map((item) => {
    const option: LosAutoCompleteOption = {
      value: item.code,
      label: _.startCase(_.toLower(item.serviceProvider)) + ' - ' + item.code,
    };
    return option;
  });
