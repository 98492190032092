import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { COMMON, GREY } from '../../../constants/style';

interface IProps {
  page: number;
  totalPage: number;
  privious: (page: number) => void;
  next: (page: number) => void;
}

const MobileViewPagination = ({ page, totalPage, privious, next }: IProps) => {
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
      }}
    >
      <IconButton disabled={page + 1 === 1} onClick={() => privious(page)}>
        <ArrowBackIos sx={{ fontSize: 12 }} />
      </IconButton>
      <Typography
        className="font-aspekta-600"
        color={COMMON.light}
        fontSize={10}
      >
        Page {page + 1} of {totalPage}
      </Typography>
      <IconButton disabled={page + 1 === totalPage} onClick={() => next(page)}>
        <ArrowForwardIos sx={{ fontSize: 12 }} />
      </IconButton>
    </Box>
  );
};

export default MobileViewPagination;
