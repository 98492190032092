import { useSnackbar } from '../../../providers/SnackbarProvider'
import { getErrorMessageFromErrorObj } from '../../../utils/api'
import { EncryptionUtils } from '../../../utils/encryptionUtils'
import { useAppConfig } from '../../config/AppConfigProvider'
import { OtpService } from '../api/otp'

export const useMobileOtpVerification = () => {
  const { setSnackbar } = useSnackbar()
  const { privateKey } = useAppConfig()

  async function generateOtp(data: {
    mobileNumber: string
    programId: string
  }) {
    try {
      const response = await OtpService.generateOTP({
        mobileNo: data.mobileNumber,
        programId: data.programId,
      })
    } catch (error) {
      console.error(error)
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      throw error
    }
  }

  async function verifyOtp(data: {
    mobileNumber: string
    otp: string
    programId: string
  }): Promise<boolean> {
    try {
      const response = await OtpService.verifyOTP({
        mobileNo: data.mobileNumber,
        otp: data.otp,
        programId: data.programId,
      })

      const decryptedResult = EncryptionUtils.decrypt(response, privateKey)
      const jsonResponse = JSON.parse(decryptedResult)
      const result = jsonResponse.result

      // const resultTimeStamp = jsonResponse.timeStamp.replace('[UTC]', '');
      // const timestamp = new Date(resultTimeStamp);
      // const currentTimestamp = Date.now();
      // const timeDifferenceInMs = currentTimestamp - timestamp.getTime();

      // const fiveMinuteInMs = 5 * 60 * 1000;

      // if (timeDifferenceInMs > fiveMinuteInMs) {
      //   console.error(
      //     'The timestamp is greater than 5 minute from the current timestamp.');
      //   throw new Error('OTP verification Failed');
      // }

      return result === 'SUCCESS'
    } catch (error) {
      console.error(error)
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
      return false
    }
  }

  return {
    generateOtp,
    verifyOtp,
  }
}
