import { Grid, InputAdornment } from '@mui/material';
import _ from 'lodash';
import LosFormInputField from '../../components/common/LosFormInputField';
import LosSelect, { LosSelectOption } from '../../components/common/LosSelect';
import LosText from '../../components/common/LosText';
import LosTextField from '../../components/common/LosTextField';
import { useLos } from '../../providers/LosProvider';
import { GuranteeType, GurantorFormData } from '../../types/Los';

type Props = {
  formData: Partial<GurantorFormData>;
  errors: Partial<GurantorFormData>;
  handleUpdate: (type: string, value: string) => void;
};
enum InputType {
  NAME = 'NAME',
  PAN_NUMBER = 'PAN_NUMBER',
  EMAIL = 'EMAIL',
  LOAN_TYPE = 'LOAN_TYPE',
  RELATIONSHIP = 'RELATIONSHIP',
  TYPE_OF_GUARNTEE = 'TYPE_OF_GUARNTEE',
  VALUE_OF_GUARNTEE = 'VALUE_OF_GUARNTEE',
}
function AddGurantorForm({ formData, errors, handleUpdate }: Props) {
  const { customerApplication, relationTypes } = useLos();
  const getLoanAmount = () => {
    const amount = customerApplication?.basicInfo?.amount ?? 0;
    return amount;
  };

  const isPanNumberInputValid = () => {
    if (!!errors.panCard) return false;
    return (formData?.panCard ?? []).length > 0;
  };

  const isEmailValid = () => {
    if (!!errors.email) return false;
    return (formData?.email ?? []).length > 0;
  };

  return (
    <Grid container rowSpacing={'1.5rem'}>
      <Grid item xs={12}>
        <LosFormInputField label="Name of the Guarantor">
          <LosTextField
            value={formData.name}
            onChange={(e) => handleUpdate(InputType.NAME, e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
          />
        </LosFormInputField>
      </Grid>
      <Grid item xs={12}>
        <LosFormInputField label="Relationship of the Guarantor">
          <LosSelect
            value={formData.relationShip}
            options={relationshipType(relationTypes)}
            onChange={(e) =>
              handleUpdate(InputType.RELATIONSHIP, e.target.value as string)
            }
            error={!!errors.relationShip}
          />
        </LosFormInputField>
      </Grid>
      <Grid item xs={12}>
        <LosFormInputField label="Email">
          <LosTextField
            value={formData.email}
            onChange={(e) => handleUpdate(InputType.EMAIL, e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
            isValid={isEmailValid()}
          />
        </LosFormInputField>
      </Grid>
      <Grid item xs={12}>
        <LosFormInputField label="Pan Card">
          <LosTextField
            value={formData.panCard}
            onChange={(e) =>
              handleUpdate(InputType.PAN_NUMBER, e.target.value.toUpperCase())
            }
            error={!!errors.panCard}
            helperText={errors.panCard}
            isValid={isPanNumberInputValid()}
          />
        </LosFormInputField>
      </Grid>
      <Grid item xs={12}>
        <LosFormInputField label="Type of Guarantee">
          <LosSelect
            value={formData.guaranteeType}
            options={guarnteeType()}
            onChange={(e) =>
              handleUpdate(InputType.TYPE_OF_GUARNTEE, e.target.value as string)
            }
            error={!!errors.guaranteeType}
          />
        </LosFormInputField>
      </Grid>
      <Grid item xs={12}>
        <LosFormInputField
          label={`Value of Guarantee (Max Upto ${getLoanAmount()})`}
        >
          <LosTextField
            value={formData.valueOfGuarantee}
            onChange={(e) => {
              handleUpdate(InputType.VALUE_OF_GUARNTEE, e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LosText text="₹" />
                </InputAdornment>
              ),
            }}
          />
        </LosFormInputField>
      </Grid>
    </Grid>
  );
}

export default AddGurantorForm;

const relationshipType = (relationType: string[]) =>
  relationType.map((item) => {
    const option: LosSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const guarnteeType = () =>
  Object.values(GuranteeType).map((item) => {
    const option: LosSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
