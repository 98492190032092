import { Grid } from '@mui/material';
import LosText from '../../components/common/LosText';
import LosFormContainer from '../../components/LosFormContainer';

import { GiftIcon } from '../../icons/LosIcons';

function LoanDispursed() {
  return (
    <LosFormContainer>
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          rowGap: '0.5rem',
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <CongratulationsContainer />
          </Grid>
        </Grid>
      </div>
    </LosFormContainer>
  );
}

export default LoanDispursed;

const CongratulationsContainer = () => {
  return (
    <div
      style={{
        borderRadius: '0.375rem',
        background: '#E7EDFC',
        paddingLeft: '0.75rem',
        paddingRight: '0.75rem',
        paddingTop: '1.25rem',
        paddingBottom: '1.25rem',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '0.5rem',
        }}
      >
        <GiftIcon />
        <LosText
          text="Congratulations!"
          style={{
            color: '#1047DC',
            textAlign: 'center',
            fontSize: '1.0625rem',
            lineHeight: '120%',
            fontWeight: 600,
          }}
        />
        <LosText
          text="Your loan has been Dispursed."
          style={{
            color: '#1047DC',
            textAlign: 'center',
            fontSize: '0.75rem',
            lineHeight: '130%',
          }}
        />
      </div>
    </div>
  );
};
