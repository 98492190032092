import { Grid, Stack } from '@mui/material';
import LosText from '../common/LosText';
import { InfoCard, InfoTitle, InfoDescription } from './InfoCard';

type Props = {
  loanAmountLeft?: string;
  loanTenureLeft?: string;
  nextEmiDate?: string;
  loading?: boolean;
};

function LoanStatusCard({
  loanAmountLeft,
  loanTenureLeft,
  nextEmiDate,
  loading = false,
}: Props) {
  return (
    <div>
      <Title text="Loan Status" />
      <SubTitle text="Get an idea about your loan details" />
      <InfoCard>
        <Grid container rowSpacing={'2rem'}>
          <Grid item xs={6} sm={3} md={3}>
            <Stack
              style={{
                padding: '0.5rem',
              }}
            >
              <InfoTitle text={'Loan Amount Left'} />
              <InfoDescription text={loanAmountLeft} />
            </Stack>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Stack
              style={{
                padding: '0.5rem',
              }}
            >
              <InfoTitle text={'Loan Tenure Left'} />
              <InfoDescription text={loanTenureLeft} />
            </Stack>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Stack
              style={{
                padding: '0.5rem',
              }}
            >
              <InfoTitle text={'Last EMI Date'} />
              <InfoDescription text={nextEmiDate} />
            </Stack>
          </Grid>
        </Grid>
      </InfoCard>
    </div>
  );
}

export default LoanStatusCard;

const Title = (data: { text: string }) => {
  return (
    <LosText
      text={data.text}
      style={{
        color: '#1B1D22',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '140%',
        marginBottom: '0.5rem',
      }}
    />
  );
};

const SubTitle = (data: { text: string }) => {
  return (
    <LosText
      text={data.text}
      style={{
        color: '#676B76',
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: '130%',
        marginBottom: '1rem',
      }}
    />
  );
};
