import { Close } from '@mui/icons-material'
import { Dialog, Stack, IconButton, Grid, InputAdornment } from '@mui/material'
import { useState } from 'react'
import LOSDateField from '../../../../components/LOSDateField'
import LosButton from '../../../../onboarding/components/common/LosButton'
import LosFormInputField from '../../../../onboarding/components/common/LosFormInputField'
import LosText from '../../../../onboarding/components/common/LosText'
import LosTextField from '../../../../onboarding/components/common/LosTextField'

type Props = {
  open: boolean
  loanId?: string
  onClose: () => void
  onSubmit: (
    loanId: string,
    amount: string,
    utrNumber: string,
    date: string
  ) => void
}
function PayEmiDialog({ open, loanId, onClose, onSubmit }: Props) {
  const [date, setDate] = useState<string>(new Date().toISOString())
  const [utrNumber, setUtrNumber] = useState<string>('')
  const [amount, setAmount] = useState<string>('')

  const isInputValid = () => {
    let isDateValid = date.length > 0
    let isUtrValid = utrNumber.length > 0
    let isAmountValid = amount.length > 0
    let isAmountNumberValid = Number(amount) > 0
    return isDateValid && isUtrValid && isAmountValid && isAmountNumberValid
  }

  function onPayNowClick() {
    if (isInputValid() == false) return
    if (!loanId) return
    let hasDate = date.length > 0
    if (hasDate == false) return
    const dateObject = new Date(date)
    onSubmit(loanId, amount, utrNumber, dateObject.toISOString())
    setAmount('')
    setDate('')
    setUtrNumber('')
    onClose()
  }

  function handeDateChange(e: any) {
    if (!e) return
    const dateString = e.$d
    if (!dateString || dateString == 'Invalid Date') return
    if (dateString.length <= 0) return
    const dateObject = new Date(dateString)
    setDate(dateObject.toISOString())
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      sx={{ borderRadius: '20px' }}
      onClose={onClose}
      open={open}
    >
      <div
        style={{
          backgroundColor: 'rgba(250, 250, 250, 1)',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent='space-between'
          alignItems={'center'}
          style={{
            paddingTop: '1.25rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <LosText text='Register Payment' />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <div
          style={{
            border: '1px solid #E1E4EB',
            padding: '1rem',
            margin: '1rem',
            borderRadius: '0.75rem',
            background: 'white',
          }}
        >
          <Stack spacing={'1rem'}>
            <LosText text='Payment Details' />
            <div>
              <Grid container spacing={'1rem'}>
                <Grid item xs={4}>
                  <LosFormInputField label='Amount'>
                    <LosTextField
                      value={amount}
                      onChange={e => {
                        setAmount(e.target.value)
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <LosText text='₹' />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </LosFormInputField>
                </Grid>
                <Grid item xs={4}>
                  <LosFormInputField label='Utr Number'>
                    <LosTextField
                      onChange={e => setUtrNumber(e.target.value)}
                    />
                  </LosFormInputField>
                </Grid>
                <Grid item xs={4}>
                  <LosFormInputField label='Payment Date'>
                    <LOSDateField
                      height='40px'
                      value={date}
                      onChange={e => {
                        handeDateChange(e)
                      }}
                      maxDate={new Date()}
                    />
                  </LosFormInputField>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </div>
      </div>
      <Stack
        direction={'row'}
        justifyContent='end'
        alignItems={'center'}
        style={{ padding: '1rem' }}
      >
        <LosButton
          text='Pay Now'
          size='small'
          onClick={onPayNowClick}
          disabled={isInputValid() == false}
        />
      </Stack>
    </Dialog>
  )
}

export default PayEmiDialog
