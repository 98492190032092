import React from 'react';
import { Typography } from '@mui/material';
import VegaHeader from '../../components/common/VegaHeader';
import { VegaTextField } from '../../components/common';
import { toLowerCase } from '../../constants/commonFunction';
import { COMMON } from '../../constants/style';

interface IProps {
  title: string;
  value: string | number;
  displayText?: string;
  name?: string;
  type?: string;
  errorText?: string;
  startAdornment?: React.ReactNode | null;
  endAdornment?: React.ReactNode | null;
  isEdit: boolean;
  disabled?: boolean;
  error?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const LOSTextFieldWrapper = ({
  title,
  value,
  isEdit,
  name,
  onChange,
  type,
  startAdornment,
  endAdornment,
  errorText,
  disabled = false,
  error = false,
  displayText,
}: IProps) => {
  return (
    <>
      <VegaHeader text={title} />
      {isEdit ? (
        <VegaTextField
          // isFixedHeight
          value={value ?? ''}
          onChange={onChange}
          key={name}
          type={type ?? 'text'}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          placeholder={`${toLowerCase(title)}`}
          disabled={disabled}
          helperText={errorText}
          error={error}
        />
      ) : (
        <>
          <Typography
            className="font-aspekta-400"
            color={COMMON.darkGray}
            fontSize={12}
            display={'flex'}
            alignItems={'center'}
          >
            {startAdornment && (
              <>
                {startAdornment} {displayText ?? value}
              </>
            )}
            {endAdornment && (
              <>
                {displayText ?? value} {endAdornment}
              </>
            )}
            {!startAdornment && !endAdornment && (displayText ?? value)}
          </Typography>
        </>
      )}
    </>
  );
};

export default LOSTextFieldWrapper;
