import { Grid, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSnackbar } from '../../../../providers/SnackbarProvider'
import { getApplicationsDetails } from '../../../../store/common/agentApplicationsSlice'
import { useAppSelector } from '../../../../store/hook'
import { getErrorMessageFromErrorObj } from '../../../../utils/api'
import { LosService } from '../../api/LosService'
import LosAppBar from '../../components/LosAppBar'
import { LosFormButtonView } from '../../components/LosFormButton'
import LosFormContainer from '../../components/LosFormContainer'
import LosText from '../../components/common/LosText'
import { useLos } from '../../providers/LosProvider'
import { UserDataDto } from '../../types/Los'
import CreateEstampUrlConfirmationDialog from './CreateEstampUrlConfirmationDialog'
import UserDetailsEditCard from './UserDetailsEditCard'

function AgreementSigning() {
  const [userDetails, setUserDetails] = useState<UserDataDto[]>()
  const [
    openAgreementSigningConformationDialog,
    setOpenAgreementSigningConformationDialog,
  ] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { applicationDetails } = useAppSelector(getApplicationsDetails)
  const { customerApplication, navigateToNextStep } = useLos()
  const { setSnackbar } = useSnackbar()

  const getUserDetailsApi = async () => {
    try {
      setLoading(true)
      if (!customerApplication) {
        setLoading(false)
        return
      }
      const response = await LosService.getUserDetails(customerApplication?.id)
      const users = getUsers(response)
      setUserDetails(users)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  async function onProceedClick() {
    try {
      setOpenAgreementSigningConformationDialog(false)
      setLoading(true)
      if (!customerApplication) {
        setLoading(false)
        return
      }
      await LosService.createEstampUrl(customerApplication.id)
      await LosService.updateNextStep(customerApplication.id)
      navigateToNextStep()
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  function onSubmitClick() {
    setOpenAgreementSigningConformationDialog(true)
  }

  function onCancelClick() {
    setOpenAgreementSigningConformationDialog(false)
  }

  useEffect(() => {
    getUserDetailsApi()
  }, [])

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <Stack sx={{ mb: 2 }}>
            <LosFormButtonView
              text='Confirm And Send Link'
              onClick={onSubmitClick}
              loading={loading}
            />
            <LosText
              text='The link will expire 14 days after the day of sending.'
              style={{
                fontFamily: 'Aspekta',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '17px',
                letterSpacing: '0em',
                textAlign: 'center',
              }}
            />
            <LosText
              text='Please contact promoters internally to avoid stamp duty.'
              style={{
                fontFamily: 'Aspekta',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '17px',
                letterSpacing: '0em',
                textAlign: 'center',
              }}
            />
          </Stack>
        )
      }}
      renderAppBar={() => {
        return (
          <LosAppBar currentStepLabel={'Agreement Signing'} hideProgressBar />
        )
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <Stack spacing={'0.5rem'}>
            <LosText
              text='Confirm promoters details to complete eSign process'
              style={{
                fontSize: '0.9rem',
                lineHeight: '100%',
                fontWeight: 600,
              }}
            />
            <LosText
              text='They will receive a link via the mobile number and email mentioned below'
              style={{
                color: 'rgba(94, 94, 94, 1)',
                fontSize: '0.76rem',
                fontWeight: 500,
                lineHeight: '140%',
              }}
            />
          </Stack>
          <Grid item xs={12} sx={{ paddingTop: 2 }}>
            <Grid container rowSpacing={'1rem'}>
              {userDetails?.map((user, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <UserDetailsEditCard
                      user={user?.userAadharData[0]?.userBasicInfo}
                      index={index}
                      userId={user.userAadharData[0]?.aadharMetaData.customerId}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CreateEstampUrlConfirmationDialog
        open={openAgreementSigningConformationDialog}
        onClose={function (): void {
          setOpenAgreementSigningConformationDialog(false)
        }}
        onSubmit={onCancelClick}
        description={
          'Are you sure you confirmed the promoters mobile & email address?'
        }
        buttonText={'No, thanks'}
        secondaryButtonText='Yes, confirmed'
        onSecondaryButtonClick={onProceedClick}
        textTypeSecondaryButton={true}
      />
    </LosFormContainer>
  )
}

export default AgreementSigning
function getUsers(userData: UserDataDto): UserDataDto[] {
  const users: UserDataDto[] = []

  for (let i = 0; i < userData.userPanData.length; i++) {
    const user: UserDataDto = {
      companyDetailsResponse: userData.companyDetailsResponse,
      userPanData: [userData.userPanData[i]],
      userAadharData: [userData.userAadharData[i]],
      userSelfieData: [userData.userSelfieData[i]],
    }
    users.push(user)
  }

  return users
}
