import React from 'react';
import { Typography } from '@mui/material';
import { GREEN, RED, YELLOW } from './colors/fontColor';

interface ILabelProps {
  text: string;
  color: 'pending' | 'approved' | 'rejected';
}

const LOSLabel = ({ color, text }: ILabelProps) => {
  return (
    <Typography
      className="font-aspekta-600"
      fontSize={10}
      lineHeight={'12px'}
      px={'12px'}
      py={'4px'}
      borderRadius={12}
      width={'fit-content'}
      sx={{
        ...(color === 'pending'
          ? { color: YELLOW.yellow, bgcolor: YELLOW.lightyellow }
          : color === 'approved'
          ? { color: GREEN.green, bgcolor: GREEN.lightgreen }
          : { color: RED.red, bgcolor: RED.lightred }),
      }}
    >
      {text}
    </Typography>
  );
};

export default LOSLabel;
