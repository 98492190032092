import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import LosAutoCompleteField, {
  LosAutoCompleteOption,
} from '../components/common/LosAutoCompleteField';
import LosFileSelect from '../components/common/LosFileSelect';
import LosFormInputField from '../components/common/LosFormInputField';
import LosTextField from '../components/common/LosTextField';
import LosAppBar from '../components/LosAppBar';
import { LosFormButtonView } from '../components/LosFormButton';
import LosFormContainer from '../components/LosFormContainer';
import { useLos } from '../providers/LosProvider';
import { ElectricityBoard, ElectricityFormData } from '../types/Los';

enum InputType {
  BILL_PDF,
  BOARD,
  BILL_NO,
}

enum BillType {
  FirstMonth,
  SecondMonth,
  ThirdMonth,
}

function ElectricityDetails() {
  const {
    navigateToNextStep,
    saveElectricityData,
    currentStepMetaData,
    electrictyBoards,
  } = useLos();
  const [formData, setFormData] = useState<ElectricityFormData>({
    electricityBoard: '',
    electricityBillNo: '',
    electricityCode: '',
    bills: {},
  });
  const [errors, setErrors] = useState<Partial<ElectricityFormData>>({});
  const [loading, setLoading] = useState<boolean>(false);

  function handleBillPdfUpdate(type: BillType, file?: File | null) {
    switch (type) {
      case BillType.FirstMonth:
        setFormData((prev) => {
          return {
            ...prev,
            bills: { ...prev.bills, firstMonth: file },
          };
        });
        break;
      case BillType.SecondMonth:
        setFormData((prev) => {
          return {
            ...prev,
            bills: { ...prev.bills, secondMonth: file },
          };
        });
        break;
      case BillType.ThirdMonth:
        setFormData((prev) => {
          return {
            ...prev,
            bills: { ...prev.bills, thirdMonth: file },
          };
        });
        break;
    }
  }

  function onElectrictyBoardUpdate(value: LosAutoCompleteOption) {
    setFormData((prev) => {
      return {
        ...prev,
        electricityBoard: value?.label ?? '',
        electricityCode: value?.value ?? '',
      };
    });
  }

  function handleUpdate(type: InputType, value: string) {
    const updatedErrors: Partial<ElectricityFormData> = { ...errors };
    switch (type) {
      case InputType.BOARD:
        if (value.length <= 0) {
          updatedErrors.electricityBoard = 'Electricity Board is required';
        } else {
          delete updatedErrors.electricityBoard;
        }
        setFormData((prev) => {
          return {
            ...prev,
            electricityBoard: value,
          };
        });
        break;
      case InputType.BILL_NO:
        if (value.length <= 0) {
          updatedErrors.electricityBillNo = 'Account no is required';
        } else {
          delete updatedErrors.electricityBillNo;
        }
        setFormData((prev) => {
          return {
            ...prev,
            electricityBillNo: value,
          };
        });
        break;
    }
    setErrors(updatedErrors);
  }

  const isInputValid = () => {
    const bills = formData.bills;
    let areBillValid =
      bills.firstMonth != null &&
      bills.secondMonth != null &&
      bills.thirdMonth != null;
    let isBillNoValid = (errors.electricityBillNo ?? '').length <= 0;
    let isBoardValid = (errors.electricityBoard ?? '').length <= 0;
    return areBillValid && isBoardValid && isBillNoValid;
  };

  async function onSubmitClick() {
    try {
      setLoading(true);
      //TODO:
      await saveElectricityData(formData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            text="Submit Application"
            disabled={isInputValid() == false}
            onClick={onSubmitClick}
            loading={loading}
          />
        );
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        );
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <LosFormInputField label="upload Electricity bill (last 3 months)">
            <Stack spacing={'1rem'}>
              <LosFileSelect
                placeholder={'Upload PDF'}
                onFileSelect={function (file: File): void {
                  handleBillPdfUpdate(BillType.FirstMonth, file);
                }}
              />
              <LosFileSelect
                placeholder={'Upload PDF'}
                onFileSelect={function (file: File): void {
                  handleBillPdfUpdate(BillType.SecondMonth, file);
                }}
              />
              <LosFileSelect
                placeholder={'Upload PDF'}
                onFileSelect={function (file: File): void {
                  handleBillPdfUpdate(BillType.ThirdMonth, file);
                }}
              />
            </Stack>
          </LosFormInputField>
        </Grid>

        <Grid item xs={12}>
          <LosFormInputField label="Select Electricity Board">
            <LosAutoCompleteField
              options={electricityBoardOptions(electrictyBoards)}
              onChange={(_, newValue: LosAutoCompleteOption) => {
                onElectrictyBoardUpdate(newValue);
              }}
            />
          </LosFormInputField>
        </Grid>

        <Grid item xs={12}>
          <LosFormInputField label="Electricity Account No">
            <LosTextField
              value={formData.electricityBillNo}
              error={!!errors.electricityBillNo}
              helperText={errors.electricityBillNo}
              onChange={(e) => {
                handleUpdate(InputType.BILL_NO, e.target.value);
              }}
            />
          </LosFormInputField>
        </Grid>
      </Grid>
    </LosFormContainer>
  );
}

export default ElectricityDetails;

const electricityBoardOptions = (boards: ElectricityBoard[]) => {
  const boardOptions = boards.map((item) => {
    const option: LosAutoCompleteOption = {
      value: item.code,
      label: _.startCase(_.toLower(item.serviceProvider)) + ' - ' + item.code,
    };
    return option;
  });

  return boardOptions;
};
