import React from 'react';
import {
  ICreditWorthinessScoreObjProps,
  IUnderwritingCollateralScoreObjProps,
  IUnderwritingComplianceScoreObjProps,
  IUnderwritingFinancialScoreObjProps,
  IUnderwritingManagementScoreObjProps,
  useRuleEngine,
} from '../../LMSAgent/Provider/RuleEngingProvider';
import { Box, Skeleton } from '@mui/material';
import LosText from '../LosText';
import { toLowerCase } from '../../../constants/commonFunction';
import { PRIMARY } from '../../../constants/style';

export type AnyScoreType =
  | ICreditWorthinessScoreObjProps
  | IUnderwritingFinancialScoreObjProps
  | IUnderwritingManagementScoreObjProps
  | IUnderwritingComplianceScoreObjProps
  | IUnderwritingCollateralScoreObjProps;

const RuleEngineType = () => {
  const {
    selectedRuleEngineType,
    setSelectedRuleEngineType,
    underWrittingScrore,
    loading,
  } = useRuleEngine();

  const getConvertedValue = (score: number) => {
    const convertedValue = score * 100;
    return convertedValue;
  };
  const RuleEngineTypeData: {
    type: string;
    weight: null | number;
  }[] = [
    {
      type: 'FINAL_CRITERIA',
      weight: null,
    },
    {
      type: 'COLLATERAL',
      weight: underWrittingScrore?.underwritingCollateralScore
        ? getConvertedValue(
            underWrittingScrore?.underwritingCollateralScore.weightedPoints
          )
        : 0,
    },
    {
      type: 'CREDIT_WORTHINESS',
      weight: underWrittingScrore?.creditWorthinessScore
        ? getConvertedValue(
            underWrittingScrore?.creditWorthinessScore.weightedPoints
          )
        : 0,
    },
    {
      type: 'FINANCIAL',
      weight: underWrittingScrore?.underwritingFinancialScore
        ? getConvertedValue(
            underWrittingScrore?.underwritingFinancialScore.weightedPoints
          )
        : 0,
    },
    {
      type: 'MANAGMENT',
      weight: underWrittingScrore?.underwritingManagementScore
        ? getConvertedValue(
            underWrittingScrore?.underwritingManagementScore.weightedPoints
          )
        : 0,
    },
    {
      type: 'COMPLIANCE',
      weight: underWrittingScrore?.underwritingComplianceScore
        ? getConvertedValue(
            underWrittingScrore?.underwritingComplianceScore.weightedPoints
          )
        : 0,
    },
  ];
  return (
    <>
      <Box
        sx={{
          width: '100%',
        }}
      >
        {RuleEngineTypeData.map(
          (rule: { type: string; weight: null | number }, index: number) => (
            <Box
              key={index}
              sx={{
                p: 2,
                minHeight: '68px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                cursor: 'pointer',
                ...(RuleEngineTypeData.length !== index + 1 && {
                  borderBottom: `1px solid #E1E4EB`,
                }),
              }}
              onClick={() => setSelectedRuleEngineType(rule.type)}
            >
              <LosText
                text={toLowerCase(rule.type)}
                fontSize={'0.75rem'}
                fontWeight={500}
                color={
                  selectedRuleEngineType === rule.type
                    ? PRIMARY.darkBlue
                    : '#676B76'
                }
              />
              {rule.weight !== null &&
                (loading ? (
                  <Skeleton />
                ) : (
                  <LosText
                    text={`Weight ${rule.weight} %`}
                    fontSize={'0.625rem'}
                    fontWeight={600}
                    color={
                      selectedRuleEngineType === rule.type
                        ? PRIMARY.darkBlue
                        : '#676B76'
                    }
                  />
                ))}
            </Box>
          )
        )}
      </Box>
    </>
  );
};

export default RuleEngineType;
