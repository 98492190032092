import { CancelOutlined } from '@mui/icons-material'
import { IconButton, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import VegaCheckBoxV2 from '../../../components/common/v2/VegaCheckBoxV2'
import { CloseDialogIcon } from '../icons/LosIcons'
import { useLos } from '../providers/LosProvider'
import {
  ApplicationPanInfo,
  CustomerApplication,
  CustomerPanData,
  PanMetadata,
  UserPanStatus,
} from '../types/Los'
import LosButton from './common/LosButton'
import LosDialog from './common/LosDialog'
import LosText from './common/LosText'
import { PanDetailsCard } from './PanDetailsCard'
import LosOnboardingButton from './common/LosOnboardingButton'
import { LosService } from '../api/LosService'
import { LocalStorageUtility, StorageKeys } from '../utils/StorageUtility'

type Props = {
  open: boolean
  panNumber: string
  onClose: () => void
  onSubmit: () => void
  onTncClick: () => void
}
function VerifyCompanyPanDetailsDialog({
  open,
  onClose,
  onSubmit,
  onTncClick,
  panNumber,
}: Props) {
  const { fetchPanDetailsForPanNumber } = useLos()
  const [panDetails, setPanDetails] = useState<ApplicationPanInfo>()
  const [tncAccepted, setTncAccepted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const isInputValid = () => {
    return tncAccepted == true
  }

  function onSubmitClick() {
    if (isInputValid()) {
      onSubmit()
      onClose()
    }
  }

  async function fetchPanDetails() {
    try {
      setLoading(true)
      const customerApplication =
        LocalStorageUtility.getItem<CustomerApplication>(
          StorageKeys.CUSTOMER_APPLICATION
        )
      // const response = await fetchPanDetailsForPanNumber(panNumber);
      //   const index = customerApplication?.currentUserIndex
      //     ? customerApplication?.currentUserIndex
      //     : 0
      //   const userId = customerApplication?.users[index]
      //   if (!userId) {
      //     console.error('UserId not found for member ')
      //     return
      //   }
      const companyPanId = customerApplication?.companyDetails.panId ?? ''
      const response = await LosService.getCompanyPanDetailsByPanId(
        companyPanId
      )
      setPanDetails(response)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open) {
      fetchPanDetails()
    }
  }, [panNumber, open])

  return (
    <LosDialog open={open} onClose={() => {}}>
      <div
        style={{
          maxWidth: '22.5rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
        }}
      >
        <Stack
          direction={'row'}
          alignItems='center'
          justifyContent={'space-between'}
        >
          <LosText text='Credit Bureau Consent' />
        </Stack>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <PanDetailsCard
            name={panDetails?.panName ?? ''}
            panNumber={panDetails?.panNumber ?? ''}
            status={
              panDetails?.valid === true
                ? UserPanStatus.APPROVED
                : UserPanStatus.REJECTED
            }
          />
        </div>
        <Stack>
          <Stack direction={'row'} spacing='1' alignItems={'start'}>
            <VegaCheckBoxV2
              sx={{
                '&.MuiButtonBase-root': {
                  paddingTop: '0px !important',
                },
                color: 'blue',
              }}
              checked={tncAccepted}
              onChange={(e, checked) => setTncAccepted(checked)}
            />
            <LosText style={{ fontSize: '0.75rem', lineHeight: '130%' }}>
              Subject to applicable laws, I give my credit bureau consent (and
              consent on behalf of my organisation I am authorized to give
              consent for) to{' '}
              <span style={{ fontWeight: 'bold' }}>
                Dhanvikas Fiscal Services Private Limited
              </span>{' '}
              and its associated financing partners to collect, receive, handle
              or share my information from credit bureaus (Experian/CIBIL/CRIF).
            </LosText>
          </Stack>
        </Stack>
        <LosOnboardingButton
          fullWidth
          text='Yes, Confirm My PAN'
          disabled={isInputValid() == false}
          onClick={onSubmitClick}
        />
      </div>
    </LosDialog>
  )
}

export default VerifyCompanyPanDetailsDialog
