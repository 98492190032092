import { Stack } from '@mui/material'
import { useState } from 'react'
import LosButton from '../../components/common/LosButton'
import LosText from '../../components/common/LosText'
import LosFormContainer from '../../components/LosFormContainer'
import { ApplicationSubmittedIcon, VegaPayLogo } from '../../icons/LosIcons'
import { ApplicationPreviewProvider } from '../../providers/ApplicationPreviewProvider'
import { CustomerApplication } from '../../types/Los'
import { LocalStorageUtility, StorageKeys } from '../../utils/StorageUtility'
import ApplicationPreview from '../ApplicationPreview/ApplicationPreview'

function ApplicationSubmitted() {
  const [openPreview, setOpenPreview] = useState<boolean>(false)

  const getApplicationId = () => {
    const application = LocalStorageUtility.getItem<CustomerApplication>(
      StorageKeys.CUSTOMER_APPLICATION
    )
    return application?.id
  }

  return (
    <LosFormContainer>
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignContent: 'center',
        }}
      >
        <Stack
          alignItems={'center'}
          justifyContent='center'
          flex={1}
          spacing='2rem'
        >
          <div>
            <img src='https://solfin.co.in/images/solfin-logo.svg'></img>
          </div>
          <div
            style={{
              borderRadius: '0.375rem',
              border: '1px dashed #1047DC',
              background: '#E7EDFC',
              paddingLeft: '0.75rem',
              paddingRight: '0.75rem',
              paddingTop: '1.25rem',
              paddingBottom: '1.25rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ApplicationSubmittedIcon />
              <LosText
                style={{
                  color: '#1047DC',
                  textAlign: 'center',
                  fontSize: '0.75rem',
                  lineHeight: '130%',
                  wordWrap: 'break-word',
                }}
              >
                Your application id{' '}
                <span style={{ fontWeight: 'bold' }}>
                  #{getApplicationId()}
                </span>{' '}
              </LosText>
              <LosText
                style={{
                  color: '#1047DC',
                  textAlign: 'center',
                  fontSize: '0.75rem',
                  lineHeight: '130%',
                  wordWrap: 'break-word',
                }}
              >
                has been received. You will be notified soon
              </LosText>
            </div>
          </div>
          <LosButton
            text='Preview Application'
            onClick={() => setOpenPreview(true)}
          />
        </Stack>
        <ApplicationPreviewProvider applicationId={getApplicationId()}>
          <ApplicationPreview
            open={openPreview}
            onClose={function (): void {
              setOpenPreview(false)
            }}
          />
        </ApplicationPreviewProvider>
      </div>
    </LosFormContainer>
  )
}

export default ApplicationSubmitted
