import { Edit } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import LosButton from '../../../../../onboarding/components/common/LosButton';
import { useColenders } from '../../../../providers/ColendersProvider';
import ColenderEligiblityForm from '../Forms/ColenderEligiblitySchemeForm';

enum ViewState {
  ReadOnly,
  Edit,
}

type FormData = {
  minLoanAmount: string;
  maxLoanAmount: string;
  minRateOfInterest: string;
  ageCriteria: string;
  bureauCriteria: string;
};

function ColenderEligiblitySchemeTab() {
  const {
    fetchColenderEligiblityScheme,
    selectedColender,
    saveColenderEligiblityScheme,
  } = useColenders();
  const [viewState, setViewState] = useState<ViewState>(ViewState.ReadOnly);
  const [formData, setFormData] = useState<Partial<FormData>>({});
  const [loading, setLoading] = useState<boolean>(false);

  function updateViewState(viewState: ViewState) {
    setViewState(viewState);
  }

  function handleUpdate(type: string, value?: string) {
    setFormData((prev) => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  async function onSaveClick() {
    const id = selectedColender?.id;
    if (!id) return;
    const response = await saveColenderEligiblityScheme({
      colenderId: id,
      formData: formData,
    });
    updateViewState(ViewState.ReadOnly);
    // setFormData(response);
  }

  function onCancelClick() {}

  async function fetchDetails() {
    setLoading(true);
    setFormData({});
    const id = selectedColender?.id;
    if (!id) return;
    const response = await fetchColenderEligiblityScheme(id);
    setFormData(response);
    setLoading(false);
  }

  useEffect(() => {
    fetchDetails();
    updateViewState(ViewState.ReadOnly);
  }, [selectedColender]);

  return (
    <div
      style={{
        padding: '12px',
      }}
    >
      <ColenderEligiblityForm
        formData={formData}
        handleUpdate={handleUpdate}
        isEditable={viewState == ViewState.Edit}
      />
      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      />
      <Stack
        direction={'row'}
        justifyContent="end"
        alignItems={'center'}
        spacing={'1rem'}
        display={viewState == ViewState.ReadOnly ? 'flex' : 'none'}
      >
        <LosButton
          startIcon={<Edit />}
          text="Edit"
          variant="outlined"
          size="small"
          onClick={() => {
            updateViewState(ViewState.Edit);
          }}
        />
      </Stack>
      <Stack
        direction={'row'}
        justifyContent="end"
        alignItems={'center'}
        spacing={'1rem'}
        display={viewState == ViewState.Edit ? 'flex' : 'none'}
      >
        <LosButton
          text="Save"
          variant="outlined"
          size="small"
          onClick={onSaveClick}
        />
        <LosButton
          text="Cancel"
          variant="outlined"
          size="small"
          onClick={() => {
            updateViewState(ViewState.ReadOnly);
          }}
        />
      </Stack>
    </div>
  );
}

export default ColenderEligiblitySchemeTab;
