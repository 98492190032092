import { Stack, Grid, Divider } from '@mui/material';
import dayjs from 'dayjs';
import { LoanDispersalSchedule } from '../../../LMSHQ/types/Lms';
import { StringUtility } from '../../utils/StringUtility';
import LosText from '../common/LosText';
import { InfoCard, InfoTitle, InfoDescription } from './InfoCard';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  totalLoanAmount?: string;
  trancheDisbursedAmount?: string;
  tranches: LoanDispersalSchedule[];
  loading?: boolean;
};

function ActiveLoanTrancheDetails({
  totalLoanAmount,
  trancheDisbursedAmount,
  tranches,
  loading = false,
}: Props) {
  const getTrancheSummaryAmount = () => {
    return `${trancheDisbursedAmount} / ${totalLoanAmount}`;
  };

  return (
    <div>
      <Title text="Trench Details" />
      <SubTitle text="Details about your loan disbursement" />
      <InfoCard>
        <Stack spacing={'1rem'}>
          <Stack>
            <InfoTitle text="Trench Amount" />
            <InfoDescription
              text={getTrancheSummaryAmount()}
              loading={loading}
            />
          </Stack>
          {tranches.map((item, index) => {
            return (
              <div>
                <TrancheDetailsRowItem
                  key={index}
                  index={index}
                  tranche={item}
                  loading={loading}
                />
                {index < tranches.length - 1 && (
                  <Divider
                    style={{
                      marginTop: '0.5rem',
                    }}
                  />
                )}
              </div>
            );
          })}
        </Stack>
      </InfoCard>
    </div>
  );
}

export default ActiveLoanTrancheDetails;

const TrancheDetailsRowItem = (data: {
  index: number;
  tranche: LoanDispersalSchedule;
  loading?: boolean;
}) => {
  const isDisbursed = () => {
    return data.tranche.dispersalDate != null;
  };

  return (
    <Grid container alignItems="center" justifyContent={'space-between'}>
      <Grid item xs={6}>
        <Stack>
          <InfoTitle text={`Trench ${data.index + 1} Amount`} />
          <InfoDescription
            text={`₹ ${StringUtility.formatCurrency(data.tranche.amount)}`}
            loading={data.loading}
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack>
          <InfoTitle text="Disbursed Date" />
          {isDisbursed() && (
            <InfoDescription
              text={`${dayjs(data.tranche.dispersalDate).format('DD/MM/YYYY')}`}
              loading={data.loading}
            />
          )}
          {isDisbursed() == false && (
            <LosText
              style={{
                color: 'rgba(255, 194, 27, 1)',
                fontSize: '0.8125rem',
                lineHeight: '120%',
                fontWeight: 450,
              }}
              text={'Pending'}
            />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

const Title = (data: { text: string }) => {
  return (
    <LosText
      text={data.text}
      style={{
        color: '#1B1D22',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '140%',
        marginBottom: '0.5rem',
      }}
    />
  );
};

const SubTitle = (data: { text: string }) => {
  return (
    <LosText
      text={data.text}
      style={{
        color: '#676B76',
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: '130%',
        marginBottom: '1rem',
      }}
    />
  );
};
