import { Stack, Typography } from '@mui/material';
import { VegaCard, VegaTable } from '../common';
import _ from 'lodash';
import { BLUE } from '../../constants/style';
import {
  VegaProgramNetworkType,
  VegaProgramWithMetaDataType,
} from '../../types/program';
import { VegaBin } from '../../types/client';
import { VegaUser } from '../../types/user';
import { DateUtility } from '../../utils/dateTime';
import { getLogoForNetwork } from '../icon/Icons';

interface ILiveProgramProps {
  programs: VegaProgramWithMetaDataType[];
}

const ListOfDraftPrograms = ({ programs }: ILiveProgramProps) => {
  const columnRef = [
    {
      field: 'name',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Program Name
        </Typography>
      ),
      flex: 1,
    },

    {
      field: 'coBrandPartnerName',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Co-branding Partner
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const name = props.row.coBrandPartnerName;
        return (
          <Typography sx={{ fontSize: 14 }}>
            {name && _.startCase(_.toLower(name))}
            {!name && '-'}
          </Typography>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Creation Date
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        const createdAt = props.row.createdAt;
        var readableDate = '-';
        if (createdAt) {
          readableDate = DateUtility.formatToDDMMMYYY(createdAt);
        }
        return <Typography sx={{ fontSize: 14 }}>{readableDate}</Typography>;
      },
    },
    {
      field: 'updatedAt',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Last Edit
        </Typography>
      ),
      flex: 0.7,
      renderCell: (props: any) => {
        const updatedAt = props.row.updatedAt;
        var readableDate = '-';
        if (updatedAt) {
          readableDate = DateUtility.formatToNDaysAgo(updatedAt);
        }
        return <Typography sx={{ fontSize: 14 }}>{readableDate}</Typography>;
      },
    },
    {
      field: 'maker',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Maker
        </Typography>
      ),
      flex: 0.8,
      renderCell: (props: any) => {
        const maker: VegaUser = props.row.maker;
        return (
          <Typography
            color={BLUE.lighter}
            sx={{ fontSize: 14, fontWeight: 'bold' }}
          >
            {maker && _.startCase(_.toLower(maker.name))}
            {!maker && '-'}
          </Typography>
        );
      },
    },
    {
      field: 'bin',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Bin
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const bin: VegaBin = props.row.bin;
        return (
          <Typography sx={{ fontSize: 14 }}>
            {_.startCase(_.toLower(bin?.binValue))}
            <span style={{ fontSize: 12 }}> - {bin?.binName}</span>
          </Typography>
        );
      },
    },
    {
      field: 'network',
      headerName: (
        <Typography className="font-aspekta-500" variant="body2">
          Network
        </Typography>
      ),
      flex: 1,
      renderCell: (props: any) => {
        const network: VegaProgramNetworkType = props.row.network;
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            {getLogoForNetwork(network)}
            <Typography sx={{ fontSize: 14 }}>
              {network && _.startCase(_.toLower(network))}
              {!network && '-'}
            </Typography>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <VegaCard fullHeight noPad>
        <VegaTable
          data={programs}
          columnDef={columnRef}
          idColumn={'programId'}
          pageSize={5}
        />
      </VegaCard>
    </>
  );
};

export default ListOfDraftPrograms;
