import { Grid, Stack } from '@mui/material';
import LosButton from '../../components/common/LosButton';
import LosText from '../../components/common/LosText';
import { LosFormButtonView } from '../../components/LosFormButton';
import LosFormContainer from '../../components/LosFormContainer';

import { GiftIcon } from '../../icons/LosIcons';
import { useLos } from '../../providers/LosProvider';

function ApplicationReadyForDisbursal() {
  const { navigateToNextStep, approveSanctionLetter } = useLos();

  return (
    <LosFormContainer>
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          rowGap: '0.5rem',
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <CongratulationsContainer />
          </Grid>
        </Grid>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        ></div>
      </div>
    </LosFormContainer>
  );
}

export default ApplicationReadyForDisbursal;

const CongratulationsContainer = () => {
  return (
    <div
      style={{
        borderRadius: '0.375rem',
        background: '#E7EDFC',
        paddingLeft: '0.75rem',
        paddingRight: '0.75rem',
        paddingTop: '1.25rem',
        paddingBottom: '1.25rem',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '0.5rem',
        }}
      >
        <GiftIcon />
        <LosText
          text="Congratulations!"
          style={{
            color: '#1047DC',
            textAlign: 'center',
            fontSize: '1.0625rem',
            lineHeight: '120%',
            fontWeight: 600,
          }}
        />
        <LosText
          text="All your loan formalities are complete!"
          style={{
            color: '#1047DC',
            textAlign: 'center',
            fontSize: '0.75rem',
            lineHeight: '130%',
          }}
        />
        <LosText
          text="Your Loan shall be disbursed in the next 2 business days"
          style={{
            color: '#1047DC',
            textAlign: 'center',
            fontSize: '0.75rem',
            lineHeight: '130%',
          }}
        />
      </div>
    </div>
  );
};
