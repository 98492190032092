import { Close } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';

import LosDialog from '../common/LosDialog';
import LosText from '../common/LosText';

type Props = {
  open: boolean;
  onClose: () => void;
};

const styles = {
  table: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    width: '100%',
  },
  th: {
    border: '1px solid black',
    padding: '4px',
  },
  td: {
    border: '1px solid black',
    padding: '4px',
    fontSize: '13px',
  },
};

type TableGridType = {
  [key: string]: string;
};

const FEE_AND_CHARGES_DATA: TableGridType[] = [
  {
    SNo: '1',
    'Fee/Charges': 'Additional Penal interest on delayed payments',
    Levy: '2% per month on balance outstanding of the previous month closing',
    'Frequency/Time': 'All the time of default',
  },
  {
    SNo: '2',
    'Fee/Charges': 'Processing Fee',
    Levy: '1% of Loan disbursal',
    'Frequency/Time': 'All the time of disbursal',
  },
  {
    SNo: '3',
    'Fee/Charges': 'Foreclosure',
    Levy: '2.5% of the Balance Outstanding of the previous month closing',
    'Frequency/Time': 'At the time of foreclosure',
  },
  {
    SNo: '4',
    'Fee/Charges': 'Part Pre Payment Charges',
    Levy: '2.5% of the Balance Outstanding of the previous month closing',
    'Frequency/Time': '',
  },
  {
    SNo: '5',
    'Fee/Charges': 'Stamp Duty',
    Levy: 'On Actuals',
    'Frequency/Time': 'On Expense',
  },
  {
    SNo: '6',
    'Fee/Charges': 'Documentation Charges',
    Levy: 'On Actuals',
    'Frequency/Time': 'On Expense',
  },
  {
    SNo: '7',
    'Fee/Charges': 'ENACH/RTGS',
    Levy: 'On Actuals',
    'Frequency/Time': 'At the time of Payment',
  },
  {
    SNo: '8',
    'Fee/Charges': 'Statement Charges',
    Levy: 'Nil',
    'Frequency/Time': '',
  },
  {
    SNo: '9',
    'Fee/Charges': 'No Dues Certificate',
    Levy: 'Nil',
    'Frequency/Time': '',
  },
  {
    SNo: '10',
    'Fee/Charges': 'Miscellaneous',
    Levy: 'On Actuals',
    'Frequency/Time':
      'Any expense incurred by DFSL in direct connection with the loan',
  },
];

const SECURITIES_DATA: TableGridType[] = [
  {
    SNo: '1',
    Securities: 'Hypothecation of the Asset for which loan has been taken',
    Applicable: 'Yes',
    'Frequency/Time': 'Within 30 days of disbursal',
  },
  {
    SNo: '2',
    Securities: 'Corporate Gurantee',
    Applicable: 'Yes',
    'Frequency/Time': 'At the time of Loan Agreement',
  },
  {
    SNo: '3',
    Securities: 'Senior Secured Floating Charge on Borrowers Assets',
    Applicable: 'Yes',
    'Frequency/Time': 'At the time of Loan Agreement',
  },
];

function KeyFactsStatementDialog({ open, onClose }: Props) {
  return (
    <LosDialog open={open} onClose={onClose}>
      <Stack
        direction={'row'}
        justifyContent="space-between"
        alignItems={'center'}
        style={{
          paddingTop: '1rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingBottom: '2rem',
        }}
      >
        <LosText text="Key Fact Statement" />
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <Box
        style={{
          width: '100%',
          maxHeight: '80vh',
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          /* This will hide the scrollbar but keep the functionality */
          WebkitOverflowScrolling:
            'touch' /* Optional: smooth scrolling on iOS devices */,
          scrollbarWidth: 'none' /* For Firefox and other browsers */,
          msOverflowStyle: 'none' /* For Internet Explorer */,
        }}
      >
        <TableGrid title={'Fee and Charges'} data={FEE_AND_CHARGES_DATA} />

        <TableGrid title={'Securities'} data={SECURITIES_DATA} />
      </Box>
    </LosDialog>
  );
}

export default KeyFactsStatementDialog;

type TableGridProps = {
  title: string;
  data: TableGridType[];
};
const TableGrid = ({ title, data }: TableGridProps) => {
  return (
    <Stack spacing={'1rem'}>
      <LosText text={title} />
      <table
        style={{
          border: '1px solid black',
          borderCollapse: 'collapse',
          width: '100%',
        }}
      >
        <thead>
          <tr>
            {Object.keys(data[0]).map((header) => (
              <th key={header} style={styles.th}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {Object.values(row).map((value, index) => (
                <td key={index} style={styles.td}>
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Stack>
  );
};
