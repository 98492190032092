import { Grid, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import VegaCheckBoxV2 from '../../../../components/common/v2/VegaCheckBoxV2'
import { useSnackbar } from '../../../../providers/SnackbarProvider'
import AadharOtpInputDialog from '../../components/AadharDialog/AadharOtpInputDialog'
import VerifyAadharDetailsDialog from '../../components/AadharDialog/VerifyAadharDetailsDialog'
import LosFormInputField from '../../components/common/LosFormInputField'
import LosText from '../../components/common/LosText'
import LosTextField from '../../components/common/LosTextField'
import LosAppBar from '../../components/LosAppBar'
import { LosFormButtonView } from '../../components/LosFormButton'
import LosFormContainer from '../../components/LosFormContainer'
import PanDetailsVerifiedStatusCard from '../../components/PanDetailsVerifiedCard'
import { useLosAuth } from '../../providers/LosAuthProvider'
import { useLos } from '../../providers/LosProvider'
import {
  AadharVerificationOtpResponse,
  CustomerPanData,
  LosStep,
  PanMetadata,
} from '../../types/Los'
import { StringUtility } from '../../utils/StringUtility'
import { LosService } from '../../api/LosService'

type FormData = {
  aadharNumber: string
}

enum InputType {
  AADHAR_NUMBER,
}

enum ViewState {
  AadharInput,
}

function IndividualAadharDetails() {
  const {
    navigateToNextStep,
    generateAadharOtp,
    verifyAadharOtp,
    currentStepMetaData,
    fetchPanDetailsForPanInfoId,
    updateAadharStatusToAccepted,
    customerApplication,
    currentStep,
  } = useLos()
  const { isAuthenticated } = useLosAuth()
  const [formData, setFormData] = useState<FormData>({
    aadharNumber: '',
  })
  const [errors, setErrors] = useState<Partial<FormData>>({})
  const [tncAccepted, setTncAccepted] = useState<boolean>(false)
  const [viewState, setViewState] = useState<ViewState>(ViewState.AadharInput)
  const [openAadharOtpInputDialog, setOpenAadharOtpInputDialog] =
    useState<boolean>(false)
  const [openAadharDetailsDialog, setOpenAadharDetailsDialog] =
    useState<boolean>(false)
  const [individualPanData, setIndividualPanData] = useState<PanMetadata>()
  const [loading, setLoading] = useState<boolean>(false)
  const [aadhaarResponse, setAadhaarResponse] =
    useState<AadharVerificationOtpResponse>()
  const { setSnackbar } = useSnackbar()

  function handleUpdate(type: InputType, value: string) {
    const updatedErrors: Partial<FormData> = { ...errors }
    switch (type) {
      case InputType.AADHAR_NUMBER:
        if (value.length > 12) return
        if (value.length <= 0) {
          updatedErrors.aadharNumber = 'Aadhaar Number is required'
        } else {
          const isValid = StringUtility.validateAadharNumber(value)
          if (isValid == false) {
            updatedErrors.aadharNumber = 'Please Enter Valid Aadhaar Number'
          } else {
            delete updatedErrors.aadharNumber
          }
        }
        setFormData(prev => {
          return {
            ...prev,
            aadharNumber: value,
          }
        })
        break
    }
    setErrors(updatedErrors)
  }

  const isAadharNumberInputValid = () => {
    if (!!errors.aadharNumber) return false
    return formData.aadharNumber.length > 0
  }

  const isInputValid = () => {
    let isAadharNumberValid = (formData.aadharNumber ?? '').length > 0
    var isTncAccepted = true
    var isAadharTncAccepted = true
    if (viewState == ViewState.AadharInput) {
      isTncAccepted = tncAccepted == true
    }

    return isAadharNumberValid && isTncAccepted && isAadharTncAccepted
  }

  async function fetchPanForCurrentMember() {
    try {
      setLoading(true)
      // const members = customerApplication?.members ?? [];
      // if (members.length > 0) {
      //   const firstMember = members[0];
      //   const panInfoId = firstMember.panInfoId;
      //   const response = await fetchPanDetailsForPanInfoId(panInfoId);
      // if (member.length > 0) {
      // const firstMember = member
      // const response = await fetchPanDetailsForPanInfoId(
      //   '6565f389b5c8f241b157a74a'
      // )
      // }
      const index = customerApplication?.currentUserIndex
        ? customerApplication?.currentUserIndex
        : 0
      const userId = customerApplication?.users[index]

      if (!userId) {
        console.error('UserId not found for member ')
        return
      }
      const response = await LosService.getPanDetailsByUserId(userId)
      setIndividualPanData(response)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  async function onVerifyAadharClick() {
    try {
      setLoading(true)
      const response = await generateAadharOtp({
        aadhaarNumber: formData.aadharNumber,
      })
      setAadhaarResponse(response)
      setOpenAadharOtpInputDialog(true)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  async function onVerifyOtpClick(otp: string) {
    try {
      setLoading(true)
      const requestId = aadhaarResponse?.requestId
      if (!requestId) {
        console.error('RequestId not found')
        return
      }
      const response = await verifyAadharOtp({
        requestId: requestId,
        otp: otp,
      })

      if (response == true) {
        navigateToNextStep()
      } else {
        setSnackbar('Failed to validate Otp', 'error')
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  async function onConfirmAadharDetailsClick() {
    try {
      setLoading(true)
      await updateAadharStatusToAccepted()
      navigateToNextStep()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  function onTncClick() {}

  function runAfterDelay(callback: () => void, delay: number) {
    setTimeout(callback, delay)
  }

  async function handleAadharAccepted() {
    setOpenAadharDetailsDialog(true)
  }

  useEffect(() => {
    if (currentStep == LosStep.AADHAAR_ACCEPTED && isAuthenticated) {
      runAfterDelay(handleAadharAccepted, 500)
    }
  }, [currentStep, customerApplication, isAuthenticated])

  useEffect(() => {
    fetchPanForCurrentMember()
  }, [customerApplication])

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <AadharInputButtonView
            onClick={onVerifyAadharClick}
            isInputValid={isInputValid()}
            tncAccepted={tncAccepted}
            onTncChange={setTncAccepted}
            onTncClick={onTncClick}
            loading={loading}
          />
        )
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        )
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <PanDetailsVerifiedStatusCard
            name={individualPanData?.name}
            panNumber={individualPanData?.number}
            status={'Verified'}
          />
        </Grid>
        <Grid
          item
          xs={12}
          display={viewState == ViewState.AadharInput ? 'block' : 'none'}
          style={{
            transition: '0.5ms all ease-in-out',
          }}
        >
          <LosFormInputField label='Aadhaar Card No'>
            <LosTextField
              value={formData.aadharNumber}
              onChange={e =>
                handleUpdate(InputType.AADHAR_NUMBER, e.target.value)
              }
              error={!!errors.aadharNumber}
              helperText={errors.aadharNumber}
              isValid={isAadharNumberInputValid()}
            />
          </LosFormInputField>
        </Grid>
      </Grid>
      <AadharOtpInputDialog
        open={openAadharOtpInputDialog}
        onClose={function (): void {
          setOpenAadharOtpInputDialog(false)
        }}
        onSubmit={onVerifyOtpClick}
        onResendOtp={onVerifyAadharClick}
      />
      <VerifyAadharDetailsDialog
        open={openAadharDetailsDialog}
        onClose={function (): void {
          setOpenAadharDetailsDialog(false)
        }}
        onSubmit={onConfirmAadharDetailsClick}
        onTncClick={function (): void {}}
      />
    </LosFormContainer>
  )
}

export default IndividualAadharDetails

const AadharInputButtonView = (data: {
  onClick: () => void
  isInputValid: boolean
  tncAccepted: boolean
  onTncChange: (checked: boolean) => void
  onTncClick: () => void
  loading?: boolean
}) => {
  return (
    <LosFormButtonView
      onClick={data.onClick}
      disabled={data.isInputValid == false}
      text='Verify Aadhaar'
      loading={data.loading}
      renderHelperView={() => {
        return (
          <Stack>
            <Stack direction={'row'} spacing='1' alignItems={'start'}>
              <VegaCheckBoxV2
                sx={{
                  '&.MuiButtonBase-root': {
                    paddingTop: '0px !important',
                  },
                  color: 'blue',
                }}
                checked={data.tncAccepted}
                onChange={(e, checked) => data.onTncChange(checked)}
              />
              <LosText style={{ fontSize: '0.75rem', lineHeight: '130%' }}>
                I give{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Dhanvikas Fiscal Services Private Limited
                </span>{' '}
                the consent to save and verify my Aadhaar Card details and agree
                to the{' '}
                <span
                  onClick={() => {
                    window.open(
                      'https://solfin.co.in/Termsandconditions',
                      '__blank'
                    )
                  }}
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  Terms and Conditions
                </span>{' '}
                and{' '}
                <span
                  onClick={() => {
                    window.open('https://solfin.co.in/Privacypolicy', '__blank')
                  }}
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  Privacy Policy
                </span>{' '}
              </LosText>
            </Stack>
          </Stack>
        )
      }}
    />
  )
}
