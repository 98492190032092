import React, { ReactNode, useEffect, useRef } from 'react';
import { CameraClickIcon } from '../icons/LosIcons';
import LosSelect, { LosSelectOption } from './common/LosSelect';

export interface Resolution {
  width: number;
  height: number;
}

export interface CameraProps {
  isActive?: boolean;
  isPause?: boolean;
  desiredCamera?: string;
  desiredResolution?: Resolution;
  facingMode?: string;
  children?: ReactNode;
  onOpened?: (cam: HTMLVideoElement, camLabel: string) => void;
  onClosed?: () => void;
  onDeviceListLoaded?: (list: MediaDeviceInfo[]) => void;
  onPictureClicked?: (data: string) => void;
}

interface PlayOptions {
  deviceId?: string;
  desiredResolution?: Resolution;
  facingMode?: string;
}

const SelfieCamera = (props: CameraProps): React.ReactElement => {
  const devices = useRef<MediaDeviceInfo[] | null>(null);
  const localStream = useRef<MediaStream | null>(null);
  const camera = useRef<HTMLVideoElement | null>(null);
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const mounted = useRef(false);

  useEffect(() => {
    const init = async () => {
      if (!devices.current) {
        await loadDevices(); // load the camera devices list when the component is mounted
      }
      if (props.isActive === true) {
        playWithDesired();
      }
      mounted.current = true;
    };
    init();
  }, []);

  useEffect(() => {
    if (mounted.current === true) {
      if (props.isActive === true) {
        playWithDesired();
      } else {
        stop();
      }
    }
  }, [props.isActive]);

  useEffect(() => {
    if (mounted.current === true) {
      if (camera.current && props.isActive === true) {
        if (props.isPause === true) {
          camera.current.pause();
        } else {
          camera.current.play();
        }
      }
    }
  }, [props.isPause]);

  useEffect(() => {
    if (
      props.isActive === true &&
      localStream.current &&
      mounted.current === true
    ) {
      playWithDesired();
    }
  }, [props.desiredCamera, props.desiredResolution, props.facingMode]);

  const playWithDesired = async () => {
    if (!devices.current) {
      await loadDevices(); // load the camera devices list if it hasn't been loaded
    }
    let desiredDevice = getDesiredDevice(devices.current ?? []);

    if (desiredDevice) {
      let options: PlayOptions = {};
      options.deviceId = desiredDevice;
      if (props.desiredResolution) {
        options.desiredResolution = props.desiredResolution;
      }
      if (props.facingMode) {
        options.facingMode = props.facingMode;
      }
      play(options);
    } else {
      throw new Error('No camera detected');
    }
  };

  const getDesiredDevice = (devices: MediaDeviceInfo[]): string | null => {
    let count = 0;
    let desiredIndex = 0;
    for (let i = 0; i < devices.length; i++) {
      let device = devices[i];
      let label = device.label || `Camera ${count++}`;
      if (props.desiredCamera) {
        if (
          label.toLowerCase().indexOf(props.desiredCamera.toLowerCase()) !== -1
        ) {
          desiredIndex = i;
          break;
        }
      }
    }

    if (devices.length > 0) {
      return devices[desiredIndex].deviceId; // return the device id
    } else {
      return null;
    }
  };

  const play = (options: PlayOptions) => {
    stop(); // close before play
    let constraints: MediaStreamConstraints = {};

    if (options.deviceId) {
      constraints = {
        video: { deviceId: options.deviceId },
        audio: false,
      };
    } else {
      constraints = {
        video: { width: 1280, height: 720 },
        audio: false,
      };
    }

    // if (options.facingMode) {
    //   delete constraints.video.deviceId;
    //   constraints.video.facingMode = { exact: options.facingMode };
    // }

    // if (options.desiredResolution) {
    //   constraints.video.width = options.desiredResolution.width;
    //   constraints.video.height = options.desiredResolution.height;
    // }
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (stream) {
        localStream.current = stream;
        // Attach local stream to video element
        if (camera.current) {
          camera.current.srcObject = stream;
        }
      })
      .catch(function (err) {
        if (options.facingMode) {
          // facing mode not supported on desktop Chrome
          delete options.facingMode;
          play(options);
        } else {
          console.error('getUserMediaError', err, err.stack);
        }
      });
  };

  const stop = () => {
    try {
      if (localStream.current) {
        const stream = localStream.current;
        const tracks = stream.getTracks();
        for (let index = 0; index < tracks.length; index++) {
          const track = tracks[index];
          track.stop();
        }
        if (props.onClosed) {
          props.onClosed();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadDevices = async () => {
    const constraints = { video: true, audio: false };
    const stream = await navigator.mediaDevices.getUserMedia(constraints); // ask for permission
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();

    let cameraDevices: MediaDeviceInfo[] = [];
    for (let i = 0; i < mediaDevices.length; i++) {
      let device = mediaDevices[i];
      if (device.kind === 'videoinput') {
        // filter out audio devices
        cameraDevices.push(device);
      }
    }
    devices.current = cameraDevices;
    const tracks = stream.getTracks();
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i];
      track.stop(); // stop the opened camera
    }

    if (props.onDeviceListLoaded) {
      props.onDeviceListLoaded(cameraDevices);
    }
  };

  const onCameraOpened = () => {
    if (props.onOpened && camera.current) {
      props.onOpened(camera.current, getCurrentCameraLabel());
    }
  };

  const getCurrentCameraLabel = (): string => {
    try {
      if (localStream.current) {
        const stream = localStream.current;
        return stream.getTracks()[0].label;
      }
      return '';
    } catch (error) {
      return '';
    }
  };

  const capturePicture = () => {
    if (camera.current && canvas.current) {
      const videoElement = camera.current;
      const canvasElement = canvas.current;

      const { videoWidth, videoHeight } = videoElement;

      canvasElement.width = videoWidth;
      canvasElement.height = videoHeight;

      const context = canvasElement.getContext('2d');

      if (context) {
        context.drawImage(videoElement, 0, 0, videoWidth, videoHeight);
        const dataUrl = canvasElement.toDataURL('image/jpeg');

        if (props.onPictureClicked) {
          props.onPictureClicked(dataUrl); // Invoke the callback with the captured picture data URL
        }
        cleanup();
      }
    }
  };

  const cleanup = () => {
    stop(); // Stop the camera

    // Release resources
    localStream.current = null;
    devices.current = null;
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
      }}
    >
      <video
        style={{
          position: 'absolute',
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
        }}
        ref={camera}
        muted
        autoPlay={true}
        playsInline={true}
        onLoadedData={onCameraOpened}
      ></video>
      <canvas ref={canvas} style={{ display: 'none' }}></canvas>
      {/* <div
        style={{
          position: 'absolute',
          top: '2%',
          right: '0',
          cursor: 'pointer',
        }}
      >
        <LosSelect options={deviceOptions(devices.current ?? [])} />
      </div> */}
      <div
        style={{
          position: 'absolute',
          bottom: '5%',

          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={capturePicture}
        >
          <CameraClickIcon />
        </div>
      </div>
    </div>
  );
};

export default SelfieCamera;

const deviceOptions = (devices: MediaDeviceInfo[]) => {
  return devices.map((item) => {
    const option: LosSelectOption = {
      value: item.label,
      label: item.deviceId,
    };
    return option;
  });
};
