import { Grid, Stack } from '@mui/material'
import { getApplicationsDetails } from '../../../store/common/agentApplicationsSlice'
import { useAppSelector } from '../../../store/hook'
import PdfPreview from '../PdfPreview'
import LosText from '../../onboarding/components/common/LosText'
import { LosService } from '../../onboarding/api/LosService'
import { ApplicationStatus, LosEntityType } from '../../onboarding/types/Los'

function BureauDetailsTab() {
  const { applicationDetails } = useAppSelector(getApplicationsDetails)

  const canShowCompanyCIBIL = () => {
    return (
      applicationDetails.basicInfo?.userType === 'NON_INDIVIDUAL' &&
      applicationDetails.losEntityType !== LosEntityType.SOLE_PROPRIETOR
    )
  }

  const canShowIndividualCIBIL = () => {
    return (
      applicationDetails.basicInfo?.userType === 'INDIVIDUAL' ||
      applicationDetails.losEntityType === LosEntityType.SOLE_PROPRIETOR
    )
  }

  async function onCommercialCIBILDownloadClick() {
    const response = await LosService.getComercialCibil({
      applicationId: applicationDetails.id,
      responseType: 'HTML',
    })
    window.open(response.url, '__blank')
  }

  async function onConsumerCIBILDownloadClick() {
    const response = await LosService.getCustomerCibil({
      applicationId: applicationDetails.id,
      responseType: 'HTML',
    })
    window.open(response.url, '__blank')
  }

  function onCompanyDownloadClick() {
    if (applicationDetails.status === ApplicationStatus.IN_PROGRESS) {
      return
    } else {
      return onCommercialCIBILDownloadClick()
    }
  }

  function onConsumerPdfDownloadClick() {
    if (applicationDetails.status === ApplicationStatus.IN_PROGRESS) {
      return
    } else {
      return onConsumerCIBILDownloadClick()
    }
  }

  function pdfPreviewtext() {
    return applicationDetails.status === ApplicationStatus.IN_PROGRESS
      ? 'Cannot Download Report'
      : 'Download'
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
        }}
      >
        {canShowCompanyCIBIL() && (
          <Grid container>
            <Grid item xs={4}>
              <PdfPreview
                onDownload={onCompanyDownloadClick}
                fileName={'Commercial CIBIL Report'}
                text={pdfPreviewtext()}
              />
            </Grid>
          </Grid>
        )}

        {canShowIndividualCIBIL() && (
          <Grid container>
            <Grid item xs={4}>
              <PdfPreview
                onDownload={onConsumerPdfDownloadClick}
                fileName={'Consumer CIBIL Report'}
                text={pdfPreviewtext()}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </>
  )
}

export default BureauDetailsTab

const CIBILJsonText = (data: { json: any; title: string }) => {
  return (
    <Grid item xs={12}>
      <Stack spacing={'1rem'}>
        <LosText fontSize={14}>{data.title}</LosText>
        <div
          style={{
            height: '50vh',
            backgroundColor: 'rgba(250, 250, 250, 1)',
            overflowY: 'scroll',
            overflowX: 'hidden',
            padding: '1rem',
            borderRadius: '1rem',
          }}
        >
          <pre>
            <code
              style={{
                fontSize: '1rem',
                fontFamily: 'Aspekta !important',
              }}
            >
              {JSON.stringify(data.json, null, 5)}
            </code>
          </pre>
        </div>
      </Stack>
    </Grid>
  )
}
