import { Box, CardMedia } from '@mui/material'
import { useEffect, useState } from 'react'
import { UploadFileIcon } from '../../components/icon/Icons'
import { BLUE, GREY, PRIMARY } from '../../constants/style'
import LosText from '../onboarding/components/common/LosText'

enum ViewState {
  Upload,
  Download,
  ViewOnly,
}

// TODO: Improve this component
type Props = {
  onDownload: () => void
  fileName?: string
  onFileSelected?: (file: File | null) => void
  canDownload?: boolean
  canUpload?: boolean
  text?: string
}

function PdfPreview({
  onDownload,
  fileName,
  onFileSelected,
  canDownload = true,
  canUpload = true,
  text = 'Download',
}: Props) {
  const [viewState, setViewState] = useState<ViewState>(ViewState.Upload)
  const inputId = `file-input-${Math.random().toString(36).substring(7)}`

  const [selectedFileName, setSelectedFilename] = useState(fileName)

  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0]
    if (file) {
      setSelectedFilename(file.name)
    }
    onFileSelected?.(file)
    setViewState(file != null ? ViewState.Download : ViewState.Upload)
  }

  useEffect(() => {
    const mappedFileName = fileName ?? ''
    if (mappedFileName.length > 0) setViewState(ViewState.Download)
    setSelectedFilename(fileName)
  }, [fileName])

  if (viewState == ViewState.Download) {
    return (
      <DownloadPdfView
        onDownload={onDownload}
        fileName={selectedFileName}
        canDownload={canDownload}
        text={text}
      />
    )
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px dashed ${GREY.dark}`,
        borderRadius: '10px',
        height: '10rem',
      }}
    >
      {canUpload && <UploadFileIcon />}
      <label
        htmlFor={inputId}
        className='font-aspekta-500'
        style={{
          borderRadius: '5px',
          color: BLUE.lighter,
          fontSize: 12,
          margin: '0px 2px',
          padding: '5px 5px',
          cursor: 'pointer',
        }}
      >
        {canUpload == true ? 'Upload File' : 'No File'}
      </label>
      {canUpload == true && (
        <input
          id={inputId}
          type='file'
          accept='application/pdf'
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      )}
    </div>
  )
}

export default PdfPreview

type DownloadPdfViewProps = {
  onDownload: () => void
  fileName?: string
  canDownload: boolean
  text: string
}

const DownloadPdfView = ({
  onDownload,
  fileName,
  canDownload,
  text,
}: DownloadPdfViewProps) => {
  return (
    <Box
      className='media'
      sx={{
        position: 'relative',
        border: `1px dashed ${GREY.dark}`,
        borderRadius: '1rem',
        padding: '0.5rem',
        height: '10rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '0.5rem',
      }}
    >
      <CardMedia
        style={{
          borderRadius: '5px',
          width: '100%',
          height: '80px',
          objectFit: 'contain',
        }}
        component='img'
        image={require('./../assets/pdf_thumbnail.png')}
      />

      <Box className='overlay'>
        <label
          onClick={onDownload}
          style={{
            borderRadius: '5px',
            backgroundColor: PRIMARY.blue,
            fontSize: 12,
            margin: '0px 2px',
            padding: '5px 5px',
            cursor: 'pointer',
          }}
        >
          {text}
        </label>
      </Box>

      <div
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          paddingLeft: '0.625rem',
          paddingRight: '0.625rem',
          textOverflow: 'ellipsis',
          width: '100%',
        }}
      >
        <LosText
          text={fileName}
          variant='caption'
          textAlign={'center'}
          overflow='hidden'
          whiteSpace='nowrap'
          textOverflow='ellipsis'
        />
      </div>
    </Box>
  )
}
