import { configureStore } from '@reduxjs/toolkit';
import stateSlice from './common/stateSlice';
import agentApplicationsSlice from './common/agentApplicationsSlice';
import offersSlice from './LMSHQ/offersSlice';
import preDisbursmentSlice from './LMSHQ/preDisbursmentSlice';
import preDisburseForAgentSlice from './common/preDisburseForAgentSlice';

export const store = configureStore({
  reducer: {
    header: stateSlice.reducer,
    agentApplication: agentApplicationsSlice.reducer,
    coLending: offersSlice.reducer,
    disbursement: preDisbursmentSlice.reducer,
    preDisbursal: preDisburseForAgentSlice.reducer,
  },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
