/** @format */

import { VegaProgramNetworkType } from './program';

export interface VegaClientInterface {
  clientId: string;
  clientName: string;
  clientUrl: string;
  clientEmail: string;
  clientMob: string;
  agreement: string;
  status: string;
  logo: string;
  crmLogo: string;
  statementAppLogo: string;
  mobileAppLogo: string;
  createdAt: Date;
  updatedAt: Date;
  address?: string | null;
  businessType?: string | null;
  cinNumber?: string | null;
  gstNumber?: string | null;
  panNumber?: string | null;
  role?: string | null;
}

export interface IVegaClientScope {
  scope: IVegaClientScopeGroup;
}
export interface IVegaClientScopeGroup {
  [featureGroup: string]: IVegaClientScopeGroupTypeRule;
}

export interface IVegaClientScopeGroupTypeRule {
  [featureType: string]: IVegaClientScopeProgramRoles;
}
export interface IVegaClientScopeProgramRoles {
  [programId: string]: string[];
}

export interface ILoggedInUser {
  email: string;
  mobileNumber?: string;
  loginTime: Date;
  idToken: string;
  accessToken: string;
  refreshToken: string;
  name?: string;
  user?: any;
}

export enum VegaClientLogoType {
  COMPANY = 'COMPANY',
  STATEMENT_APP = 'STATEMENT_APP',
  CRM = 'CRM',
  MOBILE_APP = 'MOBILE_APP',
}

export interface UpdateClientLogoRequestData {
  logoType: VegaClientLogoType;
  logo: File;
  clientId: string;
}

export const getNameForClientLogoType = (logoType: VegaClientLogoType) => {
  switch (logoType) {
    case VegaClientLogoType.COMPANY:
      return 'Logo';
    case VegaClientLogoType.CRM:
      return 'CRM Logo';
    case VegaClientLogoType.MOBILE_APP:
      return 'Mobile App Logo';
    case VegaClientLogoType.STATEMENT_APP:
      return 'Statement App Logo';
  }
};

export enum VegaBinType {
  SHARED = 'SHARED',
  DEDICATED = 'DEDICATED',
}

export interface VegaBin {
  id: number;
  clientId: string;
  binName: string;
  binValue: string;
  binType: VegaBinType;
  network: VegaProgramNetworkType;
  cardFormFactorId: string;
  startKitNumber: number;
  endKitNumber: number;
  lastAllocatedKitNumber: number;
  totalKits: number;
  allocatedKits: number;
  unallocatedKits: number;
  onHoldKits: number;
  availableKits: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface AddBinToProgramRequestData {
  binId: number;
  programId: string;
  binRangeRegex: string;
}

export const DEFAULT_BIN_RANGE_REGEX =
  '(41473800100[0-9]|4147380010[1-9][0-9]|414738001[1-9][0-9]{2})';
