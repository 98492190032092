import { Grid, Stack } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { GREY } from '../../../constants/style';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import LosDataGrid from '../../components/LosDataGrid';
import SolfinAppBar from '../../components/SolfinAppBar';
import { LOS_PROGRAM_ID } from '../../onboarding/api/constants';
import LosButton from '../../onboarding/components/common/LosButton';
import LosText from '../../onboarding/components/common/LosText';
import LosAppBar from '../../onboarding/components/LosAppBar';
import { AddAgentFormData } from '../../onboarding/types/Los';
import { StringUtility } from '../../onboarding/utils/StringUtility';
import { LeadService } from '../api/LeadService';
import AddAgentDialog, {
  AddAgentDialogViewState,
} from '../components/Agent/AddAgentDialog';
import { Agent } from '../types/Lms';

type AddAgentDialogData = {
  show: boolean;
  agent: Agent | null;
};

function Agents() {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const { setSnackbar } = useSnackbar();
  const [showAgentDialog, setShowAgentDialog] = useState<AddAgentDialogData>({
    show: false,
    agent: null,
  });

  async function fetchAgents() {
    try {
      setLoading(true);
      const response = await LeadService.getAllAgent({
        programId: LOS_PROGRAM_ID,
        page: page,
        pageSize: pageSize,
      });
      setAgents(response.records);
      setPage(response.pageNumber);
      setRowCount(response.totalItems);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function createAgent(data: AddAgentFormData) {
    try {
      setLoading(true);
      const response = await LeadService.createAgent({
        programId: LOS_PROGRAM_ID,
        name: data.name,
        email: data.email,
        phone: data.mobileNumber,
        gender: data.gender,
        address: {
          line1: data.address,
        },
      });
      const updatedAgents = [response, ...agents];
      setAgents(updatedAgents);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function updateAgent(data: AddAgentFormData) {
    try {
      const agentId = showAgentDialog.agent?.id;
      if (!agentId) return;
      setLoading(true);
      const response = await LeadService.updateAgent(agentId, {
        programId: LOS_PROGRAM_ID,
        name: data.name,
        email: data.email,
        phone: data.mobileNumber,
        gender: data.gender,
        address: {
          line1: data.address,
        },
      });
      const updatedAgents = agents.map((item) => {
        if (item.id == agentId) return response;
        return item;
      });
      setAgents(updatedAgents);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const COLUMN_DEF: GridColumns<Agent> = [
    {
      field: 'agentId',
      headerName: 'Agent Id',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Agent;
        return row.id;
      },
      flex: 2,
    },
    {
      field: 'agentName',
      headerName: 'Agent Name',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Agent;
        var displayText = '-';
        if (row.name) {
          displayText = row.name;
        }
        return displayText;
      },
      flex: 1,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Agent;
        var displayText = '-';
        if (row.gender) {
          displayText = row.gender;
        }
        return displayText;
      },
      flex: 1,
    },
    {
      field: 'mobileNumber',
      headerName: 'Mobile Number',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as Agent;
        var displayText = '-';
        if (application?.phone) {
          displayText = application.phone;
        }
        return displayText;
      },
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Agent;
        var displayText = '-';
        if (row.email) {
          displayText = row.email;
        }
        return displayText;
      },
      flex: 1,
    },
    {
      field: 'address',
      headerName: 'Address',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Agent;
        return StringUtility.formatAddressToReadable(row.address);
      },
      flex: 1,
    },

    {
      field: 'action',
      headerName: 'Action',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Agent;
        return (
          <LosButton
            variant="outlined"
            size="small"
            text="Edit"
            onClick={() => {
              setShowAgentDialog({
                show: true,
                agent: row,
              });
            }}
          />
        );
      },
      flex: 1,
    },
  ];

  useEffect(() => {
    fetchAgents();
  }, [page, pageSize]);

  return (
    <div>
      <SolfinAppBar
        title={'Agent'}
        renderActions={() => {
          return (
            <Stack direction={'row'} justifyContent="end" alignItems={'end'}>
              <LosButton
                text="Add Agent"
                onClick={() => {
                  setShowAgentDialog({ show: true, agent: null });
                }}
                size="small"
              />
            </Stack>
          );
        }}
      />
      <div
        style={{
          margin: '1rem',
        }}
      >
        <LosDataGrid
          data={agents}
          columns={COLUMN_DEF}
          idColumn={'id'}
          page={page}
          pageSize={pageSize}
          loading={loading}
          onPageChange={setPage}
          rowCount={rowCount}
        />
      </div>

      <AddAgentDialog
        open={showAgentDialog.show}
        onClose={function (): void {
          setShowAgentDialog({
            show: false,
            agent: null,
          });
        }}
        initialViewState={
          showAgentDialog.agent != null
            ? AddAgentDialogViewState.Update
            : AddAgentDialogViewState.Add
        }
        onSubmit={(data) => {
          if (showAgentDialog.agent) {
            updateAgent(data);
          } else {
            createAgent(data);
          }
        }}
        initialFormData={{
          name: showAgentDialog.agent?.name ?? '',
          gender: showAgentDialog.agent?.gender ?? '',
          mobileNumber: showAgentDialog.agent?.phone ?? '',
          email: showAgentDialog.agent?.email ?? '',
          address: showAgentDialog.agent?.address.line1 ?? '',
        }}
      />
    </div>
  );
}

export default Agents;
