import { createContext, useContext, useEffect, useState } from 'react';
import { Promoter } from '../types/Los';

export enum CompanyPanVerificationState {
  ValidateCompanyPan,
  AddPromoter,
  PromoterAdded,
  PromoterSelfie,
  VerifiedPromotersList,
  CompanyDetails,
  GstInput,
  CinInput,
  PinInput
}

type CompanyPanVerificationContextType = {
  currentState: CompanyPanVerificationState;
  companyPanDetails: CompanyPanDetails;
  updateCompanyPanDetails: (companyPanDetails: CompanyPanDetails) => void;
  updateState: (state: CompanyPanVerificationState) => void;
  promoters: Promoter[];
  addPromoter: (promoter: Promoter) => void;
};

const CompanyPanVerificationContext =
  createContext<CompanyPanVerificationContextType | null>(null);

export const useCompanyPanVerification = () =>
  useContext(
    CompanyPanVerificationContext
  ) as CompanyPanVerificationContextType;

export const CompanyPanProvider = ({ children }: any) => {
  const [currentState, setCurrentState] = useState<CompanyPanVerificationState>(
    CompanyPanVerificationState.CompanyDetails
  );
  const [promoters, setPromoters] = useState<Promoter[]>([]);
  const [companyPanDetails, setCompanyPanDetails] = useState<CompanyPanDetails>(
    {
      name: '',
      panNumber: '',
    }
  );

  function updateState(updatedState: CompanyPanVerificationState) {
    setCurrentState(updatedState);
  }

  function _onCompanyPanDetailsChange(companyPanDetails: CompanyPanDetails) {
    setCompanyPanDetails(companyPanDetails);
  }

  function _addPromoter(promoter: Promoter) {
    setPromoters([...promoters, promoter]);
  }

  return (
    <CompanyPanVerificationContext.Provider
      value={{
        promoters: promoters,
        currentState: currentState,
        companyPanDetails: companyPanDetails,
        updateCompanyPanDetails: _onCompanyPanDetailsChange,
        updateState: updateState,
        addPromoter: _addPromoter,
      }}
    >
      {children}
    </CompanyPanVerificationContext.Provider>
  );
};

export type CompanyPanDetails = {
  name: string;
  panNumber: string;
};
