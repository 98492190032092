import { Grid } from '@mui/material';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { ReadOnlyFormField } from '../../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField';
import LosText from '../../components/common/LosText';
import { useApplicationPreview } from '../../providers/ApplicationPreviewProvider';
import { CollateralDetails } from '../../types/Los';
import { LocalStorageUtility, StorageKeys } from '../../utils/StorageUtility';
import { StringUtility } from '../../utils/StringUtility';

function CollateralAndGuarantorDetailsPreview() {
  const { fetchCollateralDetails, applicationId } = useApplicationPreview();
  const [loading, setLoading] = useState<boolean>(false);
  const [collateralDetails, setCollateralDetails] = useState<
    Partial<CollateralDetails>
  >({});

  async function fetchDetails() {
    try {
      // const applicationId = LocalStorageUtility.getItem<string>(
      //   StorageKeys.APPLICATION_ID
      // );
      if (!applicationId) return;
      setLoading(true);
      const response = await fetchCollateralDetails(applicationId);

      setCollateralDetails(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const hasCollateralDetails = () => {
    return (collateralDetails.collateralDetails ?? []).length > 0;
  };

  const hasGurantorDetails = () => {
    return (collateralDetails.guaranteeDetails ?? []).length > 0;
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <div>
      <Grid container rowSpacing={'1.25rem'}>
        <Grid item xs={12}>
          <ReadOnlyFormField
            title="Loan Type"
            description={collateralDetails.loanType}
          />
        </Grid>

        <Grid item xs={12}>
          <LosText text="Collateral Details" />
          {hasCollateralDetails() == false && <LosText text="-" />}
        </Grid>

        <Grid item xs={12}>
          <Grid container rowSpacing={'2rem'}>
            {collateralDetails.collateralDetails?.map((item, index) => {
              return (
                <Grid item xs={12}>
                  <Grid container key={index}>
                    <Grid item xs={6} sm={3}>
                      <ReadOnlyFormField
                        title="Collateral Type"
                        description={_.startCase(_.toLower(item.type))}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <ReadOnlyFormField
                        title="Collateral Value"
                        description={StringUtility.formatCurrency(item.amount)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <LosText text="Guarantor Details" />
          {hasGurantorDetails() == false && <LosText text="-" />}
        </Grid>
        {collateralDetails.guaranteeDetails?.map((item, index) => {
          return (
            <Grid container key={index} rowSpacing="1.25rem">
              <Grid item xs={6} sm={3}>
                <ReadOnlyFormField title="Name" description={item?.name} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ReadOnlyFormField
                  title="Relationship"
                  description={item?.relationShip}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ReadOnlyFormField title="Email" description={item?.email} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ReadOnlyFormField
                  title="Pan Card"
                  description={item?.panCard}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ReadOnlyFormField
                  title="Collateral Type"
                  description={item?.guaranteeType}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ReadOnlyFormField
                  title="Collateral Value"
                  description={item?.valueOfGuarantee}
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default CollateralAndGuarantorDetailsPreview;
