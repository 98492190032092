import React from 'react';
import { Stack, Box } from '@mui/material';
import LosText from '../LosText';
import { toLowerCase } from '../../../constants/commonFunction';
import RuleEngineType from './RuleEngineType';
import {
  RuleTypeEnum,
  useRuleEngine,
} from '../../LMSAgent/Provider/RuleEngingProvider';
import FinalCriteriaRule from './RuleType/FinalCriteriaRule/FinalCriteriaRule';
import CollateralRule from './RuleType/CollateralRule/CollateralRule';
import CreditWorthinessRule from './RuleType/CreditWorthinessRule/CreditWorthinessRule';
import FinancialRule from './RuleType/FinancialRule/FinancialRule';
import ManagementRule from './RuleType/ManagementRule/ManagementRule';
import ComplianceRule from './RuleType/ComplianceRule/ComplianceRule';

const RuleEngine = () => {
  const { selectedRuleEngineType } = useRuleEngine();

  return (
    <>
      <Stack direction={'row'}>
        <Box sx={{ width: '160px', borderRight: `1px solid #E1E4EB` }}>
          <RuleEngineType />
        </Box>
        <Box
          sx={{
            px: 2,
            flex: 1,
          }}
        >
          {getRuleComponents(selectedRuleEngineType)}
        </Box>
      </Stack>
    </>
  );
};

export default RuleEngine;

const getRuleComponents = (ruleType: string) => {
  switch (ruleType) {
    case RuleTypeEnum.COLLATERAL:
      return <CollateralRule />;
    case RuleTypeEnum.CREDIT_WORTHINESS:
      return <CreditWorthinessRule />;
    case RuleTypeEnum.FINANCIAL:
      return <FinancialRule />;
    case RuleTypeEnum.MANAGMENT:
      return <ManagementRule />;
    case RuleTypeEnum.COMPLIANCE:
      return <ComplianceRule />;

    default:
      return <FinalCriteriaRule />;
  }
};
