import { useLos } from '../../providers/LosProvider';
import {
  isUserIndividual,
  isUserNonIndividual,
  isUserSelfEmployed,
} from '../../types/Los';
import PageNotFound from '../PageNotFound';
import CompanyPanContainer from './CompanyPanContainer';
import IndividualPanDetails from './IndividualPanDetails';

function PanDetails() {
  const { currentUserType } = useLos();

  if (
    isUserIndividual(currentUserType) ||
    isUserSelfEmployed(currentUserType)
  ) {
    return <IndividualPanDetails />;
  } else if (isUserNonIndividual(currentUserType)) {
    return <CompanyPanContainer />;
  }

  return <PageNotFound />;
}

export default PanDetails;
