import { Divider, Grid } from '@mui/material'
import { useState, useEffect } from 'react'
import { useSnackbar } from '../../../../providers/SnackbarProvider'
import { getErrorMessageFromErrorObj } from '../../../../utils/api'
import { ReadOnlyFormField } from '../../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField'
import { LosService } from '../../api/LosService'
import { ReadOnlyLosFileSelect } from '../../components/common/LosFileSelect'
import LosFormInputField from '../../components/common/LosFormInputField'
import { useApplicationPreview } from '../../providers/ApplicationPreviewProvider'
import { CompanyPreviewData } from '../../types/Los'

function CompanyDetailsPreview() {
  const { setSnackbar } = useSnackbar()
  const { fetchCompanyDetails, applicationId } = useApplicationPreview()
  const [loading, setLoading] = useState<boolean>(false)
  const [companyDetails, setCompanyDetails] = useState<
    Partial<CompanyPreviewData>
  >({})

  async function fetchDetails() {
    try {
      if (!applicationId) return
      setLoading(true)
      const response = await fetchCompanyDetails(applicationId)
      setCompanyDetails(response)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const hasGstCertficate = () =>
    (companyDetails.gstCertification ?? []).length > 0
  const hasCertficateOfIncorporation = () =>
    (companyDetails.certificateOfIncorporation ?? []).length > 0

  const getDisplayType = (hasValue: boolean) => {
    if (hasValue) return 'block'
    return 'none'
  }

  async function onDownload(documentType: string) {
    try {
      if (!applicationId) return
      const response = await LosService.getDocument({
        documentType: documentType,
        applicationId: applicationId,
      })
      window.open(response.url, '__blank')
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    }
  }
  useEffect(() => {
    fetchDetails()
  }, [])

  return (
    <div style={{ width: '100%' }}>
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12} sm={4}>
          <ReadOnlyFormField
            title='Company Name'
            description={companyDetails.companyName}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ReadOnlyFormField
            title='Gst No'
            description={companyDetails.gstNo}
          />
        </Grid>
      </Grid>
      <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />
      <Grid container rowSpacing='1.5rem' columnGap='1rem'>
        <Grid item xs={12} sm={3} display={getDisplayType(hasGstCertficate())}>
          <LosFormInputField label='GST Certificate'>
            <Grid container columnGap='1rem'>
              {companyDetails.gstCertification?.map((item, index) => {
                return (
                  <Grid key={index} item xs={12}>
                    <ReadOnlyLosFileSelect
                      fileName={item.documentName}
                      onDownload={() => onDownload(item.documentType)}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </LosFormInputField>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          display={getDisplayType(hasCertficateOfIncorporation())}
        >
          <LosFormInputField label='Certificate Of Incorporation'>
            <Grid container columnGap='1rem'>
              {companyDetails.certificateOfIncorporation?.map((item, index) => {
                return (
                  <Grid key={index} item xs={12}>
                    <ReadOnlyLosFileSelect
                      fileName={item.documentName}
                      onDownload={() => onDownload(item.documentType)}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </LosFormInputField>
        </Grid>
        {/* <Grid item xs={12} sm={3} display={getDisplayType(hasMoa())}>
          <LosFormInputField label="MOA">
            <Grid container columnGap="1rem">
              {companyDetails.moa?.map((item, index) => {
                return (
                  <Grid key={index} item xs={12}>
                    <ReadOnlyLosFileSelect
                      fileName={item.documentName}
                      onDownload={() => onDownload(item.documentType)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </LosFormInputField>
        </Grid> */}
        {/* <Grid item xs={12} sm={3} display={getDisplayType(hasAoa())}>
          <LosFormInputField label="AOA">
            <Grid container columnGap="1rem">
              {companyDetails.aoa?.map((item, index) => {
                return (
                  <Grid key={index} item xs={12}>
                    <ReadOnlyLosFileSelect
                      fileName={item.documentName}
                      onDownload={() => onDownload(item.documentType)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </LosFormInputField>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          sm={3}
          display={getDisplayType(hasPartnershipFirmDeed())}
        >
          <LosFormInputField label="Partnership Firm Deed">
            <Grid container columnGap="1rem">
              {companyDetails.partnershipFirmDeed?.map((item, index) => {
                return (
                  <Grid key={index} item xs={12}>
                    <ReadOnlyLosFileSelect
                      fileName={item.documentName}
                      onDownload={() => onDownload(item.documentType)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </LosFormInputField>
        </Grid> */}
      </Grid>
    </div>
  )
}

export default CompanyDetailsPreview
