import { Info } from '@mui/icons-material';
import { Stack } from '@mui/material';
import _ from 'lodash';
import { ReactNode } from 'react';
import VegaPill from '../../components/common/v2/VegaPill';
import LosText from '../onboarding/components/common/LosText';

type Props = {
  status?: string;
  renderEndAdornment?: () => ReactNode;
  renderStartAdornment?: () => ReactNode;
};

const STATUS_COLOR_MAP: Record<
  string,
  { textColor: string; backgroundColor: string }
> = {
  MOVED_TO_LEDGER: {
    backgroundColor: 'rgba(248, 232, 232, 1)',
    textColor: 'rgba(100, 180, 80, 1)',
  },
  PAID: {
    backgroundColor: 'rgba(235, 244, 236, 1)',
    textColor: 'rgba(54, 145, 63, 1)',
  },
  UNPAID: {
    backgroundColor: 'rgba(248, 232, 232, 1)',
    textColor: 'rgba(186, 28, 28, 1)',
  },
  PARTIAL_PAID: {
    textColor: '#ddba08',
    backgroundColor: 'rgba(252, 248, 230, 1)',
  },
  READY_FOR_DISPERSAL: {
    backgroundColor: 'rgba(235, 244, 236, 1)',
    textColor: 'rgba(54, 145, 63, 1)',
  },
  DISPERSED: {
    backgroundColor: 'rgba(235, 244, 236, 1)',
    textColor: 'rgba(54, 145, 63, 1)',
  },
  PRE_DISPERSED: {
    textColor: 'rgba(221, 186, 8, 1)',
    backgroundColor: 'rgba(252, 248, 230, 1)',
  },
  IN_PROGRESS: {
    textColor: 'rgba(221, 186, 8, 1)',
    backgroundColor: 'rgba(252, 248, 230, 1)',
  },
  PENDING: {
    textColor: 'rgba(221, 186, 8, 1)',
    backgroundColor: 'rgba(252, 248, 230, 1)',
  },
  REJECTED: {
    backgroundColor: 'rgba(248, 232, 232, 1)',
    textColor: 'rgba(186, 28, 28, 1)',
  },
  APPROVED: {
    backgroundColor: 'rgba(235, 244, 236, 1)',
    textColor: 'rgba(54, 145, 63, 1)',
  },
  COMPLETED: {
    backgroundColor: 'rgba(235, 244, 236, 1)',
    textColor: 'rgba(54, 145, 63, 1)',
  },
  ACCEPTED: {
    backgroundColor: 'rgba(235, 244, 236, 1)',
    textColor: 'rgba(54, 145, 63, 1)',
  },
  DEFAULT: {
    backgroundColor: 'white',
    textColor: 'black',
  },
};
function LosStatusPill({
  status,
  renderEndAdornment,
  renderStartAdornment,
}: Props) {
  var colorObject = STATUS_COLOR_MAP[status ?? 'DEFAULT'];
  if (!colorObject) {
    colorObject = STATUS_COLOR_MAP.DEFAULT;
  }

  const displayText = () => {
    if (status == 'READY_FOR_DISPERSAL') return 'Ready For Disbursal';
    else if (status == 'APPROVED') return 'Agreement Pending';
    else if (status == 'DISPERSED') return 'Disbursed';
    else if (status == 'PRE_DISPERSED') return 'Pre Disbursed';
    return _.startCase(_.toLower(status));
  };

  return (
    <VegaPill
      backgroundColor={colorObject.backgroundColor}
      textColor={colorObject.textColor}
    >
      <Stack direction={'row'} spacing="0.5rem" alignItems={'center'}>
        {renderStartAdornment?.()}
        <LosText
          style={{
            fontSize: '0.625rem',
            fontWeight: 600,
            lineHeight: '120%',
          }}
          text={displayText()}
        />
        {renderEndAdornment?.()}
      </Stack>
    </VegaPill>
  );
}

export default LosStatusPill;
