/** @format */

import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
  BinCharterIcon,
  HomeIcon,
  InvoiceOutlineIcon,
  PriceCharterIcon,
  Program_manage,
  ResourcesIcon,
  ServiceIcon,
  SettingIcon,
  TeamOutlineIcon,
  UniversalIcon,
  UserAccessIcon,
} from '../components/icon/Icons';
import SidebarLayout, { SidebarListItemType } from '../components/layouts/SidebarLayout';
import BinCharter from '../pages/BinCharter';
import ClientDashBoard from '../pages/ClientDashBoard';
import FinanceCenter from '../pages/FinanceCenter';
import ManageTeams from '../pages/ManageTeams';
import MoreClientOptions from '../pages/MoreClientOptions';
import PlanCharter from '../pages/PlanCharter';
import ProgramManagement from '../pages/ProgramManagement';
import Resources from '../pages/Resources';
import RolesAndAccess from '../pages/RolesAndAccess';
import ServicesSetup from '../pages/ServicesSetup';
import UniversalStandards from '../pages/UniversalStandards';
import { DASHBOARD_ROUTES, ROUTES } from '../routes';

type Props = {};

const NAV_OPTIONS: SidebarListItemType[] = [
  {
    id: ROUTES.HOME,
    icon: <HomeIcon />,
    label: 'Home',
    component: <ClientDashBoard />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.PROGRAM_MANAGE,
    icon: <Program_manage />,
    label: 'Program management',
    component: <ProgramManagement />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.SERVICE_SETUP,
    icon: <ServiceIcon />,
    label: 'Service Setup',
    component: <ServicesSetup />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.RESOURCES,
    icon: <ResourcesIcon />,
    label: 'Resources',
    component: <Resources />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.MANAGE_TEAMS,
    icon: <TeamOutlineIcon />,
    label: 'Manage Teams',
    component: <ManageTeams />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.ROLES_AND_ACCESS,
    icon: <UserAccessIcon />,
    label: 'Roles & Access',
    component: <RolesAndAccess />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.UNI_STANDARDS,
    icon: <UniversalIcon />,
    label: 'Institution Standards',
    component: <UniversalStandards />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.PRICE_CHARTER,
    icon: <PriceCharterIcon />,
    label: 'Program Schemes',
    component: <PlanCharter />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.BIN_CHARTER,
    icon: <BinCharterIcon />,
    label: 'Bin Charter',
    component: <BinCharter />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.FINANCE_CENTER,
    icon: <InvoiceOutlineIcon />,
    label: 'Finance Center',
    component: <FinanceCenter />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
  {
    id: ROUTES.CLIENT_MORE_OPTIONS,
    icon: <SettingIcon />,
    label: 'More',
    component: <MoreClientOptions />,
    isProgramSelector: false,
    isAccountSelector: false,
    isClientRoutes: true,
  },
];
export const SELECTED_VIEW = 'view';
const ClientDashboardContainer = (props: Props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedItem, setSelectedItem] = useState<SidebarListItemType>(NAV_OPTIONS[0]);

  useEffect(() => {
    setSearchParams(createSearchParams({ [SELECTED_VIEW]: selectedItem.id }));
  }, []);

  const handleClick = (item: SidebarListItemType) => {
    if (item.id === '') {
      navigate(DASHBOARD_ROUTES.CLIENT_DASHBOARD);
    } else if (item.isClientRoutes) {
      setSelectedItem(item);
      setSearchParams(createSearchParams({ [SELECTED_VIEW]: item.id }));
      // navigate(ROUTES.RESOURCES);
    } else {
      navigate(item.id);
    }
  };

  return (
    <Box>
      <SidebarLayout
        sidebarListItems={NAV_OPTIONS}
        onItemClick={handleClick}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    </Box>
  );
};

export default ClientDashboardContainer;
