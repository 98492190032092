import { Stack, Grid } from '@mui/material';
import {
  InfoCard,
  InfoTitle,
  InfoDescription,
} from '../../components/ActiveLoan/InfoCard';
import LosText from '../../components/common/LosText';

function SolfinBankDetails() {
  return (
    <div>
      <Title text="Payment Details" />
      <SubTitle text="You need to use the below bank details for making your EMI payment. You can use UPI/ Net banking / Credit-Debit Card to pay your EMI" />
      <InfoCard>
        <Stack spacing={'1rem'}>
          <Grid
            container
            alignItems="center"
            justifyContent={'space-between'}
            rowSpacing="1rem"
          >
            <Grid item xs={12}>
              <Title text="Bank Details" />
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <InfoTitle text="account Holder name" />
                <InfoDescription text={'Solfin Pvt. Ltd'} />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <InfoTitle text="Account no" />
                <InfoDescription text={'373123451515'} />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <InfoTitle text="IFSC code" />
                <InfoDescription text={'VPBK3413'} />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <InfoTitle text="Branch" />
                <InfoDescription text={'NAGAR'} />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </InfoCard>
    </div>
  );
}

export default SolfinBankDetails;

const Title = (data: { text: string }) => {
  return (
    <LosText
      text={data.text}
      style={{
        color: '#1B1D22',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '140%',
        marginBottom: '0.5rem',
      }}
    />
  );
};

const SubTitle = (data: { text: string }) => {
  return (
    <LosText
      text={data.text}
      style={{
        color: '#676B76',
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: '130%',
        marginBottom: '1rem',
      }}
    />
  );
};
