import { Stack } from '@mui/material'
import LosDialog from '../../components/common/LosDialog'
import LosOnboardingButton from '../../components/common/LosOnboardingButton'
import LosText from '../../components/common/LosText'

type Props = {
  open: boolean
  description: string
  buttonText: string
  onClose: () => void
  onSubmit: () => void
  onSecondaryButtonClick?: () => void
  secondaryButtonText: string
  textTypeSecondaryButton: boolean
}
function CreateEstampUrlConfirmationDialog({
  open,
  onClose,
  onSubmit,
  description,
  buttonText,
  onSecondaryButtonClick,
  secondaryButtonText,
}: Props) {
  function onSubmitClick() {
    onSubmit()
    onClose()
  }

  return (
    <LosDialog open={open} onClose={() => {}}>
      <div
        style={{
          maxWidth: '22.5rem',
          minWidth: '20rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            paddingBottom: '40px',
          }}
        >
          <LosText
            text={description}
            textAlign='center'
            style={{
              fontWeight: 600,
              lineHeight: '120%',
              fontSize: '1.0625rem',
            }}
          />
        </div>
        <Stack direction={'row'} gap={2}>
          <LosOnboardingButton
            fullWidth
            text={buttonText}
            onClick={onSubmitClick}
            variant='text'
            sx={{
              fontFamily: 'Aspekta',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '20px',
              letterSpacing: '0px',
              textAlign: 'left',
            }}
          />
          <LosOnboardingButton
            fullWidth
            text={secondaryButtonText}
            onClick={onSecondaryButtonClick}
          />
        </Stack>
      </div>
    </LosDialog>
  )
}

export default CreateEstampUrlConfirmationDialog
