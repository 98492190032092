import axios from 'axios';
import { LMS } from '../endpoints';

const UNDERWRITTING_BASE_URL = LMS.UNDERWRITTING;

export const getUnderWrittingScoreById = (applicationId: string) => {
  return axios.get(
    `${UNDERWRITTING_BASE_URL}/application/${applicationId}/score`
  );
};
export const updateManagementScore = (
  applicationId: string,
  comment: string
) => {
  return axios.put(
    `${UNDERWRITTING_BASE_URL}/management/application/${applicationId}?comment=${comment}`
  );
};
export const updateVetoStatus = (applicationId: string, status: boolean) => {
  return axios.put(
    `${UNDERWRITTING_BASE_URL}/compliance/veto/application/${applicationId}?veto=${status}`
  );
};
