import { CircularProgress, Stack } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import { useClientAuth } from '../../providers/ClientProvider'
import { ONBOARDING_CREDS } from '../../solfen/onboarding/api/constants'

type Props = {
  children: ReactNode
}

export function PrivateOnboardingRoutes({ children }: Props) {
  const { isAuth, login } = useClientAuth()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (isAuth == false) {
      setLoading(true)
      login(null, ONBOARDING_CREDS.email, ONBOARDING_CREDS.password)
    }
  }, [isAuth])

  if (isAuth == true) return <>{children}</>

  if (loading) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return <>Something Went wrong</>
}
