import { Grid, Box, Stack } from '@mui/material';
import { useState } from 'react';
import { GREY, BORDER_GRAY } from '../../../constants/style';
import SolfinAppBar from '../../components/SolfinAppBar';
import LosButton from '../../onboarding/components/common/LosButton';
import LosText from '../../onboarding/components/common/LosText';
import CoLendersList from '../components/Co-Lenders/CoLendersList';
import AddColenderDialog from '../components/Co-Lenders/components/AddColenderDialog';
import { ColenderDetailsFormData } from '../components/Co-Lenders/components/Forms/formData';
import SelectedCoLenderDetails from '../components/Co-Lenders/SelectedCoLenderDetails';
import { useColenders } from '../providers/ColendersProvider';

function ManageColenders() {
  const { createColender } = useColenders();
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function onSubmit(formData: ColenderDetailsFormData) {
    setLoading(true);
    await createColender(formData);
    setLoading(false);
  }

  return (
    <>
      <SolfinAppBar
        title={'Manage Co-Lenders'}
        renderActions={() => {
          return (
            <Stack direction={'row'} justifyContent="end" alignItems={'end'}>
              <LosButton
                text="Add Colender"
                onClick={() => {
                  setOpenAddDialog(true);
                }}
                size="small"
              />
            </Stack>
          );
        }}
      />
      <Box m={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                border: BORDER_GRAY,
                bgcolor: 'white',
                borderRadius: '10px',
              }}
            >
              <CoLendersList />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Box
              sx={{
                border: BORDER_GRAY,
                p: 1,
                bgcolor: 'white',
                borderRadius: '10px',
              }}
            >
              <SelectedCoLenderDetails />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <AddColenderDialog
        open={openAddDialog}
        onClose={function (): void {
          setOpenAddDialog(false);
        }}
        onSubmit={onSubmit}
      />
    </>
  );
}

export default ManageColenders;
