import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import VegaTab from '../../../../components/common/VegaTab';
import { BLUE } from '../../../../constants/style';
import LoanEmiScheduleTab from './Tabs/LoanEmiScheduleTab';
import LoanDetailsTab from './Tabs/LoanDetails/LoanDetailsTab';
import ApplicationDetailsTab from './Tabs/ApplicationDetailsTab';
import LoanRepaymentScheduleTab from './Tabs/LoanRepaymentScheduleTab';
import LosButton from '../../../onboarding/components/common/LosButton';
import PayEmiDialog from './components/PayEmiDialog';
import { useActiveLoans } from '../../providers/ActiveLoansProvider';
import { getErrorMessageFromErrorObj } from '../../../../utils/api';
import { LoanManagementService } from '../../api/LoanManagementService';
import { useSnackbar } from '../../../../providers/SnackbarProvider';

enum LoanDetailsTabType {
  Application = 'Application Details',
  Loan = 'Loan Details',
  Emi = 'Emi Schedule',
  Repayment = 'Loan Ledger',
}

const SelectedLoanDetails = () => {
  const [selectedTab, setSelectedTab] = useState<string>(
    LoanDetailsTabType.Loan
  );
  const [showPayEmiDialog, setShowEmiDialog] = useState<boolean>(false);
  const { selectedLoan } = useActiveLoans();
  const { setSnackbar } = useSnackbar();

  async function onPayEmiClick(
    loanId: string,
    amount: string,
    utrNumber: string,
    date: string
  ) {
    try {
      const response = await LoanManagementService.createRepayment({
        loanId: loanId,
        amount: Number(amount),
        dateTime: date,
        type: 'CREDIT',
        currency: 'INR',
        utr: utrNumber,
      });
      setSnackbar('Repayment Registered');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  return (
    <>
      <Stack
        direction={'row'}
        justifyContent="space-between"
        alignItems={'center'}
      >
        <Box px={1} sx={{ overflow: 'auto' }}>
          <VegaTab
            placement="vertical"
            color={BLUE.lighter}
            tabData={[
              LoanDetailsTabType.Loan,
              LoanDetailsTabType.Emi,
              LoanDetailsTabType.Repayment,
              LoanDetailsTabType.Application,
            ]}
            selected={selectedTab}
            onSelect={(select) => setSelectedTab(select)}
          />
        </Box>
        <LosButton
          size="small"
          text="Register Payment"
          onClick={() => {
            setShowEmiDialog(true);
          }}
        />
      </Stack>
      {selectedTab == LoanDetailsTabType.Application && (
        <ApplicationDetailsTab />
      )}
      {selectedTab == LoanDetailsTabType.Loan && <LoanDetailsTab />}
      {selectedTab == LoanDetailsTabType.Emi && <LoanEmiScheduleTab />}
      {selectedTab == LoanDetailsTabType.Repayment && (
        <LoanRepaymentScheduleTab />
      )}

      <PayEmiDialog
        open={showPayEmiDialog}
        loanId={selectedLoan?.id}
        onClose={function (): void {
          setShowEmiDialog(false);
        }}
        onSubmit={onPayEmiClick}
      />
    </>
  );
};

export default SelectedLoanDetails;
