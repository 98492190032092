import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSnackbar } from '../../../../../providers/SnackbarProvider'
import { getErrorMessageFromErrorObj } from '../../../../../utils/api'
import AadharNumberInputDialog from '../../../components/AadharDialog/AadharNumberInputDialog'
import AadharOtpInputDialog from '../../../components/AadharDialog/AadharOtpInputDialog'
import VerifyAadharDetailsDialog from '../../../components/AadharDialog/VerifyAadharDetailsDialog'
import LosFormInputField from '../../../components/common/LosFormInputField'
import LosText from '../../../components/common/LosText'
import LosAppBar from '../../../components/LosAppBar'
import { LosFormButtonView } from '../../../components/LosFormButton'
import LosFormContainer from '../../../components/LosFormContainer'
import { PanDetailsCard } from '../../../components/PanDetailsCard'
import PromoterDetailsCard from '../../../components/PromoterDetailsCard'
import VerificationSuccessDialog from '../../../components/VerificationSuccessDialog'
import VerifyPanDetailsDialog from '../../../components/VerifyPanDetailsDialog'
import { AddOutlinedIcon } from '../../../icons/LosIcons'
import {
  CompanyPanVerificationState,
  useCompanyPanVerification,
} from '../../../providers/CompanyPanProvider'
import { useLosAuth } from '../../../providers/LosAuthProvider'
import { useLos } from '../../../providers/LosProvider'
import {
  AadharVerificationOtpResponse,
  CustomerPanData,
  LosStep,
  PanMetadata,
} from '../../../types/Los'
import { LocalStorageUtility, StorageKeys } from '../../../utils/StorageUtility'

function PromoterAdded() {
  const {
    customerApplication,
    fetchPanDetailsForPanInfoId,
    verifyAadharOtp,
    generateAadharOtp,
    navigateToNextStep,
    currentStep,
    currentStepMetaData,
    markPromotersDetailsAsCompleted,
    updateAadharStatusToAccepted,
    fetchApplication,
  } = useLos()
  const { isAuthenticated } = useLosAuth()
  const { setSnackbar } = useSnackbar()
  const { updateState } = useCompanyPanVerification()
  const [companyPanDetails, setCompanyPanDetails] = useState<PanMetadata>()

  const [aadhaarNumber, setAadhaarNumber] = useState<string>('')
  const [aadhaarResponse, setAadhaarResponse] =
    useState<AadharVerificationOtpResponse>()
  const [openPanVerificationDialog, setOpenPanVerificationDialog] =
    useState<boolean>(false)
  const [openAadharNumberInputDialog, setOpenAadharNumberInputDialog] =
    useState<boolean>(false)
  const [openAadharOtpInputDialog, setOpenAadharOtpInputDialog] =
    useState<boolean>(false)
  const [openAadharDetailsDialog, setOpenAadharDetailsDialog] =
    useState<boolean>(false)
  const [openAadharVerifiedDialog, setOpenAadharVerifiedDialog] =
    useState<boolean>(false)
  const [openPanVerifiedDialog, setOpenPanVerifiedDialog] =
    useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  async function fetchCompanyPanDeatils() {
    const companyPanId = customerApplication?.companyDetails.panId

    if (companyPanId) {
      setLoading(true)
      const response = await fetchPanDetailsForPanInfoId(companyPanId)

      setCompanyPanDetails(response)
    }
    setLoading(false)
  }

  function onProceedClickInPanVerifiedDialog() {}

  function onProceedClickInAadharVerifiedDialog() {
    navigateToNextStep()
  }

  function onConfirmPanClick() {
    setOpenPanVerifiedDialog(true)
  }

  async function onAadharNumberConfirmClick(
    aadharNumber: string
  ): Promise<void> {
    try {
      setLoading(true)
      const response = await generateAadharOtp({
        aadhaarNumber: aadharNumber,
      })
      setAadhaarNumber(aadharNumber)
      setAadhaarResponse(response)
      setOpenAadharOtpInputDialog(true)
    } catch (error) {
      console.error(error)
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  async function resendAadhaarOtp() {
    try {
      if (!aadhaarNumber) return
      onAadharNumberConfirmClick(aadhaarNumber)
    } catch (error) {}
  }

  async function onAadharOtpConfirmClick(otp: string) {
    try {
      setLoading(true)
      const requestId = aadhaarResponse?.requestId
      if (!requestId) {
        console.error('RequestId not found')
        return
      }
      const response = await verifyAadharOtp({
        requestId: requestId,
        otp: otp,
      })

      if (response == true && aadhaarNumber.length == 12) {
        navigateToNextStep()
      } else {
        setSnackbar('Failed to validate Otp', 'error')
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  function onSubmitClick() {
    if (
      currentStep == LosStep.PAN_ACCEPTED ||
      currentStep == LosStep.PAN_VALIDATION
    ) {
      setOpenPanVerificationDialog(true)
    } else if (currentStep == LosStep.AADHAAR_VALIDATION) {
      setOpenAadharNumberInputDialog(true)
    } else if (currentStep == LosStep.SELFIE_VERIFICATION) {
      updateState(CompanyPanVerificationState.PromoterSelfie)
    } else if (currentStep == LosStep.COMPANY_DETAIL) {
      markPromotersDetailsAsCompleted()
      updateState(CompanyPanVerificationState.CompanyDetails)
    }
  }

  function onAddPromoterClick() {
    updateState(CompanyPanVerificationState.AddPromoter)
  }

  const getSubmitButtonText = () => {
    if (
      currentStep == LosStep.PAN_ACCEPTED ||
      currentStep == LosStep.PAN_VALIDATION
    ) {
      return 'Proceed to Verify Promoter’s Pan'
    } else if (currentStep == LosStep.AADHAAR_VALIDATION) {
      return 'Proceed to Verify Promoter’s Aadhaar'
    } else if (currentStep == LosStep.SELFIE_VERIFICATION) {
      return 'Proceed to Verify Promoter’s Selfie'
    } else if (currentStep == LosStep.COMPANY_DETAIL) {
      return 'Proceed to Company Detail'
    }
  }

  const canShowAddPromoter = () => {
    return (
      currentStep === LosStep.COMPANY_DETAIL &&
      !(
        customerApplication?.users &&
        customerApplication?.currentUserIndex ===
          customerApplication?.users?.length - 1
      )
    )
  }

  async function onConfirmAadharDetailsClick() {
    try {
      setLoading(true)
      await updateAadharStatusToAccepted()
      setOpenAadharVerifiedDialog(true)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  function runAfterDelay(callback: () => void, delay: number) {
    setTimeout(callback, delay)
  }

  async function handleAadharAccepted() {
    setOpenAadharDetailsDialog(true)
    setLoading(false)
  }

  useEffect(() => {
    fetchCompanyPanDeatils()
  }, [])

  useEffect(() => {
    if (isAuthenticated && currentStep == LosStep.AADHAAR_ACCEPTED) {
      setLoading(true)
      runAfterDelay(handleAadharAccepted, 500)
    }
  }, [customerApplication, currentStep, isAuthenticated])

  async function updateCustomerApplicationDetils() {
    const applicationId =
      LocalStorageUtility.getItem<string>(StorageKeys.APPLICATION_ID) ?? ''
    await fetchApplication(applicationId)
  }

  useEffect(() => {
    updateCustomerApplicationDetils()
  }, [])

  console.log('companyPanDetails', companyPanDetails)
  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            onClick={onSubmitClick}
            text={getSubmitButtonText()}
            loading={loading}
          />
        )
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        )
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <LosFormInputField label='Company Pan'>
            <PanDetailsCard
              fullWidth
              name={companyPanDetails?.panName ?? '-'}
              panNumber={companyPanDetails?.panNumber ?? '-'}
              status={companyPanDetails?.valid ? 'Verified' : 'Pending'}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosText
            text='Note: please add all the board members details'
            variant='caption'
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={'1rem'}>
            {customerApplication?.users.map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={
                    index +
                    (openAadharVerifiedDialog + '' + openPanVerificationDialog)
                  }
                >
                  <PromoterDetailsCard userId={item} />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        {canShowAddPromoter() && (
          <Grid item xs={12}>
            <Box
              onClick={onAddPromoterClick}
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'start',
                gap: '0.5rem',
              }}
            >
              <AddOutlinedIcon />
              <LosText
                text='Add Promoter'
                style={{
                  color: '#1047DC',
                  fontSize: '0.75rem',
                  fontWeight: 500,
                  lineHeight: '130%',
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      <VerifyPanDetailsDialog
        open={openPanVerificationDialog}
        onClose={function (): void {
          setOpenPanVerificationDialog(false)
        }}
        onSubmit={function (): void {
          onConfirmPanClick()
        }}
        onTncClick={() => {}}
        panNumber={'formData.panNumber'}
      />
      <AadharNumberInputDialog
        open={openAadharNumberInputDialog}
        onClose={() => {
          setOpenAadharNumberInputDialog(false)
        }}
        onSubmit={onAadharNumberConfirmClick}
      />
      <AadharOtpInputDialog
        open={openAadharOtpInputDialog}
        onClose={() => {
          setOpenAadharOtpInputDialog(false)
        }}
        onSubmit={onAadharOtpConfirmClick}
        onResendOtp={resendAadhaarOtp}
      />
      <VerifyAadharDetailsDialog
        open={openAadharDetailsDialog}
        onClose={function (): void {
          setOpenAadharDetailsDialog(false)
        }}
        onSubmit={onConfirmAadharDetailsClick}
        onTncClick={function (): void {}}
      />
      <VerificationSuccessDialog
        open={openAadharVerifiedDialog}
        onClose={function (): void {
          setOpenAadharVerifiedDialog(false)
        }}
        onSubmit={onProceedClickInAadharVerifiedDialog}
        title={'Verify Aadhaar'}
        description={'Your Aadhaar card has been verified'}
        buttonText={'Proceed'}
      />

      <VerificationSuccessDialog
        open={openPanVerifiedDialog}
        onClose={function (): void {
          setOpenPanVerifiedDialog(false)
        }}
        onSubmit={onProceedClickInPanVerifiedDialog}
        title={'Verify PAN'}
        description={'Your Pan card has been verified'}
        buttonText={'Proceed'}
      />
    </LosFormContainer>
  )
}

export default PromoterAdded
