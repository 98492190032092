import { Close } from '@mui/icons-material';
import { Stack, IconButton } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import LosDialog from '../../components/common/LosDialog';
import LosText from '../../components/common/LosText';
import { useLos } from '../../providers/LosProvider';
import { UserType } from '../../types/Los';
import CollateralAndGuarantorDetailsPreview from './CollateralAndGuarantorDetailsPreview';
import CompanyDetailsPreview from './CompanyDetailsPreview';
import ElectricityDetailsPreview from './ElectricityDetailsPreview';
import FinancialDetailsPreview from './Financial/FinancialDetailsPreview';
import KycDetailsPreview from './Kyc/KycDetailsPreview';
import PreviewAccordian from './PreviewAccordian';

type Props = {
  open: boolean;
  onClose: () => void;
};

function ApplicationPreviewMobileView({ open, onClose }: Props) {
  const { customerApplication } = useLos();

  const canShowCompanyTab = () => {
    const userType = customerApplication?.basicInfo.userType;
    if (!userType) return false;
    if (userType == UserType.INDIVIDUAL) return false;
    return true;
  };

  return (
    <LosDialog
      open={open}
      onClose={onClose}
      backgroundColor="rgba(250, 250, 250, 1)"
    >
      <div>
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems={'center'}
          style={{
            paddingTop: '1.25rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <LosText text="Preview Application" />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <PreviewAccordian
          renderPreview={function (): ReactNode {
            return (
              <KycDetailsPreview
                userType={customerApplication?.basicInfo.userType}
                viewerType="Customer"
              />
            );
          }}
          title={'Kyc Details'}
        />
        <PreviewAccordian
          renderPreview={function (): ReactNode {
            return <FinancialDetailsPreview />;
          }}
          title={'Financial Details'}
        />
        <PreviewAccordian
          renderPreview={function (): ReactNode {
            return <CollateralAndGuarantorDetailsPreview />;
          }}
          title={'Collateral and Guarantor Details'}
        />
        <PreviewAccordian
          renderPreview={function (): ReactNode {
            return <ElectricityDetailsPreview />;
          }}
          title={'Electricty Details'}
        />
        {canShowCompanyTab() && (
          <PreviewAccordian
            renderPreview={function (): ReactNode {
              return <CompanyDetailsPreview />;
            }}
            title={'Company Details'}
          />
        )}
      </div>
    </LosDialog>
  );
}

export default ApplicationPreviewMobileView;
