import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import LosDataGrid from '../../../../components/LosDataGrid'
import LosStatusPill from '../../../../components/LosStatusPill'
import { StringUtility } from '../../../../onboarding/utils/StringUtility'
import { useActiveLoans } from '../../../providers/ActiveLoansProvider'
import { LoanEmiSchedule } from '../../../types/Lms'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

function LoanEmiScheduleTab() {
  const { fetchLoanEmiSchedule, selectedLoan } = useActiveLoans()
  const [loading, setLoading] = useState<boolean>(false)
  const [emiSchedules, setEmiSchedules] = useState<LoanEmiSchedule[]>([])

  async function fetchDetails(loanId: string) {
    try {
      setEmiSchedules([])
      setLoading(true)
      const response = await fetchLoanEmiSchedule(loanId)
      setEmiSchedules(response)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const loanId = selectedLoan?.id
    if (!loanId) return
    fetchDetails(loanId)
  }, [selectedLoan])

  return (
    <div
      style={{
        paddingTop: '1rem',
        paddingBottom: '1rem',
      }}
    >
      <LosDataGrid
        data={emiSchedules}
        columns={COLUMN_DEF}
        idColumn={'id'}
        loading={loading}
      />
    </div>
  )
}

export default LoanEmiScheduleTab

const COLUMN_DEF: GridColumns<LoanEmiSchedule> = [
  {
    field: 'dueDate',
    headerName: 'EMI Due Date',
    renderCell: (props: GridRenderCellParams) => {
      const row = props.row as LoanEmiSchedule
      var displayText = '-'
      if (row.due_date) {
        displayText = dayjs(row.due_date).format('YYYY-MM-DD')
      }
      return displayText
    },
    flex: 1,
  },
  {
    field: 'installment_amount',
    headerName: 'EMI Payment',
    renderCell: (props: GridRenderCellParams) => {
      const row = props.row as LoanEmiSchedule
      var displayText = '-'
      if (row.installment_amount) {
        displayText = `₹ ${StringUtility.formatCurrency(
          row.installment_amount
        )}`
      }
      return displayText
    },
    flex: 1,
  },
  {
    field: 'interest',
    headerName: 'Intrest Payment',
    renderCell: (props: GridRenderCellParams) => {
      const row = props.row as LoanEmiSchedule
      var displayText = '-'
      if (row.interest) {
        displayText = `₹ ${StringUtility.formatCurrency(row.interest)}`
      }
      return displayText
    },
    flex: 1,
  },
  {
    field: 'principle',
    headerName: 'Principal Payment',
    renderCell: (props: GridRenderCellParams) => {
      const row = props.row as LoanEmiSchedule
      var displayText = '-'
      if (row.principle) {
        displayText = `₹ ${StringUtility.formatCurrency(row.principle)}`
      }
      return displayText
    },
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (props: GridRenderCellParams) => {
      const row = props.row as LoanEmiSchedule
      return <LosStatusPill status={row.status} />
    },
    flex: 1,
  },
]
