import { useEffect, useState } from 'react'
import { useSnackbar } from '../../../../providers/SnackbarProvider'
import ReviewPredisubrsedApplicationForHq from './ReviewPredisubrsedApplicationForHq'
import DisburseFirstTrancheDialog from './components/DisburseNow/DisburseFirstTrancheDialog'
import { getErrorMessageFromErrorObj } from '../../../../utils/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid'
import _ from 'lodash'
import LosStatusPill from '../../../components/LosStatusPill'
import LosButton from '../../../onboarding/components/common/LosButton'
import { ColendedApplication } from '../../../onboarding/types/Los'
import LosDataGrid from '../../../components/LosDataGrid'
import { LosService } from '../../../onboarding/api/LosService'
import { LOS_PROGRAM_ID } from '../../../onboarding/api/constants'
import { ApplicationPreviewProvider } from '../../../onboarding/providers/ApplicationPreviewProvider'

dayjs.extend(utc)
dayjs.extend(timezone)

type OpenDialogProps = {
  show: boolean
  applicationId: string | null
}

const ListOfPreDisbursement = () => {
  const { setSnackbar } = useSnackbar()
  const [openViewDialog, setOpenViewDialog] = useState<OpenDialogProps>({
    show: false,
    applicationId: null,
  })
  const [openDisburseNowDialog, setOpenDisburseNowDialog] =
    useState<OpenDialogProps>({
      show: false,
      applicationId: null,
    })
  const [page, setPage] = useState<number>(0)
  const [rowCount, setRowCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [applications, setApplications] = useState<ColendedApplication[]>([])
  const [pageSize, setPageSize] = useState<number>(10)

  const COLUMN_DEF: GridColumns<ColendedApplication> = [
    {
      field: 'customerId',
      headerName: 'Customer Id',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication
        return row.customerId
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication
        var displayText = '-'
        if (row.customerName) {
          displayText = row.customerName
        }
        return displayText
      },
      flex: 1.5,
      minWidth: 150,
    },
    {
      field: 'losEntityType',
      headerName: 'Entity',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication
        return _.startCase(_.toLower(row.losEntityType))
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'phone',
      headerName: 'Mobile Number',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as ColendedApplication
        var displayText = '-'
        if (application.phone) {
          displayText = application.phone
        }
        return displayText
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'amount',
      headerName: 'Loan Amount',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as ColendedApplication
        var displayText = '-'
        if (application.basicInfo?.amount) {
          displayText = `₹ ${application.basicInfo?.amount}`
        }
        return displayText
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'approvedLoanAmount',
      headerName: 'Approved Loan Amount',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as ColendedApplication
        var displayText = '-'
        if (application?.approvedAmount) {
          displayText = `₹ ${application.approvedAmount}`
        }
        return displayText
      },
      flex: 1,
      minWidth: 170,
    },
    // {
    //   field: 'colenderName',
    //   headerName: 'Colender Name',
    //   renderCell: (props: GridRenderCellParams) => {
    //     const row = props.row as ColendedApplication;
    //     var displayText = '-';
    //     if (row.colenderName) {
    //       displayText = row.colenderName;
    //     }
    //     return displayText;
    //   },
    //   flex: 1,
    //   minWidth: 100,
    // },
    // {
    //   field: 'colendingAmount',
    //   headerName: 'Colender Amount',
    //   renderCell: (props: GridRenderCellParams) => {
    //     const row = props.row as ColendedApplication;
    //     var displayText = '-';
    //     if (row.colendingAmount) {
    //       displayText = `₹ ${row.colendingAmount}`;
    //     }
    //     return displayText;
    //   },
    //   flex: 2,
    //   minWidth: 100,
    // },
    {
      field: 'tenure',
      headerName: 'Tenure',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication
        var displayText = '-'
        if (row.basicInfo?.tenure) {
          displayText = `${row.basicInfo?.tenure} Months`
        }
        return displayText
      },
      flex: 0.75,
      minWidth: 75,
    },
    {
      field: 'roi',
      headerName: 'Rate Of Interest',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication
        var displayText = '-'
        if (row.primaryRoi) {
          displayText = `${row.primaryRoi}%`
        }
        return displayText
      },
      flex: 0.75,
      minWidth: 75,
    },

    {
      field: 'agent Name',
      headerName: 'Agent Name',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication
        if (row.agentName) {
          return row.agentName
        }
        return <LosButton text='Map Agent' size='small' onClick={() => {}} />
      },
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'applicationStatus',
      headerName: 'Status',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication
        if (
          row.applicationStatus == 'READY_FOR_DISPERSAL' &&
          row.isDispersible == true
        ) {
          return (
            <LosButton
              variant='outlined'
              size='small'
              text={'Disburse Now'}
              onClick={() => {
                setOpenDisburseNowDialog({
                  show: true,
                  applicationId: row.applicationId,
                })
              }}
            />
          )
        }
        return <LosStatusPill status={row.applicationStatus} />
      },
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as ColendedApplication
        return (
          <LosButton
            variant='outlined'
            size='small'
            text='View Details'
            onClick={() => {
              // onAction(row.applicationId);
              setOpenViewDialog({
                applicationId: row.applicationId,
                show: true,
              })
            }}
          />
        )
      },
      flex: 1,
      minWidth: 100,
    },
  ]

  async function fetchApplications() {
    try {
      setLoading(true)
      const response = await LosService.getAllApplicationsForHQ({
        page: page,
        pageSize: pageSize,
        status: 'PRE_DISPERSED,READY_FOR_DISPERSAL',
        sortBy: 'createdAt',
        sortDir: 'desc',
        programId: LOS_PROGRAM_ID,
      })
      setApplications(response.records)
      setPage(response.pageNumber)
      setRowCount(response.totalItems)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchApplications()
  }, [page, pageSize])

  return (
    <>
      <LosDataGrid
        data={applications}
        columns={COLUMN_DEF}
        idColumn={'applicationId'}
        page={page}
        pageSize={pageSize}
        loading={loading}
        onPageChange={setPage}
        rowCount={rowCount}
      />

      <ApplicationPreviewProvider
        applicationId={openViewDialog.applicationId ?? undefined}
      >
        <ReviewPredisubrsedApplicationForHq
          open={openViewDialog.show}
          onClose={function (): void {
            setOpenViewDialog({ show: false, applicationId: null })
          }}
          applicationId={openViewDialog.applicationId}
        />
      </ApplicationPreviewProvider>

      <DisburseFirstTrancheDialog
        open={openDisburseNowDialog.show}
        applicationId={openDisburseNowDialog.applicationId}
        onClose={function (): void {
          setOpenDisburseNowDialog({ show: false, applicationId: null })
          fetchApplications()
        }}
      />
    </>
  )
}

export default ListOfPreDisbursement
