import React from 'react';
import { Grid, Stack } from '@mui/material';
import LosDetailsWrapper from '../../../../LosDetailsWrapper';
import { GREEN, RED } from '../../../../../../constants/style';
import { toLowerCase } from '../../../../../../constants/commonFunction';
import {
  ICollateralScoresProps,
  useRuleEngine,
} from '../../../../../LMSAgent/Provider/RuleEngingProvider';
import LosText from '../../../../LosText';
import { useAppSelector } from '../../../../../../store/hook';
import { getApplicationsDetails } from '../../../../../../store/common/agentApplicationsSlice';

interface IBasicCriteriaProps {
  name: string;
  value: string;
  color: string;
  info?: JSX.Element;
}

const getInfo = (score: number) => {
  return (
    <Stack>
      <LosText
        text={'Individual Section Weight Score (>=3)'}
        color={'white'}
        fontWeight={600}
        fontSize={'0.5rem'}
        letterSpacing={'0.3px'}
      />
      <LosText
        text={`Overrider = ${score}`}
        color={'white'}
        fontWeight={600}
        fontSize={'0.5rem'}
        letterSpacing={'0.3px'}
      />
    </Stack>
  );
};

const getColorValue = (
  score: string | number | undefined,
  lessNumber: number | undefined
) => {
  if (!score || !lessNumber)
    return {
      value: 'FAILED',
      color: RED.red,
    };

  const parseScore = typeof score === 'string' ? JSON.parse(score) : score;
  return {
    value: parseScore >= lessNumber ? 'PASSED' : 'FAILED',
    color: parseScore >= lessNumber ? GREEN.dark : RED.red,
  };
};

const OverviewCriteria = () => {
  const { loading, underWrittingScrore } = useRuleEngine();
  const { applicationDetails } = useAppSelector(getApplicationsDetails);
  const fixedDepositScore =
    underWrittingScrore?.underwritingCollateralScore?.collateralScores.find(
      (collateralScore: ICollateralScoresProps) =>
        collateralScore.collateralFactors === 'FIXED_DEPOSIT'
    );
  const basicCriteria: IBasicCriteriaProps[] = [
    {
      name: 'Override FD/BG',
      value: getColorValue(
        fixedDepositScore?.value,
        applicationDetails.basicInfo?.amount
      ).value,
      color: getColorValue(
        fixedDepositScore?.value,
        applicationDetails.basicInfo?.amount
      ).color,
    },
  ];
  return (
    <div>
      <Grid container spacing={2}>
        {basicCriteria.map((criteria: IBasicCriteriaProps, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={3} lg={2.4} xl={2.4}>
            {' '}
            <LosDetailsWrapper
              name={criteria.name}
              value={toLowerCase(criteria.value)}
              isInfo
              valueColor={criteria.color}
              // info={criteria.info}
              loading={loading}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default OverviewCriteria;
