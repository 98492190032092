/** @format */

import { useEffect, useState } from 'react';
import { getProgramMITCLink, listForClientWithMetaData } from '../api/program';
import { VegaPage } from '../components/common';
import { CorporateCardIcon, CreditCardIcon, UserIcon } from '../components/icon/Icons';
import ListOfDraftPrograms from '../components/Program Management/ListOfDraftPrograms';
import ListOfLiveProgram from '../components/Program Management/ListOfLiveProgram';
import ProgramInfoTableForStatus from '../components/Program Management/ProgramInfoTableForStatus';
import { BACKGROUND } from '../constants/style';
import { useClientAuth } from '../providers/ClientProvider';
import { useSnackbar } from '../providers/SnackbarProvider';
import { VegaCreditCardProductType, VegaProgramStatusType, VegaProgramWithMetaDataType } from '../types/program';

import { ITileDataProps } from './Resources';

enum PageTable {
  LIVE_PROGRAMS = 'LIVE_PROGRAMS',
  DRAFT_PROGRAMS = 'DRAFT_PROGRAMS',
}

let LIVE_PROGRAM = [
  {
    id: VegaCreditCardProductType.CHARGE_CARD,
    text: 'Charge Card',
    image: <CorporateCardIcon />,
    bgColor: BACKGROUND.BLUE,
    color: 'white',
    selected: PageTable.LIVE_PROGRAMS,
  },
  {
    id: VegaCreditCardProductType.RETAIL_UNSECURED,
    text: 'Retail Unsecured Card',
    image: <CreditCardIcon />,
    bgColor: BACKGROUND.BLUE,
    selected: PageTable.LIVE_PROGRAMS,
  },
  {
    id: VegaCreditCardProductType.RETAIL_SECURED,
    text: 'Retail Secured Card',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
    selected: PageTable.LIVE_PROGRAMS,
  },
  {
    id: VegaCreditCardProductType.CORPORATE_CARD,
    text: 'Commercial Card',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
    selected: PageTable.LIVE_PROGRAMS,
  },
];

let DRAFT_PROGRAM = [
  {
    id: VegaCreditCardProductType.CHARGE_CARD,
    text: 'Charge Card',
    image: <CorporateCardIcon />,
    bgColor: BACKGROUND.BLUE,
    color: 'white',
    selected: PageTable.LIVE_PROGRAMS,
  },
  {
    id: VegaCreditCardProductType.RETAIL_UNSECURED,
    text: 'Retail Unsecured Card',
    image: <CreditCardIcon />,
    bgColor: BACKGROUND.BLUE,
    selected: PageTable.LIVE_PROGRAMS,
  },
  {
    id: VegaCreditCardProductType.RETAIL_SECURED,
    text: 'Retail Secured Card',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
    selected: PageTable.LIVE_PROGRAMS,
  },
  {
    id: VegaCreditCardProductType.CORPORATE_CARD,
    text: 'Commercial Card',
    image: <UserIcon />,
    bgColor: BACKGROUND.BLUE,
    selected: PageTable.LIVE_PROGRAMS,
  },
] as ITileDataProps[];

const ProgramManagement = () => {
  const { clientId } = useClientAuth();
  const { setSnackbar } = useSnackbar();

  const [activePrograms, setActivePrograms] = useState<VegaProgramWithMetaDataType[]>();
  const [inactivePrograms, setInactivePrograms] = useState<VegaProgramWithMetaDataType[]>();

  const [selectedLiveProgramType, setSelectedLiveProgramType] = useState<ITileDataProps | null>(LIVE_PROGRAM[0]);
  const [selectedDraftProgramType, setSelectedDraftProgramType] = useState<ITileDataProps | null>(null);

  function fetchProgramsWithMetaData(data: {
    clientId: string;
    page?: number;
    pageSize?: number;
    status: string;
    type?: string;
  }) {
    listForClientWithMetaData(data)
      .then((resp) => {
        const programsFromResponse = resp?.data?.records?.reverse();
        if (!programsFromResponse) {
          console.error('Failed to fetch programs for client');
          return;
        }
        if (data.status == VegaProgramStatusType.ACTIVE) {
          setActivePrograms(programsFromResponse);
        } else {
          setInactivePrograms(programsFromResponse);
        }
      })
      .catch((err) => {});
  }

  function downloadMitcForProgram(programId: string) {
    getProgramMITCLink(programId)
      .then((response) => {
        const url = response.data.mitcUrl;
        if (!url) {
          setSnackbar('Failed to download MITC', 'error');
          return;
        }
        window.open(url, '__BLANK__');
      })
      .catch((error) => {
        setSnackbar('Failed to download MITC', 'error');
      });
  }

  useEffect(() => {
    if (!clientId) return;
    fetchProgramsWithMetaData({
      clientId: clientId,
      status: VegaProgramStatusType.ACTIVE,
      type: selectedLiveProgramType?.id ?? VegaCreditCardProductType.CHARGE_CARD,
    });
    fetchProgramsWithMetaData({
      clientId: clientId,
      status: VegaProgramStatusType.DRAFT,
      type: selectedDraftProgramType?.id ?? VegaCreditCardProductType.CHARGE_CARD,
    });
  }, []);

  useEffect(() => {
    setActivePrograms([]);
    if (!clientId) return;
    fetchProgramsWithMetaData({
      clientId: clientId,
      status: VegaProgramStatusType.ACTIVE,
      type: selectedLiveProgramType?.id ?? VegaCreditCardProductType.CHARGE_CARD,
    });
  }, [selectedLiveProgramType]);

  useEffect(() => {
    setInactivePrograms([]);
    if (!clientId) return;
    fetchProgramsWithMetaData({
      clientId: clientId,
      status: VegaProgramStatusType.DRAFT,
      type: selectedDraftProgramType?.id ?? VegaCreditCardProductType.CHARGE_CARD,
    });
  }, [selectedDraftProgramType]);

  return (
    <VegaPage>
      <ProgramInfoTableForStatus
        header={'Live'}
        data={LIVE_PROGRAM}
        setActiveState={(select: any) => {
          setSelectedDraftProgramType(null);
          setSelectedLiveProgramType(select);
        }}
        subHeader="Review all your Live programs at one place across various product categories"
        active={selectedLiveProgramType}
      />
      {selectedLiveProgramType && (
        <ListOfLiveProgram programs={activePrograms ?? []} didClickMitc={downloadMitcForProgram} />
      )}
      <ProgramInfoTableForStatus
        header={'Draft'}
        data={DRAFT_PROGRAM}
        setActiveState={(select: any) => {
          setSelectedLiveProgramType(null);
          setSelectedDraftProgramType(select);
        }}
        active={selectedDraftProgramType}
        subHeader={'Review all your draft programs at one place across various product categories'}
      />
      {selectedDraftProgramType && <ListOfDraftPrograms programs={inactivePrograms ?? []} />}
    </VegaPage>
  );
};

export default ProgramManagement;
