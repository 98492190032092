import { Divider, Grid, Box, Typography } from '@mui/material';
import React from 'react';
import { ReadOnlyFormField } from '../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField';
import LosText from '../../onboarding/components/common/LosText';

function PersonalDetails() {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Typography className="font-aspekta-500" fontSize={14}>
          Work Details
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Occupation" description="Professional" />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField
              title="Current Experience"
              description="3 Years"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Company name" description="Company LLC" />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Company Type" description="Govt. Org" />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Type of Company" description="Services" />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Industry" description="Government" />
          </Grid>
        </Grid>
      </div>
      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '1rem',
        }}
      >
        <Typography className="font-aspekta-500" fontSize={14}>
          Communication Preferences
        </Typography>
        <Grid container spacing={'1rem'}>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <ReadOnlyFormField
              title="Mode to connect"
              description="Email, SMS"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <ReadOnlyFormField
              title="Preferred communication address"
              description="Residence"
            />
          </Grid>
        </Grid>
      </div>
      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '1rem',
        }}
      >
        <Typography className="font-aspekta-500" fontSize={14}>
          Other Details
        </Typography>
        <Grid container spacing={'1rem'}>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Qualification" description="MBA" />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField
              title="Residencial Status"
              description="Rented"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Marital Status" description="Single" />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default PersonalDetails;
