import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import VegaHeader from '../../../components/common/VegaHeader';
import { ReadOnlyFormField } from '../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField';
import LosText from '../../onboarding/components/common/LosText';
import LOSTable from '../LOSTable';

enum ColumnKey {
  Name = 'name',
  Type = 'type',
  NoOfShares = 'noOfShares',
  SharePrice = 'sharePrice',
  ScripEligiblity = 'scripEligiblity',
  Isin = 'isin',
}

type Scrip = {
  name: string;
  type: string;
  noOfShares: string;
  sharePrice: string;
  scripEligiblity: string;
  isin: string;
};

const SCRIPS: Scrip[] = [
  {
    name: 'Aditya Birla SL Arbitrage Fund(G)',
    type: 'Mutual Fund',
    noOfShares: '30 units',
    sharePrice: '₹ 40,000',
    scripEligiblity: '2%',
    isin: 'INF209K01264',
  },
  {
    name: 'Aditya Birla SL Arbitrage Fund(IDCW)-Direct Plan',
    type: 'Mutual Fund',
    noOfShares: '20 units',
    sharePrice: '₹ 24,000',
    scripEligiblity: '2%',
    isin: 'INF209K01264',
  },
];

function ScripsAndCmsDetails() {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Typography className="font-aspekta-500" fontSize={14}>
          Scrip Details
        </Typography>

        <LOSTable
          theadComponent={getHeader}
          data={SCRIPS}
          rowComponent={getRow}
          loading={false}
          page={1}
          count={1}
          onPageChange={() => {}}
          privious={function (page: number): void {}}
          next={function (page: number): void {}}
        />
      </div>
      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '1rem',
        }}
      >
        <Typography className="font-aspekta-500" fontSize={14}>
          Scrip Details
        </Typography>
        <Grid container spacing={'1rem'}>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="loan amount" description="₹ 1,50,000" />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Rate of Interest" description="12.3%" />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Tenure" description="12 Months" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ScripsAndCmsDetails;
const getRow = () => {
  const applications = SCRIPS?.map((value: Scrip, index: number) => (
    <React.Fragment key={index}>
      <tr
        style={{
          background: 'white',
          borderBottom: `2px solid lightgray`,
        }}
      >
        {COLUMN.map((val: any) => customizeRow(val, value))}
      </tr>
    </React.Fragment>
  ));
  return applications;
};

const getHeader = () => {
  const headers = COLUMN.map((column: any, index: number) => (
    <th
      style={{
        flex: column.flex,
        minWidth: column.minWidth,
        padding: '15px 10px',
        textAlign: 'start',
      }}
    >
      <VegaHeader text={column.header} mb={0} />
    </th>
  ));
  return headers;
};

const COLUMN = [
  {
    key: ColumnKey.Name,
    header: 'SCRIP NAME',
    flex: 1,
    minWidth: '200px',
  },
  {
    key: ColumnKey.Type,
    header: 'SCRIP TYPE',
    flex: 1,
    minWidth: '100px',
  },
  {
    key: ColumnKey.NoOfShares,
    header: 'No. of shares',
    flex: 1,
    minWidth: '100px',
  },
  {
    key: ColumnKey.SharePrice,
    header: 'Share Price',
    flex: 0.5,
    minWidth: '100px',
  },
  {
    key: ColumnKey.Isin,
    header: 'Isin',
    flex: 0.5,
    minWidth: '100px',
  },
];

const customizeRow = (column: any, scrip: Scrip) => {
  return <TableCell text={scrip[column.key as keyof Scrip]} />;
  //   if (column.key == ColumnKey.PaymentDate) {
  //     return <TableCell text={dayjs(ledger.created_at).format('DD/MM/YYYY')} />;
  //   } else if (column.key == ColumnKey.Credit) {
  //     return <TableCell text={getCreditValue(ledger)} />;
  //   } else if (column.key == ColumnKey.Debit) {
  //     return <TableCell text={getDebitValue(ledger)} />;
  //   } else if (column.key == ColumnKey.EventType) {
  //     return <TableCell text={getEventName(ledger)} />;
  //   }
};
const TableCell = (data: { text?: string }) => {
  return (
    <td
      style={{
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        paddingLeft: '0.75rem',
        textAlign: 'start',
        fontSize: '0.75rem',
        lineHeight: '140%',
        fontWeight: 400,
      }}
    >
      <LosText
        text={data.text ?? '-'}
        fontWeight={400}
        fontSize={'0.75rem'}
        lineHeight={'140%'}
      />
    </td>
  );
};
