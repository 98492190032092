import React, { useState } from 'react';
import { Box } from '@mui/material';
import VegaTab from '../../../../components/common/VegaTab';
import { BLUE } from '../../../../constants/style';
import { IDetailsEnumProps } from '../../../enums/common';
import ColenderBankDetailsTab from './components/Tabs/ColenderBankDetailsTab';
import ColenderEligiblitySchemeTab from './components/Tabs/ColenderEligiblitySchemeTab';
import ColenderInterestSchemeTab from './components/Tabs/ColenderInterestSchemeTab';
import ColenderIncomeSchemeTab from './components/Tabs/ColenderIncomeSchemeTab';

export interface IExtraProps extends IDetailsEnumProps {
  startAdornment?: React.ReactNode | null;
  endAdornment?: React.ReactNode | null;
}

enum ColenderDetailsTabType {
  BankDetails = 'Basic Details',
  EligiblityScheme = 'Eligibility Scheme',
  InterestScheme = 'Interest Scheme',
  IncomeScheme = 'Income Scheme',
}

const SelectedCoLenderDetails = () => {
  const [selectedTab, setSelectedTab] = useState<string>(
    ColenderDetailsTabType.BankDetails
  );

  return (
    <>
      <Box px={1} sx={{ overflow: 'auto' }}>
        <VegaTab
          placement="vertical"
          color={BLUE.lighter}
          tabData={[
            ColenderDetailsTabType.BankDetails,
            ColenderDetailsTabType.EligiblityScheme,
            ColenderDetailsTabType.InterestScheme,
            ColenderDetailsTabType.IncomeScheme,
          ]}
          selected={selectedTab}
          onSelect={(select) => setSelectedTab(select)}
        />
      </Box>
      {selectedTab == ColenderDetailsTabType.BankDetails && (
        <ColenderBankDetailsTab />
      )}
      {selectedTab == ColenderDetailsTabType.EligiblityScheme && (
        <ColenderEligiblitySchemeTab />
      )}
      {selectedTab == ColenderDetailsTabType.InterestScheme && (
        <ColenderInterestSchemeTab />
      )}
      {selectedTab == ColenderDetailsTabType.IncomeScheme && (
        <ColenderIncomeSchemeTab />
      )}
    </>
  );
};

export default SelectedCoLenderDetails;
