import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import LosText from '../../../components/common/LosText';
import { CancelIcon } from '../../../icons/LosIcons';

export const SelfiePrecaution = (data: { text: string; icon: ReactNode }) => {
  return (
    <Stack
      alignItems={'center'}
      justifyContent="center"
      spacing={'0.88rem'}
      flex={1}
    >
      <div
        style={{
          background: 'rgba(16, 71, 220, 0.1)',
          height: '3rem',
          width: '3rem',
          borderRadius: '2rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        {data.icon}
        <div
          style={{
            background: 'red',
            height: '1rem',
            width: '1rem',
            borderRadius: '1rem',
            position: 'absolute',
            right: '0rem',
            bottom: '-0.25rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CancelIcon />
        </div>
      </div>
      <LosText
        textAlign={'center'}
        text={data.text}
        fontSize={'0.75rem'}
        variant="caption"
        style={{ whiteSpace: 'pre-line' }}
      />
    </Stack>
  );
};
