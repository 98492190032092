import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { useLos } from '../solfen/onboarding/providers/LosProvider'
import OnboardingLoginLeftContainer from '../solfen/onboarding/components/OnboardingLoginLeftContainer'
import OnboardingStepOverview from '../solfen/onboarding/components/OnboardingStepOverview'
import { useLosAuth } from '../solfen/onboarding/providers/LosAuthProvider'
import MobileValidationContainer from '../solfen/onboarding/pages/MobileValidation/MobileValidationContainer'
import { useEffect } from 'react'
import LosStepContainer from '../solfen/onboarding/pages/LosStepContainer'
import ActiveLoan from '../solfen/onboarding/pages/ActiveLoan/ActiveLoan'
import { LosStep } from '../solfen/onboarding/types/Los'
import {
  LocalStorageUtility,
  StorageKeys,
} from '../solfen/onboarding/utils/StorageUtility'

function LosOnboarding() {
  const { fetchApplicationState, currentStep } = useLos()
  const { isAuthenticated } = useLosAuth()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  // useEffect(() => {
  //   fetchApplicationState();
  //   console.log(isAuthenticated);
  // }, [isAuthenticated]);

  const isLoanDispursed = () => currentStep == LosStep.DISPERSED
  // useEffect(() => {
  //   LocalStorageUtility.setItem<boolean>(
  //     StorageKeys.hasFetchedStateBefore,
  //     false
  //   )
  // }, [])

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
      }}
    >
      <div
        style={{
          display: isLoanDispursed() ? 'flex' : 'none',
        }}
      >
        <ActiveLoan />
      </div>
      <Grid
        container
        style={{
          maxHeight: '100vh',
          height: '100vh',
          display: isLoanDispursed() ? 'none' : 'flex',
        }}
      >
        <Grid
          display={isMobile ? 'none' : 'inline-flex'}
          item
          xs={0}
          lg={6}
          md={6}
        >
          {isAuthenticated && <OnboardingStepOverview />}
          {isAuthenticated == false && <OnboardingLoginLeftContainer />}
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          style={{
            background: 'white',
            display: isAuthenticated == false ? 'block' : 'none',
          }}
        >
          <MobileValidationContainer />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          style={{
            background: 'white',
            display: isAuthenticated ? 'block' : 'none',
          }}
        >
          <div
            style={{
              height: '100vh',
            }}
          >
            <LosStepContainer />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default LosOnboarding
