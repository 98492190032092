import { Close } from '@mui/icons-material';
import { Dialog, Stack, IconButton } from '@mui/material';
import { useState } from 'react';
import LosTabBar from '../../components/common/LosTabBar';
import LosTabBarItem from '../../components/common/LosTabBarItem';
import LosText from '../../components/common/LosText';
import { useLos } from '../../providers/LosProvider';
import { UserType } from '../../types/Los';
import CollateralAndGuarantorDetailsPreview from './CollateralAndGuarantorDetailsPreview';
import CompanyDetailsPreview from './CompanyDetailsPreview';
import ElectricityDetailsPreview from './ElectricityDetailsPreview';
import FinancialDetailsPreview from './Financial/FinancialDetailsPreview';
import KycDetailsPreview from './Kyc/KycDetailsPreview';

type Props = {
  open: boolean;
  onClose: () => void;
};

function ApplicationPreviewDesktopView({ open, onClose }: Props) {
  const { customerApplication } = useLos();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const canShowCompanyTab = () => {
    const userType = customerApplication?.basicInfo.userType;
    if (!userType) return false;
    if (userType == UserType.INDIVIDUAL) return false;
    return true;
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      sx={{ borderRadius: '20px' }}
      onClose={onClose}
      open={open}
    >
      <div
        style={{
          backgroundColor: 'rgba(250, 250, 250, 1)',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems={'center'}
          style={{
            paddingTop: '1.25rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <LosText text="Preview Application" />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <LosTabBar
          value={selectedTab}
          onChange={(e, selected) => {
            setSelectedTab(selected);
          }}
        >
          <LosTabBarItem label="Kyc Details" />
          <LosTabBarItem label="Financial" />
          <LosTabBarItem label="Collateral & Guarantor" />
          <LosTabBarItem label="Electricity Details" />

          {canShowCompanyTab() && <LosTabBarItem label="Company Details" />}
        </LosTabBar>
        <div
          style={{
            border: '1px solid #E1E4EB',
            padding: '1rem',
            margin: '1rem',
            borderRadius: '0.75rem',
            background: 'white',
            height: '40vh',
            overflowY: 'scroll',
          }}
        >
          {selectedTab == 0 && (
            <KycDetailsPreview
              userType={customerApplication?.basicInfo.userType}
              viewerType="Customer"
            />
          )}
          {selectedTab == 1 && <FinancialDetailsPreview />}
          {selectedTab == 2 && <CollateralAndGuarantorDetailsPreview />}
          {selectedTab == 3 && <ElectricityDetailsPreview />}
          {selectedTab == 4 && <CompanyDetailsPreview />}
        </div>
      </div>
      <Stack
        direction={'row'}
        justifyContent="end"
        alignItems={'center'}
        style={{ padding: '1rem' }}
      ></Stack>
    </Dialog>
  );
}

export default ApplicationPreviewDesktopView;
