import { Close } from '@mui/icons-material';
import { Dialog, Stack, IconButton, Divider } from '@mui/material';
import { useState } from 'react';
import LosButton from '../../../../onboarding/components/common/LosButton';
import LosText from '../../../../onboarding/components/common/LosText';
import { useColenders } from '../../../providers/ColendersProvider';
import ColenderBankDetailsForm from './Forms/ColenderBankDetailsForm';
import ColenderEligiblityForm from './Forms/ColenderEligiblitySchemeForm';
import ColenderIncomeSchemeForm from './Forms/ColenderIncomeSchemeForm';
import ColenderInterestSchemeForm from './Forms/ColenderInterestSchemeForm';
import {
  ColenderBankDetailsFormData,
  ColenderDetailsFormData,
  ColenderEligiblityFormData,
  ColenderInterestSchemeFormData,
} from './Forms/formData';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: ColenderDetailsFormData) => void;
};

function AddColenderDialog({ open, onClose, onSubmit }: Props) {
  const [formData, setFormData] = useState<ColenderDetailsFormData>({
    bankDetails: {},
    interestSchemeDetails: {},
    eligiblityDetails: {},
    incomeSchemeDetails: {},
  });

  function handleIncomeSchemeDetailsUpdate(type: string, value?: string) {
    setFormData((prev) => {
      return {
        ...prev,
        incomeSchemeDetails: {
          ...prev.incomeSchemeDetails,
          [type]: value,
        },
      };
    });
  }

  function handleBankDetailsUpdate(type: string, value?: string) {
    setFormData((prev) => {
      return {
        ...prev,
        bankDetails: {
          ...prev.bankDetails,
          [type]: value,
        },
      };
    });
  }

  function handleElgiblityDetailsUpdate(type: string, value?: string) {
    setFormData((prev) => {
      return {
        ...prev,
        eligiblityDetails: {
          ...prev.eligiblityDetails,
          [type]: value,
        },
      };
    });
  }

  function handleInterestDetailsUpdate(type: string, value?: string) {
    setFormData((prev) => {
      return {
        ...prev,
        interestSchemeDetails: {
          ...prev.interestSchemeDetails,
          [type]: value,
        },
      };
    });
  }

  const isInputValid = () => {
    return (
      isBankDetailsInputValid(formData.bankDetails) &&
      isEligiblityDetailsInputValid(formData.eligiblityDetails) &&
      isInterestDetailsInputValid(formData.interestSchemeDetails)
    );
  };

  async function onSubmitClick() {
    onSubmit(formData);
    onClose();
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      sx={{ borderRadius: '20px' }}
      onClose={onClose}
      open={open}
    >
      <div
        style={{
          backgroundColor: 'rgba(250, 250, 250, 1)',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems={'center'}
          style={{
            paddingTop: '1.25rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <LosText text="Add Colender" />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <div
          style={{
            border: '1px solid #E1E4EB',
            padding: '1rem',
            margin: '1rem',
            borderRadius: '0.75rem',
            background: 'white',
          }}
        >
          <Stack spacing={'1rem'}>
            <ColenderBankDetailsForm
              formData={formData.bankDetails}
              handleUpdate={handleBankDetailsUpdate}
              isEditable={true}
            />
            <Divider variant="inset" />
            <ColenderEligiblityForm
              isEditable={true}
              formData={formData.eligiblityDetails}
              handleUpdate={handleElgiblityDetailsUpdate}
            />
            <Divider variant="inset" />
            <ColenderInterestSchemeForm
              isEditable={true}
              formData={formData.interestSchemeDetails}
              handleUpdate={handleInterestDetailsUpdate}
            />
            <Divider variant="inset" />
            <ColenderIncomeSchemeForm
              isEditable={true}
              formData={formData.incomeSchemeDetails}
              handleUpdate={handleIncomeSchemeDetailsUpdate}
            />
          </Stack>
        </div>
      </div>
      <Stack
        direction={'row'}
        justifyContent="end"
        alignItems={'center'}
        style={{ padding: '1rem' }}
      >
        <LosButton
          text="Add Colender"
          onClick={onSubmitClick}
          // disabled={isInputValid() == false}
        />
      </Stack>
    </Dialog>
  );
}

export default AddColenderDialog;

const isBankDetailsInputValid = (
  formData: Partial<ColenderBankDetailsFormData>
) => {
  return false;
};

const isEligiblityDetailsInputValid = (
  formData: Partial<ColenderEligiblityFormData>
) => {
  return false;
};

const isInterestDetailsInputValid = (
  formData: Partial<ColenderInterestSchemeFormData>
) => {
  return false;
};
