import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import SelfieApprovedView from './SelfieIntermediateStages/SelfieApprovedView';

import SelfieCaptureView from './SelfieIntermediateStages/SelfieCaptureView';
import SelfieIntialView from './SelfieIntermediateStages/SelfieIntialView';
import SelfieRejectedView from './SelfieIntermediateStages/SelfieRejectedView';
import SelfieUploadingView from './SelfieIntermediateStages/SelfieUploadingView';
import { useSelfie, SelfieViewState } from './SelfieProvider';

type Props = {
  initialState?: SelfieViewState;
};
function SelfieVerification({ initialState = SelfieViewState.Initial }: Props) {
  const { viewState, onViewStateChange } = useSelfie();

  useEffect(() => {
    onViewStateChange(initialState);
  }, [initialState]);

  if (viewState == SelfieViewState.Initial) {
    return <SelfieIntialView />;
  } else if (viewState == SelfieViewState.CaptureSelfie) {
    return <SelfieCaptureView />;
  } else if (viewState == SelfieViewState.Uploading) {
    return <SelfieUploadingView />;
  } else if (viewState == SelfieViewState.Rejected) {
    return <SelfieRejectedView />;
  } else if (viewState == SelfieViewState.Approved) {
    return <SelfieApprovedView />;
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  );
}

export default SelfieVerification;
