/** @format */

import { DASHBOARD_ROUTES } from '../routes'

export const URL = {
  CLIENT: 'www.client.vegapay.tech',
  PROGRAM: 'www.program.vegapay.tech',
  PROTOTYPE: 'www.simulator.vegapay.tech',
  BACK_OFFICE: 'www.back-office.vegapay.tech',
  DEV: 'www.dev.vegapay.tech',
  DEV_BASE: 'dev.vegapay.tech',
  DEV_SOLFIN: 'solfin.vegapay.tech',
  PROD_SOLFIN: 'finance.solfin.co.in',
  CURRENT: window.location.hostname,
  LOCAL: 'localhost',
  FOREX_BRANCH: 'www.branch.vegapay.tech',
  FOREX_CORPORATE: 'www.corporate.vegapay.tech',
}

export const HOSTNAME = {
  CLIENT: 'client.vegapay.tech',
  PROGRAM: 'program.vegapay.tech',
  PROTOTYPE: 'simulator.vegapay.tech',
  BACK_OFFICE: 'back-office.vegapay.tech',
  DEV: 'dev.vegapay.tech',
  DEV_BASE: 'dev.vegapay.tech',
  CURRENT: window.location.hostname,
  LOCAL: 'localhost',
  FOREX_BRANCH: 'branch.vegapay.tech',
  FOREX_CORPORATE: 'corporate.vegapay.tech',
  SOLFIN: 'solfin.vegapay.tech',
}

export const ACCESS_MAP: any = {
  'www.client.vegapay.tech': DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  'client.vegapay.tech': DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  'www.program.vegapay.tech': DASHBOARD_ROUTES.PROGRAM_DASHBOARD,
  'program.vegapay.tech': DASHBOARD_ROUTES.PROGRAM_DASHBOARD,
  'www.simulator.vegapay.tech': DASHBOARD_ROUTES.SIMULATOR,
  'simulator.vegapay.tech': DASHBOARD_ROUTES.SIMULATOR,
  'www.back-office.vegapay.tech': DASHBOARD_ROUTES.BACK_OFFICE,
  'back-office.vegapay.tech': DASHBOARD_ROUTES.BACK_OFFICE,
  'www.dev.vegapay.tech': DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  'dev.vegapay.tech': DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  'branch.vegapay.tech': DASHBOARD_ROUTES.MCC_BACK_OFFICE,
  'www.branch.vegapay.tech': DASHBOARD_ROUTES.MCC_BACK_OFFICE,
  'corporate.vegapay.tech': DASHBOARD_ROUTES.MCC_CORPORATE,
  'www.corporate.vegapay.tech': DASHBOARD_ROUTES.MCC_CORPORATE,
  'solfin.vegapay.tech': DASHBOARD_ROUTES.SOLFIN_ONBOARDING,
  'www.solfin.vegapay.tech': DASHBOARD_ROUTES.SOLFIN_ONBOARDING,
  localhost: DASHBOARD_ROUTES.SOLFIN_ONBOARDING,
}

export const DATE_FORMATE = 'DD-MM-YYYY'

export type DashboardURLs = keyof typeof URL
export const currentHost = window.location.hostname
export const isDev =
  currentHost === URL.DEV_SOLFIN || currentHost === URL.LOCAL;

export const isLocalPath = URL.CURRENT === URL.LOCAL

export const isCurrentPathClient = URL.CURRENT === URL.CLIENT

export const isCurrentPathPrototype = URL.CURRENT === URL.PROTOTYPE

export const isCurrentPathProgram = URL.CURRENT === URL.PROGRAM

export const isCurrentPathBackOffice = URL.CURRENT === URL.BACK_OFFICE

export const getUrl = (hostname: DashboardURLs) => {
  return `https://${URL[hostname]}`
}
