import { Card, Grid, Stack } from '@mui/material'
import LosText from '../../components/common/LosText'
import { EstampStatus, UserBasicInfo } from '../../types/Los'

type Props = {
  user?: UserBasicInfo
  index?: number
  isEsignPending: EstampStatus
}

function UserDetailsEditCard({ user, index, isEsignPending }: Props) {
  function getStatusColor(status: string) {
    return status === EstampStatus.PENDING ? '#FFC21B' : '#36913F'
  }
  return (
    <>
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '0.62rem',
          borderRadius: '7.317px',
          paddingTop: '0.75rem',
          paddingBottom: '0.75rem',
          background: 'white',
        }}
      >
        <Stack
          direction={'row'}
          alignItems='center'
          justifyContent={'space-between'}
          spacing={'0.5rem'}
          style={{
            paddingLeft: '1.25rem',
            paddingRight: '1.25rem',
          }}
        >
          {/* <LosText
            text={`Promoter ${index}`}
            style={{
              fontSize: '0.8rem',
              lineHeight: '100%',
              fontWeight: 600,
              paddingBottom: '0.4rem',
            }}
          /> */}
          <LosText
            text={`Customer Contact Details`}
            style={{
              fontSize: '0.8rem',
              lineHeight: '100%',
              fontWeight: 600,
              paddingBottom: '0.4rem',
            }}
          />
          {
            <LosText
              text={isEsignPending}
              style={{
                fontFamily: 'Aspekta',
                fontSize: '13px',
                fontWeight: 450,
                lineHeight: '16px',
                letterSpacing: '0em',
                textAlign: 'left',
                paddingBottom: '0.4rem',
                color: getStatusColor(isEsignPending),
              }}
            />
          }
        </Stack>
        <Grid
          container
          direction={'row'}
          justifyContent='start'
          style={{
            paddingLeft: '1.25rem',
            paddingRight: '1.25rem',
          }}
        >
          <Grid item xs={6}>
            <Label label='Name' description={user?.name ?? ''} />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          justifyContent='start'
          style={{
            paddingLeft: '1.25rem',
            paddingRight: '1.25rem',
          }}
        >
          <Grid item xs={6}>
            <Label
              label='Mobile Number'
              description={user?.mobileNumber ?? ''}
            />
          </Grid>
          <Grid item xs={6}>
            <Label label='Email' description={user?.emailId ?? ''} />
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default UserDetailsEditCard
const Label = (data: {
  label: string
  description?: string
  color?: string
}) => {
  return (
    <Stack rowGap={1}>
      <LosText
        text={data.label}
        style={{
          fontSize: '.625rem',
          fontFamily: 'Aspekta !important',
          color: '#676B76',
          fontWeight: 700,
          lineHeight: '124%',
          letterSpacing: '0.04375rem',
          textTransform: 'uppercase',
        }}
      />
      <LosText
        text={data.description ?? '-'}
        fontSize={'10px'}
        {...(data.color && {
          color: data.color,
        })}
        style={{
          fontFamily: 'Aspekta',
          fontSize: '13px',
          fontWeight: 400,
          lineHeight: '12px',
          letterSpacing: '0em',
          textAlign: 'left',
        }}
      />
    </Stack>
  )
}
