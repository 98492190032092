import _ from 'lodash';
import SolfenLogo from './SolfenLogo';
import { HomeMainIcon, VegaPayLogo } from '../icons/LosIcons';

function OnboardingLoginLeftContainer() {
  return (
    <div
      style={{
        background: '#161616',
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {/* <SolfenLogo /> */}
      {/* <div>
        
        <VegaPayLogo/>
      </div> */}
      <div>
        <img
          src={
            'https://vegapay-alpha-image-assets.s3.ap-south-1.amazonaws.com/logo/solfinlog.png'
          }
        ></img>
      </div>
      <div
        className=""
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          height: '',
        }}
      >
        <HomeMainIcon />
      </div>
    </div>
  );
}

export default OnboardingLoginLeftContainer;
