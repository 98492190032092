import { useState, useEffect } from 'react';

interface CountDownProps {
  initialMinutes?: number;
  initialSeconds?: number;
}

// export const useTimer = (
//   initialMinutes: number = 0,
//   initialSeconds: number = 0,
//   startTimer
// ): TimerProps => {
//   const [minutes, setMinutes] = useState(initialMinutes);
//   const [seconds, setSeconds] = useState(initialSeconds);

//   const _startTimer = (duration: number) => {
//     let totalSeconds = duration;

//     // Calculate the initial minutes and seconds based on the provided duration
//     const initialMinutes = Math.floor(totalSeconds / 60);
//     const initialSeconds = totalSeconds % 60;
//     setMinutes(initialMinutes);
//     setSeconds(initialSeconds);

//     const interval = setInterval(() => {
//       if (totalSeconds > 0) {
//         totalSeconds--;

//         // Calculate the remaining minutes and seconds
//         const remainingMinutes = Math.floor(totalSeconds / 60);
//         const remainingSeconds = totalSeconds % 60;

//         setMinutes(remainingMinutes);
//         setSeconds(remainingSeconds);
//       } else {
//         clearInterval(interval);
//       }
//     }, 1000);
//   };

//   return { minutes, seconds, _startTimer };
// };

export const useCountdown = (
  initialMinutes: number = 0,
  initialSeconds: number = 0
) => {
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  const startTimer = (duration: number) => {
    let totalSeconds = duration;

    // Calculate the initial minutes and seconds based on the provided duration
    const initialMinutes = Math.floor(totalSeconds / 60);
    const initialSeconds = totalSeconds % 60;
    setMinutes(initialMinutes);
    setSeconds(initialSeconds);

    const interval = setInterval(() => {
      if (totalSeconds > 0) {
        totalSeconds--;

        // Calculate the remaining minutes and seconds
        const remainingMinutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;

        setMinutes(remainingMinutes);
        setSeconds(remainingSeconds);
      } else {
        clearInterval(interval);
      }
    }, 1000);
  };

  return {
    minutes,
    seconds,
    startTimer,
  };
};
