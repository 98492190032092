import { Box } from '@mui/material';
import { useRuleEngine } from '../../../../LMSAgent/Provider/RuleEngingProvider';
import WeightScoreWrapper from '../../WeightScoreWrapper';
import CreditWothinessList from './components/CreditWothinessList';

const CollateralRule = () => {
  const { loading, underWrittingScrore } = useRuleEngine();
  return (
    <>
      <WeightScoreWrapper
        value={
          underWrittingScrore?.creditWorthinessScore
            ? underWrittingScrore?.creditWorthinessScore.weightedPoints
            : 0
        }
      />

      <Box
        sx={{
          pb: 2,
        }}
      >
        <CreditWothinessList />
      </Box>
    </>
  );
};

export default CollateralRule;
