import { Grid, Stack } from '@mui/material';
import LosText from '../../../components/common/LosText';
import LosAppBar from '../../../components/LosAppBar';
import { LosFormButtonView } from '../../../components/LosFormButton';
import LosFormContainer from '../../../components/LosFormContainer';
import { SelfiePlaceholder } from '../../../icons/LosIcons';
import { useLos } from '../../../providers/LosProvider';
import { useSelfie } from '../SelfieProvider';

function SelfieRejectedView() {
  const { navigateToNextStep, currentStepMetaData } = useLos();
  const { capturedSelfie } = useSelfie();
  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            onClick={() => {
              navigateToNextStep();
            }}
            text="Proceed"
          />
        );
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        );
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent={'center'}
          >
            <div
              style={{
                width: '12.5rem',
                height: '12.5rem',
                borderRadius: '12.5rem',
              }}
            >
              <img
                src={capturedSelfie}
                height="100%"
                width={'100%'}
                style={{
                  objectFit: 'cover',
                  overflow: 'hidden',
                  width: '12.5rem',
                  height: '12.5rem',
                  borderRadius: '12.5rem',
                }}
              ></img>
            </div>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            alignItems="center"
            justifyContent={'center'}
            display="flex"
            flexDirection={'column'}
            spacing={'0.75rem'}
          >
            <LosText text="Photo Verified" />
            <LosText
              text="Your photo has successfully matched with your KYC documents"
              variant="caption"
              textAlign={'center'}
            />
          </Stack>
        </Grid>
      </Grid>
    </LosFormContainer>
  );
}

export default SelfieRejectedView;
