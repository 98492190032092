import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import LosFormInputField from '../../components/common/LosFormInputField';
import LosSelect from '../../components/common/LosSelect';
import LosText from '../../components/common/LosText';
import LosAppBar from '../../components/LosAppBar';
import { LosFormButtonView } from '../../components/LosFormButton';
import LosFormContainer from '../../components/LosFormContainer';
import { useLos } from '../../providers/LosProvider';
import {
  CollateralFormData,
  CollateralTypeFormData,
  GurantorFormData,
} from '../../types/Los';
import { StringUtility, PanEntityType } from '../../utils/StringUtility';
import AddCollateralDetails from './AddCollateralDetails';
import AddGurantorDetails from './AddGurantorDetails';

enum InputType {
  NAME = 'NAME',
  PAN_NUMBER = 'PAN_NUMBER',
  EMAIL = 'EMAIL',
  LOAN_TYPE = 'LOAN_TYPE',
  RELATIONSHIP = 'RELATIONSHIP',
  TYPE_OF_GUARNTEE = 'TYPE_OF_GUARNTEE',
  VALUE_OF_GUARNTEE = 'VALUE_OF_GUARNTEE',
  Type = 'Type',
  Value = 'Value',
}

function CollateralDetails() {
  const {
    navigateToNextStep,
    saveCollateralData,
    currentStepMetaData,
    customerApplication,
  } = useLos();
  const [isGurantorFormExpanded, setIsGurantorFormExpanded] =
    useState<boolean>(false);
  const [formData, setFormData] = useState<CollateralFormData>({
    loanType: '',
    collateralDetails: [],
    guaranteeDetails: {
      name: '',
      email: '',
      panCard: '',
      relationShip: '',
      guaranteeType: '',
      valueOfGuarantee: '',
    },
  });
  const [gurantorErrors, setGurantorErrors] = useState<
    Partial<GurantorFormData>
  >({});
  const [loading, setLoading] = useState<boolean>(false);

  const getLoanAmount = () => {
    const amount = customerApplication?.basicInfo?.amount ?? 0;
    return amount;
  };

  function handleGurantorUpdate(type: string, value: string) {
    const updatedErrors: Partial<GurantorFormData> = { ...gurantorErrors };
    const updatedFormData: CollateralFormData = { ...formData };
    switch (type) {
      case InputType.NAME:
        if (value.length <= 0) {
          updatedErrors.name = 'Name is required';
        } else {
          delete updatedErrors.name;
        }
        updatedFormData.guaranteeDetails.name = value;
        break;
      case InputType.EMAIL:
        if (value.length <= 0) {
          updatedErrors.email = 'Email is required';
        } else {
          const isValidEmail = StringUtility.validateEmail(value);
          if (isValidEmail == false) {
            updatedErrors.email = 'Valid email is required';
          } else {
            delete updatedErrors.email;
          }
        }
        updatedFormData.guaranteeDetails.email = value;
        break;
      case InputType.PAN_NUMBER:
        if (value.length <= 0) {
          updatedErrors.panCard = `Valid Individual Pan Number is required`;
        } else {
          const isValidPan = StringUtility.validatePANNumber(
            PanEntityType.Person,
            value
          );
          if (isValidPan == false) {
            updatedErrors.panCard = `Valid Individual Pan Number is required`;
          } else {
            delete updatedErrors.panCard;
          }
        }
        updatedFormData.guaranteeDetails.panCard = value;
        break;
      case InputType.RELATIONSHIP:
        if (value.length <= 0) {
          updatedErrors.relationShip = 'Relationship is required';
        } else {
          delete updatedErrors.relationShip;
        }
        updatedFormData.guaranteeDetails.relationShip = value;
        break;
      case InputType.TYPE_OF_GUARNTEE:
        if (value.length <= 0) {
          updatedErrors.guaranteeType = 'Type of Guarantee is required';
        } else {
          delete updatedErrors.guaranteeType;
        }
        updatedFormData.guaranteeDetails.guaranteeType = value;
        break;
      case InputType.VALUE_OF_GUARNTEE:
        if (value.length <= 0) {
          updatedErrors.valueOfGuarantee = 'Value of Guarantee is required';
        } else {
          delete updatedErrors.valueOfGuarantee;
        }
        const numberValue = Number(value);
        if (numberValue != null && numberValue > getLoanAmount()) {
          break;
        }
        updatedFormData.guaranteeDetails.valueOfGuarantee = value;
        break;
    }
    setGurantorErrors(updatedErrors);
  }

  function handleCollateralUpdate(data: {
    type: InputType;
    value: string;
    index: number;
  }) {
    const updatedItem = formData.collateralDetails[data.index];

    switch (data.type) {
      case InputType.Type:
        updatedItem.type = data.value;
        break;
      case InputType.Value:
        updatedItem.amount = data.value;
        break;
    }

    const updatedCollaterals = formData.collateralDetails.map((item, index) => {
      if (index == data.index) return updatedItem;
      return item;
    });

    setFormData((prev) => {
      return {
        ...prev,
        collateralDetails: updatedCollaterals,
      };
    });
  }

  function onRemoveCollateral(index: number) {
    var updatedDetails: Partial<CollateralTypeFormData>[] = [
      ...formData.collateralDetails,
    ];

    updatedDetails.splice(index, 1);

    setFormData((prev) => {
      return {
        ...prev,
        collateralDetails: updatedDetails,
      };
    });
  }

  function onAddCollateralClick() {
    setFormData((prev) => {
      return {
        ...prev,
        collateralDetails: [...prev.collateralDetails, {}],
      };
    });
  }

  function onCancelGurantorClick() {
    setFormData((prev) => {
      return {
        ...prev,
        guaranteeDetails: {
          name: '',
          email: '',
          panCard: '',
          relationShip: '',
          guaranteeType: '',
          valueOfGuarantee: '',
        },
      };
    });
  }

  const isInputValid = () => {
    var isGurantorValid = true;
    if (isGurantorFormExpanded) {
      isGurantorValid = isGurantorDetailsValid(
        formData.guaranteeDetails,
        gurantorErrors
      );
    }
    const isCollateralValid = isCollateralDetailsValid(
      formData.collateralDetails
    );

    return isGurantorValid && isCollateralValid;
  };

  async function onSubmitClick() {
    try {
      setLoading(true);
      await saveCollateralData(formData);
      navigateToNextStep();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setFormData((prev) => {
      return {
        ...prev,
        loanType: customerApplication?.basicInfo?.loanType ?? '',
      };
    });
  }, [customerApplication]);

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            text="Proceed to Next Step"
            disabled={isInputValid() == false}
            onClick={onSubmitClick}
            loading={loading}
            renderHelperView={() => {
              return (
                <Stack>
                  <Stack direction={'row'} spacing="1" alignItems={'start'}>
                    <LosText
                      style={{ fontSize: '0.75rem', lineHeight: '130%' }}
                    >
                      On behalf of{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        Dhanvikas Fiscal Services Private Limited
                      </span>{' '}
                      , we shall reach out to you and the application guarantor.
                    </LosText>
                  </Stack>
                </Stack>
              );
            }}
          />
        );
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        );
      }}
    >
      <Grid container rowSpacing={'2rem'}>
        <Grid item xs={12}>
          <LosFormInputField label="Loan Type">
            <LosSelect disabled value={formData.loanType} />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <AddCollateralDetails
            formData={formData.collateralDetails}
            onAddCollateralClick={onAddCollateralClick}
            handleUpdate={handleCollateralUpdate}
            onRemoveCollateral={onRemoveCollateral}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={'1rem'}>
            <AddGurantorDetails
              formData={formData.guaranteeDetails}
              handleUpdate={handleGurantorUpdate}
              errors={gurantorErrors}
              onCancelClick={onCancelGurantorClick}
              onStateChange={function (expanded: boolean): void {
                setIsGurantorFormExpanded(expanded);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </LosFormContainer>
  );
}

export default CollateralDetails;

const isCollateralDetailsValid = (
  formData: Partial<CollateralTypeFormData>[]
) => {
  const invalidValues = formData.filter((item) => {
    if (item.type == null || item.amount == null || item.amount.length <= 0)
      return true;
    return false;
  });
  return invalidValues.length == 0;
};

const isGurantorDetailsValid = (
  formData: Partial<GurantorFormData>,
  errors: Partial<GurantorFormData>
) => {
  const isNameValid = (formData.name ?? '').length > 0;
  const isEmailValid = (formData.email ?? '').length > 0;
  const isPanCardValid =
    (formData.panCard ?? '').length > 0 && (errors.panCard ?? '').length <= 0;
  const isRelationshipValid = (formData.relationShip ?? '').length > 0;
  const isGuaranteeTypeValid = (formData.guaranteeType ?? '').length > 0;
  const isValueOfGuranteeValid = (formData.valueOfGuarantee ?? '').length > 0;
  return (
    isNameValid &&
    isEmailValid &&
    isPanCardValid &&
    isRelationshipValid &&
    isValueOfGuranteeValid &&
    isGuaranteeTypeValid
  );
};
