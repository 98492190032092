import { Close } from '@mui/icons-material';
import { Dialog, Stack, IconButton, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import LOSDateField from '../../../components/LOSDateField';
import LosButton from '../../../onboarding/components/common/LosButton';
import LosFormInputField from '../../../onboarding/components/common/LosFormInputField';
import LosText from '../../../onboarding/components/common/LosText';
import LosTextField from '../../../onboarding/components/common/LosTextField';
import { LoanDispersalSchedule } from '../../types/Lms';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    trench: LoanDispersalSchedule,
    utrNumber: string,
    date: string
  ) => void;
  trench: LoanDispersalSchedule | null;
  index: number;
};
function DisburseTrenchDialog({
  open,
  onClose,
  trench,
  index,
  onSubmit,
}: Props) {
  const [date, setDate] = useState<string>('');
  const [utrNumber, setUtrNumber] = useState<string>('');

  const isInputValid = () => {
    let isDateValid = date.length > 0;
    let isUtrValid = utrNumber.length > 0;
    return isDateValid && isUtrValid;
  };

  function onDisburseClick() {
    if (isInputValid() == false) return;
    if (!trench) return;
    let hasDate = date.length > 0;
    if (hasDate == false) return;
    const dateObject = new Date(date);
    onSubmit(trench, utrNumber, dateObject.toISOString());
    setDate('');
    setUtrNumber('');
    onClose();
  }

  function handeDateChange(e: any) {
    if (!e) return;
    const dateString = e.$d;
    if (!dateString || dateString == 'Invalid Date') return;
    if (dateString.length <= 0) return;
    const dateObject = new Date(dateString);
    setDate(dateObject.toISOString());
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      sx={{ borderRadius: '20px' }}
      onClose={onClose}
      open={open}
    >
      <div
        style={{
          backgroundColor: 'rgba(250, 250, 250, 1)',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems={'center'}
          style={{
            paddingTop: '1.25rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <LosText text="Disburse Now" />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <div
          style={{
            border: '1px solid #E1E4EB',
            padding: '1rem',
            margin: '1rem',
            borderRadius: '0.75rem',
            background: 'white',
          }}
        >
          <Stack spacing={'1rem'}>
            <LosText text="Disburse Details" />
            <div>
              <Grid container spacing={'1rem'}>
                <Grid item xs={4}>
                  <LosFormInputField label="Tranche">
                    <LosTextField
                      value={`Tranche ${index}  (₹${trench?.amount})`}
                      disabled
                    />
                  </LosFormInputField>
                </Grid>
                <Grid item xs={4}>
                  <LosFormInputField label="Utr Number">
                    <LosTextField
                      onChange={(e) => setUtrNumber(e.target.value)}
                    />
                  </LosFormInputField>
                </Grid>
                <Grid item xs={4}>
                  <LosFormInputField label="Disburse Date">
                    <LOSDateField
                      value={date}
                      height="40px"
                      onChange={(e) => {
                        handeDateChange(e);
                      }}
                    />
                  </LosFormInputField>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </div>
      </div>
      <Stack
        direction={'row'}
        justifyContent="end"
        alignItems={'center'}
        style={{ padding: '1rem' }}
      >
        <LosButton
          text="Disburse"
          onClick={onDisburseClick}
          disabled={isInputValid() == false}
        />
      </Stack>
    </Dialog>
  );
}

export default DisburseTrenchDialog;
