import axios from 'axios';
import { LMS } from '../endpoints';

const LEADS_BASE_URL = LMS.LEADS;

export const fetchAllLeads = (page: number, pageSize: number) => {
  return axios.get(`${LEADS_BASE_URL}/list?page=${page}&pageSize=${pageSize}`);
};
export const getAgentDetailsById = (agentId: string) => {
  return axios.get(`${LEADS_BASE_URL}/agent/${agentId}`);
};

//////////////////////// selfie Match \\\\\\\\\\\\\\\\\\\\\\\\\\\
export const saveSelfieMatch = (agentId: string, formData: FormData) => {
  const selfieMatchURL = `${LEADS_BASE_URL}/agent/selfie?agentId=${agentId}`;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.patch(selfieMatchURL, formData, config);
};
