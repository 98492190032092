import VegaOutLineTextField from '../../../../components/common/VegaOutLineTextField';
import { ArrowBackIos, ArrowForwardIos, Search } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { BLUE, BORDER_GRAY } from '../../../../constants/style';
import { useColenders } from '../../providers/ColendersProvider';
import { Colender } from '../../types/Lms';
import LosText from '../../../onboarding/components/common/LosText';

const CoLendersList = () => {
  const { colenders, onColenderSelected, selectedColender } = useColenders();
  return (
    <>
      <Box p={2}>
        <VegaOutLineTextField
          height={'35px'}
          value={''}
          onChange={(e) => console.log('mobile', e.target.value)}
          key="mobile"
          label={'Search'}
          type={'text'}
          placeholder={'Search'}
          Icon={<Search />}
        />
      </Box>
      <div
        style={{
          minHeight: '60vh',
        }}
      >
        {colenders.map((colender: Colender, index: number) => (
          <Box
            key={index}
            sx={{ borderBottom: BORDER_GRAY, cursor: 'pointer' }}
            onClick={() => onColenderSelected(colender)}
          >
            <Box p={2}>
              <Stack spacing={'0.5rem'}>
                <LosText
                  fontSize={13}
                  text={colender.name}
                  color={
                    selectedColender?.id == colender.id ? BLUE.lighter : 'black'
                  }
                />

                <Stack direction={'row'} alignItems="center" spacing={'0.5rem'}>
                  <LosText
                    fontSize={'10px'}
                    lineHeight={'0.5rem'}
                    variant="caption"
                  >
                    Id:
                  </LosText>
                  <LosText
                    fontSize={'10px'}
                    lineHeight={'0.5rem'}
                    variant="caption"
                    textOverflow={'ellipsis'}
                  >
                    {colender.id}
                  </LosText>
                </Stack>
              </Stack>
            </Box>
          </Box>
        ))}
      </div>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: 2,
        }}
      >
        <IconButton>
          <ArrowBackIos sx={{ fontSize: 12 }} />
        </IconButton>
        <IconButton>
          <ArrowForwardIos sx={{ fontSize: 12 }} />
        </IconButton>
      </Box>
    </>
  );
};

export default CoLendersList;
