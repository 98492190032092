import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-scroll';
import LosText from '../components/common/LosText';

function PageNotFound() {
  return (
    <Box
      sx={{
        textAlign: 'center',
        marginTop: 10,
      }}
    >
      {/* <img
        className={classes.image}
        src="/cute-404-image.png"
        alt="Cute 404 Illustration"
      /> */}
      <LosText variant="h5">Oops! Page Not Found</LosText>
      <LosText variant="body1">
        The page you are looking for does not exist.
      </LosText>
    </Box>
  );
}

export default PageNotFound;
