/** @format */

import { Box } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
  gap?: number;
  flexRow?: boolean;
};

const VegaPage = ({ children, gap, flexRow }: Props) => {
  return (
    <Box
      sx={{
        gap: gap || 5,
        // minHeight: '100vh',
        overflow: "auto",
        display: "flex",
        flexDirection: flexRow ? "row" : "column",
      }}
    >
      {children}
    </Box>
  );
};

export default VegaPage;
