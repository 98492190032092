import { Stack } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { LosTextButton } from '../../../components/LosTextButton';
import LosText from '../../components/common/LosText';
import { AddOutlinedIcon } from '../../icons/LosIcons';
import { GurantorFormData } from '../../types/Los';

import AddGurantorForm from './AddGurantorForm';

enum ViewState {
  Collapsed,
  Expanded,
}

type Props = {
  formData: Partial<GurantorFormData>;
  errors: Partial<GurantorFormData>;
  handleUpdate: (type: string, value: string) => void;
  onCancelClick: () => void;
  onStateChange: (expanded: boolean) => void;
};

function AddGurantorDetails({
  formData,
  handleUpdate,
  errors,
  onCancelClick,
  onStateChange,
}: Props) {
  const [viewState, setViewState] = useState<ViewState>(ViewState.Collapsed);

  function onViewStateChange(updatedState: ViewState) {
    setViewState(updatedState);
    onStateChange(updatedState == ViewState.Expanded);
  }

  return (
    <div style={{ width: '100%' }}>
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent={'space-between'}
      >
        <LosText text="Guarantor details" fontSize={'0.875rem'} />
        {viewState == ViewState.Expanded && (
          <LosTextButton
            text="Cancel"
            color="rgba(186, 28, 28, 1)"
            onClick={() => {
              onCancelClick();
              onViewStateChange(ViewState.Collapsed);
            }}
          />
        )}
      </Stack>
      <LosText
        text="Add a guarantor to improve your odds of getting your loan approved"
        variant="caption"
      />
      {viewState == ViewState.Collapsed && (
        <div style={{ marginTop: '1rem' }}>
          <LosTextButton
            text="Add Guarantor"
            startIcon={<AddOutlinedIcon />}
            onClick={() => onViewStateChange(ViewState.Expanded)}
          />
        </div>
      )}
      <div
        style={{
          display: viewState == ViewState.Expanded ? 'block' : 'none',
          marginTop: '1.5rem',
        }}
      >
        <AddGurantorForm
          formData={formData}
          errors={errors}
          handleUpdate={function (type: string, value: string): void {
            handleUpdate(type, value);
          }}
        />
      </div>
    </div>
  );
}

export default AddGurantorDetails;
