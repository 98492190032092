/** @format */

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { ReactNode } from 'react';
import { BLUE, PRIMARY, PURPLE } from '../../constants/style';
import { useLocation } from 'react-router-dom';
import LosText from '../../solfen/onboarding/components/common/LosText';

type Props = {
  icon?: string | ReactNode;
  label?: string | ReactNode;
  id: string;
  open?: boolean;
  isMobile?: boolean;
  isChecker?: boolean;
  isSamllScreen?: boolean;
  sx?: SxProps<Theme>;
  selected?: boolean;
  onItemClick?: () => void;
} & (
  | {
      icon: string | ReactNode;
    }
  | {
      label: string | ReactNode;
    }
);

const VegaListItem = ({
  icon,
  label,
  id,
  sx,
  selected,
  open,
  onItemClick,
  isMobile,
  isChecker,
  isSamllScreen,
}: Props) => {
  return (
    <ListItem
      key={id}
      disableGutters
      sx={{
        ...(selected && {
          bgcolor: 'rgba(10, 43, 134, 1)',
          border: '0.25rem solid rgba(255, 123, 0, 1)',
          borderLeft: 'none',
          borderTop: 'none',
          borderBottom: 'none',
        }),
        py: 0,
        ...(sx || {}),
      }}
    >
      <ListItemButton
        sx={{
          paddingLeft: selected
            ? !open
              ? '1.5rem'
              : isMobile || isSamllScreen
              ? 4
              : 1
            : !open
            ? '1.5rem'
            : isMobile || isSamllScreen
            ? '1.5rem'
            : 2,
          justifyContent: open ? 'initial' : 'flex-start',
          transition: 'all 500ms ease-in-out',
          position: 'relative',
        }}
        onClick={onItemClick}
      >
        {icon && open ? (
          <Tooltip
            title={label}
            placement="right"
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: '16px',
                  bgcolor: PRIMARY.main,
                  '& .MuiTooltip-arrow': {
                    color: 'common.black',
                  },
                },
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                my: 0.5,
                ...(isMobile === true || isSamllScreen === true
                  ? { paddingRight: 2 }
                  : { mx: 'auto' }),

                color: 'inherit',
                bgcolor: 'inherit',
              }}
            >
              {icon}
            </ListItemIcon>
          </Tooltip>
        ) : (
          <ListItemIcon
            sx={{
              minWidth: 'unset',
              paddingRight: 2,
              my: 0.5,
              color: 'inherit',
              bgcolor: 'inherit',
            }}
          >
            {icon}
          </ListItemIcon>
        )}
        {label && !open ? (
          <Box sx={{ position: 'relative' }}>
            {isChecker && (
              <Box>
                <Typography
                  sx={{
                    bgcolor: PURPLE.main,
                    fontSize: '10px',
                    color: BLUE.dark,
                    position: 'absolute',
                    top: '-8px',
                    borderRadius: '8px',
                    px: 0.5,
                  }}
                >
                  Checker
                </Typography>
              </Box>
            )}
            <LosText text={`${label}`} fontSize="0.8125rem" />
          </Box>
        ) : isMobile === true || isSamllScreen === true ? (
          <Box sx={{ position: 'relative' }}>
            {isChecker && (
              <Box>
                <Typography
                  sx={{
                    bgcolor: BLUE.light,
                    fontSize: '10px',
                    color: BLUE.dark,
                    position: 'absolute',
                    top: '-8px',
                    borderRadius: '5px',
                    px: 0.5,
                  }}
                >
                  Checker
                </Typography>
              </Box>
            )}
            <LosText text={`${label}`} fontSize="0.8125rem" />
          </Box>
        ) : (
          ''
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default VegaListItem;
