import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  GREEN,
  PRIMARY,
  RED,
  YELLOW,
  GREY,
  COMMON,
} from '../../constants/style';
import { Box, SxProps, Theme } from '@mui/material';

interface IButtonProps {
  bgColor?: string;
  className?: string;
  sx?: SxProps<Theme>;
  mx?: string | number;
  px?: number;
  py?: number;
  text: string;
  isPrimary?: boolean;
  isPurple?: boolean;
  isDanger?: boolean;
  isSuccess?: boolean;
  isWarning?: boolean;
  isGrey?: boolean;
  disabled?: boolean;
  loading?: boolean;
  isLeftBorder?: boolean;
  isRightBorder?: boolean;
  startIcon?: JSX.Element | string;
  onClick?: (e: any) => void;
  type?: 'button' | 'reset' | 'submit' | undefined;
}

const VegaContainedButton = ({
  bgColor,
  className,
  text,
  mx,
  px,
  py,
  startIcon,
  isDanger,
  isPrimary,
  isSuccess,
  isWarning,
  isGrey,
  loading,
  disabled,
  isRightBorder,
  isLeftBorder,
  isPurple,
  onClick,
  type,
  sx,
}: IButtonProps) => {
  return (
    <>
      <LoadingButton
        type={type}
        variant="contained"
        loading={loading}
        loadingPosition="start"
        disabled={disabled}
        // loadingIndicator="Loading..."
        startIcon={startIcon ?? ''}
        sx={{
          textTransform: 'capitalize',
          mx: mx ?? 1,
          px: px ?? 2,
          py: py ?? 0.5,
          borderRadius: '5px',
          ...(isLeftBorder && {
            borderTopLeftRadius: '5px !important',
            borderBottomLeftRadius: '5px !important',
            borderTopRightRadius: '0px !important',
            borderBottomRightRadius: '0px !important',
          }),
          ...(isRightBorder && {
            borderTopLeftRadius: '0px !important',
            borderBottomLeftRadius: '0px !important',
            borderTopRightRadius: '5px !important',
            borderBottomRightRadius: '5px !important',
          }),
          ...(isPrimary
            ? {
                bgcolor: PRIMARY.light,
                ':hover': { bgcolor: PRIMARY.light },
                '&.Mui-disabled': {
                  color: PRIMARY.light,
                  bgcolor: PRIMARY.lighter,
                },
              }
            : isDanger
            ? {
                bgcolor: RED.lightRed,
                color: RED.red,
                ':hover': { bgcolor: RED.lightRed, color: RED.red },
                '&.Mui-disabled': {
                  color: RED.red,
                  bgcolor: RED.lightRed,
                },
              }
            : isWarning
            ? {
                bgcolor: YELLOW.main,
                boxShadow: `0px 3px 1px -2px rgb(255 200 0 / 20%), 0px 2px 2px 0px rgb(253 222 2 / 14%), 0px 1px 5px 0px rgb(235 197 12 / 12%);`,
                ':hover': { bgcolor: YELLOW.main },
                '&.Mui-disabled': {
                  color: YELLOW.main,
                  bgcolor: YELLOW.light,
                },
              }
            : isSuccess
            ? {
                bgcolor: GREEN.dark,
                ':hover': { bgcolor: GREEN.dark },
                '&.Mui-disabled': {
                  color: GREEN.dark,
                  bgcolor: GREEN.light,
                },
              }
            : isGrey
            ? {
                bgcolor: PRIMARY.blue,
                ':hover': { bgcolor: PRIMARY.blue },
                '&.Mui-disabled': {
                  color: COMMON.darkGray,
                  bgcolor: '#29354354',
                },
              }
            : isPurple
            ? {
                bgcolor: PRIMARY.darkBlue,
                ':hover': { bgcolor: PRIMARY.darkBlue },
                '&.Mui-disabled': {
                  color: PRIMARY.darkBlue,
                  bgcolor: PRIMARY.lighter,
                },
              }
            : {
                bgcolor: '#B6B6B6',
                ':hover': { bgcolor: '#B6B6B6' },
                '&.Mui-disabled': {
                  color: 'black',
                  bgcolor: '#8080807d',
                },
              }),
          ...sx,
        }}
        onClick={onClick}
      >
        <Box
          component={'span'}
          className={className}
          sx={{ ...(loading && { ml: 2 }), lineHeight: 1.5 }}
        >
          {text}
        </Box>
      </LoadingButton>
    </>
  );
};

export default VegaContainedButton;
