import { CircularProgress, Divider, Grid } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { LoanApplicationDetails } from '../../../../LMSHQ/types/Lms';
import LosFormInputField from '../../../../onboarding/components/common/LosFormInputField';
import LosText from '../../../../onboarding/components/common/LosText';
import { useColendingLoans } from '../../../provider/ColendingLoansProvider';

function ColendingApplicationDetailsTab() {
  const { fetchApplicationDetails, selectedLoan } = useColendingLoans();
  const [loading, setLoading] = useState<boolean>(false);
  const [applicationDetails, setApplicationDetails] = useState<
    Partial<LoanApplicationDetails>
  >({});

  async function fetchDetails() {
    try {
      setApplicationDetails({});
      setLoading(true);
      const response = await fetchApplicationDetails();
      setApplicationDetails(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const getCustomer = () => applicationDetails?.customer;
  const getAgent = () => applicationDetails?.agent;

  const getUserType = () => {
    if (applicationDetails.application?.basicInfo.subType) {
      return _.startCase(
        _.toLower(applicationDetails.application?.basicInfo.subType)
      );
    }
    return '-';
  };

  useEffect(() => {
    fetchDetails();
  }, [selectedLoan]);

  return (
    <div
      style={{
        padding: '1rem',
      }}
    >
      <div
        style={{
          display: loading ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
      <div
        style={{
          display: loading == false ? 'flex' : 'none',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <LosText
          text="Customer Details"
          color={'#1B1D22'}
          fontSize={'0.875rem'}
          fontWeight={500}
          lineHeight={'140%'}
        />
        <Grid container spacing={'1rem'}>
          <Grid item xs={3}>
            <Field
              title="Customer Id"
              description={getCustomer()?.customerId}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              title="Customer Name"
              description={applicationDetails.application?.customerName}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              title="Mobile Number"
              description={applicationDetails.application?.phone}
            />
          </Grid>
          <Grid item xs={3}>
            <Field title="Entity " description={getUserType()} />
          </Grid>
        </Grid>
      </div>
      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          display: loading == false ? 'block' : 'none',
        }}
      />
      <div
        style={{
          display: loading == false ? 'flex' : 'none',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <LosText text="Agent Details" />
        <Grid container spacing={'1rem'}>
          <Grid item xs={3}>
            <Field title="Agent Id" description={getAgent()?.id} />
          </Grid>
          <Grid item xs={3}>
            <Field title="Agent Name" description={getAgent()?.name} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ColendingApplicationDetailsTab;

const Field = (data: { title: string; description?: string }) => {
  return (
    <LosFormInputField label={data.title}>
      <LosText
        text={data.description ?? '-'}
        fontSize={'0.75rem'}
        fontWeight={400}
        lineHeight={'130%'}
        color={'#1B1D22'}
      />
    </LosFormInputField>
  );
};
