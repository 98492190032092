import { Card, Stack, Grid } from '@mui/material';
import { useState } from 'react';
import LosText from './common/LosText';

type Props = {
  name: string;
  panNumber: string;
  status: string;
  fullWidth?: boolean;
};
export const PanDetailsCard = ({
  name,
  panNumber,
  status,
  fullWidth = false,
}: Props) => {
  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '0.62rem',
        borderRadius: '0.875rem',
        paddingTop: '0.5rem',
        paddingBottom: '0.75rem',
        background: 'linear-gradient(226deg, #E5F8FF 0%, #F5EDFF 100%)',
        ...(fullWidth == false && { width: '20rem' }),
      }}
    >
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent={'space-between'}
        style={{
          height: '4.2rem',
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
          borderBottom: '1px solid rgba(43, 123, 255, 0.5)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem',
          }}
        >
          <div
            style={{
              height: '2.104014rem',
              width: '3.1238rem',
            }}
          >
            <img
              src={require('../assets/income_tax_logo.png')}
              height={'100%'}
              width={'100%'}
            ></img>
          </div>
          <div style={{}}>
            <LosText text="आयकर विभाग" lineHeight={'120%'} />
            <LosText
              text="INCOME TAX DEPARTMENT"
              variant="caption"
              fontSize={'0.625rem'}
              lineHeight={'130%'}
            />
          </div>
        </div>
        <div
          style={{
            height: '2.56024rem',
            width: '1.486rem',
          }}
        >
          <img
            src={require('../assets/indian_enmblem.png')}
            height={'100%'}
            width={'100%'}
          ></img>
        </div>
      </Stack>
      <Stack
        direction={'row'}
        style={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <PanLabel label="Name" description={name} />
      </Stack>

      <Grid
        container
        direction={'row'}
        justifyContent="start"
        style={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <Grid item xs={6}>
          <PanLabel label="Pan Number" description={panNumber} />
        </Grid>
        <Grid item xs={6}>
          <PanLabel label="Status" description={status} />
        </Grid>
      </Grid>
    </Card>
  );
};

const PanLabel = (data: { label: string; description: string }) => {
  return (
    <Stack spacing={'0px'}>
      <LosText text={data.label} variant="caption" />
      <LosText text={data.description} fontSize={'10px'} />
    </Stack>
  );
};
