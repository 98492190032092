/** @format */
import './../../../components/common/v2/Styles/__styles.css';
import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientImg } from '../../../components/icon/LoginImage';
import { BORDER, BLUE } from '../../../constants/style';
import { useClientAuth } from '../../../providers/ClientProvider';
import { ROUTES } from '../../../routes';
import LosButton from '../../onboarding/components/common/LosButton';
import LosFormInputField from '../../onboarding/components/common/LosFormInputField';
import LosText from '../../onboarding/components/common/LosText';
import LosTextField from '../../onboarding/components/common/LosTextField';
import { VegaPayLogo } from '../../onboarding/icons/LosIcons';

const AgentLogin = () => {
  const { login } = useClientAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigation = useNavigate();

  const forgotPasswordHandler = () => {
    navigation(ROUTES.FORGOT_PASSWORD);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        p: 5,
        bgcolor: '#EBEFF4',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        sx={{
          bgcolor: 'background.paper',
          borderRadius: '20px',
          py: 8,
          px: 5,
          maxWidth: '1100px',
          marginX: 'auto',
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            ...(isMobile ? { borderBottom: BORDER } : { borderRight: BORDER }),
          }}
          justifyContent="center"
        >
          <div>
            <Stack
              direction={'row'}
              alignItems="center"
              justifyContent={'center'}
              spacing="1rem"
            >
              <div>
                <img src={'https://solfin.co.in/images/solfin-logo.svg'}></img>
              </div>

              {/* <VegaPayLogo /> */}
              <LosText
                variant="body2"
                sx={{ fontWeight: 'bold', color: BLUE.dark, mt: 1.5 }}
              >
                Agent Dashboard
              </LosText>
            </Stack>
            <ClientImg />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            pl: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <LosText mb={5} textAlign={'center'}>
            Let's get you started
          </LosText>
          <Box component={'form'} onSubmit={(e) => login(e, email, password)}>
            <Box mb={2}>
              <LosFormInputField label="Email">
                <LosTextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </LosFormInputField>
            </Box>
            <Box mb={3}>
              <LosFormInputField label="Password">
                <LosTextField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                />
              </LosFormInputField>
            </Box>
            <Stack spacing={2}>
              <LosButton type="submit" text="Login" />
              <Typography
                sx={{
                  color: BLUE.lighter,
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                component={'span'}
                onClick={forgotPasswordHandler}
                textAlign={'center'}
              >
                Forgot Password ?
              </Typography>
            </Stack>
          </Box>
          <Box mt={2} textAlign="center">
            <LosText style={{ fontWeight: 500 }}>
              <span
                style={{
                  color: BLUE.lighter,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontFamily: 'Aspekta !important',
                }}
                onClick={() =>
                  window.open('https://solfin.co.in/Privacypolicy')
                }
              >
                Privacy Policy
              </span>{' '}
              &{' '}
              <span
                style={{
                  color: BLUE.lighter,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontFamily: 'Aspekta !important',
                }}
                onClick={() =>
                  window.open('https://solfin.co.in/Termsandconditions')
                }
              >
                Terms of Service
              </span>{' '}
              apply.
            </LosText>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgentLogin;
