import { Box, styled } from '@mui/material';
import { ReactNode } from 'react';

interface StyledPillProps {
  textColor?: string;
  backgroundColor?: string;
  padding?: string;
  borderRadius?: string;
}

const StyledPill = styled(Box)<StyledPillProps>(
  ({
    theme,
    textColor = 'white',
    backgroundColor,
    padding = '0.25rem 0.5rem',
    borderRadius = '0.75rem',
  }) => ({
    alignItems: 'center',
    padding: padding,
    borderRadius: borderRadius,
    color: textColor,
    backgroundColor: backgroundColor,
  })
);

interface VegaPillProps extends StyledPillProps {
  children: ReactNode;
}

function VegaPill({ children, ...rest }: VegaPillProps) {
  return <StyledPill {...rest}>{children}</StyledPill>;
}

export default VegaPill;
