import { ArrowBackIos, ArrowForwardIos, Search } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import dayjs from 'dayjs';
import VegaOutLineTextField from '../../../../components/common/VegaOutLineTextField';
import { BORDER_GRAY, BLUE } from '../../../../constants/style';
import { CustomLoanDto, Loan } from '../../../LMSHQ/types/Lms';
import LosText from '../../../onboarding/components/common/LosText';
import { StringUtility } from '../../../onboarding/utils/StringUtility';
import { useColendingLoans } from '../../provider/ColendingLoansProvider';

function ColendingActiveLoansList() {
  const {
    activeLoans,
    onLoanSelect,
    loadingLoans,
    selectedLoan,
    loadNextPage,
    loadPreviousPage,
  } = useColendingLoans();

  const getLoanTitle = (loan: CustomLoanDto) => {
    const name = loan.customerName;
    var displayText = '-';
    if (name && name.length > 0) {
      displayText = name;
    }
    return displayText;
  };

  const getLoanDescriptionDisplayText = (loan: CustomLoanDto) => {
    const mobile = loan.mobile;
    var displayText = '-';
    if (mobile && mobile.length > 0) {
      displayText = mobile;
    }
    return displayText;
  };

  return (
    <>
      <Box p={2}>
        <VegaOutLineTextField
          height={'35px'}
          value={''}
          onChange={(e) => console.log('mobile', e.target.value)}
          key="mobile"
          label={'Search'}
          type={'text'}
          placeholder={'Search'}
          Icon={<Search />}
        />
      </Box>
      {loadingLoans && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div
        style={{
          minHeight: '60vh',
        }}
      >
        {activeLoans.map((loan: CustomLoanDto, index: number) => (
          <Box
            sx={{
              borderBottom: BORDER_GRAY,
              cursor: 'pointer',
            }}
            onClick={() => onLoanSelect(loan)}
          >
            <Stack p={2} spacing={'0.5rem'}>
              <LosText
                fontSize={13}
                text={getLoanTitle(loan)}
                color={selectedLoan?.id == loan.id ? BLUE.lighter : 'black'}
              />
              <LosText
                fontSize={'10px'}
                text={getLoanDescriptionDisplayText(loan)}
                variant="caption"
              />
              <LosText
                fontSize={'10px'}
                lineHeight={'0.5rem'}
                variant="caption"
                textOverflow="ellipsis"
                noWrap
              >
                LAN: {loan.id}
              </LosText>
            </Stack>
          </Box>
        ))}
      </div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: 2,
        }}
      >
        <IconButton onClick={loadPreviousPage}>
          <ArrowBackIos sx={{ fontSize: 12 }} />
        </IconButton>
        <IconButton onClick={loadNextPage}>
          <ArrowForwardIos sx={{ fontSize: 12 }} />
        </IconButton>
      </Box>
    </>
  );
}

export default ColendingActiveLoansList;
