import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import AadharDetailsCard from "../../../components/AadharDetailsCard";
import LosAppBar from "../../../components/LosAppBar";
import { LosFormButtonView } from "../../../components/LosFormButton";
import LosFormContainer from "../../../components/LosFormContainer";
import PanDetailsVerifiedStatusCard from "../../../components/PanDetailsVerifiedCard";
import { useLos } from "../../../providers/LosProvider";
import {
  AadharMetaData,
  CustomerAadharData,
  CustomerPanData,
  PanMetadata,
} from "../../../types/Los";
import { useCompanyPanVerification } from "../../../providers/CompanyPanProvider";
import { useSelfie, SelfieViewState } from "../SelfieProvider";
import { LosService } from "../../../api/LosService";

function SelfieIntialView() {
  const { onViewStateChange } = useSelfie();
  const {
    customerApplication,
    currentStepMetaData,
    fetchPanDetailsForPanInfoId,
    fetchAadharDetailsForAadharInfoId,
  } = useLos();
  const { promoters } = useCompanyPanVerification();
  const [individualPanDetails, setIndividualPanDetails] =
    useState<PanMetadata>();
  const [individualAadhaarData, setIndividualAadhaarData] =
    useState<AadharMetaData>();
  const [loading, setLoading] = useState<boolean>(false);

  async function fetchPanDetails() {
    setLoading(true);
    // const members = customerApplication?.members ?? []
    // if (members.length > 0) {
    //   const firstMember = members[0]
    //   const panInfoId = firstMember.panInfoId
    //   if (panInfoId && panInfoId.length > 0) {
    //     const response = await fetchPanDetailsForPanInfoId(panInfoId)
    //     setIndividualPanDetails(response)
    //   }
    // }
    const index = customerApplication?.currentUserIndex
      ? customerApplication?.currentUserIndex
      : 0;
    const userId = customerApplication?.users[index];
    if (!userId) {
      console.error("UserId not found for member ");
      return;
    }
    const response = await LosService.getPanDetailsByUserId(userId);
    setIndividualPanDetails(response);
    setLoading(false);
  }

  async function fetchAadharDetails() {
    try {
      // const members = customerApplication?.members ?? [];
      // if (members.length <= 0) {
      //   console.error('No members found');
      //   return;
      // }
      // const latestMember = members[members.length - 1];
      // const aadharInfoId = latestMember.aadhaarInfoId;
      // if (!aadharInfoId) {
      //   console.error('AadhaarInfoId not found for member ' + latestMember);
      //   return;
      // }
      // const response = await fetchAadharDetailsForAadharInfoId(aadharInfoId);
      const index = customerApplication?.currentUserIndex
        ? customerApplication?.currentUserIndex
        : 0;
      const userId = customerApplication?.users[index];
      if (!userId) {
        console.error("UserId not found for member ");
        return;
      }
      const response = await LosService.getAdhaarDetailsByUserId(userId);

      setIndividualAadhaarData(response);
    } catch (error) {}
  }

  useEffect(() => {
    fetchPanDetails();
    fetchAadharDetails();
  }, [customerApplication]);

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            onClick={() => {
              onViewStateChange(SelfieViewState.CaptureSelfie);
            }}
            text="Proceed to Photo Verification"
            loading={loading}
          />
        );
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        );
      }}
    >
      <Grid container rowSpacing={"1.5rem"}>
        <Grid item xs={12}>
          <PanDetailsVerifiedStatusCard
            name={individualPanDetails?.panName}
            panNumber={individualPanDetails?.panNumber}
            status="Verified"
          />
        </Grid>
        <Grid item xs={12}>
          <AadharDetailsCard
            name={individualAadhaarData?.name}
            dob={individualAadhaarData?.dob}
            aadharNumber={individualAadhaarData?.uid}
            status="Verified"
            address={individualAadhaarData?.address}
          />
        </Grid>
      </Grid>
    </LosFormContainer>
  );
}

export default SelfieIntialView;
