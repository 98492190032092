import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useClientAuth } from '../../providers/ClientProvider';
import { ROUTES } from '../../routes';

type Props = {
  children: ReactNode;
};

export function PrivateAgentRoute({ children }: Props) {
  const { isAuth } = useClientAuth();

  if (isAuth == true) return <>{children}</>;

  return <Navigate to={ROUTES.AGENT_LOGIN} />;
}
