import { Box } from '@mui/material';
import { useRuleEngine } from '../../../../LMSAgent/Provider/RuleEngingProvider';
import LosText from '../../../LosText';
import WeightScoreWrapper from '../../WeightScoreWrapper';
import CollateralList from './components/CollateralList';
import ScoreOverview from './components/ScoreOverview';

const CollateralRule = () => {
  const { loading, underWrittingScrore } = useRuleEngine();
  return (
    <>
      <WeightScoreWrapper
        value={
          underWrittingScrore?.underwritingCollateralScore
            ? underWrittingScrore?.underwritingCollateralScore.weightedPoints
            : 0
        }
      />

      <Box
        sx={{
          pb: 2,
        }}
      >
        <CollateralList />
      </Box>
      <Box
        sx={{
          py: 1.8,
          borderTop: '1px solid #E1E4EB',
        }}
      >
        <LosText
          text=" Score Overview"
          fontWeight={500}
          fontSize={'0.875rem'}
          mb={2}
        />
        <ScoreOverview />
      </Box>
    </>
  );
};

export default CollateralRule;
