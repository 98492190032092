import { Card, Grid, Stack } from '@mui/material';
import LosText from './common/LosText';

type Props = {
  name?: string;
  dob?: string;
  aadharNumber?: string;
  status?: string;
  address?: string;
};

function AadharDetailsCard({
  name,
  aadharNumber,
  dob,
  status,
  address,
}: Props) {
  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '0.62rem',
        borderRadius: '7.317px',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        background: 'white',
        width: '100%',
      }}
    >
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent={'space-between'}
        style={{
          height: '4.2rem',
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
          borderBottom: '1px solid rgba(250, 182, 15, 1)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem',
          }}
        >
          <div
            style={{
              height: '2.104014rem',
              width: '3.1238rem',
            }}
          >
            <img
              src={require('../assets/aadhaar_logo.png')}
              height={'100%'}
              width={'100%'}
            ></img>
          </div>
          <div style={{}}>
            <LosText text="UIDAI" lineHeight={'120%'} fontSize={'0.8125rem'} />
            <LosText
              text="GOVERNMENT OF INDIA"
              variant="caption"
              fontSize={'0.625rem'}
              lineHeight={'130%'}
            />
          </div>
        </div>
        <div
          style={{
            height: '2.56024rem',
            width: '1.486rem',
          }}
        >
          <img
            src={require('../assets/indian_enmblem.png')}
            height={'100%'}
            width={'100%'}
          ></img>
        </div>
      </Stack>
      <Grid
        container
        direction={'row'}
        justifyContent="start"
        style={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <Grid item xs={6}>
          <Label label="Name" description={name} />
        </Grid>
        <Grid item xs={6}>
          <Label label="DOB" description={dob} />
        </Grid>
      </Grid>

      <Grid
        container
        direction={'row'}
        justifyContent="start"
        style={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <Grid item xs={6}>
          <Label label="Aadhaar Number" description={aadharNumber} />
        </Grid>
        <Grid item xs={6}>
          <Label label="Status" description={status} />
        </Grid>
      </Grid>
      <Grid
        container
        direction={'row'}
        justifyContent="start"
        style={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
        }}
      >
        <Grid item xs={12}>
          <Label label="Address" description={address} />
        </Grid>
      </Grid>
    </Card>
  );
}

export default AadharDetailsCard;

const Label = (data: { label: string; description?: string }) => {
  return (
    <Stack spacing={'0px'}>
      <LosText text={data.label} variant="caption" />
      <LosText text={data.description ?? '-'} fontSize={'10px'} />
    </Stack>
  );
};
