import {
  Box,
  InputAdornment,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from '@mui/material';
import { ChangeEvent, HTMLInputTypeAttribute } from 'react';
import { PRIMARY } from '../../constants/style';
import VegaHeader from './VegaHeader';
import { RejectedOutlined } from '../../solfen/onboarding/icons/LosIcons';

export type InputProps = {
  value: number | string;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
  label?: string;
  className?: string;
  key: string;
  type?: HTMLInputTypeAttribute;
  size?: 'small' | 'medium' | undefined;
  textFieldWidth?: number;
  disabled?: boolean;
  autoFocus?: boolean;
  sx?: SxProps<Theme>;
  multiline?: boolean;
  rows?: number;
  startAdornment?: React.ReactNode | null;
  endAdornment?: React.ReactNode | null;

  maxNumber?: number;
  fontSize?: number;
  minNumber?: number;
  maxLength?: number;
  focused?: boolean;
  error?: boolean;
  isFixedHeight?: boolean;
  errorHelperText?: string;
  headerText?: string;
  name?: any;
  errorText?: any;
};

export type IProps = {
  textFieldWidth?: number;

  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  maxNumber?: number;
  minNumber?: number;
  maxLength?: number;
  isFixedHeight?: boolean;
  headerText?: string;
  errorText?: string;
  name?: any;
} & TextFieldProps;

const VegaTextField = ({
  className,
  textFieldWidth,
  startAdornment,
  endAdornment,
  maxNumber,
  minNumber,
  maxLength,
  headerText,
  isFixedHeight,
  errorText,
  name,
  ...rest
}: IProps) => {
  const { type, size, autoComplete, sx, ...restProps } = rest;
  return (
    <Box sx={{ position: 'relative' }}>
      {headerText?.length && <VegaHeader text={headerText} />}
      <TextField
        onWheel={(e: any) => e.target.blur()}
        autoComplete={autoComplete || 'false'}
        fullWidth={!textFieldWidth}
        InputLabelProps={{
          style: { color: '#AFAFAF' },
        }}
        InputProps={
          isFixedHeight
            ? {
                style: {
                  borderRadius: '9px',
                  height: '35px',
                },
                endAdornment: endAdornment,
                startAdornment: startAdornment,
              }
            : {
                style: {
                  borderRadius: '9px',
                  height: '40px',
                },
                endAdornment: endAdornment,
                startAdornment: startAdornment,
              }
        }
        type={type}
        inputProps={
          type === 'number'
            ? {
                max: maxNumber,
                min: minNumber,
              }
            : {
                maxLength: maxLength,
              }
        }
        size={size ?? 'medium'}
        sx={{
          // fontSize: { fontSize },
          ...(isFixedHeight
            ? {
                '& .MuiInputLabel-root': {
                  transform: 'translate(14px, 6px) scale(1)',
                  // fontFamily: 'Aspekta-450 !important',
                  // fontSize: 13,
                },
              }
            : {
                '& .MuiInputLabel-root': {
                  transform: 'translate(14px, 13px) scale(1)',
                },
              }),
          '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
          ...(textFieldWidth && { width: textFieldWidth }),
          '& .MuiFormLabel-root': {
            '&.Mui-focused': {
              color: `${PRIMARY.main} !important`,
            },
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#AFAFAF',
              borderWidth: '1px',
              borderRadius: '9px',
            },
            ':hover fieldset': {
              borderColor: PRIMARY.main,
            },
          },
          ...sx,
        }}
        {...(rest.error && {
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <RejectedOutlined />
              </InputAdornment>
            ),
          },
        })}
        {...restProps}
      />
      {errorText && (
        <Typography
          className="font-aspekta-500"
          color={'red'}
          fontSize={13}
          sx={{ position: 'absolute', right: 0 }}
        >
          {errorText[name]}
        </Typography>
      )}
    </Box>
  );
};

export default VegaTextField;
