import { Stack, Box } from '@mui/material';
import { ReactNode } from 'react';
import LosText from '../onboarding/components/common/LosText';

export const LosTextButton = (data: {
  text: string;
  startIcon?: ReactNode;
  color?: string;
  onClick?: () => void;
  py?: string;
}) => {
  return (
    <Stack direction={'row'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          cursor: 'pointer',
          gap: '0.5rem',
          ...(data.py && {
            py: data.py,
          }),
        }}
        onClick={data.onClick}
      >
        {data.startIcon}
        <LosText
          text={data.text}
          color={data.color ?? 'rgba(16, 71, 220, 1)'}
          style={{
            fontSize: '0.75rem',
            fontWeight: 500,
            lineHeight: '130%',
          }}
        />
      </Box>
    </Stack>
  );
};
