import { Close } from '@mui/icons-material';
import {
  Dialog,
  Stack,
  IconButton,
  Divider,
  Grid,
  CircularProgress,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { LOS_PROGRAM_ID } from '../../../onboarding/api/constants';
import { LosService } from '../../../onboarding/api/LosService';
import LosButton from '../../../onboarding/components/common/LosButton';
import LosFormInputField from '../../../onboarding/components/common/LosFormInputField';
import LosSelect, {
  LosSelectOption,
} from '../../../onboarding/components/common/LosSelect';
import LosText from '../../../onboarding/components/common/LosText';
import LosTextField from '../../../onboarding/components/common/LosTextField';
import {
  CustomerApplication,
  LendingOfferFormData,
} from '../../../onboarding/types/Los';
import { ColenderService } from '../../api/ColenderService';
import { Colender } from '../../types/Lms';
import { ReadOnlyFormField } from '../Co-Lenders/components/Forms/ReadOnlyFormField';

type Props = {
  open: boolean;
  onClose: () => void;
  applicationId?: string;
  onSubmit: (
    applicationId: string,
    requestedLoanAmount: number,
    formData: Partial<LendingOfferFormData>
  ) => void;
};

enum InputType {
  LoanType = 'loanType',
  ApprovedLoanAmount = 'approvedLoanAmount',
  RateOfInterest = 'rateOfInterest',
  ColenderId = 'colenderId',
  ColenderRatio = 'colenderRatio',
}

function LendingDetailsDialog({
  open,
  onClose,
  applicationId,
  onSubmit,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [colenders, setColenders] = useState<Colender[]>([]);
  const [selectedColender, setSelectedColender] = useState<Colender>();
  const [primaryColender, setPrimaryColender] = useState<Colender>();
  const [customerApplication, setCustomerApplication] =
    useState<CustomerApplication>();
  const [solfinRoi, setSolfinRoi] = useState<number>(0);

  const [formData, setFormData] = useState<Partial<LendingOfferFormData>>({
    loanType: '',
    approvedLoanAmount: '',
    rateOfInterest: '',
    colenderId: '',
    colenderRatio: '',
  });

  function fetchColenders() {
    setLoading(true);
    ColenderService.getColenders(LOS_PROGRAM_ID)
      .then((res) => {
        setColenders(res);
        const filtered =
          res.filter((item) => item.isPrimaryLender == true) ?? [];

        if (filtered.length > 0) {
          setPrimaryColender(filtered[0]);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }

  function fetchApplication(applicationId: string) {
    setLoading(true);
    LosService.getApplicationById(applicationId)
      .then((res) => {
        setCustomerApplication(res);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }

  const isInputValid = () => {
    const isLoanAmountValid = (formData.approvedLoanAmount ?? '').length > 0;
    const isLoanTypeValid = (formData.loanType ?? '').length > 0;
    var isPrimaryRoiValid = (formData.rateOfInterest ?? '').length > 0;
    var isRatioValid = true;
    var isColenderValid = true;

    if (formData.loanType == LoanType.COLENDED) {
      isPrimaryRoiValid = isParentROIValid();
      isRatioValid = (formData.colenderRatio ?? '').length > 0;
      isColenderValid = (formData.colenderId ?? '').length > 0;
    }
    return (
      isLoanAmountValid &&
      isLoanTypeValid &&
      isPrimaryRoiValid &&
      isRatioValid &&
      isColenderValid
    );
  };

  function handleUpdate(type: InputType, value: string) {
    if (type == InputType.ApprovedLoanAmount) {
      const numberValue = Number(value);
      const approvedLoanAmountNumberValue = Number(
        customerApplication?.basicInfo?.amount
      );
      if (numberValue > approvedLoanAmountNumberValue) {
        return;
      }
    }
    if (type == InputType.RateOfInterest) {
      const numberValue = Number(value);
      if (numberValue > 100) return;
    }

    if (type == InputType.LoanType) {
      setFormData((prev) => {
        return {
          ...prev,
          colenderId: undefined,
          colenderRatio: undefined,
          loanType: value,
        };
      });
      return;
    }

    setFormData((prev) => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  function handleColenderSelection(colenderId: string) {
    handleUpdate(InputType.ColenderId, colenderId);
    const filteredColenders =
      colenders.filter((item) => item.id === colenderId) ?? [];
    if (filteredColenders.length > 0) {
      setSelectedColender(filteredColenders[0]);
    }
  }

  const getColenderAmount = () => {
    if (formData.approvedLoanAmount && formData.colenderRatio) {
      var amount = 0;
      switch (formData.colenderRatio) {
        case ColendingRatio.Fity_Fifty:
          amount = Number(formData.approvedLoanAmount) * 0.5;
          break;
        case ColendingRatio.Sixty_Fourty:
          amount = Number(formData.approvedLoanAmount) * 0.6;
          break;
        case ColendingRatio.Seventy_thirty:
          amount = Number(formData.approvedLoanAmount) * 0.7;
          break;
        case ColendingRatio.Eighty_twenty:
          amount = Number(formData.approvedLoanAmount) * 0.8;
          break;
      }
      return `${amount.toFixed(2)}`;
    }
    return '-';
  };

  const getSolfinAmount = () => {
    if (formData.approvedLoanAmount && formData.colenderRatio) {
      var amount = 0;
      switch (formData.colenderRatio) {
        case ColendingRatio.Fity_Fifty:
          amount = Number(formData.approvedLoanAmount) * 0.5;
          break;
        case ColendingRatio.Sixty_Fourty:
          amount = Number(formData.approvedLoanAmount) * 0.4;
          break;
        case ColendingRatio.Seventy_thirty:
          amount = Number(formData.approvedLoanAmount) * 0.3;
          break;
        case ColendingRatio.Eighty_twenty:
          amount = Number(formData.approvedLoanAmount) * 0.2;
          break;
      }
      return `${amount.toFixed(2)}`;
    }
    return '-';
  };

  async function onSubmitClick() {
    if (!customerApplication) return;
    onSubmit(
      customerApplication.id,
      customerApplication.basicInfo.amount,
      formData
    );
    handleClose();
  }

  function handleClose() {
    setFormData({});
    onClose();
  }

  const isParentROIValid = () => {
    if (formData.loanType == LoanType.COLENDED)
      return solfinRoi > 0 && solfinRoi <= 100;
    return true;
  };

  const getUserTypeDisplayText = () => {
    if (customerApplication?.basicInfo?.userType) {
      return _.startCase(_.toLower(customerApplication?.basicInfo?.userType));
    }
    return '-';
  };

  useEffect(() => {
    if (open == true && applicationId != null) {
      fetchColenders();
      fetchApplication(applicationId);
    }
  }, [open, applicationId]);

  useEffect(() => {
    const roi = calculateSolfinROI({
      parentLoanInterest: formData.rateOfInterest,
      colenderRoi: selectedColender?.interest,
      colenderRatio: formData.colenderRatio,
    });
    setSolfinRoi(roi);
  }, [formData.rateOfInterest, formData.colenderRatio, formData.colenderId]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      sx={{ borderRadius: '20px' }}
      onClose={handleClose}
      open={open}
    >
      <div
        style={{
          backgroundColor: 'rgba(250, 250, 250, 1)',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems={'center'}
          style={{
            paddingTop: '1.25rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <LosText text="Loan Detail Approval" />
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>

        {loading && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </div>
        )}
        {loading == false && (
          <Stack spacing={'1rem'}>
            <div
              style={{
                border: '1px solid #E1E4EB',
                padding: '1rem',
                margin: '1rem',
                borderRadius: '0.75rem',
                background: 'white',
              }}
            >
              <Grid container>
                <Grid item xs={3}>
                  <ReadOnlyFormField
                    title={'Application ID'}
                    description={applicationId}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ReadOnlyFormField
                    title={'Applicant Name'}
                    description={customerApplication?.customerName}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ReadOnlyFormField
                    title={'Loan Tenure'}
                    description={`${
                      customerApplication?.basicInfo?.tenure ?? '-'
                    } Months`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ReadOnlyFormField
                    title={'User Type'}
                    description={getUserTypeDisplayText()}
                  />
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                border: '1px solid #E1E4EB',
                padding: '1rem',
                margin: '1rem',
                borderRadius: '0.75rem',
                background: 'white',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
              }}
            >
              <LosText text="Loan Details" />
              <Grid container spacing={'1rem'}>
                <Grid item xs={6}>
                  <LosFormInputField label="Requested Loan Amount">
                    <LosTextField
                      value={customerApplication?.basicInfo?.amount}
                      disabled
                    />
                  </LosFormInputField>
                </Grid>
                <Grid item xs={6}>
                  <LosFormInputField
                    label={`Approved Loan Amount Max (${customerApplication?.basicInfo?.amount})`}
                  >
                    <LosTextField
                      value={formData.approvedLoanAmount}
                      onChange={(e) => {
                        handleUpdate(
                          InputType.ApprovedLoanAmount,
                          e.target.value
                        );
                      }}
                    />
                  </LosFormInputField>
                </Grid>
                <Grid item xs={6}>
                  <LosFormInputField label="Loan Type">
                    <LosSelect
                      options={loanTypeOptions()}
                      value={formData.loanType}
                      onChange={(e) => {
                        handleUpdate(
                          InputType.LoanType,
                          e.target.value as string
                        );
                      }}
                    />
                  </LosFormInputField>
                </Grid>
                <Grid item xs={6}>
                  <LosFormInputField label="Rate Of Interest">
                    <LosTextField
                      type={'number'}
                      value={formData.rateOfInterest}
                      onChange={(e) => {
                        handleUpdate(InputType.RateOfInterest, e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LosText text="%" variant="caption" />
                          </InputAdornment>
                        ),
                      }}
                      error={isParentROIValid() == false}
                    />
                  </LosFormInputField>
                </Grid>
                {formData.loanType == LoanType.COLENDED && (
                  <Grid item xs={6}>
                    <LosFormInputField label="Colender">
                      <LosSelect
                        options={colenderOptions(colenders)}
                        value={formData.colenderId}
                        onChange={(e) => {
                          handleColenderSelection(e.target.value as string);
                        }}
                      />
                    </LosFormInputField>
                  </Grid>
                )}

                {formData.loanType == LoanType.COLENDED && (
                  <Grid item xs={6}>
                    <LosFormInputField label="Colender to Solfin Ratio">
                      <LosSelect
                        options={ratioOptions()}
                        value={formData.colenderRatio}
                        onChange={(e) => {
                          handleUpdate(
                            InputType.ColenderRatio,
                            e.target.value as string
                          );
                        }}
                      />
                    </LosFormInputField>
                  </Grid>
                )}
              </Grid>
            </div>
            <div
              style={{
                border: '1px solid #E1E4EB',
                padding: '1rem',
                margin: '1rem',
                borderRadius: '0.75rem',
                background: 'white',
                display:
                  formData.loanType == LoanType.COLENDED ? 'flex' : 'none',
                flexDirection: 'column',
                gap: '1rem',
                transition: '2ms all ease-in',
              }}
            >
              <LosText text="Net Loan Details" />
              <Grid container>
                <Grid item xs={3}>
                  <ReadOnlyFormField
                    title={`${selectedColender?.name ?? ''} Amount`}
                    description={getColenderAmount()}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ReadOnlyFormField
                    title={`${selectedColender?.name ?? ''} Rate Of Interest`}
                    description={`${selectedColender?.interest ?? '-'}%`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ReadOnlyFormField
                    title={`${primaryColender?.name ?? 'Solfin'} Amount`}
                    description={getSolfinAmount()}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ReadOnlyFormField
                    title={`${
                      primaryColender?.name ?? 'Solfin'
                    } Rate Of Interest`}
                    description={`${solfinRoi ?? '-'}%`}
                  />
                </Grid>
              </Grid>
            </div>
          </Stack>
        )}
      </div>
      <Stack
        direction={'row'}
        justifyContent="end"
        alignItems={'center'}
        style={{ padding: '1rem' }}
        spacing="1rem"
      >
        {formData.loanType == LoanType.COLENDED &&
          isParentROIValid() == false && (
            <LosText
              text="Please enter valid interest rate for the loan"
              variant="caption"
              color={'red'}
            />
          )}
        <LosButton
          text="Send Offer"
          onClick={onSubmitClick}
          disabled={isInputValid() == false}
        />
      </Stack>
    </Dialog>
  );
}

export default LendingDetailsDialog;

enum LoanType {
  SELF = 'SELF',
  COLENDED = 'COLENDED',
}

enum ColendingRatio {
  Eighty_twenty = '80:20',
  Seventy_thirty = '70:30',
  Sixty_Fourty = '60:40',
  Fity_Fifty = '50:50',
}

const ratioOptions = () =>
  Object.values(ColendingRatio).map((item) => {
    const option: LosSelectOption = {
      value: item,
      label: item,
    };
    return option;
  });

const loanTypeOptions = () =>
  Object.values(LoanType).map((item) => {
    var displayText = '';
    if (item == LoanType.SELF) {
      displayText = 'ONUS-Solfin';
    } else if (item == LoanType.COLENDED) {
      displayText = 'COLEND';
    }
    const option: LosSelectOption = {
      value: item,
      label: displayText,
    };
    return option;
  });

const colenderOptions = (colenders: Colender[]) => {
  return colenders.map((item) => {
    const option: LosSelectOption = {
      value: item.id,
      label: _.startCase(_.toLower(item.name)),
    };
    return option;
  });
};

/**
 * x * 0.2 + 10 * 0.8 = 15
 *
 * x= solfin rate of interest
 * 0.2 is solfin loan ratio
 * 10 is colender rate of interest
 * 0.8 is colender loan ratio
 * 15 is user input for parent loan rate of interest
 *
 */

function calculateSolfinROI(data: {
  parentLoanInterest?: string;
  colenderRoi?: number;
  colenderRatio?: string;
}): number {
  if (!data.parentLoanInterest || !data.colenderRoi || !data.colenderRatio)
    return -1;
  const parentLoanInterest = parseFloat(data.parentLoanInterest);
  const colenderRoi = data.colenderRoi;
  var colenderRatio = parseFloat(data.colenderRatio);
  var solfinRatio = 100 - colenderRatio;

  colenderRatio /= 100;
  solfinRatio /= 100;

  const solfinRateOfInterest: number =
    (parentLoanInterest - colenderRoi * colenderRatio) / solfinRatio;

  return solfinRateOfInterest;
}
