import { Box } from '@mui/material';
import { DataGrid, GridCallbackDetails } from '@mui/x-data-grid';
import { BORDER, COMMON, GREEN, GREY } from '../../constants/style';

type Props = {
  data?: any;
  columnDef: any[];
  pageSize?: number;
  page?: number;
  idColumn: string;
  onPageChange?: (page: number, details: GridCallbackDetails) => void;
  onPageSizeChange?: (pageSize: number, details: GridCallbackDetails) => void;
  onCellClick?: (val: any) => void;
  onRowSelect?: (val: any) => void;
  paginationMode?: any;
  rowCount?: any;
  props?: any;
  border?: boolean;
  noTopRadius?: boolean;
  disablePagination?: boolean;
  disableSelectionOnClick?: boolean;
  checkboxSelection?: boolean;
  loading?: boolean;
  headerColor?: string;
  headerFontWeight?: number;
  noFooter?: boolean;
  isSelected?: any;
};

const VegaTable = ({
  data,
  columnDef,
  idColumn,
  pageSize,
  page,
  onPageChange,
  onPageSizeChange,
  onRowSelect,
  paginationMode,
  rowCount,
  onCellClick,
  border,
  noTopRadius,
  disablePagination = false,
  disableSelectionOnClick = false,
  checkboxSelection,
  loading = false,
  headerColor = GREY.light,
  headerFontWeight = 600,
  noFooter = false,
  isSelected,
}: Props) => {
  const normalisedColumDef = columnDef.map((column) => ({
    ...column,
    headerClassName: 'vegatable--header ',
    cellClassName: 'vegatable--cell',
  }));
  console.log('disablePagination', disablePagination);
  return (
    <>
      <Box
        className="font-aspekta-500"
        sx={{
          borderRadius: '20px',
          height: '100%',
          width: '100%',
          '& .vegatable--header': {
            border: 'none !important',
            borderWidth: '0px',
            bgcolor: headerColor,
            '& p': {
              // fontWeight: 600,
              // fontFamily: 'Aspekta-500',
              color: COMMON.gray,
            },
          },
          '& .vegatable--cell': {
            border: 'none !important',
            borderWidth: '0px',
            maxHeight: 'auto !important',
            color: GREY.grey_light,
            paddingLeft: '20px',
            fontWeight: 500,
            ':focus-within': {
              outline: 'none !important',
            },
            ':focus': {
              outline: 'none !important',
            },
          },

          '& .vegatable--cell:nth-child(1)': {
            paddingLeft: '45px !important',
          },
          '& .vegatable--cell:last-child': {
            paddingRight: '50px !important',
          },
          '& .vegatable--blue': {
            bgcolor: '#F6F9FF',
            '&:hover': {
              bgcolor: '#F6F9FF',
            },
          },
          '& .vegatable--white': {
            bgcolor: '#FFFFFF',
            '&:hover': {
              bgcolor: '#FFFFFF',
            },
          },

          '& .MuiDataGrid-footerContainer': {
            display: noFooter ? 'none' : 'inherit',
            border: 'none !important',
            bgcolor: GREY.light,
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
          },
          '& .vegatable--header > .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiTablePagination-toolbar': {
            '& .MuiTablePagination-select': {
              marginTop: '2px',
              paddingBottom: '0px',
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            overflowX: 'scroll',
            ...(border
              ? { border: BORDER, borderRadius: '20px' }
              : { border: 'none' }),
            '& .MuiDataGrid-columnHeaders': {
              border: 'none !important',
              ...(noTopRadius
                ? { borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }
                : {
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                  }),
              fontWeight: 700,
            },
            '& .MuiDataGrid-cell': {
              borderBottom: '0px',
            },
            '& .MuiTablePagination-toolbar': {
              '& .MuiTablePagination-select': {
                marginTop: '2px',
                paddingBottom: '0px',
              },
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:nth-child(1)': {
              ...(checkboxSelection
                ? { paddingLeft: '0px', bgcolor: GREY.light }
                : { paddingLeft: '45px' }),
            },
            '& .MuiDataGrid-columnHeader:last-child': {
              ...(checkboxSelection
                ? { paddingRight: '0px', bgcolor: GREY.light }
                : { paddingRight: '50px' }),
            },
            '& .MuiCheckbox-root': {
              color: GREY.dark,
              '&.Mui-checked': {
                color: GREEN.dark,
              },
            },
          }}
          page={page ?? undefined}
          pageSize={pageSize ?? 15}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onCellClick={onCellClick}
          onSelectionModelChange={onRowSelect}
          paginationMode={paginationMode ?? 'client'}
          pagination={!disablePagination ? undefined : true}
          rows={data}
          rowCount={rowCount}
          columns={normalisedColumDef}
          getRowId={(row: any) => {
            return row[idColumn];
          }}
          getRowClassName={(params: any) => {
            return `vegatable--row ${
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'vegatable--white'
                : 'vegatable--blue'
            }`;
          }}
          loading={loading}
          isRowSelectable={isSelected}
          disableSelectionOnClick={disableSelectionOnClick}
          checkboxSelection={checkboxSelection}
          disableColumnSelector
          disableColumnMenu
          hideFooterSelectedRowCount
          rowsPerPageOptions={paginationMode ? [5, 10, 15] : []}
          disableDensitySelector
        />
      </Box>
    </>
  );
};

export default VegaTable;
