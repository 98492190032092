import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useSearchParams,
  useLocation,
  createSearchParams,
} from 'react-router-dom';
import { NESTED_VIEW } from '../../../routes';

type NestedRouterContextType = {
  currentRoute: string;
  currentRouteMetaData: Record<string, any> | null;
  navigateToRoute: (route: string, metaData?: Record<string, any>) => void;
};

const NestedRouterContext = createContext<NestedRouterContextType | null>(null);

export const useNestedRouter = () =>
  useContext(NestedRouterContext) as NestedRouterContextType;

type Props = {
  children: ReactNode;
  routes: string[];
  initialRoute: string;
};
export const NestedRouterProvider = ({
  children,
  routes,
  initialRoute,
}: Props) => {
  const [currentRoute, setCurrentRoute] = useState<string>(initialRoute);
  const [currentRouteMetaData, setCurrentRouteMetaData] = useState<Record<
    string,
    any
  > | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();

  function _navigateToRoute(route: string, metaData?: Record<string, any>) {
    setCurrentRouteMetaData(metaData ?? null);
    setSearchParams(
      createSearchParams({
        [NESTED_VIEW]: route,
      })
    );
  }

  useEffect(() => {
    setCurrentRoute(initialRoute);
  }, [initialRoute]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const queryParams = Object.fromEntries(params.entries());
    const view = queryParams.nested_view;
    setCurrentRoute(view);
  }, [searchParams]);

  return (
    <NestedRouterContext.Provider
      value={{
        currentRoute: currentRoute,
        navigateToRoute: _navigateToRoute,
        currentRouteMetaData: currentRouteMetaData,
      }}
    >
      {children}
    </NestedRouterContext.Provider>
  );
};
