import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BORDER_GRAY, GREY } from '../../../constants/style';
import SolfinAppBar from '../../components/SolfinAppBar';
import ActiveLoansList from '../components/ActiveLoans/ActiveLoansList';
import SelectedLoanDetails from '../components/ActiveLoans/SelectedLoanDetails';

export interface IActiveLoansProps {
  name: string;
  id: string;
  index: number;
}

const ActiveLoans = () => {
  return (
    <>
      <SolfinAppBar title={'Active Loans'} />
      <Box m={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                border: BORDER_GRAY,
                bgcolor: 'white',
                borderRadius: '10px',
              }}
            >
              <ActiveLoansList />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Box
              sx={{
                border: BORDER_GRAY,
                p: 1,
                bgcolor: 'white',
                borderRadius: '10px',
              }}
            >
              <SelectedLoanDetails />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ActiveLoans;
