import { Grid, Box, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import LosFormInputField from '../../components/common/LosFormInputField'
import LosTextField from '../../components/common/LosTextField'
import LosAppBar from '../../components/LosAppBar'
import { LosFormButtonView } from '../../components/LosFormButton'
import LosFormContainer from '../../components/LosFormContainer'
import { useLos } from '../../providers/LosProvider'
import {
  CompanyDetailsResponse,
  GSTNData,
  PvtLtdCompanyFormData,
} from '../../types/Los'
import LosText from '../../components/common/LosText'
import { LosService } from '../../api/LosService'

enum InputType {
  PIN_NUMBER,
}

const PartnershipIdentificationNumberInput = () => {
  const {
    navigateToNextStep,
    currentStepMetaData,
    savePinData,
    customerApplication,
  } = useLos()
  const [formData, setFormData] = useState<PvtLtdCompanyFormData>({
    pinNumber: '',
    companyName: customerApplication?.companyDetails.companyName ?? '',
    gstNo: '',
  })
  const [parseGstData, setParseGstData] =
    useState<CompanyDetailsResponse | null>(null)
  const [errors, setErrors] = useState<Partial<PvtLtdCompanyFormData>>({})
  const [loading, setLoading] = useState<boolean>(false)

  function handleUpdate(type: InputType, value: string) {
    const updatedErrors: Partial<PvtLtdCompanyFormData> = { ...errors }
    switch (type) {
      case InputType.PIN_NUMBER:
        if (value.length <= 0) {
          updatedErrors.pinNumber = 'Pin number is required'
        } else {
          delete updatedErrors.pinNumber
        }
        setFormData(prev => {
          return {
            ...prev,
            pinNumber: value,
          }
        })
        break
    }
    setErrors(updatedErrors)
  }
  // const parseGstData: Partial<GSTNData> =
  //   customerApplication &&
  //   JSON.parse(customerApplication.companyDetails.gstData)

  const fetchGstDetails = async () => {
    if (customerApplication?.id) {
      const companyDetails = await LosService.getCompanyDetails(
        customerApplication.id
      )
      setParseGstData(companyDetails)
    }
  }

  const isInputValid = () => {
    let isNameValid = (formData.pinNumber ?? '').length > 0

    return isNameValid
  }

  async function onSubmit() {
    const newFormData = {
      pinNumber: formData.pinNumber,
      companyName: customerApplication?.companyDetails.companyName,
      gstNo: parseGstData?.gstNo,
    }
    try {
      setLoading(true)
      await savePinData(newFormData)
      navigateToNextStep()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchGstDetails()
  }, [customerApplication?.id])

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            text='Proceed to next step'
            onClick={onSubmit}
            disabled={isInputValid() == false}
            loading={loading}
          />
        )
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        )
      }}
    >
      <>
        <Box
          sx={{
            bgcolor: 'rgba(249, 249, 249, 1)',
            borderRadius: '6px',
            p: 1.5,
            mb: 3,
          }}
        >
          <Stack direction={'row'} alignItems={'center'} mb={2} gap={1}>
            <div
              style={{
                height: '32px',
                width: '32px',
              }}
            >
              <img
                src={require('../../assets/GST_logo.png')}
                height={'100%'}
                width={'100%'}
              ></img>
            </div>
            <div style={{}}>
              <LosText
                text='GST Details'
                variant='body1'
                fontSize={'0.8125rem'}
                fontWeight={'bold'}
                lineHeight={'130%'}
              />
            </div>
          </Stack>
          <Grid container rowSpacing={'1.5rem'}>
            <Grid item xs={12}>
              <LosFormInputField label='legal Name'>
                <LosText
                  text={parseGstData?.gstMeta?.legalNameOfBusiness ?? '-'}
                  variant='body1'
                  fontSize={'0.8125rem'}
                  fontWeight={'bold'}
                  lineHeight={'130%'}
                />
              </LosFormInputField>
            </Grid>
            <Grid item xs={6}>
              <LosFormInputField label='gst no'>
                <LosText
                  text={parseGstData?.gstNo ?? '-'}
                  variant='body1'
                  fontSize={'0.8125rem'}
                  fontWeight={'bold'}
                  lineHeight={'130%'}
                />
              </LosFormInputField>
            </Grid>
            <Grid item xs={6}>
              <LosFormInputField label='gst status'>
                <LosText
                  text={parseGstData?.gstMeta.gstinStatus ?? '-'}
                  variant='body1'
                  fontSize={'0.8125rem'}
                  fontWeight={'bold'}
                  lineHeight={'130%'}
                />
              </LosFormInputField>
            </Grid>
            <Grid item xs={6}>
              <LosFormInputField label='company type'>
                <LosText
                  text={parseGstData?.gstMeta?.constitutionOfBusiness ?? '-'}
                  variant='body1'
                  fontSize={'0.8125rem'}
                  fontWeight={'bold'}
                  lineHeight={'130%'}
                />
              </LosFormInputField>
            </Grid>
            <Grid item xs={6}>
              <LosFormInputField label='trade of bussiness'>
                <LosText
                  text={parseGstData?.gstMeta?.tradeNameOfBusiness ?? '-'}
                  variant='body1'
                  fontSize={'0.8125rem'}
                  fontWeight={'bold'}
                  lineHeight={'130%'}
                />
              </LosFormInputField>
            </Grid>
          </Grid>
        </Box>
        <Grid container rowSpacing={'1.5rem'}>
          <Grid item xs={12}>
            <LosFormInputField label='Partnership Identification number'>
              <LosTextField
                value={formData.pinNumber}
                onChange={e =>
                  handleUpdate(InputType.PIN_NUMBER, e.target.value)
                }
                error={!!errors.gstNo}
                helperText={errors.gstNo}
                isValid={isInputValid()}
              />
            </LosFormInputField>
          </Grid>
        </Grid>
      </>
    </LosFormContainer>
  )
}

export default PartnershipIdentificationNumberInput
