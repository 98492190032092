import { Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import VegaCheckBoxV2 from "../../../../../components/common/v2/VegaCheckBoxV2";
import LosFormInputField from "../../../components/common/LosFormInputField";
import LosText from "../../../components/common/LosText";
import LosTextField from "../../../components/common/LosTextField";
import LosAppBar from "../../../components/LosAppBar";
import { LosFormButtonView } from "../../../components/LosFormButton";
import LosFormContainer from "../../../components/LosFormContainer";
import VerifyPanDetailsDialog from "../../../components/VerifyPanDetailsDialog";
import { PanEntityType, StringUtility } from "../../../utils/StringUtility";
import {
  CompanyPanVerificationState,
  useCompanyPanVerification,
} from "../../../providers/CompanyPanProvider";
import { useLos } from "../../../providers/LosProvider";
import { LosEntityType, LosStep } from "../../../types/Los";
import _ from "lodash";
import { useLosAuth } from "../../../providers/LosAuthProvider";
import VerifyCompanyPanDetailsDialog from "../../../components/VerifyCompanyPanDetailsDialog";

type FormData = {
  name: string;
  panNumber: string;
};

enum InputType {
  NAME = "name",
  PAN_NUMBER = "panNumber",
}

function CompanyPanDetails() {
  const {
    saveCompanyPanData,
    navigateToNextStep,
    updatePanStatusToAccepted,
    customerApplication,
    fetchPanDetailsForPanInfoId,
    currentStep,
    currentStepMetaData,
    updateCompanyPanStatusToAccepect,
  } = useLos();
  const { isAuthenticated } = useLosAuth();
  const { updateState, updateCompanyPanDetails } = useCompanyPanVerification();
  const [formData, setFormData] = useState<FormData>({
    name: "",
    panNumber: "",
  });
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [tncAccepted, setTncAccepted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [openPanVerificationDialog, setOpenPanVerificationDialog] =
    useState<boolean>(false);

  function handleUpdate(type: InputType, value: string) {
    const updatedErrors: Partial<FormData> = { ...errors };
    switch (type) {
      case InputType.NAME:
        if (value.length <= 0) {
          updatedErrors.name = "Name is required";
        } else {
          delete updatedErrors.name;
        }
        setFormData((prev) => {
          return {
            ...prev,
            name: value,
          };
        });
        break;
      case InputType.PAN_NUMBER:
        if (value.length <= 0) {
          updatedErrors.panNumber = "Pan Number is required";
        } else {
          var panType = PanEntityType.Company;
          const type = customerApplication?.losEntityType;
          if (type == LosEntityType.SOLE_PROPRIETOR) {
            panType = PanEntityType.Company;
          } else if (type == LosEntityType.PARTNERSHIP) {
            panType = PanEntityType.Firm;
          }
          const isValidPan = StringUtility.validatePANNumber(panType, value);
          if (isValidPan == false) {
            updatedErrors.panNumber = `Valid ${_.startCase(
              _.toLower(customerApplication?.losEntityType)
            )} Pan Number is required`;
          } else {
            delete updatedErrors.panNumber;
          }
        }
        setFormData((prev) => {
          return {
            ...prev,
            panNumber: value.toUpperCase(),
          };
        });
        break;
    }

    setErrors(updatedErrors);
  }

  const isPanNumberInputValid = () => {
    if (!!errors.panNumber) return false;
    return formData?.panNumber?.length > 0;
  };

  const isInputValid = () => {
    let isPanValid = isPanNumberInputValid();
    let isNameValid = (errors.name ?? "").length <= 0;
    var isTncAccepted = tncAccepted == true;
    return isPanValid && isNameValid && isTncAccepted;
  };

  async function onVerifyPanClick() {
    try {
      setLoading(true);
      await saveCompanyPanData({
        name: formData.name,
        panNumber: formData.panNumber,
      });
      navigateToNextStep();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function onConfirmPanClick() {
    try {
      setLoading(true);
      updateCompanyPanDetails({
        name: formData.name,
        panNumber: formData.panNumber,
      });
      const response = await updateCompanyPanStatusToAccepect();
      navigateToNextStep();
      // updateState(CompanyPanVerificationState.AddPromoter);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function onTncClick() {}

  function runAfterDelay(callback: () => void, delay: number) {
    setTimeout(callback, delay);
  }

  async function handlePanAcceptedState() {
    const companyPanId = customerApplication?.companyDetails.panId;
    console.log({ companyPanId });

    if (companyPanId) {
      const response = await fetchPanDetailsForPanInfoId(companyPanId);
      setFormData({ name: response.panName, panNumber: response.panNumber });
      setOpenPanVerificationDialog(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (currentStep == LosStep.COMPANY_PAN_ACCEPTED && isAuthenticated) {
      setLoading(true);
      runAfterDelay(handlePanAcceptedState, 500);
    }
  }, [currentStep, customerApplication, isAuthenticated]);

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <SubmitPanDetailsButtonView
            onClick={onVerifyPanClick}
            onTncChange={setTncAccepted}
            isInputValid={isInputValid()}
            isTncChecked={tncAccepted}
            onTncClick={onTncClick}
            loading={loading}
          />
        );
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        );
      }}
    >
      <Grid container rowSpacing={"1.5rem"}>
        <Grid item xs={12}>
          <LosFormInputField label="Company Name">
            <LosTextField
              value={formData.name}
              onChange={(e) => handleUpdate(InputType.NAME, e.target.value)}
              error={!!errors.name}
              helperText={errors.name}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label="Company Pan Card">
            <LosTextField
              value={formData.panNumber}
              onChange={(e) =>
                handleUpdate(InputType.PAN_NUMBER, e.target.value.toUpperCase())
              }
              error={!!errors.panNumber}
              helperText={errors.panNumber}
              isValid={isPanNumberInputValid()}
            />
          </LosFormInputField>
        </Grid>
      </Grid>
      <VerifyCompanyPanDetailsDialog
        open={openPanVerificationDialog}
        onClose={function (): void {
          setOpenPanVerificationDialog(false);
        }}
        onSubmit={function (): void {
          onConfirmPanClick();
        }}
        onTncClick={onTncClick}
        panNumber={formData.panNumber}
      />
    </LosFormContainer>
  );
}

export default CompanyPanDetails;

const SubmitPanDetailsButtonView = (data: {
  onClick: () => void;
  onTncChange: (checked: boolean) => void;
  isInputValid: boolean;
  isTncChecked: boolean;
  onTncClick: () => void;
  loading?: boolean;
}) => {
  return (
    <LosFormButtonView
      onClick={data.onClick}
      disabled={data.isInputValid == false}
      text="Verify PAN"
      loading={data.loading}
      renderHelperView={() => {
        return (
          <Stack>
            <Stack direction={"row"} spacing="1" alignItems={"start"}>
              <VegaCheckBoxV2
                sx={{
                  "&.MuiButtonBase-root": {
                    paddingTop: "0px !important",
                  },
                  color: "blue",
                }}
                checked={data.isTncChecked}
                onChange={(e, checked) => data.onTncChange(checked)}
              />
              <LosText style={{ fontSize: "0.75rem", lineHeight: "130%" }}>
                I give{" "}
                <span style={{ fontWeight: "bold" }}>
                  Dhanvikas Fiscal Services Private Limited
                </span>{" "}
                the consent to save and verify my KYC details and agree to the{" "}
                <span
                  onClick={() => {
                    window.open(
                      "https://solfin.co.in/Termsandconditions",
                      "__blank"
                    );
                  }}
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Terms and Conditions
                </span>{" "}
                and{" "}
                <span
                  onClick={() => {
                    window.open(
                      "https://solfin.co.in/Privacypolicy",
                      "__blank"
                    );
                  }}
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Privacy Policy
                </span>{" "}
              </LosText>
            </Stack>
          </Stack>
        );
      }}
    />
  );
};
