import React from 'react';
import { Grid } from '@mui/material';
import LosDetailsWrapper from '../../../../LosDetailsWrapper';
import { GREEN, RED } from '../../../../../../constants/style';
import { toLowerCase } from '../../../../../../constants/commonFunction';
import { useRuleEngine } from '../../../../../LMSAgent/Provider/RuleEngingProvider';

interface IBasicCriteriaProps {
  name: string;
  value: string;
  color: string;
}

const VetoCriteria = () => {
  const { underWrittingScrore } = useRuleEngine();

  const getColorValue = (isVeto: boolean) => {
    return {
      value: !isVeto ? 'NO' : 'YES',
      color: !isVeto ? GREEN.dark : RED.red,
    };
  };
  const basicCriteria: IBasicCriteriaProps[] = [
    {
      name: 'Veto Management',
      value: getColorValue(
        underWrittingScrore?.underwritingManagementScore.comment === 'VETO'
      ).value,
      color: getColorValue(
        underWrittingScrore?.underwritingManagementScore.comment === 'VETO'
      ).color,
    },
    {
      name: 'Veto Compliance',
      value: getColorValue(
        underWrittingScrore?.underwritingComplianceScore.veto === true
      ).value,
      color: getColorValue(
        underWrittingScrore?.underwritingComplianceScore.veto === true
      ).color,
    },
  ];
  return (
    <div>
      <Grid container spacing={2}>
        {basicCriteria.map((criteria: IBasicCriteriaProps, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={3} lg={2.4} xl={2.4}>
            {' '}
            <LosDetailsWrapper
              name={criteria.name}
              value={toLowerCase(criteria.value)}
              isInfo
              valueColor={criteria.color}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default VetoCriteria;
