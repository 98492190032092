import { useLos } from '../../providers/LosProvider';
import { LosUserType } from '../../types/Los';
import PageNotFound from '../PageNotFound';
import PartnershipFirmCompanyDetails from './PartnershipFirmCompanyDetails';
import PvtLtdCompanyDetails from './PvtLtdCompanyDetails';
import SelfEmployedCompanyDetails from './SelfEmployedCompanyDetails';
import SoleProprietorCompanyDetails from './SoleProprietorCompanyDetails';

function CompanyDetails() {
  const { currentUserType } = useLos();
  if (currentUserType == LosUserType.PARTNERSHIP_FIRM) {
    return <PartnershipFirmCompanyDetails />;
  } else if (currentUserType == LosUserType.PVT_LIMITED) {
    return <PvtLtdCompanyDetails />;
  } else if (currentUserType == LosUserType.SOLE_PROPRIETOR) {
    return <SoleProprietorCompanyDetails />;
  } else if (currentUserType == LosUserType.SELF_EMPLOYED) {
    return <SelfEmployedCompanyDetails />;
  }
  return <PageNotFound />;
}

export default CompanyDetails;
