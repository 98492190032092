import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '..';
import {
  IApplicationsForSolfinProps,
  ILoanEngagementProps,
} from '../../solfen/types/application';

export const DEFULT_COLENDING = {
  loanId: '',
  status: '',
  officerId: 'c4567af7-a230-46ca-8ea1-9f2f37e92466',
  requestedLoanAmount: 100000.0,
  colenderId: '',
  applicationId: '',
  lendingType: 'SELF',
  colendingRatio: '',
  approvedLoanAmount: 0,
  roi: 0,
} as ILoanEngagementProps;

interface IProps {
  coLendingDetails: ILoanEngagementProps[];
  openOffer: boolean;
}

const initialState: IProps = {
  coLendingDetails: [],
  openOffer: false,
};

const offersSlice = createSlice({
  name: 'coLending',
  initialState,
  reducers: {
    setCoLendingDetails: (state: IProps, action) => {
      const { payload } = action;
      state.coLendingDetails = payload;
    },
    setOpenOffer: (state: IProps, action) => {
      const { payload } = action;
      state.openOffer = payload;
    },
  },
});

export const { setCoLendingDetails, setOpenOffer } = offersSlice.actions;
export const getCoLendingDetails = (state: AppState) => state.coLending;
export default offersSlice;
