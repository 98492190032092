import { Grid, Stack } from '@mui/material'
import { useState } from 'react'
import SelfieCamera from '../../../components/SelfieCamera'
import LosText from '../../../components/common/LosText'
import LosAppBar from '../../../components/LosAppBar'
import { LosFormButtonView } from '../../../components/LosFormButton'
import LosFormContainer from '../../../components/LosFormContainer'
import {
  BackgroundImageIcon,
  EyeGlasses,
  SelfiePlaceholder,
  UserProfileIcon,
} from '../../../icons/LosIcons'
import { useSelfie, SelfieViewState } from '../SelfieProvider'
import { SelfiePrecaution } from './SelfiePrecaution'
import { useLos } from '../../../providers/LosProvider'

enum SelfieCaptureViewState {
  Initial,
  Capturing,
}
function SelfieCaptureView() {
  const { currentStepMetaData } = useLos()
  const { onViewStateChange, onSelfieCaptureChange } = useSelfie()
  const [viewState, setViewState] = useState<SelfieCaptureViewState>(
    SelfieCaptureViewState.Initial
  )

  function updateViewState(viewState: SelfieCaptureViewState) {
    setViewState(viewState)
  }

  function onSelfieCaptureClick() {
    updateViewState(SelfieCaptureViewState.Capturing)
  }

  function onSelfieCaptured(selfie: string) {
    onSelfieCaptureChange(selfie)
    onViewStateChange(SelfieViewState.Uploading)
  }

  if (viewState == SelfieCaptureViewState.Capturing) {
    return (
      <SelfieCamera
        isActive={viewState == SelfieCaptureViewState.Capturing}
        onPictureClicked={onSelfieCaptured}
        desiredResolution={{
          width: 1280,
          height: 720,
        }}
      ></SelfieCamera>
    )
  }

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            onClick={onSelfieCaptureClick}
            text='Take Selfie'
          />
        )
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        )
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <Stack>
            <LosText text='Submit your live Selfie to complete the KYC ' />
            <LosText
              text='Please take a clear photo to help us confirm your identity'
              variant='caption'
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={'row'}
            alignItems='center'
            justifyContent={'center'}
          >
            <SelfiePlaceholder />
          </Stack>
        </Grid>
        <Grid item xs={12} alignItems='center' justifyContent={'stretch'}>
          <Grid container columns={3}>
            <Grid item xs={1}>
              <SelfiePrecaution text={'No \nEye Glass'} icon={<EyeGlasses />} />
            </Grid>
            <Grid item xs={1}>
              <SelfiePrecaution
                text={'Avoid \nblurred photo'}
                icon={<UserProfileIcon />}
              />
            </Grid>
            <Grid item xs={1}>
              <SelfiePrecaution
                text={'No Object \nin Background'}
                icon={<BackgroundImageIcon />}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LosFormContainer>
  )
}

export default SelfieCaptureView
