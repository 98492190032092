import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { ReadOnlyFormField } from '../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField';
import LosText from '../../onboarding/components/common/LosText';

function PaymentDetails() {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Typography className="font-aspekta-500" fontSize={14}>
          Bank Details
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Name" description="State Bank of India" />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Branch" description="JP Nagar" />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="ISFC Code" description="SB3340030" />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField
              title="Account Number"
              description="934131345333"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Account Type" description="Saving" />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="Client Name" description="Bajaj" />
          </Grid>
        </Grid>
      </div>
      <Divider
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '1rem',
        }}
      >
        <Typography className="font-aspekta-500" fontSize={14}>
          Repayment Details
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField
              title="ECS Max Limitt"
              description="₹ 1,50,000"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField
              title="ecs start date"
              description="01/03/2024"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="ecs end date" description="01/03/2026" />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <ReadOnlyFormField title="ECS Amount" description="₹ 1,50,000" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default PaymentDetails;
