import axios from 'axios';
import { BASE_URL } from '../../../api/endpoints';
import { VegaCustomer } from '../types/Los';

const CustomerEndpoints = {
  BASE: 'onboarding/v1/customer',
  GET: '/{customerId}',
  CREATE: '/customer',
  DEDUPE_CHECK: '/dedupe',
};

const CustomerInStepsEndpoint = {
  CREATE_CUSTOMER: '/create/id',
};

const CUSTOMER_BASE_URL = BASE_URL + CustomerEndpoints.BASE;
export class CustomerService {
  static async getCustomer(customerId: string): Promise<VegaCustomer> {
    try {
      const endpoint =
        CUSTOMER_BASE_URL +
        CustomerEndpoints.GET.replace('{customerId}', customerId);
      const response = await axios.get(endpoint);
      const responseData: VegaCustomer = response.data as VegaCustomer;
      return responseData;
    } catch (error) {
      throw error;
    }
  }
  static async createCustomerInSteps(
    data: CreateCustomerInStepsRequestData
  ): Promise<CreateCustomerInStepsResponseData> {
    try {
      const endpoint =
        CUSTOMER_BASE_URL + CustomerInStepsEndpoint.CREATE_CUSTOMER;
      const response = await axios.post(endpoint, data);
      const responseData: CreateCustomerInStepsResponseData = {
        customerId: response.data,
      };
      
      return responseData;
    } catch (error) {
      throw error;
    }
  }

  static async vectorCheckDedupe(
    data: Partial<VectorCheckDedupeApiData>
  ): Promise<VectorCheckDedupeResponseData> {
    try {
      const endpoint = CUSTOMER_BASE_URL + CustomerEndpoints.DEDUPE_CHECK;
      const response = await axios.post(endpoint, data);
      const dedupeResponse: VectorCheckDedupeResponseData = {
        exists: response.data.exists,
        existingCustomer: response.data.existingCustomer,
      };
      return dedupeResponse;
    } catch (error) {
      throw error;
    }
  }
}

export type VectorCheckDedupeResponseData = {
  exists: boolean;
  existingCustomer?: VegaCustomer;
};

export type CreateCustomerApiData = {
  clientId: string;
};

export type VectorCheckDedupeApiData = {
  clientId: string | null;
  phone: string | null;
  email: string | null;
  idNumber: string | null;
  idType: string | null;
};

export type CreateCustomerInStepsRequestData = {
  mobileNumber: string;
  clientId: string;
  customerType: string;
};

export type CreateCustomerInStepsResponseData = {
  customerId: string;
};
