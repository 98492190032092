import { Box } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import LosDataGrid from '../../components/LosDataGrid';
import LosStatusPill from '../../components/LosStatusPill';
import SolfinAppBar from '../../components/SolfinAppBar';
import { LOS_PROGRAM_ID } from '../../onboarding/api/constants';
import LosButton from '../../onboarding/components/common/LosButton';
import { Lead } from '../../onboarding/types/Los';
import { LeadService } from '../api/LeadService';
import MapAgentDialog from '../components/Leads/Components/MapAgentDialog';
import { Agent } from '../types/Lms';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type MapAgentDialogProps = {
  show: boolean;
  lead: Lead | null;
};

const Leads = () => {
  const { setSnackbar } = useSnackbar();
  const [leads, setLeads] = useState<Lead[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);

  const [showMapAgentDialog, setShowMapAgentDialog] =
    useState<MapAgentDialogProps>({
      show: false,
      lead: null,
    });

  const COLUMN_DEF: GridColumns<Lead> = [
    {
      field: 'id',
      headerName: 'Lead Id',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Lead;
        return row.id;
      },
      flex: 1.5,
    },
    {
      field: 'fullName',
      headerName: 'Customer Name',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Lead;
        var displayText = '-';
        if (row.fullName) {
          displayText = row.fullName;
        }
        return displayText;
      },
      flex: 1.5,
    },
    {
      field: 'phone',
      headerName: 'Mobile Number',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as Lead;
        var displayText = '-';
        if (application.phone) {
          displayText = application.phone;
        }
        return displayText;
      },
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      renderCell: (props: GridRenderCellParams) => {
        const application = props.row as Lead;
        var displayText = '-';
        if (application.createdAt) {
          displayText = dayjs(application.createdAt).format('DD/MM/YYYY');
        }
        return displayText;
      },
      flex: 0.75,
    },
    {
      field: 'agent Name',
      headerName: 'Agent Name',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Lead;
        if (row.agentId) {
          return row.agentName;
        }
        return (
          <LosButton
            text="Map Agent"
            size="small"
            onClick={() => {
              setShowMapAgentDialog({
                show: true,
                lead: row,
              });
            }}
          />
        );
      },
      flex: 1,
    },
    {
      field: 'preEligibility',
      headerName: 'Pre Eligiblity',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Lead;
        return '-';
      },
      flex: 0.5,
    },

    {
      field: 'status',
      headerName: 'Status',
      renderCell: (props: GridRenderCellParams) => {
        const row = props.row as Lead;
        return <LosStatusPill status={row.status} />;
      },
      flex: 0.5,
    },
  ];

  async function mapAgentToLead(lead: Lead, agent: Agent) {
    try {
      const response = await LeadService.mapAgentToLead({
        leadId: lead.id,
        agentId: agent.id,
      });

      const updatedLeads = leads.map((item) => {
        if (item.id == lead.id) return response;
        return item;
      });

      setLeads(updatedLeads);
    } catch (error) {
      console.error(error);
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  async function getAllLeads() {
    try {
      setLoading(true);
      const response = await LeadService.getAllLeads({
        programId: LOS_PROGRAM_ID,
        page: page,
        pageSize: pageSize,
        sortBy: 'createdAt',
        sortDir: 'desc',
      });
      setLeads(response.records);
      setPage(response.pageNumber);
      setRowCount(response.totalItems);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllLeads();
  }, [page, pageSize]);

  return (
    <>
      <SolfinAppBar title={'All Leads'} />
      <Box m={2}>
        <LosDataGrid
          data={leads}
          columns={COLUMN_DEF}
          idColumn={'id'}
          page={page}
          pageSize={pageSize}
          onPageChange={setPage}
          loading={loading}
          rowCount={rowCount}
        />
      </Box>
      <MapAgentDialog
        open={showMapAgentDialog.show}
        onClose={function (): void {
          setShowMapAgentDialog({
            show: false,
            lead: null,
          });
        }}
        onSubmit={function (agent: Agent): void {
          const lead = showMapAgentDialog.lead;
          if (!lead) return;
          mapAgentToLead(lead, agent);
        }}
      />
    </>
  );
};

export default Leads;
