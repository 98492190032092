import { Grid } from '@mui/material'
import _ from 'lodash'
import { GREEN, RED } from '../../../../../constants/style'
import { ReadOnlyFormField } from '../../../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField'
import LosText from '../../../components/common/LosText'
import { StringUtility } from '../../../utils/StringUtility'
import { PromoterKycDetails } from '../types'
import {
  DocumentStatus,
  UserAadharStatus,
  UserDataDto,
} from '../../../types/Los'
import PanDetails from '../../PanDetails/PanDetails'
import AadharDetails from '../../AadharDetails/AadharDetails'

type Props = {
  kycDetails?: Partial<UserDataDto>
  index: number
  viewerType: 'Customer' | 'HQ'
}
function PromoterKycDetailsPreview({ kycDetails, index, viewerType }: Props) {
  const userAadharDetails = kycDetails?.userAadharData?.[0].aadharMetaData
  const userPanDetails = kycDetails?.userPanData?.[0].panMetadata
  const userSelfieDeatils = kycDetails?.userSelfieData?.[0].selfieMetaData
  const userBasicInfo = kycDetails?.userAadharData?.[0].userBasicInfo

  const designationDisplayText = () => {
    if (userBasicInfo?.designation) {
      return _.startCase(_.toLower(userBasicInfo.designation))
    }
    return '-'
  }

  const getSelfieStatus = () => {
    if (isVerified()) {
      return 'Verified'
    }
    return 'Not Verified'
  }

  const isVerified = () => {
    const status = userSelfieDeatils?.status
    return status === DocumentStatus.APPROVED
  }

  const isCustomerViewingComponent = () => viewerType === 'Customer'

  return (
    <Grid container rowSpacing={'1rem'}>
      {/*  Promoter  */}
      <Grid item xs={12}>
        <Grid container rowSpacing={'1rem'}>
          <Grid item xs={12}>
            <LosText text={`Promoter ${index + 1} Details`} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField title='Name' description={userBasicInfo?.name} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField
              title='Designation'
              description={designationDisplayText()}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Pan Details */}
      <Grid item xs={12}>
        <Grid container rowSpacing={'1rem'}>
          <Grid item xs={12}>
            <LosText text='Pan Details' />
          </Grid>
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField
              title='Name'
              description={userPanDetails?.name ?? '-'}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField
              title='Pan Number'
              description={userPanDetails?.number ?? '-'}
            />
          </Grid>

          {isCustomerViewingComponent() == false && (
            <Grid item xs={6} sm={3}>
              <ReadOnlyFormField
                title='Status'
                description={userPanDetails?.panStatus ?? '--'}
                // descriptionColor={
                //   kycDetails?.panDetails?.status == 'VALID'
                //     ? GREEN.dark
                //     : RED.red
                // }
              />
            </Grid>
          )}
          {/* {isCustomerViewingComponent() == false && (
            <Grid item xs={6} sm={3}>
              <ReadOnlyFormField
                title='Bureau Acceptance'
                description={kycDetails?.panDetails?.bureauAcceptance}
              />
            </Grid>
          )} */}
        </Grid>
      </Grid>

      {/* Aadhaar details */}
      <Grid item xs={12}>
        <Grid container rowSpacing={'1rem'}>
          <Grid item xs={12}>
            <LosText text='Aadhaar Details' />
          </Grid>
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField
              title='Name'
              description={userAadharDetails?.name ?? '-'}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField
              title='Aadhaar Number'
              description={userAadharDetails?.uid ?? '-'}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField
              title='Date of Birth/Year'
              description={userAadharDetails?.dob ?? '-'}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField
              title='Status'
              description={userAadharDetails?.status}
              descriptionColor={
                userAadharDetails?.status == UserAadharStatus.APPROVED
                  ? GREEN.dark
                  : RED.red
              }
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField
              title='Gender'
              description={userAadharDetails?.gender ?? '-'}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField
              title='Current Address'
              description={StringUtility.formatAadhaarAddressToReadable(
                userAadharDetails?.splitAddress
              )}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <ReadOnlyFormField
              title='Permanent Address'
              description={StringUtility.formatAadhaarAddressToReadable(
                userAadharDetails?.splitAddress
              )}
            />
          </Grid>
          {/* {isCustomerViewingComponent() == false && (
            <Grid item xs={6} sm={3}>
              <ReadOnlyFormField
                title='Aadhaar Acceptance'
                description={userAadharDetails.}
              />
            </Grid>
          )} */}
        </Grid>
      </Grid>

      {/* Selfie */}
      <Grid item xs={12}>
        <Grid container rowSpacing={'1rem'}>
          <Grid item xs={12}>
            <LosText text='Selfie Details' />
          </Grid>
          <Grid item xs={4}>
            <div
              style={{
                height: '5rem',
                width: '5rem',
                borderRadius: '0.75rem',
              }}
            >
              <img
                src={userSelfieDeatils?.fileName}
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: '0.75rem',
                }}
              ></img>
            </div>
          </Grid>
          {isCustomerViewingComponent() == false && (
            <Grid item xs={4}>
              <ReadOnlyFormField
                title='Face Match'
                description={userSelfieDeatils?.score ?? '-'}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <ReadOnlyFormField
              title='Status'
              description={getSelfieStatus()}
              descriptionColor={
                getSelfieStatus() === 'Verified' ? GREEN.dark : RED.red
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PromoterKycDetailsPreview
//TODO: to add burue
