import { Skeleton } from '@mui/material';
import { ReactNode } from 'react';
import LosText from '../common/LosText';

export const InfoCard = (data: { children: ReactNode }) => {
  return (
    <div
      style={{
        borderRadius: '0.375rem',
        boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.08)',
        padding: '0.75rem',
        backgroundColor: 'white',
      }}
    >
      {data.children}
    </div>
  );
};

export const InfoTitle = (data: { text: string }) => {
  return (
    <LosText
      text={data.text}
      style={{
        color: '#676B76',
        fontSize: '0.625rem',
        fontWeight: 700,
        lineHeight: '124%',
        letterSpacing: '0.04375rem',
        textTransform: 'uppercase',
      }}
    />
  );
};

export const InfoDescription = (data: {
  text?: string | number;
  loading?: boolean;
}) => {
  const displayText = () => {
    if (data.text) {
      return `${data.text}`;
    }
    return '-';
  };
  if (data.loading == true) {
    return <Skeleton />;
  }
  return (
    <LosText
      text={displayText()}
      style={{
        color: '#1B1D22',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '140%',
      }}
    />
  );
};
