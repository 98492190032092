import { Dialog, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  backgroundColor?: string;
};

function LosDialog({
  open,
  onClose,
  children,
  backgroundColor = 'white',
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: '1rem',
          backgroundColor: backgroundColor,
          ...(isMobile && {
            margin: 0,
          }),
        },
      }}
      sx={{
        ...(isMobile == false && {
          '& .MuiBackdrop-root': {
            width: '50%',
            left: '50%',
          },
          '& .MuiDialog-container': {
            width: '50%',
            left: '50%',
            transform: 'translateX(100%)',
          },
        }),
        ...(isMobile && {
          '& .MuiDialog-container': {
            alignItems: 'end',
            justifyContent: 'center',
          },
        }),
      }}
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default LosDialog;
