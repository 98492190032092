import { Grid, Stack } from '@mui/material';
import { useState } from 'react';
import LosFileSelect from '../../components/common/LosFileSelect';
import LosFormInputField from '../../components/common/LosFormInputField';
import LosAppBar from '../../components/LosAppBar';
import { LosFormButtonView } from '../../components/LosFormButton';
import LosFormContainer from '../../components/LosFormContainer';

import { useLos } from '../../providers/LosProvider';
import { PartnershipFirmFinancialFormData } from '../../types/Los';

enum InputType {
  BANK_STATEMENT,
  ITR_FIRST_YEAR,
  ITR_SECOND_YEAR,
}

function PartnershipFirmFinancialDetails() {
  const { navigateToNextStep, saveFiancialData, currentStepMetaData } =
    useLos();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<PartnershipFirmFinancialFormData>({
    auditedFinancials: {},
    bankStatement: null,
    itr: {},
  });
  const [errors, setErrors] = useState<
    Partial<PartnershipFirmFinancialFormData>
  >({});

  function handleFileUpdate(type: InputType, value: File) {
    const updatedErrors: Partial<PartnershipFirmFinancialFormData> = {};

    switch (type) {
      case InputType.BANK_STATEMENT:
        setFormData((prev) => {
          return {
            ...prev,
            bankStatement: value,
          };
        });
        break;
      case InputType.ITR_FIRST_YEAR:
        setFormData((prev) => {
          return {
            ...prev,
            itr: {
              ...prev.itr,
              firstYear: value,
            },
          };
        });
        break;
      case InputType.ITR_SECOND_YEAR:
        setFormData((prev) => {
          return {
            ...prev,
            itr: {
              ...prev.itr,
              secondYear: value,
            },
          };
        });
        break;
    }
    setErrors(updatedErrors);
  }

  const isInputValid = () => {
    const itr = formData.itr;
    let isItrValid = itr.firstYear != null && itr.secondYear != null;
    let isBankStatementValid = formData.bankStatement != null;

    return isItrValid && isBankStatementValid;
  };

  async function onSubmit() {
    try {
      setLoading(true);
      await saveFiancialData(formData);
      navigateToNextStep();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            text="Proceed to Next Step"
            disabled={isInputValid() == false}
            onClick={onSubmit}
            loading={loading}
          />
        );
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        );
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <LosFormInputField label="Recent Bank Statement (Last 6 months)">
            <LosFileSelect
              placeholder={'Upload Bank Statement'}
              onFileSelect={function (file: File): void {
                handleFileUpdate(InputType.BANK_STATEMENT, file);
              }}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label="Income Tax Return (Last 2 years)">
            <Stack spacing={'1rem'}>
              <LosFileSelect
                placeholder={'Upload PDF'}
                onFileSelect={function (file: File): void {
                  handleFileUpdate(InputType.ITR_FIRST_YEAR, file);
                }}
              />
              <LosFileSelect
                placeholder={'Upload PDF'}
                onFileSelect={function (file: File): void {
                  handleFileUpdate(InputType.ITR_SECOND_YEAR, file);
                }}
              />
            </Stack>
          </LosFormInputField>
        </Grid>
      </Grid>
    </LosFormContainer>
  );
}

export default PartnershipFirmFinancialDetails;
