import { Typography } from '@mui/material';
import VegaHeader from '../../components/common/VegaHeader';
import VegaSelect from '../../components/common/VegaSelect';
import { toLowerCase } from '../../constants/commonFunction';
import { COMMON } from '../../constants/style';

interface IProps {
  options: string[];
  title: string;
  value: string;
  name: string;
  type?: string;
  isEdit: boolean;
  isCurrency?: boolean;
  onChange: (selected: string) => void;
}

const LOSSelectWrapper = ({
  title,
  value,
  isEdit,
  isCurrency,
  name,
  onChange,
  type,
  options,
}: IProps) => {
  return (
    <>
      <VegaHeader text={title} />
      {isEdit ? (
        <VegaSelect
          // isFixedHeight
          options={options}
          selected={value ?? ''}
          onChange={(select) => onChange(select)}
          key={name}
          placeholder={`${toLowerCase(title)}`}
          isCurrency={isCurrency}
        />
      ) : (
        <Typography
          className="font-aspekta-600"
          color={COMMON.light}
          fontSize={12}
        >
          {toLowerCase(value)}
        </Typography>
      )}
    </>
  );
};

export default LOSSelectWrapper;
