import { LosService } from '../api/LosService';

export const useLosEnumApi = () => {
  async function getUserTypes(): Promise<string[]> {
    try {
      const response = await LosService.getUserTypeOptions();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function getUserSubTypes(userType: string): Promise<string[]> {
    try {
      const response = await LosService.getUserSubTypeOptions(userType);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function getRelationTypes(): Promise<string[]> {
    try {
      const response = await LosService.getRelationTypeOptions();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function getLoanTypes(): Promise<string[]> {
    try {
      const response = await LosService.getLoanTypeOptions();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function getCollateralTypes(userType: string): Promise<string[]> {
    try {
      const response = await LosService.getCollateralTypeOptions(userType);
      return response;
    } catch (error) {
      throw error;
    }
  }

  return {
    getUserSubTypes,
    getUserTypes,
    getRelationTypes,
    getLoanTypes,
    getCollateralTypes,
  };
};
