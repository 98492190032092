import { Skeleton } from '@mui/material';
import LosFormInputField from '../../../../../onboarding/components/common/LosFormInputField';
import LosText from '../../../../../onboarding/components/common/LosText';

type Props = {
  title: string;
  description?: string;
  loading?: boolean;
  descriptionColor?: string;
};
export const ReadOnlyFormField = ({
  title,
  description,
  loading = false,
  descriptionColor = '#1B1D22',
}: Props) => {
  const displayText = () => {
    if (description && description.length > 0) {
      return description;
    }
    return '-';
  };
  return (
    <LosFormInputField label={title}>
      {loading == true && <Skeleton />}
      {loading == false && (
        <LosText
          text={displayText()}
          fontSize={'0.75rem'}
          fontWeight={400}
          lineHeight={'130%'}
          color={descriptionColor}
        />
      )}
    </LosFormInputField>
  );
};
