import { InputAdornment, OutlinedInput } from '@mui/material';
import React from 'react';
import { InputProps } from './VegaTextField';

interface ExtraProps {
  Icon?: React.ReactNode;
}

export interface ITextProps extends InputProps, ExtraProps {
  type?: 'number' | 'text';
  readOnly?: boolean;
  min?: number | string;
  max?: number | string;
  height?: number | string;
  defaultValue?: number | string;
}

const VegaOutLineTextField = ({
  key,
  value,
  onChange,
  placeholder,
  Icon,
  type,
  readOnly,
  min,
  max,
  height,
  defaultValue,
  textFieldWidth,
  disabled
}: ITextProps) => {
  return (
    <>
      <OutlinedInput
        fullWidth
        id={key}
        type={type}
        name={key}
        readOnly={readOnly}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onWheel={(e: any) => e.target.blur()}
        placeholder={placeholder}
        disabled={disabled}
        inputProps={{
          min,
          max,
          step: 1,
        }}
        size="small"
        sx={{
          borderRadius: '7px',
          height: height ?? '50px',
          ...(textFieldWidth && { width: textFieldWidth }),
        }}
        startAdornment={
          Icon && <InputAdornment position="start">{Icon}</InputAdornment>
        }
      />
    </>
  );
};

export default VegaOutLineTextField;
