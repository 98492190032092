import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps,
} from '@mui/material';
import { ReactNode } from 'react';
import LosCard, { LosCardProps } from './LosCard';

interface LosAccordianProps extends Omit<AccordionProps, 'children'> {
  collapsedView: ReactNode;
  expandedView: ReactNode;
  expanded: boolean;
  collapsedViewActions?: ReactNode;
  expandedViewActions?: ReactNode;
  collapsedViewCardProps?: LosCardProps;
  expandedViewCardProps?: LosCardProps;
}

function LosAccordian({
  collapsedView,
  expandedView,
  expanded,
  collapsedViewActions,
  expandedViewActions,
  collapsedViewCardProps,
  expandedViewCardProps,
  ...rest
}: LosAccordianProps) {
  function getActions() {
    if (expanded) {
      return expandedViewActions;
    }
    return collapsedViewActions;
  }

  return (
    <Accordion
      {...rest}
      sx={{
        '&.MuiPaper-root': {
          boxShadow: 'none !important',
          border: 'none !important',
          backgroundColor: 'transparent',
        },
        '& .MuiAccordionSummary-root': {
          borderRadius: '10px',
          '& .Mui-expanded': {
            margin: 0,
            padding: 0,
          },
        },
        // '.css-163jq61-MuiPaper-root-MuiAccordion-root:before': {
        //   display: 'none',
        //   height: '0px',
        // },
        '&:before': {
          display: 'none',
        },
        '& .Mui-expanded': {
          borderBottomLeftRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
        },
      }}
      expanded={expanded}
    >
      <AccordionSummary
        sx={{
          width: '100%',
          padding: 0,
          border: 'none !important',

          '& .MuiAccordionSummary-contentGutters': {
            padding: '0rem',
            margin: '0rem',
          },
        }}
      >
        <LosCard
          {...collapsedViewCardProps}
          flexDirection="row"
          justifyContent={'space-between'}
          alignItems={'center'}
          width="100%"
          noBottomBorderRadius={expanded}
        >
          {collapsedView}

          {getActions()}
        </LosCard>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          width: '100%',
          border: 'none',
          margin: 0,
          padding: 0,
        }}
      >
        <LosCard
          {...collapsedViewCardProps}
          flexDirection="row"
          justifyContent={'space-between'}
          alignItems={'center'}
          width="100%"
          noTopBorderRadius={expanded}
        >
          {expandedView}
        </LosCard>
      </AccordionDetails>
    </Accordion>
  );
}

export default LosAccordian;
