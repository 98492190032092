import { Edit, EditOutlined, Save } from '@mui/icons-material'
import { Box, Grid } from '@mui/material'
import { useState, useEffect } from 'react'
import VegaHeader from '../../../../../../components/common/VegaHeader'
import VegaOutlineButton from '../../../../../../components/common/VegaOutlineButton'
import { BORDER_GRAY, PURPLE } from '../../../../../../constants/style'
import {
  getApplicationsDetails,
  setApplicationsDetails,
} from '../../../../../../store/common/agentApplicationsSlice'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hook'
import {
  addFinancialDetailsForAgent,
  updateDocumentForAgent,
} from '../../../../../APIs/LMS_Agent/applications'
import {
  documentTypeEnum,
  financialDocumentTypeArrForPartner,
} from '../../../../../enums/applications'
import DocumentsShow from './components/DocumentsShow'
import { getErrorMessageFromErrorObj } from '../../../../../../utils/api'
import { useSnackbar } from '../../../../../../providers/SnackbarProvider'
import {
  IApplicationsForSolfinProps,
  IFinacialDocumentProps,
} from '../../../../../types/application'
import VegaTextButton from '../../../../../../components/common/VegaTextButton'
import { IFilesProps } from '../ElectricityDetails/ElectricityDetails'
import Media from '../ElectricityDetails/Media'
import { LosService } from '../../../../../onboarding/api/LosService'

interface IProps {
  getAllApplicationById: () => void
}

const DEFAULT_DATA = [
  {
    documentType: documentTypeEnum.AUDITED_FINANCIAL_REPORT_LAST_YEAR,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.AUDITED_FINANCIAL_REPORT_SECOND_LAST_YEAR,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },

  {
    documentType: documentTypeEnum.BANK_STATEMENT,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.INCOME_TAX_RETURN_LAST_YEAR,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
  {
    documentType: documentTypeEnum.INCOME_TAX_RETURN_SECOND_LAST_YEAR,
    file: null,
    documentName: '',
    id: '',
    documentCategory: '',
  },
]

const FinacialDetailsForPartnership = ({ getAllApplicationById }: IProps) => {
  const { applicationDetails, isApplicationForHQ } = useAppSelector(
    getApplicationsDetails
  )
  const dispatch = useAppDispatch()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isClear, setIsClear] = useState<boolean>(false)
  const { setSnackbar } = useSnackbar()
  const [filesData, setFilesData] = useState<IFilesProps[]>([])
  const companyName = applicationDetails?.companyDetails?.companyName

  const addDocs = async (name: string, formData: FormData) => {
    try {
      const response = await addFinancialDetailsForAgent(
        applicationDetails.id,
        name,
        formData
      )
      dispatch(setApplicationsDetails(response.data))
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    }
  }

  const updateDocs = async (name: string, file: File) => {
    try {
      const response = await LosService.saveFinancialData({
        applicationId: applicationDetails.id,
        file: file,
        documentType: name,
      })
      dispatch(setApplicationsDetails(response))
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    }
  }
  const handleImage = (name: string, value: any, index: number) => {
    const fileObj = { ...filesData[index] }
    const newObj = {
      ...fileObj,
      documentType: name,
      file: value,
      documentName: value.name,
      id: fileObj.id === '' ? new Date().getTime().toString() : fileObj.id,
    } as IFilesProps
    const copy = [...filesData]
    copy.splice(index, 1, newObj)
    setFilesData(copy)
  }
  const isFiles = filesData.some((file: IFilesProps) => file.id === '')

  const uploadFiles = async () => {
    console.log('uploadFiles', filesData)
    for (let index = 0; index < filesData.length; index++) {
      const element = filesData[index]
      const formData = new FormData()
      formData.append('file', element.file)

      if (applicationDetails.financialDetails) {
        element.file && (await updateDocs(element.documentType, element.file))
      }

      // if (applicationDetails?.financialDetails !== null) {
      //   const exists = applicationDetails?.financialDetails?.documents?.some(
      //     (document) => document.documentType === element.documentType
      //   );
      //   if (exists) {
      //     element.file &&
      //     (await updateDocs(element.documentType, element.file));
      //   } else {
      //     formData.append('employerDetail', companyName ?? '');
      //     element.file && (await addDocs(element.documentType, formData));
      //   }
      // } else {
      //   formData.append('employerDetail', companyName ?? '');
      //   element.file && (await addDocs(element.documentType, formData));
      // }
    }
    setSnackbar('Successfully uploaded financial documents ')
  }
  const onUploadFiles = async () => {
    await uploadFiles()
    // onUpdateApplication();
  }

  const getFileData = async (documents: IFinacialDocumentProps[]) => {
    const fileArr: IFilesProps[] = []
    let newFileArr: string[] = [
      documentTypeEnum.BANK_STATEMENT,
      documentTypeEnum.INCOME_TAX_RETURN_LAST_YEAR,
      documentTypeEnum.INCOME_TAX_RETURN_SECOND_LAST_YEAR,
      documentTypeEnum.AUDITED_FINANCIAL_REPORT_LAST_YEAR,
      documentTypeEnum.AUDITED_FINANCIAL_REPORT_SECOND_LAST_YEAR,
    ]
    await documents.map(
      async (document: IFinacialDocumentProps, index: number) => {
        const newObj = {
          ...document,
          file: null,
        } as IFilesProps
        fileArr.push(newObj)
        newFileArr = [
          ...newFileArr.filter(
            (newFile: string) => newFile !== document.documentType
          ),
        ]
      }
    )
    newFileArr.forEach(eleme => {
      fileArr.push({
        documentType: eleme,
        file: null,
        documentName: '',
        id: '',
        documentCategory: '',
      })
    })

    setFilesData([...fileArr] as IFilesProps[])
  }

  useEffect(() => {
    if (applicationDetails?.financialDetails !== null) {
      if (applicationDetails?.financialDetails?.documents?.length) {
        const documents: IFinacialDocumentProps[] =
          applicationDetails.financialDetails.documents

        getFileData(documents)
      }
    } else {
      setFilesData([...DEFAULT_DATA])
    }
  }, [isClear])

  const getITRComponent = (file: IFilesProps, index: number) => {
    if (
      file.documentType === documentTypeEnum.INCOME_TAX_RETURN_LAST_YEAR ||
      file.documentType === documentTypeEnum.INCOME_TAX_RETURN_SECOND_LAST_YEAR
    ) {
      return (
        <>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Media
              file={file}
              handleImage={handleImage}
              isEdit={isEdit}
              index={index}
            />
          </Grid>
        </>
      )
    }
  }
  const getAuditComponent = (file: IFilesProps, index: number) => {
    if (
      file.documentType ===
        documentTypeEnum.AUDITED_FINANCIAL_REPORT_LAST_YEAR ||
      file.documentType ===
        documentTypeEnum.AUDITED_FINANCIAL_REPORT_SECOND_LAST_YEAR
    ) {
      return (
        <>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Media
              file={file}
              handleImage={handleImage}
              isEdit={isEdit}
              index={index}
            />
          </Grid>
        </>
      )
    }
  }

  const isITRVisisble = () => {
    const itrFiles = filesData
      .sort((a, b) => {
        const indexA = financialDocumentTypeArrForPartner.indexOf(
          a.documentType
        )
        const indexB = financialDocumentTypeArrForPartner.indexOf(
          b.documentType
        )
        return indexA - indexB
      })
      .map((file: IFilesProps, index: number) => getITRComponent(file, index))
    if (itrFiles.length > 0) {
      return true
    } else {
      return false
    }
  }

  const isAuditVisible = () => {
    const auditFiles = filesData
      .sort((a, b) => {
        const indexA = financialDocumentTypeArrForPartner.indexOf(
          a.documentType
        )
        const indexB = financialDocumentTypeArrForPartner.indexOf(
          b.documentType
        )
        return indexA - indexB
      })
      .map((file: IFilesProps, index: number) => getITRComponent(file, index))

    if (auditFiles.length > 0) {
      return true
    } else {
      return false
    }
  }
  return (
    <>
      <Box py={2}>
        <VegaHeader text='Bank statement (last 6 months) ' />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Media
              file={
                filesData.filter(
                  fileData => fileData.documentType === 'BANK_STATEMENT'
                )?.[0]
              }
              handleImage={handleImage}
              isEdit={isEdit}
              index={2}
            />
          </Grid>
        </Grid>
      </Box>
      {isITRVisisble() && (
        <Box py={2}>
          {/* <VegaHeader text="iTR (last 2 YEARS)" /> */}
          <VegaHeader text='Income Tax Return (Last 2 years)' />
          <Grid container spacing={2}>
            {filesData
              .sort((a, b) => {
                const indexA = financialDocumentTypeArrForPartner.indexOf(
                  a.documentType
                )
                const indexB = financialDocumentTypeArrForPartner.indexOf(
                  b.documentType
                )
                return indexA - indexB
              })
              .map((file: IFilesProps, index: number) =>
                getITRComponent(file, index)
              )}
          </Grid>
        </Box>
      )}
      {isAuditVisible() && (
        <Box py={2}>
          {/* <VegaHeader text="iTR (last 2 YEARS)" /> */}
          <VegaHeader text='Audited Financial Report (Last 2 years)' />
          <Grid container spacing={2}>
            {filesData
              .sort((a, b) => {
                const indexA = financialDocumentTypeArrForPartner.indexOf(
                  a.documentType
                )
                const indexB = financialDocumentTypeArrForPartner.indexOf(
                  b.documentType
                )
                return indexA - indexB
              })
              .map((file: IFilesProps, index: number) =>
                getAuditComponent(file, index)
              )}
          </Grid>
        </Box>
      )}
      <Box textAlign={'end'} sx={{ mt: 2 }}>
        {isEdit ? (
          <>
            <VegaOutlineButton
              text={'Save'}
              isPurple
              disabled={isFiles}
              onClick={async () => {
                // await onUpdateApplication();
                await onUploadFiles()
                setIsEdit(!isEdit)
              }}
            />
            <VegaTextButton
              text={'Cancel'}
              color={PURPLE.dark}
              // onClick={() => setIsEdit(!isEdit)}
              onClick={() => {
                setIsClear(!isClear)
                setIsEdit(!isEdit)
                getAllApplicationById()
              }}
            />
          </>
        ) : (
          !isApplicationForHQ &&
          (applicationDetails.status === 'IN_PROGRESS' ||
            applicationDetails.status === 'PENDING') && (
            <VegaOutlineButton
              text={'Edit'}
              startIcon={<EditOutlined />}
              isPurple
              onClick={() => setIsEdit(!isEdit)}
            />
          )
        )}
      </Box>
    </>
  )
}

export default FinacialDetailsForPartnership
