import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { useState } from 'react'
import LosFormInputField from '../../components/common/LosFormInputField'
import LosTextField from '../../components/common/LosTextField'
import LosAppBar from '../../components/LosAppBar'
import { LosFormButtonView } from '../../components/LosFormButton'
import LosFormContainer from '../../components/LosFormContainer'
import { useLos } from '../../providers/LosProvider'
import {
  CustomerApplication,
  LosStep,
  PvtLtdCompanyFormData,
} from '../../types/Los'
import { StringUtility } from '../../utils/StringUtility'
import VerifyGSTDetailsDialog from '../../components/VerifyGSTDetailsDialog'
import { useLosAuth } from '../../providers/LosAuthProvider'
import VerificationSuccessDialog from '../../components/VerificationSuccessDialog'
import { LocalStorageUtility, StorageKeys } from '../../utils/StorageUtility'
import { LosService } from '../../api/LosService'

enum InputType {
  COMPANY_NAME,
  GST_NUMBER,
}

function PvtLtdCompanyDetails() {
  const {
    currentStep,
    navigateToNextStep,
    currentStepMetaData,
    fetchPanDetailsForPanInfoId,
    customerApplication,
    saveCompanyData,
    updateGstStatusToAccepted,
  } = useLos()
  const { isAuthenticated } = useLosAuth()
  const [formData, setFormData] = useState<PvtLtdCompanyFormData>({
    companyName: '',
    gstNo: '',
  })
  const [errors, setErrors] = useState<Partial<PvtLtdCompanyFormData>>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [openVerifyGSTDialog, setOpenVerifyGSTDialog] = useState<boolean>(false)
  const [openGstVerifiedDialog, setOpenGstVerifiedDialog] =
    useState<boolean>(false)

  function onGstVerificationSuccessClick() {
    navigateToNextStep()
  }

  function handleUpdate(type: InputType, value: string) {
    const updatedErrors: Partial<PvtLtdCompanyFormData> = { ...errors }
    switch (type) {
      case InputType.COMPANY_NAME:
        if (value.length <= 0) {
          updatedErrors.companyName = 'Company Name is required'
        } else {
          delete updatedErrors.companyName
        }
        setFormData(prev => {
          return {
            ...prev,
            companyName: value,
          }
        })
        break
      case InputType.GST_NUMBER:
        if (value.length <= 0) {
          updatedErrors.gstNo = 'GST Number is required'
        } else {
          const isValid = StringUtility.validateGSTNumber(value)
          if (isValid == false) {
            updatedErrors.gstNo = 'Valid GST Number is required'
          } else {
            delete updatedErrors.gstNo
          }
        }
        setFormData(prev => {
          return {
            ...prev,
            gstNo: value,
          }
        })
        break
    }
    setErrors(updatedErrors)
  }

  const isGSTNumberInputValid = () => {
    if (!!errors.gstNo) return false
    if (!formData.gstNo) return false
    return formData?.gstNo.length > 0
  }

  const isInputValid = () => {
    let isPanValid = isGSTNumberInputValid()
    let isNameValid = (formData.companyName ?? '').length > 0
    return isPanValid && isNameValid
  }

  async function fetchCompanyDetails() {
    try {
      setLoading(true)
      const customerApplication =
        LocalStorageUtility.getItem<CustomerApplication>(
          StorageKeys.CUSTOMER_APPLICATION
        )
      const customerApplicationId = customerApplication?.id

      if (!customerApplicationId) return

      const response = await LosService.getUserDetails(customerApplicationId)
      setFormData(prev => {
        return {
          ...prev,
          companyName: response.companyDetailsResponse.companyName,
          gstNo: response.companyDetailsResponse.gstNo,
        }
      })
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  async function onSubmit() {
    try {
      setLoading(true)
      await saveCompanyData(formData)
      navigateToNextStep()
      setOpenVerifyGSTDialog(true)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchCompanyDetails()
  }, [customerApplication])

  async function handleGstAcceptedState() {
    // const companyPanId = customerApplication?.companyPanId
    const companyPanId = customerApplication?.companyDetails.panId
    if (companyPanId) {
      setOpenVerifyGSTDialog(true)
    }
    setLoading(false)
  }

  function runAfterDelay(callback: () => void, delay: number) {
    setTimeout(callback, delay)
  }

  async function onGstVerificationConfirm() {
    try {
      setLoading(true)
      await updateGstStatusToAccepted()
      setOpenGstVerifiedDialog(true)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (currentStep == LosStep.GST_ACCEPTED && isAuthenticated) {
      setLoading(true)
      runAfterDelay(handleGstAcceptedState, 500)
    }
  }, [currentStep, customerApplication, isAuthenticated])

  return (
    <LosFormContainer
      renderSubmitButton={() => {
        return (
          <LosFormButtonView
            text='Verify GST'
            onClick={onSubmit}
            disabled={isInputValid() == false}
            loading={loading}
          />
        )
      }}
      renderAppBar={() => {
        return (
          <LosAppBar
            totalSteps={currentStepMetaData.total}
            currentStep={currentStepMetaData.index + 1}
            currentStepLabel={currentStepMetaData.categoryName}
          />
        )
      }}
    >
      <Grid container rowSpacing={'1.5rem'}>
        <Grid item xs={12}>
          <LosFormInputField label='Company Name'>
            <LosTextField
              value={formData.companyName}
              disabled
              error={!!errors.companyName}
              helperText={errors.companyName}
            />
          </LosFormInputField>
        </Grid>
        <Grid item xs={12}>
          <LosFormInputField label='GST NO.'>
            <LosTextField
              value={formData.gstNo}
              onChange={e => handleUpdate(InputType.GST_NUMBER, e.target.value)}
              error={!!errors.gstNo}
              helperText={errors.gstNo}
              isValid={isGSTNumberInputValid()}
            />
          </LosFormInputField>
        </Grid>
      </Grid>
      <VerifyGSTDetailsDialog
        gstNumber={''}
        open={openVerifyGSTDialog}
        onClose={() => setOpenVerifyGSTDialog(false)}
        onSubmit={onGstVerificationConfirm}
      />
      <VerificationSuccessDialog
        open={openGstVerifiedDialog}
        onClose={function (): void {
          setOpenGstVerifiedDialog(false)
        }}
        onSubmit={onGstVerificationSuccessClick}
        title={'Verify GST'}
        description={'Your Gst has been verified'}
        buttonText={'Proceed'}
      />
    </LosFormContainer>
  )
}

export default PvtLtdCompanyDetails
