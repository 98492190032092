import { Typography } from '@mui/material';
import VegaHeader from '../../../../../components/common/VegaHeader';
import VegaSelect from '../../../../../components/common/VegaSelect';
import { toLowerCase } from '../../../../../constants/commonFunction';
import { COMMON } from '../../../../../constants/style';

interface IProps {
  options: string[];
  title: string;
  value: string;
  name: string;
  type?: string;
  isEdit: boolean;
  onChange: (selected: string) => void;
}

const AgentSelect = ({
  title,
  value,
  isEdit,
  name,
  onChange,
  type,
  options,
}: IProps) => {
  const handleChange = (name: string, value: string) => {
    onChange(value);
  };

  return (
    <>
      <VegaHeader text={title} />
      {isEdit ? (
        <VegaSelect
          isFixedHeight
          options={options}
          selected={value ?? ''}
          onChange={(select) => handleChange(name, select)}
          key={name}
          placeholder={`${toLowerCase(title)}`}
        />
      ) : (
        <Typography
          className="font-aspekta-400"
          color={COMMON.darkGray}
          fontSize={12}
        >
          {toLowerCase(value)}
        </Typography>
      )}
    </>
  );
};

export default AgentSelect;
