import { Slider, SliderProps, styled } from '@mui/material';

interface LosSliderProps extends SliderProps {}

const StyledSlider = styled(Slider)(({ theme }) => {
  return {
    fontFamily: 'Aspekta !important',
    color: '#88F231',
    height: '0.5rem',
    '& .MuiSlider-thumb': {
      height: '1.5rem',
      width: '1.5rem',
      backgroundColor: '#88F231',
    },
  };
});

function LosSlider({ ...rest }: LosSliderProps) {
  return <StyledSlider {...rest} />;
}

export default LosSlider;
