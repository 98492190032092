import { Typography, Box, Grid } from '@mui/material'
import { toLowerCase } from '../../../../constants/commonFunction'
import { dateFormat, timeFormat } from '../../../../mcc/variable'
import LOSTextWrapper from '../../../components/LOSTextWrapper'
import {
  CompanyDetailsResponse,
  DocumentStatus,
  PanMetadata,
} from '../../../onboarding/types/Los'

interface IProps {
  header?: string
  subHeader?: string
  panId: string | null
  panInfo: Partial<CompanyDetailsResponse>
  isLoading: boolean
}

const PAN = {
  id: '',
  applicationId: '',
  customerId: '',
  name: '',
  number: '',
  typeOfHolder: '',
  status: null,
  firstName: '',
  middleName: '',
  lastName: '',
  title: '',
  panStatus: '',
  panStatusCode: '',
  aadhaarSeedingStatus: '',
  aadhaarSeedingStatusCode: '',
  lastUpdatedOn: '',
  createdAt: '',
  updatedAt: '',
  valid: true,
  individual: true,
}

const KYCCompanyDetailsContainer = ({
  header,
  panId,
  subHeader,
  panInfo,
  isLoading,
}: IProps) => {
  // const [loadingPan, setLoadingPan] = useState<boolean>(false)
  // const [panDetails, setPanDetails] = useState<IPanDetailsProps>({ ...PAN })
  // const getPanDetails = () => {
  //   if (panId) {
  //     fetchPANDetails(panId)
  //       .then(res => {
  //         setPanDetails(res.data)
  //         setLoadingPan(false)
  //       })
  //       .catch(error => {
  //         setLoadingPan(false)
  //       })
  //   } else {
  //     setLoadingPan(false)
  //   }
  // }
  // useEffect(() => {
  //   setLoadingPan(true)
  //   const aadharInfo = setTimeout(() => {
  //     getPanDetails()
  //   }, 500)
  //   return () => {
  //     clearTimeout(aadharInfo)
  //   }
  // }, [])
  const getSelfieStatus = () => {
    if (isVerified()) {
      return 'Verified'
    }
    return 'Not Verified'
  }

  const isVerified = () => {
    const status = panInfo?.valid
    return status === true
  }
  return (
    <>
      {header && (
        <Typography className='font-aspekta-500' fontSize={14}>
          {header}
        </Typography>
      )}
      <Box py={2}>
        {subHeader && (
          <Typography
            className='font-aspekta-700'
            sx={{
              textTransform: 'uppercase',
              fontSize: 10,
              letterSpacing: '0.07em',
              textDecoration: 'underline',
              mb: 1,
              color: 'black',
            }}
          >
            {subHeader}
          </Typography>
        )}
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <LOSTextWrapper
              header='name'
              loading={isLoading}
              text={panInfo?.companyName ?? '--'}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <LOSTextWrapper
              header='pan number'
              loading={isLoading}
              text={panInfo?.panNumber ?? '--'}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <LOSTextWrapper
              header='status'
              loading={isLoading}
              text={toLowerCase(getSelfieStatus())}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <LOSTextWrapper
              header='timestamp'
              loading={isLoading}
              text={`${dateFormat(panInfo?.updatedAt)}, ${timeFormat(
                panInfo?.updatedAt
              )}`}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default KYCCompanyDetailsContainer
