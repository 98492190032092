import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useSnackbar } from '../../../../providers/SnackbarProvider'
import { getErrorMessageFromErrorObj } from '../../../../utils/api'
import LoanLedgerDataGrid from '../../../components/LoanLedger/LoanLedgerDataGrid'
import {
  Loan,
  LoanDispersalSchedule,
  LoanEmiSchedule,
  LoanLedger,
} from '../../../LMSHQ/types/Lms'
import ActiveLoanTrancheDetails from '../../components/ActiveLoan/ActiveLoanTrancheDetails'
import {
  InfoCard,
  InfoDescription,
  InfoTitle,
} from '../../components/ActiveLoan/InfoCard'
import LoanStatusCard from '../../components/ActiveLoan/LoanStatusCard'
import LosText from '../../components/common/LosText'
import { useLosAuth } from '../../providers/LosAuthProvider'
import { useLos } from '../../providers/LosProvider'
import { LosStep } from '../../types/Los'
import { StringUtility } from '../../utils/StringUtility'
import SolfinBankDetails from './SolfinBankDetails'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
function ActiveLoan() {
  const { setSnackbar } = useSnackbar()
  const theme = useTheme()
  const isNotDesktop = useMediaQuery(theme.breakpoints.down('md'))
  const {
    customerApplication,
    fetchLoanForApplication,
    currentStep,
    fetchLoanEmiSchedule,
    fetchLoanDispersalSchedule,
    fetchLoanLedger,
  } = useLos()
  const { isAuthenticated } = useLosAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [loan, setLoan] = useState<Loan>()
  const [schedules, setSchedules] = useState<LoanEmiSchedule[]>([])
  const [firstSchedule, setFirstSchedule] = useState<LoanEmiSchedule>()
  const [tranches, setTranches] = useState<LoanDispersalSchedule[]>([])
  const [loanLedger, setLoanLedger] = useState<LoanLedger[]>([])
  const [lastPaidEmiLedger, setLastPaidEmiLedger] = useState<LoanLedger>()

  async function fetchLoanDetails(applicationId: string) {
    try {
      setLoading(true)
      const response = await fetchLoanForApplication(applicationId)
      setLoan(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  async function fetchDispersalSchedule() {
    try {
      const loanId = loan?.id
      if (!loanId) return
      setLoading(true)
      const response = await fetchLoanDispersalSchedule(loanId)
      setTranches(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  async function fetchLedger() {
    try {
      const loanId = loan?.id
      if (!loanId) return
      setLoading(true)
      const response = await fetchLoanLedger({
        loanId: loanId,
        filters: {},
      })
      const ledgers = response.records
      const lastPaidEmi = ledgers.find(
        item => item.loan_ledger_category == 'REPAYMENT'
      )
      setLastPaidEmiLedger(lastPaidEmi)
      setLoanLedger(ledgers)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  async function fetchEmiSchedule() {
    try {
      const loanId = loan?.id
      if (!loanId) return
      setLoading(true)
      const response = await fetchLoanEmiSchedule(loanId)
      const firstItem = response.find(item => {
        if (
          item.status == 'UNPAID' ||
          item.status == 'PARTIAL_PAID' ||
          item.status == 'MOVE_TO_LEDGER'
        ) {
          return true
        }
        return false
      })
      setFirstSchedule(firstItem)
      setSchedules(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  const getNextEmiDate = () => {
    if (firstSchedule?.due_date) {
      const emiDate = firstSchedule.due_date
      return dayjs(emiDate).format('DD/MM/YYYY')
    }
    return '-'
  }

  const getLastEmiDate = () => {
    if (lastPaidEmiLedger?.created_at) {
      const emiDate = lastPaidEmiLedger.created_at
      return dayjs(emiDate).format('DD/MM/YYYY')
    }
    return '-'
  }

  const getTrancheDisbursedAmount = () => {
    var amount = 0
    tranches.forEach(item => {
      if (item.dispersalDate != null) {
        amount += item.amount
      }
    })
    return `${StringUtility.formatCurrency(amount)}`
  }

  useEffect(() => {
    const applicationId = customerApplication?.id
    if (!applicationId) return
    if (currentStep == LosStep.DISPERSED && isAuthenticated == true) {
      fetchLoanDetails(applicationId)
    }
  }, [customerApplication, currentStep, isAuthenticated])

  useEffect(() => {
    fetchEmiSchedule()
    fetchDispersalSchedule()
    fetchLedger()
  }, [loan])

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          height: 'auto',
          width: '100%',
          padding: '1.5rem',
          background:
            'linear-gradient(135deg, rgba(231, 237, 252, 0.50) 0%, rgba(231, 237, 252, 0.25) 100%)',
        }}
      >
        <Title text='Loan Overview' />
        <SubTitle text='Get an idea about your loan details' />
        <Grid container columnSpacing={'1rem'} rowSpacing={'1rem'}>
          <Grid item xs={6} sm={6} md={2}>
            <ReadOnlyData
              title='Total Loan Amount'
              description={`₹ ${StringUtility.formatCurrency(
                loan?.loan_amount
              )}`}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <ReadOnlyData
              title='Disbursed Loan Amount'
              description={`₹ ${getTrancheDisbursedAmount()}`}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <ReadOnlyData
              title='Tenure'
              description={`${loan?.tenure} ${_.startCase(
                _.toLower(loan?.tenure_unit)
              )}`}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <ReadOnlyData
              title='Rate Of Interest'
              description={`${loan?.interest_rate ?? '-'}%`}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <ReadOnlyData
              title='EMI Amount'
              description={`₹ ${StringUtility.formatCurrency(
                firstSchedule?.installment_amount
              )}`}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <ReadOnlyData
              title='Emi Due Date'
              description={getNextEmiDate()}
              loading={loading}
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          width: '100%',
          flexDirection: isNotDesktop ? 'column' : 'row',
        }}
      >
        <div
          style={{
            backgroundColor: '#FFF',
            flex: 2,
            margin: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <LoanStatusCard
            loanAmountLeft={`₹ ${StringUtility.formatCurrency(
              loan?.due_principal
            )}`}
            loanTenureLeft={`${loan?.installment_count} Months`}
            nextEmiDate={getLastEmiDate()}
            loading={loading}
          />
          <div
            style={{
              flex: 1,
              paddingTop: '1rem',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <Title text='Repayment History' />
            <SubTitle text='Keep Track of your loan history' />
            <div
              style={{
                flex: 1,
                overflowX: 'hidden',
                overflowY: 'scroll',
                display: loading ? 'none' : 'block',
              }}
            >
              <LoanLedgerDataGrid loanId={loan?.id} />
            </div>

            {loading == true && <Skeleton height={'10rem'} />}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            margin: '1.5rem',
            gap: '2rem',
          }}
        >
          <ActiveLoanTrancheDetails
            totalLoanAmount={`₹ ${StringUtility.formatCurrency(
              loan?.loan_amount
            )}`}
            trancheDisbursedAmount={getTrancheDisbursedAmount()}
            tranches={tranches}
            loading={loading}
          />
          <SolfinBankDetails />
        </div>
      </div>
    </div>
  )
}

export default ActiveLoan

type ReadOnlyDataProps = {
  title: string
  description?: string
  loading?: boolean
}
const ReadOnlyData = ({
  title,
  description,
  loading = false,
}: ReadOnlyDataProps) => {
  return (
    <InfoCard>
      <InfoTitle text={title} />
      {loading == true && <Skeleton />}
      {loading == false && <InfoDescription text={description} />}
    </InfoCard>
  )
}

const Title = (data: { text: string }) => {
  return (
    <LosText
      text={data.text}
      style={{
        color: '#1B1D22',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '140%',
        marginBottom: '0.5rem',
      }}
    />
  )
}

const SubTitle = (data: { text: string }) => {
  return (
    <LosText
      text={data.text}
      style={{
        color: '#676B76',
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: '130%',
        marginBottom: '1rem',
      }}
    />
  )
}
