import { LosService } from '../api/LosService'
import {
  ApplicationDto,
  CustomerApplication,
  LosEntityType,
} from '../types/Los'
import { LocalStorageUtility, StorageKeys } from '../utils/StorageUtility'

export const useCustomerApplicationApi = () => {
  async function getCustomerApplicationAndCreateIfNotFound(data: {
    customerId: string
    programId: string
    leadId: string
  }): Promise<CustomerApplication> {
    try {
      console.log('Fetching customer application')
      const existingApplication = await getCustomerApplication({
        customerId: data.customerId,
      })

      if (existingApplication) {
        console.log('Application exists for customer')
        return existingApplication
      }
      console.log('Creating customer application')

      const newApplication = await createCustomerApplication(data)
      const customerApplication = await getApplicationById(
        newApplication.applicationId
      )
      if (customerApplication != undefined) {
        console.log('Application created')
        return customerApplication
      }
      throw new Error('Failed to get and create new application')
    } catch (error) {
      throw error
    }
  }

  async function getCustomerApplication(data: {
    customerId: string
  }): Promise<CustomerApplication> {
    try {
      const response = await LosService.getApplicationForCustomer(
        data.customerId
      )
      saveApplicationIdToStorage(response.id)
      return response
    } catch (error) {
      throw error
    }
  }
  async function createCustomerApplication(data: {
    customerId: string
    programId: string
    leadId: string
  }): Promise<ApplicationDto> {
    try {
      const res = await LosService.createApplication({
        programId: data.programId,
        customerId: data.customerId,
        leadId: data.leadId,
        losEntityType: LosEntityType.PARTNERSHIP, //@afraz for not creating user in backend we are hardcoding losEntity type
      })
      saveApplicationIdToStorage(res.applicationId)
      return res
    } catch (err) {
      throw err
    }
  }

  async function getApplicationById(
    applicationId: string
  ): Promise<CustomerApplication> {
    try {
      const response = await LosService.getApplicationById(applicationId)
      return response
    } catch (error) {
      throw error
    }
  }

  function saveApplicationIdToStorage(id: string) {
    if (!id || id.length <= 0) {
      console.error(
        'Application id is null or empty. Not saving in Localstorage'
      )
      return
    }
    LocalStorageUtility.setItem(StorageKeys.APPLICATION_ID, id)
  }

  return {
    createCustomerApplication: createCustomerApplication,
    getCustomerApplication: getCustomerApplication,
    getCustomerApplicationAndCreateIfNotFound:
      getCustomerApplicationAndCreateIfNotFound,
    getApplicationById: getApplicationById,
  }
}
