import { createContext, useContext, useEffect, useState } from 'react';
import { PaginatedResponse } from '../../../utils/api';
import { LOS_PROGRAM_ID } from '../../onboarding/api/constants';
import { CustomerService } from '../../onboarding/api/CustomerService';
import { LosService } from '../../onboarding/api/LosService';
import { CustomerApplication, VegaCustomer } from '../../onboarding/types/Los';
import { LeadService } from '../api/LeadService';
import {
  GetLoanLedgerApiData,
  LoanManagementService,
} from '../api/LoanManagementService';
import {
  Agent,
  CustomLoanDto,
  Loan,
  LoanApplicationDetails,
  LoanDetails,
  LoanDispersalSchedule,
  LoanEmiSchedule,
  LoanLedger,
  LoanStatus,
} from '../types/Lms';

type ActiveLoansContextType = {
  activeLoans: CustomLoanDto[];
  selectedLoan?: CustomLoanDto;
  loadingLoans: boolean;

  // methods
  onLoanSelect: (loan: CustomLoanDto) => void;

  // fetch
  fetchLoanDispersalSchedule: (
    loanId: string
  ) => Promise<LoanDispersalSchedule[]>;
  fetchLoanLedger: (data: {
    loanId: string;
    filters: Partial<GetLoanLedgerApiData>;
  }) => Promise<PaginatedResponse<LoanLedger>>;
  fetchLoanEmiSchedule: (loanId: string) => Promise<LoanEmiSchedule[]>;
  fetchApplicationDetails: () => Promise<Partial<LoanApplicationDetails>>;
  fetchLoanDetails: () => Promise<Partial<LoanDetails>>;
  disburseTrench: (data: {
    trench: LoanDispersalSchedule;
    date: string;
    utrNumber: string;
  }) => Promise<LoanDispersalSchedule>;

  loadNextPage: () => void;
  loadPreviousPage: () => void;
};

const ActiveLoanContext = createContext<ActiveLoansContextType | null>(null);

export const useActiveLoans = () =>
  useContext(ActiveLoanContext) as ActiveLoansContextType;

export const ActiveLoansProvider = ({ children }: any) => {
  const [activeLoans, setActiveLoans] = useState<CustomLoanDto[]>([]);
  const [selectedLoan, setSelectedLoan] = useState<CustomLoanDto>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingLoans, setLoadingLoans] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);

  function _updateSelectedLoan(loan: CustomLoanDto) {
    setSelectedLoan(loan);
  }

  async function _fetchApplication(
    applicationId: string
  ): Promise<CustomerApplication> {
    try {
      const response = await LosService.getApplicationById(applicationId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchCustomerDetails(
    customerId: string
  ): Promise<VegaCustomer> {
    try {
      const response = await CustomerService.getCustomer(customerId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchAgentDetails(agentId: string): Promise<Agent> {
    try {
      const response = await LeadService.getAgent(agentId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchApplicationDetails(): Promise<
    Partial<LoanApplicationDetails>
  > {
    try {
      const applicationId = selectedLoan?.applicationId;
      if (!applicationId) throw new Error('Application Id not Found');
      const application = await _fetchApplication(applicationId);
      const customer = await _fetchCustomerDetails(application.customerId);
      const data: Partial<LoanApplicationDetails> = {
        customer: customer,
        application: application,
      };
      const agentId = application.agentId;
      if (agentId) {
        const agent = await _fetchAgentDetails(application.agentId);
        data.agent = agent;
      }
      return data;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchLoanDetails(): Promise<Partial<LoanDetails>> {
    try {
      const loanId = selectedLoan?.id;
      const applicationId = selectedLoan?.applicationId;
      if (!applicationId) throw new Error('Application Id not Found');
      if (!loanId) {
        throw new Error('Loan id not found ' + selectedLoan);
      }
      const application = await _fetchApplication(applicationId);
      const customer = await _fetchCustomerDetails(application.customerId);
      const response = await _fetchLoanDispersal(loanId);
      const detailedLoan = await _fetchDetailedLoan(loanId);
      const data: Partial<LoanDetails> = {
        loan: detailedLoan,
        trenches: response,
        childLoans: detailedLoan.child_loans,
        customer: customer,
        application: application,
      };
      return data;
    } catch (error) {
      throw error;
    }
  }

  async function _fetchLoans() {
    try {
      setActiveLoans([]);
      setLoadingLoans(true);
      const response = await LoanManagementService.getAllLoans(LOS_PROGRAM_ID, {
        status: LoanStatus.UNPAID,
        page: page,
        pageSize: 10,
      });
      setActiveLoans(response.records);
      setPage(response.pageNumber);
      setRowCount(response.totalItems);
      if (response.records.length > 0) {
        setSelectedLoan(response.records[0]);
      }
    } catch (error) {
      throw error;
    } finally {
      setLoadingLoans(false);
    }
  }

  async function _fetchLoanDispersal(
    loanId: string
  ): Promise<LoanDispersalSchedule[]> {
    try {
      setLoading(true);
      const response = await LoanManagementService.getDispersalSchedule(loanId);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function _fetchDetailedLoan(loanId: string): Promise<Loan> {
    try {
      setLoading(true);
      const response = await LoanManagementService.getDetailedLoan(loanId);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function _fetchLoanLedger(data: {
    loanId: string;
    filters: Partial<GetLoanLedgerApiData>;
  }): Promise<PaginatedResponse<LoanLedger>> {
    try {
      setLoading(true);
      const response = await LoanManagementService.getLedger(data);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function _fetchLoanEmiSchedule(
    loanId: string
  ): Promise<LoanEmiSchedule[]> {
    try {
      setLoading(true);
      const response = await LoanManagementService.getEmiSchedule(loanId);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function _disburseTrench(data: {
    trench: LoanDispersalSchedule;
    date: string;
    utrNumber: string;
  }): Promise<LoanDispersalSchedule> {
    try {
      const response = await LoanManagementService.disburseLoanSchedule({
        scheduleId: data.trench.id,
        utrNumber: data.utrNumber,
        date: data.date,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  function _onLoadNextPage() {
    if (activeLoans.length < 10) return;
    setPage(page + 1);
  }

  function _onLoadPrevPage() {
    if (page <= 0) return;
    setPage(page - 1);
  }

  useEffect(() => {
    _fetchLoans();
  }, [page]);

  return (
    <ActiveLoanContext.Provider
      value={{
        activeLoans: activeLoans,
        selectedLoan: selectedLoan,
        loadingLoans: loadingLoans,

        // methods
        onLoanSelect: _updateSelectedLoan,

        // fetch api
        fetchLoanLedger: _fetchLoanLedger,
        fetchLoanEmiSchedule: _fetchLoanEmiSchedule,
        fetchLoanDispersalSchedule: _fetchLoanDispersal,
        fetchApplicationDetails: _fetchApplicationDetails,
        fetchLoanDetails: _fetchLoanDetails,
        disburseTrench: _disburseTrench,

        loadNextPage: _onLoadNextPage,
        loadPreviousPage: _onLoadPrevPage,
      }}
    >
      {children}
    </ActiveLoanContext.Provider>
  );
};
