import _ from 'lodash'
import VegaTextV2 from '../../../components/common/v2/VegaTextV2'
import { useLos } from '../providers/LosProvider'
import OnboardingStepListItem from './OnboardingStepListItem'
import { LosStep } from '../types/Los'
import { useMemo } from 'react'

function OnboardingStepOverview() {
  const { journeySteps, currentStepMetaData, currentStep } = useLos()
  // function getSelectedStep(index: number) {
  //   if (currentStep === LosStep.SUBMITTED) {
  //     return false
  //   }
  //   return currentStepMetaData.index == index
  // }
  const getSelectedStep = useMemo(() => {
    return (index: number) => {
      if (currentStep === LosStep.SUBMITTED) {
        return false
      }

      return currentStepMetaData.index === index
    }
  }, [currentStep, currentStepMetaData])

  return (
    <div
      className='bgImage'
      style={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div
        className='imageOverlay'
        style={{
          paddingTop: '6rem',
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '1rem',
          paddingLeft: '5.62rem',
          paddingRight: '1.5rem',
          alignItems: 'start',
        }}
      >
        <VegaTextV2
          text='Get your Loan from solfin in 6 quick Steps!'
          variant='h5'
          style={{
            fontSize: '1.0625rem',
            fontWeight: 600,
            lineHeight: '120%',
            width: '100%',
            textAlign: 'start',
            color: 'white',
          }}
        />
        <div
          style={{
            flex: '1',
            display: 'flex',
            overflow: 'scroll',
            rowGap: '1rem',
            flexDirection: 'column',
            marginTop: '3rem',
            width: '100%',
            height: '100%',
          }}
        >
          {journeySteps.map((item, index) => {
            return (
              <OnboardingStepListItem
                key={index}
                index={index}
                completed={
                  index < currentStepMetaData.index ||
                  currentStep === LosStep.SUBMITTED ||
                  currentStep === LosStep.SANCTION_LETTER ||
                  currentStep === LosStep.AGREEMENT ||
                  currentStep === LosStep.BANK_DETAILS ||
                  currentStep === LosStep.READY_FOR_DISPERSAL
                }
                selected={
                  getSelectedStep(index)
                  // currentStepMetaData.index == index
                  // // (currentStep === LosStep.SUBMITTED &&
                  // //   index === journeySteps.length - 1)
                }
                heading={_.startCase(_.toLower(item))}
                description={
                  currentStepMetaData.description ??
                  'Let us know about your loan requirement'
                }
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default OnboardingStepOverview
