import { Close, DeleteOutlineOutlined } from '@mui/icons-material'
import {
  Dialog,
  Stack,
  IconButton,
  Grid,
  Button,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useSnackbar } from '../../../../../providers/SnackbarProvider'
import { getErrorMessageFromErrorObj } from '../../../../../utils/api'
import LOSDateField from '../../../../components/LOSDateField'
import { LoanManagementService } from '../../../../LMSHQ/api/LoanManagementService'
import PdfPreview from '../../../../components/PdfPreview'
import { ReadOnlyFormField } from '../../../../LMSHQ/components/Co-Lenders/components/Forms/ReadOnlyFormField'
import { Loan, LoanDispersalSchedule } from '../../../../LMSHQ/types/Lms'
import { LosService } from '../../../../onboarding/api/LosService'
import LosButton from '../../../../onboarding/components/common/LosButton'
import LosFormInputField from '../../../../onboarding/components/common/LosFormInputField'
import LosText from '../../../../onboarding/components/common/LosText'
import LosTextField from '../../../../onboarding/components/common/LosTextField'
import { AddOutlinedIcon } from '../../../../onboarding/icons/LosIcons'
import {
  AddTrancheFormData,
  CustomerApplication,
  LosStep,
} from '../../../../onboarding/types/Los'
import { StringUtility } from '../../../../onboarding/utils/StringUtility'
import { dateFormat } from '../../../../../mcc/variable'

type Props = {
  open: boolean
  onClose: () => void
  applicationId: string | null
}

function AddLoanTranchesDialog({ open, onClose, applicationId }: Props) {
  const { setSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)
  const [customerApplication, setCustomerApplication] =
    useState<CustomerApplication>()
  const [loan, setLoan] = useState<Loan>()
  const [tranches, setTranches] = useState<Partial<AddTrancheFormData>[]>([])
  const [existingTranches, setExistingTranches] = useState<
    LoanDispersalSchedule[]
  >([])
  const [selectedInvoice, setSelectedInvoice] = useState<File | null>(null)

  async function fetchApplication(applicationId: string) {
    try {
      setLoading(true)
      const response = await LosService.getApplicationById(applicationId)
      setCustomerApplication(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  async function fetchLoan(applicationId: string) {
    try {
      setLoading(true)
      const response = await LoanManagementService.getLoanByApplicationId(
        applicationId
      )

      setLoan(response)
      fetchLoanTranches(response.id)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  async function fetchLoanTranches(loanId: string) {
    try {
      setLoading(true)
      const response = await LoanManagementService.getDispersalSchedule(loanId)
      setExistingTranches(response)
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  const userTypeDisplayText = () => {
    if (customerApplication?.basicInfo?.subType)
      return _.startCase(_.toLower(customerApplication?.basicInfo.subType))
    return '-'
  }

  function onTrancheAddClick() {
    const loanId = loan?.id
    if (!loanId) return
    setTranches(prev => [...prev, { loanId: loanId }])
  }

  function onTrancheDeleteClick(index: number) {
    var updatedDetails: Partial<AddTrancheFormData>[] = [...tranches]

    updatedDetails.splice(index, 1)

    setTranches(updatedDetails)
  }

  function updateTrancheDate(index: number, value: string) {
    const selectedTranche = tranches[index]
    const updatedTranche = { ...selectedTranche }
    updatedTranche.scheduleDate = value
    const updatedTranches = tranches.map((item, currentIndex) => {
      if (index == currentIndex) return updatedTranche
      return item
    })
    setTranches(updatedTranches)
  }

  function updateTrancheAmount(index: number, value: string) {
    const selectedTranche = tranches[index]
    const updatedTranche = { ...selectedTranche }
    updatedTranche.amount = value
    const updatedTranches = tranches.map((item, currentIndex) => {
      if (index == currentIndex) return updatedTranche
      return item
    })
    setTranches(updatedTranches)
  }

  const canAddNewTranche = () => {
    const hasExistingTranche = existingTranches.length > 0
    if (hasExistingTranche) {
      return false
    }
    const approvedLoanAmount = loan?.loan_amount
    console.log('====================================')
    console.log({ loan })
    console.log('====================================')
    var totalAmountEnteredInTranches = 0
    tranches.forEach(item => {
      totalAmountEnteredInTranches += Number(item.amount)
    })

    return totalAmountEnteredInTranches < Number(approvedLoanAmount)
  }

  const isInputValid = () => {
    return isTotalTranchAmountValid()
  }

  const isTotalTrancheAmountGreater = () => {
    const approvedLoanAmount = loan?.loan_amount
    var totalAmountEnteredInTranches = 0
    tranches.forEach(item => {
      totalAmountEnteredInTranches += Number(item.amount)
    })

    return totalAmountEnteredInTranches > Number(approvedLoanAmount)
  }

  const isTotalTranchAmountValid = () => {
    const approvedLoanAmount = loan?.loan_amount
    var totalAmountEnteredInTranches = 0
    tranches.forEach(item => {
      totalAmountEnteredInTranches += Number(item.amount)
    })

    return approvedLoanAmount == totalAmountEnteredInTranches
  }

  const lastTrancheDate = (currentIndex: number) => {
    const todayDate = new Date().toISOString()
    if (tranches.length == 0) return todayDate
    return tranches[currentIndex - 1]?.scheduleDate ?? todayDate
  }

  async function onSubmitClick() {
    try {
      setLoading(true)

      await LoanManagementService.createDisbursalSchedules({
        loanId: loan?.id,
        schedules: tranches,
      })

      await LosService.updateApplicationStatus({
        applicationId: customerApplication?.id,
        agentId: customerApplication?.agentId,
        status: LosStep.READY_FOR_DISPERSAL,
      })

      handleCloseDialog()
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error')
    } finally {
      setLoading(false)
    }
  }

  function handleCloseDialog() {
    setTranches([])
    setCustomerApplication(undefined)
    setLoan(undefined)
    setSelectedInvoice(null)
    onClose()
  }

  const getDisbursalPlanTitle = () => {
    if (existingTranches.length <= 0) {
      return 'Update Disbursal Plan'
    }
    return 'Disbursal Plan'
  }

  const getDialogPlanTitle = () => {
    if (existingTranches.length <= 0) {
      return 'Update Invoice Details'
    }
    return 'Invoice Details'
  }

  const getPerformaInvoiceSectionTitle = () => {
    if (existingTranches.length <= 0) {
      return 'Upload Performa Invoice'
    }
    return 'Performa Invoice'
  }

  const hasExistingTranches = () => existingTranches.length > 0

  useEffect(() => {
    if (open == true && applicationId != null) {
      fetchApplication(applicationId)
      fetchLoan(applicationId)
    }
  }, [open, applicationId])

  useEffect(() => {
    isTotalTranchAmountValid()
  }, [tranches])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      sx={{ borderRadius: '20px' }}
      onClose={handleCloseDialog}
      open={open}
    >
      <div
        style={{
          backgroundColor: 'rgba(250, 250, 250, 1)',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent='space-between'
          alignItems={'center'}
          style={{
            paddingTop: '1.25rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <LosText text={getDialogPlanTitle()} />
          <IconButton onClick={handleCloseDialog}>
            <Close />
          </IconButton>
        </Stack>
        <CardContent>
          <Grid item container xs={12} spacing={2} alignItems={'start'}>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Application Id'
                description={customerApplication?.id}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Name'
                description={customerApplication?.customerName}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='User Type'
                description={userTypeDisplayText()}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Loan Type'
                description={customerApplication?.basicInfo?.loanType ?? '-'}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
              <ReadOnlyFormField
                title='Tenure'
                description={`${
                  customerApplication?.basicInfo?.tenure ?? '-'
                } Months`}
                loading={loading}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <LosFormInputField label={getPerformaInvoiceSectionTitle()}>
                <PdfPreview
                  onDownload={function (): void {}}
                  onFileSelected={file => {
                    setSelectedInvoice(file)
                  }}
                />
              </LosFormInputField>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Stack spacing={'1rem'}>
            <LosText
              text={getDisbursalPlanTitle()}
              style={{
                fontWeight: 500,
                lineHeight: '124%',
              }}
            />

            <Grid container rowGap={'1rem'} columnGap={'1rem'}>
              <Grid item xs={12} sm={3}>
                <ReadOnlyFormField
                  title='Requested Loan Amount'
                  description={`${StringUtility.formatCurrency(
                    customerApplication?.basicInfo.amount
                  )}`}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <ReadOnlyFormField
                  title='Approved Loan Amount'
                  description={`${StringUtility.formatCurrency(
                    loan?.loan_amount
                  )}`}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <ReadOnlyFormField
                  title='Rate Of Interest'
                  description={`${loan?.interest_rate ?? '-'}%`}
                />
              </Grid>
            </Grid>

            {existingTranches?.map(
              (schedule: LoanDispersalSchedule, index: number) => (
                <div key={index}>
                  <LosFormInputField label={`Tranche ${index + 1}`}>
                    <Grid container rowGap={'1rem'} columnGap={'1rem'}>
                      <Grid item xs={12} sm={3}>
                        <ReadOnlyFormField
                          title='Date'
                          description={dateFormat(schedule.scheduleDate)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <ReadOnlyFormField
                          title='Amount'
                          description={`${StringUtility.formatCurrency(
                            schedule.amount
                          )}`}
                        />
                      </Grid>
                    </Grid>
                  </LosFormInputField>
                </div>
              )
            )}

            {tranches.map((item, index) => {
              return (
                <TrancheInputView
                  index={index}
                  dateValue={item.scheduleDate}
                  amountValue={item.amount}
                  onDateChange={function (date: string): void {
                    updateTrancheDate(index, date)
                  }}
                  onAmountChange={function (amount: string): void {
                    updateTrancheAmount(index, amount)
                  }}
                  onDelete={function (): void {
                    onTrancheDeleteClick(index)
                  }}
                  minDate={lastTrancheDate(index)}
                />
              )
            })}
            <Stack
              direction={'row'}
              alignItems='start'
              display={canAddNewTranche() ? 'flex' : 'none'}
              style={{
                transition: '5ms all ease-in-out',
              }}
            >
              <Button
                variant='text'
                startIcon={<AddOutlinedIcon />}
                sx={{
                  color: 'rgba(16, 71, 220, 1)',
                  paddingLeft: '4px',
                  textTransform: 'none',
                }}
                onClick={onTrancheAddClick}
              >
                Add new tranche
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </div>
      <Stack
        direction={'row'}
        justifyContent='end'
        alignItems={'center'}
        style={{ padding: '1rem' }}
        spacing='1rem'
      >
        <LosText
          text='Tranche Amount cannot be greater than Approved Loan amount'
          variant='caption'
          color={'red'}
          display={isTotalTrancheAmountGreater() ? '' : 'none'}
        />
        {hasExistingTranches() == false && (
          <LosButton
            text='Confirm'
            onClick={onSubmitClick}
            disabled={isInputValid() == false}
            loading={loading}
          />
        )}
        {hasExistingTranches() == true && (
          <LosButton
            text='Close'
            variant='outlined'
            onClick={handleCloseDialog}
            loading={loading}
          />
        )}
      </Stack>
    </Dialog>
  )
}

export default AddLoanTranchesDialog

const CardContent = ({ children }: any) => {
  return (
    <div
      style={{
        border: '1px solid #E1E4EB',
        padding: '1rem',
        margin: '1rem',
        borderRadius: '0.75rem',
        background: 'white',
      }}
    >
      {children}
    </div>
  )
}

const TrancheInputView = (data: {
  index: number
  onDateChange: (date: string) => void
  dateValue?: string
  onAmountChange: (amount: string) => void
  amountValue?: string
  onDelete: () => void
  minDate?: string
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <LosFormInputField
      key={data.index}
      label={`Tranche ${data.index + 1}`}
      endAdornment={
        <InputAdornment position='end'>
          {isMobile && (
            <IconButton onClick={data.onDelete}>
              <DeleteOutlineOutlined />
            </IconButton>
          )}
        </InputAdornment>
      }
    >
      <Grid container columnGap={'1rem'} rowGap='1rem' alignItems='end'>
        <Grid item sm={4} xs={12}>
          <LosFormInputField label='Date'>
            <LOSDateField
              onChange={e => {
                if (!e) return
                const dateString = e.$d
                if (!dateString || dateString == 'Invalid Date') return
                if (dateString.length <= 0) return
                const dateObject = new Date(dateString)
                data.onDateChange(dateObject.toISOString())
              }}
              height='40px'
              value={data.dateValue ?? ''}
              minDate={data.minDate}
            />
          </LosFormInputField>
        </Grid>
        <Grid item sm={4} xs={12}>
          <LosFormInputField label='Amount'>
            <LosTextField
              onChange={e => {
                const numberValue = Number(e.target.value)
                if (isNaN(numberValue)) return
                data.onAmountChange(e.target.value)
              }}
              value={data.amountValue}
            />
          </LosFormInputField>
        </Grid>
        <Grid item>
          <LosFormInputField label=''>
            <IconButton onClick={data.onDelete}>
              <DeleteOutlineOutlined />
            </IconButton>
          </LosFormInputField>
        </Grid>
      </Grid>
    </LosFormInputField>
  )
}
