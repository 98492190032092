import { createContext, useContext, useEffect, useState } from 'react';
import {
  useSearchParams,
  useLocation,
  createSearchParams,
} from 'react-router-dom';
import { NESTED_VIEW } from '../../../routes';

type LmsAgentRouterContextType = {
  selectedRoute: string;
  navigateToApplicationHome: () => void;
  navigateToApplicationReview: (applicationId: string) => void;
  applicationId: string | null;
};

export enum LmsAgentApplicationRoute {
  Applications = '/applications',
  ApplicationReview = '/application-review',
}

const LmsAgentRouterContext = createContext<LmsAgentRouterContextType | null>(
  null
);

export const useLmsAgentRouter = () =>
  useContext(LmsAgentRouterContext) as LmsAgentRouterContextType;

export const LmsAgentRouterProvider = ({ children }: any) => {
  const [route, setRoute] = useState<string>(
    LmsAgentApplicationRoute.Applications
  );
  const [applicationId, setApplicationId] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();

  function _navigateToApplicationHome() {
    setApplicationId(null);
    setSearchParams(
      createSearchParams({
        [NESTED_VIEW]: LmsAgentApplicationRoute.Applications,
      })
    );
  }

  function _navigateToApplicatioReview(applicationId: string) {
    setApplicationId(applicationId);
    setSearchParams(
      createSearchParams({
        [NESTED_VIEW]: LmsAgentApplicationRoute.ApplicationReview,
      })
    );
  }

  useEffect(() => {
    const params = new URLSearchParams(search);
    const queryParams = Object.fromEntries(params.entries());
    const view = queryParams.nested_view;
    setRoute(view);
  }, [searchParams]);

  return (
    <LmsAgentRouterContext.Provider
      value={{
        selectedRoute: route,
        applicationId: applicationId,
        navigateToApplicationHome: _navigateToApplicationHome,
        navigateToApplicationReview: _navigateToApplicatioReview,
      }}
    >
      {children}
    </LmsAgentRouterContext.Provider>
  );
};
