import { Grid, InputAdornment } from '@mui/material';
import { LessOrEqual } from '../../../../../../components/icon/Icons';
import LosText from '../../../../../onboarding/components/common/LosText';
import { StringUtility } from '../../../../../onboarding/utils/StringUtility';
import { EditFormFiled } from './EditFormFiled';
import {
  ColenderEligiblityFormData,
  ColenderEligiblitySchemeInputType,
} from './formData';
import { ReadOnlyFormField } from './ReadOnlyFormField';

type Props = {
  isEditable: boolean;
  formData: Partial<ColenderEligiblityFormData>;
  handleUpdate: (
    type: ColenderEligiblitySchemeInputType,
    value?: string
  ) => void;
};

function ColenderEligiblityForm({ isEditable, handleUpdate, formData }: Props) {
  if (isEditable) {
    return <EditView formData={formData} onUpdate={handleUpdate} />;
  }

  return <ReadOnlyView formData={formData} />;
}

export default ColenderEligiblityForm;

type EditViewProps = {
  formData: Partial<ColenderEligiblityFormData>;
  onUpdate: (type: ColenderEligiblitySchemeInputType, value?: string) => void;
};
const EditView = ({ formData, onUpdate }: EditViewProps) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Grid container spacing={'1rem'}>
          <Grid item xs={3}>
            <EditFormFiled
              title="Minimum Loan Amount"
              description={formData.minLoanAmount}
              startAdornment={
                <InputAdornment position="start">
                  <LosText text="₹" />
                </InputAdornment>
              }
              onChange={(e) => {
                onUpdate(ColenderEligiblitySchemeInputType.MinLoanAmount, e);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="Maximum Loan Amount"
              description={formData.maxLoanAmount}
              startAdornment={
                <InputAdornment position="start">
                  <LosText text="₹" />
                </InputAdornment>
              }
              onChange={(e) => {
                onUpdate(ColenderEligiblitySchemeInputType.MaxLoanAmount, e);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="Minimum Rate Of Interest"
              description={formData.minRateOfInterest}
              endAdornment={
                <InputAdornment position="start">
                  <LosText text="%" />
                </InputAdornment>
              }
              onChange={(e) => {
                onUpdate(
                  ColenderEligiblitySchemeInputType.minRateOfInterest,
                  e
                );
              }}
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="Age Criteria (Years)"
              description={formData.ageCriteria}
              onChange={(e) => {
                onUpdate(ColenderEligiblitySchemeInputType.AgeCriteria, e);
              }}
              startAdornment={<LessOrEqual />}
            />
          </Grid>
          <Grid item xs={3}>
            <EditFormFiled
              title="Bureau Criteria (Score)"
              description={formData.bureauCriteria}
              onChange={(e) => {
                onUpdate(ColenderEligiblitySchemeInputType.BureauCriteria, e);
              }}
              startAdornment={<LessOrEqual />}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

type ReadOnlyViewProps = {
  formData: Partial<ColenderEligiblityFormData>;
};

const ReadOnlyView = ({ formData }: ReadOnlyViewProps) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Grid container spacing={'1rem'}>
          <Grid item xs={3}>
            <ReadOnlyFormField
              title="Minimum Loan Amount"
              description={`₹ ${StringUtility.formatCurrency(
                formData.minLoanAmount
              )}`}
            />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField
              title="Maximum Loan Amount"
              description={`₹ ${StringUtility.formatCurrency(
                formData.maxLoanAmount
              )}`}
            />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField
              title="Minimum Rate Of Interest"
              description={`${formData.minRateOfInterest ?? '-'}%`}
            />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField
              title="Age Criteria"
              description={`>= ${formData.ageCriteria ?? '-'} Years`}
            />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormField
              title="Bureau Criteria"
              description={`>= ${formData.bureauCriteria ?? '-'} Score`}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
