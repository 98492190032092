import { IconButton, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import VegaCheckBoxV2 from '../../../../components/common/v2/VegaCheckBoxV2'
import { CloseDialogIcon } from '../../icons/LosIcons'
import { useLos } from '../../providers/LosProvider'
import { AadharMetaData, CustomerAadharData } from '../../types/Los'
import { StringUtility } from '../../utils/StringUtility'
import AadharDetailsCard from '../AadharDetailsCard'
import LosButton from '../common/LosButton'
import LosDialog from '../common/LosDialog'
import LosText from '../common/LosText'
import LosOnboardingButton from '../common/LosOnboardingButton'
import { LosService } from '../../api/LosService'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  onTncClick: () => void
}

function VerifyAadharDetailsDialog({ open, onClose, onSubmit }: Props) {
  const { fetchAadharDetailsForAadharInfoId, customerApplication } = useLos()
  const [aadhaarDetails, setAadhaarDetails] = useState<AadharMetaData>()
  const [tncAccepted, setTncAccepted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const isInputValid = () => {
    return tncAccepted == true
  }

  function onSubmitClick() {
    if (isInputValid()) {
      onSubmit()
      onClose()
    }
  }

  async function fetchAadharDetails() {
    try {
      const index = customerApplication?.currentUserIndex
        ? customerApplication?.currentUserIndex
        : 0
      const userId = customerApplication?.users[index]
      // const members = customerApplication?.members ?? []
      // if (members.length <= 0) {
      //   console.error('No members found')
      //   return
      // }
      // const latestMember = members[members.length - 1]
      // const aadharInfoId = latestMember.aadhaarInfoId
      // if (!aadharInfoId) {
      //   console.error('AadhaarInfoId not found for member ' + latestMember)
      //   return
      // }
      // const response = await fetchAadharDetailsForAadharInfoId(aadharInfoId)
      if (!userId) {
        console.error('UserId not found for member ')
        return
      }
      const response = await LosService.getAdhaarDetailsByUserId(userId)
      console.log('====================================')
      console.log({ response })
      console.log('====================================')
      setAadhaarDetails(response)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open) {
      fetchAadharDetails()
    }
  }, [open, customerApplication])

  return (
    <LosDialog open={open} onClose={() => {}}>
      <div
        style={{
          maxWidth: '22.5rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
        }}
      >
        <Stack
          direction={'row'}
          alignItems='center'
          justifyContent={'space-between'}
        >
          <LosText text='Verify Aadhaar' />
        </Stack>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AadharDetailsCard
            name={aadhaarDetails?.name}
            aadharNumber={aadhaarDetails?.uid}
            dob={aadhaarDetails?.dob}
            status={aadhaarDetails?.status}
            address={StringUtility.formatAadhaarAddressToReadable(
              aadhaarDetails?.splitAddress
            )}
          />
        </div>

        <Stack>
          <Stack direction={'row'} spacing='1' alignItems={'start'}>
            <VegaCheckBoxV2
              sx={{
                '&.MuiButtonBase-root': {
                  paddingTop: '0px !important',
                },
                color: 'blue',
              }}
              checked={tncAccepted}
              onChange={(e, checked) => {
                setTncAccepted(checked)
              }}
            />
            <LosText style={{ fontSize: '0.75rem', lineHeight: '130%' }}>
              I give{' '}
              <span style={{ fontWeight: 'bold' }}>
                Dhanvikas Fiscal Services Private Limited
              </span>{' '}
              the consent to save and verify my Aadhaar Card details.
            </LosText>
          </Stack>
        </Stack>
        <LosOnboardingButton
          fullWidth
          text='Yes, Confirm My Aadhaar'
          disabled={isInputValid() == false}
          onClick={onSubmitClick}
        />
      </div>
    </LosDialog>
  )
}

export default VerifyAadharDetailsDialog
