import { useMediaQuery, useTheme } from '@mui/material';
import ApplicationPreviewDesktopView from './ApplicationPreviewDesktopView';
import ApplicationPreviewMobileView from './ApplicationPreviewMobileView';

type Props = {
  open: boolean;
  onClose: () => void;
};
function ApplicationPreview({ open, onClose }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  if (isMobile) {
    return <ApplicationPreviewMobileView open={open} onClose={onClose} />;
  }
  return <ApplicationPreviewDesktopView open={open} onClose={onClose} />;
}

export default ApplicationPreview;
